import axios from "axios";

const GetAllPrinters = ({ token, admin_id, location_id, page = 1, size = 25, payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/kitchenmanager/printer/all`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id,
        'page': page,
        'size': size
      },
    })
      .then(response => {

        let total = response.data.total

        let updatedPayload = [...payload];
        if (response.data.items) {
          updatedPayload = [...payload, ...response.data.items];
        }

        let count = updatedPayload.length

        if (count < total) {
          const nextPage = response.data.page + 1
          GetAllPrinters({
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            page: nextPage,
            size: size,
            sorted: true,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => { reject(error) })
  });
};

export default GetAllPrinters