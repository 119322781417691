import { createSlice } from "@reduxjs/toolkit";

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoice_pagesize: 10,
    invoice_pagination: 0,
  },
  reducers: {
    setInvoiceId: (state, action) => {
      state.invoice_id = action.payload;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoices: (state, action) => {
      state.invoices = action.payload
    },
    editInvoices: (state, action) => {
      for (let invoice in state.invoices.content) {
        if (state.invoices.content[invoice].id === action.payload.id) {
          state.invoices.content[invoice] = action.payload
        }
      }
    },
    setInvoicesHighLights: (state, action) => {
      state.invoiceshighlights = action.payload
    },
    setInvoiceModalShow: (state, action) => {
      state.invoicemodalshow = action.payload
    },
    setInvoicePagination: (state, action) => {
      state.invoice_pagination = (action.payload - 1)
    },
    setInvoicePagesize: (state, action) => {
      state.invoice_pagesize = action.payload
    },
  }
});

export const {
  setInvoiceId, setInvoice, setInvoices, editInvoices, setInvoicesHighLights, setInvoiceModalShow, setInvoicePagination, setInvoicePagesize,
} = invoiceSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getInvoiceId = (state) => state.invoice.invoice_id
export const getInvoice = (state) => state.invoice.invoice
export const getInvoices = (state) => state.invoice.invoices
export const getInvoicesHighlights = (state) => state.invoice.invoiceshighlights
export const getInvoiceModalShow = (state) => state.invoice.invoicemodalshow
export const getInvoicePagination = (state) => state.invoice.invoice_pagination
export const getInvoicePagesize = (state) => state.invoice.invoice_pagesize

export default invoiceSlice.reducer;