import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import TableButton from './tableButton';
import NarrowcastingSchedulesButtons from '../portal/narrowcasting/narrowcastingSchedulesButtons';
import NarrowcastingSchedulesModal from './narrowcastingSchedulesModal';

import './css/productTable.css';
import PaginationAndSizeComponent from './paginationAndSize';

export default function NarrowcastingSchedulesTable(props) {
  const schedules = props.schedules?.items || [];
  const pages = props?.schedules?.pages || 0;
  const page = props?.schedules?.page || 0;

  const buttons = NarrowcastingSchedulesButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []
  for (const schedule of Object.values(schedules)) {
    rows.push(
      <tr className='product-row' key={schedule.id}>
        <td>{schedule.name}</td>
        <td>{schedule.description}</td>
        <td>{schedule.day}</td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(schedule) }} id={schedule.id}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(schedule) }} id={schedule.id}>{translate(edit.tag)}</Button>}
          {del && <Button size="sm" type='button' onClick={() => { del.onClick(schedule) }} id={schedule.id}>{translate(del.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Description')}</th>
            <th>{translate('Day')}</th>
            <th width='25%'>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <NarrowcastingSchedulesModal modal={modalview.modal} />
    </>
  );
}