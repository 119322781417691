import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getHashChannelsEmailsAll, getChannelsEmailsAll } from "../../redux/dataSlices/notificationSlice";
import { NotificationSubscriptionFormFields } from "./notificationsFormFields";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import { eventChoices } from "../../selectfieldchoices/notificationselect.mjs";
import NotificationSubscriptionForm from "../../components/notificationSubscriptionForm";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostNotificationSubscription from "../../actablueAPI/notifications/PostNotificationSubscription";

const NotificationSubscriptionAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const channels_all = useSelector(getChannelsEmailsAll)
  const hash_channels_all = useSelector(getHashChannelsEmailsAll)
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState({ administration_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newSubscription = HandleOnChange({ event: event, object: subscription })
    setSubscription(newSubscription)
  }

  const onChangeChannel = (event) => {
    let newSubscription = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: subscription, hash: hash_channels_all })
    setSubscription(newSubscription)
  }

  const onChangeEvents = (event) => {
    let newSubscription = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: subscription, selectfield: 'events' })
    setSubscription(newSubscription)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostNotificationSubscription({ token: token, admin_id: admin_id, data: subscription })
      .then(() => {
        navigate("/notifications");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NotificationSubscriptionFormFields(),
    field: {
      onChange: onChange
    },
    data: subscription,
    channel: {
      label: 'channel',
      options: channels_all,
      onChange: onChangeChannel,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    events: {
      label: 'events',
      options: eventChoices,
      onChange: onChangeEvents,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const card = {
    size: 12,
    title: "Subscription add ",
    data: <NotificationSubscriptionForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationSubscriptionAdd;

