const OpticonEslFormFields = () => {
  let opticonEslFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },

    // single option select fields  
    // {type:'hidden', name:'administration_id', id:'administration_id', readonly:true},
    { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id' },

    // single option fields
    { type: 'text', name: `Product`, id: 'product_id' },
    { type: 'text', name: `Name`, id: 'product_name' },
    { type: 'text', name: 'ESL Barcode', id: 'esl_barcode' },
  ]

  return opticonEslFormFields
}

export default OpticonEslFormFields