import { createSlice } from "@reduxjs/toolkit";

export const selectLocationSlice = createSlice({
  name: "selectLocation",
  initialState: {
    selected_location_id: localStorage.getItem('location_id') !== 'undefined' ? JSON.parse(localStorage.getItem('location_id')): undefined,
    selected_location: localStorage.getItem('location') !== 'undefined' ? JSON.parse(localStorage.getItem('location')): undefined,
    selected_location_rawscopes: []
  },
  reducers: {
    setSelectedLocationId: (state, action) => {
      if (action.payload) {
        state.selected_location_id = action.payload;
        localStorage.setItem('location_id', JSON.stringify(action.payload));
      };
      if (!action.payload) {
        state.selected_location_id = undefined;
        localStorage.setItem('location_id', undefined);

        // delete all data
        
        state.orders = [];
        state.ordershighlights = [];
        state.invoices = [];
        state.invoiceshighlights = [];
        state.transactions = [];
        state.transactionshighlights = [];
        state.reservations = [];
        state.reservationshighlights = [];
        
      };
    },

    setSelectedLocation: (state, action) => {
      if (action.payload) {
        state.selected_location = action.payload;
        localStorage.setItem('location', JSON.stringify(action.payload));
      };
      if (!action.payload) {
        state.selected_location = undefined;
        localStorage.setItem('location', undefined);
      };
    },
    setSelectedLocationScopes: (state, action) => {
      state.selected_location_scopes = action.payload
    },
    setSelectedLocationRawScopes: (state, action) => {
      state.selected_location_rawscopes = action.payload
    }
  }
});

export const {
  setSelectedLocationId, setSelectedLocation, setSelectedLocationScopes, setSelectedLocationRawScopes
} = selectLocationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getSelectedLocation_id = (state) => state.selectLocation.selected_location_id
export const getSelectedLocation = (state) => state.selectLocation.selected_location
export const getSelectedLocationScopes = (state) => state.selectLocation.selected_location_scopes
export const getSelectedLocationRawScopes = (state) => state.selectLocation.selected_location_rawscopes

export default selectLocationSlice.reducer;