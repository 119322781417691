import axios from "axios";

const DeletePrinterById = ({token, admin_id, location_id, id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_ENV}/schapkaartjes/printer`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json'
      },
      params: {
        admin_id: admin_id,
        location_id: location_id,
        id: id
      }
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.error("error:", error)
      reject(error)
    });
  });
};

export default DeletePrinterById