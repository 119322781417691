import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './css/modal.css'
import { useSelector } from 'react-redux';
import { getTaskData } from '../redux/dataSlices/analyseSlice';

export default function AnalyseProgressModal(props) {
  const { t: translate } = useTranslation();
  const task_data = useSelector(getTaskData)
  
  return (
    <Modal
      show={props.modal.show}
      size="lg"
      dialogClassName='modal-fullscreen-md-down modal-md'
      contentClassName='h-md-100'
      aria-labelledby="contained-modal-title-vcenter"
      centered scrollable
    >
      <Modal.Header>
        <Modal.Title>Analyse running</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar animated label={`${task_data ? Math.round(task_data.progress * 100) : 0}%`} now={task_data ? task_data.progress * 100 : 0} />
        <p>{task_data?.current} from {task_data?.total}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
