import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import SubscribeModal from './subscribeModal';
import SubscribeButtons from '../portal/subscribe/subscribebuttons';
import { useTranslation } from 'react-i18next';
import SubscribeCopyModal from './subscribeCopyModal';
import { useSelector } from 'react-redux';
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';
import SubscribeEditModal from './subscribeEditModal';


export default function SubscribeTable(subscriptions) {
  const location_id = useSelector(getSelectedLocation_id)
  const buttons = SubscribeButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const copy = buttons.hasOwnProperty('copy') ? Object.assign({}, buttons.copy) : false
  const infoModalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: subscriptions.id }) : false
  const { t: translate } = useTranslation();

  let rows = []

  for (const subscription of Object.values(subscriptions)) {
    // const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, {id:paymentledger.id}) : false
    let registrationRow = []
    for (const setting of Object.values(subscription.settings)) {
      registrationRow.push(
        <tr key={setting.id}>
          <td>{setting.subscription.name}</td>
          <td>{setting.start ? new Date(setting.start).toLocaleString() : undefined}</td>
          <td>{setting.stop ? new Date(setting.stop).toLocaleString() : undefined}</td>
          <td>{setting.duration}</td>
        </tr>
      )
    }
    rows.push(
      <tr className='product-row' key={subscription.id}>
        <td>{subscription.name}</td>
        <td>
          <Table width='100%'>
            <thead>
              <tr>
                <th width='30%'>{translate('Registration')}</th>
                <th width='25%'>{translate('Start')}</th>
                <th width='25%'>{translate('Stop')}</th>
                <th width='25%'>{translate('Length')}</th>
              </tr>
            </thead>
            <tbody>
              {registrationRow}
            </tbody>
          </Table>
        </td>
        <td>
          <Button size="sm" type='button' onClick={() => { infoModalview.onClick(subscription) }}>{translate(infoModalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={()=>{edit.onClick(subscription)}}>{translate(edit.tag)}</Button>}
          {/* {copy && <Button size="sm" type='button' onClick={()=>{copy.onClick(subscription)}} >{translate(copy.tag)}</Button>} */}
        </td>
      </tr>
    )
  };

  return (
    <>
      {/* {add && location_id && <TableButton {...add} />} */}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Registration')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <SubscribeModal modal={infoModalview.modal} />
        <SubscribeEditModal modal={edit.modal} />
        {/* <SubscribeCopyModal modal={copy.modal}/> */}
      </Table>
    </>
  );
}