import { useSelector } from "react-redux";
import { getScopes } from "../redux/dataSlices/scopesSlice";

export const ReportSelectChoices = () => {
  const scopes = useSelector(getScopes);

  const reportSelectChoices = [
    { 'id': 'transactions', 'name': 'Transactions' },
    { 'id': 'sales', 'name': 'Sales' },
    { 'id': 'productpermonth', 'name': 'Products per month' },
    { 'id': 'device', 'name': 'Device' },
    { 'id': 'operator', 'name': 'Operator' },
  ]

  if (scopes.transactionAnalyse) {
    reportSelectChoices.push({ 'id': 'analysetransactiondescript', 'name': 'Transaction analyse per month' })
    reportSelectChoices.push({ 'id': 'metadata', 'name': 'Metadata' })
  }
  return reportSelectChoices
}
 