import { createSlice } from "@reduxjs/toolkit";

export const reportingSlice = createSlice({
  name: "reporting",
  initialState: {
    graph_labels: [],
    graph_datasets: []
  },
  reducers: {
    setStatusReportDay: (state, action) => {
      state.status_report_day = action.payload
    },
    setStatusReportWeek: (state, action) => {
      state.status_report_week = action.payload
    },
    setStatusReportMonth: (state, action) => {
      state.status_report_month = action.payload
    },
    setSalesReportDay: (state, action) => {
      state.sales_report_day = action.payload
    },
    setSalesReportWeek: (state, action) => {
      state.sales_report_week = action.payload
    },
    setSalesReportMonth: (state, action) => {
      state.sales_report_month = action.payload
    },
    setProductsReportMonth: (state, action) => {
      state.product_report_month = action.payload
    },
    setUsersSalesReportDay: (state, action) => {
      state.user_sales_report_day = action.payload
    },
    setUsersSalesReportWeek: (state, action) => {
      state.user_sales_report_week = action.payload
    },
    setUsersSalesReportMonth: (state, action) => {
      state.user_sales_report_month = action.payload
    },
    setOperatorsSalesReportDay: (state, action) => {
      state.operator_sales_report_day = action.payload
    },
    setOperatorsSalesReportWeek: (state, action) => {
      state.operator_sales_report_week = action.payload
    },
    setOperatorsSalesReportMonth: (state, action) => {
      state.operator_sales_report_month = action.payload
    },
    setGraphLabels: (state, action) => {
      state.graph_labels = action.payload
    },
    setGraphHeader: (state, action) => {
      state.graph_header = action.payload
    },
    setGraphDatasets: (state, action) => {
      state.graph_datasets = action.payload
    },
    setReportHighlights: (state, action) => {
      state.highlights = action.payload
    },
    setProgressModalShow: (state, action) => {
      state.progress_modal_show = action.payload
    },
    setProgressData: (state, action) => {
      state.progress_data = action.payload
    },
  }
});

export const {
  setStatusReportDay, setStatusReportWeek, setStatusReportMonth,
  setSalesReportDay, setSalesReportWeek, setSalesReportMonth,
  setProductsReportMonth,
  setUsersSalesReportDay, setUsersSalesReportWeek, setUsersSalesReportMonth,
  setOperatorsSalesReportDay, setOperatorsSalesReportWeek, setOperatorsSalesReportMonth,
  setGraphLabels, setGraphHeader, setGraphDatasets,
  setReportHighlights,
  setProgressModalShow,
  setProgressData
} = reportingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getStatusReportDay = (state) => state.reporting.status_report_day
export const getStatusReportWeek = (state) => state.reporting.status_report_week
export const getStatusReportMonth = (state) => state.reporting.status_report_month
export const getSalesReportDay = (state) => state.reporting.sales_report_day
export const getSalesReportWeek = (state) => state.reporting.sales_report_week
export const getSalesReportMonth = (state) => state.reporting.sales_report_month
export const getProductReportMonth = (state) => state.reporting.product_report_month
export const getUsersSalesReportDay = (state) => state.reporting.user_sales_report_day
export const getUsersSalesReportWeek = (state) => state.reporting.user_sales_report_week
export const getUsersSalesReportMonth = (state) => state.reporting.user_sales_report_month
export const getOperatorsSalesReportDay = (state) => state.reporting.operator_sales_report_day
export const getOperatorsSalesReportWeek = (state) => state.reporting.operator_sales_report_week
export const getOperatorsSalesReportMonth = (state) => state.reporting.operator_sales_report_month
export const getGraphLabels = (state) => state.reporting.graph_labels
export const getGraphHeader = (state) => state.reporting.graph_header
export const getGraphDatasets = (state) => state.reporting.graph_datasets
export const getReportHighlights = (state) => state.reporting.highlights
export const getProgressModalShow = (state) => state.reporting.progress_modal_show
export const getProgressData= (state) => state.reporting.progress_data

export default reportingSlice.reducer;