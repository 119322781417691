import { Table, Tab, Nav, Button } from 'react-bootstrap'
import SingleSelectField from './singleselectField'
import AnalyseButtons from '../portal/analyses/analyseButtons'
import AnalyseOnChangeActions from '../portal/analyses/analyseFormFieldOnChange'
import { useSelector } from 'react-redux'
import { getActionSpinner } from '../redux/dataSlices/analyseSlice'
import LoadingSpinner from "./spinner";
import { useTranslation } from 'react-i18next';
import { getRole } from '../redux/dataSlices/tokenSlice'

export default function AnalyseProductDataTable(data) {
  const role = useSelector(getRole)
  const results = data.taskData?.results
  const buttons = AnalyseButtons.call()
  const onChangeActions = AnalyseOnChangeActions()
  const actionSpinner = useSelector(getActionSpinner)
  const exportButton = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const fixvat = buttons.hasOwnProperty('fixvat') ? Object.assign({}, buttons.fixvat) : false
  const { t: translate } = useTranslation();
  
  let noname = []
  let novat = []
  let noeans = []
  let nojournalcategory = []
  let noproductcategory =[]
  let vatdifference = []
  let eansmultipleproducts = []
  let skucodesmultipleproducts = []

  if (results) {
    let counter = 0

    if (results.noname?.length > 0) {
      for (const product of results.noname) {
        noname.push(
          <tr className='product-row' key={counter}>
            <td>{product.id}</td>
            <td>{product.name}</td>
          </tr>
        )
        counter++
      }
    }
    
    if (results.novat?.length > 0) {
      for (const product of results.novat) {
        novat.push(
          <tr className='product-row' key={counter}>
            <td>{product.id}</td>
            <td>{product.name}</td>
          </tr>
        )
        counter++
      }
    }
    
    if (results.noeans?.length > 0) {
      for (const product of results.noeans) {
        noeans.push(
          <tr className='product-row' key={counter}>
            <td>{product.id}</td>
            <td>{product.name}</td>
          </tr>
        )
        counter++
      }
    }
    
    if (results.nojournalcategory?.length > 0) {
      for (const product of results.nojournalcategory) {
        nojournalcategory.push(
          <tr className='product-row' key={counter}>
            <td>{product.id}</td>
            <td>{product.name}</td>
            <td>{actionSpinner ? <LoadingSpinner /> : onChangeActions.journalcategories.selected && <Button size="sm" type='button' onClick={() => { buttons.journalConnect.onClick(product) }}>Connect</Button>}</td>
          </tr>
        )
        counter++
      }
  
    }
    
    if (results.noproductcategory?.length > 0) {
      for (const product of results.noproductcategory) {
        noproductcategory.push(
          <tr className='product-row' key={counter}>
            <td>{product.id}</td>
            <td>{product.name}</td>
          </tr>
        )
        counter++
      }  
    }
    
    if (results.vatdifference?.length > 0) {
      for (const product of results.vatdifference) {
        vatdifference.push(
          <tr className='product-row' key={counter}>
            <td>{product.id}</td>
            <td>{product.name}</td>
            <td>{product.vat?.name}</td>
            <td>{product.journalcategory?.title}</td>
            <td>{product.journalcategory?.vat?.name}</td>
          </tr>
        )
        counter++
      }
  
    }
    
    if (results.eansmultipleproducts) {
      for (const [ean, products] of Object.entries(results.eansmultipleproducts)) {
        eansmultipleproducts.push(
          <tr className='product-row' key={counter}>
            <td>{ean}</td>
            <td>{products.map((product)=> {return(<p>{product.name}</p>)})}</td>
          </tr>
        )
      counter++
      }
    }
    
    if (results.skucodesmultipleproducts) {
      for (const [ean, products] of Object.entries(results.skucodesmultipleproducts)) {
        skucodesmultipleproducts.push(
          <tr className='product-row' key={counter}>
            <td>{ean}</td>
            <td>{products.map((product)=> {return(<p>{product.name}</p>)})}</td>
          </tr>
        )
      counter++
      }
    }
  }

  return (
    <Tab.Container defaultActiveKey="noname">
    <Nav variant="tabs">
      <Nav.Item><Nav.Link eventKey="noname">No Name</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="novat">No VAT</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="nojournalcategory">No Journalcategory</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="noproductcategory">No Productcategory</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="noeans">No Eans</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="vatdifference">VAT difference</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="eansmultipleproducts">Ean multiple products</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="skucodesmultipleproducts">SKU multiple products</Nav.Link></Nav.Item>
    </Nav>
    <Tab.Content>
      <Tab.Pane eventKey="noname">
        <div className='tab-container'>
          <h2>No Name ({noname.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {noname}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.noname, 'noname') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="novat">
        <div className='tab-container'>
          <h2>No VAT ({novat.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {novat}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.novat, 'novat') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="nojournalcategory">
        <div className='tab-container'>
          <h2>No journalcategory ({nojournalcategory.length})</h2>
          <SingleSelectField {...onChangeActions.journalcategories} name='JOURNAL' id='JOURNAL'/>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {nojournalcategory}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.nojournalcategory, 'nojournalcategory') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="noproductcategory">
        <div className='tab-container'>
          <h2>No productcategory ({noproductcategory.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {noproductcategory}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.noproductcategory, 'noproductcategory') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="noeans">
        <div className='tab-container'>
          <h2>No eans ({noeans.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
              </tr>
          </thead>
            <tbody>
              {noeans}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.noeans, 'noeans') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="vatdifference">
        <div className='tab-container'>
          <h2>Vat difference product and journalcategory ({vatdifference.length})</h2>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { fixvat.onClick(results.vatdifference) }}>{translate(fixvat.tag)} Add same VAT to product as its journalcategory</Button>}
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Vat product</th>
                <th>Journal category</th>
                <th>Vat journalcategory</th>
              </tr>
          </thead>
            <tbody>
              {vatdifference}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.vatdifference, 'vatdifference') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="eansmultipleproducts">
        <div className='tab-container'>
          <h2>Ean multiple products ({eansmultipleproducts.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>Ean</th>
                <th>Products</th>
              </tr>
          </thead>
            <tbody>
              {eansmultipleproducts}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.eansmultipleproducts, 'eansmultipleproducts') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="skucodesmultipleproducts">
        <div className='tab-container'>
          <h2>SKU multiple products ({skucodesmultipleproducts.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>SKU</th>
                <th>Products</th>
              </tr>
          </thead>
            <tbody>
              {skucodesmultipleproducts}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.skucodesmultipleproducts, 'skucodesmultipleproducts') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
    </Tab.Content>
  </Tab.Container>
  );
}
