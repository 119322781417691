import axios from "axios";

export default function PutKeycloakUser(token, id, user) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_KEYCLOAK_ADMIN_URL}/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
      data: user
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    });
  });
};