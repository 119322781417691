import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import ProductSupplierButtons from '../portal/products/productsupplierbuttons';
import ProductSupplierModal from './productsupplierModal';
import PaginationComponent from './pagination';
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

export default function ProductSupplierTable(products) {

  const buttons = ProductSupplierButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  pagesize['number_of_elements'] = products.number_of_elements

  if (products && products.content && products.content.length > 0) {
    const pages = products.total_pages
    const page = (products.number + 1)
    return (
      <>
        {<PaginationComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
        {add && <TableButton {...add} />}
        <Table hover width='100%'>
          <thead>
            <tr>
              <th></th>
              <th>Code</th>
              <th>Price</th>
              <th>Supplier</th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {products.content.map((product) => {
              const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: product.id }) : false
              const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: product.id }) : false
              return (
                <tr className='product-row' key={product.id}>
                  <td>
                    <img className="product-thumbnail" src={product.thumbnail} alt='' width='128'></img>
                  </td>
                  <td>{product.name}</td>
                  <td>{Number(product.price_incl_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                  <td>{product.type}</td>
                  <td>
                    <p>Created: {new Date(product.creation).toLocaleString()}</p>
                    <p>Modified: {new Date(product.modification).toLocaleString()}</p>
                  </td>
                  <td>
                    <Button size="sm" type='button' onClick={() => { modalview.onClick(product) }} id={product.id}>{translate(modalview.tag)}</Button>
                    {edit && <TableButton {...edit} />}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <ProductSupplierModal modal={modalview.modal} />
        </Table>
      </>
    );
  } else {
    return (
      <>
        {add && <TableButton {...add} />}
        <Table width='100%'>
          <thead>
            <tr>
              <th></th>
              <th>
                {translate('Supplier')}
              </th>
              <th>
                {translate('Price')}
              </th>
              <th>
                {translate('Supplier')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
        </Table>
      </>
    );
  }
}