import axios from "axios";

const ImportSnelstartProducts = ({token, admin_id, productpages}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/snelstart/product/import"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: { productpages: productpages }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  });
};

export default ImportSnelstartProducts