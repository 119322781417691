import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSubscriptionModalShow, setSubscriptionModalShow, setSubscription } from "../../redux/dataSlices/subscriptionSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import DelSubscriptionById from "../../actablueAPI/registrations/DelSubscriptionById copy";

export default function SubscriptionButtons() {
  const token = useSelector(getToken)
  const modalShow = useSelector(getSubscriptionModalShow)
  // const pageSize = useSelector(getDevicePagesize)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(subscription) {
    dispatch(setSubscription(subscription));
    dispatch(setSubscriptionModalShow(true));
  };

  function onclickCopy(subscription) {
    dispatch(setSubscription(subscription));
    navigate("/subscription/add");
  };

  function onclickEdit(subscription) {
    dispatch(setSubscription(subscription));
    navigate("/subscription/edit");
  };

  const onViewHide = () => {
    dispatch(setSubscriptionModalShow(false));
  };

  const onclickAdd = () => {
    dispatch(setSubscription());
    navigate("/subscription/add");
  };

  function onclickDelete(subscription, refresh) {
    dispatch(setLoading(true))
    DelSubscriptionById({token:token, id:subscription.id})
    .then(response => {
      refresh()
      dispatch(setLoading(false))
    })
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.accounting >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
    buttons.copy = {
      tag: "Copy",
      value: 'copy',
      onClick: onclickCopy,
      className: 'add-button'
    }
  }
  if (scopes.accounting >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }
  if (scopes.accounting >= 3) {
    buttons.del = {
      tag: "Delete",
      value: 'del',
      onClick: onclickDelete
    }
  }

  return buttons
}

