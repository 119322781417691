import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import TableButton from './tableButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPlanningLocation } from '../redux/dataSlices/planningSlice';
import PlanningButtons from '../portal/planning/planningbuttons';
import PlanningObjectModal from './planningObjectModal';
import PaginationAndSizeComponent from './paginationAndSize';

function PlanningObjectTable(props) {
  const planning_location = useSelector(getPlanningLocation);
  const pages = (props?.planning_objects?.total_pages) || 0;
  const page = (props?.planning_objects?.number + 1) || 0;
  const buttons = PlanningButtons.call()
  const modalview = buttons.hasOwnProperty('objectmodalview') ? Object.assign({}, buttons.objectmodalview) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.paginationObjectPlanning
  let pagesize = buttons.pagesizePlanningObject

  if (!planning_location) {
    return (
      <>
        <Table width='100%'>
          Selected location has no planning settings, please go to the locations page and setup your planning location.
        </Table>
      </>
    )
  }

  let rows = []
  if (props?.planning_objects?.content && planning_location) {
    props?.planning_objects?.content.forEach(object => {
      const modalview = buttons.hasOwnProperty('objectmodalview') ? Object.assign({}, buttons.objectmodalview, { id: object.id }) : false
      const edit = buttons.hasOwnProperty('planning_object_edit') ? Object.assign({}, buttons.planning_object_edit, { id: object.id }) : false
      rows.push(
        <tr key={object.id} className='product-row'>
          <td>{object.name || "N/A"}</td>
          <td>{object.description || "N/A"}</td>
          <td>{object.type || "N/A"}</td>
          <td>{object.capacity || "N/A"}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(object) }} id={object.id}>{translate(modalview.tag)}</Button>
            {edit && <TableButton {...edit} />}
          </td>
        </tr>
      )
    });
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Description')}
            </th>
            <th>
              {translate('Type')}
            </th>
            <th>
              {translate('Capacity')}
            </th>
            <th width='15%'>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <PlanningObjectModal modal={modalview.modal} />
      </Table>
    </>
  );
}

export default PlanningObjectTable;