import axios from "axios";

const GetInvoicePaymentsAccounting = ({token, transaction_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/invoicepayments/accounting`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json',
      },
      params: {
        'transaction_id': transaction_id
      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetInvoicePaymentsAccounting