import { useSelector, useDispatch } from 'react-redux';

import { getToken } from "../redux/dataSlices/tokenSlice";
import { getReservation, getReservations, setReservations, getReservationObjects, setReservation, setReservationWithoutObjectModalShow } from '../redux/dataSlices/reservationSlice';

import axios from "axios";

import HandleOnChange from "../helpers/handleOnChange";
import HandleOnChangeMultiSelectFieldReservationObject from "../helpers/handleOnChangeMultiSelectFieldReservationObject";
import ReservationAssignForm from "./reservationAssignForm";

import { reservationAssignFormFields } from "../portal/reservations/reservationAssignFormFields.mjs";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import './css/reservationAssignModal.css'
import useHandleError from '../customhooks/useHandleError';


export default function ReservationAssignModal(props) {
  const token = useSelector(getToken);
  const reservation = useSelector(getReservation);
  const reservations = useSelector(getReservations)
  const dispatch = useDispatch()
  const reservation_objects = useSelector(getReservationObjects)
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newReservation = HandleOnChange({ event: event, object: reservation })
    dispatch(setReservation(newReservation))
  };

  const onReservationObjectChange = (event) => {
    let newReservation = HandleOnChangeMultiSelectFieldReservationObject({ event: event, object: reservation, selectfield: 'reservation_objects', hash: reservation_objects })
    dispatch(setReservation(newReservation))
  };

  const onCancel = () => {
    dispatch(setReservationWithoutObjectModalShow(false))
  }

  const onSubmit = () => {
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/reservations/reservation"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      data: reservation
    })
      .then(() => {
        let newReservations = Object.assign({}, reservations)
        for (let _reservation in newReservations) {
          if (newReservations[_reservation].id === reservation.id) {
            newReservations[_reservation] = reservation
          }
        }
        dispatch(setReservations(newReservations))
        dispatch(setReservationWithoutObjectModalShow(false));
        dispatch(setReservationWithoutObjectModalShow(false));
      })
      .catch(error => {
        HandleError({ error: error, variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } })
      })


  };

  const formData = {
    fields: reservationAssignFormFields,
    field: {
      onChange: onChange
    },
    data: reservation,
    reservation_objects_data: reservation_objects,
    reservation_objects: {
      name: 'reservation_objects',
      options: [],
      selected: [],
      onChange: onReservationObjectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  if (reservation) {
    return (
      <Modal
        show={props.modal.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // onHide={handleClose}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {translate('Reservation')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className='reservationassign-modal-body'>
                <h4>{translate('Reservation Info')}</h4>
                <p>{translate('Name')}: {reservation.reservation_name}</p>
                <p>{translate('Phone')}: {reservation.reservation_phonenumber}</p>
                <p>{translate('Email')}: {reservation.reservation_email}</p>
                <p>{translate('People')}: {reservation.number_of_people}</p>
                <p>{translate('Remark')}: {reservation.remark}</p>
              </div>
            </div>
            <div className="col-6">
              <div className='reservationassign-modal-body'>
                {reservation?.reservation_objects?.length > 0 ?
                  <>
                    {reservation.reservation_objects.map((object) => (
                      <>
                        <h5 key={object.reservation_object_id}>Name: {object.name}</h5>
                        <p>{translate('Capacity')}: {object.capacity}</p>
                        <p>{translate('Minimum reservation size')}: {object.minimum_reservation_size}</p>
                      </>
                    ))}
                  </>
                  :
                  <>
                    <p>{translate('No table(s) selected')}</p>
                  </>
                }
              </div>
            </div>
          </div>
          <h4>{translate('Assign Table')}</h4>
          <ReservationAssignForm {...formData} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type='submit' onClick={onCancel}>{translate('Cancel')}</Button>
          <Button variant="primary" type='submit' onClick={onSubmit}>{translate('Save')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}