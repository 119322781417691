import LoadingSpinner from "./spinner";

import { useSelector } from "react-redux";
import { getLoading } from "../redux/dataSlices/loadingSlice";

import * as React from "react";
import { Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const HighlightsCard = (props) => {
  const loading = useSelector(getLoading);
  const { t: translate } = useTranslation();

  return (
    <Col md={props.size} className={props.className}>
      <Card className={props.overflow && 'card-overflow'}>
        <Card.Body>
          <Card.Title>{typeof props.title === 'string' ? translate(props.title) : props.title}</Card.Title>
          {props.header &&
            <Card.Text>
              {props.header}
            </Card.Text>
          }
          <Card.Text as="div">
            {loading ? <LoadingSpinner /> : props.data}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default HighlightsCard;
