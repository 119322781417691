import { StyleSheet } from "@react-pdf/renderer";

const height = 16
const fontSize = 10

export const tableRowsCount = 11;

export const borderColor = '#90e5fc'

export const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: fontSize,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
  }, 
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  // ItemsTable
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
  // TableHeader
  headerContainer: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: height,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  headerday: {
    // width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerjournal: {
    // width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },  
  headerproduct: {
    // width: '45%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerproductPercentage: {
    // width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerproductQuantity: {
    // width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headerproductTotal: {
    // width: '15%'
  },
  // TableRow
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: height,
    fontStyle: 'bold',
  },
  rowday: {
    // width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingRight: 8,
  },
  rowjournal: {
    // width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingRight: 8,
  },
  rowproduct: {
      // width: '45%',
      textAlign: 'left',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
  },
  rowproductPercentage: {
    // width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
},
  rowproductQuantity: {
      // width: '10%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'right',
      paddingRight: 8,
  },
  rowproductTotal: {
      // width: '15%',
      textAlign: 'right',
      paddingRight: 8,
  },
  // EmptyTableRow
  emptyRow: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: height,
    fontStyle: 'bold',
    color: 'white'
  },
  emptyRowDescription: {
      width: '60%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  emptyRowqty: {
      width: '10%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  emptyRowRate: {
      width: '15%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  emptyRowAmount: {
      width: '15%',
  },
  // footer
  footerRow: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: height,
    fontSize: fontSize,
    fontStyle: 'bold',
  },
  footerDescription: {
      width: '85%',
      textAlign: 'right',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingRight: 8,
  },
  footerTotal: {
      width: '15%',
      textAlign: 'right',
      paddingRight: 8,
  },
  });