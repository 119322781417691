import { createSlice } from "@reduxjs/toolkit";

export const scopesSlice = createSlice({
  name: "scopes",
  initialState: {
    scopes: localStorage.getItem('scopes') ? JSON.parse(localStorage.getItem('scopes')): {},
  },
  reducers: {
    setScopes: (state, action) => {
      state.scopes = action.payload;
      localStorage.setItem('scopes', JSON.stringify(action.payload));
    },
  }
});

export const {
  setScopes
} = scopesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getScopes = (state) => state.scopes.scopes


export default scopesSlice.reducer;