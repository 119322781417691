import { useSelector } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

const PlanningLocationFormFields = () => {
  const scopes = useSelector(getScopes);

  if (!scopes.planning) return {}

  let planningLocationFormFields = [
    { type: 'select', name: `Application`, id: 'application', formdatafield: 'application', required: false },
    { type: 'number', name: `Capacity`, id: 'capacity', required: false },
    { type: 'number', name: `Travel time`, id: 'travel_time', required: false },
    { type: 'number', name: `Duration`, id: 'duration', required: false },
    { type: 'text', name: 'Timezone', id: 'timezone', required: false },
    { type: 'checkbox', name: 'Eco', id: 'eco', required: false },
    { type: 'checkbox', name: `Can reserve events`, id: 'can_reserve_events', required: false },
    { type: 'checkbox', name: `Automatically accept plannings`, id: 'automatically_accept_plannings', required: false },
    { type: 'checkbox', name: `Automatically assign tables`, id: 'automatically_assign_tables', required: false },
    { type: 'checkbox', name: `Precise automatic plannings`, id: 'precise_automatic_plannings', required: false },
    { type: 'checkbox', name: `Active`, id: 'is_active', required: false },
    // Opening Times
  ]

  return planningLocationFormFields
}

export default PlanningLocationFormFields