import OrderButtons from "../portal/orders/orderbuttons";
import OrderModal from "../components/orderModal";
import { useTranslation } from 'react-i18next';
import { Button, Table, Dropdown, Form, Navbar } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { getRole } from "../redux/dataSlices/tokenSlice";

export default function OrderTable(props) {
  const role = useSelector(getRole)
  const orders = props.orders
  const hashEmployees = props.employees
  const buttons = OrderButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const exportButton = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const pickingOrder = buttons.hasOwnProperty('pickingOrder') ? Object.assign({}, buttons.pickingOrder) : false
  const transaction = buttons.hasOwnProperty('transaction') ? Object.assign({}, buttons.transaction) : false
  const ccvtransaction = buttons.hasOwnProperty('ccvtransaction') ? Object.assign({}, buttons.ccvtransaction) : false
  const dpd = buttons.hasOwnProperty('dpd') ? Object.assign({}, buttons.dpd) : false
  const selected = buttons?.select?.selected
  const { t: translate } = useTranslation();

  let sorted = []
  // convert object to array for sorting
  if (orders) {
    sorted = Object.values(orders).sort((a, b) => b?.number - a?.number)
  }

  let rows = []
  sorted.forEach(order => {
    const delivery = order.hasOwnProperty('delivery') ? Object.assign({}, order.delivery, { id: order.id }) : false
    const exportPdf = buttons?.modalview?.modal?.hasOwnProperty('exportPdf') ? Object.assign({}, buttons?.modalview?.modal?.exportPdf, { id: order.id }) : false

    rows.push(
        <tr key={order.id} className={selected?.hasOwnProperty(order.id) ? 'product-row selected' : 'product-row'}>
          <td>
            <Form>
              <Form.Check onChange={() => { buttons.select.onClick(order) }} checked={selected?.hasOwnProperty(order.id)} />
            </Form>
          </td>
          <td>{order.number}</td>
          <td>{order.date ? new Date(order.date).toLocaleString() : new Date(order.modification).toLocaleString()}</td>
          <td>{order.operator_id ? hashEmployees[order.operator_id]?.name : ''}</td>
          <td align="right">{Number(order.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          <td>{order.origin}</td>
          <td>{delivery ? <div>Delivery: {delivery?.deliver_method}</div> : ''}</td>
          <td>{order.status}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(order) }} id={order.id}>{translate(modalview.tag)}</Button>
            {(role === 'super' || role === 'admin') && transaction && !order.transactions && <Button size="sm" type='button' onClick={() => { transaction.onClick(order) }} id={order.id}>{translate(transaction.tag)}</Button>}
            {ccvtransaction && order.origin === 'CCVSHOP' && !order.transactions && <Button size="sm" type='button' onClick={() => { ccvtransaction.onClick(order) }} id={order.id}>{translate(ccvtransaction.tag)}</Button>}
            {exportPdf && <Button size="sm" type='button' onClick={() => { exportPdf.onClick(order) }} id={order.id}>{translate(exportPdf.tag)}</Button>}
            {pickingOrder && <Button size="sm" type='button' onClick={() => { pickingOrder.onClick(order) }}>{translate(pickingOrder.tag)}</Button>}
            {dpd && order?.origin === 'CCVSHOP' && order?.delivery && order?.ccv_order_id && <Button size="sm" type='button' onClick={() => { dpd.onClick(order) }}>{translate(dpd.tag)}</Button>}
          </td>
        </tr>
    )
  });

  return (
    <>
      {Object.keys(selected).length > 0 &&
        <Navbar expand="xxl" className="bg-body-tertiary">
          {Object.keys(selected).length} {translate('order(s) selected.')}
          <Dropdown className='actions-button'>
            <Dropdown.Toggle size='sm' type='button'>
              {translate('Actions')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {exportButton && <Dropdown.Item onClick={() => { exportButton.onClick() }}>{exportButton.tag}</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      }
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {orders && orders?.length > 0 &&
                <Form>
                  <Form.Check onChange={() => { buttons.selectAll.onClick(orders) }} checked={orders?.every((order) => selected.hasOwnProperty(order?.id))} />
                </Form>
              }
            </th>
            <th width='15%'>{translate('Order number')}</th>
            <th>{translate('Date')}</th>
            <th>{translate('Employee')}</th>
            <th className="text-end">{translate('Amount')}</th>
            <th>{translate('Origin')}</th>
            <th>{translate('Delivery')}</th>
            <th>{translate('Status')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <OrderModal modal={modalview.modal} />
      </Table>
    </>
  );
}