import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getWarehouseAdvice, setWarehouseAdvice } from "../../redux/dataSlices/warehouseSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PutWarehouseProductById from "../../actablueAPI/warehouse/PutWarehouseProductById";
import StockProductForm from "../../components/stockProductForm";
import useHandleError from "../../customhooks/useHandleError";
import { validateFormObject } from "../../helpers/validateFormObject";
import { StockProductFormFields } from "./stockProductFormFields";

const StockProductEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const product = useSelector(getWarehouseAdvice)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const [invalidFields, setInvalidFields] = useState([]);
  const navTitle = "Stockproduct edit"

  const onChange = (event) => {
    let newProduct = HandleOnChange({ event: event, object: product })
    dispatch(setWarehouseAdvice(newProduct))
  }

  const onLocationIdChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'location_id' })
    dispatch(setWarehouseAdvice(newProduct))
  }

  const onAdminIdChange = (event) => {
    let newProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: product, selectfield: 'administration_id' })
    delete newProduct.location_id
    dispatch(setWarehouseAdvice(newProduct))
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: product, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    let newProduct = {}
    newProduct.id = product.id
    newProduct.administration_id = product.administration_id
    newProduct.location_id = product.location_id
    newProduct.minimum_stock = product?.minimum_stock
    // newProduct.maximum_stock = product?.maximum_stock

    PutWarehouseProductById({ token: token, id: product.id, data: newProduct })
      .then(() => {
        navigate(-1);
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: StockProductFormFields(),
    field: {
      onChange: onChange
    },
    data: product,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item?.administration_id === product?.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const card = {
    size: 12,
    title: navTitle,
    data: <StockProductForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  );
};
export default StockProductEdit;
