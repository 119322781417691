import { Offcanvas } from 'react-bootstrap';

function OffcanvasSidebar(props) {
  return (
      <Offcanvas show={props.show} placement={props.placement} onHide={props.onHide}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{props.title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={props?.className}>
          {props.data}
        </Offcanvas.Body>
      </Offcanvas>
  );
}

export default OffcanvasSidebar;