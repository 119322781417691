import axios from "axios";

const PostUser = ({token, user}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/serials/user"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: user
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log("error:", error)
    });
  });
};

export default PostUser