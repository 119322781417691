import CalculatePayments from "./createPayments"

export default function CreateTransaction(order, invoice, payment_type) {
  let newOrder = JSON.parse(JSON.stringify(order))
  newOrder.is_paid = true

  let newInvoice = JSON.parse(JSON.stringify(invoice))
  newInvoice.status = 'COMPLETE'

  const {payments, amount_open, amount_exchange, amount_paid} = CalculatePayments(invoice, payment_type)

  let type = 'INTERNAL'
  if (order.origin === 'CCVSHOP') {
    type = 'CCVSHOP'
  }

  let newTransaction = {
    user_id: order.user_id,
    customer_id: order.customer_id,
    relation_id: order.relation_id,
    contact_id: order.contact_id,
    operator_id: order.operator_id,
    administration_id: order.administration_id,
    location_id: order.location_id,
    invoice: newInvoice,
    order: newOrder,
    payments: payments,
    amount_open: amount_open,
    amount_exchange: amount_exchange,
    amount_paid: amount_paid,
    type: 'INTERNAL',
    descript: 'created by Actablue portal'
  }

  return newTransaction
}