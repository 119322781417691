export const NarrowcastingFormFields = () => {

  const narrowcastingDeviceFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name', required: true },
    { type: 'select', name: `Screen Rotation`, id: 'screen_rotation', formdatafield: 'screen_rotation', required: true },
    { type: 'multiselect', name: `Schedules`, id: 'schedule', formdatafield: 'schedules' }
  ]

  return narrowcastingDeviceFormFields
}