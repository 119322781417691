import axios from "axios";

const GetReservationsListByLocationId = ({token, admin_id, location_id, start, end}) => {

  return new Promise ((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/reservations/reservation/list/location"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'location_id': location_id,
        'start': start,
        'end': end
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  })

};

export default GetReservationsListByLocationId
