import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { getReservation, getReservationLocation, setReservationTimelineModalShow } from '../redux/dataSlices/reservationSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Nav, Tab } from 'react-bootstrap';
import './css/modal.css'
import './css/productModal.css'

import JsonHighlighter from './serviceJsonHighlight';

import ReservationButtons from "../portal/reservations/reservationbuttons";
import TableButton from './tableButton';
import { useTranslation } from 'react-i18next';

export default function ReservationTimelineModal(props) {
  const reservation = useSelector(getReservation);
  const reservation_location = useSelector(getReservationLocation)
  const dispatch = useDispatch()
  const buttons = ReservationButtons.call()
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  const handleClose = () => dispatch(setReservationTimelineModalShow(false));

  if (reservation) {
    const accept = buttons.hasOwnProperty('accept') ? Object.assign({}, buttons.accept, { id: reservation.id }) : false
    const cancel = buttons.hasOwnProperty('cancel') ? Object.assign({}, buttons.cancel, { id: reservation.id }) : false
    const pending = buttons.hasOwnProperty('pending') ? Object.assign({}, buttons.pending, { id: reservation.id }) : false
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: reservation.id }) : false
    const status = reservation.status;
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
        centered scrollable>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Reservation ID: {reservation.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Reservation Details</h4>
                  <br />
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {reservation.reservation_name}</p>
                      <p>Phone: {reservation.reservation_phonenumber}</p>
                      <p>
                        {translate('Email')}: {reservation.reservation_email}
                      </p>
                      <p>People: {reservation.number_of_people}</p>
                    </div>
                    <div className="col">
                      <p>Time: {moment(reservation.reservation_timestamp).tz(reservation_location?.timezone || 'Europe/Amsterdam').format('DD/MM/YYYY HH:mm:ss')}</p>
                      <p>Status: {reservation.status}</p>
                      <p>Remark: {reservation.remark}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {reservation.id}</p>
                    </div>
                    <div className="col">

                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {reservation.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {reservation.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...reservation} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          {accept && status !== 'ACCEPTED' && <TableButton {...accept} />}
          {pending && status !== 'PENDING' && <TableButton {...pending} />}
          {cancel && status !== 'CANCELLED' && <TableButton {...cancel} />}
          {edit && <TableButton {...edit} />}
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}