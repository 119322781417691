import { Col, Form } from 'react-bootstrap';
import MultiSelectField from '../components/multiselectField';
import MultiSelectCreateField from '../components/multiselectcreateField';
import SingleSelectField from '../components/singleselectField';
import SingleSelectCreateField from '../components/singleselectcreateField';
import { useTranslation } from 'react-i18next';

export default function CreateFieldTable(field, data, buttons) {
  const { t: translate } = useTranslation();

  let checked = undefined
  if (data?.[field?.id] === true || data?.[field?.id] === 'true') {
    checked = true
  }

  let value = data?.[field?.id]

  let name = field.name
  let placeholder = field.placeholder

  if (field.type !== 'hidden') {
    name = translate(field.name, { keySeparator: ':', nsSeparator: ':' })
    placeholder = translate(field.placeholder ?? field.name)
  }

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  switch (field.type) {
    case 'hidden':
      return (
        <td>
          <Col sm="10">
            <Form.Control prefix={field.prefix}
              type={field.type}
              name={field.name}
              placeholder={field.placeholder}
              id={field?.id}
              value={value}
              onChange={
                buttons?.[field?.id]?.onChange ?
                  (event) => { buttons?.[field?.id]?.onChange(event, data) }
                  :
                  (event) => { buttons?.onChange(event, data) }
              }
              readOnly={field.readonly}
              disabled={field.disabled} />
          </Col>
        </td>
      )
    case 'checkbox':
      return (
        <td>
          <Col sm="10">
            <Form.Check
              prefix={field.prefix}
              reverse={field.reverse}
              type="switch"
              name={name}
              value={Boolean(value)}
              onChange={
                buttons?.[field?.id]?.onChange ?
                  (event) => { buttons?.[field?.id]?.onChange(event, data) }
                  :
                  (event) => { buttons?.onChange(event, data) }
              }
              id={field.id}
              disabled={field.disabled}
              checked={Boolean(checked)}
              isInvalid={field?.invalid} />
          </Col>
        </td>
      )
    case 'switch':
      return (
        <td>
          <Col sm="10">
            <Form.Check
              prefix={field.prefix}
              reverse={field.reverse}
              type="switch"
              name={name}
              value={Boolean(value)}
              onChange={
                buttons?.[field?.id]?.onChange ?
                  (event) => { buttons?.[field?.id]?.onChange(event, data) }
                  :
                  (event) => { buttons?.onChange(event, data) }
              }
              id={field.id}
              disabled={field.disabled}
              checked={Boolean(checked)}
              isInvalid={field?.invalid} />
          </Col>
        </td>
      )
    case 'select':
      return (
        <td>
          <Col sm="10">
            <SingleSelectField {...buttons?.[field.id]} prefix={field.prefix} name={name} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{translate(field?.invalidFeedback ?? 'Please fill in this field.')}</div>
            }
          </Col>
        </td>
      )
    case 'multiselect':
      return (
        <td>
          <Col sm="10">
            <MultiSelectField {...buttons?.[field.id]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{translate(field?.invalidFeedback ?? 'Please fill in this field.')}</div>
            }
          </Col>
        </td>
      )
    case 'multi+create':
      return (
        <td>
          <Col sm="10">
            <MultiSelectCreateField {...buttons?.[field.id]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{translate(field?.invalidFeedback ?? 'Please fill in this field.')}</div>
            }
          </Col>
        </td>
      )
    case 'select+create':
      return (
        <td>
          <Col sm="10">
            <SingleSelectCreateField {...buttons?.[field.id]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{translate(field?.invalidFeedback ?? 'Please fill in this field.')}</div>
            }
          </Col>
        </td>
      )
    case 'time':
      return (
        <td>
          <Col sm="10">
            <Form.Control prefix={field.prefix}
              type='time'
              name={name}
              id={field.id}
              accept={field.accept}
              placeholder={placeholder ?? name}
              value={value || ""}
              onChange={
                buttons?.[field?.id]?.onChange ?
                  (event) => { buttons?.[field?.id]?.onChange(event, data) }
                  :
                  (event) => { buttons?.onChange(event, data) }
              }
              readOnly={field.readonly}
              step={field.step}
              disabled={field.disabled}
              required={field.required ?? true}
              maxLength={field.maxlength}
              isInvalid={field?.invalid}
              inputMode={field.inputmode}
              onWheel={field.onWheel || numberInputOnWheelPreventChange}
            />
            <Form.Control.Feedback type="invalid">
              {translate(field?.invalidFeedback ?? 'Please fill in this field.')}
            </Form.Control.Feedback>
          </Col>
        </td>
      )
    case 'color':
      return (
        <td>
          <Col sm="1">
            <Form.Control prefix={field.prefix}
              type='color'
              name={name}
              id={field.id}
              accept={field.accept}
              placeholder={placeholder ?? name}
              value={value}
              onChange={
                buttons?.[field?.id]?.onChange ?
                  (event) => { buttons?.[field?.id]?.onChange(event, data) }
                  :
                  (event) => { buttons?.onChange(event, data) }
              }
              readOnly={field.readonly}
              step={field.step}
              disabled={field.disabled}
              required={field.required ?? true}
              maxLength={field.maxlength}
              isInvalid={field?.invalid}
              inputMode={field.inputmode}
              onWheel={field.onWheel || numberInputOnWheelPreventChange}
            />
            <Form.Control.Feedback type="invalid">
              {field?.invalidFeedback ?? 'Please fill in this field.'}
            </Form.Control.Feedback>
          </Col>
        </td>
      )
    case 'object':
      return
    default:
      return (
        <td>
          <Col sm="10">
            <Form.Control prefix={field.prefix}
              type={field.type}
              name={name}
              id={field.id}
              accept={field.accept}
              placeholder={placeholder ?? name}
              value={value === null ? "" : value}
              onChange={
                buttons?.[field?.id]?.onChange ?
                  (event) => { buttons?.[field?.id]?.onChange(event, data) }
                  :
                  (event) => { buttons?.onChange(event, data) }
              }
              readOnly={field.readonly}
              step={field.step}
              disabled={field.disabled}
              required={field.required ?? true}
              maxLength={field.maxlength}
              isInvalid={field?.invalid}
              inputMode={field.inputmode}
              onWheel={field.onWheel || numberInputOnWheelPreventChange}
            />
            <Form.Control.Feedback type="invalid">
              {translate(field?.invalidFeedback ?? 'Please fill in this field.')}
            </Form.Control.Feedback>
          </Col>
        </td>
      )
  }
}