import axios from "axios";
import moment from "moment"

const GetStatusReportRealtime = ({token, admin_id, location_id, location}) => {

  return new Promise ((resolve, reject) => {
    let year = moment().year()
    let month = moment().month() + 1 // returns index !!
    let day = moment().date()
    let timezone = location?.timezone ? location?.timezone : "Europe/Amsterdam"

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/reporting/v2/dailystatus/realtime"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        'tz': timezone,
        'year': year,
        'month': month,
        'day': day
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  })

};

export default GetStatusReportRealtime
