import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getSnelstartCompany } from '../redux/dataSlices/snelstartSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { useTranslation } from 'react-i18next';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';


export default function SnelstartModal(props) {
  const object = useSelector(getSnelstartCompany);
  const role = useSelector(getRole)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
          {translate('Snelstart info')}: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col-3">
                      <p>{translate('Name')}: {object.name || "N/A"}</p>
                      <p>{translate('Company name')}: {object.company_name || "N/A"}</p>
                      <p>
                        {translate('Email')}: {object.email || "N/A"}
                      </p>
                    </div>
                    <div className='col-3'></div>
                    <div className="col">
                      <p>{translate('Administration')}: {hash_admins_all[object.administration_id]?.name || "N/A"}</p>
                      <p>{translate('Location')}: {hash_locations_all[object?.location?.id]?.name || "N/A"}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate('Cash gap ledger')}: {object.cash_gap_ledger || "N/A"}</p>
                      <p>{translate('Default cash register')}: {object.default_cash_register || "N/A"}</p>
                      <p></p>
                    </div>
                    <div className="col">
                      <p>{translate('Write product')}: {JSON.stringify(object.write_product) || "N/A"}</p>
                      <p>{translate('Write order')}: {JSON.stringify(object.write_order) || "N/A"}</p>
                      <p>{translate('Write relation')}: {JSON.stringify(object.write_relation) || "N/A"}</p>
                      <p>{translate('Skip non active products')}: {JSON.stringify(object.skip_non_active_artikels) || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.snelstart_administratie_identifier}</p>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object?.location?.id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}