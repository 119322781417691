import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getLocation } from '../redux/dataSlices/locationSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';

import OpeningtimesTable from './openingtimesTable';
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getReservationLocation } from '../redux/dataSlices/reservationSlice';
import { getScopes } from '../redux/dataSlices/scopesSlice';
import { getPlanningLocation } from '../redux/dataSlices/planningSlice';


export default function LocationModal(props) {
  const object = useSelector(getLocation);
  const reservation_location = useSelector(getReservationLocation)
  const planning_location = useSelector(getPlanningLocation)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Location: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="details">Details</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="openingtimes">Opening Times</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="phonenumbers">Phonenumber</Nav.Link></Nav.Item>
              {scopes.reservations && reservation_location &&
                <>
                  <Nav.Item><Nav.Link eventKey="reservation-info">Reservation location info</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reservation-details">Reservation location details</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="areas">Areas</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="servicehours">Service Hours</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="widget">{translate('Widget information')}</Nav.Link></Nav.Item>
                </>
              }
              {scopes.planning && planning_location &&
                <>
                  <Nav.Item><Nav.Link eventKey="planning-info">Planning location info</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="planning-details">Planning location details</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="planning-openingtimes">Planning location opening times</Nav.Link></Nav.Item>
                </>
              }
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
              {service && scopes.reservations && reservation_location && <Nav.Item><Nav.Link eventKey="reservationservice">Reservation Service</Nav.Link></Nav.Item>}
              {service && scopes.planning && planning_location && <Nav.Item><Nav.Link eventKey="planningservice">Planning Service</Nav.Link></Nav.Item>}
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="info">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Location info</h4>
                  <div className="row">
                    <div className="col-3">
                      {object.hasOwnProperty('img_urls') &&
                        <>
                          {object.img_urls.map((url) => {
                            return (<img className="thumbnail" src={url} alt='' width='256'></img>)
                          })}
                        </>
                      }
                    </div>
                    <div className='col-3'></div>
                    <div className="col">
                      <p>Administration: {hash_admins_all[object.administration_id]?.name}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>Description: {object.description}</p>
                      <p>Webshop URL: {object.webshop_url}</p>
                      <p>Reservation URL: {object.reservation_url}</p>
                      <p>Menu URL: {object.menu_url}</p>
                    </div>
                    <div className="col">
                      {object.hasOwnProperty('address') &&
                        <>
                          <p>Country: {object.address.country}</p>
                          <p>Address: {object.address.street} {object.address.street_number} {object.address.street_number_additional}</p>
                          <p>Proverence: {object.address.proverence}</p>
                          <p>Place: {object.address.place}</p>
                          <p>Zip Code: {object.address.zip_code}</p>
                        </>
                      }

                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Delivery cost: {object.delivery_cost}</p>
                      <p>Delivery cost treshold: {object.delivery_cost_threshold}</p>
                      <p>Delivery range: {object.delivery_range}</p>
                    </div>
                    <div className="col">
                      <p>Timezone: {object.timezone}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="details">
                <div className="container text-center">
                  <div className="col">
                    <p>Eco: {JSON.stringify(object.eco)}</p>
                    <p>Can Rerservate: {JSON.stringify(object.can_reservate)}</p>
                    <p>Can Deliver: {JSON.stringify(object.can_deliver)}</p>
                    <p>Can Take out: {JSON.stringify(object.can_take_out)}</p>
                    <p>Price indication: {object.price_indication}</p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="openingtimes">
                <div className="container text-center">
                  <div className="col">
                    <OpeningtimesTable {...object.opening_times} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="phonenumbers">
                <div className="container text-center">
                  {object?.phonenumbers?.map((phone_number) => (
                    <>
                      <div className="row">
                        <div className="col">
                          <p>Type: {phone_number.type}</p>
                          <p>Phonenumber: {phone_number.number}</p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </Tab.Pane>
              {scopes.reservations && reservation_location &&
                <>
                  <Tab.Pane eventKey="reservation-info">
                    <br></br>
                    <div className="container text-center">
                      <h4 className='text-start'>Reservation location info</h4>
                      <div className="row">
                        <div className="col-3">
                          {reservation_location.hasOwnProperty('img_urls') &&
                            <>
                              {reservation_location.img_urls.map((url) => {
                                return (<img className="thumbnail" src={url} alt='' width='256'></img>)
                              })}
                            </>
                          }
                        </div>
                        <div className='col-3'></div>
                        <div className="col">
                          <p>Administration: {hash_admins_all[reservation_location.administration_id]?.name}</p>
                          <p>Application: {reservation_location?.application}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <p>Capacity: {reservation_location.capacity}</p>
                          <p>Duration: {reservation_location.duration}</p>
                          <p>Timezone: {reservation_location.timezone}</p>
                        </div>
                        <div className="col">
                          <p>Walk-in time: {reservation_location.walk_in_time}</p>
                          <p>Walk-out time: {reservation_location.walk_out_time}</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="reservation-details">
                    <div className="container text-center">
                      <div className="col">
                        <p>Eco: {JSON.stringify(reservation_location.eco)}</p>
                        <p>Can reserve events: {JSON.stringify(reservation_location.can_reserve_events)}</p>
                        <p>Automatically accept reservations: {JSON.stringify(reservation_location.automatically_accept_reservations)}</p>
                        <p>Automatically assign tables: {JSON.stringify(reservation_location.automatically_assign_tables)}</p>
                        <p>Precise automatic reservations: {JSON.stringify(reservation_location.precise_automatic_reservations)}</p>
                        <p>Is active: {JSON.stringify(reservation_location.is_active)}</p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="areas">
                    <div className="container text-center">
                      {reservation_location?.areas?.map((area) => (
                        <>
                          <div className="row">
                            <div className="col">
                              <p>{area}</p>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="servicehours">
                    <div className="container text-center">
                      <div className="col">
                        <OpeningtimesTable {...reservation_location.opening_times} />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="widget">
                    <div className="container text-center">
                      <div className="col">
                        <p>Location id: {reservation_location.id}</p>
                        <p>Administration id: {reservation_location.administration_id}</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </>
              }
              {scopes.planning && planning_location &&
                <>
                  <Tab.Pane eventKey="planning-info">
                    <br></br>
                    <div className="container text-center">
                      <h4 className='text-start'>Planning location info</h4>
                      <div className="row">
                        <div className="col">
                          <p>Administration: {hash_admins_all[planning_location.administration_id]?.name}</p>
                          <p>Application: {planning_location?.application}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <p>Capacity: {planning_location.capacity}</p>
                          <p>Travel Time: {planning_location.travel_time}</p>
                          <p>Duration: {planning_location.duration}</p>
                          <p>Timezone: {planning_location.timezone}</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="planning-details">
                    <div className="container text-center">
                      <div className="col">
                        <p>Eco: {JSON.stringify(planning_location.eco)}</p>
                        <p>Can reserve events: {JSON.stringify(planning_location.can_reserve_events)}</p>
                        <p>Automatically accept plannings: {JSON.stringify(planning_location.automatically_accept_plannings)}</p>
                        <p>Automatically assign tables: {JSON.stringify(planning_location.automatically_assign_tables)}</p>
                        <p>Precise automatic plannings: {JSON.stringify(planning_location.precise_automatic_plannings)}</p>
                        <p>Is active: {JSON.stringify(planning_location.is_active)}</p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="planning-openingtimes">
                    <div className="container text-center">
                      <div className="col">
                        <OpeningtimesTable {...planning_location.opening_times} />
                      </div>
                    </div>
                  </Tab.Pane>
                </>
              }
              {service &&
                <Tab.Pane eventKey="service">
                  <div className="container text-center">
                    <div className="row">
                      <div className="col">
                        <p>Id: {object.id}</p>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col">
                        <p>Administration: {object.administration_id}</p>
                      </div>
                    </div>
                    <div className="col">
                      <JsonHighlighter {...object} />
                    </div>
                  </div>
                </Tab.Pane>
              }

              {service && scopes.reservations && reservation_location &&
                <Tab.Pane eventKey="reservationservice">
                  <div className="container text-center">
                    <div className="row">
                      <div className="col">
                        <p>Id: {reservation_location.id}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p>Administration: {reservation_location.administration_id}</p>
                      </div>
                    </div>
                    <div className="col">
                      <JsonHighlighter {...reservation_location} />
                    </div>
                  </div>
                </Tab.Pane>
              }

              {service && scopes.planning && planning_location &&
                <Tab.Pane eventKey="planningservice">
                  <div className="container text-center">
                    <div className="row">
                      <div className="col">
                        <p>Id: {planning_location.id}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p>Administration: {planning_location.administration_id}</p>
                      </div>
                    </div>
                    <div className="col">
                      <JsonHighlighter {...planning_location} />
                    </div>
                  </div>
                </Tab.Pane>
              }

            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}