import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getHashRelationsAll } from '../redux/dataSlices/relationSlice';

export default function SuppliersEditTable(props) {
  const relations = useSelector(getHashRelationsAll);
  const suppliers = props?.suppliers
  const edit = suppliers.hasOwnProperty('edit') ? Object.assign({}, suppliers.edit) : false

  let rows = []
  const { t: translate } = useTranslation();

  let selected = props.suppliers?.selected ?? []

  suppliers?.data?.map((supplier) => (
    rows.push(
      <tr key={supplier?.id} className='product-row'>
        <td><Form.Check onChange={props?.suppliers?.check?.onChange} id={supplier.id} /></td>
        <td>{relations[supplier?.relation_id]?.name || 'Could not load name...'}</td>
        <td align="right">{Number(supplier.supplier_price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td>
          {supplier?.supplier_artikel_code}
        </td>
        <td>
          {edit && <Button size="sm" type='button' onClick={(event) => { edit.onClick(event, supplier?.id) }} id={supplier?.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  ))

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th width='1%'></th>
            <th width='25%'>{translate('Supplier')}</th>
            <th width='25%' className="text-end">{translate('Price')}</th>
            <th width='20%'>{translate('Supplier product code')}</th>
            <th width='20%'>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <Button variant="primary" className='mt-3' type={props.suppliers?.add?.type} onClick={props.suppliers?.add?.onClick} name={props.suppliers?.add?.name}>
        {props.suppliers?.add?.title}
      </Button>
      <Button variant="primary" className='mt-3' type={props.suppliers?.remove?.type} onClick={props.suppliers?.remove?.onClick} name={props.suppliers?.remove?.name} disabled={selected.length === 0 ? true : false}>
        {props.suppliers?.remove?.title}
      </Button>
    </>
  );
}