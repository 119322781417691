export default function HandleOnChangeMultiSelectFieldWithObjects(props) {
  const event = props.event
  const object = props.object || {}
  const selectfield = props.selectfield || props.event.id
  const hash = props.hash

  let newObject = JSON.parse(JSON.stringify(object))
  // let newObject = Object.assign({}, object)
  let excistingSelection = []
  let newSelection = []
  
  // clear newobject selectfield
  newObject[selectfield] = []
  // create array with excisting id's  
  if (object.hasOwnProperty(selectfield)) {
    // eslint-disable-next-line
    object[selectfield]?.map((item) => {
      excistingSelection.push(item.id)
    })
  }

  // create array with new id's
  event.forEach(target => {
    newSelection.push(target.value)
  });

  // check for id's that stay or must be deleted
  if (object.hasOwnProperty(selectfield)) {
    // eslint-disable-next-line
    object[selectfield]?.map((selected) => {
      if (newSelection.includes(selected.id)) {
        newObject[selectfield].push(selected)
      }
    })
  }

  // check for new id's, add them to object.selectfield
  event.forEach(target => {
    if (!excistingSelection.includes(target.value)) {
      newObject[selectfield].push(hash[target.value])    
    }
  });

  return (newObject)
}