import axios from "axios";

const DeletePrinter = ({ token, id, admin_id, location_id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_ENV}/kitchenmanager/printer`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'id': id,
        'admin_id': admin_id,
        'location_id': location_id
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default DeletePrinter