export default function HandleOnChangeEans(props) {
    const event = props.event
    const object = props.object || {}
    
  let newObject = JSON.parse(JSON.stringify(object))
  // let newObject = Object.assign({}, object)
    let excistingEans = []
    let newEans = []

    // clear newProduct eans
    newObject.eans = []

    // create array with excisting eans  
    if (object.hasOwnProperty('eans')) {
      // eslint-disable-next-line
      object.eans.map((ean) => {
        excistingEans.push(ean.ean_code)
      })
    }
    
    // create array with new eans
    event.forEach(target => {
      newEans.push(target.value)
    });

    // check for eans that stay or must be deleted
    if (object.hasOwnProperty('eans')) {
      // eslint-disable-next-line
      object.eans.map((ean) => {
        if (newEans.includes(ean.ean_code)) {
          newObject.eans.push(ean)    
        }
      })
    }

    // check for new eans, add them to product.eans
    event.forEach(target => {
      if (!excistingEans.includes(target.value)) {
        newObject.eans.push({'ean_code':target.value})    
      }
    });

    return (newObject)
}