import axios from "axios";

const GetTransactionOrderById = ({token, id, admin_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/invoicepayments/transaction/order/${id}`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json',
                'administration': admin_id
      },
    })
    .then(response => {
      resolve(response.data)
    })
    .catch(error => {
      console.log("error:", error)
    });
  });
};

export default GetTransactionOrderById