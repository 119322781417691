import { createSlice } from "@reduxjs/toolkit";

export const exportSlice = createSlice({
  name: "export",
  initialState: {
    hash_export_profiles_all: {},
    match_columns_form: {},
    export_profile: {}
  },
  reducers: {
    setTaskId: (state, action) => {
      state.task_id = action.payload;
    },
    setTaskData: (state, action) => {
      state.task_data = action.payload
    },
    setExportModalShow: (state, action) => {
      state.export_modal_show = action.payload
    },
    setExportProfileModalShow: (state, action) => {
      state.export_modal_profile_show = action.payload
    },
    setExportProfiles: (state, action) => {
      state.export_profiles = action.payload
      action?.payload?.forEach(profile => {
        state.hash_export_profiles_all[profile.id] = profile
      })
    },
    setExportProfile: (state, action) => {
      state.export_profile = action.payload
    },
    setMatchColumnsForm: (state, action) => {
      state.match_columns_form = action.payload
    },
    setExportSaveModalShow: (state, action) => {
      state.export_save_modal_show = action.payload
    },
    
  }
});

export const {
  setTaskId, setTaskData, setExportModalShow, setExportProfile, setExportProfiles, setMatchColumnsForm, setExportSaveModalShow, setExportProfileModalShow
} = exportSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getTaskId = (state) => state.export.task_id
export const getTaskData = (state) => state.export.task_data
export const getExportModalShow = (state) => state.export.export_modal_show
export const getExportProfileModalShow = (state) => state.export.export_modal_profile_show
export const getExportSaveModalShow = (state) => state.export.export_save_modal_show
export const getExportProfile = (state) => state.export.export_profile
export const getExportProfiles = (state) => state.export.export_profiles
export const getHashExportProfiles = (state) => state.export.hash_export_profiles_all
export const getMatchColumnsForm = (state) => state.export.match_columns_form

export default exportSlice.reducer;