import { Button, Table } from 'react-bootstrap';
import AttributeGroupButtons from '../portal/attributes/attributegroupbuttons';
import PaginationAndSizeComponent from './paginationAndSize';
import TableButton from './tableButton';

import AttributeGroupModal from './attributegroupModal';
import { useTranslation } from 'react-i18next';

export default function AttributeGroupTable(attributeGroups) {
  const buttons = AttributeGroupButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let pages = attributeGroups?.total_pages
  let page = attributeGroups?.number + 1

  let rows = []
  rows = attributeGroups?.content?.map((attributeGroup) => {
    return (
      <tr key={attributeGroup.id}>
        <td>{attributeGroup.name}</td>
        <td>{attributeGroup.type}</td>
        <td>
          <p>
            {translate('Created')}: {new Date(attributeGroup.creation).toLocaleString()}
          </p>
          <p>
            {translate('Modified')}: {new Date(attributeGroup.modification).toLocaleString()}
          </p>
        </td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(attributeGroup) }}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(attributeGroup) }}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  })

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <TableButton {...add} />}
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Type')}
            </th>
            <th>
              {translate('Date')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <AttributeGroupModal modal={modalview.modal} />
    </>
  );
}
