import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function SelectFieldOnchangeWithData(props) {
  const selectfield = props.selectfield
  const data = props.data
  const { t: translate } = useTranslation();
  const options = selectfield.options
  const selected = selectfield.selected ? { value: selectfield.selected.id, label: selectfield.selected.name } : null

  if (options) {
    return (
      <Select
        aria-label={selectfield.label}
        onChange={(event) => { selectfield.onChange(event, data) }}
        width={selectfield.width}
        className={selectfield.className}
        options={options.length > 0 && options.map((option) => ({ value: option.id, label: option.name }))}
        value={selected}
        defaultValue={selected}
        placeholder={selectfield?.placeholder ? <div className='select-placeholder'>{translate(selectfield.placeholder)}</div> : translate('Select...')}
        isClearable={selectfield.clearable}
        isSearchable={selectfield.searchable}
        isLoading={selectfield.loading}
        isDisabled={selectfield.loading}
      />
    );
  }
  return (
    <Select
      aria-label={selectfield.label}
      onChange={selectfield.onChange}
      width={selectfield.width}
      className={selectfield.className}
      value={null}
      defaultValue={null}
      placeholder={selectfield?.placeholder ? <div className='select-placeholder'>{translate(selectfield.placeholder)}</div> : translate('Select...')}
      isDisabled={true}
    />
  );

}

export default SelectFieldOnchangeWithData;