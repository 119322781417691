import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getWarehouse, setWarehouse } from "../../redux/dataSlices/warehouseSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import WarehouseForm from "../../components/warehouseForm";
import WarehouseFormFields from "./warehouseFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PutWarehouseWarehouseById from "../../actablueAPI/warehouse/PutWarehouseWarehouseById.jsx";

const WarehouseEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const warehouse = useSelector(getWarehouse)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (event) => {
    let newWarehouse = HandleOnChange({ event: event, object: warehouse })
    dispatch(dispatch(setWarehouse(newWarehouse)))
  }

  const onLocationIdChange = (event) => {
    let newWarehouse = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: warehouse, selectfield: 'location_id' })
    dispatch(setWarehouse(newWarehouse))
  }

  const onAdminIdChange = (event) => {
    let newWarehouse = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: warehouse, selectfield: 'administration_id' })
    delete newWarehouse.location_id
    dispatch(dispatch(setWarehouse(newWarehouse)))
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    let newWarehouse = JSON.parse(JSON.stringify(warehouse))
    delete newWarehouse.racks
    PutWarehouseWarehouseById({token:token, data:newWarehouse})
    .then(response => {
      navigate("/warehouse");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: WarehouseFormFields(),
    field: {
      onChange: onChange
    },
    data: warehouse,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins.filter((item) => { if (item.administration_id === warehouse.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: "Warehouse edit ".concat(warehouse.name),
    data: <WarehouseForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Warehouse edit"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default WarehouseEdit;

