import { Pie } from 'react-chartjs-2';

export const transactionAnalyseGraph = (report) => {
  let graphCardData = <p>No data</p>

  if (Object.keys(report).length > 0) {

    let graphLabels = []
    let totalData = []
    let percentageData = []
    let backGroundColor = []
    let borderColor = []

    for (const countryData of Object.values(report).sort((a, b) => b.percentage - a.percentage)) {
      if (countryData.hasOwnProperty('country')) {
        let color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
        graphLabels.push(countryData.country)
        totalData.push(countryData.revenue)
        percentageData.push(countryData.percentage.toFixed(2))
  
        backGroundColor.push(color)
        borderColor.push(color)
      }
    }
    
    const graphDatasetAmount = [
      {
        label: 'Value',
        data: totalData,
        borderColor: borderColor,
        backgroundColor: backGroundColor,
      },
    ]

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
        }
      },
    };

    const dataAmount = {
      labels: graphLabels,
      datasets: graphDatasetAmount
    }

    graphCardData =  <div className='pie-container'><Pie options={options} data={dataAmount} /></div> //<Pie options={options} data={dataQuantity} />
  }

  return graphCardData
};