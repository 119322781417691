import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from '../../redux/dataSlices/tokenSlice';
import { getCcvshopWebhookModalShow, setCcvshopWebhookModalShow, setCcvshopWebhook, } from "../../redux/dataSlices/ccvshopSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import DeleteCcvshopWebhookById from "../../actablueAPI/ccvshop/DeleteCcvshopWebhookById";

export default function CcvshopWebhookButtons() {
  const token = useSelector(getToken)
  const admin_id = useSelector(getSelectedAdmin_id)
  const modalShow = useSelector(getCcvshopWebhookModalShow)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(ccvshopWebhook) {
    dispatch(setCcvshopWebhook(ccvshopWebhook));
    dispatch(setCcvshopWebhookModalShow(true));
  };

  // function onclickEdit (ccvshopWebhook) {
  //   dispatch(setCcvshopWebhook(ccvshopWebhook));
  //   navigate("/ccvshopwebhook/edit");
  // };

  function onclickDelete(ccvshopWebhook) {
    DeleteCcvshopWebhookById({token:token, admin_id:admin_id, id:ccvshopWebhook.id})
    .then(response => {
      navigate("/ccvshops");
    })
  };

  const onViewHide = () => {
    dispatch(setCcvshopWebhookModalShow(false));
  };

  const onclickAdd = () => {
    navigate("/ccvshopwebhook/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.ccvshopwebhooks >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.ccvshopwebhooks >= 3) {
    buttons.delete = {
      tag: 'Delete',
      value: 'del',
      onClick: onclickDelete
    }
  }

  return buttons
}

