import axios from "axios";

const GetWarehouseWarehouse = ({token, admin_id, location_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/warehouse/warehouse`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id,
        'location': location_id
      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetWarehouseWarehouse