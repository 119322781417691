import React, { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getAttributeGroup, setAttributeGroup } from "../../redux/dataSlices/attributeSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import AttributeGroupForm from "../../components/attributegroupForm";
import { AttributeGroupFormFields } from "./attributegroupFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import PostAttribute from "../../actablueAPI/products/PostAttribute";
import AttributeProductTable from "../../components/attributeProductTable";
import AttributeProductToAddTable from "../../components/attributeProductToAddTable";
import { attributegroupAddProductFilterChoices, attributegroupTypeChoices } from "../../selectfieldchoices/attributegroupAddProductType.mjs";
import PostProductSearch from "../../actablueAPI/products/PostProductsSearch";
import GetProductScanLive from "../../actablueAPI/products/GetProductsScanLive";
import HandleOnChangeArrayWithObjects from "../../helpers/handleOnChangeArrayWithObjects";
import useHandleError from "../../customhooks/useHandleError";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";

const AttributeGroupEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const attributeGroup = useSelector(getAttributeGroup)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productSearchResult, setProductSearchResult] = useState([])
  const [productsToAdd, setProductsToAdd] = useState([])
  const [filter, setFilter] = useState('article')
  const [search, setSearch] = useState()
  const HandleError = useHandleError();

  function FilterProducts(products, attributeGroup) {
    // filter newProducts (remove already added ones), and remove double finds in sku/eans
    // first unique
    let unique = [...new Set(products)]
    // filter out the already added ones.
    let filteredProducts = []
    for (const product of unique) {
      let alreadyAdded = false
      for (const addedProduct of attributeGroup.products) {
        if (addedProduct.id === product.id) {
          alreadyAdded = true
        }
      }
      if (!alreadyAdded) {
        filteredProducts.push(product)
      }
    }
    return filteredProducts
  }

  const onChange = (event) => {
    let newAttributeGroup = HandleOnChange({ event: event, object: attributeGroup })
    dispatch(setAttributeGroup(newAttributeGroup))
  }

  const onSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const onSearchKeyDown = (key) => {
    if (key.keyCode === 13) {
      onSearchClick()
    }
  }

  const onTypeChange = (event) => {
    let newAttributeGroup = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: attributeGroup, selectfield: 'type' })
    dispatch(setAttributeGroup(newAttributeGroup))
  }

  const onSearchClick = () => {
    if (search !== '') {
      const searchDataObject = {
        'text': search,
        'fuzziness': 2,
        'type': filter,
        'fields': ['name', 'skuCode'],
        'limit': 25
      }
      axios.all([
        PostProductSearch({ token: token, admin_id: admin_id, location_id: location_id, data: searchDataObject }),
        GetProductScanLive({ token: token, admin_id: admin_id, location_id: location_id, ean: search })
      ])
        .then(axios.spread((searchResponse, scanResponse) => {
          let newProducts = [...searchResponse.data, ...scanResponse.data]
          setProductSearchResult(newProducts)
          setProductsToAdd(FilterProducts(newProducts, attributeGroup))
        }))
        .catch(error => { HandleError({ error: error }) })
        .finally(() => { dispatch(setLoading(false)) })
    }
  }

  const onFilterChange = (event) => {
    if (event) {
      setFilter(event.value)
    } else {
      setFilter('article')
    }
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    console.log('attributegroup', attributeGroup)
    PostAttribute({ token: token, admin_id: admin_id, attributeGroup: attributeGroup })
      .then(response => {
        navigate(-1);
      })
      .catch(error => {
        HandleError({ error: error })
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: AttributeGroupFormFields(),
    field: {
      onChange: onChange
    },
    data: attributeGroup,
    type: {
      label: 'type',
      options: attributegroupTypeChoices,
      selected: { value: 'ADDON', label: 'ADDON' },
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
      onClick: onSearchClick,
      onKeyDown: onSearchKeyDown,
    },
    filter: {
      label: 'Filter by:',
      selected: { 'id': filter, 'name': filter },
      options: attributegroupAddProductFilterChoices,
      onChange: onFilterChange,
      clearable: Boolean(false)
    }
  }

  const onAddClick = (product) => {
    let newAttributeGroup = HandleOnChangeArrayWithObjects({ event: product, object: attributeGroup, selectfield: 'products', action: 'add' })
    dispatch(setAttributeGroup(newAttributeGroup))
    setProductsToAdd(FilterProducts(productSearchResult, newAttributeGroup))
  }

  const onRemoveClick = (product) => {
    let newAttributeGroup = HandleOnChangeArrayWithObjects({ event: product, object: attributeGroup, selectfield: 'products', action: 'remove' })
    dispatch(setAttributeGroup(newAttributeGroup))
    setProductsToAdd(FilterProducts(productSearchResult, newAttributeGroup))
  }

  const addButton = {
    tag: '+',
    onClick: onAddClick
  }

  const removeButton = {
    tag: '-',
    onClick: onRemoveClick
  }

  const editCard = {
    size: 12,
    title: `Attribute group edit ${attributeGroup.name}`,
    data: <AttributeGroupForm {...formData} />,
  };

  const productToAddCard = {
    size: 6,
    title: 'Products to add',
    data: <AttributeProductToAddTable searchAndFilter={searchAndFilter} products={productsToAdd} addButton={addButton} />,
  };

  const productCard = {
    size: 6,
    title: 'Products',
    data: <AttributeProductTable products={attributeGroup.products} removeButton={removeButton} />,
  };

  return (
    <>
      <BasicCard {...editCard} />
      <BasicCard {...productCard} />
      <BasicCard {...productToAddCard} />
    </>
  )

};
export default AttributeGroupEdit;

