import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

export default function AddressesEditTable(props) {
  const { t: translate } = useTranslation();

  let rows = []
  if (props.data.hasOwnProperty('addresses')) {
    props.data.addresses.forEach(element => {
      rows.push(
        <tr key={element.id}>
          {/* <td className='col-1'></td> */}
          <td className='col-2'>
            <Form.Control prefix={element.id}
              type='text'
              name='street'
              placeholder={translate('Street')}
              value={element.street}
              onChange={props.addresses.onChange}
            />
          </td>
          <td className='col-1'>
            <Form.Control prefix={element.id}
              type='number'
              name='street_number'
              placeholder={translate('Street number')}
              value={element.street_number}
              onChange={props.addresses.onChange}
            />
          </td>
          <td className='col-1'>
            <Form.Control prefix={element.id}
              type='text'
              name='street_number_additional'
              placeholder={translate('Additional')}
              value={element.street_number_additional}
              onChange={props.addresses.onChange}
            />
          </td>
          <td className='col-1'>
            <Form.Control prefix={element.id}
              type='text'
              name='zip_code'
              placeholder={translate('Zipcode')}
              value={element.zip_code}
              onChange={props.addresses.onChange}
            />
          </td>
          <td className='col-2'>
            <Form.Control prefix={element.id}
              type='text'
              name='place'
              placeholder={translate('Place')}
              value={element.place}
              onChange={props.addresses.onChange}
            />
          </td>
          <td className='col-2'>
            <Form.Control prefix={element.id}
              type='text'
              name='proverence'
              placeholder={translate('Proverence')}
              value={element.proverence}
              onChange={props.addresses.onChange}
            />
          </td>
          <td className='col-2'>
            <Form.Control prefix={element.id}
              type='text'
              name='country'
              placeholder={translate('Country')}
              value={element.country}
              onChange={props.addresses.onChange}
            />
          </td>
          {/* <td className='col'>
          </td> */}
        </tr>
      )
    });
  }

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th className='col-2'>
              {translate('Street')}
            </th>
            <th className='col-1'>
              {translate('Street number')}
            </th>
            <th className='col-1'>
              {translate('Additional')}
            </th>
            <th className='col-1'>
              {translate('Zipcode')}
            </th>
            <th className='col-2'>
              {translate('Place')}
            </th>
            <th className='col-2'>
              {translate('Proverence')}
            </th>
            <th className='col-2'>
              {translate('Country')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <Button variant="primary" className='mt-3' type={props.addresses.add.type} onClick={props.addresses.add.onClick} name={props.addresses.add.name}>
          {translate(props.addresses.add.title)}
        </Button>
      </Table>
    </>
  );
}