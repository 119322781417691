import * as XLSX from 'xlsx/xlsx.mjs';
import { setTaskData } from "../redux/dataSlices/analyseSlice";
import { setProgressModalShow } from "../redux/dataSlices/analyseSlice";

export const PurchaseAdviceExport = ({ adviceList, relation_id, hashRelationsAll, dispatch }) => {
  const dateNow = new Date();
  let headers = ['Name', 'Minimum stock', 'Stock', 'Purchase advice', 'Order unit', 'Date Generated'];
  let data;
  let current = 1;

  // If there's no specific supplier selected
  if (!relation_id) {
    // Track unique supplier ids to avoid duplicate headers
    const existingSupplierIds = new Set();

    // Loop over each product to add headers for unique suppliers only
    adviceList?.forEach(product => {
      product.suppliers?.forEach((supplier) => {
        if (!existingSupplierIds.has(supplier.relation_id)) {
          headers.splice(1, 0, `${hashRelationsAll?.[supplier.relation_id]?.name} Article Code`);
          headers.splice(1, 0, `${hashRelationsAll?.[supplier.relation_id]?.name} Price`);
          existingSupplierIds.add(supplier.relation_id);
        }
      });
    });

    // Create rows from the advice data
    dispatch(setProgressModalShow(true))
    const rows = adviceList?.map((product) => {
      let task_data = {
        current: current,
        total: adviceList?.length ?? 0,
        progress: current === 0 ? 0 : current / adviceList?.length
      };

      dispatch(setTaskData(task_data))

      let row = [
        product.name,
        product.minimum_stock,
        // product.maximum_stock,
        product.total_quantity,
        product.purchase_advice,
        product.packaging ?? "1",
        dateNow.toLocaleString()
      ];

      // Add supplier data
      existingSupplierIds.forEach(supplierId => {
        const supplier = product.suppliers?.find(supplier => supplier?.relation_id === supplierId);
        row.splice(1, 0, supplier?.supplier_artikel_code);
        row.splice(1, 0, supplier?.supplier_price);
      });

      current++;
      return row;
    });

    data = [headers, ...rows];
  } else {
    // If a specific supplier is selected
    headers.splice(1, 0, `Supplier Article Code`)
    headers.splice(1, 0, `Supplier Price`)

    const rows = adviceList?.map(product => {
      let task_data = {
        current: current,
        total: adviceList?.length ?? 0,
        progress: current === 0 ? 0 : current / adviceList?.length
      };

      dispatch(setTaskData(task_data))

      let row = [
        product.name,
        product.minimum_stock,
        // product.maximum_stock,
        product.total_quantity,
        product.purchase_advice,
        product.packaging ?? "1",
        dateNow.toLocaleString()
      ];

      // Add supplier data
      const supplierIndex = product?.suppliers?.findIndex(supplier => supplier?.relation_id === relation_id)
      const supplier = product.suppliers?.[supplierIndex];
      row.splice(1, 0, supplier?.supplier_artikel_code);
      row.splice(1, 0, supplier?.supplier_price);

      current++;
      return row;
    });

    data = [headers, ...rows];
  }

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

  dispatch(setProgressModalShow(false));
  if (!relation_id) XLSX.writeFile(workbook, 'Purchase Advice.xlsx');
  if (relation_id) XLSX.writeFile(workbook, `Purchase Advice ${hashRelationsAll[relation_id]?.name}.xlsx`)

};