import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getRelation } from '../redux/dataSlices/relationSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { useTranslation } from 'react-i18next';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';


export default function RelationModal(props) {
  const object = useSelector(getRelation);
  const role = useSelector(getRole)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            {translate('relation_modal_title', {relation: object?.name})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="addresses">Address</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="phonenumbers">Phonenumber</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="emails">{translate('Email')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Administration')}: {hash_admins_all[object.administration_id]?.name}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="addresses">
                <div className="container text-center">
                  {object?.addresses?.map((address) => (
                    <div className="row">
                      <div className="col">
                        <p>{translate('Type')}: {address.type}</p>
                        <p>{translate('Address')}: {address.street} {address.street_number} {address.additional_street_number}</p>
                        <p>{translate('Zipcode')}: {address.zip_code} {address.place}</p>
                        <p>{translate('Country')}: {address.country}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="phonenumbers">
                <div className="container text-center">
                  {object?.phone_numbers?.map((phone_number) => (
                    <>
                      <div className="row">
                        <div className="col">
                          <p>{translate('Type')}: {phone_number.type}</p>
                          <p>{translate('Phonenumber')}: {phone_number.number}</p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="emails">
                <div className="container text-center">
                  {object?.emails?.map((email) => (
                    <>
                      <div className="row">
                        <div className="col">
                          <p>{translate('Type')}: {email.type}</p>
                          <p>{translate('Email')}: {email.email}</p>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                    <div className="col">

                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}