export default function CreateInvoice(order) {
  // create invoice_lines
  let invoice_lines = []
  let orderlinesAmountExclusiveVat = 0
  let orderlinesAmountInclusiveVat = 0
  if (order.order_lines) {
    for (const orderline of order.order_lines) {
      let newOrderline = JSON.parse(JSON.stringify(orderline))
      delete newOrderline.id
      // check presence of orderline vat_percentage
      if (!orderline.hasOwnProperty('vat_percentage')) {
        newOrderline.vat_percentage = 0
      }
      // check presence of orderline price_inclusive_vat
      if (!orderline.hasOwnProperty('price_inclusive_vat')) {
        newOrderline.price_inclusive_vat = orderline.price_exclusive_vat
        if (orderline.vat_percentage !== 0 && orderline.price_exclusive_vat && orderline.price_exclusive_vat !== 0) {
          newOrderline.price_inclusive_vat = String((Number(orderline.price_exclusive_vat) * ((100+orderline.vat_percentage)/100)).toFixed(4))
        }
      }
      // check presence of orderlines price_exclusive_vat
      if (!orderline.hasOwnProperty('price_exclusive_vat')) {
        newOrderline.price_exclusive_vat = orderline.price_inclusive_vat
        if (orderline.vat_percentage !== 0 && orderline.price_inclusive_vat && orderline.price_inclusive_vat !== 0) {
          newOrderline.price_exclusive_vat = String((Number(orderline.price_inclusive_vat) / ((100+orderline.vat_percentage)/100)).toFixed(4))
        }
      }
      // check presence of orderlines amount_inclusive_vat
      if (!orderline.hasOwnProperty('amount_inclusive_vat')) {
        newOrderline.amount_inclusive_vat = String(0)
        if (orderline.quantity !== 0) {
          if (orderline.price_inclusive_vat && orderline.price_inclusive_vat !== 0) {
            newOrderline.amount_inclusive_vat = String((Number(orderline.price_inclusive_vat) * orderline.quantity).toFixed(4))
          }
          // no orderline.price_inclusive_vat ? try fix it with price_exclusive_vat
          if (!orderline.price_inclusive_vat && orderline.price_exclusive_vat && orderline.price_exclusive_vat !== 0) {
            if (orderline.vat_percentage && orderline.vat_percentage !== 0) {
              newOrderline.amount_inclusive_vat = String(((Number(orderline.price_exclusive_vat) * ((100+orderline.vat_percentage)/100)) * orderline.quantity).toFixed(4))
            }
            if (!orderline.vat_percentage || orderline.vat_percentage === 0) {
              newOrderline.amount_inclusive_vat = String((Number(orderline.price_exclusive_vat) * orderline.quantity).toFixed(4))
            }
          }
          
        }
      }
      // check presence of orderlines amount_exclusive_vat
      if (!orderline.hasOwnProperty('amount_exclusive_vat')) {
        newOrderline.amount_exclusive_vat = String(0)
        if (orderline.quantity !== 0) {
          if (orderline.price_exclusive_vat && orderline.price_exclusive_vat !== 0) {
            newOrderline.amount_exclusive_vat = String((Number(orderline.price_exclusive_vat) * orderline.quantity).toFixed(4))
          }
          // no orderline.price_exclusive_vat ? try fix it with price_inclusive_vat
          if (!orderline.price_exclusive_vat && orderline.price_inclusive_vat && orderline.price_inclusive_vat !== 0) {
            if (orderline.vat_percentage && orderline.vat_percentage !== 0) {
              newOrderline.amount_exclusive_vat = String(((Number(orderline.price_inclusive_vat) / ((100+orderline.vat_percentage)/100)) * orderline.quantity).toFixed(4))
            }
            if (!orderline.vat_percentage || orderline.vat_percentage === 0) {
              newOrderline.amount_exclusive_vat = String((Number(orderline.price_inclusive_vat) * orderline.quantity).toFixed(4))
            }
          }
        }
      }
      orderlinesAmountInclusiveVat += Number(orderline.amount_inclusive_vat || newOrderline.amount_inclusive_vat)
      orderlinesAmountExclusiveVat += Number(orderline.amount_exclusive_vat || newOrderline.amount_exclusive_vat)
      invoice_lines.push(newOrderline)
    }
  }

  let newInvoice = {
    user_id: order.user_id,
    customer_id: order.customer_id,
    relation_id: order.relation_id,
    contact_id: order.contact_id,
    administration_id: order.administration_id,
    location_id: order.location_id,
    name: order.name,
    operator_id: order.operator_id,
    payment_amount_exclusive_vat: order.payment_amount_exclusive_vat || String(orderlinesAmountExclusiveVat.toFixed(4)),
    payment_amount_inclusive_vat: order.payment_amount_inclusive_vat || String(orderlinesAmountInclusiveVat.toFixed(4)),
    status: 'OPEN',
    invoice_lines: invoice_lines,
    descript: order.descript,
    origin: 'INTERNAL',
  }
  return newInvoice
}