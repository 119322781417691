import axios from "axios";

const DeleteOnlinePaymtentsProfile = ({token, admin_id, location_id, type, id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_ENV}/online-payments/paymentprofile`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id,
        'location': location_id,
        'sourcetype': type,
        'id': id
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default DeleteOnlinePaymtentsProfile