import axios from "axios";

const PostCcvshopCcvKeys = ({ token, admin_id, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/ccvshop/ccv/keys`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administrationId': admin_id
      },
      data: data
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default PostCcvshopCcvKeys