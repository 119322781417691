import moment from 'moment-timezone';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import ReservationButtons from "../portal/reservations/reservationbuttons";

import { useTranslation } from 'react-i18next';
import ReservationAssignModal from './reservationAssignModal';
import { useSelector } from 'react-redux';
import { getReservationLocation } from '../redux/dataSlices/reservationSlice';

function ReservationWithoutObjectTable(props) {
  const reservation_location = useSelector(getReservationLocation)
  const reservationButtons = ReservationButtons.call()
  const withoutObjectModalView = reservationButtons.hasOwnProperty('withoutObjectModalView') ? Object.assign({}, reservationButtons.withoutObjectModalView) : false
  const { t: translate } = useTranslation();

  let rows = []

  props.reservations.forEach(reservation => {
    rows.push(
      <tr key={reservation.id}>
        {/* <td>
          <Form>
            <Form.Check />
          </Form>
        </td>
        <td></td> */}
        <td>{reservation.reservation_name}</td>
        {/* <td>{moment(reservation.reservation_timestamp).utc().format('DD/MM/YYYY HH:mm:ss')}</td> */}
        {/* <td>{moment(reservation.reservation_timestamp).local().format('DD/MM/YYYY HH:mm:ss')}</td> */}
        {/* <td>{moment(reservation.reservation_timestamp).tz('Europe/Amsterdam').format('DD/MM/YYYY HH:mm:ss')}</td> */}
        <td>{moment(reservation.reservation_timestamp).tz(reservation_location?.timezone || 'Europe/Amsterdam').format('DD/MM/YYYY HH:mm:ss')}</td>
        <td>{reservation.number_of_people}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { withoutObjectModalView.onClick(reservation) }} id={reservation.id}>{withoutObjectModalView.tag}</Button>
        </td>
      </tr>
    )

  });

  return (
    <Table width='100%'>
      <thead>
        <tr>
          {/* <th></th>
          <th>+</th> */}
          <th>
            {translate('Name')}
          </th>
          <th>
            {translate('Time')}
          </th>
          <th>
            {translate('People')}
          </th>
          <th>
            {translate('Action')}
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
      <ReservationAssignModal modal={withoutObjectModalView.modal} />
    </Table>
  );
}

export default ReservationWithoutObjectTable;