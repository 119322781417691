import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getOnlinePaymentModalShow, getRefresh, setOnlinePayment, setOnlinePaymentModalShow, setRefresh } from "../../redux/dataSlices/onlinepaymentSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import useHandleError from "../../customhooks/useHandleError";
import DeleteOnlinePaymtentsProfile from "../../actablueAPI/onlinepayments/DeleteOnlinePaymentsProfile";

export default function OnlinePaymentButtons() {
  const token = useSelector(getToken)
  const modalShow = useSelector(getOnlinePaymentModalShow)
  const scopes = useSelector(getScopes)
  const refresh = useSelector(getRefresh)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();

  function onViewClick(onlinePayment) {
    dispatch(setOnlinePayment(onlinePayment));
    dispatch(setOnlinePaymentModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setOnlinePaymentModalShow(false));
  };

  const onclickEdit = (onlinePayment) => {
    dispatch(setOnlinePayment(onlinePayment));
    navigate("/onlinepayment/edit");
  };

  const onclickAdd = () => {
    navigate("/onlinepayment/add");
  };

  const onclickDelete = (paymentProfile) => {
    confirmAlert({
      title: translate('confirm_alert_title_delete'),
      message: translate('confirm_alert_message_delete_payment_profile'),
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            DeleteOnlinePaymtentsProfile({token:token, admin_id:paymentProfile.administration_id, location_id:paymentProfile.location_id, type:paymentProfile.source_type, id:paymentProfile.id})
            .then(response => {
              dispatch(setLoading(false))
              dispatch(setRefresh(!refresh))
            })
            .catch(error => {
              HandleError({ error: error })
              dispatch(setLoading(false))
            })
          }
        },
        {
          label: translate('No'),
        }
      ]
    });
};

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.onlinepayments >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.onlinepayments >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }
  if (scopes.onlinepayments >= 3) {
    buttons.del = {
      tag: "Delete",
      value: 'del',
      onClick: onclickDelete
    }
  }

  return buttons
}

