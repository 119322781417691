import axios from "axios";

const PutImportProfile = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_ENV}/import/profile`,
      headers: {
        'Authorization': 'Bearer '.concat(token['access_token']),
        'Content-Type': 'application/json',
      },
      data: data
    })
    .then(response => {resolve(response.data)})
    .catch(error => {reject(error)})
  });
};

export default PutImportProfile