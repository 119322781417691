export const CcvshopEditFormFields = () => {

  const ccvshopEditFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'formitable_id', id: 'formitable_id', readonly: true },
    { type: 'hidden', name: 'customer_id', id: 'customer_id', readonly: true },
    { type: 'text', name: `domain`, id: 'domain' },
    { type: 'text', name: 'api_public', id: 'api_public' },
    { type: 'text', name: 'api_secret', id: 'api_secret' },
    { type: 'text', name: 'return_url', id: 'return_url' },
    { type: 'checkbox', name: 'order_stock_mutation', id: 'order_stock_mutation' },
    { type: 'checkbox', name: 'wms_stock_mutation', id: 'wms_stock_mutation' },
    { type: 'checkbox', name: 'write_product', id: 'write_product' },
    { type: 'checkbox', name: 'write_order', id: 'write_order' },
    { type: 'checkbox', name: 'write_new_order', id: 'write_new_order' },
    { type: 'text', name: 'rate_limit', id: 'rate_limit', readonly: true },
  ]

  return ccvshopEditFormFields

}

export const CcvshopAddFormFields = () => {

  const ccvshopAddFormFields = [
    // single option fields
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: `Domain`, id: 'domain' },
    { type: 'text', name: 'API public key', id: 'api_public' },
    { type: 'text', name: 'API secret key', id: 'api_secret' }
  ]

  return ccvshopAddFormFields
}

export const CcvshopWebhookFormFields = () => {

  const ccvshopWebhookFormFields = [
    { type: 'select', name: 'CCV API key', id: 'ccv_api_key_id', formdatafield: 'ccv_api_key_id' },
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'select', name: `Event`, id: 'event', formdatafield: 'event' },
    { type: 'checkbox', name: 'Active', id: 'is_active' },
  ]

  return ccvshopWebhookFormFields
}