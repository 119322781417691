import { StyleSheet } from "@react-pdf/renderer";

const height = 16
const fontSize = 10

export const tableRowsCount = 11;

export const borderColor = '#90e5fc'

export const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: fontSize,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
  }, 
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  // ItemsTable
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
  // TableHeader
  headerContainer: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: height,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  headerday: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  headername: {
    width: '70%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },  
  headeramount: {
      width: '30%'
  },
  // TableRow
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: height,
    fontStyle: 'bold',
  },
  rowday: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingRight: 8,
  },
  rowname: {
    width: '70%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingRight: 8,
  },
  rowamount: {
      width: '30%',
      textAlign: 'right',
      paddingRight: 8,
  },
  // footer
  footerRow: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: height,
    fontSize: fontSize,
    fontStyle: 'bold',
  },
  footerDescription: {
      width: '70%',
      textAlign: 'right',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingRight: 8,
  },
  footerTotal: {
      width: '30%',
      textAlign: 'right',
      paddingRight: 8,
  },
  });