import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';
import PaginationAndSizeComponent from './paginationAndSize';
import CardButtons from '../portal/loyalty/cardbuttons';
import CardModal from './cardModal';
import { useSelector } from 'react-redux';
import { getSelectedAdmin_id } from '../redux/dataSlices/selectAdminSlice';
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';

export default function CardsTable(props) {
  const selectedAdmin = useSelector(getSelectedAdmin_id)
  const selectedLocation = useSelector(getSelectedLocation_id)
  const hash_locations_all = props.hash_locations_all
  const hash_admins_all = props.hash_admins_all
  const cards = props?.cards?.content
  const pages = props?.cards?.total_pages
  const page = (props?.cards?.number + 1)

  const buttons = CardButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize
  let rows = []

  if (cards) {
    for (const card of Object.values(cards)) {
      rows.push(
        <tr className='product-row' key={card.id}>
          <td>{hash_admins_all[card?.administration_id]?.name ?? 'Unknown Administration'}</td>
          <td>{hash_locations_all[card?.location_id]?.name ?? 'Unknown Location'}</td>
          <td>{card?.barcode}</td>
          <td>{card?.type}</td>
          <td>{card?.card_type}</td>
          <td>{card?.points}</td>
          <td>{JSON.stringify(card?.add_points)}</td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(card) }} id={card?.id}>{translate(modalview.tag)}</Button>}
            {edit && selectedAdmin && selectedLocation && <Button size="sm" type='button' onClick={() => { edit.onClick(card) }} id={card?.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      <PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />
      {add && selectedAdmin && selectedLocation && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Administration')}</th>
            <th>{translate('Location')}</th>
            <th>{translate('Barcode')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Card type')}</th>
            <th>{translate('Points')}</th>
            <th>{translate('Add points')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <CardModal modal={modalview.modal} />
    </>
  );
}