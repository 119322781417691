import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

export default function TranslationTable(props) {
  const en = require(`../translations/en.json`)
  const nl = require(`../translations/nl.json`)
  const { t: translate } = useTranslation();

  let combined = []
  for (const [key, value] of Object.entries(en)) {
    if (!combined[key]) {combined[key] = {}}
    combined[key]['en'] = value
  }
  for (const [key, value] of Object.entries(nl)) {
    if (!combined[key]) {combined[key] = {}}
    combined[key]['nl'] = value
  }

  let sorted = []
  if (combined) {
    sorted = Object.keys(combined).sort().reduce((a, c) => (a[c] = combined[c], a), {})
  }

  let rows = []
  let missingEN = []
  let missingNL = []
  for (const [key, value] of Object.entries(sorted)) {
    if (!value.en) {missingEN.push(key)}
    if (!value.nl) {missingNL.push(key)}
    rows.push(
      <tr key={key}>
        <td>{key} </td>
        <td>{value.en} </td>
        <td>{value.nl} </td>
      </tr>
    )
  }

  console.log('missingEN', missingEN)
  console.log('missingNL', missingNL)

  return (
    <Table hover width='100%'>
      <thead>
        <tr>
          <th>{translate('Line/Word')}</th>
          <th>{translate('EN')}</th>
          <th>{translate('NL')}</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table >
  )
}