import axios from "axios";

const GetWarehouseProductsPackagingToMinStock = ({ token, admin_id, location_id, product_id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/warehouse/products/packagingtominstock`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id,
        'location': location_id,
        'product': product_id
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default GetWarehouseProductsPackagingToMinStock