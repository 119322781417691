import axios from "axios";

const PostProductSearch = ({ token, admin_id, location_id, data, signal = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/product/search`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: { 'location_id': location_id, },
      data: data,
      signal: signal
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) });
  });
};

export default PostProductSearch