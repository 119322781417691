import React, { useEffect, useState } from "react";
import BasicCard from "../../components/card"
import SettingsForm from "../../components/settingsForm";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin, getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation, getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { setMessage } from "../../redux/dataSlices/messageSlice";
import {
  setSettingsMasterproduct, setSettingsSchapkaartjes, setSettingsPrinters,
  getSettingsMasterproduct, getSettingsSchapkaartjes, getSettingsPrinters
} from "../../redux/dataSlices/settingsSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import axios from "axios";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { SettingsFormFields } from "./settingsFormFields.jsx"
import { codeToPrintChoices, settingsAcceptedCurrency } from "../../selectfieldchoices/shelflabelselect.mjs";
import { masterProductChoices } from "../../selectfieldchoices/productselect.mjs";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getHashTemplatesAll, getTemplatesAll, setTemplatesAll } from "../../redux/dataSlices/templateSlice";
import GetAllTemplates from "../../actablueAPI/shelflabels/GetAllTemplates";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import { getAllowedCurrency, setAllowedCurrency } from "../../redux/dataSlices/cashcountSlice";
import GetAllPrinters from "../../actablueAPI/shelflabels/GetAllPrinters";
import GetShelflabelsSettings from "../../actablueAPI/shelflabels/GetShelflabelsSettings";
import GetProductsSettings from "../../actablueAPI/products/GetProductsSettings";
import GetCashCountCurrency from "../../actablueAPI/cashcount/GetCashCountCurrency";
import PostShelflabelsSettings from "../../actablueAPI/shelflabels/PostShelflabelsSettings";
import PostCashCountCurrency from "../../actablueAPI/cashcount/PostCashCountCurrency";
import PutProductsSettings from "../../actablueAPI/products/PutProductsSettings";
import GetSettings from "../../actablueAPI/kitchenmanager/GetSettings.jsx";
import { default as GetAllKmPrinters } from "../../actablueAPI/kitchenmanager/GetAllPrinters.jsx"
import { getHashPrintersAll, getHashScreensAll, getKitchenManagerSettings, getPrintersAll, getScreensAll, setKitchenManagerSettings, setPrintersAll, setScreensAll } from "../../redux/dataSlices/kitchenmanagerSlice.js";
import PostSettings from "../../actablueAPI/kitchenmanager/PostSettings.jsx";
import DeepCopy from "../../helpers/DeepCopy.jsx";
import GetAllScreens from "../../actablueAPI/kitchenmanager/GetAllScreens.jsx";
import GetProductById from "../../actablueAPI/products/GetProductByID.jsx";
import useHandleError from "../../customhooks/useHandleError.jsx";

const Settings = (props) => {

  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const selectedAdmin = useSelector(getSelectedAdmin)
  const selectedLocation = useSelector(getSelectedLocation)
  const scopes = useSelector(getScopes);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const masterProduct = useSelector(getSettingsMasterproduct);
  const schapkaartjesSettings = useSelector(getSettingsSchapkaartjes);
  const allowedCurrency = useSelector(getAllowedCurrency);
  const printers = useSelector(getSettingsPrinters);
  const kmSettings = useSelector(getKitchenManagerSettings);
  const kmPrinters = useSelector(getPrintersAll);
  const hashKmPrinters = useSelector(getHashPrintersAll);
  const hash_templates_all = useSelector(getHashTemplatesAll)
  const templates_all = useSelector(getTemplatesAll)
  const screens = useSelector(getScreensAll);
  const hashScreensAll = useSelector(getHashScreensAll);
  const [onHoldProductLabel, setOnHoldProductLabel] = useState("");
  const [nextCourseProductLabel, setNextCourseProductLabel] = useState("");
  const [vipProductLabel, setVipProductLabel] = useState("");
  const [pauseProductLabel, setPauseProductLabel] = useState("");

  const onChange = (event) => {
    let newSchapkaartjesSettings = HandleOnChange({ event: event, object: schapkaartjesSettings })
    dispatch(setSettingsSchapkaartjes(newSchapkaartjesSettings));
  }

  const onChangeCheckboxProducts = (event) => {
    let newObject = HandleOnChange({ event: event, object: masterProduct })
    dispatch(setSettingsMasterproduct(newObject));
  }

  const onChangeSingleSelectValue = (event) => {
    let newObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: schapkaartjesSettings })
    dispatch(setSettingsSchapkaartjes(newObject));
  }

  const onMasterProductChange = (event) => {
    let newMasterProduct = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: masterProduct, selectfield: 'master_product' })
    dispatch(setSettingsMasterproduct(newMasterProduct));
  }

  const onChangeSingleSelectValueKm = (event) => {
    let newObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: kmSettings, selectfield: 'km_default_printer' })
    dispatch(setKitchenManagerSettings(newObject));
  }

  const onChangeKitchenManager = (event) => {
    let newObject = HandleOnChange({ event: event, object: kmSettings })
    dispatch(setKitchenManagerSettings(newObject));
  }

  const onChangeSingleSelectValueScreen = (event) => {
    let newObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: kmSettings, selectfield: 'default_screen' })
    dispatch(setKitchenManagerSettings(newObject));
  }

  const onChangeOnHoldProduct = (event) => {
    console.log("onChangeOnHoldProduct - event:", event);
    let newObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: kmSettings, selectfield: 'on_hold_product' })
    dispatch(setKitchenManagerSettings(newObject));
    setOnHoldProductLabel(event.label);
  }

  const onChangeNextCourseProduct = (event) => {
    let newObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: kmSettings, selectfield: 'next_course_product' })
    dispatch(setKitchenManagerSettings(newObject));
    setNextCourseProductLabel(event.label);
  }

  const onChangeVipProduct = (event) => {
    let newObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: kmSettings, selectfield: 'vip_product' })
    dispatch(setKitchenManagerSettings(newObject));
    setVipProductLabel(event.label);
  }

  const onChangePauseProduct = (event) => {
    let newObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: kmSettings, selectfield: 'pause_product' })
    dispatch(setKitchenManagerSettings(newObject));
    setPauseProductLabel(event.label);
  }

  const onCurrencyChange = (event) => {
    let newEvent = event
    
    for (let i = newEvent.length - 1; i >= 0; i--) {
      if (isNaN(newEvent[i].value)) {
        dispatch(setMessage(`Value "${newEvent[i].value}" is not a valid number.`))
        newEvent.splice(i, 1);
      }
    }

    let newAllowedCurrency = HandleOnChangeMultiSelectFieldWithValues({ event: newEvent, object: allowedCurrency, selectfield: 'allowed_currency' })
    dispatch(setAllowedCurrency(newAllowedCurrency))
  }

  const onSubmit = () => {
    let newProductSettings = JSON.parse(JSON.stringify(masterProduct));
    if (newProductSettings) newProductSettings.id = location_id;

    PutProductsSettings({ token: token, admin_id: admin_id, data: newProductSettings })
    .then(() => {
      if (!scopes.schapkaartjes) {
        dispatch(setMessage('Instellingen Opgeslagen'))
        dispatch(setLoading(false))
      }
    })
    .catch(error => {
      dispatch(setLoading(false))
      HandleError({ error: error })
    })

    if (scopes.cashcount) {
      const newAllowedCurrency = Object.assign({ 'admin_id': admin_id, 'location_id': location_id, 'allowed_currency': allowedCurrency.allowed_currency })
      PostCashCountCurrency({ token: token, admin_id: admin_id, location_id: location_id, data: newAllowedCurrency })
      .then(response => {
        if (!scopes.schapkaartjes) {
          dispatch(setMessage('Instellingen Opgeslagen'))
        }
        dispatch(setAllowedCurrency(response.data))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }

    if (scopes.schapkaartjes) {
      const newSchapkaartjesSettings = Object.assign({ 'admin_id': admin_id, 'location_id': location_id }, schapkaartjesSettings)
      PostShelflabelsSettings({ token: token, admin_id: admin_id, location_id: location_id, data: newSchapkaartjesSettings})
      .then(response => {
        dispatch(setSettingsSchapkaartjes(response.data))
        if (!scopes.sortcategories) {
          dispatch(setMessage('Instellingen Opgeslagen'))
          dispatch(setLoading(false))
        }
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }

    if (scopes.sortcategories) {
      let newKmSettings = JSON.parse(JSON.stringify(formData.kmSettings));
      if (typeof newKmSettings.km_default_printer === 'object') newKmSettings.default_printer = newKmSettings.km_default_printer?.id
      if (typeof newKmSettings.km_default_printer !== 'object') newKmSettings.default_printer = newKmSettings.km_default_printer
      
      PostSettings({ token: token, admin_id: admin_id, location_id: location_id, data: newKmSettings })
      .then(response => {
        dispatch(setMessage('Instellingen Opgeslagen'))
        let newSettings = DeepCopy(response.data)
        newSettings.km_default_printer = response.data.default_printer
        newSettings.default_screen = response.data.default_screen?.id || null
        delete newSettings.default_printer
        dispatch(setKitchenManagerSettings(newSettings))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: SettingsFormFields(),
    field: {
      onChange: onChange
    },
    data: { ...masterProduct, ...schapkaartjesSettings, ...kmSettings },
    printers: printers,
    screens: screens,
    hash_kmPrinters: hashKmPrinters,
    hash_screens_all: hashScreensAll,
    kmSettings: kmSettings,
    templates: templates_all,
    currency: allowedCurrency,
    sync_active: {
      onChange: onChangeCheckboxProducts
    },
    replace_eans: {
      onChange: onChangeCheckboxProducts
    },
    kitchen_manager_enabled: {
      onChange: onChangeKitchenManager
    },
    master_product: {
      label: 'master_product',
      options: masterProductChoices,
      selected: [],
      onChange: onMasterProductChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    code_to_print: {
      label: 'code_to_print',
      options: codeToPrintChoices,
      selected: [],
      onChange: onChangeSingleSelectValue,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    default_printer: {
      label: 'default_printer',
      options: printers,
      selected: [],
      onChange: onChangeSingleSelectValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    print_default_template: {
      label: 'print_default_template',
      options: [],
      selected: [],
      hash: hash_templates_all,
      onChange: onChangeSingleSelectValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    esl_default_template: {
      label: 'esl_default_template',
      options: [],
      selected: [],
      hash: hash_templates_all,
      onChange: onChangeSingleSelectValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    accepted_currency: { // This one is for shelflabels
      label: 'accepted_currency',
      options: settingsAcceptedCurrency,
      selected: [],
      onChange: onChangeSingleSelectValue,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    allowed_currency: { // This one is for cash-count
      label: 'allowed_currency',
      options: [],
      selected: [],
      onChange: onCurrencyChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    km_default_printer: {
      label: 'km_default_printer',
      options: kmPrinters,
      selected: [],
      onChange: onChangeSingleSelectValueKm,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    default_screen: {
      label: 'default_screen',
      options: screens,
      selected: [],
      onChange: onChangeSingleSelectValueScreen,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    on_hold_product: {
      label: 'on_hold_product',
      options: [],
      selected: {},
      onChange: onChangeOnHoldProduct,
      clearable: Boolean(true),
      searchable: Boolean(true),
      selected_label: onHoldProductLabel
    },
    next_course_product: {
      label: 'next_course_product',
      options: [],
      selected: [],
      onChange: onChangeNextCourseProduct,
      clearable: Boolean(true),
      searchable: Boolean(true),
      selected_label: nextCourseProductLabel
    },
    vip_product: {
      label: 'vip_product',
      options: [],
      selected: [],
      onChange: onChangeVipProduct,
      clearable: Boolean(true),
      searchable: Boolean(true),
      selected_label: vipProductLabel
    },
    pause_product: {
      label: 'pause_product',
      options: [],
      selected: [],
      onChange: onChangePauseProduct,
      clearable: Boolean(true),
      searchable: Boolean(true),
      selected_label: pauseProductLabel
    }
  };

  const settingsCard = {
    size: 12,
    title: "Settings",
    data: <SettingsForm formdata={formData} token={token} admin={selectedAdmin} location={selectedLocation} />,
  };

  useEffect(() => {
    dispatch(setPrintersAll([]))
    dispatch(setNavBarTitle("Settings"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      if (scopes.schapkaartjes) {
        axios.all([
          GetShelflabelsSettings({token: token, admin_id:admin_id, location_id:location_id}),
          GetAllTemplates({ token: token, admin_id: admin_id, location_id: location_id, base_labels: true, page: 1, size: 25 }),
          GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id, page: 1, size: 100 }),
        ])
        .then(axios.spread((schapkaartjesSettings, responseTemplates, responsePrinters) => {
          dispatch(setSettingsSchapkaartjes(schapkaartjesSettings.data))
          let sorted = []
          if (responseTemplates) {
            sorted = Object.values(responseTemplates).sort((a, b) => b.base_label - a.base_label)
          }
          dispatch(setTemplatesAll(sorted))
          dispatch(setSettingsPrinters(responsePrinters))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
      }
  
      if (scopes.cashcount) {
        GetCashCountCurrency({ token: token, admin_id: admin_id, location_id: location_id})
        .then(response => {
          dispatch(setAllowedCurrency(response.data))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setAllowedCurrency([]))
          dispatch(setLoading(false))
        })
      }
  
      if (scopes.sortcategories) {
        GetSettings({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          let newSettings = DeepCopy(response.data)
          newSettings.km_default_printer = response.data.default_printer
          newSettings.default_screen = response.data.default_screen?.id || null
          delete newSettings.default_printer

          if (newSettings.on_hold_product) {
            GetProductById({ token: token, admin_id: admin_id, location_id: location_id, id: newSettings.on_hold_product })
            .then(response => {
              setOnHoldProductLabel(response.data?.name);
            })
          }

          if (newSettings.next_course_product) {
            GetProductById({ token: token, admin_id: admin_id, location_id: location_id, id: newSettings.next_course_product })
            .then(response => {
              setNextCourseProductLabel(response.data?.name);
            })
          }

          if (newSettings.vip_product) {
            GetProductById({ token: token, admin_id: admin_id, location_id: location_id, id: newSettings.vip_product })
            .then(response => {
              setVipProductLabel(response.data?.name);
            })
          }

          if (newSettings.pause_product) {
            GetProductById({ token: token, admin_id: admin_id, location_id: location_id, id: newSettings.pause_product })
            .then(response => {
              setPauseProductLabel(response.data?.name);
            })
          }

          dispatch(setKitchenManagerSettings(newSettings))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
        
        GetAllKmPrinters({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setPrintersAll(response))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
        
        GetAllScreens({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setScreensAll(response))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
      }
      
      GetProductsSettings({token: token, admin_id: admin_id, location_id: location_id})
      .then(response => {
        dispatch(setSettingsMasterproduct(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }
    // eslint-disable-next-line
  }, [location_id]);

  return (
    <>
      {props.offcanvas ?
        <SettingsForm formdata={formData} token={token} admin={selectedAdmin} location={selectedLocation} /> :
        <BasicCard {...settingsCard} />
      }
    </>
  )
};
export default Settings;
