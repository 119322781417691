import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button, Table } from 'react-bootstrap';

import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { getUserRole } from '../redux/dataSlices/userSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';


export default function UserRoleModal(props) {
  const object = useSelector(getUserRole);
  const role = useSelector(getRole);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const { t: translate } = useTranslation();

  if (!object) return;

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  const priority = {
    "delete": 4,
    "create": 3,
    "update": 2,
    "read": 1
  };

  let sortedPortalScopes = {};
  let sortedApiScopes = {};
  if (Array.isArray(object.roles)) {
    // Create arrays to store roles for each prefix
    const portalRoles = [];
    const apiRoles = [];

    object.roles.forEach(role => {
      const parts = role.split(':');
      const prefix = parts[0];
      const title = parts[2];
      const description = prefix === 'portal' ? parts[4].charAt(0).toUpperCase() + parts[4].slice(1) : `${parts[3]}/${parts[4]}`;
      const value = parts[5];

      // Choose the correct array based on the prefix
      const targetArray = prefix === 'portal' ? portalRoles : apiRoles;

      targetArray.push({
        title,
        description,
        value
      });
    });

    // Sort roles for each prefix based on title
    portalRoles.sort((a, b) => a.title.localeCompare(b.title));
    apiRoles.sort((a, b) => a.title.localeCompare(b.title));

    // Convert sorted arrays back to objects
    portalRoles.forEach(role => {
      const key = `${role.title}-${role.description}`;
      if (!sortedPortalScopes[key] || priority[role.value] > priority[sortedPortalScopes[key].value]) {
        sortedPortalScopes[key] = role;
      }
    });

    apiRoles.forEach(role => {
      const key = `${role.title}-${role.description}`;
      if (!sortedApiScopes[key] || priority[role.value] > priority[sortedApiScopes[key].value]) {
        sortedApiScopes[key] = role;
      }
    });
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            User Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="portal_scopes">{translate('Portal Scopes')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="api_scopes">{translate('API Scopes')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="portal_scopes">
                <div className="container">
                  <div className="row">
                    <Table hover width='100%'>
                      <thead>
                        <tr className='product-row'>
                          <th width='40%'>
                            {translate('Location')}
                          </th>
                          <th>
                            {translate('Name')}
                          </th>
                          <th>
                            {translate('Value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(sortedPortalScopes).map((role) => (
                          <tr className='product-row'>
                            <td>{hash_locations_all[role.title]?.name}</td>
                            <td>{translate(role.description)}</td>
                            <td>{role.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="api_scopes">
                <div className="container">
                  <div className="row">
                    <Table hover width='100%'>
                      <thead>
                        <tr className='product-row'>
                          <th width='40%'>
                            {translate('Location')}
                          </th>
                          <th>
                            {translate('Name')}
                          </th>
                          <th>
                            {translate('Value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(sortedApiScopes).map((role) => (
                          <tr className='product-row'>
                            <td>{hash_locations_all[role.title]?.name}</td>
                            <td>{translate(role.description)}</td>
                            <td>{role.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}