import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setAddOnProductsAll, getAddOnProductsAll, getHashAddOnProductsAll } from "../../redux/dataSlices/productSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import AttributeGroupForm from "../../components/attributegroupForm";
import { AttributeGroupFormFields } from "./attributegroupFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import PostAttribute from "../../actablueAPI/products/PostAttribute";
import GetAllProductsWithType from "../../actablueAPI/products/GetAllProductsWithType";
import useHandleError from "../../customhooks/useHandleError";

const AttributeGroupAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  // const attributeGroup = useSelector(getAttributeGroup)
  const addon_products = useSelector(getAddOnProductsAll)
  const hash_addon_products = useSelector(getHashAddOnProductsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attributeGroup, setAttributeGroup] = useState({ administation_id: admin_id, location_id: location_id, type: 'ADDON' })
  const HandleError = useHandleError();

  const onProductsChange = (event) => {
    let newAttributeGroup = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: attributeGroup, selectfield: 'products', hash: hash_addon_products })
    setAttributeGroup(newAttributeGroup)
  };

  const onChange = (event) => {
    let newAttributeGroup = HandleOnChange({ event: event, object: attributeGroup })
    setAttributeGroup(newAttributeGroup)
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    PostAttribute({ token: token, admin_id: admin_id, attributeGroup: attributeGroup })
      .then(response => {
        navigate(-1);
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: AttributeGroupFormFields(),
    field: {
      onChange: onChange
    },
    data: attributeGroup,
    products: {
      label: 'products',
      options: addon_products,
      selected: [],
      onChange: onProductsChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: 'Attribute group add',
    data: <AttributeGroupForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setLoading(true))
    dispatch(setNavBarTitle('Attribute group add'))
    GetAllProductsWithType({ token: token, admin_id: admin_id, location_id: location_id, type: 'addon' })
      .then(response => {
        dispatch(setAddOnProductsAll(response))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default AttributeGroupAdd;

