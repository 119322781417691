import axios from "axios";

const GetShelflabelsTemplate = ({token, admin_id, location_id, id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/schapkaartjes/template"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id,
        'id': id      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetShelflabelsTemplate