import axios from "axios";

const GetReservationObjects = ({token, admin_id, location_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/reservations/reservationobject`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type':'application/json'
      },
      params: { 
        'administration_id': admin_id,
        'location_id': location_id
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetReservationObjects