export const phoneNumberTypeChoices = [
    { id: 'zaak', name: 'zaak' },
    { id: 'prive', name: 'prive' },
    { id: 'mobiel', name: 'mobiel' },
    { id: 'facturatie', name: 'facturatie' },
    { id: 'reserveringen', name: 'reserveringen' },
    ]

export const emailTypeChoices = [
    { id: 'zaak', name: 'zaak' },
    { id: 'prive', name: 'prive' },
    { id: 'mobiel', name: 'mobiel' },
    { id: 'facturatie', name: 'facturatie' },
    { id: 'reserveringen', name: 'reserveringen' },
    ]