import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { pdf } from "@react-pdf/renderer"
import Invoice from '../pdfcreators/createInvoicePDF';
import { setProgressModalShow, setTaskData } from "../redux/dataSlices/analyseSlice";

async function exportMultipleInvoicePDF(invoices, hash_locations_all, relations_hash, dispatch) {

  const zip = new JSZip();

  try {
    dispatch(setProgressModalShow(true))
    let current = 0
    let task_data = {
      current: current,
      total: Object.keys(invoices).length,
      progress: current === 0 ? 0 : current / Object.keys(invoices).length
    }
    dispatch(setTaskData(task_data))
    const fileNames = {};

    for (const [, invoice] of Object.entries(invoices)) {
      let fileName = invoice?.number ? `invoice-${invoice?.number.replace(/[^\w\s]/gi, "")}` : 'Unknown invoice';

      if (zip.files[`${fileName}.pdf`]) {
        if (fileNames[fileName] === undefined) {
          fileNames[fileName] = 1;
        } else {
          fileNames[fileName]++;
        }
        fileName = invoice?.number ? `invoice-${invoice?.number.replace(/[^\w\s]/gi, "")}_${fileNames[fileName]}` : `Unknown invoice_${fileNames[fileName]}`;
      }

      const blob = await pdf(<Invoice invoice={invoice} hash_locations={hash_locations_all} relation={relations_hash[invoice?.relation_id]} />).toBlob()
      if (Object.keys(invoices).length > 1) {
        zip.file(`${fileName}.pdf`, blob);
        current++
        let task_data = {
          current: current,
          total: Object.keys(invoices).length,
          progress: current === 0 ? 0 : current / Object.keys(invoices).length
        }
        dispatch(setTaskData(task_data))
      } else {
        saveAs(blob, `${fileName}.pdf`)
        dispatch(setProgressModalShow(false))
      }
    }
  } catch (error) {
    console.error(error);
    dispatch(setProgressModalShow(false))
  }

  if (Object.keys(invoices).length > 1) {
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'invoices.zip');
    dispatch(setProgressModalShow(false))
  }
}

export default exportMultipleInvoicePDF