import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setGuestCheck, setGuestCheckPagination, getGuestCheckModalShow, setGuestCheckModalShow, setGuestCheckPagesize, getGuestCheckPagesize, setGuestCheckRefresh, getGuestCheckRefresh } from "../../redux/dataSlices/guestcheckSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import GetOrderById from "../../actablueAPI/invoicepayments/GetOrderByID";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import useHandleError from "../../customhooks/useHandleError";
import { stateChoices } from "../../selectfieldchoices/guestcheckselect.mjs";
import PostGuestCheck from "../../actablueAPI/guestchecks/PostGuestCheck";
import { getRole } from "../../redux/dataSlices/tokenSlice";

export default function GuestCheckButtons() {
  const token = useSelector(getToken)
  const modalShow = useSelector(getGuestCheckModalShow)
  const pageSize = useSelector(getGuestCheckPagesize)
  const scopes = useSelector(getScopes)
  const refresh = useSelector(getGuestCheckRefresh)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();

  function onViewClick(guestcheck) {
    // get order
    if (guestcheck.order_id) {
      let newGuestcheck = JSON.parse(JSON.stringify(guestcheck))
      GetOrderById({ token: token, admin_id: newGuestcheck.administration_id, id: newGuestcheck.order_id })
        .then(response => {
          newGuestcheck.order = response.data
          dispatch(setGuestCheck(newGuestcheck));
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setGuestCheck(guestcheck));
        })
        .finally(() => {
          dispatch(setGuestCheckModalShow(true));
        })
    }
  };

  const onViewHide = () => {
    dispatch(setGuestCheckModalShow(false));
  };

  const onclickEdit = (guestcheck) => {
    dispatch(setGuestCheck(guestcheck));
    navigate("/guestcheck/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setGuestCheckPagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setGuestCheckPagesize(event.value))
  };

  const onclickAdd = () => {
    navigate("/guestcheck/add");
  };

  const onFix = (guestcheck) => {
      // get order
      if (guestcheck.order_id) {
        let newGuestcheck = JSON.parse(JSON.stringify(guestcheck))
        GetOrderById({ token: token, admin_id: newGuestcheck.administration_id, id: newGuestcheck.order_id })
          .then(response => {
            let order = response.data
            newGuestcheck.last_operator_id = order.operator_id
            newGuestcheck.amount_incl_vat = order.payment_amount_inclusive_vat
            newGuestcheck.last_order_at = order.modification
            PostGuestCheck({token:token, admin_id:guestcheck.administration_id, data:newGuestcheck})
            .then(response => {
              // refresh page? or change state in redux
              dispatch(setGuestCheckRefresh(!refresh))
            })
          })
          .catch(error => {
            HandleError({ error: error })
          })
      }
  }

  const onStatusChange = (event, guestcheck) => {
    // change state on server
    const newGuestcheck = JSON.parse(JSON.stringify(guestcheck))
    newGuestcheck.state = event.value
    PostGuestCheck({token:token, admin_id:guestcheck.administration_id, data:newGuestcheck})
    .then(response => {
      // refresh page? or change state in redux
      dispatch(setGuestCheckRefresh(!refresh))
    })
    
  }

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },
    statusSelectfield: {
      label: 'Status',
      options: stateChoices,
      onChange: onStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.guestchecks >= 1) {
    buttons.add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.guestchecks >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  // add buttons based on role
  if (role === 'super' || role === 'admin') {
    buttons.fix = {
      tag: 'Fix',
      value: 'fix',
      onClick: onFix
    }
  }

  return buttons
}

