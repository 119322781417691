import { createSlice } from "@reduxjs/toolkit";

export const snelstartSlice = createSlice({
  name: "snelstart",
  initialState: {
    hash_snelstartcompanys_all: {},
    hash_snelstart_transactions_on_invoicenumber: {}
  },
  reducers: {
    setSnelstartCompanyId: (state, action) => {
      state.snelstartcompany_id = action.payload;
    },
    setSnelstartCompany: (state, action) => {
      state.snelstartcompany = action.payload;
    },
    setSnelstartCompanys: (state, action) => {
      state.snelstartcompanys = action.payload
    },
    setSnelstartCompanysAll: (state, action) => {
      state.snelstartcompanys_all = action.payload
      action?.payload?.forEach(snelstartcompany => {
        state.hash_snelstartcompanys_all[snelstartcompany.id] = snelstartcompany
      });
    },
    setSnelstartCompanyModalShow: (state, action) => {
      state.snelstartcompany_modalshow = action.payload
    },
    setSnelstartCompanyPagination: (state, action) => {
      state.snelstartcompany_pagination = (action.payload - 1)
    },
    setSnelstartCompanyPagesize: (state, action) => {
      state.snelstartcompany_pagesize = action.payload
    },
    setSnelstartHashTransactionsOnInvoiceNumber: (state, action) => {
      state.hash_snelstart_transactions_on_invoicenumber = action.payload
    },
    setSnelstartTransactionsLoading: (state, action) => {
      state.snelstart_transactions_loading = action.payload
    },
    setSnelstartTemplates: (state, action) => {
      state.snelstartTemplates = action.payload
    }
  }
});

export const {
  setSnelstartCompanyId, 
  setSnelstartCompany, 
  setSnelstartCompanys, 
  setSnelstartCompanyModalShow, 
  setSnelstartCompanyPagination, 
  setSnelstartCompanyPagesize, 
  setSnelstartCompanysAll, 
  setSnelstartHashTransactionsOnInvoiceNumber, 
  setSnelstartTransactionsLoading,
  setSnelstartTemplates
} = snelstartSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getSnelstartCompanyId = (state) => state.snelstart.snelstartcompany_id
export const getSnelstartCompany = (state) => state.snelstart.snelstartcompany
export const getSnelstartCompanys = (state) => state.snelstart.snelstartcompanys
export const getSnelstartCompanysAll = (state) => state.snelstart.snelstartcompanys_all
export const getHashSnelstartCompanysAll = (state) => state.snelstart.hash_snelstartcompanys_all
export const getSnelstartCompanysHighlights = (state) => state.snelstart.snelstartcompanyshighlights
export const getSnelstartCompanyModalShow = (state) => state.snelstart.snelstartcompany_modalshow
export const getSnelstartCompanyPagination = (state) => state.snelstart.snelstartcompany_pagination
export const getSnelstartCompanyPagesize = (state) => state.snelstart.snelstartcompany_pagesize
export const getSnelstartHashTransactionsOnInvoiceNumber = (state) => state.snelstart.hash_snelstart_transactions_on_invoicenumber
export const getSnelstartTransactionsLoading = (state) => state.snelstart.snelstart_transactions_loading
export const getSnelstartTemplates = (state) => state.snelstart.snelstartTemplates

export default snelstartSlice.reducer;