import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';


function SnelstartForm(props) {
  const { t: translate } = useTranslation();
  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  let hashOptions = []

  if (props.default_verkoop_order_sjabloon.options) {
    for (const option of props.default_verkoop_order_sjabloon.options) {
      hashOptions[option.id] = option
    }
    props.default_verkoop_order_sjabloon.options = props.default_verkoop_order_sjabloon.options.filter(option => {if (option.id) {return true}})
    props.direct_payment_verkoop_order_sjabloon.options = props.default_verkoop_order_sjabloon.options.filter(option => {if (option.id) {return true}})
    props.invoice_verkoop_order_sjabloon.options = props.default_verkoop_order_sjabloon.options.filter(option => {if (option.id) {return true}})
  }

  if (props.data.hasOwnProperty('default_verkoop_order_sjabloon')) {
    props.default_verkoop_order_sjabloon.selected = { 'value': hashOptions[props.data.default_verkoop_order_sjabloon]?.id, 'label': hashOptions[props.data.default_verkoop_order_sjabloon]?.omschrijving }
  }

  if (props.data.hasOwnProperty('direct_payment_verkoop_order_sjabloon')) {
    props.direct_payment_verkoop_order_sjabloon.selected = { 'value': hashOptions[props.data.direct_payment_verkoop_order_sjabloon]?.id, 'label': hashOptions[props.data.direct_payment_verkoop_order_sjabloon]?.omschrijving }
  }

  if (props.data.hasOwnProperty('invoice_verkoop_order_sjabloon')) {
    props.invoice_verkoop_order_sjabloon.selected = { 'value': hashOptions[props.data.invoice_verkoop_order_sjabloon]?.id, 'label': hashOptions[props.data.invoice_verkoop_order_sjabloon]?.omschrijving }
  }

  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default SnelstartForm;