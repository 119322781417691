import JsonHighlighter from './serviceJsonHighlight';

export default function SearchIdDataTable(object) {
  return (
    <div className="container text-center">
      <div className="row">
        <div className="col">
          <p>Id: {object.id}</p>
        </div>
    
      </div>
      <div className="col">
        <JsonHighlighter {...object} />
      </div>
    </div>
  )


  
}
