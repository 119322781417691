import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getCcvshop } from '../redux/dataSlices/ccvshopSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { useTranslation } from 'react-i18next';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';


export default function CcvshopModal(props) {
  const object = useSelector(getCcvshop);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            {translate('CCVShop')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="webshop">{translate('Webshop')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Domain')}: {object?.domain || "N/A"}</p>
                      <p>{translate('API Public')}: {object?.api_public || "N/A"}</p>
                      <p>{translate('API Secret')}: {object?.api_secret || "N/A"}</p>
                      <p>{translate('Rate limit')}: {object?.rate_limit || "N/A"}</p>
                      <p>{translate('Write new order')}: {JSON.stringify(object?.write_new_order) || "N/A"}</p>
                      <p>{translate('Write order')}: {JSON.stringify(object?.write_order) || "N/A"}</p>
                      <p>{translate('Write product')}: {JSON.stringify(object?.write_product) || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="webshop">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object?.webshop?.name || "N/A"}</p>
                      <p>{translate('ID')}: {object?.webshop?.id || "N/A"}</p>
                      <p>{translate('API Root')}: {object?.webshop?.api_root || "N/A"}</p>
                      <p>{translate('Is Multishop')}: {JSON.stringify(object?.webshop?.is_multishop_system) || "N/A"}</p>
                      <p>{translate('Product Limit')}: {object?.webshop?.product_limit || "N/A"}</p>
                      <p>{translate('Product Limit Left')}: {object?.webshop?.product_limit_left || "N/A"}</p>
                      <h4>{translate('Settings')}</h4>
                      <p>{translate('Default VAT Rate')}: {object?.webshop?.settings?.default_vat_rat || "N/A"}</p>
                      <p>{translate('Default Package ID')}: {object?.webshop?.settings?.default_package_id || "N/A"}</p>
                      <p>{translate('Price Incl. Vat')}: {JSON.stringify(object?.webshop?.settings?.prices_include_vat) || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object?.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object?.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object?.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object?.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object?.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}