import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import OpticonConnectionButtons from '../portal/opticon/opticonconnectionbuttons';
import OpticonConnectionModal from './opticonConnectionModal';
import { useTranslation } from 'react-i18next';


export default function OpticonConnectionsTable(props) {
  const connections = props.connections
  const hash_administrations = props.hash_administrations
  const hash_locations = props.hash_locations
  const buttons = OpticonConnectionButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let connectionRows = []
  if (connections) {
    for (let connection of Object.values(connections)) {
      const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: connection.id }) : false
      connectionRows.push(
        <tr className='category-row' key={connection.administration_id.concat(connection.location_id)}>
          <td>{hash_administrations[connection.administration_id]?.name}</td>
          <td>{hash_locations[connection.location_id]?.name}</td>
          <td>{connection.guid}</td>
          <td>{JSON.stringify(connection.use_image)}</td>
          <td>{JSON.stringify(connection.use_template)}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(connection) }} id={connection.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(connection) }} id={connection.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }


  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>
              {translate('Administration')}
            </th>
            <th>
              {translate('Location')}
            </th>
            <th>
              {translate('Server GUID')}
            </th>
            <th>
              {translate('Use Image')}
            </th>
            <th>
              {translate('Use Template')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {connectionRows}
        </tbody>
        <OpticonConnectionModal modal={modalview.modal} />
      </Table>
    </>
  );
}