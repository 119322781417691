import { Pie } from 'react-chartjs-2';

export const productPerMonthReportGraph = (report) => {
  let graphCardData = <p>No data</p>
  let graphLabels = []
  let totalData = []
  let quantityData = []
  let backGroundColor = []
  let borderColor = []

  if (!report.days.totals || Object.keys(report.days.totals) === 0) return graphCardData

  for (const product of Object.values(report.days.totals).sort((a, b) => b.amount - a.amount)) {
    let color = '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    graphLabels.push(product.name)
    totalData.push(product.total)
    quantityData.push(product.amount)

    backGroundColor.push(color)
    borderColor.push(color)

    const graphDatasetAmount = [
      {
        label: 'Value',
        data: quantityData,
        borderColor: borderColor,
        backgroundColor: backGroundColor,
      },
    ]

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
      },
    };

    const dataAmount = {
      labels: graphLabels,
      datasets: graphDatasetAmount
    }

    graphCardData = <div className='pie-container'><Pie options={options} data={dataAmount} /></div>
  }

  return graphCardData
};