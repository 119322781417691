import { Button, ListGroup, Col, Row } from 'react-bootstrap';

export default function TemplateElementListGroup({ element, index, onClick, selected, onRemove }) {

  return (
    <ListGroup.Item
      key={element.id ?? index}
      active={selected === element}
      onClick={(event) => onClick(event, element, index)}
      action
    >
      <Row>
        <Col md={8}>
          <strong>Name:</strong> {element?.name} <br />
          <strong>Type:</strong> {element?.type}
        </Col>
        <Col md={4}>
          <Button variant="danger" className='mt-1' style={{ float: 'right' }} onClick={(event) => { onRemove(event, element) }}>
            Remove
          </Button>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}