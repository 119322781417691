import axios from "axios";

export default async function delKeycloakUser(token, id) {
  return await axios({
    method: 'delete',
    url: `${process.env.REACT_APP_KEYCLOAK_ADMIN_URL}/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${id}`,
    headers: {
      'Authorization': `Bearer ${token['access_token']}`,
      'Content-Type': 'application/json',
    },
  }) 
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('del user eror ', error);
      return 
    })
    
}