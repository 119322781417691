import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import RackButtons from '../portal/stock/rackbuttons';
import './css/rackTable.css';

export default function RackTable(object) {
  const racks = object.racks
  const buttons = RackButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let rackRows = []
  for (let rack of Object.values(racks)) {
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: rack.id }) : false
    rackRows.push(
      <tr className='rack-row' key={rack.id}>
        <td>{rack.name}</td>
        <td>
          <p>Created: {new Date(rack.creation).toLocaleString()}</p>
          <p>Modified: {new Date(rack.modification).toLocaleString()}</p>
        </td>
        <td>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(object, rack) }} id={rack.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      <Table width='100%'>
        <thead key='header'>
          <tr>
            <th>{translate('Name')}</th>
            <th>Date</th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rackRows}
        </tbody>
      </Table>
      {add && <Button size="sm" type='button' onClick={() => { add.onClick(object) }} id={object.id}>{translate(add.tag)}</Button>}
    </>
  );
}