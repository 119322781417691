import { Table, Tab, Nav, Button } from 'react-bootstrap'
import AnalyseButtons from '../portal/analyses/analyseButtons'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { getRole } from '../redux/dataSlices/tokenSlice'

export default function AnalyseTransactionDataTable(data) {
  const role = useSelector(getRole)
  const results = data.taskData?.results
  const buttons = AnalyseButtons.call()
  const exportButton = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const { t: translate } = useTranslation();
  
  let is_not_processed_by_accounting = []
  let is_processed_by_accounting = []

  if (results) {
    let counter = 0

    if (results.is_not_processed_by_accounting?.length > 0) {
      for (const transaction of results.is_not_processed_by_accounting) {
        is_not_processed_by_accounting.push(
          <tr className='product-row' key={counter}>
            <td>{transaction.administration_id}</td>
            <td>{transaction.location_id}</td>
            <td>{transaction.transaction_id}</td>
            <td>{transaction.creation ? new Date(transaction.creation).toLocaleString() : 'no creation date'}</td>
            <td>{transaction.is_processed_by_accounting ? JSON.stringify(transaction.is_processed_by_accounting) : 'false'}</td>
            <td>{transaction.available_in_snelstart_based_on_accounting ? JSON.stringify(transaction.available_in_snelstart_based_on_accounting) : transaction.accounting_response ? 'false' : ''}</td>
            <td>{transaction.accounting_response ? JSON.stringify(transaction.accounting_response) : ''}</td>
            <td>{transaction.available_in_snelstart_based_on_snelstart ? JSON.stringify(transaction.available_in_snelstart_based_on_snelstart) : transaction.snelstart_response ? 'false' : ''}</td>
            <td>{transaction.snelstart_response ? JSON.stringify(transaction.snelstart_response) : ''}</td>
          </tr>
        )
        counter++
      }
    }

    counter = 0

    if (results.is_processed_by_accounting?.length > 0) {
      for (const transaction of results.is_processed_by_accounting) {
        is_processed_by_accounting.push(
          <tr className='product-row' key={counter}>
            <td>{transaction.administration_id}</td>
            <td>{transaction.location_id}</td>
            <td>{transaction.transaction_id}</td>
            <td>{transaction.creation ? new Date(transaction.creation).toLocaleString() : 'no creation date'}</td>
            <td>{transaction.is_processed_by_accounting ? JSON.stringify(transaction.is_processed_by_accounting) : 'false'}</td>
            <td>{transaction.available_in_snelstart_based_on_accounting ? JSON.stringify(transaction.available_in_snelstart_based_on_accounting) : transaction.accounting_response ? 'false' : ''}</td>
            <td>{transaction.accounting_response ? JSON.stringify(transaction.accounting_response) : ''}</td>
            <td>{transaction.available_in_snelstart_based_on_snelstart ? JSON.stringify(transaction.available_in_snelstart_based_on_snelstart) : transaction.snelstart_response ? 'false' : ''}</td>
            <td>{transaction.snelstart_response ? JSON.stringify(transaction.snelstart_response) : ''}</td>
          </tr>
        )
        counter++
      }
    }
  }

  return (
    <Tab.Container defaultActiveKey="not_processed">
    <Nav variant="tabs">
      <Nav.Item><Nav.Link eventKey="not_processed">Not processed</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="processed">Processed</Nav.Link></Nav.Item>
    </Nav>
    <Tab.Content>
      <Tab.Pane eventKey="not_processed">
        <div className='tab-container'>
          <h2>Transactions ({is_not_processed_by_accounting.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>Administration ID</th>
                <th>Location ID</th>
                <th>Transaction ID</th>
                <th>Creation</th>
                <th>Is processed by accounting</th>
                <th>Available in snelstart based on accounting</th>
                <th>Accounting response</th>
                <th>Available in snelstart based on snelstart</th>
                <th>Snelstart response</th>
              </tr>
            </thead>
            <tbody>
              {is_not_processed_by_accounting}
            </tbody>
          </Table>
          {(role === 'super' || role === 'admin') && <Button size="sm" type='button' onClick={() => { exportButton.onClick(results.is_not_processed_by_accounting, 'is_not_processed_by_accounting') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="processed">
        <div className='tab-container'>
          <h2>Transactions ({is_processed_by_accounting.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>Administration ID</th>
                <th>Location ID</th>
                <th>Transaction ID</th>
                <th>Creation</th>
                <th>Is processed by accounting</th>
                <th>Available in snelstart based on accounting</th>
                <th>Accounting response</th>
                <th>Available in snelstart based on snelstart</th>
                <th>Snelstart response</th>
              </tr>
            </thead>
            <tbody>
              {is_processed_by_accounting}
            </tbody>
          </Table>
          {<Button size="sm" type='button' onClick={() => { exportButton.onClick(results.is_processed_by_accounting, 'is_processed_by_accounting') }}>{translate(exportButton.tag)}</Button>}
        </div>
      </Tab.Pane>
    </Tab.Content>
  </Tab.Container>
  );
}
