import { Button, Table } from 'react-bootstrap'
import AdviceButtons from '../portal/stock/advicebuttons';
import WarehousePurchaseAdviceModal from './warehousePurchaseAdviceModal';
import { useTranslation } from 'react-i18next';
import PaginationAndSizeComponent from './paginationAndSize';
import CreateFieldTable from '../helpers/createFieldTable';
import { StockProductTableFormFields } from '../portal/stock/stockProductFormFields';

export default function WarehouseAdviceTable(adviceList) {
  const list = adviceList.content
  const buttons = AdviceButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const fields = StockProductTableFormFields();
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let pages = adviceList?.total_pages
  let page = adviceList?.number + 1

  let adviceRows = []
  if (list) {
    for (let product of Object.values(list)) {
      adviceRows.push(
        <tr className='category-row' key={product.id}>
          <td>{product.name ?? "N/A"}</td>
          {
            fields.map(field => (
              CreateFieldTable(field, product, buttons)
            ))
          }
          <td>{product.total_quantity ?? "N/A"}</td>
          <td>{product.purchase_advice ?? "N/A"}</td>
          <td>{product.packaging ?? "1"}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(product) }}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(product) }}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table width='100%'>
        <thead key='header'>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Minimum stock')}</th>
            {/* <th>{translate('Maximum stock')}</th> */}
            <th>{translate('Stock')}</th>
            <th>{translate('Purchase advice')}</th>
            <th>{translate('Order unit')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {adviceRows}
        </tbody>
        <WarehousePurchaseAdviceModal modal={modalview.modal} />
      </Table>
    </>
  );
}