import { createSlice } from "@reduxjs/toolkit";

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    hash_subscriptions_all: {},
    hash_subscriptiontypes: {},
  },
  reducers: {
    setSubscriptionId: (state, action) => {
      state.subscription_id = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload
    },
    setSubscriptionsAll: (state, action) => {
      state.subscriptions_all = action.payload
      if (action.payload) {
        action?.payload?.forEach(subscription => {
          state.hash_subscriptions_all[subscription.id] = subscription
        });
      }
    },
    setSubscriptionModalShow: (state, action) => {
      state.subscription_modalshow = action.payload
    },
    setSubscriptionCopyModalShow: (state, action) => {
      state.subscription_copy_modalshow = action.payload
    },
    setSubscriptionPagination: (state, action) => {
      state.subscription_pagination = (action.payload - 1)
    },
    setSubscriptionPageSize: (state, action) => {
      state.subscription_pagesize = action.payload
    },
    setSubscriptionTypes: (state, action) => {
      state.subscriptiontypes = action.payload
      if (action.payload) {
        action?.payload?.forEach(type => {
          state.hash_subscriptiontypes[type.id] = type
        });
      }
    },
    setSubscriptionRefresh: (state, action) => {
      state.refresh = action.payload
    }
  }
});

export const {
  setSubscriptionId, 
  setSubscription, 
  setSubscriptions, 
  setSubscriptionModalShow, 
  setSubscriptionCopyModalShow, 
  setSubscriptionPagination, 
  setSubscriptionPageSize, 
  setSubscriptionsAll, 
  setSubscriptionTypes,
  setSubscriptionRefresh
} = subscriptionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getSubscriptionId = (state) => state.subscription.subscription_id
export const getSubscription = (state) => state.subscription.subscription
export const getSubscriptions = (state) => state.subscription.subscriptions
export const getSubscriptionsAll = (state) => state.subscription.subscriptions_all
export const getHashSubscriptionsAll = (state) => state.subscription.hash_subscriptions_all
export const getSubscriptionsHighlights = (state) => state.subscription.subscriptionshighlights
export const getSubscriptionModalShow = (state) => state.subscription.subscription_modalshow
export const getSubscriptionCopyModalShow = (state) => state.subscription.subscription_copy_modalshow
export const getSubscriptionPagination = (state) => state.subscription.subscription_pagination
export const getSubscriptionPagesize = (state) => state.subscription.subscription_pagesize
export const getSubscriptionTypes = (state) => state.subscription.subscriptiontypes
export const getHashSubscriptionTypes = (state) => state.subscription.hash_subscriptiontypes
export const getSubscriptionRefresh = (state) => state.subscription.refresh

export default subscriptionSlice.reducer;