import axios from "axios";

const PutProduct = ({ token, admin_id, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/product`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      data: data
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
        console.log("error:", error)
      });
  });
};

export default PutProduct