import { Pie } from 'react-chartjs-2';

export const salesReportGraph = (report) => {
  let graphCardData = <p>No data</p>

  if (report.cumulated && Object.keys(report.cumulated).length > 0) {

    let graphLabels = []
    let totalData = []
    let quantityData = []
    let backGroundColor = []
    let borderColor = []

    for (const [, journalData] of Object.entries(report.cumulated).sort(([a, adata], [b, bdata]) => bdata.totals.total - adata.totals.total)) {
      if (journalData.toplevel) {
        let color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
        graphLabels.push(journalData.name)
        totalData.push(journalData.totals.total)
        quantityData.push(journalData.totals.amount)

        backGroundColor.push(color)
        borderColor.push(color)
      }
    }
    
    const graphDatasetAmount = [
      {
        label: 'Value',
        data: totalData,
        borderColor: borderColor,
        backgroundColor: backGroundColor,
      },
    ]

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
        }
      },
    };

    const dataAmount = {
      labels: graphLabels,
      datasets: graphDatasetAmount
    }

    graphCardData =  <div className='pie-container'><Pie options={options} data={dataAmount} /></div>
  }
  
  return graphCardData
};