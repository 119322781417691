import GetProductById from "../actablueAPI/products/GetProductByID";
import { setProgressData } from "../redux/dataSlices/reportingSlice";

export const CreateSalesReportMultipleDaysExport = async (props) => {
  const token = props.token
  let dummy = []
  let exportData = []

  exportData = await topLevel(props, dummy)

  async function topLevel() {
    const cumulated = props.report.cumulated
    const hashCategories = props.report.hashCategories
    const exportCategoryId = props.categoryId
    
    const total = Object.keys(cumulated).length
    let counter = 0
    props.progressData = {title:'Creating data for export/pdf, please wait...', progress:0, current:counter, total:total}
    props.dispatch(setProgressData(props.progressData))
  
    let topLevelRows = []
    for (const [categoryId, categoryData] of Object.entries(cumulated)) {
      counter = counter + 1
      const progress = (counter*100)/total
      props.progressData = {title:'Creating data for export/pdf, please wait...', progress:progress, current:counter, total:total}
      props.dispatch(setProgressData(props.progressData))
  
      if ((categoryData.toplevel && exportCategoryId === undefined) || String(exportCategoryId) === String(categoryId)) {
        let baseLine = {}
        // baseLine.day = day
        baseLine.journal = categoryData.name || 'No journal'
  
        // add child journals to newLine
        await addChildData(baseLine, false, categoryId, hashCategories, cumulated, topLevelRows)
  
      }
    }
    return topLevelRows
  }
  
  async function addChildData(baseLine, passedLine, categoryId, hashCategories, cumulated, topLevelRows) {
    if (hashCategories[categoryId]?.hasOwnProperty('children') && hashCategories[categoryId].children.length > 0) {
      const total = hashCategories[categoryId].children.length
      let counter = 0
      props.dispatch(setProgressData({...props.progressData, ...{subTitle:'Creating child data for export/pdf, please wait...', subProgress:0, subCurrent:counter, subTotal:total}}))
  
      for (const child of hashCategories[categoryId].children) {
        counter = counter + 1
        const progress = (counter*100)/total
        props.dispatch(setProgressData({...props.progressData, ...{subTitle:'Creating child data for export/pdf, please wait...', subProgress:progress, subCurrent:counter, subTotal:total}}))
      
        if (cumulated[child.id]) {
          let newLine = {}
          if (passedLine) { newLine = Object.assign({}, passedLine) }
          if (!passedLine) { newLine = Object.assign({}, baseLine) }
          let subJournalName = createTableHeaderSubJournal(newLine)
          newLine[subJournalName] = hashCategories[child.id]?.title
          await addChildData(baseLine, newLine, child.id, hashCategories, cumulated, topLevelRows)
        }
      }
    }
  
    if (cumulated[categoryId].bottomlevel) {
      await AddProductData(baseLine, passedLine, cumulated[categoryId], topLevelRows)
    }
  }
  
  async function AddProductData(baseLine, passedLine, lowest, topLevelRows) {
    const total = Object.keys(lowest.products).length
    let counter = 0
    props.dispatch(setProgressData({...props.progressData, ...{subTitle:'Creating product data for export/pdf, please wait...', subProgress:0, subCurrent:counter, subTotal:total}}))
  
    const admin = props.admin
  
    // sort products based on quantity
    let sorted = Object.entries(lowest.products).sort(([productAId, productA] , [productBId, productB]) => productB?.amount - productA?.amount)
  
    // for (let [productId, product] of Object.entries(lowest.products)) {
    for (let [productId, product] of Object.values(sorted)) {
      counter = counter + 1
      const progress = (counter*100)/total
      props.dispatch(setProgressData({...props.progressData, ...{subTitle:'Creating product data for export/pdf, please wait...', subProgress:progress, subCurrent:counter, subTotal:total}}))
    
      let completeProduct = {}

      await GetProductById({token:token, admin_id:admin.id, id:productId})
      .then(response => {
        // console.log('response', response)
        completeProduct = response.data
      })
  
      let newLine = {}
      if (passedLine) { newLine = Object.assign({}, passedLine) }
      if (!passedLine) { newLine = Object.assign({}, baseLine) }
  
      newLine.productSKU = completeProduct.sku_code
      newLine.product = completeProduct.name
      // add ean
      if (completeProduct.eans) {
        let eancounter = 0
        for (const ean of completeProduct.eans) {
          eancounter = eancounter + 1
          const header = `ean${eancounter}`
          newLine[header] = ean.ean_code
        }
      }
      // add packaging
      newLine.packaging = completeProduct.product_specifications?.packaging
      newLine.productPercentage = product.percentage
      newLine.productQuantity = product.amount
      newLine.productTotal = product.total
  
      // marge 
      if (completeProduct.suppliers) {
        let margecounter = 0
        for (const supplier of completeProduct.suppliers) {
          let marge = 0
          const supplierprice = supplier.supplier_price
          const salesprice = product.total / product.amount
          marge = (salesprice - supplierprice) * product.amount
          const margeheader = `marge${margecounter}`
          newLine[margeheader] = marge
          let margePercentage = 0
          const salescost = supplierprice * product.amount
          margePercentage = ((product.total - salescost) * 100) / product.total
          const margePercentageHeader = `%marge${margecounter}`
          newLine[margePercentageHeader] = margePercentage
        }
        }
      else {
        newLine['marge0'] = 'No supplier'
        newLine['%marge0'] = 'No supplier'
      }
      
      if (!dummy[0] || Object.keys(newLine).length > Object.keys(dummy[0]).length) {
        dummy[0] = Object.assign({}, newLine)
      }
  
      topLevelRows.push(newLine)
    }
  }
  
  function createTableHeaderSubJournal(newLine) {
    const baseName = 'subJournal'
    let newName = undefined
    for (const [key, value] of Object.entries(newLine)) {
      if (key.includes(baseName)) {
        // check if last char is number, if so add 1 for new header
        if (!isNaN(key.slice(-1))) { newName = baseName + (Number(key.slice(-1)) + 1) }
      }
      else {
        newName = 'subJournal1'
      }
    }
    return newName
  }


  // first object
  let firstObject = exportData[0]
  let newFirstObject = {}
  for (const key of Object.keys(dummy[0])) {
    newFirstObject[key] = firstObject[key]
  }
  exportData[0] = Object.assign({}, newFirstObject)

  return exportData
};

