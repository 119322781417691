import axios from "axios";

const GetWarehouseProductsPurchaseAdviceList = ({ token, admin_id, location_id, supplier_id, relation_id, page = 0, size = 25 }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/warehouse/products/purchaseadvicelist`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id,
        'location': location_id,
        'supplier': supplier_id,
        'relation': relation_id
      },
      params: {
        page: page,
        pagesize: size
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default GetWarehouseProductsPurchaseAdviceList