import Table from 'react-bootstrap/Table';

export default function InvoicelineTable(props) {

  let newInvoiceLines = []
  if (props.invoice.invoice_lines) {
    for(const invoiceline of Object.values(props.invoice.invoice_lines)) {
      newInvoiceLines.push(
        <tr key={invoiceline.id}>
          <td>{invoiceline.quantity}</td>
          <td>{invoiceline.product_name}</td>
          <td align="right">{Number(invoiceline.amount_inclusive_vat).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        </tr>
      )
      if (invoiceline.attributes) {
        for (const attribute of Object.values(invoiceline.attributes)) {
          newInvoiceLines.push(
            <tr key={attribute.id}>
              <td>{attribute.quantity}</td>
              <td>{attribute.product_name}</td>
              <td align="right">{Number(attribute.amount_inclusive_vat).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            </tr>
          )
        }
      }
    }
  }

  return (
    <Table hover width='100%'>
      <thead>
        <tr>
          <th width='20%'>#</th>
          <th width='60%'>Item</th>
          <th width='20%'>Price</th>
        </tr>
      </thead>
      <tbody>
        {newInvoiceLines}
      </tbody>
    </Table>
  );
}