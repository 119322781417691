import { useSelector } from "react-redux"
import { getRole } from "../redux/dataSlices/tokenSlice"
import { getSelectedLocation_id } from "../redux/dataSlices/selectLocationSlice"
import { getSelectedAdmin_id } from "../redux/dataSlices/selectAdminSlice"

export const ImportTypeChoices = () => {
  const role = useSelector(getRole)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)

  const importTypeChoices = []

  if (location_id) {
    importTypeChoices.push({ id: 'products', name: 'products' })
  }

  if (admin_id) {
    importTypeChoices.push({ id: 'products+locations', name: 'products for all locations' })
    if (role === 'super' || role === 'admin'){
      importTypeChoices.push({ id: 'actablueExportFile', name: 'actablue export file' })
      importTypeChoices.push({ id: 'stock', name: 'stock'})
      // importTypeChoices.push({ id: 'relations', name: 'relations'})
    }
  }

  return importTypeChoices
} 

export const importDynamicChoices = [
    { id: 'ean', name: 'ean' },
]

export const ImportUniqueId = () => {
  const role = useSelector(getRole)

  let importUniqieIdChoise = [
    { id: 'EAN', name: 'EAN' },
    { id: 'SKU', name: 'SKU' },
  ]

  if (role === 'super' || role === 'admin'){
    importUniqieIdChoise.push({ id: 'ACTABLUE', name: 'ACTABLUE' })
  }

  return importUniqieIdChoise
} 

export const ImportDoubleSkuEan = () => {
  const role = useSelector(getRole)

  let importDoubleSkuEan = [
    { id: 'NOTHING', name: 'NOTHING' },
    { id: 'DEACTIVATE', name: 'DEACTIVATE' },
  ]

  if (role === 'super' || role === 'admin'){
    importDoubleSkuEan.push({ id: 'DELETE', name: 'DELETE' })
  }

  return importDoubleSkuEan
} 

export const ImportOnlyNew = () => {
  let importOnlyNew = [
    { id: 'NO', name: 'NO' },
    { id: 'YES', name: 'YES' },
  ]
  return importOnlyNew
} 

export const ImportJournalCategories = () => {
  let importJournalCategories = [
    { id: 'NO', name: 'NO' },
    { id: 'YES', name: 'YES' },
  ]
  return importJournalCategories
} 