import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getCount, getHashDrawersAll } from '../redux/dataSlices/cashcountSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import './css/countModal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';
import { getHashUsersAll } from '../redux/dataSlices/userSlice';
import { getHashEmployeesAll } from '../redux/dataSlices/employeeSlice';


export default function CountModal(props) {
  const object = useSelector(getCount);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const hash_employees_all = useSelector(getHashEmployeesAll);
  const hash_users_all = useSelector(getHashUsersAll);
  const hash_drawers_all = useSelector(getHashDrawersAll)
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            {translate('Cashcount')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="counted">{translate('Counted')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Counted by')}: {hash_employees_all[object?.employee_id]?.name ?? 'Unknown User/Employee'}</p>
                      <p>{translate('Amount counted')}: {Number(object?.counted?.total).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                      <p>{translate('Drawer')}: {hash_drawers_all[object?.drawer_id]?.name ?? 'Unknown Drawer'}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Administration')}: {hash_admins_all[object?.admin_id]?.name ?? 'Unknown Administration'}</p>
                      <p>{translate('Location')}: {hash_locations_all[object?.location_id]?.name ?? 'Unknown Location'}</p>
                      <p>{translate('Counted on')}: {new Date(object?.creation).toLocaleString()}</p>
                      <p>{translate('Modified at')}: {new Date(object?.modification).toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate('Checked')}: <span className='modal-icons'>{object?.checked ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</span></p>
                      {object?.checked_by && <p>{translate('Checked by')}: {hash_users_all[object?.checked_by]?.name ?? hash_employees_all[object?.checked_by]?.name ?? 'Unknown User/Employee'}</p>}
                    </div>
                    <div className="col">
                      <p>{translate('Accepted')}: <span className='modal-icons'>{object?.accepted ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</span></p>
                      {object?.accepted_by && <p>{translate('Accepted by')}: {hash_users_all[object?.accepted_by]?.name ?? hash_employees_all[object?.accepted_by]?.name ?? 'Unknown User/Employee'}</p>}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="counted">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>{translate('Counted currency')}</h4>
                  <div className="row">
                    <div className="col">
                      {object?.counted?.currency ?
                        <div className='counted-currency'>
                          {Object.keys(object?.counted?.currency).map((key) => {
                            const currencyType = object?.counted?.currency[key];
                            return (
                              <div className="row">
                                <div className="col">
                                  <div key={key}>
                                    <h5>{translate('Currency')}: {Number(currencyType?.value).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h5>
                                    <p>{translate('Amount')}: {currencyType?.count}</p>
                                    <p>{translate('Total')}:  {Number(currencyType?.total).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        :
                        <h5>{translate('No currency counted')}</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object?.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>admin_id: {object?.admin_id}</p>
                    </div>
                    <div className="col">
                      <p>location_id: {object?.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}