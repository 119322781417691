import PostTransactionSearchV2Minimal from "./PostTransactionSearchV2Minimal";

const GetAllTransactionsSearchV2Minimal = ({ token, admin_id, location_id, startDate, endDate, page = 0, payload = [], resolver = null }) => {

  console.log('startDate', startDate)
  console.log('endDate', endDate)

  // solution using Date
  let searchObject = {
    date_range: {
      from: startDate.toISOString('YYYY-MM-DDT00:00:00.000Z'),
      to: endDate.toISOString('YYYY-MM-DDT23:59:59.999Z'),
      field: "creation"
    },
    limit: 25
  }

  return new Promise((resolve, reject) => {
    PostTransactionSearchV2Minimal({ token: token, admin_id: admin_id, location_id: location_id, page: page, searchObject: searchObject })
      .then(response => {
        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }
        if (!response.data.last) {
          GetAllTransactionsSearchV2Minimal({
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            startDate: startDate,
            endDate: endDate,
            page: response.data.number + 1,
            payload: updatedPayload,
            resolver: resolver || resolve
          })
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => {
        reject(error)
      });
  });
};

export default GetAllTransactionsSearchV2Minimal