import { createSlice } from "@reduxjs/toolkit";
import GetPayloadFromToken from "../../helpers/getPayloadFromToken";

const rememberMe = JSON.parse(localStorage.getItem('rememberMe')) || false;
export const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token: rememberMe ?
      (localStorage.getItem('token') !== 'undefined' && JSON.parse(localStorage.getItem('token'))) :
      (sessionStorage.getItem('token') !== 'undefined' && JSON.parse(sessionStorage.getItem('token'))),
    role: rememberMe ?
      (localStorage.getItem('role') !== 'undefined' && JSON.parse(localStorage.getItem('role'))) :
      (sessionStorage.getItem('role') !== 'undefined' && JSON.parse(sessionStorage.getItem('role'))),
    rememberMe: rememberMe
  },
  reducers: {
    setToken: (state, action) => {
      if (action.payload) {
        let token = Object.assign({}, action.payload)
        const [payload, role] = GetPayloadFromToken(action.payload)
        token.payload = payload
        token.role = role
        state.token = token;
        state.role = role;
        if (!state.rememberMe) {
          sessionStorage.setItem('token', JSON.stringify(token));
          sessionStorage.setItem('role', JSON.stringify(role));
        } else {
          localStorage.setItem('token', JSON.stringify(token));
          localStorage.setItem('role', JSON.stringify(role));
        }
      }
      if (!action.payload) {
        state.token = undefined;
        state.role = undefined;
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      }
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload
      localStorage.setItem('rememberMe', JSON.stringify(action.payload));
    },
  }
});

export const {
  setToken, setRememberMe
} = tokenSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getToken = (state) => state.token.token
export const getRole = (state) => state.token.role
export const getRememberMe = (state) => state.token.rememberMe

export default tokenSlice.reducer;