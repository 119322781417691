import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSnelstartCompany, getSnelstartTemplates, setSnelstartCompany, setSnelstartTemplates } from "../../redux/dataSlices/snelstartSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import SnelstartForm from "../../components/snelstartForm";
import { snelstartCompanyFormFields } from "./snelstartFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import GetSnelstartTemplates from "../../actablueAPI/snelstart/GetSnelstartTemplates";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { syncVerkoopOrderProcesStatusChoices } from "../../selectfieldchoices/snelstartSelect.mjs";
import PostSnelstartCompany from "../../actablueAPI/snelstart/PostSnelstartCompany";

const SnelstartEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const snelstart_company = useSelector(getSnelstartCompany)
  const snelstartTemplates = useSelector(getSnelstartTemplates)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (event) => {
    let newCompany = HandleOnChange({ event: event, object: snelstart_company })
    dispatch(setSnelstartCompany(newCompany))
  }

  const onDefaultOrderSjabloonChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_company, selectfield: 'default_verkoop_order_sjabloon' })
    dispatch(setSnelstartCompany(newCompany))
  };

  const onDirectPaymentSjabloonChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_company, selectfield: 'direct_payment_verkoop_order_sjabloon' })
    dispatch(setSnelstartCompany(newCompany))
  };

  const onOnAcountOrderSjabloonChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_company, selectfield: 'invoice_verkoop_order_sjabloon' })
    dispatch(setSnelstartCompany(newCompany))
  };

  const onSyncVerkoopOrderProcesStatusChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_company, selectfield: 'sync_verkoop_order_proces_status' })
    dispatch(setSnelstartCompany(newCompany))
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostSnelstartCompany({token:token, admin_id:admin_id, identifier:snelstart_company.snelstart_administratie_identifier , data:snelstart_company})
    .then(response => {
      navigate("/snelstart");
    })
};

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: snelstartCompanyFormFields,
    field: {
      onChange: onChange
    },
    data: snelstart_company,
    default_verkoop_order_sjabloon: {
      label: 'default_verkoop_order_sjabloon',
      options: snelstartTemplates,
      selected: [],
      onChange: onDefaultOrderSjabloonChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    direct_payment_verkoop_order_sjabloon: {
      label: 'direct_payment_verkoop_order_sjabloon',
      options: snelstartTemplates,
      selected: [],
      onChange: onDirectPaymentSjabloonChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    invoice_verkoop_order_sjabloon: {
      label: 'invoice_verkoop_order_sjabloon',
      options: snelstartTemplates,
      selected: [],
      onChange: onOnAcountOrderSjabloonChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    sync_verkoop_order_proces_status: {
      label: 'sync_verkoop_order_proces_status',
      options: syncVerkoopOrderProcesStatusChoices,
      onChange: onSyncVerkoopOrderProcesStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: "Snelstart edit ",
    data: <SnelstartForm {...formData} />,
  };

  useEffect(() => {
    GetSnelstartTemplates({token:token, admin_id:admin_id, location_id:location_id})        
    .then(response => {
      dispatch(setSnelstartTemplates(response.data))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default SnelstartEdit;

