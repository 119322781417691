import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const TemplateFormFields = () => {
  const role = useSelector(getRole);

  const advancedFormFields = [
    { type: 'number', name: `Height px`, id: 'height_px', md: 6 },
    { type: 'number', name: `Width px`, id: 'width_px', md: 6 },
    { type: 'number', name: `Title X`, id: 'title_x', md: 4 },
    { type: 'number', name: `Title Y`, id: 'title_y', md: 4 },
    { type: 'number', name: `Title width`, id: 'title_width', md: 4 },
    { type: 'number', name: `Description X`, id: 'contents_x', md: 4 },
    { type: 'number', name: `Description Y`, id: 'contents_y', md: 4 },
    { type: 'number', name: `Description width`, id: 'contents_width', md: 4 },
    { type: 'number', name: `Price X`, id: 'price_x', md: 4 },
    { type: 'number', name: `Price Y`, id: 'price_y', md: 4 },
    { type: 'number', name: `Price width`, id: 'price_width', md: 4 },
    { type: 'number', name: `Code X`, id: 'code_x', md: 3 },
    { type: 'number', name: `Code Y`, id: 'code_y', md: 3 },
    { type: 'number', name: `Code width px`, id: 'code_width_px', md: 3 },
    { type: 'number', name: `Code height px`, id: 'code_height_px', md: 3 },
    { type: 'number', name: `Border 1 X`, id: 'border_primary_x', md: 3 },
    { type: 'number', name: `Border 1 Y`, id: 'border_primary_y', md: 3 },
    { type: 'number', name: `Border 1 width px`, id: 'border_primary_width_px', md: 3 },
    { type: 'number', name: `Border 1 height px`, id: 'border_primary_height_px', md: 3 },
    { type: 'number', name: `Border 2 X`, id: 'border_secondary_x', md: 3 },
    { type: 'number', name: `Border 2 Y`, id: 'border_secondary_y', md: 3 },
    { type: 'number', name: `Border 2 width px`, id: 'border_secondary_width_px', md: 3 },
    { type: 'number', name: `Border 2 height px`, id: 'border_secondary_height_px', md: 3 },
  ]

  const templateFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'base_label', id: 'base_label', readonly: true },

    { type: 'text', name: `Name`, id: 'name', md: 12 },

    { type: 'checkbox', name: `Text centered`, id: 'text_centered', md: 3 },
    { type: 'checkbox', name: `Border 1`, id: 'include_border_primary', md: 3 },
    { type: 'checkbox', name: `Border 2`, id: 'include_border_secondary', md: 3 },
    { type: 'checkbox', name: `Include images`, id: 'include_images', md: 3 },

    { type: 'number', name: 'Height mm', id: 'height_mm', md: 6, step: .01 },
    { type: 'number', name: `Width mm`, id: 'width_mm', md: 6, step: .01 },

    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type', md: 4 },
    { type: 'select', name: `ESL Type`, id: 'esl_model', formdatafield: 'esl_model', md: 4 },
    { type: 'select', name: `Text align`, id: 'text_align', formdatafield: 'text_align', md: 4 },
    { type: 'number', name: `Title fontsize`, id: 'title_fontsize_px', md: 4 },
    { type: 'select', name: `Title font`, id: 'title_text_font', formdatafield: 'title_text_font', md: 4 },
    { type: 'color', name: `Title color`, id: 'title_text_color', md: 4 },
    { type: 'checkbox', name: `Title bold`, id: 'title_text_bold', md: 4 },
    { type: 'checkbox', name: `Title italic`, id: 'title_text_italic', md: 8 },
    { type: 'number', name: `Description fontsize`, id: 'contents_fontsize_px', md: 4 },
    { type: 'select', name: `Description font`, id: 'contents_text_font', formdatafield: 'contents_text_font', md: 4 },
    { type: 'color', name: `Description color`, id: 'contents_text_color', md: 4 },
    { type: 'checkbox', name: `Description bold`, id: 'contents_text_bold', md: 4 },
    { type: 'checkbox', name: `Description italic`, id: 'contents_text_italic', md: 8 },
    { type: 'number', name: `Price fontsize`, id: 'price_fontsize_px', md: 4 },
    { type: 'select', name: `Price font`, id: 'price_text_font', formdatafield: 'price_text_font', md: 4 },
    { type: 'color', name: `Price color`, id: 'price_text_color', md: 4 },
    { type: 'checkbox', name: `Price bold`, id: 'price_text_bold', md: 4 },
    { type: 'checkbox', name: `Price italic`, id: 'price_text_italic', md: 8 },
    // { type: 'checkbox', name: `Title underlined`, id: 'title_text_underlined' },
    // { type: 'checkbox', name: `Title strikethrough`, id: 'title_text_strikethrough' },
    // { type: 'checkbox', name: `Description underlined`, id: 'contents_text_underlined' },
    // { type: 'checkbox', name: `Description strikethrough`, id: 'contents_text_strikethrough' },
    // { type: 'checkbox', name: `Price underlined`, id: 'price_text_underlined' },
    // { type: 'checkbox', name: `Price strikethrough`, id: 'price_text_strikethrough' },
    { type: 'object', name: `Advanced`, id: 'advanced', fields: advancedFormFields },
  ]


  if (role === 'super' || role === 'admin') {
    templateFormFields.splice(3, 1, // overwrite index 3 
      { type: 'checkbox', name: `Base label`, id: 'base_label', md: 12 },
    )
  }

  return templateFormFields
}

export const TemplateElementFormFields = (type) => {
  const templateElementFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'template_id', id: 'template_id', readonly: true },
    { type: 'text', name: `Name`, id: 'name', md: 6 },
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type', md: 6 },
    { type: 'number', name: `X`, id: 'x', md: 3 },
    { type: 'number', name: `Y`, id: 'y', md: 3 },
    { type: 'number', name: `Width`, id: 'width', md: 3 },
    { type: 'number', name: `Height`, id: 'height', md: 3 },
    { type: 'number', name: `Opacity`, id: 'opacity', min: 0, max: 255, md: 3 },
    { type: 'number', name: `Outline size`, id: 'outline_size', min: 0, md: 3 },
    { type: 'color', name: `Fill`, id: 'fill', md: 3 },
    { type: 'color', name: `Outline color`, id: 'outline_color', md: 3 },
    { type: 'hidden', name: `Text type`, id: 'text_type', formdatafield: 'text_type', md: 6 }, // Select
    { type: 'hidden', name: `Text`, id: 'text', md: 6 }, // Text
    { type: 'hidden', name: `Text fontsize`, id: 'text_fontsize', md: 6 }, // Number
    { type: 'hidden', name: `Text font`, id: 'text_font', formdatafield: 'text_font', md: 6 }, // Select
    { type: 'hidden', name: `Text bold`, id: 'text_bold', md: 6 }, // Checkbox
    { type: 'hidden', name: `Text italic`, id: 'text_italic', md: 6 }, // Checkbox
    // { type: 'checkbox', name: `Text underlined`, id: 'text_underlined', md: 3 },
    // { type: 'checkbox', name: `Text strikethrough`, id: 'text_strikethrough', md: 3 },
  ]

  switch (type) {
    case 'rectangle':
    case 'circle':
    case 'triangle':
    case 'cross':
      break;
    case 'text':
      templateElementFormFields.splice(-6, 1, { type: 'select', name: `Text type`, id: 'text_type', formdatafield: 'text_type', md: 6 })
      templateElementFormFields.splice(-5, 1, { type: 'text', name: `Text`, id: 'text', md: 6 })
      templateElementFormFields.splice(-4, 1, { type: 'number', name: `Text fontsize`, id: 'text_fontsize', md: 6 })
      templateElementFormFields.splice(-3, 1, { type: 'select', name: `Text font`, id: 'text_font', formdatafield: 'text_font', md: 6 })
      templateElementFormFields.splice(-2, 1, { type: 'checkbox', name: `Text bold`, id: 'text_bold', md: 6 })
      templateElementFormFields.splice(-1, 1, { type: 'checkbox', name: `Text italic`, id: 'text_italic', md: 6 })
      break;
    case 'qrcode':
    case 'barcode':
      templateElementFormFields.splice(8, 1, { type: 'hidden', name: `Opacity`, id: 'opacity', min: 0, max: 255, md: 3 })
      templateElementFormFields.splice(9, 1, { type: 'hidden', name: `Outline size`, id: 'outline_size', min: 0, md: 3 })
      templateElementFormFields.splice(10, 1, { type: 'hidden', name: `Fill`, id: 'fill', md: 3 })
      templateElementFormFields.splice(11, 1, { type: 'hidden', name: `Outline color`, id: 'outline_color', md: 3 })
      templateElementFormFields.splice(-5, 1, { type: 'text', name: `Text`, id: 'text', md: 6 })
      break;
    default:
      break;
  }

  return templateElementFormFields
}

