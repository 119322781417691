import axios from "axios";
import moment from "moment"

const GetProductsPerMonth = ({ token, admin_id, location_id, startDate, location, signal }) => {

  return new Promise((resolve, reject) => {
    let year = moment.unix(startDate).year()
    let month = moment.unix(startDate).month() + 1 // returns index !!
    let timezone = location?.timezone ? location?.timezone : "Europe/Amsterdam"

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/reporting/v2/sales/products/month"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        'tz': timezone,
        'year': year,
        'month': month
      },
      signal: signal
    })
      .then(salesReportResponse => {
        resolve(salesReportResponse)
      })
      .catch(error => {
        reject(error)
      });
  })

};

export default GetProductsPerMonth
