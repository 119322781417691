import axios from "axios";

const GetCategories = ({ token, admin_id, location_id, page = 1, size = 25 }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/kitchenmanager/category/all`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id,
        'page': page,
        'size': size
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default GetCategories