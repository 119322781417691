export const SearchSelectChoices = () => {

  const searchSelectChoices = [
    { id: 'administration_id', name: 'Administration ID' },
    { id: 'location_id', name: 'Location ID' },
    { id: 'device_id', name: 'Device ID' },
    { id: 'device_uid', name: 'Device UID' },
    { id: 'user_id', name: 'User ID' },
    { id: 'relation_id', name: 'Relation ID' },
    { id: 'product_id', name: 'Product ID' },
    { id: 'category_id', name: 'Category ID' },
    { id: 'vat_id', name: 'Vat ID' },
    { id: 'order_id', name: 'Order ID' },
    { id: 'invoice_id', name: 'Invoice ID' },
    { id: 'transaction_id', name: 'Transaction ID' },
  ]
  return searchSelectChoices
}
 