export const reservationFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'user_id', id: 'user_id', readonly: true },
  { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
  { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
  { type: 'text', name: 'Name', id: 'reservation_name', required: true },
  { type: 'email', name: 'Email', id: 'reservation_email', required: true },
  { type: 'datetime-local', name: 'Date', id: 'reservation_timestamp', step: '15', required: true },
  { type: 'number', name: 'Duration', id: 'duration', required: true },
  { type: 'text', name: 'Number of people', id: 'number_of_people', required: true },
  { type: 'text', name: 'Phonenumber', id: 'reservation_phonenumber', required: true },
  { type: 'text', name: 'Remark', id: 'remark', required: false },
  { type: 'text', name: 'Preferred sitting location', id: 'preferred_sitting_location', required: false },

  // single option select fields  
  { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', required: true },
  { type: 'select', name: 'Status', id: 'status', formdatafield: 'status', required: true },

  // multi option select fields
  { type: 'multiselect', name: 'Reservationobjects', id: 'reservation_objects', formdatafield: 'reservation_objects' , required: false },

  // not adjustable fields
  // {type:'datetime-local', name:'creation', id:'creation' , readonly:true},
  // {type:'datetime-local', name:'modification', id:'modification' , readonly:true},  
]