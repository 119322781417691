import { setTaskResults } from "../redux/dataSlices/analyseSlice";
import GetAllCategoriesWithType from '../actablueAPI/products/getAllCategoriesWithType';
import PutProduct from "../actablueAPI/products/PutProduct";
import useHandleError from "../customhooks/useHandleError";

export function FixVatDifference({ token, admin_id, location_id, exportdata, taskResults, dispatch }) {
  const HandleError = useHandleError();
  GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id })
    .then(async response => {
      let categoryHash = []
      response.forEach(category => {
        categoryHash[category.id] = category
      });
      for (const product of exportdata) {
        let newProduct = JSON.parse(JSON.stringify(product))
        newProduct.vat = product.journalcategory.vat
        await PutProduct({ token: token, admin_id: admin_id, data: newProduct })
          .then(async (response) => {
            await removeFromTaskDataResults({ productToBeRemoved: response.data, table: 'vatdifference' })
          })
      }
    })
    .catch(error => { HandleError({ error: error }) })
  // .finally(() => {removeFromTaskDataResults({productToBeRemoved:response.data, table:'vatdifference'})})

  async function removeFromTaskDataResults({ productToBeRemoved, table }) {
    if (taskResults.results?.[table]) {
      let newTaskResults = JSON.parse(JSON.stringify(taskResults))
      let newTaskTable = []
      for (const product of newTaskResults.results[table]) {
        if (product.id !== productToBeRemoved.id) {
          newTaskTable.push(product)
        }
      }
      newTaskResults.results[table] = newTaskTable
      dispatch(setTaskResults(newTaskResults))
    }
  }
}

