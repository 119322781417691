import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import TemplateButtons from "../portal/shelflabels/templatebuttons";
import TemplateModal from "./templateModal";
import PaginationAndSizeComponent from './paginationAndSize';
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

export default function TemplateTable(templates) {
  const buttons = TemplateButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  pagesize['number_of_elements'] = templates.total

  if (templates && templates.items && templates.items.length > 0) {
    const pages = templates.pages
    const page = (templates.page)
    return (
      <>
        {add && <TableButton {...add} />}
        {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
        <Table hover width='100%'>
          <thead>
            <tr>
              <th>
                {translate('Name')}
              </th>
              <th>
                {translate('Type')}
              </th>
              <th>
                {translate('ESL Model')}
              </th>
              <th>
                {translate('Date')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {templates.items.map((template) => {
              const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: template.id }) : false
              const baselabeledit = buttons.hasOwnProperty('baselabeledit') ? Object.assign({}, buttons.baselabeledit, { id: template.id }) : false
              const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: template.id }) : false
              return (
                <tr className='product-row' key={template.id}>
                  <td>{template.name}</td>
                  <td>{template.type}</td>
                  <td>{template.esl_model}</td>
                  <td>
                    <p>
                      {translate('Created')}: {new Date(template.creation).toLocaleString()}
                    </p>
                    <p>
                      {translate('Modified')}: {new Date(template.modification).toLocaleString()}
                    </p>
                  </td>
                  <td>
                    <Button size="sm" type='button' onClick={() => { modalview.onClick(template) }} id={template.id}>{translate(modalview.tag)}</Button>
                    {template.base_label && baselabeledit && <Button size="sm" type='button' onClick={() => { baselabeledit.onClick(template) }} id={template.id}>{translate(baselabeledit.tag)}</Button>}
                    {!template.base_label && edit && <Button size="sm" type='button' onClick={() => { edit.onClick(template) }} id={template.id}>{translate(edit.tag)}</Button>}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <TemplateModal modal={modalview.modal} />
        </Table>
      </>
    );
  } else {
    return (
      <>
        {add && <TableButton {...add} />}
        <Table width='100%'>
          <thead>
            <tr>
              <th>
                {translate('Name')}
              </th>
              <th>
                {translate('Type')}
              </th>
              <th>
                {translate('ESL Model')}
              </th>
              <th>
                {translate('Date')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
        </Table>
      </>
    );
  }
}