import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReportingButtons from '../portal/reporting/reportingbuttons';

export default function StatusReportSingleDay(statusReport) {
  const buttons = ReportingButtons.call()
  const pdfButton = buttons.hasOwnProperty('pdf') ? Object.assign({}, buttons.pdf) : false
  
  let statusRowsDay = []
  let paymentRows = []
  let journalCategoryRows = []
  let vatRows = []

  const payments = statusReport['totals']
  const journals = statusReport['journals']
  const taxs = statusReport['taxs']
  const { t: translate } = useTranslation();

  if (payments) {
    let totalPaymentRow = ''
    for (let [payment, amount] of Object.entries(payments)) {
      if (payment !== 'total') {
        paymentRows.push(
          <tr key={payment}>
            <td>{payment}</td>
            <td align="right">{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
        )
      } 
      if (payment === 'total') {
        totalPaymentRow = (
          <tr key={payment}>
            <td>
              <strong>Total</strong>
            </td>
            <td align="right">
              <strong>{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong>
            </td>
          </tr>
        )
      }
    }
    paymentRows.push(totalPaymentRow)
  }

  if (journals) {
    let totalJournalsRow = ''
    for (let [journalId, journalObject] of Object.entries(journals)) {
      if (journalId !== 'total') {
        journalCategoryRows.push(
          <tr key={journalId}>
            <td>{journalObject.name}</td>
            <td align="right">{journalObject.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
        )
      }
      if (journalId === 'total') {
        totalJournalsRow = (
          <tr key={journalId}>
            <td>
              <strong>Total</strong>
            </td>
            <td align="right">
              <strong>{journalObject.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong>
            </td>
          </tr>
        )
      }
    }
    journalCategoryRows.push(totalJournalsRow)
  }

  if (taxs) {
    for (let [tax, amount] of Object.entries(taxs)) {
      vatRows.push(
        <tr key={tax}>
          <td>{tax}</td>
          <td align="right">{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        </tr>
      )
    }
  }

  statusRowsDay.push(
    <tr key='today'>
      <td>
        <Table hover width='100%'>
          <thead>
            <tr>
              <th width='50%'>{translate('Name')}</th>
              <th width='50%' className="text-end">{translate('Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {paymentRows}
          </tbody>
        </Table>
      </td>
      <td>
        <Table hover width='100%'>
          <thead>
            <tr>
              <th width='50%'>{translate('Name')}</th>
              <th width='50%' className="text-end">{translate('Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {journalCategoryRows}
          </tbody>
        </Table>
      </td>
      <td>
        <Table hover width='100%'>
          <thead>
            <tr>
              <th width='50%'>%</th>
              <th width='50%' className="text-end">{translate('Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {vatRows}
          </tbody>
        </Table>
      </td>
    </tr>
  )

  return (
    <>
      <Table width='100%'>
        <thead>
          <tr key='header'>
            <th width='30%'>
              {translate('Payments')}
            </th>
            <th width='30%'>
              {translate('Journal categories')}
            </th>
            <th width='30%'>
              {translate('Vats')}
            </th>
          </tr>
        </thead>
        <tbody>
          {statusRowsDay}
        </tbody>
      </Table>
      {/* {exportButton && <Button size="sm" type='button' onClick={() => { exportButton.onClick({report: statusReport, type:'statusReportSingleDay' }) }}>{translate(exportButton.tag)}</Button>} */}
      {pdfButton && <Button size="sm" type='button' onClick={() => { pdfButton.onClick({ report: statusReport, type:'statusReportSingleDay' }) }}>{translate(pdfButton.tag)}</Button>}
    </>
  )
}