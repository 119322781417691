import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import { useTranslation } from 'react-i18next';
import KeycloakUserModal from './keycloakUserModal';
import KeycloakUserButtons from '../portal/keycloak/keycloakuserbuttons';

import './css/productTable.css';

export default function KeycloakUserTable(props) {
  const keycloakUsers = props.users
  const refresh = props.refresh
  const buttons = KeycloakUserButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const disable = buttons.hasOwnProperty('disable') ? Object.assign({}, buttons.disable) : false
  const enable = buttons.hasOwnProperty('enable') ? Object.assign({}, buttons.enable) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const { t: translate } = useTranslation();

  let rows = []
  if (keycloakUsers) {
    for (const user of Object.values(keycloakUsers)) {
      rows.push(
        <tr className='product-row' key={user.id}>
          <td>{user.firstName} {user.lastName}</td>
          <td>{user.email}</td>
          <td>{JSON.stringify(user.enabled)}</td>
          <td>{user.createdTimestamp ? new Date(user.createdTimestamp).toLocaleString() : user.createdTimestamp}</td>
          <td>{user.attributes?.origin}</td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(user) }} id={user.id}>{translate(modalview.tag)}</Button>}
            {disable && user.enabled && <Button size="sm" type='button' onClick={() => { disable.onClick(user, refresh) }} id={user.id}>{translate(disable.tag)}</Button>}
            {enable && !user.enabled && <Button size="sm" type='button' onClick={() => { enable.onClick(user, refresh) }} id={user.id}>{translate(enable.tag)}</Button>}
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(user, refresh) }} id={user.id}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }
  
  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Email')}</th>
            <th>{translate('Enabled')}</th>
            <th>{translate('Created')}</th>
            <th>{translate('Origin')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <KeycloakUserModal modal={modalview.modal} />
    </>
  );
}