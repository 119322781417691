import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
  },
  reducers: {
    setSettingsMasterproduct: (state, action) => {
      state.settings_masterproduct = action.payload
    },
    setSettingsSchapkaartjes: (state, action) => {
      state.settings_schapkaartjes = action.payload
    },
    setSettingsPrinters: (state, action) => {
      state.printers = action.payload
    },
    setSettingsPrinter: (state, action) => {
      state.printer = action.payload
    },

  }
});

export const {
  setSettingsMasterproduct, setSettingsSchapkaartjes, setSettingsPrinters, setSettingsPrinter
} = settingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getSettingsMasterproduct = (state) => state.settings.settings_masterproduct
export const getSettingsSchapkaartjes = (state) => state.settings.settings_schapkaartjes
export const getSettingsPrinters = (state) => state.settings.printers
export const getSettingsPrinter = (state) => state.settings.printer

export default settingsSlice.reducer;