import { createSlice } from "@reduxjs/toolkit";

export const vatSlice = createSlice({
  name: "vat",
  initialState: {
    vat_pagesize: 10,
    vat_pagination: 0,
    hash_vats_all: {},
  },
  reducers: {
    setVatId: (state, action) => {
      state.vat_id = action.payload;
    },
    setVat: (state, action) => {
      state.vat = action.payload;
    },
    setVats: (state, action) => {
      state.vats = action.payload
    },
    setVatsAll: (state, action) => {
      state.vats_all = action.payload
      action?.payload?.forEach(vat => {
        state.hash_vats_all[vat.id] = vat
      });
    },
    editVats: (state, action) => {
      for (let vat in state.vats.content) {
        if (state.vats.content[vat].id === action.payload.id) {
          state.vats.content[vat] = action.payload
        }
      }
    },
    setVatModalShow: (state, action) => {
      state.vatmodalshow = action.payload
    },
    setVatPagination: (state, action) => {
      state.vat_pagination = (action.payload - 1)
    },
    setVatPagesize: (state, action) => {
      state.vat_pagesize = action.payload
    },

  }
});

export const {
  setVatId, setVat, setVats, editVats, setVatModalShow, setVatPagination, setVatPagesize, setVatsAll,
} = vatSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getVatId = (state) => state.vat.vat_id
export const getVat = (state) => state.vat.vat
export const getVats = (state) => state.vat.vats
export const getVatsAll = (state) => state.vat.vats_all
export const getHashVatsAll = (state) => state.vat.hash_vats_all
export const getVatModalShow = (state) => state.vat.vatmodalshow
export const getVatPagination = (state) => state.vat.vat_pagination
export const getVatPagesize = (state) => state.vat.vat_pagesize

export default vatSlice.reducer;