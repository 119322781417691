import { Col, Card } from "react-bootstrap";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { setProgressModalShow, getProgressModalShow, getTaskData } from "../redux/dataSlices/analyseSlice";

function ProgressCard() {
  const progressModalShow = useSelector(getProgressModalShow)
  const taskData = useSelector(getTaskData)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  if (!progressModalShow && taskData?.state === 'PROGRESS')
    return (
      <Col className="progress-card" onClick={() => {
        navigate("/analyses");
        dispatch(setProgressModalShow(true))
      }}>
        <Card className={'progress-card-body'}>
          <Card.Body>
            <CircularProgressbar value={taskData ? Math.round(taskData.progress * 100) : 0} text={`${taskData ? Math.round(taskData.progress * 100) : 0}%`} />
          </Card.Body>
        </Card>
      </Col>
    );
}

export default ProgressCard;