import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const SortCategoryFormFields = () => {
  const role = useSelector(getRole)

  const sortcategoryFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name', required: true },
    { type: 'number', name: 'Sort index', id: 'sort_index', required: true, step:'1' },
  ]

  if (role === 'super' || role === 'admin') {
    sortcategoryFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'admin_id', formdatafield: 'admin_id', required: true },
    )
    sortcategoryFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return sortcategoryFormFields
}