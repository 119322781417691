import { StyleSheet } from "@react-pdf/renderer";

const baseFontSize = 10
const baseMargin = 5
const basePadding = 2.5

export const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: baseFontSize,
        paddingLeft: (basePadding * 10),
        paddingRight: (basePadding * 10),
        paddingTop: (basePadding * 20),
        paddingBottom: (basePadding * 20)
    },
    header: {
        flexDirection: 'row',
        marginTop: (baseMargin * 1.5),
        lineHeight: 1.5
    },
    br: {
        marginTop: baseMargin,
        marginBottom: baseMargin
    },
    relationNaw: {
        flex: 1,
        textAlign: 'right',
        marginRight: (baseMargin * 5),
    },
    locationNaw: {
        flex: 1,
        marginLeft: (baseMargin * 5)
    },
    invoiceTitle: {
        fontSize: (baseFontSize + 5),
        fontFamily: 'Helvetica-Bold',
        marginTop: (baseMargin * 2),
        marginLeft: (baseMargin * 4.5),
        marginBottom: (baseMargin * 1.5)
    },
    invoiceInfo: {
        fontSize: baseFontSize,
        fontFamily: 'Helvetica',
        marginLeft: (baseMargin * 4.5),
        lineHeight: 2
    },
    table: {
        display: 'table',
        width: 'auto',
        marginLeft: baseMargin,
        marginRight: baseMargin,
        marginTop: baseMargin,
        borderTop: 2,
        borderBottom: 2
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: 1,
        borderBottomColor: 'black',
    },
    tableHeaderAmount: {
        width: '15%',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: basePadding,
        paddingBottom: basePadding,
        paddingTop: (basePadding * 2),
        fontSize: (baseFontSize + 1),
    },
    tableHeader: {
        width: '50%',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textAlign: 'left',
        paddingLeft: basePadding,
        paddingBottom: basePadding,
        paddingTop: (basePadding * 2),
        fontSize: (baseFontSize + 1),
    },
    tableHeaderPrice: {
        width: '50%',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textAlign: 'right',
        paddingLeft: basePadding,
        paddingBottom: basePadding,
        paddingRight: basePadding,
        paddingTop: (basePadding * 2),
        fontSize: (baseFontSize + 1),
    },
    tableCellAmount: {
        width: '15%',
        textAlign: 'left',
        alignItems: 'center',
        paddingTop: (basePadding * 2),
        paddingRight: basePadding,
        paddingLeft: basePadding,
        paddingBottom: (basePadding * 2),
        fontSize: baseFontSize
    },
    tableCell: {
        width: '50%',
        textAlign: 'left',
        alignItems: 'center',
        paddingTop: (basePadding * 2),
        paddingRight: basePadding,
        paddingLeft: basePadding,
        paddingBottom: (basePadding * 2),
        fontSize: baseFontSize
    },
    tableCellPrice: {
        width: '50%',
        textAlign: 'right',
        alignItems: 'center',
        paddingTop: (basePadding * 2),
        paddingRight: basePadding,
        paddingLeft: basePadding,
        paddingBottom: (basePadding * 2),
        fontSize: baseFontSize
    },
    tableHeaderCentered: {
        width: '50%',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingLeft: basePadding,
        paddingBottom: basePadding,
        paddingTop: (basePadding * 2),
        fontSize: (baseFontSize + 1),
    },
    tableCellCentered: {
        width: '50%',
        textAlign: 'center',
        paddingTop: (basePadding * 2),
        paddingRight: basePadding,
        paddingLeft: basePadding,
        paddingBottom: (basePadding * 2),
        fontSize: baseFontSize
    },
});