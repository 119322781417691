import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function TransactionAnalyse(report) {
  let transactionAnalyse = []

  const { t: translate } = useTranslation();

  for (const countryData of Object.values(report).sort((a, b) => b.percentage - a.percentage)) {
    if (countryData.hasOwnProperty('country')) {
      transactionAnalyse.push(
        <tr key={countryData.country}>
          <td>{countryData.country}</td>
          <td>{countryData.percentage?.toFixed(2)+'%'}</td>
          <td>{countryData.revenue?.toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        </tr>
      )
    
    }
  }

  transactionAnalyse.push(
    <tr key='empty'>
      <td><strong>Gender</strong></td>
      <td><strong></strong></td>
      <td></td>
    </tr>
  )

  for (const countryData of Object.values(report).sort((a, b) => b.percentage - a.percentage)) {
    if (countryData.hasOwnProperty('gender')) {
      transactionAnalyse.push(
        <tr key={countryData.gender}>
          <td>{countryData.gender}</td>
          <td>{countryData.percentage?.toFixed(2)+'%'}</td>
          <td>{countryData.revenue?.toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        </tr>
      )
    
    }
  }


  return (
    <>
      <Table width='100%'>
        <thead>
          <tr key='header'>
            <th width='40%'>
              {translate('Country')}
            </th>
            <th width='30%'>
              {translate('Percentage')}
            </th>
            <th width='30%'>
              {translate('Revenue')}
            </th>
          </tr>
        </thead>
        <tbody>
          {transactionAnalyse}
        </tbody>
      </Table>
      {/* {exportButton && <Button size="sm" type='button' onClick={() => { exportButton.onClick({report: statusReport, type:'statusReportSingleDay' }) }}>{translate(exportButton.tag)}</Button>} */}
      {/* {pdfButton && <Button size="sm" type='button' onClick={() => { pdfButton.onClick({ report: statusReport, type:'statusReportSingleDay' }) }}>{translate(pdfButton.tag)}</Button>} */}
    </>
  )
}