import { Table } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';


export default function ServicehoursEditTable(props) {
  const { t: translate } = useTranslation();
  const days = {
    1: translate('Monday'),
    2: translate('Tuesday'),
    3: translate('Wednesday'),
    4: translate('Thursday'),
    5: translate('Friday'),
    6: translate('Saturday'),
    7: translate('Sunday')
  }
  const hash_openingtimes = {}

  if (props?.data?.hasOwnProperty('opening_times')) {
    for (const day of Object.values(props?.data?.opening_times)) {
      hash_openingtimes[day.day] = day
    }
  }

  let rows = []
  for (const [key, value] of Object.entries(days)) {
    rows.push(
      <tr key={key}>

        <td>{value}</td>
        <td>
          <Form.Control prefix={key}
            type='time'
            id='time_open'
            name='time_open'
            placeholder='time_open'
            value={hash_openingtimes[key] ? hash_openingtimes[key].time_open : ''}
            onChange={props.service_hours.onChange}
            step='900'
          />
        </td>
        <td>
          <Form.Control prefix={key}
            type='time'
            id='time_closed'
            name='time_closed'
            placeholder='time_closed'
            value={hash_openingtimes[key] ? hash_openingtimes[key].time_closed : ''}
            onChange={props.service_hours.onChange}
            step='900'
          />
        </td>
      </tr>
    )
  }

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Day')}
            </th>
            <th>
              {translate('Open')}
            </th>
            <th>
              {translate('Close')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </>
  );
}