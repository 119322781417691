import { useNavigate } from "react-router-dom";
import { getRole } from "../../redux/dataSlices/tokenSlice";
import { useSelector, useDispatch } from "react-redux";
import { setCategory, getCategoryModalShow, setCategoryModalShow, getSelectedJournalCategories, setSelectedJournalCategories, setCategoryProducts } from "../../redux/dataSlices/categorySlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { confirmAlert } from "react-confirm-alert";
import GetCategoryByIdProducts from "../../actablueAPI/products/GetCategoryByIDProducts";
import useHandleError from "../../customhooks/useHandleError";
import DelCategoryById from "../../actablueAPI/products/DelCategoryByID";

export default function CategoryButtons() {
  const token = useSelector(getToken)
  const admin_id = useSelector(getSelectedAdmin_id)
  const modalShow = useSelector(getCategoryModalShow)
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const selectedCategories = useSelector(getSelectedJournalCategories)

  function onViewClick(event, journalcategory) {
    event.stopPropagation();
    dispatch(setCategory(journalcategory));
    GetCategoryByIdProducts({token:token, id:journalcategory.id, admin_id:admin_id})
    .then(response => {
      dispatch(setCategoryProducts(response.data))
      dispatch(setCategoryModalShow(true));
    })
    .catch(error => {
      dispatch(setCategoryModalShow(true));
    })
  };

  const onViewHide = (event) => {
    event.stopPropagation();
    dispatch(setCategoryModalShow(false));
  };

  const onclickEdit = (event, journalcategory) => {
    event.stopPropagation();
    dispatch(setCategory(journalcategory));
    navigate("/journalcategory/edit");
  };

  const onclickAdd = () => {
    navigate("/journalcategory/add");
  };

  const onSelect = (event, category) => {
    event.stopPropagation();
    if (selectedCategories?.hasOwnProperty(category.id)) {
      const newSelectedCategories = { ...selectedCategories };
      delete newSelectedCategories[category.id];
      dispatch(setSelectedJournalCategories(newSelectedCategories));
    } else {
      const newSelectedCategories = { ...selectedCategories, [category.id]: category };
      dispatch(setSelectedJournalCategories(newSelectedCategories));
    }
  };

  const onclickDelete = (category, refresh) => {
    confirmAlert({
      title: 'confirm_alert_title_delete',
      message: `confirm_alert_message_delete_category_message`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            DelCategoryById({token:token, admin_id:admin_id, id:category.id})
            .then(response => {
              refresh()
            })
            .catch(error => {
              HandleError({ error: error })
            })
          }
        },
        {
          label: 'No',
        }
      ]
    })
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    select: {
      onClick: onSelect
    },
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.journalcategories >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.journalcategories >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.multiEdit = {
      tag: "Edit",
      value: 'multiEdit',
      onClick: onclickEdit
    }
  }

  if (role === 'super' || role === 'admin') {
    buttons.del = {
      tag:'Delete',
      value:'del',
      onClick:onclickDelete
    }
  }

  return buttons
}

