import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { setSnelstartCompany, getSnelstartCompany } from "../../redux/dataSlices/snelstartSlice";
import { setAccounting, getAccounting } from "../../redux/dataSlices/accountingSlice";
import BasicCard from "../../components/card"
import SnelstartTable from "../../components/snelstartTable";
import AccountingTable from "../../components/accountingTable";
import GetSnelstartCompany from "../../actablueAPI/snelstart/GetSnelstartCompany";
import GetPaymentLedgers from "../../actablueAPI/accounting/GetPaymentLedgers";
import useHandleError from "../../customhooks/useHandleError";

const Snelstart = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const snelstartCompany = useSelector(getSnelstartCompany)
  const accounting = useSelector(getAccounting)
  const dispatch = useDispatch();
  const HandleError = useHandleError();

  const snelstartCard = {
    size: 12,
    title: "SnelStart",
    data: <SnelstartTable {...snelstartCompany} />,
  };

  const accountingCard = {
    size: 12,
    title: "Accounting",
    data: <AccountingTable {...accounting} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("SnelStart"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      axios.all([
        // get snelstart company
        GetSnelstartCompany({ token: token, admin_id: admin_id, location_id: location_id }),
        // get accounting information
        GetPaymentLedgers({ token: token, admin_id: admin_id, location_id: location_id })
      ])
        .then(axios.spread((companyResponse, paymentledgersResponse) => {
          dispatch(setSnelstartCompany(companyResponse.data))
          dispatch(setAccounting(paymentledgersResponse.data))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [location_id]);



  return (
    <>
      <BasicCard {...accountingCard} />
      <BasicCard {...snelstartCard} />
    </>
  )
};
export default Snelstart;
