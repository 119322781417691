import { useSelector } from 'react-redux';
import { Modal, Nav, Tab, Button } from 'react-bootstrap';
import { getTransaction } from '../redux/dataSlices/transactionSlice';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';
import { getHashDevicesUidAll } from "../redux/dataSlices/deviceSlice";
import { getRole } from '../redux/dataSlices/tokenSlice';
import OrderlineTable from '../components/orderlineTable';
import InvoicelineTable from '../components/invoicelineTable';
import EftReceiptTable from '../components/eftreceiptTable';
import PaymentTable from './paymentTable';
import TransactionTotalsTable from './transactionTotalTable';
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import VatlineTable from './vatlineTable';
import { getHashEmployeesAll } from '../redux/dataSlices/employeeSlice';

export default function TransactionModal(props) {
  const object = useSelector(getTransaction);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const hash_device_uid_all = useSelector(getHashDevicesUidAll)
  const hashEmployees = useSelector(getHashEmployeesAll)
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  const convertJson = (string) => {
    // We try catch, if the descript isn't JSON it'll throw an error and we'll return false
    // that's how we'll know if it's a valid JSON or not
    try {
      let convertedJson = Object.entries(JSON.parse(string)).map(([key, value]) => `${key}: ${value}`);
      return convertedJson
    } catch (error) {
      return false
    }
  }

  if (object) {
    let formattedDescript = convertJson(object?.descript);
    return (
      <Modal
        show={props.modal.show}
        size="lg"
        dialogClassName='modal-fullscreen-md-down modal-md'
        contentClassName='h-md-100'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable
      >
        <Modal.Header className='order-modal-head'>
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Transaction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="info">
                  {translate('Info')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="order">
                  {translate('Order')}
                </Nav.Link>
              </Nav.Item>
              {service &&
                <Nav.Item>
                  <Nav.Link eventKey="service">
                    {translate('Service')}
                  </Nav.Link>
                </Nav.Item>
              }
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <h5>STATUS: </h5>
                      <p>{object.invoice?.status}</p>
                    </div>
                  </div>
                </div>
                <br></br>
                <h4>Invoice Total</h4>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <h6>{Number(object.invoice?.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>Incl. BTW</p>
                    </div>
                    <div className="col">
                      <h6>{Number(object.invoice?.payment_amount_exclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>Excl. BTW</p>
                    </div>
                    <div className="col">
                      <h6>{Number(object.invoice?.payment_amount_inclusive_vat - object.invoice?.payment_amount_exclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>BTW</p>
                    </div>
                  </div>
                </div>
                <br></br>
                <h4>Transaction Details</h4>
                {object.invoice && <h5>Invoice #: {object.invoice?.number}</h5>}
                {object.administration_id && <h5>Administration: {hash_admins_all[object.administration_id]?.name}</h5>}
                {object.location_id && <h5>Location: {hash_locations_all[object.location_id]?.name}</h5>}
                {object.invoice?.operator_id && <h5>Employee: {hashEmployees[object.invoice?.operator_id]?.name}</h5>}
                {object.user_id && <h5>Device: {hash_device_uid_all[object.user_id]?.name} ({hash_device_uid_all[object.user_id]?.id})</h5>}
                {object.descript && object.descript !== "{}" &&
                  <>
                    <h5>Description:</h5>
                    <div className='product-description'>
                      {formattedDescript ?
                        formattedDescript.map((line, index) => (
                          <p key={index}>{line}</p>
                        ))
                        :
                        <p>{object.descript}</p>
                      }
                    </div>
                  </>
                }
                <br></br>
                {object.invoice && <h5>Invoice: </h5>}
                {object.invoice && <InvoicelineTable invoice={object.invoice} />}
                <h5>Transaction totals:</h5>
                <TransactionTotalsTable transaction={object} />
                {object.invoice && object.invoice.invoice_lines && <h5>Vat:</h5>}
                {object.invoice && object.invoice.invoice_lines && <VatlineTable {...object} />}
                {object.payments && <h5>Payments:</h5>}
                {object.payments && <PaymentTable payments={object.payments} />}
                {object.invoice.receipts && <h5>Eft receipt:</h5>}
                {object.invoice.receipts && <EftReceiptTable receipts={object.invoice.receipts} />}
              </Tab.Pane>
              <Tab.Pane eventKey="order">
                {object.order && <h5>Order:</h5>}
                {object.order && <OrderlineTable order={object.order} />}
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>

                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props?.modal?.exportPdf?.onClick(object)}>{translate(props?.modal?.exportPdf?.tag)}</Button>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}