export const VatFormFields = () => {
  const vatFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'external_id', id: 'external_id', readonly: true },

    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: `Value`, id: 'value' },
    { type: 'text', name: `Type`, id: 'type', readonly: true },
    { type: 'text', name: `Ledger`, id: 'ledger' },
    { type: 'checkbox', name: 'Active', id: 'active' },

    // single option select fields  

    // multi option select fields

    // multi option select fields + self create

    // single option object fields

    // multiple option fields

    // not adjustable fields
    // {type:'datetime-local', name:'creation', id:'creation' , readonly:true},
    // {type:'datetime-local', name:'modification', id:'modification' , readonly:true},    
  ]

  return vatFormFields
}