/* eslint-disable no-undef */
import React from "react";

const Sitemap = () => {

  return (
    <>
      Sitemap
    </>
  )
};
export default Sitemap;
