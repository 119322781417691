import { StyleSheet } from "@react-pdf/renderer";

const baseFontSize = 10
const baseMargin = 5
const basePadding = 2.5

export const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: baseFontSize,
        paddingLeft: (basePadding * 10),
        paddingRight: (basePadding * 10),
        paddingTop: (basePadding * 20),
        paddingBottom: (basePadding * 20)
    },
    header: {
        flexDirection: 'row',
        marginTop: (baseMargin * 4.5),
        lineHeight: 1.5
    },
    br: {
        marginTop: baseMargin,
        marginBottom: baseMargin
    },
    eftReceiptTitle: {
        fontSize: (baseFontSize + 5),
        fontFamily: 'Helvetica-Bold',
        marginTop: (baseMargin * 2),
        marginLeft: (baseMargin * 4.5),
        marginBottom: (baseMargin * 1.5)
    },
    eftReceiptLine: {
        fontSize: baseFontSize,
        fontFamily: 'Helvetica',
        marginLeft: (baseMargin * 4.5),
        lineHeight: 1.5
    }
});