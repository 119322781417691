export const attributegroupAddProductFilterChoices = [
  // { 'id': 'discount', 'name': 'discount' },
  // { 'id': 'emballage', 'name': 'emballage' },
  // { 'id': 'paidin', 'name': 'paidin' },
  // { 'id': 'paidout', 'name': 'paidout' },
  { 'id': 'article', 'name': 'article' },
  // { 'id': 'service', 'name': 'service' },
  // { 'id': 'deposit', 'name': 'deposit' },
  // { 'id': 'noturnover', 'name': 'noturnover' },
  { 'id': 'addon', 'name': 'addon' },
  // { 'id': 'addoncombination', 'name': 'addoncombination' },
  // { 'id': 'subscription', 'name': 'subscription' }
]

export const attributegroupTypeChoices = [
  // { id: 'RECEIPT', name: 'RECEIPT' },
  { id: 'ADDON', name: 'ADDON' },
  { id: 'MENU', name: 'MENU' },
  { id: 'TEMPLATE', name: 'TEMPLATE' },
]