import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReportingButtons from '../portal/reporting/reportingbuttons';


export default function StatusReportMulitpleDays(statusReport) {
  const buttons = ReportingButtons.call()
  const pdfButton = buttons.hasOwnProperty('pdf') ? Object.assign({}, buttons.pdf) : false
  let statusRowsDay = []
  let statusRowsDays = []
  const { t: translate } = useTranslation();

  if (statusReport.calculatedTotals) {
    statusRowsDay.push(createStatusRowsDay(statusReport.calculatedTotals, 'Total', translate))
  }

  statusRowsDays.push(statusRowsDay)

  return (
    <>
      <Table width='100%'>
        <thead>
          <tr key='header'>
            <th width='30%'>
              {translate('Payments')}
            </th>
            <th width='30%'>
              {translate('Journal categories')}
            </th>
            <th width='30%'>
              {translate('Vats')}
            </th>
          </tr>
        </thead>
        <tbody>
          {statusRowsDay}
        </tbody>
      </Table>
      {pdfButton && <Button size="sm" type='button' onClick={() => { pdfButton.onClick({ report: statusReport, type: 'statusReportMultipleDays' }) }}>{translate(pdfButton.tag)}</Button>}
    </>
  )
}

function createStatusRowsDay(day, date, translate) {
  let paymentRows = []
  let journalCategoryRows = []
  let vatRows = []

  const payments = day['payments']
  const journals = day['journals']
  const taxs = day['taxs']

  if (payments) {
    for (let [payment, amount] of Object.entries(payments)) {
      if (payment !== 'total') {
        paymentRows.push(
          <tr key={payment}>
            <td>{payment}</td>
            <td align="right">{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
        )
      }
      if (payment === 'total') {
        paymentRows.push(
          <tr key={payment}>
            <td>
              <strong>Total</strong>
            </td>
            <td align="right">
              <strong>{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong>
            </td>
          </tr>
        )
      }
    }
  }

  if (journals) {
    for (let [journalId, journalObject] of Object.entries(journals)) {
      if (journalId !== 'total') {
        journalCategoryRows.push(
          <tr key={journalId}>
            <td>{journalObject.name}</td>
            <td align="right">{journalObject.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
        )
      }
      if (journalId === 'total') {
        journalCategoryRows.push(
          <tr key={journalId}>
            <td>
              <strong>Total</strong>
            </td>
            <td align="right">
              <strong>{journalObject.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong>
            </td>
          </tr>
        )
      }
    }
  }

  if (taxs) {
    for (let [tax, amount] of Object.entries(taxs)) {
      if (tax !== 'total') {
        vatRows.push(
          <tr key={tax}>
            <td>{tax}</td>
            <td align="right">{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
        )
      } else {
        vatRows.push(
          <tr key={tax}>
            <td>
              <strong>Total</strong>
            </td>
            <td align="right">
              <strong>{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong></td>
          </tr>
        )
      }
    }
  }

  return (
    <tr key={date}>
      <td>
        <Table hover width='100%'>
          <thead>
            <tr>
              <th width='50%'>{translate('Name')}</th>
              <th width='50%' className="text-end">{translate('Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {paymentRows}
          </tbody>
        </Table>
      </td>
      <td>
        <Table hover width='100%'>
          <thead>
            <tr>
              <th width='50%'>{translate('Name')}</th>
              <th width='50%' className="text-end">{translate('Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {journalCategoryRows}
          </tbody>
        </Table>
      </td>
      <td>
        <Table hover width='100%'>
          <thead>
            <tr>
              <th width='50%'>%</th>
              <th width='50%' className="text-end">{translate('Amount')}</th>
            </tr>
          </thead>
          <tbody>
            {vatRows}
          </tbody>
        </Table>
      </td>
    </tr>
  )
}
