import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
// import { useTranslation } from 'react-i18next';

import './css/productEdit.css'

import ImportingProduct from '../portal/importing/importingProducts';
import ImportingRelation from '../portal/importing/importingRelations';
import ImportingProductActablueFile from '../portal/importing/importingProductsActablueFile';
import ImportingStock from '../portal/importing/importingStock';


function ImportMatchColumnsForm(props) {
  // const { t: translate } = useTranslation();

  let importMatchColumnsFormData = {}
  switch (props.importForm?.importtype) {
    case 'products':
    case 'products+locations':
      importMatchColumnsFormData = ImportingProduct(props.importForm, props.headers, props.data)
      break;
    case 'relations':
      importMatchColumnsFormData = ImportingRelation(props.importForm, props.headers, props.data)
      break
    case 'actablueExportFile':
      importMatchColumnsFormData = ImportingProductActablueFile(props.importForm, props.headers, props.data)
      break
    case 'stock':
      importMatchColumnsFormData = ImportingStock(props.importForm, props.headers, props.data)
      break
    default:
      break;
  }

  if (props.matchColumnsForm) {
    for (const [key, value] of Object.entries(props.matchColumnsForm)) {
      if (!key.includes('dynamic') && importMatchColumnsFormData[key]) {
        importMatchColumnsFormData[key].selected = {'value': value, 'label': value}
      }
    }
  } 
  
  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  importMatchColumnsFormData.fields?.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {/* {translate(field.name)} */}
            {field.name}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, importMatchColumnsFormData, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, importMatchColumnsFormData))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {/* {translate('Main')} */}
                {'Main'}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      {importMatchColumnsFormData.submit && 
      <Button variant="primary" type={importMatchColumnsFormData.submit.type} onClick={importMatchColumnsFormData.submit.onClick}>
        {importMatchColumnsFormData.submit.title}
      </Button>
      }
      <Button variant="primary" type={importMatchColumnsFormData.save.type} onClick={importMatchColumnsFormData.save.onClick}>
        {importMatchColumnsFormData.save.title}
      </Button>
    </>
  );
}


export default ImportMatchColumnsForm;