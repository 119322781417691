import axios from "axios";

const GetInvoicePaymentsTransactionTrigger = ({token, admin_id, transaction_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/invoicepayments/transaction/${transaction_id}/trigger`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json',
                'administration': admin_id
      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetInvoicePaymentsTransactionTrigger