import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';
import SingleSelectCreateField from './singleselectcreateField';

export default function PhoneNumbersEditTable(props) {
  const { t: translate } = useTranslation();

  let rows = []
  let selected = props.phone_numbers.selected ?? []
  if (props.data.hasOwnProperty(props.phone_numbers.add.name)) {
    props.data[props.phone_numbers.add.name].forEach(element => {
      rows.push(
        <tr key={element.id}>
          <td><Form.Check onChange={props?.phone_numbers?.check?.onChange} id={element.id} /></td>
          <td>
            <SingleSelectCreateField name='type' 
              options={props.phone_numbers.type.options} 
              onChange={props.phone_numbers.type.onChange} 
              clearable={true} 
              searcheable={true} 
              selected={{ 'value': element.type, 'label': element.type }} 
              required={true} 
              id='type' 
              elementid={element.id} prefix='phone_numbers'
            />
          </td>
          <td>
            <Form.Control prefix={element.id}
              elementid={element.id}
              type='text'
              name='number'
              placeholder={translate('Number')}
              value={element.number}
              onChange={props?.phone_numbers?.onChange}
            />
          </td>
        </tr>
      )
    });
  }

  return (
    <Table hover width='100%'>
      <thead>
        <tr>
          <th></th>
          <th>
            {translate('Type')}
          </th>
          <th>
            {translate('Number')}
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
      <Button variant="primary" className='mt-3' type={props.phone_numbers.add.type} onClick={props.phone_numbers.add.onClick} name={props.phone_numbers.add.name}>
        {translate(props.phone_numbers.add.title)}
      </Button>
      <Button variant="primary" className='mt-3' type={props.phone_numbers.remove.type} onClick={props.phone_numbers.remove.onClick} name={props.phone_numbers.remove.name} disabled={selected.length === 0 ? true : false}>
        {translate(props.phone_numbers.remove.title)}
      </Button>
    </Table>
  );
}