import QRCode from "react-qr-code";
import QrorderingButtons from "../portal/qrordering/qrorderingbuttons";
import { Button, Row, Col } from "react-bootstrap";
import { QrOrderingFormFields, QrOrderingRangeFormFields } from "../portal/qrordering/qrorderingFormFields";
import CreateFieldTemplate from "../helpers/createFieldTemplate";
import { useSelector } from "react-redux";
import { getSelectedAdmin_id } from "../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../redux/dataSlices/selectLocationSlice";
import { getToken } from "../redux/dataSlices/tokenSlice";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function QrOrderingTable(props) {
  const token = useSelector(getToken);
  const buttons = QrorderingButtons.call()
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const download = buttons.hasOwnProperty('download') ? Object.assign({}, buttons.download) : false
  const downloadRange = buttons.hasOwnProperty('downloadRange') ? Object.assign({}, buttons.downloadRange) : false
  const { t: translate } = useTranslation();

  let basicFields = []
  QrOrderingFormFields()?.forEach(field => {
    basicFields.push(CreateFieldTemplate(field, props))
  })


  let rangeFields = []
  QrOrderingRangeFormFields()?.forEach(field => {
    rangeFields.push(CreateFieldTemplate(field, props))
  })

  let url = `${process.env.REACT_APP_QRORDERING_BASE_URL}?adminId=${admin_id}&locationId=${location_id}&userId=${token?.payload?.sub}&gc=${props?.data?.table_number === "" ? "0" : props?.data?.table_number}`

  return (
    admin_id && location_id ? (
      <>
        <Row>
          <Col md={6}>
            <div className="centered-v">
              {basicFields}
            </div>
          </Col>
          <Col md={4}>
            <div style={{ height: "auto", marginLeft: "auto", maxWidth: 128, width: "100%" }}>
              <QRCode
                size={4096}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`${process.env.REACT_APP_QRORDERING_BASE_URL}?adminId=${admin_id}&locationId=${location_id}&userId=${token?.payload?.sub}&gc=${props?.data?.table_number === "" ? "0" : props?.data?.table_number}`}
                viewBox={`0 0 4096 4096`}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="centered-v">
              <Button size="sm" type='button' onClick={() => { download.onClick(props?.data?.table_number === "" ? "0" : props?.data?.table_number) }}>{translate(download.tag)}</Button>
              <Link to={url}><Button size="sm" type="button">{translate('Test link')}</Button></Link>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={8}>
            <div className="centered-v">
              <Row key={`fields-row`}>
                {rangeFields}
              </Row>
              <Row>
                <Col style={{ marginTop: "2rem", marginLeft: "1rem"}}>
                  <Button size="sm" type='button' className="mb-3" onClick={() => { downloadRange.onClick(props?.data?.table_number_range_start, props?.data?.table_number_range_end) }}>{translate(downloadRange.tag)}</Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    ) : (
      <Row>
        <Col md={12}>
          <div className="centered-vh">
            <p>Please select an admin and location.</p>
          </div>
        </Col>
      </Row>
    )
  );

}