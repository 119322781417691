import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './css/modal.css'
import { useSelector } from 'react-redux';
import { getTaskData } from '../redux/dataSlices/exportSlice';

export default function ExportProgressModal(props) {
  const { t: translate } = useTranslation();
  const task_data = useSelector(getTaskData)

  let percentage = 0
  if (task_data) {
    percentage = (task_data?.current * 100) / task_data?.total
  }
  
  return (
    <Modal
    show={props.modal.show}
    size="lg"
    dialogClassName='modal-fullscreen-md-down modal-md'
    contentClassName='h-md-100'
    aria-labelledby="contained-modal-title-vcenter"
    centered scrollable
  >
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>Export running</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar animated label={`${task_data ? Math.round(percentage) : 0}%`} now={task_data ? percentage : 0}/>
        <p>{task_data?.current} from {task_data?.total}</p>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.modal.onHide}>{translate('Close')}</Button> */}
      </Modal.Footer>
    </Modal.Dialog>
  </Modal>
  );
}
