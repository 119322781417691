import axios from "axios";

const PostTransactionSearch = ({ token, admin_id, location_id, searchObject, signal = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/invoicepayments/transaction/search"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'location': location_id,
      },
      data: searchObject,
      signal: signal
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      });
  });
};

export default PostTransactionSearch