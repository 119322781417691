import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setTaskId, setMatchColumnsForm, getMatchColumnsForm, setImportSaveModalShow } from "../../redux/dataSlices/importSlice";
import { ProductImportFormFields } from "./importFormFields.jsx";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { importDynamicChoices } from "../../selectfieldchoices/importselect";
import PostImportProducts from "../../actablueAPI/import/PostImportProducts.jsx";
import PostImportProductsAdminWide from "../../actablueAPI/import/PostImportProductsAdminWide.jsx";
import { getLocationsAll } from "../../redux/dataSlices/locationSlice.js";
import useHandleError from "../../customhooks/useHandleError.jsx";

const ImportingProduct = (importForm, headers, data) => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const match_columns_form = useSelector(getMatchColumnsForm)
  const locationsAll = useSelector(getLocationsAll)
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  
  const onMatchColumsFormChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form})
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  }

  const onSkuCodeChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'sku_code' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onPluCodeChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'plu_code' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onNameChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'name' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onDescriptionChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'description' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onThumbnailChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'thumbnail' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onActiveChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'active' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onDiscountChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'discount' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onPriceExclVatChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'price_excl_vat' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onPriceInclVatChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'price_incl_vat' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onPurchasePriceChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'supplier_price' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onaAskSnChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'ask_sn' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onMargeChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'marge' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onEmballageChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'emballage' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onVatChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'vat' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onEanChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'ean' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onMaingroupChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'maingroup' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onSubgroupChange1 = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'subgroup1' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };


  const onSubgroupChange2 = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'subgroup2' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onSubgroupChange3 = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'subgroup3' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onSizeChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'size' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onColorChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'color' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onBrandChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'brand' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onModelChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'model' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onPackagingChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'packaging' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onContentsChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'contents' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onWeightChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'weigth' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onHeightChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'heigth' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onLengthChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'length' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onWidthChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'width' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onDimensionChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: 'dimension' })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onDynamicChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: event.prefix.concat('_').concat(event.name) })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };


  const onMatchColumnsSave = (event) => {
    dispatch(setImportSaveModalShow(true))
  }

  const onStartImport = () => {
    let ab_data = {}
    ab_data.token = token
    ab_data.access_token = token.access_token
    ab_data.admin_id = admin_id
    ab_data.location_id = location_id
    ab_data.ab_selection = {}
    ab_data.ab_selection.admin_id = admin_id
    ab_data.ab_selection.location_id = location_id
    ab_data.read_dict = data
    ab_data.matchingForm = Object.assign({}, match_columns_form)
    ab_data.matchingForm.administration_id = admin_id
    ab_data.matchingForm.location_id = location_id
    ab_data.supplier_id = importForm.supplier_id
    ab_data.unique_id = importForm.unique_id
    ab_data.double_sku_ean = importForm.double_sku_ean
    ab_data.only_new = importForm.only_new
    ab_data.journalcategories = importForm.journalcategories

    switch (importForm.importtype) {
      case 'products':
        PostImportProducts({data: ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'products+locations':
        // add list of location id's
        ab_data.locations = locationsAll.map((location) => location.id)
        PostImportProductsAdminWide({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      default:
        break;
    }
  }

  const importMatchColumnsFormData = {
    submit: {
      title: 'start import',
      type: 'submit',
      onClick: onStartImport
    },
    save: {
      title: 'save settings',
      type: 'submit',
      onClick: onMatchColumnsSave
    },
    fields: ProductImportFormFields(headers, importForm),
    // field: {
    //   onChange: onMatchColumsFormChange
    // },
    data: importForm,
    headerSelection: {
      options: headers,
      onChange: onMatchColumsFormChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    sku_code: {
      name: 'sku_code',
      options: headers,
      // selected: {},
      onChange: onSkuCodeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    plu_code: {
      name: 'plu_code',
      options: headers,
      // selected: {},
      onChange: onPluCodeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    name: {
      name: 'name',
      options: headers,
      // selected: {},
      onChange: onNameChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    description: {
      name: 'description',
      options: headers,
      // selected: {},
      onChange: onDescriptionChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    thumbnail: {
      name: 'thumbnail',
      options: headers,
      // selected: {},
      onChange: onThumbnailChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    active: {
      name: 'active',
      options: headers,
      // selected: {},
      onChange: onActiveChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    discount: {
      name: 'discount',
      options: headers,
      // selected: {},
      onChange: onDiscountChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    price_excl_vat: {
      name: 'price_excl_vat',
      options: headers,
      // selected: {},
      onChange: onPriceExclVatChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    price_incl_vat: {
      name: 'price_incl_vat',
      options: headers,
      // selected: {},
      onChange: onPriceInclVatChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    supplier_artikel_code: {
      name: 'supplier_artikel_code',
      options: headers,
      // selected: {},
      onChange: onMatchColumsFormChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    supplier_price: {
      name: 'supplier_price',
      options: headers,
      // selected: {},
      onChange: onPurchasePriceChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    ask_sn: {
      name: 'ask_sn',
      options: headers,
      // selected: {},
      onChange: onaAskSnChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    marge: {
      name: 'marge',
      options: headers,
      // selected: {},
      onChange: onMargeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    emballage: {
      name: 'emballage',
      options: headers,
      // selected: {},
      onChange: onEmballageChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    vat: {
      name: 'vat',
      options: headers,
      // selected: {},
      onChange: onVatChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    ean: {
      name: 'ean',
      options: headers,
      // selected: {},
      onChange: onEanChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    maingroup: {
      name: 'maingroup',
      options: headers,
      // selected: {},
      onChange: onMaingroupChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    subgroup1: {
      name: 'subgroup1',
      options: headers,
      // selected: {},
      onChange: onSubgroupChange1,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    subgroup2: {
      name: 'subgroup2',
      options: headers,
      // selected: {},
      onChange: onSubgroupChange2,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    subgroup3: {
      name: 'subgroup3',
      options: headers,
      // selected: {},
      onChange: onSubgroupChange3,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    size: {
      name: 'size',
      options: headers,
      // selected: {},
      onChange: onSizeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    color: {
      name: 'color',
      options: headers,
      // selected: {},
      onChange: onColorChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    brand: {
      name: 'brand',
      options: headers,
      // selected: {},
      onChange: onBrandChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    model: {
      name: 'model',
      options: headers,
      // selected: {},
      onChange: onModelChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    packaging: {
      name: 'packaging',
      options: headers,
      // selected: {},
      onChange: onPackagingChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    contents: {
      name: 'contents',
      options: headers,
      // selected: {},
      onChange: onContentsChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    weigth: {
      name: 'weigth',
      options: headers,
      // selected: {},
      onChange: onWeightChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    heigth: {
      name: 'heigth',
      options: headers,
      // selected: {},
      onChange: onHeightChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    length: {
      name: 'length',
      options: headers,
      // selected: {},
      onChange: onLengthChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    width: {
      name: 'width',
      options: headers,
      // selected: {},
      onChange: onWidthChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    dimension: {
      name: 'dimension',
      options: headers,
      // selected: {},
      onChange: onDimensionChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    dynamic: {
      name: 'dymanic',
      options: importDynamicChoices,
      // selected: {},
      onChange: onDynamicChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  }

  return importMatchColumnsFormData
};
export default ImportingProduct;