import { useSelector, useDispatch } from "react-redux";
import { setTransaction, setTransactionPagination, getTransactionModalShow, setTransactionModalShow, setTransactionPagesize, getTransactionPagesize, getAccountingModalShow, setAccountingModalShow, setAccountingInfo } from "../../redux/dataSlices/transactionSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { TransactionPDF } from "../../pdfcreators/transactionPDF";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { useState } from "react";
import exportMultipleTransactionPDF from "../../helpers/exportMultipleTransactionPDF";
import GetRelationById from "../../actablueAPI/relations/GetRelationByID";
import GetInvoicePaymentsAccounting from "../../actablueAPI/invoicepayments/GetInvoicePaymentsAccounting";
import GetInvoicePaymentsTransactionTrigger from "../../actablueAPI/invoicepayments/GetInvoicePaymentsTransactionTrigger";
import { useTranslation } from 'react-i18next';
import { setMessage } from "../../redux/dataSlices/messageSlice";
import ConfirmAlert from "../../components/confirmAlert";
import GetTransactionById from "../../actablueAPI/invoicepayments/GetTransactionByID";
import useHandleError from "../../customhooks/useHandleError";

export default function TransactionButtons() {
  const token = useSelector(getToken)
  const admin_id = useSelector(getSelectedAdmin_id)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const modalShow = useSelector(getTransactionModalShow)
  const accountingModalShow = useSelector(getAccountingModalShow)
  const pageSize = useSelector(getTransactionPagesize)
  const HandleError = useHandleError();
  const [selected, setSelected] = useState({})
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const onSelect = (object) => {
    if (selected.hasOwnProperty(object.id)) {
      const newSelected = { ...selected };
      delete newSelected[object.id];
      setSelected(newSelected);
    } else {
      const newSelected = { ...selected, [object.id]: object };
      setSelected(newSelected);
    }
  };

  const onSelectAll = (objects) => {
    const allSelected = objects.every(
      (object) => selected.hasOwnProperty(object.id)
    );

    if (allSelected) {
      setSelected({});
    } else {
      const newSelected = { ...selected };

      objects.forEach((object) => {
        newSelected[object.id] = object;
      });

      setSelected(newSelected);
    }
  };

  async function onViewClick(transaction) {
    // dispatch(setTransaction(await AddInvoiceAndOrderToTransaction(token, admin_id, transaction)))
    dispatch(setTransaction(await GetTransactionById({token:token, admin_id:admin_id, id:transaction.id})))
    dispatch(setTransactionModalShow(true));
  };

  function onAccountingViewClick(transaction) {
    dispatch(setAccountingModalShow(true));
  };


  const onViewHide = (event) => {
    dispatch(setTransactionModalShow(false));
  };

  const onAccountingViewHide = (event) => {
    dispatch(setAccountingModalShow(false));
  };

  const onPaginationClick = (event) => {
    dispatch(setTransactionPagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setTransactionPagesize(event.value))
  }

  const onClickExportPdf = async (transaction) => {
    let newTransaction = await GetTransactionById({token:token, admin_id:admin_id, id:transaction.id})

    let relation = undefined;
    if (newTransaction?.invoice?.relation_id) {
      await GetRelationById({ token: token, id: newTransaction?.invoice?.relation_id, admin_id:admin_id })
      .then(response => {
        relation = response?.data;
      })
    }
    TransactionPDF(newTransaction, hash_locations_all, relation)
  }

  const onExportClick = async () => {
    let relations_hash = [];

    for (const order of Object.values(selected)) {
      if (order?.relation_id) {
        await GetRelationById({ token: token, id: order?.relation_id, admin_id:admin_id })
        .then(response => {
          relations_hash[response?.data?.id] = response?.data;
        })
      }
    }
    
    exportMultipleTransactionPDF(token, admin_id, selected, hash_locations_all, relations_hash, dispatch)
  }

  const onAccountingInfo = (transaction) => {
    GetInvoicePaymentsAccounting({token:token, transaction_id:transaction.id})
    .then(response => {
      dispatch(setAccountingInfo(response.data))
      dispatch(setAccountingModalShow(true));
    })
    .catch(error => {
      HandleError({ error: error })
    })
  }

  const onTriggerTransaction = (transaction) => {
    ConfirmAlert({
      translate: translate,
      title: 'confirm_transaction_trigger_title',
      message: 'confirm_transaction_trigger_message',
      onClick: () => {
        GetInvoicePaymentsTransactionTrigger({token:token, admin_id:admin_id, transaction_id:transaction.id})
        .then(dispatch(setMessage('transaction_trigger_message')))
        .catch(error => {
          HandleError({ error: error })
        })
      }
    });
  }



  let buttons = {
    modalview: {
      tag: 'View',
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide,
        exportPdf: {
          tag: 'PDF',
          onClick: onClickExportPdf
        }
      }
    },
    accountingmodalview: {
      tag: 'View',
      onClick: onAccountingViewClick,
      modal: {
        show: accountingModalShow,
        onHide: onAccountingViewHide,
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },
    trigger: {
      tag: 'Retry',
      onClick: onTriggerTransaction
    },
    accountingInfo: {
      tag: 'Info',
      onClick: onAccountingInfo
    },
    select: {
      onClick: onSelect,
      selected: selected
    },
    selectAll: {
      onClick: onSelectAll
    },
    export: {
      tag: 'Export to PDF',
      value: 'export',
      onClick: onExportClick
    }
  }

  return buttons
}

