import { createSlice } from "@reduxjs/toolkit";

export const opticonSlice = createSlice({
  name: "opticon",
  initialState: {
    hash_opticon_esls_all: {},
    hash_opticon_connections_all: {}
  },
  reducers: {
    setOpticonEslId: (state, action) => {
      state.opticon_esl_id = action.payload;
    },
    setOpticonEsl: (state, action) => {
      state.opticon_esl = action.payload;
    },
    setOpticonEsls: (state, action) => {
      state.opticon_esls = action.payload
    },
    setOpticonEslsAll: (state, action) => {
      state.opticon_esls_all = action.payload
      action?.payload?.forEach(opticon_esl => {
        state.hash_opticon_esls_all[opticon_esl.id] = opticon_esl
      });
    },
    setOpticonEslModalShow: (state, action) => {
      state.opticon_esl_modalshow = action.payload
    },
    setOpticonEslPagination: (state, action) => {
      state.opticon_esl_pagination = (action.payload - 1)
    },
    setOpticonEslPageSize: (state, action) => {
      state.opticon_esl_pagesize = action.payload
    },
    setOpticonEslTypes: (state, action) => {
      state.opticon_esltypes = action.payload
      action?.payload?.forEach(type => {
        state.hash_opticonesltypes[type.id] = type
      });
    },

    setOpticonConnectionId: (state, action) => {
      state.opticon_connection_id = action.payload;
    },
    setOpticonConnection: (state, action) => {
      state.opticon_connection = action.payload;
    },
    setOpticonConnections: (state, action) => {
      state.opticon_connections = action.payload
    },
    setOpticonConnectionsAll: (state, action) => {
      state.opticon_connections_all = action.payload
      action?.payload?.forEach(opticon_connection => {
        state.hash_opticon_connections_all[opticon_connection.id] = opticon_connection
      });
    },
    setOpticonConnectionModalShow: (state, action) => {
      state.opticon_connection_modalshow = action.payload
    },
    setOpticonConnectionPagination: (state, action) => {
      state.opticon_connection_pagination = (action.payload - 1)
    },
    setOpticonConnectionPageSize: (state, action) => {
      state.opticon_connection_pagesize = action.payload
    },
    setOpticonConnectionTypes: (state, action) => {
      state.opticon_connectiontypes = action.payload
      action?.payload?.forEach(type => {
        state.hash_opticonconnectiontypes[type.id] = type
      });
    },
  }
});

export const {
  setOpticonEslId, setOpticonEsl, setOpticonEsls, setOpticonEslModalShow, setOpticonEslPagination, setOpticonEslPageSize, setOpticonEslsAll,
  setOpticonConnectionId, setOpticonConnection, setOpticonConnections, setOpticonConnectionModalShow, setOpticonConnectionPagination, setOpticonConnectionPageSize, setOpticonConnectionsAll,
} = opticonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getOpticonEslId = (state) => state.opticon.opticon_esl_id
export const getOpticonEsl = (state) => state.opticon.opticon_esl
export const getOpticonEsls = (state) => state.opticon.opticon_esls
export const getOpticonEslsAll = (state) => state.opticon.opticon_esls_all
export const getHashOpticonEslsAll = (state) => state.opticon.hash_opticon_esls_all
export const getOpticonEslModalShow = (state) => state.opticon.opticon_esl_modalshow
export const getOpticonEslPagination = (state) => state.opticon.opticon_esl_pagination
export const getOpticonEslPagesize = (state) => state.opticon.opticon_esl_pagesize
export const getOpticonEslTypes = (state) => state.opticon.opticon_esltypes
export const getHashOpticonEslTypes = (state) => state.opticon.hash_opticon_esltypes

export const getOpticonConnectionId = (state) => state.opticon.opticon_connection_id
export const getOpticonConnection = (state) => state.opticon.opticon_connection
export const getOpticonConnections = (state) => state.opticon.opticon_connections
export const getOpticonConnectionsAll = (state) => state.opticon.opticon_connections_all
export const getHashOpticonConnectionsAll = (state) => state.opticon.hash_opticon_connections_all
export const getOpticonConnectionModalShow = (state) => state.opticon.opticon_connection_modalshow
export const getOpticonConnectionPagination = (state) => state.opticon.opticon_connection_pagination
export const getOpticonConnectionPagesize = (state) => state.opticon.opticon_connection_pagesize
export const getOpticonConnectionTypes = (state) => state.opticon.opticon_connectiontypes
export const getHashOpticonConnectionTypes = (state) => state.opticon.hash_opticon_connectiontypes

export default opticonSlice.reducer;