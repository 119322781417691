import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getError, setError } from '../redux/dataSlices/errorSlice';

import { Toast } from 'react-bootstrap';
import './css/toast.css';

export default function ErrorToast() {
  const error = useSelector(getError);
  const dispatch = useDispatch();
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (error) {
      setIsTransitioning(true);
    }
  }, [error, dispatch]);

  const onViewHide = () => {
    setIsTransitioning(false);
    const timer = setTimeout(() => {
      dispatch(setError(false));
    }, 500);
    return () => clearTimeout(timer);
  };

  if (error) {
    return (
      <Toast
        onClose={onViewHide}
        key="error-modal"
        className={'transition-div' + (isTransitioning ? ' transition-in' : ' transition-out')}
        animation={true}
      >
        <Toast.Header>
          <strong className="me-auto"><i className="bi bi-exclamation-square-fill"></i> Error "{error?.payload?.name}{error?.payload?.error}"</strong>
        </Toast.Header>
        <Toast.Body>
          {/* {JSON.stringify(error?.payload)}<br></br> */}
          STATUS: {error?.payload?.status}<br></br>
          {error?.payload?.code}{error?.payload?.status_code}<br></br>
          {error?.payload?.message}<br></br>
          {error?.payload?.data?.message}<br></br>
          {/* {JSON.stringify(error?.payload)}<br></br> */}
        </Toast.Body>
      </Toast>
    );
  }

  return null;
}