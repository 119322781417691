import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ImportProfileButtons from '../portal/import_profiles/importProfileButtons';
import { useTranslation } from 'react-i18next';
import ImportProfileModal from './importProfileModal';

export default function ImportProfileTable(importProfiles) {
  const buttons = ImportProfileButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();

  let rows = []
  for (const importProfile of Object.values(importProfiles) ) {
    rows.push(
      <tr className='product-row' key={importProfile.id}>
        <td>{importProfile.name}</td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(importProfile) }} id={importProfile.id}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(importProfile) }} id={importProfile.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}        
        </tbody>
      </Table>
      <ImportProfileModal modal={modalview.modal} />
    </>
  );
}