import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const CategoryFormFields = () => {
  const role = useSelector(getRole)

  const categoryFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'snelstart_id', id: 'snelstart_id', readonly: true },
    { type: 'hidden', name: 'external_id', id: 'external_id', readonly: true },
    { type: 'hidden', name: 'ccv_id', id: 'ccv_id', readonly: true },
    { type: 'hidden', name: 'lightspeed_id', id: 'lightspeed_id', readonly: true },

    { type: 'text', name: `Name`, id: 'title' },
    { type: 'text', name: `Description`, id: 'description' },
    { type: 'text', name: `Type`, id: 'type', readonly: true },
    { type: 'text', name: `Ledger`, id: 'ledger' },
    { type: 'checkbox', name: `Visible`, id: 'is_visible' },

    // single option select fields  
    // { type: 'select', name: 'Parent', id: 'parent', formdatafield: 'parent' },
    { type: 'select', name: `VAT`, id: 'vat', formdatafield: 'vats', required: true },

    // multi option select fields

    // multi option select fields + self create

    // single option object fields

    // multiple option fields

    // not needed, splitted in journal and productpage categories

    // not adjustable fields
    // {type:'datetime-local', name:'creation', id:'creation' , readonly:true},
    // {type:'datetime-local', name:'modification', id:'modification' , readonly:true},    
  ]

  if (role === 'super' || role === 'admin') {
    categoryFormFields.push({ type: 'select', name: 'Parent', id: 'parent', formdatafield: 'parent' })
  }

  return categoryFormFields
}

export const CategoryMultiEditFormFields = () => {
  const fields = [
    { type: 'select', name: `VAT`, id: 'vat', formdatafield: 'vats', required: true, md: 5 },
  ]

  return fields
}
