import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getPlaylist } from '../redux/dataSlices/narrowcastingSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { layoutChoices } from '../selectfieldchoices/narrowcastingselect.mjs';


export default function NarrowcastingPlaylistModal(props) {
  const object = useSelector(getPlaylist);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Playlist: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="views">{translate('Views')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>{translate('Description')}: {object.description}</p>
                      <p>{translate('Layout')}: {layoutChoices.find(element => element.id === object.layout)?.name}</p>
                      <p>{translate('Start Time')}: {object.start_time}</p>
                      <p>{translate('End Time')}: {object.end_time}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Creation')}: {new Date(object.creation).toLocaleString()}</p>
                      <p>{translate('Modification')}: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="views">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Views</h4>
                  <div className="row">
                    <div className="col">
                      {(object.views && object.views.length > 0) ?
                        <div>
                          <ul>
                            {object.views.map((view) => (
                              <li>
                                <p key={view.id}>{view.name}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                        :
                        <h5>No Views</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}