import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import { orderSequenceChoices, screenTypeChoices } from "../../selectfieldchoices/kitchenmanagerselect.mjs";
import KmScreensForm from "../../components/kmScreensForm";
import { ScreenFormFields } from "./screenFormFields";
import PostScreen from "../../actablueAPI/kitchenmanager/PostScreen";
import HandleOnChangeMultiObjectField from "../../helpers/handleOnChangeMultiObjectFields";
import HandleOnChangeMultiObjectSingleSelectFieldWithValue from "../../helpers/handleOnChangeMultiObjectSingleSelectFieldWithValue";
import AddObject from "../../helpers/addObject";
import RemoveObject from "../../helpers/removeObject";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox";
import { getHashPrintersAll, getHashScreensAll, getHashSortCategoriesAll, getPrintersAll, getScreen, getScreensAll, getSortCategoriesAll, setScreen } from "../../redux/dataSlices/kitchenmanagerSlice";
import CheckNewObjects from "../../helpers/checkNewObjects";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import DeepCopy from "../../helpers/DeepCopy";
import { validateFormObject } from "../../helpers/validateFormObject";


const ScreenEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll);
  const sortCategories = useSelector(getSortCategoriesAll);
  const hash_sort_categories = useSelector(getHashSortCategoriesAll);
  const kmPrinters = useSelector(getPrintersAll);
  const hash_printers = useSelector(getHashPrintersAll);
  const screens = useSelector(getScreensAll);
  const hash_screens = useSelector(getHashScreensAll);
  const devices = [...kmPrinters, ...screens];
  const hash_devices = Object.assign({}, hash_printers, hash_screens);
  const navigate = useNavigate();
  const screen = useSelector(getScreen)
  const [id, setId] = useState(screen.settings.next_device_sort_groups.length);
  const [checkedList, setCheckedList] = useState([])
  const [invalidFields, setInvalidFields] = useState([]);
  const dispatch = useDispatch();

  const onChange = (event) => {
    let newScreen = HandleOnChange({ event: event, object: screen })
    dispatch(setScreen(newScreen))
  }

  const onLocationIdChange = (event) => {
    let newScreen = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: screen, selectfield: 'location_id' })
    dispatch(setScreen(newScreen))
  }

  const onAdminIdChange = (event) => {
    let newScreen = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: screen, selectfield: 'admin_id' })
    delete newScreen.location_id
    dispatch(setScreen(newScreen))
  }

  const onTypeChange = (event) => {
    let newScreen = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: screen, selectfield: 'type' })
    dispatch(setScreen(newScreen))
  }

  const onDeviceReadyChange = (event) => {
    let newScreen = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: screen, selectfield: 'next_device_ready', prefix: 'settings' })
    dispatch(setScreen(newScreen))
  }

  const onNewOrderChange = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: newScreen.settings, selectfield: 'new_order', prefix: 'order_sequence' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onNextCourseChange = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: newScreen.settings, selectfield: 'next_course', prefix: 'order_sequence' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onVipChange = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: newScreen.settings, selectfield: 'vip', prefix: 'order_sequence' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onPauseChange = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: newScreen.settings, selectfield: 'pause', prefix: 'order_sequence' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onOnHoldChange = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: newScreen.settings, selectfield: 'on_hold', prefix: 'order_sequence' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onChangeNextDeviceSortGroups = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeMultiObjectField({ event: event, object: newScreen.settings, field: 'next_device_sort_groups' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onDeviceChange = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: newScreen.settings, selectfield: 'device_id', field: 'next_device_sort_groups' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onSortGroupChange = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: newScreen.settings, selectfield: 'sort_category', field: 'next_device_sort_groups' })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onAddObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = AddObject({ event: event, object: newScreen.settings, id: newId })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
  }

  const onRemoveObjects = (event) => {
    let newScreen = JSON.parse(JSON.stringify(screen))
    let newSettings = RemoveObject({ event: event, object: newScreen.settings, checkedList: checkedList })
    newScreen.settings = newSettings
    dispatch(setScreen(newScreen))
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: screen, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    let newScreen = DeepCopy(screen)

    CheckNewObjects(newScreen.settings)

    dispatch(setLoading(true))

    PostScreen({ token: token, data: newScreen })
      .then(() => {
        navigate("/kitchenmanager/devices");
      })
      .catch(() => {
        dispatch(setLoading(false))
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: ScreenFormFields(),
    field: {
      onChange: onChange
    },
    data: screen,
    hash_devices: hash_devices,
    hash_sort_categories: hash_sort_categories,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === screen.admin_id) { return true } }),
      hash: hash_locations_all,
      selected: null,
      onChange: onLocationIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    admin_id: {
      label: 'admin_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: null,
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    type: {
      label: 'type',
      options: screenTypeChoices,
      selected: [],
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    next_device_ready: {
      label: 'next_device_ready',
      options: devices,
      selected: [],
      onChange: onDeviceReadyChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    new_order: {
      label: 'new_order',
      options: orderSequenceChoices,
      selected: [],
      onChange: onNewOrderChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    next_course: {
      label: 'next_course',
      options: orderSequenceChoices,
      selected: [],
      onChange: onNextCourseChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    vip: {
      label: 'vip',
      options: orderSequenceChoices,
      selected: [],
      onChange: onVipChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    pause: {
      label: 'pause',
      options: orderSequenceChoices,
      selected: [],
      onChange: onPauseChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    on_hold: {
      label: 'on_hold',
      options: orderSequenceChoices,
      selected: [],
      onChange: onOnHoldChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    next_device_sort_groups: {
      onChange: onChangeNextDeviceSortGroups,
      add: {
        title: 'Add',
        type: 'button',
        name: 'next_device_sort_groups',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'next_device_sort_groups',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
      },
      selected: checkedList,
      device_id: {
        label: 'device_id',
        options: devices,
        // selected: {},
        onChange: onDeviceChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      },
      sort_category: {
        label: 'sort_category',
        options: sortCategories,
        // selected: {},
        onChange: onSortGroupChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      }
    },
  };

  const editCard = {
    size: 12,
    title: "Screen edit",
    data: <KmScreensForm {...formData} />,
  };

  useEffect(() => {
    let newScreen = DeepCopy(screen)
    for (let [index, object] of newScreen.settings.next_device_sort_groups.entries()) {
      object.id = index.toString(); // Stupid shit
    }
    dispatch(setScreen(newScreen))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};



export default ScreenEdit;

