export default function EftReceiptTable(props) {
  if (props.receipts) {
    return (
      <>
        {// eslint-disable-next-line
          props.receipts.map((eftreceipt) => {
            if (eftreceipt.lines) {
              return (
                <>
                 <tr key={eftreceipt.id} >
                   <td>{eftreceipt.lines.map((line) => {return (<div>{line[1]}</div>)})}</td>
                 </tr>
                </>
              )
            }
          })}
      </>
    );
  }
}