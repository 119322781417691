import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import CreateField from '../helpers/createField';
import { getHashUserRolesAll } from '../redux/dataSlices/userSlice';


function UserForm(props) {
  const { t: translate } = useTranslation();
  const hash_user_roles = useSelector(getHashUserRolesAll);

  if (props.data.hasOwnProperty('administrations')) {
    props.data.administrations.forEach(administration => {
      props.administrations.selected.push({ 'value': administration.id, 'label': props.administrations.hash[administration.id].name })
    });
  }

  if (props.data.hasOwnProperty('type')) {
    props.type.selected.push({ 'value': props.data.type, 'label': props.data.type })
  }

  if (props.data.hasOwnProperty('roles') && props.data.roles) {
    if (typeof props.data.roles === 'object') {
      if (props.data.roles && props.roles.options) {
        let foundRoles = props.roles.options.filter(role => JSON.stringify(role.roles || {}).toLowerCase() === JSON.stringify(props.data.roles).toLowerCase())
        if (foundRoles.length > 0) {
          for (const role of foundRoles) {
            props.roles.selected.push({ 'value': role?.id, 'label': role?.name })
          }
        }
      }
    }

    if (typeof props.data.roles !== 'object') props.roles.selected.push({ 'value': props.data.roles, 'label': hash_user_roles[props.data.roles]?.name })
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default UserForm;