export const reservationAssignFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'user_id', id: 'user_id', readonly: true },
  { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
  { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
  { type: 'hidden', name: 'reservation_name', id: 'reservation_name' },
  { type: 'hidden', name: 'reservation_email', id: 'reservation_email' },
  { type: 'hidden', name: 'reservation_timestamp', id: 'reservation_timestamp', step: '15' },
  { type: 'hidden', name: 'number_of_people', id: 'number_of_people' },
  { type: 'hidden', name: 'reservation_phonenumber', id: 'reservation_phonenumber' },
  { type: 'hidden', name: 'remark', id: 'remark' },
  { type: 'hidden', name: 'preferred_sitting_location', id: 'preferred_sitting_location' },

  // single option select fields  
  { type: 'hidden', name: 'Type', id: 'type', formdatafield: 'type' },
  { type: 'hidden', name: 'Status', id: 'status', formdatafield: 'status' },

  // multi option select fields
  { type: 'multiselect', name: 'Reservationobjects', id: 'reservation_objects', formdatafield: 'reservation_objects' },

  // not adjustable fields
  // {type:'datetime-local', name:'creation', id:'creation' , readonly:true},
  // {type:'datetime-local', name:'modification', id:'modification' , readonly:true},  
]