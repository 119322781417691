import axios from "axios";

const PostDevice = ({token, admin_id, device}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/narrowcasting/device`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type':'application/json',
        // 'administration': admin_id
      },
      data: device
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default PostDevice