import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import CcvshopButtons from '../portal/ccvshops/ccvshopbuttons';
import CcvshopModal from './ccvshopModal';
import { useTranslation } from 'react-i18next';


export default function CcvshopTable(ccvshops) {
  const { t: translate } = useTranslation();
  let accounting_excists = false

  const buttons = CcvshopButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  let modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: ccvshops.id }) : false

  let rows = []

  for (const index in ccvshops) {
    const ccvshop = ccvshops[index]
    if (ccvshop) {
      const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: ccvshop.id }) : false
      const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: ccvshop.id }) : false

      rows.push(
        <tr className='product-row' key={ccvshop.id}>
          <td>{ccvshop.domain}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(ccvshop) }} id={ccvshop.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(ccvshop) }} id={ccvshop.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }

  };

  return (
    <>
      {add && !accounting_excists && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Domain')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <CcvshopModal modal={modalview.modal} />
      </Table>
    </>
  );
}