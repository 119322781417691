import { createSlice } from "@reduxjs/toolkit";

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contact_pagesize: 10,
    contact_pagination: 0,
  },
  reducers: {
    setContactId: (state, action) => {
      state.contact_id = action.payload;
    },
    setContact: (state, action) => {
      state.contact = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload
    },
    setContactModalShow: (state, action) => {
      state.contactmodalshow = action.payload
    },
    setContactPagination: (state, action) => {
      state.contact_pagination = (action.payload - 1)
    },
    setContactPagesize: (state, action) => {
      state.contact_pagesize = action.payload
    }, 
  }
});

export const {
  setContactId, setContact, setContacts, setContactModalShow, setContactPagination, setContactPagesize,
} = contactSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getContactId = (state) => state.contact.contact_id
export const getContact = (state) => state.contact.contact
export const getContacts = (state) => state.contact.contacts
export const getContactModalShow = (state) => state.contact.contactmodalshow
export const getContactPagination = (state) => state.contact.contact_pagination
export const getContactPagesize= (state) => state.contact.contact_pagesize

export default contactSlice.reducer;