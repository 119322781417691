import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
  name: "message",
  initialState: {
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload
    },
  }
});

export const {
  setMessage
} = messageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getMessage = (state) => state.message.message

export default messageSlice.reducer;