import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setTaskId } from "../../redux/dataSlices/importSlice";
import { RelationImportFormFields } from "./importFormFields.jsx";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostImportRelations from "../../actablueAPI/import/PostImportRelations.jsx";
import useHandleError from "../../customhooks/useHandleError.jsx";

const ImportingRelation = (importForm, headers, data) => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const dispatch = useDispatch();
  const [matchColumnsForm, setMatchColumnsForm] = useState([])
  const navTitle = 'importing'
  const HandleError = useHandleError();

  const onNameChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: matchColumnsForm, selectfield: 'name' })
    setMatchColumnsForm(newMatchColumnsForm)
  };

  const onMatchColumnsSubmit = () => {
    let ab_data = {}
    ab_data.token = token
    ab_data.access_token = token.access_token
    ab_data.admin_id = admin_id
    ab_data.location_id = location_id
    ab_data.ab_selection = {}
    ab_data.ab_selection.admin_id = admin_id
    ab_data.ab_selection.location_id = location_id
    ab_data.read_dict = data
    ab_data.matchingForm = matchColumnsForm
    ab_data.matchingForm.administration_id = admin_id
    ab_data.matchingForm.location_id = location_id

    switch (importForm.importtype) {
      case 'relations':
        PostImportRelations({ data: ab_data })
          .then(response => {
            dispatch(setTaskId(response.data.task_id))
          })
          .catch(function (error) {
            HandleError({ error: error })
          })
        break;
      default:
        break;
    }
  };

  const importMatchColumnsFormData = {
    submit: {
      title: navTitle,
      type: 'submit',
      onClick: onMatchColumnsSubmit
    },
    fields: RelationImportFormFields(),
    // field: {
    //   onChange: onChange
    // },
    data: importForm,
    name: {
      name: 'name',
      options: headers,
      // selected: {},
      onChange: onNameChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },

  }

  return importMatchColumnsFormData
};
export default ImportingRelation;