import axios from "axios";

const PostEmployeeRole = ({token, admin_id, role}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/employee/roledefinitions`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json',
                'administration': admin_id
      },
      data: role
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default PostEmployeeRole