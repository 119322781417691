import { Button, Table } from 'react-bootstrap'
import SubscribeButtons from '../portal/subscribe/subscribebuttons'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';

export default function SubscriptionsTable(subscriptions) {
  const location_id = useSelector(getSelectedLocation_id)
  const buttons = SubscribeButtons.call()
  const subscribe = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.subscribe, { id: subscriptions.id }) : false
  const { t: translate } = useTranslation();

  let rows = []

  for (const subscription of Object.values(subscriptions)) {
    rows.push(
      <tr className='product-row' key={subscription.id}>
        <td>{subscription.name}</td>
        <td>{subscription.duration}</td>
        <td>{subscription.interval}</td>
        <td>{subscription.price}</td>
        <td>
          {location_id && <Button size="sm" type='button' onClick={() => { subscribe.onClick(subscription) }}>{translate(subscribe.tag)}</Button>}
        </td>
      </tr>
    )
  };

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Duration (months)')}</th>
            <th>{translate('Interval (months)')}</th>
            <th>{translate('Price')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </>
  );
}