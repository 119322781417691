import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';
import CountButtons from '../portal/cashcount/countbuttons';
import CountModal from './countModal';
import PaginationAndSizeComponent from './paginationAndSize';

export default function CountTable(props) {
  const counts = props?.counts?.items
  const hash_drawers_all = props.hash_drawers_all
  const pages = props?.counts?.pages
  const page = props?.counts?.page

  const buttons = CountButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (counts) {
    for (const count of Object.values(counts)) {
      rows.push(
        <tr className='product-row' key={count.id}>
          <td>{hash_drawers_all[count?.drawer_id]?.name ?? 'Unknown Drawer'}</td>
          <td>{Number(count?.counted?.total).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          <td><div className='table-icons'>{hash_drawers_all[count?.drawer_id]?.previous_count_id === count.id ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</div></td>
          {/* <td><div className='table-icons'>{count?.checked ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</div></td> */}
          {/* <td><div className='table-icons'>{count?.accepted ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</div></td> */}
          <td>
            {new Date(count?.creation).toLocaleString()}
          </td>
          <td>
            {new Date(count?.modification).toLocaleString()}
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(count) }} id={count?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(count) }} id={count?.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Drawer')}</th>
            <th>{translate('Amount counted')}</th>
            <th>{translate('Is latest count')}</th>
            {/* <th>{translate('Checked')}</th> */}
            {/* <th>{translate('Accepted')}</th> */}
            <th>{translate('Created on')}</th>
            <th>{translate('Modified at')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <CountModal modal={modalview.modal} />
    </>
  );
}