import axios from "axios";

const GetDeviceByUId = ({token, id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/devices/uid/${id}`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json'
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    });
  });
};

export default GetDeviceByUId