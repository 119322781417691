import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import PostReseller from "../../actablueAPI/kitchenmanager/PostReseller";
import { ResellerFormFields } from "./resellerFormFields";
import KmResellerForm from "../../components/kmResellerForm";


const ResellerAdd = () => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reseller, setReseller] = useState({ id: token?.payload?.sub, name: token?.payload?.name });

  const onChange = (event) => {
    let newReseller = HandleOnChange({ event: event, object: reseller })
    setReseller(newReseller)
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    PostReseller({ token: token, data: reseller })
      .then(() => {
        navigate("/kitchenmanager/resellers");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: ResellerFormFields(),
    field: {
      onChange: onChange
    },
    data: reseller
  };

  const addCard = {
    size: 12,
    title: "Reseller add",
    data: <KmResellerForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Reseller add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default ResellerAdd;

