const GuestCheckFormFields = () => {

  let guestcheckFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'order_id', id: 'order_id', readonly: true },
    { type: 'text', name: 'guestcheck_number', id: 'guestcheck_number', readonly: true },
    { type: 'text', name: 'Naam', id: 'name' },
    { type: 'select', name: 'State', id: 'state', formdatafield: 'state' }

  ]


  return guestcheckFormFields
}

export default GuestCheckFormFields