import { createSlice } from "@reduxjs/toolkit";

export const navbartitleSlice = createSlice({
  name: "navbartitle",
  initialState: {
    navbartitle: undefined,
  },
  reducers: {
    setNavBarTitle: (state, action) => {
      state.navbartitle = action.payload
    },
  }
});

export const {
  setNavBarTitle
} = navbartitleSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getNavBarTitle = (state) => state.navbartitle.navbartitle

export default navbartitleSlice.reducer;