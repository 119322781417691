import axios from "axios";

const GetLocationInfo = ({ latitude, longitude, localityLanguage = 'en' }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_WEATHER_LOCATION_BASE_URL,
      params: {
        'latitude': latitude,
        'longitude': longitude,
        'localityLanguage': localityLanguage,
      }
    })
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      console.error('error', error);
      reject(error)
    });
  });
};

export default GetLocationInfo