import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import BasicCard from "../../components/card"
import EftJournalTable from "../../components/eftJournalTable";
import GetInvoicePaymentsEJournal from "../../actablueAPI/invoicepayments/GetInvoicePaymentsEJournal";
import { getEJournalPagesize, getEJournalPagination, getEJournals, setEJournals } from "../../redux/dataSlices/eJournalSlice";
import useHandleError from "../../customhooks/useHandleError";

const Eftjournal = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const eJournals = useSelector(getEJournals)
  const pagination = useSelector(getEJournalPagination)
  const pageSize = useSelector(getEJournalPagesize)
  const dispatch = useDispatch();
  const HandleError = useHandleError();

  const card = {
    size: 12,
    title: admin_id ? 'EFT Journal' : 'Choose administration and location.',
    data: <EftJournalTable eJournals={eJournals} />,
  }

  useEffect(() => {
    dispatch(setNavBarTitle('EFT Journal'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      GetInvoicePaymentsEJournal({ token: token, admin_id: admin_id, location_id: location_id, page: pagination, size: pageSize })
        .then(response => {
          dispatch(setEJournals(response.data))
        })
        .catch(error => { HandleError({ error: error }) })
        .finally(() => { dispatch(setLoading(false)) })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, pageSize, pagination]);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default Eftjournal;
