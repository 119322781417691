// const MinStockValidator = (object, field) => {
//   if (object?.minimum_stock >= 0 && object?.minimum_stock <= object?.maximum_stock) return true
//   return false
// }

// const MaxStockValidator = (object, field) => {
//   if (object?.maximum_stock >= 0 && object?.maximum_stock >= object?.minimum_stock) return true
//   return false
// }

export const StockProductFormFields = () => {

  let stockFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'Location', id: 'location_id', formdatafield: 'location_id' },
    { type: 'text', name: `name`, id: 'name', readonly: true, disabled: true },
    { type: 'number', name: `Minimum Stock`, id: 'minimum_stock', required: false },
    // { type: 'number', name: `Maximum Stock`, id: 'maximum_stock', required: false, validator: MaxStockValidator, invalidFeedback: 'Please enter a valid amount that is greater than or equal to 0 and greater than or equal the Minimum Stock.' },
  ]

  return stockFormFields
}

export const StockProductTableFormFields = () => {

  let stockFormFields = [
    { type: 'number', name: `Minimum Stock`, id: 'minimum_stock', required: true },
    // { type: 'number', name: `Maximum Stock`, id: 'maximum_stock', required: true, placeholder: "N/A", validator: MaxStockValidator, invalidFeedback: 'Please enter a valid amount that is greater than or equal to 0 and greater than or equal the Minimum Stock.' },
  ]

  return stockFormFields
}