import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function MetaDataReport(props) {
  const country = props.country
  const gender = props.gender
  let countryLines = []
  let genderLines = []

  const { t: translate } = useTranslation();

  for (const [countryName, countryQuantity] of Object.entries(country.metadata?.Country).sort((a, b) => b[1] - a[1])) {
      countryLines.push(
        <tr key={countryName}>
          <td>{countryName}</td>
          <td>{countryQuantity}</td>
          <td></td>
          <td></td>
        </tr>
      )
  }

  for (const [genderName, genderQuantity] of Object.entries(gender.metadata?.Gender).sort((a, b) => b[1] - a[1])) {
    genderLines.push(
        <tr key={genderName}>
          <td>{genderName}</td>
          <td>{genderQuantity}</td>
          <td></td>
          <td></td>
        </tr>
      )
  }

  // for (const [countryName, quantity] of country.metadata?.Country.sort((a, b) => b - a)) {
  //     countryLines.push(
  //       <tr key={countryName}>
  //         <td>{countryName}</td>
  //         <td>{quantity}</td>
  //         {/* <td>{countryData.percentage?.toFixed(2)+'%'}</td> */}
  //         {/* <td>{countryData.revenue?.toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td> */}
  //       </tr>
  //     )
  // }

  // for (const countryData of Object.values(report).sort((a, b) => b.percentage - a.percentage)) {
  //   if (countryData.hasOwnProperty('gender')) {
  //     transactionAnalyse.push(
  //       <tr key={countryData.gender}>
  //         <td>{countryData.gender}</td>
  //         <td>{countryData.percentage?.toFixed(2)+'%'}</td>
  //         <td>{countryData.revenue?.toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
  //       </tr>
  //     )
    
  //   }
  // }


  return (
    <>
      <Table width='100%'>
        <thead>
          <tr key='country'>
            <th width='40%'>{translate('Country')}</th>
            <th width='20%'>{translate('Quantity')}</th>
            <th width='20%'>{translate('Percentage')}</th>
            <th width='20%'>{translate('Revenue')}</th>
          </tr>
        </thead>
        <tbody>
          {countryLines}
        </tbody>
      </Table>
      <Table width='100%'>
        <thead>
          <tr key='gender'>
            <th width='40%'>{translate('Gender')}</th>
            <th width='20%'>{translate('Quantity')}</th>
            <th width='20%'>{translate('Percentage')}</th>
            <th width='20%'>{translate('Revenue')}</th>
          </tr>
        </thead>
        <tbody>
          {genderLines}
        </tbody>
      </Table>
      {/* {exportButton && <Button size="sm" type='button' onClick={() => { exportButton.onClick({report: statusReport, type:'statusReportSingleDay' }) }}>{translate(exportButton.tag)}</Button>} */}
      {/* {pdfButton && <Button size="sm" type='button' onClick={() => { pdfButton.onClick({ report: statusReport, type:'statusReportSingleDay' }) }}>{translate(pdfButton.tag)}</Button>} */}
    </>
  )
}