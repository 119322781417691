import { Table, Tab, Nav } from 'react-bootstrap'

export default function AnalyseOrderDataTable(data) {
  let notpaid = []
  let notransaction = []
  let totals = []

  if (data.results) {
    
    let counter = 0
    
    if (data.results.notpaid?.length > 0) {
      for (const order of data.results.notpaid) {
        notpaid.push(
          <tr className='product-row' key={counter}>
            <td>{order.id}</td>
            <td>{order.name}</td>
            <td>{order.payment_amount_inclusive_vat?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            <td>{new Date(order.creation).toLocaleString()}</td>
            <td>{new Date(order.modification).toLocaleString()}</td>
          </tr>
        )
        counter++
      }
    }
    
    if (data.results.notransaction?.length > 0) {
      for (const order of data.results.notransaction) {
        notransaction.push(
          <tr className='product-row' key={counter}>
            <td>{order.id}</td>
            <td>{order.name}</td>
            <td>{order.payment_amount_inclusive_vat?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            <td>{new Date(order.creation).toLocaleString()}</td>
            <td>{new Date(order.modification).toLocaleString()}</td>
          </tr>
        )
        counter++
      }
  
    }
    
    if (data.results.totals?.length > 0) {
      for (const order of data.results.totals) {
        totals.push(
          <tr className='product-row' key={counter}>
            <td>{order.id}</td>
            <td>{order.name}</td>
            <td>{order.order_total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            <td>{order.invoice_total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            <td>{order.transaction_total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            <td>{order.payment_total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            <td>{order.transaction_total_paid?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            <td>{new Date(order.creation).toLocaleString()}</td>
            <td>{new Date(order.modification).toLocaleString()}</td>
          </tr>
        )
        counter++
      }
  
    } 

  }

  return (
    <Tab.Container defaultActiveKey="notpaid">
    <Nav variant="tabs">
      <Nav.Item><Nav.Link eventKey="notpaid">Not paid</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="notransaction">No transaction</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="totals">Totals</Nav.Link></Nav.Item>
    </Nav>
    <Tab.Content>
      <Tab.Pane eventKey="notpaid">
        <div className='tab-container'>
          <h2>Not paid ({notpaid.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Total</th>
                <th>Creation</th>
                <th>Modification</th>
              </tr>
            </thead>
            <tbody>
              {notpaid}
            </tbody>
          </Table>
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="notransaction">
        <div className='tab-container'>
          <h2>No transactions ({notpaid.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Total</th>
                <th>Creation</th>
                <th>Modification</th>
              </tr>
            </thead>
            <tbody>
              {notransaction}
            </tbody>
          </Table>
        </div>
      </Tab.Pane>
      <Tab.Pane eventKey="totals">
        <div className='tab-container'>
          <h2>Totals ({notpaid.length})</h2>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Order total</th>
                <th>Invoice total</th>
                <th>Transaction total</th>
                <th>Transaction total paid</th>
                <th>Payment total</th>
                <th>Creation</th>
                <th>Modification</th>
              </tr>
            </thead>
            <tbody>
              {totals}
            </tbody>
          </Table>
        </div>
      </Tab.Pane>
    </Tab.Content>
  </Tab.Container>
  );
}