export function validateLocation(location) {
    if (
        location &&
        location.latitude !== undefined &&
        location.longitude !== undefined &&
        location.timezone !== undefined &&
        location.address &&
        location.address.place &&
        location.address.country 
    ) {
        return true;
    }
    return false;
}