import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import TableButton from './tableButton';
import NarrowcastingViewsButtons from '../portal/narrowcasting/narrowcastingViewsButtons';
import NarrowcastingViewsModal from './narrowcastingViewsModal';

import './css/productTable.css';
import PaginationAndSizeComponent from './paginationAndSize';

export default function NarrowcastingViewsTable(props) {
  const views = props.views?.items || []
  const pages = props?.views?.pages || 0
  const page = props?.views?.page || 0
  
  const buttons = NarrowcastingViewsButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []
  for (const view of Object.values(views)) {
    rows.push(
      <tr className='product-row' key={view.id}>
        <td>{view.name}</td>
        <td>{view.content_type}</td>
        <td>{view.seconds}</td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(view) }}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(view) }}>{translate(edit.tag)}</Button>}
          {del && <Button size="sm" type='button' onClick={() => { del.onClick(view) }} id={view.id}>{translate(del.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Content Type')}</th>
            <th>{translate('Seconds')}</th>
            <th width='25%'>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <NarrowcastingViewsModal modal={modalview.modal} />
    </>
  );
}