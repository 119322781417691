import React, { useEffect } from "react";
import BasicCard from "../../components/card";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import QrOrderingTable from "../../components/qrOrderingTable";
import HandleOnChange from "../../helpers/handleOnChange";
import ProgressModal from "../../components/progressModal";
import { getProgressModalShow, setProgressModalShow } from "../../redux/dataSlices/analyseSlice";
import { getData, setData } from "../../redux/dataSlices/qrorderingSlice";

const Qrordering = () => {
  const navTitle = "QR-ordering"
  const qrOrderingData = useSelector(getData)
  const dispatch = useDispatch();
  const modal_show = useSelector(getProgressModalShow)


  const onChange = (event) => {
    let newData = HandleOnChange({ event: event, object: qrOrderingData })
    if (newData?.table_number_range_start > newData?.table_number_range_end) return
    dispatch(setData(newData));
  }

  const formData = {
    data: qrOrderingData,
    field: {
      onChange: onChange
    },
  };

  const card = {
    size: 12,
    title: navTitle,
    data: <QrOrderingTable {...formData} />,
  };

  const onViewHide = () => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Exporting...',
      onHide: onViewHide
    }
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ProgressModal {...progressModal} />
      <BasicCard {...card} />
    </>
  )
};
export default Qrordering;
