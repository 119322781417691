import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button, Table } from 'react-bootstrap';

import { getRole } from '../redux/dataSlices/tokenSlice';

import { getSortCategory } from '../redux/dataSlices/kitchenmanagerSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';


export default function SortCategoryModal(props) {
  const object = useSelector(getSortCategory);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  let sorted_products = [];
  let sorted_product_categories = [];

  if (object) {

    object.sorted_products.forEach(product => {
      sorted_products.push(<tr className='product-row'>
        <td>{product?.name !== '' ? product?.name : product?.product_id}</td>
        <td>{product?.printer?.name}</td>
        <td>{JSON.stringify(product?.has_multiple_groups)}</td>
      </tr>)
    });

    object.sorted_product_categories.forEach(category => {
      sorted_product_categories.push(<tr className='product-row'>
        <td>{category?.name !== '' ? category?.name : category?.product_category_id}</td>
        <td>{category?.printer?.name}</td>
      </tr>)
    });

    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Sort-category: {object?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="sorted_products">{translate('Sorted products')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="sorted_product_categories">{translate('Sorted product categories')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object?.name}</p>
                      <p>{translate('Sort Index')}: {object?.sort_index}</p>
                      <p>{translate('Amount sorted products')}: {object?.sorted_products.length}</p>
                      <p>{translate('Amount sorted product categories')}: {object?.sorted_product_categories.length}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Administration')}: {hash_admins_all[object.admin_id]?.name}</p>
                      <p>{translate('Location')}: {hash_locations_all[object.location_id]?.name}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="sorted_products">
                <div className="container">
                  <div className="row">
                    <Table hover width='100%'>
                      <thead>
                        <tr className='product-row'>
                          <th>
                            {translate('Product')}
                          </th>
                          <th>
                            {translate('Printer')}
                          </th>
                          <th>
                            {translate('Has multiple categories?')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sorted_products}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="sorted_product_categories">
                <div className="container">
                  <div className="row">
                    <Table hover width='100%'>
                      <thead>
                        <tr className='product-row'>
                          <th>
                            {translate('Product Category')}
                          </th>
                          <th>
                            {translate('Printer')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sorted_product_categories}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object?.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>admin_id: {object?.admin_id}</p>
                    </div>
                    <div className="col">
                      <p>location_id: {object?.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}