import axios from "axios";

const GetTemplateById = ({ token, admin_id, template_id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/schapkaartjes/template`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        id: template_id,
        admin_id: admin_id
      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetTemplateById