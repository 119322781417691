import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import DeviceForm from "../../components/deviceForm";
import SmartPOSFormFields from "./smartposDeviceFormFields";
import VectronFormFields from "./vectronDeviceFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { deviceTypeChoices } from "../../selectfieldchoices/deviceselect.mjs";
import PutDevicesDevice from "../../actablueAPI/devices/PutDevicesDevice";
import useHandleError from "../../customhooks/useHandleError";

const DeviceAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const [device, setDevice] = useState({ administration_id: admin_id, location_id: location_id, type: 'SMARTPOS' })

  const onChange = (event) => {
    let newDevice = HandleOnChange({ event: event, object: device })
    setDevice(newDevice)
  }

  const onTypeChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'type' })
    setDevice(newDevice)
  }

  const onLocationIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'location_id' })
    setDevice(newDevice)
  }

  const onAdminIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'administration_id' })
    delete newDevice.location_id
    dispatch(setDevice(newDevice))
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    PutDevicesDevice({ token: token, admin_id: device?.administration_id, type: device?.type.toLowerCase(), data: device })
      .then(() => {
        navigate("/devices");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: device?.type === 'VECTRON' ? VectronFormFields() : SmartPOSFormFields(),
    field: {
      onChange: onChange
    },
    data: device,
    type: {
      label: 'type',
      options: deviceTypeChoices,
      selected: { value: 'SMARTPOS', label: 'SMARTPOS' },
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === device?.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Device add ",
    data: <DeviceForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Device add"))

    if (!formData?.data) navigate(-1)

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  if (formData?.data) {
    return (
      <>
        <BasicCard {...addCard} />
      </>
    )
  }

};
export default DeviceAdd;

