export const NarrowcastingPlaylistFormFields = () => {

  const NarrowcastingPlaylistFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'hidden', name: 'admin_id', id: 'admin_id' },
    { type: 'hidden', name: 'location_id', id: 'location_id' },
    { type: 'text', name: `Name`, id: 'name', required: true },
    { type: 'text', name: `Description`, id: 'description', required: false },
    { type: 'select', name: `Layout`, id: 'layout', formdatafield: 'layout', required: true },
    { type: 'checkbox', name: 'All day', id: 'all_day' },
    { type: 'time', name: `Start Time`, id: 'start_time', required: true, disabled: false },
    { type: 'time', name: `End Time`, id: 'end_time', required: true, disabled: false },
    { type: 'multiselect', name: `Views`, id: 'views', formdatafield: 'views' }
  ]

  return NarrowcastingPlaylistFormFields
}