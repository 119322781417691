import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getCcvshops, getHashCcvshops } from "../../redux/dataSlices/ccvshopSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import CcvshopWebhookForm from "../../components/ccvshopWebhookForm";
import { CcvshopWebhookFormFields } from "./ccvshopFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { ccvWebhookEventChoices, ccvWebhookTypeChoices } from "../../selectfieldchoices/ccvselect.mjs";
import PostCcvshopWebhook from "../../actablueAPI/ccvshop/PostCcvshopWebhook.jsx";

const CcvshopWebhookAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const ccvshops = useSelector(getCcvshops)
  const hash_ccvshops = useSelector(getHashCcvshops)
  const [ccvshop_webhook, setCcvshopWebhook] = useState({ administration: admin_id })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "CCVShop webhook add"

  const onChange = (event) => {
    let newCcvshopWebhook = HandleOnChange({ event: event, object: ccvshop_webhook })
    dispatch(setCcvshopWebhook(newCcvshopWebhook))
  }

  const onTypeChange = (event) => {
    let newCcvshopWebhook = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: ccvshop_webhook, selectfield: 'type' })
    dispatch(setCcvshopWebhook(newCcvshopWebhook))
  }

  const onEventChange = (event) => {
    let newCcvshopWebhook = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: ccvshop_webhook, selectfield: 'event' })
    dispatch(setCcvshopWebhook(newCcvshopWebhook))
  }

  const onCcvApiKeyChange = (event) => {
    let newCcvshopWebhook = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: ccvshop_webhook, selectfield: 'ccv_api_key_id' })
    dispatch(setCcvshopWebhook(newCcvshopWebhook))
  }

  // const onLocationIdChange = (event) => {
  //   let newCcvshopWebhook = HandleOnChangeSingleSelectFieldWithValue({event:event, object:ccvshop_webhook, selectfield:'location_id'})
  //   dispatch(setCcvshopWebhook(newCcvshopWebhook))
  // }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostCcvshopWebhook({ token: token, admin_id: admin_id, data: ccvshop_webhook })
      .then(() => {
        navigate("/ccvshops");
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: CcvshopWebhookFormFields(),
    field: {
      onChange: onChange
    },
    data: ccvshop_webhook,
    type: {
      label: 'type',
      options: ccvWebhookTypeChoices,
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    event: {
      label: 'event',
      options: ccvWebhookEventChoices,
      onChange: onEventChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    ccv_api_key_id: {
      label: 'ccv_api_key_id',
      options: ccvshops,
      hash: hash_ccvshops,
      onChange: onCcvApiKeyChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: navTitle,
    data: <CcvshopWebhookForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default CcvshopWebhookAdd;

