import { useSelector } from 'react-redux';

import { getRole } from '../redux/dataSlices/tokenSlice';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Nav, Tab } from 'react-bootstrap';
import './css/modal.css'
import './css/productModal.css'

import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { getPlanningObject } from '../redux/dataSlices/planningSlice';

export default function PlanningObjectModal(props) {
  const object = useSelector(getPlanningObject);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Planning Object
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>{translate('Description')}: {object.description}</p>
                      <p>{translate('Type')}: {object.type || "N/A"}</p>
                      <p>{translate('Capacity')}: {object.capacity || "N/A"}</p>
                    </div>
                    <div className="col">
                      <p>{translate('Minimum planning size')}: {object.minimum_planning_size}</p>
                      <p>{translate('Automatically assignable')}: {JSON.stringify(object.automatically_assignable)}</p>
                      <p>{translate('Automatically maximize capacity')}: {JSON.stringify(object.automatically_maximize_capacity)}</p>
                      <p>{translate('Counts for capacity')}: {JSON.stringify(object.counts_for_capacity)}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}