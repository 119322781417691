import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarView, getHashPlanningObjectsAll, getHashPlanningsFromPlanningObjects, setCalendarEnd, setCalendarLoading, setCalendarStart, setCalendarView, setHashPlanningsFromPlanningObjects, setPlanning, setPlanningModalShow } from '../redux/dataSlices/planningSlice';
import "./css/planningCalendar.css"
import DeepCopy from '../helpers/DeepCopy';
import { PutPlanning } from '../actablueAPI/planning/Planning';
import { getToken } from '../redux/dataSlices/tokenSlice';
import PlanningButtons from '../portal/planning/planningbuttons';
import PlanningModal from './planningModal';
import useHandleError from '../customhooks/useHandleError';
import { Col, Container, Row } from 'react-bootstrap';
import { UuidToColor } from '../helpers/ColorHelper';
import { useContextMenu } from 'react-contexify';
import PlanningCalendarItemMenu from './planningCalendarItemMenu';
import Completed from "../static/icons/statusCompleted.svg";
import Accepted from "../static/icons/statusAccepted.svg";
import Pending from "../static/icons/statusPending.svg";
import Cancelled from "../static/icons/statusCancelled.svg";
import Unknown from "../static/icons/statusUnknown.svg"
import { ReactSVG } from 'react-svg';

const localizer = momentLocalizer(moment); // DO NOT MOVE
const DnDCalendar = withDragAndDrop(Calendar); // DO NOT MOVE

const PlanningCalendar = () => {
  const plannings = useSelector(getHashPlanningsFromPlanningObjects);
  const planning_objects = useSelector(getHashPlanningObjectsAll);
  const token = useSelector(getToken);
  const calendarView = useSelector(getCalendarView);
  const buttons = PlanningButtons.call();
  const modalview = buttons.hasOwnProperty('calendarmodalview') ? Object.assign({}, buttons.calendarmodalview) : false
  const [events, setEvents] = useState([])
  const [objectColors, setObjectColors] = useState({});
  const [hiddenObjects, setHiddenObjects] = useState({});
  const [hiddenStatus, setHiddenStatus] = useState([]);
  const HandleError = useHandleError();
  const dispatch = useDispatch();
  const { show } = useContextMenu({
    id: "planning-calendar-item-menu"
  });

  useEffect(() => {
    let newEvents = [];
    let newObjectColors = {}
    Object.values(planning_objects).forEach((object) => {
      newObjectColors[object?.id] = object?.color || UuidToColor(object?.id, 100, 75, true)
    })
    setObjectColors(newObjectColors);

    if (plannings) {
      // eslint-disable-next-line array-callback-return
      Object.entries(plannings)?.map(([id, plannings]) => {
        if (!hiddenObjects[id]) {
          Object.values(plannings).forEach(planning => {
            if (hiddenStatus.includes(planning?.status?.toLowerCase())) return;
            const startDate = new Date(planning?.planning_timestamp);
            const endDate = new Date(startDate.getTime() + planning?.duration * 60000);
            newEvents.push({
              title: planning?.planning_name,
              start: startDate,
              end: endDate,
              id: planning?.id,
              planning_object_id: id,
              object: planning,
            })
          })
        }
      });
      setEvents(newEvents);
    }
    // eslint-disable-next-line
  }, [plannings, hiddenObjects, hiddenStatus]);

  const updateEventToPlanning = async ({ event, start, end }) => {
    const updatedEvents = events.map(currentEvent =>
      currentEvent.id === event.id ? { ...currentEvent, start, end } : currentEvent
    );

    const differenceInMs = end - start;
    const newDuration = differenceInMs / (1000 * 60);

    let newPlannings = DeepCopy(plannings)
    for (const planning_object of event?.object?.planning_objects) {
      if (!newPlannings[planning_object?.planning_object_id]) newPlannings[planning_object?.planning_object_id] = {}
      newPlannings[planning_object?.planning_object_id][event?.id].duration = newDuration
      newPlannings[planning_object?.planning_object_id][event?.id].planning_timestamp = new Date(start).getTime();
    }

    setEvents(updatedEvents);
    dispatch(setCalendarLoading(true));
    await PutPlanning({ token: token, data: newPlannings[event?.planning_object_id][event?.id] })
      .then(response => {
        const updatedEvents = events.map(currentEvent =>
          currentEvent.id === event.id ? { ...currentEvent, start, end, object: response.data } : currentEvent
        );
        for (const planning_object of event?.object?.planning_objects) {
          if (!newPlannings[planning_object?.planning_object_id]) newPlannings[planning_object?.planning_object_id] = {}
          newPlannings[planning_object?.planning_object_id][event?.id] = response.data
        }
        setEvents(updatedEvents);
        dispatch(setHashPlanningsFromPlanningObjects(newPlannings))
      })
      .catch(error => {
        setEvents(events); // Revert the changed events
        HandleError({ error: error?.response?.data?.message || error, anchorOrigin: { horizontal: 'center', vertical: 'top' } })
      })
      .finally(() => {
        dispatch(setCalendarLoading(false));
      })
  }

  const handleSelectSlot = ({ event, start, end }) => {
    return;
    // if (calendarView === 'month') return; // We don't want people to be able to create plannings in the month view because the control for it is different
    // TODO: Add support for adding new plannings using the drag "select slot"
  }

  const handleEventDoubleClick = (event) => {
    dispatch(setPlanning(event?.object))
    dispatch(setPlanningModalShow(true))
  };

  const handleEventDrop = async ({ event, start, end }) => {
    updateEventToPlanning({ event: event, start: start, end: end });
  };

  const handleEventResize = async ({ event, start, end }) => {
    updateEventToPlanning({ event: event, start: start, end: end });
  };

  const onClickHideObject = (event, object) => {
    let newHiddenObjects = DeepCopy(hiddenObjects)
    if (newHiddenObjects[object?.id]) {
      delete newHiddenObjects[object?.id]
    } else {
      newHiddenObjects[object?.id] = object
    }
    setHiddenObjects(newHiddenObjects);
  }

  const onClickHideStatus = (event, status) => {
    let newHiddenStatus = DeepCopy(hiddenStatus);
    if (newHiddenStatus.includes(status)) {
      const index = newHiddenStatus.findIndex(foundStatus => status === foundStatus);
      newHiddenStatus.splice(index, 1);
    } else {
      newHiddenStatus.push(status);
    }
    setHiddenStatus(newHiddenStatus);
  } 

  // const handleDropFromOutside = ({ event, start, end }) => {
  //   console.log('Dropped from outside:', { event, start, end });
  // };

  // Handler for navigating the calendar (today, back, next buttons)
  // const handleNavigate = (date, view) => {
  //   console.log('Navigated to:', { date, view });
  // };

  // Handler for changing the view (month, week, day buttons)
  const handleViewChange = (view) => {
    dispatch(setCalendarView(view))
  };

  const handleRangeChange = (range) => {
    let start, end;
    if (Array.isArray(range)) {
      start = moment(range[0]).startOf('day').subtract(1, 'days').toDate();
      end = moment(range[range.length - 1]).endOf('day').add(1, 'days').toDate();
    } else {
      start = moment(range.start).startOf('day').subtract(1, 'days').toDate();
      end = moment(range.end).endOf('day').add(1, 'days').toDate();
    }

    const formattedStart = moment(start).format('YYYY-MM-DDTHH:mm:ss');
    const formattedEnd = moment(end).format('YYYY-MM-DDTHH:mm:ss');

    dispatch(setCalendarStart(formattedStart));
    dispatch(setCalendarEnd(formattedEnd));
  };

  const eventStyleGetter = (event) => {
    let backgroundColor = 'white';
    let border = '1px solid black';
    let color = '#a0a0a0';
    if (objectColors[event.planning_object_id]) backgroundColor = `${objectColors[event.planning_object_id]}8C`;
    if (objectColors[event.planning_object_id]) border = `1px solid ${backgroundColor}`

    const customStyles = {
      backgroundColor,
      color,
      border,
      borderRadius: '5px'
    };

    return {
      style: customStyles,
    };
  };

  const displayContextMenu = (event, planning) => {
    show({
      event: event,
      props: { planning: planning?.object, currentPlanningObjectId: planning?.planning_object_id }
    })
  }

  const EventWrapper = ({ event, children, style }) => {
    return (
      <div style={style} onContextMenu={(eve) => { displayContextMenu(eve, event) }}>
        {children}
      </div>
    );
  };

  const EventBodyWrapper = ({ event }) => {
    // let statusColor = '#FFFFFF'
    let statusIcon = Unknown

    switch (event.object.status) {
      case 'COMPLETED':
        statusIcon = Completed;
        break;
      case 'ACCEPTED':
        // statusColor = '#bc3c3c';
        statusIcon = Accepted;
        break;
      case 'PENDING':
        // statusColor = '#FFFD98';
        statusIcon = Pending;
        break;
      case 'CANCELLED':
        // statusColor = '#bc3c3c';
        statusIcon = Cancelled;
        break;
      default:
        break;
    }

    return (
      <>
        <div style={{ float: 'right' }}>
          <span
            className='rbc-object-color'
            style={{
              borderRadius: '50%',
              display: 'inline-block',
              width: '1.5rem',
              height: '1.5rem', mixBlendMode: 'difference'
            }}
          >
            <ReactSVG className='rbc-item-status-icon' src={statusIcon} />
          </span>
        </div>
        <div id={event.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <p style={{ margin: 0, mixBlendMode: 'difference', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {event.title}
          </p>
        </div>
      </>
    )
  };

  return (
    <>
      <DnDCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week', 'day']}
        defaultView="month"
        view={calendarView}
        draggableAccessor={() => true}
        resizable
        selectable
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        onSelectSlot={handleSelectSlot}
        // onDropFromOutside={handleDropFromOutside}
        onDoubleClickEvent={handleEventDoubleClick}
        // onNavigate={handleNavigate}
        onView={handleViewChange}
        onRangeChange={handleRangeChange}
        eventPropGetter={eventStyleGetter}
        dayLayoutAlgorithm={'no-overlap'}
        components={{
          eventWrapper: EventWrapper,
          event: EventBodyWrapper
        }}
        style={{ minHeight: calendarView === 'month' ? '55rem' : '100rem' }}
      />
      <Container fluid style={{ marginTop: '1rem', paddingLeft: 15 }}>
        <Row style={{ paddingLeft: 0 }}>
          <Col md={4} className='rbc-objects' style={{ paddingLeft: 0 }}>
            <ul style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', paddingLeft: 0, margin: 0 }}>
              {Object.values(planning_objects).map(object => (
                <li key={object.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                  <span
                    className='rbc-object-color'
                    onClick={(event) => { onClickHideObject(event, object) }}
                    style={{
                      borderRadius: '50%',
                      background: `${objectColors[object?.id]}8C`,
                      display: 'inline-block',
                      width: '1.5rem',
                      height: '1.5rem',
                      minHeight: '1.5rem',
                      minWidth: '1.5rem',
                      marginRight: '0.5rem',
                      cursor: 'pointer',
                      border: `1px solid ${objectColors[object?.id]}`
                    }}
                  />
                  <p style={{ margin: 0, cursor: 'pointer', textDecoration: hiddenObjects[object?.id] ? 'line-through' : 'none' }} onClick={(event) => { onClickHideObject(event, object) }}>{object.name}</p>
                </li>
              ))}
            </ul>
          </Col>
          <Col className='rbc-objects' style={{ paddingLeft: 0, marginRight: '2rem' }}>
            <ul style={{ display: 'grid', paddingLeft: 0, margin: 0, float: 'right' }}>
              <li key={'status-cancelled'} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <span
                  onClick={(event) => { onClickHideStatus(event, 'cancelled') }}
                  style={{
                    display: 'inline-block',
                    width: '1.5rem',
                    height: '1.5rem',
                    minHeight: '1.5rem',
                    minWidth: '1.5rem',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}>
                  <ReactSVG className='rbc-item-status-icon' src={Cancelled} />
                </span>
                <p style={{ margin: 0, cursor: 'pointer', textDecoration: hiddenStatus.includes('cancelled') ? 'line-through' : 'none' }} onClick={(event) => { onClickHideStatus(event, 'cancelled') }}>Cancelled</p>
              </li>
              <li key={'status-pending'} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <span
                  onClick={(event) => { onClickHideStatus(event, 'pending') }}
                  style={{
                    display: 'inline-block',
                    width: '1.5rem',
                    height: '1.5rem',
                    minHeight: '1.5rem',
                    minWidth: '1.5rem',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}>
                  <ReactSVG className='rbc-item-status-icon' src={Pending} />
                </span>
                <p style={{ margin: 0, cursor: 'pointer', textDecoration: hiddenStatus.includes('pending') ? 'line-through' : 'none' }} onClick={(event) => { onClickHideStatus(event, 'pending') }}>Pending</p>
              </li>
              <li key={'status-accepted'} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <span
                  onClick={(event) => { onClickHideStatus(event, 'accepted') }}
                  style={{
                    display: 'inline-block',
                    width: '1.5rem',
                    height: '1.5rem',
                    minHeight: '1.5rem',
                    minWidth: '1.5rem',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}>
                  <ReactSVG className='rbc-item-status-icon' src={Accepted} />
                </span>
                <p style={{ margin: 0, cursor: 'pointer', textDecoration: hiddenStatus.includes('accepted') ? 'line-through' : 'none' }} onClick={(event) => { onClickHideStatus(event, 'accepted') }}>Accepted</p>
              </li>
              <li key={'status-completed'} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <span
                  onClick={(event) => { onClickHideStatus(event, 'completed') }}
                  style={{
                    display: 'inline-block',
                    width: '1.5rem',
                    height: '1.5rem',
                    minHeight: '1.5rem',
                    minWidth: '1.5rem',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}>
                  <ReactSVG className='rbc-item-status-icon' src={Completed} />
                </span>
                <p style={{ margin: 0, cursor: 'pointer', textDecoration: hiddenStatus.includes('completed') ? 'line-through' : 'none' }} onClick={(event) => { onClickHideStatus(event, 'completed') }}>Completed</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <PlanningModal modal={modalview.modal} calendar={true} />
      <PlanningCalendarItemMenu />
    </>
  );
};

export default PlanningCalendar;
