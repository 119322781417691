import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import TableButton from './tableButton';
import ReservationOptionButtons from '../portal/reservations/reservationoptionbuttons';
import ReservationOptionModal from "../components/reservationoptionModal";
import { useTranslation } from 'react-i18next';


function ReservationOptionTable(reservationoptions) {
  const buttons = ReservationOptionButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let rows = []
  for (let reservationoption in reservationoptions) {
    let resobject = reservationoptions[reservationoption]
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: resobject.id }) : false
    const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false

    rows.push(
      <tr className='product-row' key={resobject.id}>
        <td>{resobject.name}</td>
        <td>{resobject.starting_time}</td>
        <td>{resobject.ending_time}</td>
        <td>{resobject.days_before_final_cancel}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(resobject) }} id={resobject.id}>{translate(modalview.tag)}</Button>
          {edit && <TableButton {...edit} />}
          {del && <Button size="sm" type='button' onClick={() => { del.onClick(resobject) }} id={resobject.id}>{translate(del.tag)}</Button>}
        </td>
      </tr>
    )
  }

  if (reservationoptions) {
    return (
      <>
        {add && <TableButton {...add} />}
        <Table hover width='100%'>
          <thead>
            <tr>
              <th>
                {translate('Name')}
              </th>
              <th>
                {translate('Start')}
              </th>
              <th>
                {translate('End')}
              </th>
              <th>
                {translate('Cancel before days')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
          <ReservationOptionModal modal={modalview.modal} />
        </Table>
      </>
    );
  } else {
    return (
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Start')}
            </th>
            <th>
              {translate('End')}
            </th>
            <th>
              {translate('Cancel before days')}
            </th>
            <th>
              {translate('Actions')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
      </Table>
    );
  }
}

export default ReservationOptionTable;