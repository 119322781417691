export const templateFormFields = [
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  // { type: 'number', name: 'title_fontsize_px', id: 'title_fontsize_px' },
  // { type: 'number', name: 'contents_fontsize_px', id: 'contents_fontsize_px' },
  // { type: 'number', name: 'price_fontsize_px', id: 'price_fontsize_px' },
  // { type: 'number', name: 'title_width', id: 'title_width' },
  // { type: 'number', name: 'contents_width', id: 'contents_width' },
  // { type: 'number', name: 'price_width', id: 'price_width' },
  // { type: 'number', name: 'code_width_px', id: 'code_width_px' },
  // { type: 'number', name: 'code_height_px', id: 'code_height_px' },
]

export const productPrintFormFields =
  [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'select', name: 'eans', id: 'eans', formdatafield: 'eans' },
  ]

