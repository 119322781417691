export default function HandleOnChangeMultiSelectFieldWithValues(props) {
  const event = props.event
  const object = props.object || {}
  const selectfield = props.selectfield || props.event.id
  const prefix = event?.prefix ?? props.prefix

  let newObject = JSON.parse(JSON.stringify(object))
  let existingSelection = []
  let newSelection = []

  if (prefix) {
    // Check if newObject is defined
    if (!newObject) {
      newObject = {};
    }

    // Check if newObject[prefix] is defined as an object
    if (!newObject[prefix]) {
      newObject[prefix] = {};
    }

    // Now you can safely set the property
    newObject[prefix][selectfield] = [];
  }

  if (!prefix) newObject[selectfield] = []

  // create array with excisting id's  
  if (object.hasOwnProperty(selectfield)) {
    // eslint-disable-next-line
    object[selectfield].map((item) => {
      existingSelection.push(item.id)
    })
  }

  // create array with new id's
  event.forEach(target => {
    newSelection.push(target.value)
  });

  // check for id's that stay or must be deleted
  if (object.hasOwnProperty(selectfield)) {
    // eslint-disable-next-line
    object[selectfield].map((selected) => {
      if (newSelection.includes(selected.id)) {
        if (prefix) newObject[prefix][selectfield].push(selected)
        if (!prefix) newObject[selectfield].push(selected)
      }
    })
  }

  // check for new id's, add them to object.selectfield
  event.forEach(target => {
    if (!existingSelection.includes(target.value)) {
      if (prefix) newObject[prefix][selectfield].push(target.value)
      if (!prefix) newObject[selectfield].push(target.value)
    }
  });

  return (newObject)
}