export const printerTypeChoices = [
    { 'id': 'Bixolon', 'name': 'Bixolon' },
    { 'id': 'Windows', 'name': 'Windows' },
    { 'id': 'Epson', 'name': 'Epson' },
    { 'id': 'Brother', 'name': 'Brother' },
]

export const printerPaperTypeChoices = [
    { 'id': 'G', 'name': 'Gap' },
    { 'id': 'C', 'name': 'Continuous' },
    { 'id': 'B', 'name': 'Black Mark' }
]