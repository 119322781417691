import axios from "axios";

const PostRegistrationsRegistrationUser = (dataObject) => {
  let origin = 'actablue'
  if (window.location.href.includes('humbee')) {origin = 'humbee'}

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/registrations/registration/user/${origin}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataObject
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  });
};

export default PostRegistrationsRegistrationUser