const LocationFormFields = () => {
  let locationFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name', required: true },
    { type: 'text', name: 'Description', id: 'description', required: false },
    { type: 'number', name: 'Delivery cost', id: 'delivery_cost', required: false },
    { type: 'number', name: 'Delivery cost threshold', id: 'delivery_cost_threshold', required: false },
    { type: 'text', name: 'Delivery range', id: 'delivery_range', required: false },
    { type: 'text', name: 'Menu URL', id: 'menu_url', required: false },
    { type: 'text', name: 'Reservation URL', id: 'reservation_url', required: false },
    { type: 'text', name: 'Webshop URL', id: 'webshop_url', required: false },
    { type: 'text', name: 'Timezone', id: 'timezone', required: true },
    // { type: 'object', name: 'Reservations', id: 'reservation_location', fields: Reservations() },
    { type: 'object', name: 'Address', id: 'address', fields: address },
    { type: 'checkbox', name: 'Eco', id: 'eco', required: false },
    { type: 'checkbox', name: 'Can reservate', id: 'can_reservate', required: false },
    { type: 'checkbox', name: 'Can deliver', id: 'can_deliver', required: false },
    { type: 'checkbox', name: 'Can take out', id: 'can_take_out', required: false },
    { type: 'select', name: 'Price indication', id: 'price_indication', formdatafield: 'price_indication', required: false },
    { type: 'multi+create', name: 'Image URLs', id: 'img_urls', formdatafield: 'img_urls', required: false },

  ]

  return locationFormFields
}

let address = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true, prefix: 'address' },
  { type: 'text', name: 'Street', id: 'street', prefix: 'address', required: true },
  { type: 'text', name: 'Streetnumber', id: 'street_number', prefix: 'address', required: true },
  { type: 'text', name: 'Additional', id: 'street_number_additional', prefix: 'address', required: false },
  { type: 'text', name: 'Zipcode', id: 'zip_code', prefix: 'address', required: true },
  { type: 'text', name: 'Place', id: 'place', prefix: 'address', required: true },
  { type: 'text', name: 'Proverence', id: 'proverence', prefix: 'address', required: false },
  { type: 'text', name: 'Country', id: 'country', prefix: 'address', required: true },
]

export default LocationFormFields