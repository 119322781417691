import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getResellerPageSize, getResellerPagination, getResellers, setResellers } from "../../redux/dataSlices/kitchenmanagerSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import useHandleError from "../../customhooks/useHandleError";
import GetResellers from "../../actablueAPI/kitchenmanager/GetResellers";
import GetReseller from "../../actablueAPI/kitchenmanager/GetReseller";
import KmResellerTable from "../../components/kmResellerTable";
import { useNavigate } from "react-router-dom";

const Resellers = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const role = useSelector(getRole);
  const resellers = useSelector(getResellers);
  const reseller_pagination = useSelector(getResellerPagination);
  const reseller_pagesize = useSelector(getResellerPageSize);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const navTitle = "Kitchen Manager"

  const resellersCard = {
    size: 12,
    title: "Resellers",
    data: <KmResellerTable resellers={resellers} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    dispatch(setLoading(true))

    if (!scopes.resellers || role === 'merchant') {
      HandleError({ error: 'You do not have permission to view this page' })
      navigate('/dashboards')
      return;
    }

    if (scopes.resellers) {
      if (role === 'super' || role === 'admin') {
        GetResellers({ token: token, page: reseller_pagination, size: reseller_pagesize })
          .then(response => {
            dispatch(setResellers(response.data))
          })
          .catch(error => {
            HandleError({ error: error })
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      }

      if (role === 'reseller') {
        GetReseller({ token: token, id: token?.payload?.sub })
          .then(response => {
            dispatch(setResellers([response.data]))
          })
          .catch(error => {
            if (error?.response?.status !== 404) {
              HandleError({ error: error })
            }
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      }
    }

    // eslint-disable-next-line
  }, [, reseller_pagesize, reseller_pagination]);

  return (
    <>
      {scopes.resellers &&
        <BasicCard {...resellersCard} />
      }
    </>
  )
};
export default Resellers;

