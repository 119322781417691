import { createSlice } from "@reduxjs/toolkit";

export const loggedInUserSlice = createSlice({
  name: "loggedInUser",
  initialState: {
  },
  reducers: {
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload
    },
    clearReduxAll: state => {}
  }
});

export const {
  setLoggedInUser,
  clearReduxAll
} = loggedInUserSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getLoggedInUser = (state) => state.loggedInUser.loggedInUser


export default loggedInUserSlice.reducer;