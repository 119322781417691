import { getToken } from '../../redux/dataSlices/tokenSlice';
import { getSelectedAdmin_id } from '../../redux/dataSlices/selectAdminSlice';
import { getSelectedLocation_id } from '../../redux/dataSlices/selectLocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import downloadQrCodes, { generateQRCode } from '../../helpers/downloadQrcodes';
import { useNavigate } from 'react-router-dom';
import { setProgressModalShow } from '../../redux/dataSlices/analyseSlice';
import { getData } from '../../redux/dataSlices/qrorderingSlice';

export default function QrorderingButtons() {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const qrOrderingData = useSelector(getData)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const onDownloadClick = async (tableNumber) => {
    const dataURL = await generateQRCode(`${process.env.REACT_APP_QRORDERING_BASE_URL}?adminId=${admin_id}&locationId=${location_id}&userId=${token?.payload?.sub}&gc=${tableNumber}`)
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `QRCode-${tableNumber}.png`;
    link.click();
    link.remove();
  }

  const onDownloadRangeClick = (start, end) => {
    dispatch(setProgressModalShow(true))
    downloadQrCodes(qrOrderingData?.table_number_range_start, qrOrderingData?.table_number_range_end, admin_id, location_id, token, dispatch, navigate);
  }

  let buttons = {

    download: {
      tag: "Download",
      onClick: onDownloadClick,
    },
    downloadRange: {
      tag: "Download range",
      onClick: onDownloadRangeClick,
    }

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  // if (scopes.qrordering >= 1) {

  // }

  return buttons
}

