import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card";
import GuestCheckTable from "../../components/guestcheckTable";
import { getGuestCheckRefresh, getGuestChecks, setGuestChecks } from "../../redux/dataSlices/guestcheckSlice";
import GetGuestChecksStatus from "../../actablueAPI/guestchecks/GetGuestCheckByState";
import useHandleError from "../../customhooks/useHandleError";
import { getHashEmployeesAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";


const Guestchecks = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const guestchecks = useSelector(getGuestChecks)
  const refresh = useSelector(getGuestCheckRefresh)
  const hashEmployees = useSelector(getHashEmployeesAll)
  const dispatch = useDispatch();
  const navTitle = "Guestchecks"
  const HandleError = useHandleError();

  const card = {
    size: 12,
    title: navTitle,
    data: <GuestCheckTable guestchecks={guestchecks} hashEmployees={hashEmployees} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))

    if (Object.keys(hashEmployees).length === 0) {
      GetEmployees({token:token, admin_id:admin_id})
      .then(response => {
        dispatch(setEmployeesAll(response.data))
      })
      .catch(error => {
        HandleError({ error: error })
      })
    }

    GetGuestChecksStatus({ token: token, admin_id: admin_id, location_id: location_id, state: "OPEN" })
      .then(response => {
        dispatch(setGuestChecks(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [admin_id, location_id, refresh]);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default Guestchecks;
