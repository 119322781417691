import axios from "axios";

const DeleteProductSupplier = ({ token, id, admin_id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/supplier/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      });
  });
};

export default DeleteProductSupplier