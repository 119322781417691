import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

function TableButton(props) {
  const { t: translate } = useTranslation();
  if (props) {
    return (
      <Button
        size={props.size || "sm"}
        type={props.type || "button"}
        className={props.className}
        variant={props.variant}
        value={props.value}
        onClick={props.onClick}
        id={props.id}
        disabled={props.disabled}
        style={props.style}>
        {translate(props.tag)}
      </Button>
    );
  } else {
    return (
      <Button size="sm" type='button'>Button</Button>
    );
  }

}

export default TableButton;