import { Pie } from 'react-chartjs-2';

export const operatorsSalesReportGraph = (report) => {
  let graphCardData = <p>No data</p>
  if (Object.keys(report.operators).length > 0) {
  
    let graphLabels = []
    let dataSetData = []
    let backGroundColor = []
    let borderColor = []

    for (const [operatorId, operatorData] of Object.entries(report.operators).sort(([a, adata], [b, bdata]) => bdata.total - adata.total)) {
      let color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
      graphLabels.push(operatorId)
      dataSetData.push(operatorData.days.totals.total)

      backGroundColor.push(color)
      borderColor.push(color)
    }
    
    const graphDataset = [
      {
        label: 'Value',
        data: dataSetData,
        borderColor: borderColor,
        backgroundColor: backGroundColor,
      },
    ]

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
        }
      },
    };

    const data = {
      labels: graphLabels,
      datasets: graphDataset
    }

    graphCardData = <div className='pie-container'><Pie options={options} data={data} /></div> //<Pie options={options} data={dataQuantity} />

  }
  
  return graphCardData
};