import AnalyseProductDataTable from './anlayseProductDataTable';
import AnalyseOrderDataTable from './anlayseOrderDataTable';
import AnalyseTransactionDataTable from './anlayseTransactionDataTable';

export default function AnalyseDataTable(data) {
  switch (data.type) {
    case 'products':
      return AnalyseProductDataTable(data)
    case 'orders':
      return AnalyseOrderDataTable(data)
    case 'transaction_processed_by_accounting':
      return AnalyseTransactionDataTable(data)
    default:
      break;
  }

  
}
