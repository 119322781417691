import { scopes } from "../../scopes/scopes.mjs"

const SubscriptionFormFields = () => {

  let scopeFields = []

  const subscriptionFormFields =
    [
      // single option fields
      { type: 'hidden', name: 'id', id: 'id', readonly: true },
      { type: 'text', name: `Name`, id: 'name' },
      // {type:'text', name:'type', id:'type'},
      { type: 'number', name: `Duration`, id: 'duration' },
      { type: 'number', name: `Interval (Months)`, id: 'interval' },
      { type: 'number', name: `Prolongation (Months)`, id: 'prolongation' },
      { type: 'number', name: `Grace period (Months)`, id: 'grace_period' },
      { type: 'number', name: `Cancel period (Months)`, id: 'cancel_period' },
      { type: 'select', name: 'Product', id: 'product_id', formdatafield: 'product_id' },
      // single option select fields + self create
      { type: 'select+create', name: `Type`, id: 'type', formdatafield: 'type' },

      // { type: 'object', name: 'scopes', id: 'scopes', fields: scopeFields },

    ]

  for (const scope of Object.values(scopes)) {
    if (scope !== 'NumberOfDevices') {
      scopeFields.push({ type: 'select', name: scope, id: scope, placeholder: scope, formdatafield: 'scopes', prefix: 'scopes' })
    }
    if (scope === 'NumberOfDevices') {
      scopeFields.push({ type: 'number', name: scope, id: scope, placeholder: scope, formdatafield: 'scopes', prefix: 'scopes' })
    }
  }

  return { subscriptionFormFields: subscriptionFormFields, scopeFields: scopeFields }
}

export default SubscriptionFormFields