import { createSlice } from "@reduxjs/toolkit";

export const cashcountSlice = createSlice({
  name: "cashcount",
  initialState: {
    drawer_pagesize: 10,
    drawer_pagination: 0,
    count_pagesize: 10,
    count_pagination: 0,
    hash_drawers_all: {},
    hash_counts_all: {},
    allowed_currency: []
  },
  reducers: {
    setDrawerId: (state, action) => {
      state.drawer_id = action.payload;
    },
    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    setDrawers: (state, action) => {
      state.drawers = action.payload
    },
    setDrawersAll: (state, action) => {
      state.drawers_all = action.payload
      action?.payload?.items?.forEach(drawer => {
        state.hash_drawers_all[drawer.id] = drawer
      });
    },
    setDrawerModalShow: (state, action) => {
      state.drawer_modalshow = action.payload
    },
    setDrawerPagination: (state, action) => {
      state.drawer_pagination = (action.payload - 1)
    },
    setDrawerPageSize: (state, action) => {
      state.drawer_pagesize = action.payload
    },
    setAllowedCurrency: (state, action) => {
      state.allowed_currency = action.payload
    },
    setCountId: (state, action) => {
      state.count_id = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setCounts: (state, action) => {
      state.counts = action.payload
    },
    setCountsAll: (state, action) => {
      state.counts_all = action.payload
      action?.payload?.items?.forEach(count => {
        state.hash_counts_all[count.id] = count
      });
    },
    setCountModalShow: (state, action) => {
      state.count_modalshow = action.payload
    },
    setCountPagination: (state, action) => {
      state.count_pagination = (action.payload - 1)
    },
    setCountPageSize: (state, action) => {
      state.count_pagesize = action.payload
    },
    setEmployeePasswordModalShow: (state, action) => {
      state.employee_password_modalshow = action.payload
    }
  }
});

export const {
  setDrawerId, setDrawer, setDrawers, setDrawersAll, setDrawerModalShow, setDrawerPagination, setDrawerPageSize,
  setAllowedCurrency,
  setCountId, setCount, setCounts, setCountsAll, setCountModalShow, setCountPagination, setCountPageSize,
  setEmployeePasswordModalShow
} = cashcountSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getDrawerId = (state) => state.cashcount.drawer_id
export const getDrawer = (state) => state.cashcount.drawer
export const getDrawers = (state) => state.cashcount.drawers
export const getDrawersAll = (state) => state.cashcount.drawers_all
export const getHashDrawersAll = (state) => state.cashcount.hash_drawers_all
export const getDrawerModalShow = (state) => state.cashcount.drawer_modalshow
export const getDrawerPagination = (state) => state.cashcount.drawer_pagination
export const getDrawerPageSize = (state) => state.cashcount.drawer_pagesize

export const getAllowedCurrency = (state) => state.cashcount.allowed_currency

export const getCountId = (state) => state.cashcount.count_id
export const getCount = (state) => state.cashcount.count
export const getCounts = (state) => state.cashcount.counts
export const getCountsAll = (state) => state.cashcount.counts_all
export const getHashCountsAll = (state) => state.cashcount.hash_counts_all
export const getCountModalShow = (state) => state.cashcount.count_modalshow
export const getCountPagination = (state) => state.cashcount.count_pagination
export const getCountPageSize = (state) => state.cashcount.count_pagesize

export const getEmployeePasswordModalShow = (state) => state.cashcount.employee_password_modalshow

export default cashcountSlice.reducer;