export const productFilterChoices = [
    { 'id': 'discount', 'name': 'discount' },
    { 'id': 'emballage', 'name': 'emballage' },
    { 'id': 'paidin', 'name': 'paidin' },
    { 'id': 'paidout', 'name': 'paidout' },
    { 'id': 'article', 'name': 'article' },
    { 'id': 'service', 'name': 'service' },
    { 'id': 'deposit', 'name': 'deposit' },
    { 'id': 'noturnover', 'name': 'noturnover' },
    { 'id': 'addon', 'name': 'addon' },
    { 'id': 'addoncombination', 'name': 'addoncombination' },
    { 'id': 'subscription', 'name': 'subscription' }
]

export const masterProductChoices = [
    { 'name': 'ACTABLUE', 'id': 'ACTABLUE' },
    { 'name': 'CCV', 'id': 'CCV' },
    { 'name': 'VECTRON', 'id': 'VECTRON' },
    { 'name': 'LIGHTSPEED', 'id': 'LIGHTSPEED' },
]

export const visibleChoices = [
    { 'name': 'true', 'id': Boolean(true)},
    { 'name': 'false', 'id': Boolean(false)}
]