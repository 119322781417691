import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getJournalCategoriesAll } from '../../redux/dataSlices/categorySlice'
import { getJournalCategoryEventData, setJournalCategoryEventData } from "../../redux/dataSlices/analyseSlice";

export default function AnalyseOnChangeActions() {
  const journalCategories = useSelector(getJournalCategoriesAll)
  const journalCategory = useSelector(getJournalCategoryEventData)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  
  const onJournalCategoriesChange = (event) => {
    dispatch(setJournalCategoryEventData(event))
  };

  let onChangeActions = {
    journalcategories: {
      name: 'journalcategories',
      options: journalCategories,
      selected: journalCategory,
      onChange: onJournalCategoriesChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.

  return onChangeActions
}

