export const reservationoptionFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
  { type: 'text', name: 'Name', id: 'name' },
  { type: 'time', name: 'Start Time', id: 'starting_time' },
  { type: 'time', name: 'End Time', id: 'ending_time' },
  { type: 'text', name: 'Days to cancel', id: 'days_before_final_cancel' },

  // single option select fields  
  { type: 'select', name: 'Product', id: 'product_id', formdatafield: 'product_id' },
  { type: 'select', name: 'Prepaid ID', id: 'prepaid_id', formdatafield: 'prepaid_id' },

  // multi option select fields
  // {type:'multiselect', name:'option_packages', id:'option_packages', formdatafield:'option_packages'},
  // {type:'multiselect', name:'reservation_objects', id:'reservation_objects', formdatafield:'reservation_objects'},
]