import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

import PaginationAndSizeComponent from './paginationAndSize';
import KmScreensModal from './kmScreensModal';
import ScreenButtons from '../portal/kitchenmanager/screenButtons';

export default function KmScreensTable(props) {
  const screens = props?.screens?.items
  const pages = props?.screens?.pages
  const page = props?.screens?.page

  const buttons = ScreenButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('delete') ? Object.assign({}, buttons.delete) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (screens) {
    for (const screen of Object.values(screens)) {
      rows.push(
        <tr className='product-row' key={screen.id}>
          <td>{screen?.name ?? 'Unknown'}</td>
          <td>{screen?.type}</td>
          <td className='text-center'><div className='table-icons'>{screen?.active ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</div></td>
          <td>
            <p>
              {translate('Created')}: {new Date(screen?.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(screen?.modification).toLocaleString()}
            </p>
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(screen) }} id={screen?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(screen) }} id={screen?.id}>{translate(edit.tag)}</Button>}
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(screen) }} id={screen.id}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Type')}</th>
            <th className='text-center'>{translate('Active')}</th>
            <th>
              {translate('Date')}
            </th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <KmScreensModal modal={modalview.modal} />
    </>
  );
}