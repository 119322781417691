import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getServiceProductsAll, getDepositProductsAll, setServiceProductsAll, setDepositProductsAll } from "../../redux/dataSlices/productSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import ReservationOptionForm from "../../components/reservationoptionForm";
import { reservationoptionFormFields } from "./reservationoptionFormFields.mjs";
import GetAllProducts from "../../helpers/getAllProducts";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostReservationOption from "../../actablueAPI/reservations/PostReservationOption";
import useHandleError from "../../customhooks/useHandleError";

const ReservationOptionEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const service_products = useSelector(getServiceProductsAll)
  const deposit_products = useSelector(getDepositProductsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reservationOption, setReservationOption] = useState({ administration_id: admin_id })
  const HandleError = useHandleError();

  const onProductIdChange = (event) => {
    let newReservationOption = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationOption, selectfield: 'product_id' })
    setReservationOption(newReservationOption)
  }

  const onPrepaidIdChange = (event) => {
    let newReservationOption = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationOption, selectfield: 'prepaid_id' })
    setReservationOption(newReservationOption)
  }

  const onChange = (event) => {
    let newReservationOption = HandleOnChange({ event: event, object: reservationOption })
    setReservationOption(newReservationOption)
  };

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostReservationOption({token:token, data:reservationOption})
    .then(response => {
      navigate("/reservationoptions");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: reservationoptionFormFields,
    field: {
      onChange: onChange
    },
    data: reservationOption,
    product_id: {
      label: 'products',
      options: service_products,
      selected: [],
      onChange: onProductIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    prepaid_id: {
      label: 'products',
      options: deposit_products,
      selected: [],
      onChange: onPrepaidIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Reservation option add",
    data: <ReservationOptionForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Reservation option add"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))

    axios.all([
      GetAllProducts({ type: 'service', admin_id: admin_id, location_id: location_id, token: token }),
      GetAllProducts({ type: 'deposit', admin_id: admin_id, location_id: location_id, token: token })
    ])
    .then((serviceProductsResponse, depositProductsResponse) => {
      dispatch(setServiceProductsAll(serviceProductsResponse))
      dispatch(setDepositProductsAll(depositProductsResponse))
      dispatch(setLoading(false))
    })
    .catch((error) => {
      HandleError({ error: error })
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )
};
export default ReservationOptionEdit;
