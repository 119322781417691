import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const ProgramFormFields = () => {
  const role = useSelector(getRole)

  const programFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name', required: true },
    { type: 'text', name: 'Description', id: 'description', required: false },
    { type: 'number', name: 'Minimum points required', id: 'minimal_points_required', required: true, step: 'none' },
    { type: 'number', name: 'Points per product', id: 'points_per_product', required: true, step: '.01' },
    { type: 'number', name: 'Points per value', id: 'points_per_value', required: true, step: '.01' },
    { type: 'datetime-local', name: 'Start', id: 'start', required: true },
    { type: 'datetime-local', name: 'Stop', id: 'stop', required: true },
  ]

  if (role === 'super' || role === 'admin') {
    programFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    programFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return programFormFields
}

export const CardFormFields = () => {
  const role = useSelector(getRole)

  const cardFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', required: true },
    { type: 'select', name: 'Card type', id: 'card_type', formdatafield: 'card_type', required: false },
    { type: 'text', name: 'Barcode', id: 'barcode', required: false },
    { type: 'checkbox', name: 'Add points', id: 'add_points', required: false },
    { type: 'number', name: 'Points', id: 'points', required: false },
  ]

  if (role === 'super' || role === 'admin') {
    cardFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id', required: true },
    )
    cardFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return cardFormFields
}