export default function HandleOnChangeSubscriptionSettings(props) {
  const event = props.event
  const object = props.object || {}
  // const admin_id = props.admin_id

  let newObject = JSON.parse(JSON.stringify(object))

  for (let setting of Object.values(newObject.settings)) {
    if (setting.id === event.target.attributes.prefix.value) {
        switch (event.target.type) {
          case 'checkbox':
            setting[event.target.id] = event.target.checked ? event.target.checked : false
            break;
          case 'number':
            setting[event.target.id] = (event.target.value === null || event.target.value === "") ? null : Number(event.target.value)
            break;
          default:
            setting[event.target.id] = event.target.value
            break;
        }
      }
    }
  
  return (newObject)
}


