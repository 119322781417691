import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getRole } from "../../redux/dataSlices/tokenSlice";
import { useSelector } from "react-redux";

const KitchenManagerScreensValidator = (object, field) => {
  if (object?.kitchenmanager?.screens) return true
  if (!object?.kitchenmanager?.category && !object?.kitchenmanager?.screens && !object.kitchenmanager?.printer) return true
  if (object?.kitchenmanager?.category && !object?.kitchenmanager?.screens && object.kitchenmanager?.printer) return true
  if (object?.kitchenmanager?.category && object?.kitchenmanager?.screens) return true
  if (object?.kitchenmanager?.screens?.length === 0 || object?.kitchenmanager?.screens?.length > 0) return true
  return false
}

const KitchenManagerCategoryValidator = (object, field) => {
  if (object?.kitchenmanager?.category) return true
  if (!object?.kitchenmanager?.category && !object?.kitchenmanager?.printer && (!object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length === 0)) return true
  if (object?.kitchenmanager?.category && (object?.kitchenmanager?.printer || (object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length > 0))) return true
  return false
}

const KitchenManagerPrinterValidator = (object, field) => {
  if (object?.kitchenmanager?.printer) return true
  if (!object?.kitchenmanager?.category && !object?.kitchenmanager?.printer && (!object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length === 0)) return true
  if (object?.kitchenmanager?.category && !object?.kitchenmanager?.printer && (object.kitchenmanager?.screens || object.kitchenmanager?.screens?.length > 0)) return true
  if (object?.kitchenmanager?.category && object?.kitchenmanager?.printer) return true
  return false
}

export const CategoryFormFields = () => {
  const scopes = useSelector(getScopes);
  const role = useSelector(getRole);
  

  const sorted_product_category = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'product_category_id', id: 'product_category_id', readonly: true },
    { type: 'hidden', name: 'name', id: 'name', readonly: true },
    { type: 'select', name: 'Printer', id: 'printer', prefix: 'kitchenmanager', formdatafield: 'printer', required: true, validator: KitchenManagerPrinterValidator },
    { type: 'select', name: 'Category', id: 'category', prefix: 'kitchenmanager', formdatafield: 'category', required: true, validator: KitchenManagerCategoryValidator },
    { type: 'multiselect', name: 'Screens', id: 'screens', prefix: 'kitchenmanager', formdatafield: 'screens', required: true, validator: KitchenManagerScreensValidator }
  ]

  const categoryFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'snelstart_id', id: 'snelstart_id', readonly: true },
    { type: 'hidden', name: 'external_id', id: 'external_id', readonly: true },
    { type: 'hidden', name: 'ccv_id', id: 'ccv_id', readonly: true },
    { type: 'hidden', name: 'lightspeed_id', id: 'lightspeed_id', readonly: true },

    { type: 'text', name: `Name`, id: 'title', required: true },
    { type: 'text', name: `Description`, id: 'description', required: false },
    { type: 'hidden', name: `Type`, id: 'type', readonly: true },
    // {type:'text', name:'ledger', id:'ledger'}, only with journalpages
    { type: 'checkbox', name: `Visible`, id: 'is_visible', required: false },
    { type: 'number', name: `Sort index`, id: 'sort_index', required: false },

    // single option select fields  
    // { type: 'select', name: 'Parent', id: 'parent', formdatafield: 'parent' },

    // multi option select fields

    // multi option select fields + self create

    // single option object fields

    // multiple option fields

    // not needed, splitted in journal and productpage categories

    // not adjustable fields
    // {type:'datetime-local', name:'creation', id:'creation' , readonly:true},
    // {type:'datetime-local', name:'modification', id:'modification' , readonly:true},    
  ]
  
  if (role === 'super' || role === 'admin') {
    categoryFormFields.push({ type: 'select', name: 'Parent', id: 'parent', formdatafield: 'parent' })
  }

  if (scopes.sortcategories) {
    categoryFormFields.push({ type: 'object', name: `Kitchen Manager`, id: 'kitchenmanager', fields: sorted_product_category })
  }

  return categoryFormFields
}

export const CategoryMultiEditFormFields = () => {
  const fields = [
    { type: 'select', name: 'Visible', id: 'is_visible', formdatafield: 'is_visible', md: 4 },
  ]

  return fields
}