export default function InvoiceHighLightCalculation (invoices) {
  let total_invoices = 0
  let invoicecount = 0 
  let average = 0
  let waiting = 0
  for (let invoice in invoices) {
    total_invoices = total_invoices + Number(invoices[invoice].payment_amount_inclusive_vat)
    invoicecount = invoicecount + 1
    // if (invoices[invoice].status == 'OPEN') {
    //   waiting = waiting + 1
    // }
  };
  if (invoicecount > 0) {
    average = total_invoices/invoicecount
  }
  average = Number(average).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  return [{Total:invoicecount}, {Average:average}, {Waiting:waiting}]
}