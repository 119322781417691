import * as XLSX from 'xlsx/xlsx.mjs';
import { setTaskData } from "../redux/dataSlices/exportSlice";
import { setProgressModalShow } from "../redux/dataSlices/analyseSlice";

export const TransactionProcessedByAccountingExport = ({exportData, fileName, dispatch}) => {
  dispatch(setProgressModalShow(true))
  const { exportLines, dummy } = _createExportLines(exportData, dispatch)

  // first object
  let firstObject = exportLines[0]
  let newFirstObject = {}
  for (const key of Object.keys(dummy)) {
    newFirstObject[key] = firstObject[key]
  }

  exportLines[0] = Object.assign({}, newFirstObject)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(exportLines), 'sheet1')
  XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });
  
  dispatch(setProgressModalShow(false))

};

function _createExportLines(exportData, dispatch) {
  const exportLength = exportData.length
  let current = 1
  let dummy = {}
  let exportLines = []

  for (const transaction of exportData) {
    let task_data = {
      current: current,
      total: exportLength,
      progress: current === 0 ? 0 : current / exportLength
    }
    dispatch(setTaskData(task_data))

    let newLine = {}
    newLine.administration_id = transaction.administration_id
    newLine.location_id = transaction.location_id
    newLine.transaction_id = transaction.transaction_id
    newLine.creation = transaction.creation ? new Date(transaction.creation).toLocaleString() : 'no creation date'
    newLine.is_processed_by_accounting = transaction.is_processed_by_accounting ? JSON.stringify(transaction.is_processed_by_accounting) : 'false'
    newLine.available_in_snelstart_based_on_snelstart = transaction.available_in_snelstart_based_on_snelstart ? JSON.stringify(transaction.available_in_snelstart_based_on_snelstart) : 'false'
    newLine.snelstart_response = transaction.snelstart_response ? JSON.stringify(transaction.snelstart_response) : ''
    newLine.available_in_snelstart_based_on_accounting = transaction.available_in_snelstart_based_on_accounting ? JSON.stringify(transaction.available_in_snelstart_based_on_accounting) : 'false'
    newLine.accounting_response = transaction.accounting_response ? JSON.stringify(transaction.accounting_response) : ''

    // create longest dummy
    if (Object.keys(newLine).length > Object.keys(dummy).length) {
      dummy = Object.assign({}, newLine)
    }
    exportLines.push(newLine)

    current = current + 1
  }

  return ({ exportLines: exportLines, dummy: dummy })
}
