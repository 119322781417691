const product_specifications = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true, prefix: 'product_specifications' },
  { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true, prefix: 'product_specifications' },
  { type: 'text', name: 'brand', id: 'brand', prefix: 'product_specifications' },
  { type: 'text', name: 'model', id: 'model', prefix: 'product_specifications' },
  { type: 'text', name: 'packaging', id: 'packaging', prefix: 'product_specifications' },
  { type: 'text', name: 'contents', id: 'contents', prefix: 'product_specifications' },
  { type: 'text', name: 'weigth', id: 'weigth', prefix: 'product_specifications' },
  { type: 'text', name: 'heigth', id: 'heigth', prefix: 'product_specifications' },
  { type: 'text', name: 'length', id: 'length', prefix: 'product_specifications' },
  { type: 'text', name: 'width', id: 'width', prefix: 'product_specifications' },

  // not adjustable fields
  // {type:'datetime-local', name:'creation', id:'creation', readonly:true},
  // {type:'datetime-local', name:'modification', id:'modification', readonly:true},    
]

export const productSupplierFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true, prefix: 'product_supplier' },
  { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true, prefix: 'product_supplier' },
  { type: 'text', name: 'supplier_artikel_code', id: 'supplier_artikel_code', prefix: 'product_supplier' },
  { type: 'text', name: 'supplier_price', id: 'supplier_price', prefix: 'product_supplier' },

  // single option select fields  
  { type: 'select', name: 'relation_id', id: 'relation_id', readonly: true, prefix: 'product_supplier', formdatafield: 'relation_id' },

  // multi option select fields + self create
  { type: 'multi+create', name: 'eans', id: 'eans', formdatafield: 'eans' },

  // single option object fields
  { type: 'object', name: 'product_specifications', id: 'product_specifications', fields: product_specifications },

  // not adjustable fields
  // {type:'datetime-local', name:'creation', id:'creation' , readonly:true},
  // {type:'datetime-local', name:'modification', id:'modification' , readonly:true},    
]