import { setError } from "../redux/dataSlices/errorSlice";

import { setProgressModalShow, setTaskData } from "../redux/dataSlices/analyseSlice";
import PostImageBase64 from "../actablueAPI/shelflabels/PostImageBase64";
import { clearBuffer, drawBitmap, getLabelData, printBuffer, setLabelId, setLength, setWidth } from "../static/includes/js/bxllabel";
import { requestPrint } from '../static/includes/js/bxlcommon';

let current = 0

function viewResult(result) {
  if (result.split(":")[1] !== 'success') throw new Error(`Printer status is ${result.split(":")[1]}`);
  return
}

async function printShelflabelsBixolon(admin_id, location_id, selectedProducts, token, dispatch, navigate, settings, template, printer) {

  try {
    let task_data = {
      current: current,
      total: Object.keys(selectedProducts).length,
      progress: current === 0 ? 0 : current / Object.keys(selectedProducts).length
    }
    let pixelRatio = printer.dpi / 300;
    setLabelId(current);
    clearBuffer();
    dispatch(setTaskData(task_data))
    dispatch(setProgressModalShow(true))
    for (const id of Object.keys(selectedProducts)) {
      try {
        
        const productData = {
          'product_id': id,
          'admin_id': admin_id,
          'location_id': location_id,
          'name': selectedProducts[id]?.name ?? 'Unknown product',
          'contents': selectedProducts[id]?.description ?? '',
          'price': `${(Number(selectedProducts[id]?.price_incl_vat || 0) - Number(selectedProducts[id]?.discount || 0)).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'sku_code': selectedProducts[id]?.sku_code ?? '0',
          'ean_code': selectedProducts[id]?.eans?.[0]?.ean_code ?? '0',
          'url': '',
          'discount': `${Number(selectedProducts[id]?.discount).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'emballage': `${Number(selectedProducts[id]?.emballage).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'price_incl_vat': `${Number(selectedProducts[id]?.price_incl_vat).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'price_excl_vat': `${Number(selectedProducts[id]?.price_excl_vat).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'price_incl_discount': `${(Number(selectedProducts[id]?.price_incl_vat || 0) - Number(selectedProducts[id]?.discount || 0)).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'brand': selectedProducts[id]?.product_specifications?.brand ?? '',
          'weight': selectedProducts[id]?.product_specifications?.weight ?? '',
          'height': selectedProducts[id]?.product_specifications?.height ?? '',
          'length': selectedProducts[id]?.product_specifications?.length ?? '',
          'width': selectedProducts[id]?.product_specifications?.width ?? ''
        }

        await PostImageBase64({ token: token, template_id: template?.id, code_type: settings.code_to_print, data: productData })
          // eslint-disable-next-line no-loop-func
          .then(response => {
            const imgData = `data:image/png;base64,${response?.data}`
            let bitmapWidth = Math.round(template.width_px * pixelRatio)
            setWidth(bitmapWidth)
            if (printer?.paper_type === 'C') setLength(Math.round(template.height_px * pixelRatio), printer.gap_length, printer.paper_type, printer.gap_offset)
            drawBitmap(imgData, 0, 0, bitmapWidth, 0);

            printBuffer();

            let strSubmit = getLabelData()

            requestPrint(printer.name, strSubmit, viewResult)
          })

        current++

        let task_data = {
          current: current,
          total: Object.keys(selectedProducts).length,
          progress: current === 0 ? 0 : current / Object.keys(selectedProducts).length
        }
        dispatch(setTaskData(task_data))
      } catch (error) {
        if (!error.code === "ERR_BAD_REQUEST") {
          dispatch(setError(JSON.stringify(`An unknown error occurred.`)));
          return false;
        }
      }
    }
    
    dispatch(setProgressModalShow(false))
  } catch (error) {
    console.error(error);
  }
}

export default printShelflabelsBixolon