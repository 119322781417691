export const templateTypeChoices = [
    { 'id': 'qr', 'name': 'QR-Code' },
    { 'id': 'bar', 'name': 'Barcode' },
]

export const templateESLModelChoices = [
    { 'id': 'other', 'name': 'Other' },
    { 'id': 'ee153r', 'name': 'EE-153R' },
    { 'id': 'ee213r', 'name': 'EE-213R' },
    { 'id': 'ee293r', 'name': 'EE-293R' },
    { 'id': 'ee420r', 'name': 'EE-420R' },
    { 'id': 'ee750r', 'name': 'EE-750R' },
]

export const templateAlignChoices = [
    { 'id': 'left', 'name': 'Left' },
    { 'id': 'center', 'name': 'Center' },
    { 'id': 'right', 'name': 'Right' },
]

export const codeToPrintChoices = [
    { 'name': 'ean', 'id': 'ean' },
    { 'name': 'sku', 'id': 'sku' }
]

export const supportedElementTypes = [
    { 'name': 'Rectangle', 'id': 'rectangle' },
    { 'name': 'Circle', 'id': 'circle' },
    { 'name': 'Triangle', 'id': 'triangle' },
    { 'name': 'Cross', 'id': 'cross' },
    { 'name': 'Text', 'id': 'text' },
    { 'name': 'QR-Code', 'id': 'qrcode' },
    { 'name': 'Barcode', 'id': 'barcode' }
]

export const supportedElementTextTypes = [
    { 'name': 'Discount Amount', 'id': 'discount' },
    // { 'name': 'SKU Code', 'id': 'sku_code' },
    { 'name': 'Emballage', 'id': 'emballage' },
    { 'name': 'Price Excl. Vat', 'id': 'price_excl_vat' },
    { 'name': 'Price Incl. Vat', 'id': 'price_incl_vat' },
    { 'name': 'Price Incl. Discount', 'id': 'price_incl_discount'},
    { 'name': 'Brand', 'id': 'brand' },
    { 'name': 'Weight', 'id': 'weight' },
    { 'name': 'Height', 'id': 'height' },
    { 'name': 'Length', 'id': 'length' },
    { 'name': 'Width', 'id': 'width' },
    { 'name': 'Custom', 'id': 'custom' }
]

export const supportFonts = [
    { 'name': 'Arial Black', 'id': 'ArialBlack' },
    { 'name': 'Arial', 'id': 'Arial' },
    { 'name': 'Times New Roman', 'id': 'TimesNewRoman' },
    { 'name': 'Verdana', 'id': 'Verdana' }
]

export const settingsAcceptedCurrency = [
    { 'name': 'Euro', 'id': '\u20ac' },
    { 'name': 'Dollar', 'id': '\u0024' },
    { 'name': 'Pound', 'id': '\u00A3' },
    { 'name': 'None', 'id': '' },
]