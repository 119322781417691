import axios from "axios";

const PostUserForgot = (user) => {
  let origin = 'actablue'
  if (window.location.href.includes('humbee')) {origin = 'humbee'}

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/registrations/registration/user/${origin}/forgot`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        // 'username': user,
        'email': user
      }
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    });
  });
};

export default PostUserForgot