import LoadingSpinner from "./spinner";
import { useSelector } from "react-redux";
import { getLoading } from "../redux/dataSlices/loadingSlice";
import { Col, Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function BasicCard(props) {
  const loading = useSelector(getLoading);
  const { t: translate } = useTranslation();

  let title = props.title
  if (typeof props.title === 'string') {
    title = translate(props.title)
  }
  if (typeof props.title === 'object') {
    title = `${translate(props.title.text)} (${translate(props.title.option)})`
  }

  let subtitle = props.subtitle

  return (
    <Col md={props.size} className={props.className}>
      <Card className={props.overflow && 'card-overflow'}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted" as="div">
            {subtitle}
          </Card.Subtitle>
          <Card.Text as="div">
            {loading ? <LoadingSpinner {...props?.loadingData} /> : props.data}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default BasicCard;