export const ccvWebhookTypeChoices = [
    { id: 'ATTRIBUTECOMBINATIONS', name: 'ATTRIBUTECOMBINATIONS' },
    { id: 'ATTRIBUTES', name: 'ATTRIBUTES' },
    { id: 'ATTRIBUTEVALUES', name: 'ATTRIBUTEVALUES' },
    { id: 'CATEGORIES', name: 'CATEGORIES' },
    { id: 'DISCOUNTCOUPONS', name: 'DISCOUNTCOUPONS' },
    { id: 'INVOICES', name: 'INVOICES' },
    { id: 'MAILINGADDRESSES', name: 'MAILINGADDRESSES' },
    { id: 'QUOTATIONS', name: 'QUOTATIONS' },
    { id: 'REDIRECTS', name: 'REDIRECTS' },
    { id: 'RETURNS', name: 'RETURNS' },
    { id: 'DOMAINS', name: 'DOMAINS' },
    { id: 'PRODUCTS', name: 'PRODUCTS' },
    { id: 'ORDERS', name: 'ORDERS' },
    { id: 'PRODUCTATTRIBUTEVALUES', name: 'PRODUCTATTRIBUTEVALUES' },
    ]

export const ccvWebhookEventChoices = [
    { id: 'CREATED', name: 'CREATED' },
    { id: 'UPDATED', name: 'UPDATED' },
    { id: 'UPDATED_STATUS', name: 'UPDATED_STATUS' },
    { id: 'UPDATED_ISPAID', name: 'UPDATED_ISPAID' },
    { id: 'DELETED', name: 'DELETED' },
    ]