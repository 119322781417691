import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getReservationObjects, setReservationObjects, getReservationPagesize, getReservationPagination } from "../../redux/dataSlices/reservationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import ReservationObjectTable from "../../components/reservationobjectTable";
import GetReservationObjects from "../../actablueAPI/reservations/GetReservationObjects";
import useHandleError from "../../customhooks/useHandleError";

const ReservationObjects = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const reservationobjects = useSelector(getReservationObjects);
  const pagination = useSelector(getReservationPagination);
  const pageSize = useSelector(getReservationPagesize)
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const navTitle = "Reservation objects"

  const reservationObjectCard = {
    size: 12,
    title: navTitle,
    data: <ReservationObjectTable {...reservationobjects} />
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))

      GetReservationObjects({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setReservationObjects(response.data))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pageSize]);


  return (
    <>
      <BasicCard {...reservationObjectCard} />
    </>
  )
};
export default ReservationObjects;
