const RackFormFields = () => {
  let rackFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },

    // single option select fields  
    { type: 'select', name: 'Warehouse', id: 'warehouse_id', formdatafield: 'warehouse_id' },

    // single option fields
    { type: 'text', name: 'Name', id: 'name' },
    { type: 'text', name: 'Description', id: 'description' },
  ]

  return rackFormFields
}

export default RackFormFields