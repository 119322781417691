export const AnalyseFormFields = (analyseForm) => {
  const analyseFormFields = [
    { type: 'select', name: `Analyse type`, id: 'analysetype', formdatafield: 'analysetype' },
    { type: 'hidden', name: `Start date`, id: 'start_date' },
    { type: 'hidden', name: `End date`, id: 'end_date' },
    { type: 'hidden', name: `Check also processed`, id: 'also_proccesed' },
    { type: 'hidden', name: `Check also snelstart`, id: 'also_snelstart' }
  ]

  if (analyseForm.analysetype === 'transaction_processed_by_accounting') {
    analyseFormFields.splice(-4, 1,{ type: 'date', name: `Start date`, id: 'start_date' })
    analyseFormFields.splice(-3, 1,{ type: 'date', name: `End date`, id: 'end_date' })
    analyseFormFields.splice(-2, 1,{ type: 'checkbox', name: `Check also processed`, id: 'also_processed' })
    analyseFormFields.splice(-1, 1,{ type: 'checkbox', name: `Check also snelstart`, id: 'also_snelstart' })
  }

  return analyseFormFields
}

