import { createSlice } from "@reduxjs/toolkit";

export const formTabSlice = createSlice({
  name: "formtab",
  initialState: {
    active_tab: 'main',
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.active_tab = action.payload
    },
  }
});

export const {
  setActiveTab
} = formTabSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getActiveTab = (state) => state.formtab.active_tab

export default formTabSlice.reducer;