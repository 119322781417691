export function UuidToColor(uuid, saturation = 25, lightness = 85, hex = false) {
    // Remove dashes from the UUID
    const cleanedUuid = uuid.replace(/-/g, '');

    // Use the first 8 characters for the hue
    const hashValue = parseInt(cleanedUuid.slice(0, 8), 16);
    const hue = hashValue % 360;

    // Use the next parts of the UUID for saturation and lightness
    const satValue = parseInt(cleanedUuid.slice(8, 12), 16);
    const adjustedSaturation = saturation + (satValue % 70);

    const lightValue = parseInt(cleanedUuid.slice(12, 16), 16);
    const adjustedLightness = lightness + (lightValue % 10);

    if (!hex) {
        return `hsl(${hue},${adjustedSaturation}%,${adjustedLightness}%)`;
    }

    return HslToHex(hue, adjustedSaturation, adjustedLightness);
}

export function RandomHslColor(saturation = 25, lightness = 85, hex = false) {
    if (!hex) {
        return "hsl(" + 360 * Math.random() + ',' +
            (saturation + 70 * Math.random()) + '%,' +
            (lightness + 10 * Math.random()) + '%)'
    }
    return HslToHex(360 * Math.random(), (saturation + 70 * Math.random()), (lightness + 10 * Math.random()))
}

export function HslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * Math.max(0, Math.min(1, color))).toString(16).padStart(2, '0');   // clamp between 0 and 1, then convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}