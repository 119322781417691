import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';
import CreateFieldNoTranslation from '../helpers/createFieldNoTransalation';

function SubscriptionForm(props) {
  // console.log('prps', props)
  const { t: translate } = useTranslation();

  if (props.type.options) {
    let newOptions = []
    for (const option of Object.values(props.type.options)) {
      let newOption = Object.assign({}, option)
      newOption.name = option.type
      newOptions.push(newOption)
    }
    props.type.options = newOptions
  }

  if (props.data.hasOwnProperty('type')) {
    props.type.selected = { 'value': props.data.type.id, 'label': props.data.type.type }
  }

  if (props.data.hasOwnProperty('product_id')) {
    props.product_id.selected = { value: props.data.product_id, label: props.product_id.hash[props.data.product_id] ? props.product_id.hash[props.data.product_id].name : 'unknown product id' }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.subscriptionFormFields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  // scopes
  formTabs.push(
    <Nav.Item key='scopes'>
      <Nav.Link eventKey='scopes'>
        {translate('Scopes')}
      </Nav.Link>
    </Nav.Item>
  );
  let scopeFields = []
  props.fields.scopeFields.forEach(field => {
    if (props.data.scopes && props.data.scopes[field.name]) {
      props.scopes.selected = { value: props.data.scopes[field.name], label: props.data.scopes[field.name] }
    }
    if (props.data.scopes && !props.data.scopes[field.name]) {
      props.scopes.selected = undefined
    }
    scopeFields.push(CreateFieldNoTranslation(field, props, 'scopes'))
  });
  formTabContent.push(
    <Tab.Pane eventKey='scopes' key='scopes'>
      <div className='tab-container'>
        {scopeFields}
      </div>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default SubscriptionForm;