import React from "react";
import { Page, Document, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../pdfstylesheet/stylesEftJournalReceiptPDF"

export const eftReceiptLines = (eftJournalReceipt) => {
  let eftJournalReceiptLines = []
  if (eftJournalReceipt) {
    if (eftJournalReceipt.lines?.length === 3) {
      mspReceipt()
    }
    if (eftJournalReceipt.lines?.length > 3) {
      ccvReceipt()
    }
  }
 
  function ccvReceipt() {
    eftJournalReceiptLines.push(<Text style={styles.eftReceiptTitle}>'CCV receipt:'</Text>)
    for (const line of eftJournalReceipt.lines) {
      // eftJournalReceiptLines.push(line[1])
      eftJournalReceiptLines.push(<Text style={styles.eftReceiptLine}>{line[1]}</Text>)
    }
  }

  function mspReceipt() {
    eftJournalReceiptLines.push(<Text style={styles.eftReceiptTitle}>'MSP receipt:'</Text>)
    for (const lineArray of eftJournalReceipt.lines) {
      for (const line of lineArray) {
        // eftJournalReceiptLines.push(line)
        eftJournalReceiptLines.push(<Text style={styles.eftReceiptLine}>{line}</Text>)
      }
    }
  }

  return eftJournalReceiptLines
}

const EftJournalReceipt = ({ eftJournalReceipt }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {eftReceiptLines(eftJournalReceipt).map((receiptline) => (
            // <Text style={styles.eftReceiptLine}>{receiptline}</Text>
            receiptline
        ))}
      </Page>
    </Document>
  );
}

export default EftJournalReceipt;