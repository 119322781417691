import { createSlice } from "@reduxjs/toolkit";

export const printerSlice = createSlice({
  name: "printer",
  initialState: {
    hash_printers_all: {},
  },
  reducers: {
   setPrinterId: (state, action) => {
      state.printer_id = action.payload;
    },
    setPrinter: (state, action) => {
      state.printer = action.payload;
    },
    setPrinters: (state, action) => {
      if (action.payload.hasOwnProperty('content')) {state.printers = action.payload}
      if (!action.payload.hasOwnProperty('content')) {state.printers = {'content':action.payload}}
    },
    setPrintersAll: (state, action) => {
      state.printers_all = action.payload
      action?.payload?.forEach(printer => {
        state.hash_printers_all[printer?.id] = printer
      });
    },
    setPrinterModalShow: (state, action) => {
      state.printermodalshow = action.payload
    },
  }
});

export const {
  setPrinterId, setPrinter, setPrinters, setPrinterModalShow, setPrintersAll
} = printerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getPrinterId = (state) => state.printer.printer_id
export const getPrinter = (state) => state.printer.printer
export const getPrinters = (state) => state.printer.printers
export const getPrintersAll = (state) => state.printer.printers_all
export const getHashPrintersAll = (state) => state.printer.hash_printers_all
export const getPrinterModalShow = (state) => state.printer.printermodalshow

export default printerSlice.reducer;