import axios from "axios";

const GetProduct = ({token, admin_id, location_id, id, ccv_id, external_id, lightspeed_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/product`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json',
                'administration': admin_id
      },
      params: {
        location_id: location_id,
        id: id,
        ccv_id: ccv_id,
        external_id: external_id,
        lightspeed_id: lightspeed_id
      }
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log("error:", error)
    });
  });
};

export default GetProduct