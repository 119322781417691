export default function HandleOnChangeMultiSelectFieldReservationObject(props) {
  const event = props.event
  const reservation = props.object || {}
  const reservation_objects = props.hash

  let newReservation = JSON.parse(JSON.stringify(reservation))
  // let newReservation = Object.assign({}, reservation)
  let excistingObjects = []
  let newObjects = []
  
  // clear newreservation reservation_objects
  newReservation.reservation_objects = []

  // create array with new object id's
  event.forEach(target => {
    newObjects.push(target.value)
  });

  // check for objects that stay or must be deleted
  if (reservation.hasOwnProperty('reservation_objects')) {
    // eslint-disable-next-line
    reservation.reservation_objects.map((object) => {
      if (newObjects.includes(object.reservation_object_id)) {
        newReservation.reservation_objects.push(object)    
      }
    })
  }

  // create array with excisting object id's  
  if (reservation.hasOwnProperty('reservation_objects')) {
    // eslint-disable-next-line
    reservation.reservation_objects.map((object) => {
      excistingObjects.push(object.reservation_object_id)
    })
  }

  // check for new objects, add them to reservation.reservation_objects
  event.forEach(target => {
    if (!excistingObjects.includes(target.value)) {
      reservation_objects.forEach(reservation_object => {
        if (reservation_object.id === target.value) {
          let newReservationObject = Object.assign({}, reservation_object)
          delete newReservationObject.id
          newReservationObject.reservation_object_id = reservation_object.id
          delete newReservationObject.reservation_options
          if (reservation_object.hasOwnProperty('reservation_options')) {
            newReservationObject.reservation_options = []
            reservation_object.reservation_options.forEach(reservation_option => {
              let newReservationOption = Object.assign({}, reservation_option)
              delete newReservationOption.reservation_objects
              newReservationObject.reservation_options.push(newReservationOption)
            });
          }
          newReservation.reservation_objects.push(newReservationObject)    
        }
      });
      
    }
  });

  return (newReservation)
}