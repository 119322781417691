import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setUser, getUserModalShow, setUserModalShow } from "../../redux/dataSlices/userSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getRole } from "../../redux/dataSlices/tokenSlice"

export default function UserButtons() {
  const modalShow = useSelector(getUserModalShow)
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(user) {
    dispatch(setUser(user));
    dispatch(setUserModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setUserModalShow(false));
  };

  const onclickEdit = (user) => {
    dispatch(setUser(user))
    navigate("/users/edit");
  };

  const onclickAddKeycloakUser = () => {
    navigate("/users/keycloakuseradd");
  };

  const onclickAddUser = () => {
    navigate("/users/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.users >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAddUser,
      className: 'add-button'
    }
  }
  if (scopes.users >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  if (role === 'super' || role === 'admin') {
    buttons.addkeycloakuser = {
      tag: "Add excisting keycloak user",
      value: 'addKeycloakUser',
      onClick: onclickAddKeycloakUser,
      className: 'add-button'
    }
  }

  return buttons
}

