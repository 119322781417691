import React from "react";
import { Page, Document, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../pdfstylesheet/stylesPickOrderPDF"
import { generateQRCode } from "./createTransactionPDF";

const PickingOrder = ({ order, hash_locations, relation }) => {
  let location_name = ''
  let location_address_line_1 = ''
  let location_address_line_2 = ''
  let location_country = ''
  let location_phonenumber = ''
  let location_email = ''
  let location_webshop_url = ''

  let relation_name = ''
  let relation_address_line_1 = ''
  let relation_address_line_2 = ''
  let relation_country = ''
  let relation_phonenumber = ''
  let relation_email = ''

  const location = hash_locations[order?.location_id]

  if (location) {
    location_name = location.name
    location_address_line_1 = `${location.address.street || ''} ${location.address.street_number || ''}${location.address.street_number_additional || ''}`
    location_address_line_2 = `${location.address.zip_code || ''} ${location.address.place || ''}`
    location_country = location.address.country
    location_phonenumber = location.phonenumbers?.find(phonenumber => phonenumber.type === "facturatie")?.number
    location_email = location.emails?.find(email => email.type === "facturatie")?.email
    location_webshop_url = location.webshop_url
  }


  if (order.delivery) {
    if (order.delivery.billing_address) {
      relation_name = order.delivery.billing_address.full_name
      relation_address_line_1 = order.delivery.billing_address.address_line1
      relation_address_line_2 = `${order.delivery.billing_address.zipcode || ''} ${order.delivery.billing_address.city || ''}`
      relation_country = order.delivery.billing_address.country
      relation_phonenumber = order.delivery.billing_address.telephone
    }
    if (order.delivery.delivery_address) {
      relation_name = order.delivery.delivery_address.full_name || order.delivery.billing_address.full_name
      relation_address_line_1 = order.delivery.delivery_address.address_line1
      relation_address_line_2 = `${order.delivery.delivery_address.zipcode || ''} ${order.delivery.delivery_address.city || ''}`
      relation_country = order.delivery.delivery_address.country
      relation_phonenumber = order.delivery.delivery_address.telephone
    }
  }

  if (!order.delivery && order.relation_id && relation) {
    const address = relation?.addresses?.[0]
    relation_name = hash_locations[order?.location_id]?.name
    relation_address_line_1 = `${address?.street || ''} ${address?.street_number || ''}${address.street_number_additional || ''}`
    relation_address_line_2 = `${address?.zip_code || ''} ${address.place || ''}`
    relation_country = address?.country
    relation_phonenumber = relation?.phone_numbers?.find(phonenumber => phonenumber.type === "facturatie")?.number
    relation_email = relation?.emails?.find(email => email.type === "facturatie")?.email
  }


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} key="header">

          <View style={styles.locationNaw} key='location-naw'>
            <Text>{location_name}</Text>
            <Text>{location_address_line_1}</Text>
            <Text>{location_address_line_2}</Text>
            <Text>{location_country}</Text>
            <Text>{location_phonenumber}</Text>
            <Text>{location_email}</Text>
            <Text>{location_webshop_url}</Text>
          </View>

          <View style={styles.relationNaw} key='relation-naw'>
            <Text>{relation_name}</Text>
            <Text>{relation_address_line_1}</Text>
            <Text>{relation_address_line_2}</Text>
            <Text>{relation_country}</Text>
            <Text>{relation_phonenumber}</Text>
            <Text>{relation_email}</Text>
          </View>

        </View>

        <Text style={styles.invoiceTitle}>Order</Text>
        <Text style={styles.invoiceInfo}>Order #{order?.number}</Text>
        <Text style={styles.invoiceInfo}>Status: {order?.status}</Text>
        <Text style={styles.invoiceInfo}>Date: {new Date(order?.creation).toLocaleString()}</Text>
        {order?.delivery?.billing_address?.comment && <Text style={styles.invoiceInfo}>Remark: {order?.delivery?.billing_address?.comment}</Text>}
        {order?.delivery?.delivery_address?.comment && <Text style={styles.invoiceInfo}>Remark: {order?.delivery?.delivery_address?.comment}</Text>}

        <View style={styles.table} key='invoice-table'>
          <View style={styles.tableRow} key='invoice-table-row'>
            <Text style={styles.tableHeaderSKU}>SKU</Text>
            <Text style={styles.tableHeaderAmount}>Amount</Text>
            <Text style={styles.tableHeader}>Item</Text>
          </View>

          {order?.order_lines?.map((orderLine, index) => (
            <View style={styles.tableRow} key={`table-row-${index}`}>
              <Text style={styles.tableCellSKU}>{orderLine?.product?.sku_code}</Text>
              <Text style={styles.tableCellAmount}>{orderLine?.quantity}</Text>
              <Text style={styles.tableCell}>{orderLine?.product_name}</Text>
            </View>
          ))}
        </View>
        <View style={styles.qrCodeContainer} key='table-row-total'>
          <Image src={generateQRCode(order.id)} />
        </View>
      </Page>
    </Document>
  );
}

export default PickingOrder;