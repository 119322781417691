import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';
import { layoutChoices } from '../selectfieldchoices/narrowcastingselect.mjs';


function NarrowcastingPlaylistForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('views')) {
    if (!props.views.selected) { props.views.selected = [] }
    props.data.views?.forEach(view => {
      props.views.selected.push({ 'value': view.id, 'label': view.name })
    });
  }

  if (props.data?.hasOwnProperty('layout')) {
    props.layout.selected = { 'value': props.data.layout, 'label': layoutChoices.find(element => element.id === props.data.layout)?.name }
  }

  if (props?.data?.all_day) {
    props.fields.find(field => field.id === 'start_time').disabled = true;
    props.fields.find(field => field.id === 'end_time').disabled = true;
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default NarrowcastingPlaylistForm;