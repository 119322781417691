export const deviceTypeChoices = [
    { id: 'VECTRON', name: 'VECTRON' },
    { id: 'SMARTPOS', name: 'SMARTPOS' },
]

export const selected_scanner = [
    { id: 'NONE', name: 'NONE' },
    { id: 'BLUETOOTH', name: 'BLUETOOTH' },
    { id: 'SERIAL_PORT', name: 'SERIAL_PORT' },
]

export const selected_printer = [
    { id: 'IP', name: 'IP' },
    { id: 'BLUETOOTH', name: 'BLUETOOTH' },
    { id: 'SUNMI_BT', name: 'SUNMI_BT' },
    { id: 'DURAPOS', name: 'DURAPOS' },
    { id: 'EPSON', name: 'EPSON' }
]

export const eft_payment_provider = [
    { id: 'MSP', name: 'MSP' },
    { id: 'CCV', name: 'CCV' },
    { id: 'Independent', name: 'Independent' },
]

export const filterChoices = [
    { 'id': 'active_true', 'name': 'Active' },
    { 'id': 'active_false', 'name': 'Not Active' },
    { 'id': 'smartpos_active', 'name': 'Smartpos Active' },
    { 'id': 'vectron_active', 'name': 'Vectron Active' },
    { 'id': 'smartpos_not_active', 'name': 'Smartpos Not Active' },
    { 'id': 'vectron_not_active', 'name': 'Vectron Not Active' },
]