export default function HandleOnChangeTemplateElementSelectField(props) {
  const event = props.event
  const object = props.object
  const selectfield = props.selectfield || props.event.id
  const index = props.index

  // let newObject = Object.assign({}, object)
  let newObject = JSON.parse(JSON.stringify(object))

  // clear newobject selectfield
  delete newObject.elements[index][selectfield]

  newObject.elements[index][selectfield] = event ? event.value : null;  

  return (newObject)
}