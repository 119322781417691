import axios from "axios";

const GetReservationLocationById = ({token, location_id}) => {

  return new Promise ((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/reservations/location/${location_id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  })

};

export default GetReservationLocationById

