import { createSlice } from "@reduxjs/toolkit";

export const guestCheckSlice = createSlice({
  name: "guestcheck",
  initialState: {
    guestcheck_pagesize: 10,
    guestcheck_pagination: 0,
    hash_guestchecks_all: {},
    guestcheck_refresh: false
  },
  reducers: {
    setGuestCheckId: (state, action) => {
      state.guestcheck_id = action.payload;
    },
    setGuestCheck: (state, action) => {
      state.guestcheck = action.payload;
    },
    setGuestChecks: (state, action) => {
      state.guestchecks = action.payload
    },
    setGuestChecksAll: (state, action) => {
      state.guestchecks_all = action.payload
      // action?.payload?.forEach(guestcheck => {
      //   state.hash_guestchecks_all[guestcheck.id] = guestcheck
      // });
    },
    setGuestCheckModalShow: (state, action) => {
      state.guestcheck_modalshow = action.payload
    },
    setGuestCheckPagination: (state, action) => {
      state.guestcheck_pagination = (action.payload - 1)
    },
    setGuestCheckPagesize: (state, action) => {
      state.guestcheck_pagesize = action.payload
    },
    setGuestCheckRefresh: (state, action) => {
      state.guestcheck_refresh = action.payload
    }
  }
});

export const {
  setGuestCheckId, setGuestCheck, setGuestChecks, setGuestChecksAll, setGuestCheckModalShow, setGuestCheckPagination, setGuestCheckPagesize, setGuestCheckRefresh
} = guestCheckSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getGuestCheckId = (state) => state.guestcheck.guestcheck_id
export const getGuestCheck = (state) => state.guestcheck.guestcheck
export const getGuestChecks = (state) => state.guestcheck.guestchecks
export const getGuestChecksAll = (state) => state.guestcheck.guestchecks_all
// export const getHashLocationsAll = (state) => state.guestcheck.hash_locations_all
export const getGuestCheckModalShow = (state) => state.guestcheck.guestcheck_modalshow
export const getGuestCheckPagination = (state) => state.guestcheck.guestcheck_pagination
export const getGuestCheckPagesize = (state) => state.guestcheck.guestcheck_pagesize
export const getGuestCheckRefresh = (state) => state.guestcheck.guestcheck_refresh

export default guestCheckSlice.reducer;