import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import OpticonEslButtons from '../portal/opticon/opticoneslbuttons';
import OpticonEslModal from './opticonEslModal';
import { useTranslation } from 'react-i18next';


export default function OpticonESLTable(props) {
  const esls = props.esls
  const links = props.hash_links
  const templates = props.hash_templates
  const buttons = OpticonEslButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let eslRows = []
  if (esls?.items) {
    eslRows = esls.items.map(esl => {
      const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: esl.id }) : false
      const refresh = buttons.hasOwnProperty('refresh') ? Object.assign({}, buttons.refresh, { id: esl.id }) : false
      if(links[esl.product_id]) {
        return (
          <tr className='category-row' key={esl.esl_barcode.concat(esl.product_id)}>
            <td>{esl.product_name}</td>
            <td>{esl.esl_variant}</td>
            <td>{esl.esl_barcode}</td>
            <td>{templates[links[esl?.product_id]?.esl_template_id]?.name}</td>
            <td>{templates[links[esl?.product_id]?.print_template_id]?.name}</td>
            <td>
              <Button size="sm" type='button' onClick={() => { modalview.onClick(esl, links[esl?.product_id]) }} id={esl.id}>{translate(modalview.tag)}</Button>
              {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(esl) }} id={esl.id}>{translate(edit.tag)}</Button>}
              {refresh && <Button size="sm" type='button' onClick={() => { refresh.onClick(esl, links[esl.product_id]) }} id={esl.id}>{translate(refresh.tag)}</Button>}
            </td>
          </tr>
        )
      }
      return null
    })
  }

  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>
              {translate('Name')}
            </th>
            <th>ESL variant</th>
            <th>ESL barcode</th>
            <th>ESL template</th>
            <th>Print template</th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {eslRows}
        </tbody>
        <OpticonEslModal modal={modalview.modal} />
      </Table>
    </>
  );
}