export default function HandleOnChangeProductCategory(props) {
  const event = props.event
  const object = props.object || {}
  const hash = props.hash
  const deleteList = props.deleteList

  let newObject = JSON.parse(JSON.stringify(object))
  // let newObject = Object.assign({}, object)
  let newDeleteCategories = Object.assign([], deleteList)
  let excistingCategories = []
  let newCategories = []
  
  // clear newProduct categories
  newObject.categories = []

  // create array with excisting category id's  
  if (object.hasOwnProperty('categories')) {
    // eslint-disable-next-line
    object.categories.map((category) => {
      excistingCategories.push(category.id)
    })
  }

  // create array with new category id's
  event.forEach(target => {
    newCategories.push(target.value)
  });

  // check for categories that stay or must be deleted
  if (object.hasOwnProperty('categories')) {
    // eslint-disable-next-line
    object.categories.map((category) => {
      if (category.type === 'PRODUCTPAGE' && newCategories.includes(category.id)) {
        newObject.categories.push(category)    
      }
      if (category.type === 'PRODUCTPAGE' && !newCategories.includes(category.id)) {
        newDeleteCategories.push(category)      
      }
      if (category.type !== 'PRODUCTPAGE') {
        newObject.categories.push(category)      
      }
    })
  }

  // check for new catagories, add them to product.categories
  event.forEach(target => {
    if (!excistingCategories.includes(target.value)) {
      newObject.categories.push(hash[target.value])    
    }
  });

  return [newObject, newDeleteCategories]
}