import Table from 'react-bootstrap/Table';

export default function VatlineTable(props) {

  let lines = []
  if (props.invoice) {lines = props.invoice.invoice_lines}
  if (props.order_lines) {lines = props.order_lines}
  if (props.invoice_lines) {lines = props.invoice_lines}


  let newVatLines = []
  let calculatedVatTable = []
  for(const line of Object.values(lines)) {
    const vatPercentage = String(line.vat_percentage)
    const inclVat = Number(line.amount_inclusive_vat)
    const exclVat = line.amount_exclusive_vat? Number(line.amount_exclusive_vat) : line.vat_percentage ? Number(line.amount_inclusive_vat/(100+line.vat_percentage)* 100) : Number(line.amount_inclusive_vat)
    if (!calculatedVatTable[String(vatPercentage)]) {
      calculatedVatTable[String(vatPercentage)] = {'%':vatPercentage, inclVat:0, exclVat:0, vat:0 }
    }
    const addedInclVat = calculatedVatTable[String(vatPercentage)].inclVat + inclVat
    const addedExclVat = calculatedVatTable[String(vatPercentage)].exclVat + exclVat
    const addedVAT = addedInclVat - addedExclVat
    calculatedVatTable[String(vatPercentage)] = {'%':vatPercentage, inclVat:addedInclVat, exclVat:addedExclVat, vat:addedVAT}
    if (line.attributes) {
      for (const attribute of Object.values(line.attributes)) {
        const vatPercentage = String(attribute.vat_percentage)
        const inclVat = Number(attribute.amount_inclusive_vat)
        const exclVat = attribute.amount_exclusive_vat? Number(attribute.amount_exclusive_vat) : attribute.vat_percentage ? Number(attribute.amount_inclusive_vat/(100+attribute.vat_percentage)* 100) : Number(attribute.amount_inclusive_vat)
        if (!calculatedVatTable[String(vatPercentage)]) {
          calculatedVatTable[String(vatPercentage)] = {'%':vatPercentage, inclVat:0, exclVat:0, vat:0 }
        }
        const addedInclVat = calculatedVatTable[String(vatPercentage)].inclVat + inclVat
        const addedExclVat = calculatedVatTable[String(vatPercentage)].exclVat + exclVat
        const addedVAT = addedInclVat - addedExclVat
        calculatedVatTable[String(vatPercentage)] = {'%':vatPercentage, inclVat:addedInclVat, exclVat:addedExclVat, vat:addedVAT}
      }
    }
  }

  for (const line of Object.values(calculatedVatTable)) {
    newVatLines.push(
      <tr key={line['%']}>
        <td>{line['%']}</td>
        <td align="right">{Number(line.exclVat).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td align="right">{Number(line.inclVat).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td align="right">{Number(line.vat).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
      </tr>
    )
  }
  
  return (
    <Table hover width='100%'>
      <thead>
        <tr>
          <th width='40%'>%</th>
          <th width='20%' className="text-end">Excl</th>
          <th width='20%' className="text-end">Incl</th>
          <th width='20%' className="text-end">VAT</th>
        </tr>
      </thead>
      <tbody>
        {newVatLines}
      </tbody>
    </Table>
  );
}