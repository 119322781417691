import { createSlice } from "@reduxjs/toolkit";

export const errorSlice = createSlice({
  name: "error",
  initialState: {

  },
  reducers: {
    setError: (state, action) => {
      if (action.payload) {
        state.error = []
        state.error.type = action.type
        state.error.payload = JSON.parse(action.payload)
        // state.error.message = JSON.parse(action.payload).message
        // state.error.response = JSON.parse(action.payload).response
      }
      if (!action.payload) {delete state.error}
    },
    setResponseError: (state, action) => {
      if (action.payload) {
        state.error = []
        state.error.type = action.type
        state.error.payload = action.payload.data
        // state.error.response = action.payload.response
      }
      if (!action.payload) {delete state.error}
    },
    setPythonResponseError: (state, action) => {
      if (action.payload) {
        let parsed = JSON.parse(action.payload)
        let newPayload = []
        newPayload.name = parsed.data.error
        newPayload.code = parsed.data.error_code
        newPayload.message = parsed.data.error_message
        state.error = []
        state.error.type = action.type
        state.error.payload = newPayload
        state.error.response = action.payload.response
      }
      if (!action.payload) {delete state.error}
    },
  }
});

export const {
  setError, setResponseError, setPythonResponseError
} = errorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getError = (state) => state.error.error
export const getMessage = (state) => state.error.message

export default errorSlice.reducer;