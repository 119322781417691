import { createSlice } from "@reduxjs/toolkit";

export const matchingSlice = createSlice({
  name: "matching",
  initialState: {
  },
  reducers: {
    setTaskId: (state, action) => {
      state.task_id = action.payload;
    },
    setTaskData: (state, action) => {
      state.task_data = action.payload
    },
    setMatchingModalShow: (state, action) => {
      state.matching_modal_show = action.payload
    },
  }
});

export const {
  setTaskId, setTaskData, setMatchingModalShow
} = matchingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getTaskId = (state) => state.matching.task_id
export const getTaskData = (state) => state.matching.task_data
export const getMatchingModalShow = (state) => state.matching.matching_modal_show

export default matchingSlice.reducer;