import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import { apiScopes, apiScopesMap } from "../../scopes/scopes.mjs"
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { UserRoleFormFields } from "./userRoleFormFields";
import UserRoleForm from "../../components/userRoleForm";
import HandleOnChange from "../../helpers/handleOnChange";
import PostUserRoleDefinitions from "../../actablueAPI/serials/PostUserRoleDefinitions";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getHashAdminLocationsAll } from "../../redux/dataSlices/locationSlice";
import GetRegistration from "../../actablueAPI/registrations/GetRegistration";
import GetLocationRawScopes from "../../helpers/getLocationRawScopes";
import DeepCopy from "../../helpers/DeepCopy";
import useHandleError from "../../customhooks/useHandleError";

const UserRoleAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const hash_admin_locations_all = useSelector(getHashAdminLocationsAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const navTitle = "User Role add";
  const [userRole, setUserRole] = useState({ administration_id: admin_id, roles: {} });
  const [formFields, setFormFields] = useState({ 'userRoleFormFields': UserRoleFormFields() });

  const onChange = (event) => {
    let newObject = HandleOnChange({ event: event, object: userRole });
    setUserRole(newObject);
  }

  const onChangeScope = (event) => {
    const generateUserRoleScope = (scope) => {
      // event.target.alt is location_id
      let location_id = event.target.alt;
      let splitScope = scope.split(":");
      if (scope.split("+").length > 1) splitScope = scope.split("+")[1].split(":");
      let newScopeArray = [splitScope[0], admin_id, location_id, splitScope[1], splitScope[2], event.target.name];
      let newScope = newScopeArray.join(':');
      if (scope.split("+").length > 1) newObject.roles[scope] = newScope;
      if (scope.split("+").length <= 1) newObject.roles[`${location_id}+${scope}`] = newScope;
    }

    let newObject = DeepCopy(userRole);
    if (event.target.checked) {
      generateUserRoleScope(event.target.id);
      let splitScope = event.target.id.split("+")[1].split(":");
      if (splitScope[0] === 'portal') {
        splitScope.splice(3, 1)
        let scopeWithoutValue = splitScope.join(":")
        if (apiScopesMap[scopeWithoutValue]) {
          apiScopesMap[scopeWithoutValue].forEach(apiScope => {
            generateUserRoleScope(apiScope);
          });
        }
      }
    } else {
      if (newObject.roles[event.target.id]?.split(":")[5] === event.target.name) {
        delete newObject.roles[event.target.id];
        let splitScope = event.target.id.split("+")[1].split(":");
        if (splitScope[0] === 'portal') {
          splitScope.splice(3, 1)
          let scopeWithoutValue = splitScope.join(":")
          if (apiScopesMap[scopeWithoutValue]) {
            apiScopesMap[scopeWithoutValue].forEach(apiScope => {
              delete newObject.roles[apiScope];
            });
          }
        }
      } else {
        generateUserRoleScope(event.target.id);
        let splitScope = event.target.id.split("+")[1].split(":");
        if (splitScope[0] === 'portal') {
          splitScope.splice(3, 1)
          let scopeWithoutValue = splitScope.join(":")
          if (apiScopesMap[scopeWithoutValue]) {
            apiScopesMap[scopeWithoutValue].forEach(apiScope => {
              generateUserRoleScope(apiScope);
            });
          }
        }
      }
    }

    dispatch(setUserRole(newObject));
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    dispatch(setLoading(true))
    // set scopes back to array with strings
    let newUserRole = Object.assign({}, userRole)

    if (userRole.roles) {
      let newRoles = []
      for (const value of Object.values(userRole.roles)) {
        if (value.split(":")[0] === 'portal') newRoles.push(value)
        if (value.split(":")[0] === 'api') {
          let newValue;

          switch (value.split(":")[5]) {
            case 'read':
              newRoles.push(value)
              break;
            case 'update':
              newValue = value.replace(/:[^:]+$/, ':read');
              newRoles.push(newValue)

              newRoles.push(value)
              break;
            case 'create':
              newValue = value.replace(/:[^:]+$/, ':read');
              newRoles.push(newValue)
              newValue = value.replace(/:[^:]+$/, ':update');
              newRoles.push(newValue)

              newRoles.push(value)
              break;
            case 'delete':
              newValue = value.replace(/:[^:]+$/, ':read');
              newRoles.push(newValue)
              newValue = value.replace(/:[^:]+$/, ':update');
              newRoles.push(newValue)
              newValue = value.replace(/:[^:]+$/, ':create');
              newRoles.push(newValue)

              newRoles.push(value)
              break;
            default:
              break;
          }
        }
      }
      newUserRole.roles = newRoles
    }


    PostUserRoleDefinitions({ token: token, admin_id: admin_id, object: newUserRole })
    .then(() => {
      navigate("/userroles")
    })
    .catch(error => {
      HandleError({ error: error })
      dispatch(setLoading(false))
    })

  };
  
  const createFormFields = async () => {
    let newFormFields = DeepCopy(formFields);
    newFormFields.roleFields = [];
    newFormFields.apiRoleFields = [];

    for (const location of Object.values(hash_admin_locations_all)) {
      const response = await GetRegistration({ token: token, admin_id: admin_id, location_id: location?.id });
      const location_scopes = GetLocationRawScopes(response.data, true, location?.id);

      location_scopes.forEach((scope) => {
        let scopeWithoutLocation = scope.split("+")[1];
        newFormFields.roleFields.push({ type: 'select', name: scopeWithoutLocation, id: scope, placeholder: scopeWithoutLocation, formdatafield: 'roles', prefix: 'roles', location_id: location?.id });
      });

      for (const scope of Object.values(apiScopes)) {
        newFormFields.apiRoleFields.push({ type: 'select', name: scope, id: `${location?.id}+${scope}`, placeholder: scope, formdatafield: 'roles', prefix: 'roles', location_id: location?.id });
      }
    }

    if (newFormFields.roleFields.length < 1 && newFormFields.apiRoleFields.length < 1) {
      navigate('/userroles');
      return;
    }

    setFormFields(newFormFields);
    dispatch(setLoading(false))
  };

  useEffect(() => {
    const getFormFields = async () => {
      await createFormFields();
    };
    dispatch(setNavBarTitle(navTitle));
    dispatch(setLoading(true));
  
    getFormFields();
    // eslint-disable-next-line
  }, [admin_id]);

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: formFields,
    field: {
      onChange: onChange,
      onChangeScope: onChangeScope
    },
    data: userRole
  };

  const addCard = {
    size: 12,
    title: navTitle,
    data: <UserRoleForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};
export default UserRoleAdd;

