import axios from "axios";

const PostUserRoleDefinitions = ({ token, admin_id, object }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/serials/roledefinitions"),
      headers: {
        'Authorization': 'Bearer '.concat(token['access_token']),
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      data: object
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      });
  });
};

export default PostUserRoleDefinitions