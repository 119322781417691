import { createSlice } from "@reduxjs/toolkit";

export const accountingSlice = createSlice({
  name: "accounting",
  initialState: {
    hash_accountings_all: {}
  },
  reducers: {
    setAccountingId: (state, action) => {
      state.accounting_id = action.payload;
    },
    setAccounting: (state, action) => {
      state.accounting = action.payload;
    },
    setAccountings: (state, action) => {
      state.accountings = action.payload
    },
    setAccountingsAll: (state, action) => {
      state.accountings_all = action.payload
      action?.payload?.forEach(accounting => {
        state.hash_accountings_all[accounting.id] = accounting
      });
    },
    setAccountingModalShow: (state, action) => {
      state.accounting_modalshow = action.payload
    },
    setAccountingPagination: (state, action) => {
      state.accounting_pagination = (action.payload - 1)
    },
    setAccountingPagesize: (state, action) => {
      state.accounting_pagesize = action.payload
    },
  }
});

export const {
  setAccountingId, setAccounting, setAccountings, setAccountingModalShow, setAccountingPagination, setAccountingPagesize, setAccountingsAll,
} = accountingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getAccountingId = (state) => state.accounting.accounting_id
export const getAccounting = (state) => state.accounting.accounting
export const getAccountings = (state) => state.accounting.accountings
export const getAccountingsAll = (state) => state.accounting.accountings_all
export const getHashAccountingsAll = (state) => state.accounting.hash_accountings_all
export const getAccountingsHighlights = (state) => state.accounting.accountingshighlights
export const getAccountingModalShow = (state) => state.accounting.accounting_modalshow
export const getAccountingPagination = (state) => state.accounting.accounting_pagination
export const getAccountingPagesize = (state) => state.accounting.accounting_pagesize

export default accountingSlice.reducer;