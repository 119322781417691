import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import TableButton from './tableButton';
import ReservationObjectButtons from '../portal/reservations/reservationobjectbuttons';
import ReservationObjectModal from "../components/reservationobjectModal";
import { useTranslation } from 'react-i18next';


function ReservationObjectTable(reservationobjects) {
  const buttons = ReservationObjectButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  // convert object to array for sorting
  let reservationobjects_array = Object.values(reservationobjects)
  let sorted = reservationobjects_array.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  })

  let rows = []
  sorted.forEach(resobject => {
    // for (let reservationobject in reservationobjects) {
    // let resobject = reservationobjects[reservationobject]
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: resobject.id }) : false
    const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false

    rows.push(
      <tr key={resobject.id}>
        <td>{resobject.name}</td>
        <td>{resobject.type}</td>
        <td>{resobject.capacity}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(resobject) }} id={resobject.id}>{translate(modalview.tag)}</Button>
          {edit && <TableButton {...edit} />}
          {del && <Button size="sm" type='button' onClick={() => { del.onClick(resobject) }} id={resobject.id}>{translate(del.tag)}</Button>}
        </td>
      </tr>
    )
    // }
  })

  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Type')}
            </th>
            <th>
              {translate('Capacity')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <ReservationObjectModal modal={modalview.modal} />
      </Table>
    </>
  );
}

export default ReservationObjectTable;