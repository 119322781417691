export const productPerMonthReportHighlights = (report) => {
  let mostRevenue = {total:0}
  let mostQuantity = {amount:0}

  if (report.days.totals && Object.keys(report.days.totals).length > 0) {
    for (const product of Object.values(report.days.totals)) {
      if (product.total > mostRevenue.total) {
        mostRevenue = JSON.parse(JSON.stringify(product))
      }
      if (product.amount > mostQuantity.amount) {
        mostQuantity = JSON.parse(JSON.stringify(product))
      }
    }
  
    let newReportHighlights = [
      {'Highest revenue': {'amount':mostRevenue.name}},
      {'Highest quantity': {'amount':mostQuantity.name}},
    ]
  
    return newReportHighlights
  }

  return false
};
