import axios from "axios";

export default function PostKeycloakUserLogIn({user, password}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        username: user,
        password: password,
        grant_type: 'password'
      }
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    });
  });
};