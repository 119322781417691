import React from "react";
import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import { planningFormFields } from "./planningFormFields.jsx"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue"
import DeepCopy from "../../helpers/DeepCopy.jsx";
import { statusFilterChoices } from "../../selectfieldchoices/planningselect.mjs";
import PlanningForm from "../../components/planningForm.jsx";
import { GetAllPlanningObjects, PostPlanning } from "../../actablueAPI/planning/Planning.jsx";
import { getHashPlanningObjectsAll, getPlanningObjectsAll, setPlanningObjectsAll } from "../../redux/dataSlices/planningSlice.js";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice.js";
import useHandleError from "../../customhooks/useHandleError.jsx";
import { validateFormObject } from "../../helpers/validateFormObject.jsx";
// import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects.jsx";
import axios from "axios";
import { getDevicesAll } from "../../redux/dataSlices/deviceSlice.js";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject.jsx";

const PlanningAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const planning_objects = useSelector(getPlanningObjectsAll);
  const hash_planning_objects = useSelector(getHashPlanningObjectsAll);
  const devices = useSelector(getDevicesAll);
  // const hash_devices_all = useSelector(getHashDevicesAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planning, setPlanning] = useState({ location_id: location_id, status: 'PENDING' })
  const [invalidFields, setInvalidFields] = useState([]);
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newPlanning = HandleOnChange({ event: event, object: planning })
    setPlanning(newPlanning)
  };

  const onDeviceChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'device_id' })
    setPlanning(newPlanning)
  };

  const onStatusChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'status' })
    setPlanning(newPlanning)
  };

  // const onPlanningObjectChange = (event) => {
  //   let newPlanning = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: planning, selectfield: 'planning_objects', hash: hash_planning_objects })
  //   setPlanning(newPlanning)
  // };

  const onPlanningObjectChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: planning, selectfield: 'planning_objects', hash: hash_planning_objects })
    newPlanning.device_id = newPlanning?.planning_objects?.device_id || null
    setPlanning(newPlanning)
  };

  const onCancel = () => { navigate(-1) }

  const onSubmit = (event) => {
    const [invalid] = validateFormObject({ object: planning, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let newPlanning = DeepCopy(planning)
    newPlanning.status = planning.status.toUpperCase()

    newPlanning.planning_timestamp = moment(planning.planning_timestamp).utc().format();

    if (!newPlanning.planning_objects) newPlanning.planning_objects = []

    if (newPlanning?.planning_objects) {
      if (!newPlanning.planning_objects.planning_object_id) newPlanning.planning_objects.planning_object_id = newPlanning?.planning_objects?.id;
      delete newPlanning.planning_objects.id
      newPlanning.planning_objects = [newPlanning?.planning_objects]
      // newPlanning.planning_objects.planning_object_id = newPlanning?.planning_objects?.id;
      // delete newPlanning?.planning_objects?.id
      // for (const planning_object of newPlanning?.planning_objects) {
      //   planning_object.planning_object_id = planning_object.id
      //   delete planning_object.id
      // }
    }

    PostPlanning({ token: token, data: newPlanning })
      .then(() => {
        navigate("/planning");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: planningFormFields,
    field: {
      onChange: onChange
    },
    // hash_devices_all: hash_devices_all,
    data: planning,
    device_id: {
      name: 'device_id',
      options: devices,
      selected: '',
      onChange: onDeviceChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    status: {
      name: 'status',
      options: statusFilterChoices,
      selected: '',
      onChange: onStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    planning_objects: {
      name: 'planning_objects',
      options: planning_objects,
      selected: [],
      onChange: onPlanningObjectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Planning add",
    data: <PlanningForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setLoading(true))

    axios.all([
      GetAllPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id }),
      // GetDevicesListAll({ token: token })
    ])
      .then(axios.spread((planningObjectsResponse) => {
        dispatch(setPlanningObjectsAll(planningObjectsResponse))
        // dispatch(setDevicesAll(devicesResponse.data))
        dispatch(setLoading(false))
      }))
      .catch((error) => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )
};
export default PlanningAdd;
