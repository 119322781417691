import axios from "axios";

const PostSchedule = ({token, admin_id, schedule}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/narrowcasting/schedule`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type':'application/json',
        // 'administration': admin_id
      },
      data: schedule
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    });
  });
};

export default PostSchedule