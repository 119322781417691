import axios from "axios";

const PutWarehouseProductById = ({ token, id, data, signal = null}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_ENV}/warehouse/products/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
      data: data,
      signal: signal
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default PutWarehouseProductById