export default function HandleOnChangeCurrencyCount(props) {
  const event = props.event
  const object = props.object
  const hash_drawers_all = props.hash_drawers_all

  let newObject = JSON.parse(JSON.stringify(object))

  let previous_day_amount = 0

  if (hash_drawers_all && newObject?.drawer_id && hash_drawers_all[newObject?.drawer_id]) {
    if (hash_drawers_all[newObject?.drawer_id].previous_count) {
      previous_day_amount = Number(hash_drawers_all[newObject?.drawer_id]?.previous_count?.next_day_amount)
    }
  }

  if (object && event) {

    if (!newObject['counted']) {
      newObject['counted'] = {};
    }

    if (!newObject['counted']['currency']) {
      newObject['counted']['currency'] = {};
    }

    if (!newObject['counted']['currency'][event.target.name]) {
      newObject['counted']['currency'][event.target.name] = { value: event.target.name };
    }

    if (event.target.role === 'total') {

      newObject['counted']['currency'][event.target.name].count = Number(event.target.value)

      // Recalculate total
      newObject['counted']['currency'][event.target.name].total = (newObject['counted']['currency'][event.target.name].value * newObject['counted']['currency'][event.target.name].count).toFixed(2);
    }

    if (event.target.role === 'value') {
      newObject['counted']['currency'][event.target.name].total = Number(event.target.value)

      // Recalculate count
      newObject['counted']['currency'][event.target.name].count = (newObject['counted']['currency'][event.target.name].total / newObject['counted']['currency'][event.target.name].value).toFixed(0);
    }

    // Recalculate overall total
    let newTotal = 0.00

    Object.entries(newObject?.counted?.currency).map(([key, value]) => {
      return newTotal += Number(value.total)
    })
    newObject['counted'].total = Number(newTotal).toFixed(2)

    let reporting_total = 0.00

    for (let report in newObject?.reports) {
      reporting_total += (Number(newObject?.reports[report]?.cash) - Number(newObject?.reports[report].exchange)).toFixed(2)
    }

    newObject['discrepancy'] = Math.abs(Number(reporting_total) + previous_day_amount - newObject['counted'].total).toFixed(2);
  }
  return (newObject)
}


