import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashUsersAll, getUsersAll } from "../../redux/dataSlices/userSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import RelationForm from "../../components/relationForm";
import { RelationFormFields } from "./relationFormFields";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox";
import HandleOnChangeMultiObjectField from "../../helpers/handleOnChangeMultiObjectFields";
import AddObject from "../../helpers/addObject";
import RemoveObject from "../../helpers/removeObject";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import CheckNewObjects from "../../helpers/checkNewObjects";
import { relationTypeChoices } from "../../selectfieldchoices/relationselect.mjs";
import { phoneNumberTypeChoices, emailTypeChoices } from "../../selectfieldchoices/phonenumberselect.mjs";
import HandleOnChangeMultiObjectSingleSelectFieldWithValue from "../../helpers/handleOnChangeMultiObjectSingleSelectFieldWithValue";
import PostRelationsRelation from "../../actablueAPI/relations/PostRelationsRelation";

const RelationAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const users_all = useSelector(getUsersAll)
  const hash_users_all = useSelector(getHashUsersAll)
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [relation, setRelation] = useState({ administration_id: admin_id, user_id: token?.payload?.sub })
  const [checkedList, setCheckedList] = useState([])
  const [id, setId] = useState(0)

  const onChange = (event) => {
    let newRelation = HandleOnChange({ event: event, object: relation })
    setRelation(newRelation)
  }

  const onTypeChange = (event) => {
    let newRelation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: relation, selectfield: 'type' })
    dispatch(setRelation(newRelation))
  }

  const onAdminIdChange = (event) => {
    let newRelation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: relation, selectfield: 'administration_id' })
    delete newRelation.location_id
    setRelation(newRelation)
  }

  const onUserIdChange = (event) => {
    let newRelation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: relation, selectfield: 'user_id' })
    setRelation(newRelation)
  }

  const onChangePhonenumbers = (event) => {
    let newRelation = HandleOnChangeMultiObjectField({ event: event, object: relation, field: 'phone_numbers' })
    setRelation(newRelation)
  }

  const onPhonenumbersTypeChange = (event) => {
    let newRelation = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: relation, selectfield: 'type', field: 'phone_numbers' })
    setRelation(newRelation)
  }

  const onChangeEmails = (event) => {
    let newRelation = HandleOnChangeMultiObjectField({ event: event, object: relation, field: 'emails' })
    setRelation(newRelation)
  }

  const onEmailTypeChange = (event) => {
    let newRelation = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: relation, selectfield: 'type', field: 'emails' })
    setRelation(newRelation)
  }

  const onChangeAddressess = (event) => {
    let newRelation = HandleOnChangeMultiObjectField({ event: event, object: relation, field: 'addresses' })
    setRelation(newRelation)
  }

  const onAddObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newRelation = AddObject({ event: event, object: relation, id: newId })
    setRelation(newRelation)
  }

  const onRemoveObjects = (event) => {
    let newRelation = RemoveObject({ event: event, object: relation, checkedList: checkedList })
    setRelation(newRelation)
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onChangeAllCheckbox = (event) => {

  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    let newRelation = JSON.parse(JSON.stringify(relation))
    CheckNewObjects(newRelation)
    PostRelationsRelation({token:token, admin_id:admin_id, data:newRelation})
    .then(response => {
      navigate("/relations");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: RelationFormFields(),
    field: {
      onChange: onChange
    },
    data: relation,
    type: {
      label: 'type',
      options: relationTypeChoices,
      // selected: {},
      onChange: onTypeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      // selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    user_id: {
      label: 'user_id',
      options: users_all,
      hash: hash_users_all,
      // selected: {},
      onChange: onUserIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    phone_numbers: {
      onChange: onChangePhonenumbers,
      add: {
        title: 'Add',
        type: 'button',
        name: 'phone_numbers',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'phone_numbers',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList,
      type: {
        label: 'type',
        options: phoneNumberTypeChoices,
        // selected: {},
        onChange: onPhonenumbersTypeChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      }
    },
    emails: {
      onChange: onChangeEmails,
      add: {
        title: 'Add',
        type: 'button',
        name: 'emails',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'emails',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList,
      type: {
        label: 'type',
        options: emailTypeChoices,
        // selected: {},
        onChange: onEmailTypeChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      }
    },
    addresses: {
      onChange: onChangeAddressess,
      add: {
        title: 'Add',
        type: 'button',
        name: 'addresses',
        onClick: onAddObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'addresses',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList
    }
  };

  const editCard = {
    size: 12,
    title: "Relation add",
    data: <RelationForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Relation add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default RelationAdd;

