import { useSelector } from 'react-redux';
import { Modal, Nav, Tab, Button } from 'react-bootstrap';
import { getAccountingInfo } from '../redux/dataSlices/transactionSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';

export default function TransactionSnelstartModal(props) {
  const object = useSelector(getAccountingInfo)
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  return (
    <Modal
      show={props.modal.show}
      size="lg"
      dialogClassName='modal-fullscreen-md-down modal-md'
      contentClassName='h-md-100'
      aria-labelledby="contained-modal-title-vcenter"
      centered scrollable
    >
      <Modal.Header className='order-modal-head'>
        <Modal.Title className='text-center' id="contained-modal-title-vcenter">
          Accounting info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container defaultActiveKey="info">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="info">
                {translate('Info')}
              </Nav.Link>
            </Nav.Item>
            {service &&
              <Nav.Item>
                <Nav.Link eventKey="service">
                  {translate('Service')}
                </Nav.Link>
              </Nav.Item>
            }
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="info">
              <br></br>
              <h4>Result accounting</h4>
              <div className="container text-center">
              {object?.payload ? <JsonHighlighter {...JSON.parse(object.payload)} /> : 'No result available, probably no ledgers available for booked products, used journals or taxrates.' }
              </div>
              <br></br>
            </Tab.Pane>
            <Tab.Pane eventKey="service">
              <br></br>
              <div className="container text-center">
                <div className="row">
                  <div className="col">
                    <p>Id: {object?.id}</p>
                  </div>
                </div>
                <div className="col">
                  <JsonHighlighter {...object} />
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
      </Modal.Footer>
    </Modal>
  );
}