import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { setError } from "../redux/dataSlices/errorSlice";
import { setProgressModalShow, setTaskData } from "../redux/dataSlices/analyseSlice";
import PostImageBase64 from "../actablueAPI/shelflabels/PostImageBase64";

async function downloadShelflabels(admin_id, location_id, selectedProducts, token, dispatch, navigate, settings, template_id) {
  const zip = new JSZip();

  try {
    const images = {};
    let current = 0
    let task_data = {
      current: current,
      total: Object.keys(selectedProducts).length,
      progress: current === 0 ? 0 : current / Object.keys(selectedProducts).length
    }
    dispatch(setTaskData(task_data))
    dispatch(setProgressModalShow(true))
    for (const id of Object.keys(selectedProducts)) {
      // eslint-disable-next-line no-loop-func
      try {
        const dataObject = {
          'product_id': id,
          'admin_id': admin_id,
          'location_id': location_id,
          'name': selectedProducts[id]?.name ?? 'Unknown product',
          'contents': selectedProducts[id]?.description ?? '',
          'price': `${Number(selectedProducts[id]?.price_incl_vat).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'sku_code': selectedProducts[id]?.sku_code ?? '0',
          'ean_code': selectedProducts[id]?.eans?.[0]?.ean_code ?? '0',
          'url': '',
          'discount': `${Number(selectedProducts[id]?.discount).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'emballage': `${Number(selectedProducts[id]?.emballage).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'price_incl_vat': `${Number(selectedProducts[id]?.price_incl_vat).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'price_excl_vat': `${Number(selectedProducts[id]?.price_excl_vat).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'price_incl_discount': `${(Number(selectedProducts[id]?.price_incl_vat || 0) - Number(selectedProducts[id]?.discount || 0)).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
          'brand': selectedProducts[id]?.product_specifications?.brand ?? '',
          'weight': selectedProducts[id]?.product_specifications?.weight ?? '',
          'height': selectedProducts[id]?.product_specifications?.height ?? '',
          'length': selectedProducts[id]?.product_specifications?.length ?? '',
          'width': selectedProducts[id]?.product_specifications?.width ?? ''
        }
        const imageResponse = await PostImageBase64({token:token, template_id:template_id, code_type:settings.code_to_print, inverted_print:settings.inverted_print, data:dataObject})

        images[id] = { data: imageResponse.data };
        current++
        let task_data = {
          current: current,
          total: Object.keys(selectedProducts).length,
          progress: current === 0 ? 0 : current / Object.keys(selectedProducts).length
        }
        dispatch(setTaskData(task_data))
      } catch (error) {
        if (!error.code === "ERR_BAD_REQUEST") {
          dispatch(setError(JSON.stringify(`An unknown error occurred.`)));
          return false;
        }
      }

    }

    try {
      const fileNames = {};

      for (const id of Object.keys(selectedProducts)) {
        if (images[id]) {
          let fileName = selectedProducts[id].name ? `${selectedProducts[id]?.name.replace(/[^\w\s]/gi, "")}.png` : 'Unknown Product.png';

          if (zip.files[fileName]) {
            if (fileNames[fileName] === undefined) {
              fileNames[fileName] = 1;
            } else {
              fileNames[fileName]++;
            }

            fileName = selectedProducts[id].name ? `${selectedProducts[id]?.name.replace(/[^\w\s]/gi, "")}_${fileNames[fileName]}.png` : `Unknown Product_${fileNames[fileName]}.png`;
          }

          if (Object.keys(images).length > 1) {
            zip.file(fileName, atob(images[id].data), { binary: true });
          } else {
            const byteCharacters = atob(images[id].data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });
            saveAs(blob, fileName);
          }
        }
      }
    } catch (error) {
      console.error(error)
      dispatch(setProgressModalShow(false))
      dispatch(setError(JSON.stringify(`An unknown error occurred.`)));
      return false;
    }

    if (Object.keys(selectedProducts).length > 1) {
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, 'products.zip');
    }
    dispatch(setProgressModalShow(false))
  } catch (error) {
    console.error(error);
  }
}

export default downloadShelflabels