const CheckNewObjects = (object) => {

  if (object?.phonenumbers) {
    for (let phonenumber of object?.phonenumbers) {
      if (phonenumber?.new) {
        delete phonenumber.new
        delete phonenumber.id
      }
    }
  }

  if (object?.phone_numbers) {
    for (let phonenumber of object?.phone_numbers) {
      if (phonenumber?.new) {
        delete phonenumber.new
        delete phonenumber.id
      }
    }
  }

  if (object?.addresses) {
    for (let address of object?.addresses) {
      if (address?.new) {
        delete address.new
        delete address.id
      }
    }
  }

  if (object?.emails) {
    for (let email of object?.emails) {
      if (email?.new) {
        delete email.new
        delete email.id
      }
    }
  }

  if (object?.next_device_sort_groups) {
    for (let next_device_sort_group of object?.next_device_sort_groups) {
      if (next_device_sort_group?.new) {
        delete next_device_sort_group.new
        delete next_device_sort_group.id
      }
    }
  }

};

export default CheckNewObjects
