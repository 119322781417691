import React from "react";
import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getReservationObjects, setReservationObjects, getReservationId, getReservationLocation } from "../../redux/dataSlices/reservationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import ReservationForm from "../../components/reservationForm";
import { reservationFormFields } from "./reservationFormFields.mjs"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleOnChangeMultiSelectFieldReservationObject from "../../helpers/handleOnChangeMultiSelectFieldReservationObject";
import { reservationStatusChoices, reservationTypeChoices } from "../../selectfieldchoices/reservationselect.mjs";
import PutReservation from "../../actablueAPI/reservations/PutReservation";
import GetReservationObjects from "../../actablueAPI/reservations/GetReservationObjects";
import GetReservationById from "../../actablueAPI/reservations/GetReservationByID";
import useHandleError from "../../customhooks/useHandleError";

const ReservationEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const reservation_id = useSelector(getReservationId)
  const reservation_objects = useSelector(getReservationObjects)
  const reservation_location = useSelector(getReservationLocation)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reservation, setReservation] = useState({})
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newReservation = HandleOnChange({ event: event, object: reservation })
    // reservation.reservation_timestamp = new Date(reservation.reservation_timestamp)
    setReservation(newReservation)
  };

  const onTypeChange = (event) => {
    let newReservation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservation, selectfield: 'type' })
    setReservation(newReservation)
  };

  const onStatusChange = (event) => {
    let newReservation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservation, selectfield: 'status' })
    setReservation(newReservation)
  };

  const onReservationObjectChange = (event) => {
    let newReservation = HandleOnChangeMultiSelectFieldReservationObject({ event: event, object: reservation, selectfield: 'reservation_objects', hash: reservation_objects })
    setReservation(newReservation)
  };

  const onCancel = () => { navigate(-1) }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let newReservation = Object.assign({}, reservation)
    newReservation.type = reservation.type.toUpperCase()
    newReservation.status = reservation.status.toUpperCase()

    // newReservation.reservation_timestamp = moment(reservation.reservation_timestamp).format('YYYY-MM-DDTHH:mm:ss.000+00:00');
    newReservation.reservation_timestamp = moment(reservation.reservation_timestamp).utc().format();

    PutReservation({ token: token, data: newReservation })
      .then(() => {
        navigate("/reservations");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: reservationFormFields,
    field: {
      onChange: onChange
    },
    data: reservation,
    type: {
      name: 'type',
      options: reservationTypeChoices,
      selected: '',
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    status: {
      name: 'status',
      options: reservationStatusChoices,
      selected: '',
      onChange: onStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    reservation_objects: {
      name: 'reservation_objects',
      options: reservation_objects,
      selected: [],
      onChange: onReservationObjectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: "Reservation edit ",
    data: <ReservationForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Reservation Edit"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))

    axios.all([
      GetReservationById({ token: token, id: reservation_id }),
      GetReservationObjects({ token: token, admin_id: admin_id, location_id: location_id })
    ])
      .then(axios.spread((reservationResponse, reservationobjectResponse) => {
        let reservation = reservationResponse.data
        reservation.reservation_timestamp = moment(reservation.reservation_timestamp).tz(reservation_location?.timezone || 'Europe/Amsterdam').format('YYYY-MM-DDTHH:mm:ss')
        setReservation(reservation)
        dispatch(setReservationObjects(reservationobjectResponse.data))
        dispatch(setLoading(false))
      }))
      .catch(function (error) {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )
};
export default ReservationEdit;
