import axios from "axios";

const GetAllKeycloakUsers = ({token, page=0, payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_KEYCLOAK_ADMIN_URL}/${process.env.REACT_APP_KEYCLOAK_REALM}/users`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
      params: {
        first: page,
        max: 100
      }
    })
    .then(response => {

      let updatedPayload = [...payload];
      if (response.data) {
        updatedPayload = [...payload, ...response.data];
      }

      if (response.data.length !== 0) {
        GetAllKeycloakUsers({
          token: token,
          page: updatedPayload.length,
          payload: updatedPayload,
          resolver: resolver || resolve
        });
      } else {
        if (resolver) resolver(updatedPayload);
        resolve(updatedPayload);
      }
    })
    .catch(error => {
      reject(error);
    });
  });
};

export default GetAllKeycloakUsers