import { Button, Table, Col } from 'react-bootstrap'
import TemplateButtons from "../portal/shelflabels/templatebuttons";
import TemplateModal from "./templateModal";
import './css/productTable.css';
import { useTranslation } from 'react-i18next';

export default function TemplateImagesTable(props) {
  const buttons = TemplateButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const templateImages = props?.images?.templateImages
  const setTemplateImages = props?.images?.setTemplateImages
  const { t: translate } = useTranslation();
  let imageRows = []

  const handleImageRemove = (index) => {
    const newTemplateImages = JSON.parse(JSON.stringify(templateImages))
    newTemplateImages.splice(index, 1)
    setTemplateImages(newTemplateImages);
  };

  for (const [index, image] of Object.entries(templateImages)) {
    imageRows.push(
      <>
        <tr className='product-row' key={index}>
          <td>
            {image?.filename}
          </td>
          <td>{image?.filetype}</td>
          <td>{image?.filesize}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { handleImageRemove(index) }} id={index}>
              {translate('Delete')}
            </Button>
          </td>
        </tr>
      </>
    );
  }

  if (templateImages) {
    return (
      <>
        <Col md={12}>
          <Table hover width='100%'>
            <thead>
              <tr>
                <th>
                  {translate('Name')}
                </th>
                <th>
                  {translate('Filetype')}
                </th>
                <th>
                  {translate('Filesize')}
                </th>
                <th>
                  {translate('Actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {imageRows}
            </tbody>
            <TemplateModal modal={modalview.modal} />
          </Table>
        </Col>
      </>
    );
  } else {
    return (
      <>
        <Col md={12}>
          <Table width='100%'>
            <thead>
              <tr>
                <th>
                  {translate('Name')}
                </th>
                <th>
                  {translate('Filetype')}
                </th>
                <th>
                  {translate('Filesize')}
                </th>
                <th>
                  {translate('Actions')}
                </th>
              </tr>
            </thead>
          </Table>
        </Col>
      </>
    );
  }
}