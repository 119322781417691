import { useSelector, useDispatch } from "react-redux";
import { setInvoice, setInvoicePagination, getInvoiceModalShow, setInvoiceModalShow, setInvoicePagesize, getInvoicePagesize } from "../../redux/dataSlices/invoiceSlice";

import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { InvoicePDF } from "../../pdfcreators/invoicePDF";
import { useState } from "react";
import exportMultipleInvoicePDF from "../../helpers/exportMultipleInvoicePDF";
import GetRelationById from "../../actablueAPI/relations/GetRelationByID";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";

export default function InvoiceButtons() {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const modalShow = useSelector(getInvoiceModalShow)
  const pageSize = useSelector(getInvoicePagesize)
  const hash_locations_all = useSelector(getHashLocationsAll);
  const [selected, setSelected] = useState({})
  const dispatch = useDispatch();

  const onSelect = (object) => {
    if (selected.hasOwnProperty(object.id)) {
      const newSelected = { ...selected };
      delete newSelected[object.id];
      setSelected(newSelected);
    } else {
      const newSelected = { ...selected, [object.id]: object };
      setSelected(newSelected);
    }
  };

  const onSelectAll = (objects) => {
    const allSelected = objects.every(
      (object) => selected.hasOwnProperty(object.id)
    );

    if (allSelected) {
      setSelected({});
    } else {
      const newSelected = { ...selected };

      objects.forEach((object) => {
        newSelected[object.id] = object;
      });

      setSelected(newSelected);
    }
  };

  function onViewClick(invoice) {
    dispatch(setInvoice(invoice));
    dispatch(setInvoiceModalShow(true));
  };

  const onViewHide = (event) => {
    dispatch(setInvoiceModalShow(false));
  };

  const onPaginationClick = (event) => {
    dispatch(setInvoicePagination(event.target.value))
  };

  const onClickExportPdf = async (invoice) => {
    let relation = undefined;
    if (invoice?.relation_id) {
      await GetRelationById({ token: token, id: invoice?.relation_id, admin_id:admin_id })
        .then(response => {
          relation = response?.data;
        })
    }
    InvoicePDF(invoice, hash_locations_all, relation)
  }

  const onExportClick = async () => {
    let relations_hash = [];

    for (const invoice of Object.values(selected)) {
      if (invoice?.relation_id) {
        await GetRelationById({ token: token, id: invoice?.relation_id, admin_id:admin_id })
          .then(response => {
            relations_hash[response?.data?.id] = response?.data;
          })
      }
    }
    exportMultipleInvoicePDF(selected, hash_locations_all, relations_hash, dispatch)
  }

  const onProductPageSizeChange = (event) => {
    dispatch(setInvoicePagesize(event.value))
  }

  let buttons = {
    modalview: {
      tag: 'View',
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide,
        exportPdf: {
          tag: 'PDF',
          onClick: onClickExportPdf
        }
      }
    },
    select: {
      onClick: onSelect,
      selected: selected
    },
    selectAll: {
      onClick: onSelectAll
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },
    export: {
      tag: 'Export to PDF',
      value: 'export',
      onClick: onExportClick
    }
  }

  return buttons
}

