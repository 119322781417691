import { createSlice } from "@reduxjs/toolkit";

export const navbarSlice = createSlice({
  name: "navbar",
  initialState: {
    registration_pagesize:10,
    registration_pagination:10,
    hash_registrations_all:{},
  },
  reducers: {
    setRegistrationId: (state, action) => {
      state.registration_id = action.payload;
    },
    setRegistration: (state, action) => {
      state.registration = action.payload;
    },
    setRegistrations: (state, action) => {
      state.registrations = action.payload
    },
    setRegistrationsAll: (state, action) => {
      state.registrations_all = action.payload
      action?.payload?.forEach(registration => {
        state.hash_registrations_all[registration.id] = registration
      });
    },
    setRegistrationModalShow: (state, action) => {
      state.registration_modalshow = action.payload
    },
    setRegistrationPagination: (state, action) => {
      state.registration_pagination = (action.payload - 1)
    },
    setRegistrationPageSize: (state, action) => {
      state.registration_pagesize = action.payload
    },

  }
});

export const {
  setRegistrationId, setRegistration, setRegistrations, setRegistrationModalShow, setRegistrationPagination, setRegistrationPageSize, setRegistrationsAll,
} = navbarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getRegistrationId = (state) => state.navbar.registration_id
export const getRegistration = (state) => state.navbar.registration
export const getRegistrations = (state) => state.navbar.registrations
export const getRegistrationsAll = (state) => state.navbar.registrations_all
export const getHashRegistrationsAll = (state) => state.navbar.hash_registrations_all
export const getRegistrationModalShow = (state) => state.navbar.registration_modalshow
export const getRegistrationPagination = (state) => state.navbar.registration_pagination
export const getRegistrationPageSize = (state) => state.navbar.registration_pagesize

export default navbarSlice.reducer;