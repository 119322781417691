import { useSelector } from "react-redux"
import { getRole } from "../redux/dataSlices/tokenSlice"

export const AnalyseSelectChoices = () => {
  const role = useSelector(getRole)

  const analyseSelectChoices = [
    { id: 'products', name: 'Products check' },
    { id: 'transaction_processed_by_accounting', name: 'Transaction processed by accounting' }
  ]

  if (role === 'super' || role === 'admin'){
    analyseSelectChoices.push({ id: 'orders', name: 'Orders check' })
    analyseSelectChoices.push({ id: 'double_skucodes_active_false', name: 'Set double skucodes active false' })
    analyseSelectChoices.push({ id: 'orders_is_paid_true', name: 'Set all orders is paid true' })    
    analyseSelectChoices.push({ id: 'delete_non_active_products', name: 'Delete all non active products' })    
    analyseSelectChoices.push({ id: 'transaction_processed_by_accounting', name: 'Transaction processed by accounting' })    
  }
  
  
  return analyseSelectChoices
}
 