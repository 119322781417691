import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BasicCard from "../../components/card";
import ExportForm from "../../components/exportForm";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { exportTypeChoices } from "../../selectfieldchoices/exportselect.mjs";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { ExportFormFields } from "./exportFormFields";
import { getExportModalShow, setExportModalShow } from "../../redux/dataSlices/exportSlice";
import ExportProgressModal from "../../components/exportprogressModal";
import GetAllProducts from "../../actablueAPI/products/GetAllProducts";
import { useNavigate } from "react-router-dom";
import { ProductExport } from "../../exports/productExport";
import useHandleError from "../../customhooks/useHandleError";

const Export = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const modal_show = useSelector(getExportModalShow)
  const HandleError = useHandleError();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exportForm, setExportForm] = useState({})

  const onViewHide = (event) => {
    dispatch(setExportModalShow(false));
  };

  const onExportTypeChange = (event) => {
    let newExportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: exportForm, selectfield: 'exporttype' })
    setExportForm(newExportForm)
  };

  const onExport = () => {
    dispatch(setExportModalShow(true))
    switch (exportForm.exporttype) {
      case 'products':
        GetAllProducts({ type: 'article', token: token, admin_id: admin_id, location_id: location_id, dispatch: dispatch })
          .then(response => {
            ProductExport({ data: response, dispatch: dispatch })
            dispatch(setExportModalShow(false))
          })
          .catch(error => {
            HandleError({ error: error })
            dispatch(setExportModalShow(false))
          })
        break;

      default:
        break;
    }
  };

  const onCancel = () => { navigate(-1) }

  const exportProgressModal = {
    modal: {
      show: modal_show,
      onHide: onViewHide
    }
  }

  const exportFormData = {
    submit: {
      title: 'Export',
      type: 'submit',
      onClick: onExport
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: ExportFormFields(),
    data: exportForm,
    exporttype: {
      name: 'exporttype',
      options: exportTypeChoices,
      // selected: {},
      onChange: onExportTypeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  const card = {
    size: 12,
    title: 'Importing',
    data: location_id ? <ExportForm {...exportFormData} /> : 'Choose location',
  };

  return (
    <>
      <ExportProgressModal {...exportProgressModal} />
      <BasicCard {...card} />
    </>
  )
};

export default Export;