// be aware: if there is a new role added, this has to be implemented in actasmart as well!!

export const roles =
[
  "actasmart:refund",
  "actasmart:settings",
  "actasmart:drawer",
  "actasmart:negative_price",
  "actasmart:negative_quantity",
  "actasmart:product_longpress",
  "actasmart:create_relation",
  "actasmart:relation_longpress",
  "actasmart:eft_longpress",
  "actasmart:eft_cancel",
  "actasmart:discount",
  "actasmart:delete_lines",
  "actasmart:saved_orders",
  "actasmart:saved_order_set_paid",

]

// be aware: if there is a new role added, this has to be implemented in actasmart as well!!