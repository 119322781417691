import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { getRole } from '../redux/dataSlices/tokenSlice';
import './css/modal.css'
import { useTranslation } from 'react-i18next';
import { getRegistration } from '../redux/dataSlices/registrationSlice';
import SubscribeEditTable from './subscribeEditTable';

export default function SubscribeEditModal(props) {
  const object = useSelector(getRegistration);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Edit Subscription: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <SubscribeEditTable {...object} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onCancel}>{translate('Cancel')}</Button>
          <Button onClick={props.modal.onSave}>{translate('Save')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}