import axios from "axios";

const GetCashCountCountAll = ({ token, admin_id, location_id, page, size }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/cashcount/count/all`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id,
        'page': page,
        'size': size
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetCashCountCountAll