export const NotificationApplicationFormFields = () => {

  let notificationApplicationFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name' },
    { type: 'checkbox', name: 'Enabled', id: 'enabled' },

    { type: 'multiselect', name: `Channels`, id: 'channels', formdatafield: 'channels' },
    // { type: 'multiselect', name: `Devices`, id: 'devices', formdatafield: 'devices' },
    // { type: 'multiselect', name: `Emails`, id: 'emails', formdatafield: 'emails' },
    { type: 'multiselect', name: `Events`, id: 'events', formdatafield: 'events' },
  ]

  return notificationApplicationFormFields
}

export const NotificationSubscriptionFormFields = () => {

  let notificationSubscriptionFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    // { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name' },
    { type: 'email', name: 'Email', id: 'to_email' },

    { type: 'select', name: `Channel`, id: 'channel', formdatafield: 'channel' },
    // { type: 'multiselect', name: `Devices`, id: 'devices', formdatafield: 'devices' },
    { type: 'multiselect', name: `Events`, id: 'events', formdatafield: 'events' },
  ]

  return notificationSubscriptionFormFields
}

export const NotificationTemplateFormFields = () => {

  let notificationTemplateFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name' },
    { type: 'file', name: `File`, id: 'file' },
  ]

  return notificationTemplateFormFields
}

export const NotificationChannelsEmailFormFields = () => {

  let notificationChannelsEmailFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name' },
    { type: 'text', name: 'type', id: 'type', required: true  },
    { type: 'email', name: 'from_address', id: 'from_address', required: true  },
    { type: 'text', name: 'username', id: 'username', required: true  },
    { type: 'text', name: 'password', id: 'password', required: true  },
    { type: 'text', name: 'host', id: 'host', required: true  },
    { type: 'text', name: 'port', id: 'port', required: true  },
    { type: 'select', name: `template`, id: 'template', formdatafield: 'template', required: true },
  ]

  return notificationChannelsEmailFormFields
}

export const NotificationReservationTemplateFormFields = () => {

  let notificationApplicationFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name' },
    { type: 'select', name: 'reservation_status', id: 'reservation_status', formdatafield: 'status', required: true },

    { type: 'select', name: `template`, id: 'template', formdatafield: 'template', required: true },
  ]

  return notificationApplicationFormFields
}