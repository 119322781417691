
import { Modal, Col, Row, Button, Tab, Nav } from 'react-bootstrap';
import './css/modal.css'
import CreateFieldTemplate from '../helpers/createFieldTemplate';
import { useSelector } from 'react-redux';
import { getHashRelationsAll, getRelationsAll } from '../redux/dataSlices/relationSlice';

export default function ProductSupplierModalForm(props) {
  const toggleModal = props?.toggleModal
  const onSaveClick = props?.onSaveClick
  const relationsAll = useSelector(getRelationsAll);
  const hash_relations_all = useSelector(getHashRelationsAll);

  if (relationsAll.length > 0 && props?.relation_id) {
    props.relation_id.options = relationsAll.map((relation) => ({
      id: relation.id,
      name: relation.name,
    }));
  }

  if (props?.relation_id) {
    if (props?.data?.relation_id && hash_relations_all[props?.data?.relation_id]) {
      props.relation_id.selected = { 'value': hash_relations_all[props?.data?.relation_id]?.id, 'label': hash_relations_all[props?.data?.relation_id]?.name }
    }
    if (!props?.data?.relation_id) {
      props.relation_id.selected = null
    }
  }

  if (props.data.eans && props.data.hasOwnProperty('eans')) {
    props.data.eans.forEach(ean => {
      props.eans.selected.push({ 'value': ean.ean_code, 'label': ean.ean_code })
      props.eans.options.push({ 'id': ean.ean_code, 'name': ean.ean_code })
    });
  }

  let basicFields = [];
  let formTabs = [];
  let formTabContent = [];

  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {field?.name}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props?.invalidFields?.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateFieldTemplate(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <Row className='ms-1 mt-3 me-1'>
            <Col md={12}>
              <Row key={`fields-row`}>
                {objectFields}
              </Row>
            </Col>
          </Row>
        </Tab.Pane>
      );
    } else {
      field.invalid = props?.invalidFields?.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateFieldTemplate(field, props))
    }
  });

  return (
    <Modal
      show={props?.show}
      size="xl"
      contentClassName='w-100 h-100'
      className='product-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className="product-modal-head" >
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='cst-modal-body'>
        <Tab.Container defaultActiveKey='main'>
          <Nav variant="tabs">
            <Nav.Item><Nav.Link eventKey="main">{'Main'}</Nav.Link></Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <Row className='ms-1 mt-3 me-1'>
                <Col md={12}>
                  <Row key={`fields-row`}>
                    {basicFields}
                  </Row>
                </Col>
              </Row>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleModal}>Close</Button>
        <Button variant="primary" onClick={onSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

}