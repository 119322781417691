import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import TableButton from './tableButton';
import NarrowcastingDevicesButtons from '../portal/narrowcasting/narrowcastingDevicesButtons';
import NarrowcastingDevicesModal from './narrowcastingDevicesModal';

import './css/productTable.css';
import { screenRotationChoices } from '../selectfieldchoices/narrowcastingselect.mjs';
import PaginationAndSizeComponent from './paginationAndSize';

export default function NarrowcastingDevicesTable(props) {
  const devices = props.devices?.items || []
  const pages = props?.devices?.pages || 0
  const page = props?.devices?.page || 0

  const buttons = NarrowcastingDevicesButtons.call()
  const modalview = buttons.hasOwnProperty('modalView') ? Object.assign({}, buttons.modalView) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []
  for (const device of Object.values(devices)) {
    rows.push(
      <tr className='product-row' key={device.id}>
        <td>{device.name}</td>
        <td>{screenRotationChoices.find(element => element.id === device.screen_rotation)?.name}</td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(device) }}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(device) }}>{translate(edit.tag)}</Button>}
          {del && <Button size="sm" type='button' onClick={() => { del.onClick(device) }} id={device.id}>{translate(del.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th width='33%'>{translate('Name')}</th>
            <th width='33%'>{translate('Screen Rotation')}</th>
            <th width='25%'>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <NarrowcastingDevicesModal modal={modalview.modal} />
    </>
  );
}