import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { PrinterFormFields } from "./printerFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import PostPrinter from "../../actablueAPI/kitchenmanager/PostPrinter";
import KmPrinterForm from "../../components/kmPrinterForm";
import { printerTypeChoices } from "../../selectfieldchoices/kitchenmanagerselect.mjs";
import { getHashResellersAll, getResellersAll } from "../../redux/dataSlices/kitchenmanagerSlice";
import { validateFormObject } from "../../helpers/validateFormObject";
import { setLoading } from "../../redux/dataSlices/loadingSlice";


const PrinterAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_resellers_all = useSelector(getHashResellersAll);
  const resellers_all = useSelector(getResellersAll);
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [printer, setPrinter] = useState({ admin_id: admin_id, location_id: location_id, type: 'Sunmi_NT311', active: true });
  const [invalidFields, setInvalidFields] = useState([]);

  const onChange = (event) => {
    let newPrinter = HandleOnChange({ event: event, object: printer })
    setPrinter(newPrinter)
  }

  const onResellerChange = (event) => {
    let newPrinter = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: printer, selectfield: 'reseller_id' })
    setPrinter(newPrinter)
  }

  const onLocationIdChange = (event) => {
    let newPrinter = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: printer, selectfield: 'location_id' })
    setPrinter(newPrinter)
  }

  const onAdminIdChange = (event) => {
    let newPrinter = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: printer, selectfield: 'admin_id' })
    delete newPrinter.location_id
    setPrinter(newPrinter)
  }

  const onTypeChange = (event) => {
    let newPrinter = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: printer, selectfield: 'type' })
    setPrinter(newPrinter)
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: printer, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    PostPrinter({ token: token, data: printer })
      .then(() => {
        navigate("/kitchenmanager/devices");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: PrinterFormFields(),
    field: {
      onChange: onChange
    },
    data: printer,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === printer.admin_id) { return true } }),
      hash: hash_locations_all,
      selected: null,
      onChange: onLocationIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    admin_id: {
      label: 'admin_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: null,
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    reseller_id: {
      label: 'reseller_id',
      options: resellers_all,
      hash: hash_resellers_all,
      selected: null,
      onChange: onResellerChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: printerTypeChoices,
      selected: [],
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Printer add",
    data: <KmPrinterForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Printer add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default PrinterAdd;

