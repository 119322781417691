import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import GuestCheckButtons from '../portal/guestchecks/guestcheckbuttons';
import PaginationComponent from './pagination';
import GuestCheckModal from './guestcheckModal';
import SelectFieldOnchangeWithData from './selectFieldOnChangeWithData';
import { useTranslation } from 'react-i18next';

export default function GuestCheckTable(props) {
  const guestchecks = props.guestchecks || []
  const hashEmployees = props.hashEmployees || []
  const buttons = GuestCheckButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const fix = buttons.hasOwnProperty('fix') ? Object.assign({}, buttons.fix) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  for (const guestcheck of guestchecks) {
    buttons.statusSelectfield.selected = { 'id': guestcheck.state, 'name': guestcheck.state }
    let lastOperater = guestcheck.last_operator_id
    if (lastOperater) {
      if (hashEmployees[lastOperater]) {
        lastOperater = hashEmployees[lastOperater].name
      }
    }
    rows.push(
      <tr className='product-row' key={guestcheck.guestcheck_number}>
        <td>{guestcheck.guestcheck_number}</td>
        {/* <td>{guestcheck.state}</td> */}
        <td><SelectFieldOnchangeWithData selectfield={buttons.statusSelectfield} data={guestcheck}/></td>
        <td>{guestcheck.name}</td>
        <td>{lastOperater}</td>
        <td>{guestcheck.last_order_at ? new Date(guestcheck.last_order_at).toLocaleString() : new Date(guestcheck.last_order_at).toLocaleString()}</td>
        <td align="right">{Number(guestcheck.amount_incl_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(guestcheck) }}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(guestcheck) }}>{translate(edit.tag)}</Button>}
          {fix && <Button size="sm" type='button' onClick={() => { fix.onClick(guestcheck) }}>{translate(fix.tag)}</Button>}
        </td>
      </tr>
    )
  }
  
  const pages = guestchecks?.total_pages
  const page = (guestchecks?.number + 1)

  return (
    <>
      {/* {add && <TableButton {...add} />} */}
      {<PaginationComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Number')}</th>
            <th>{translate('State')}</th>
            <th>{translate('Name')}</th>
            <th>{translate('Last operator')}</th>
            <th>{translate('Last order')}</th>
            <th className="text-end">{translate('Amount')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <GuestCheckModal modal={modalview.modal} />
      </Table>
    </>
  );
}