import { createSlice } from "@reduxjs/toolkit";

export const warehouseSlice = createSlice({
  name: "warehouse",
  initialState: {
    hash_stocks_all: {},
    hash_racks_all: {},
    hash_warehouses_all: {},
    stock_pagesize: 10,
    stock_pagination: 0,
    warehouseAdvicePagesize: 10,
    warehouseAdvicePagination: 0,
  },
  reducers: {
    setStockId: (state, action) => {
      state.stock_id = action.payload;
    },
    setStock: (state, action) => {
      state.stock = action.payload;
    },
    setStocks: (state, action) => {
      state.stocks = action.payload
    },
    setStocksAll: (state, action) => {
      state.stocks_all = action.payload
      // action?.payload?.forEach(stock => {
      //   state.hash_stocks_all[stock.id] = stock
      // });
    },
    setStockModalShow: (state, action) => {
      state.stock_modalshow = action.payload
    },
    setStockPagination: (state, action) => {
      state.stock_pagination = (action.payload - 1)
    },
    setStockPageSize: (state, action) => {
      state.stock_pagesize = action.payload
    },
    setStockTypes: (state, action) => {
      state.stocktypes = action.payload
      action?.payload?.forEach(type => {
        state.hash_stocktypes[type.id] = type
      });
    },
    setStockRefresh: (state, action) => {
      state.stock_refresh = action.payload
    },
    setRackId: (state, action) => {
      state.rack_id = action.payload;
    },
    setRack: (state, action) => {
      state.rack = action.payload;
    },
    setRacks: (state, action) => {
      state.racks = action.payload
    },
    setRacksAll: (state, action) => {
      state.racks_all = action.payload
      action?.payload?.forEach(rack => {
        state.hash_racks_all[rack.id] = rack
      });
    },
    setRackModalShow: (state, action) => {
      state.rack_modalshow = action.payload
    },
    setRackPagination: (state, action) => {
      state.rack_pagination = (action.payload - 1)
    },
    setRackPageSize: (state, action) => {
      state.rack_pagesize = action.payload
    },
    setRackTypes: (state, action) => {
      state.racktypes = action.payload
      action?.payload?.forEach(type => {
        state.hash_racktypes[type.id] = type
      });
    },

    setWarehouseId: (state, action) => {
      state.warehouse_id = action.payload;
    },
    setWarehouse: (state, action) => {
      state.warehouse = action.payload;
    },
    setWarehouses: (state, action) => {
      state.warehouses = action.payload
    },
    setWarehousesAll: (state, action) => {
      state.warehouses_all = action.payload
      action?.payload?.forEach(warehouse => {
        state.hash_warehouses_all[warehouse.id] = warehouse
      });
    },
    setWarehouseModalShow: (state, action) => {
      state.warehouse_modalshow = action.payload
    },
    setWarehousePagination: (state, action) => {
      state.warehouse_pagination = (action.payload - 1)
    },
    setWarehousePageSize: (state, action) => {
      state.warehouse_pagesize = action.payload
    },
    setWarehouseTypes: (state, action) => {
      state.warehousetypes = action.payload
      action?.payload?.forEach(type => {
        state.hash_warehousetypes[type.id] = type
      });
    },
    setWarehouseAdvice: (state, action) => {
      state.warehouseAdvice = action.payload
    },
    setWarehouseAdviceList: (state, action) => {
      state.warehouseAdviceList = action.payload
    },
    setWarehouseAdvicePagination: (state, action) => {
      state.warehouseAdvicePagination = (action.payload - 1)
    },
    setWarehouseAdvicePageSize: (state, action) => {
      state.warehouseAdvicePagesize = action.payload
    },
    setWarehouseAdviceModalShow: (state, action) => {
      state.warehouseAdviceModalshow = action.payload
    },
    setWarehouseAdviceReseller: (state, action) => {
      state.warehouseAdviceReseller = action.payload
    },
    setStockProduct: (state, action) => {
      state.warehouseStockProduct = action.payload
    },
    setStockProductSearch: (state, action) => {
      state.warehouseStockProductSearch = action.payload
    },
    setStockProductFilter: (state, action) => {
      state.warehouseStockProductFilter = action.payload
    },
  }
});

export const {
  setStockId, setStock, setStocks, setStockModalShow, setStockPagination, setStockPageSize, setStocksAll, setStockRefresh,
  setRackId, setRack, setRacks, setRackModalShow, setRackPagination, setRackPageSize, setRacksAll,
  setWarehouseId, setWarehouse, setWarehouses, setWarehouseModalShow, setWarehousePagination, setWarehousePageSize, setWarehousesAll,
  setWarehouseAdviceList, setWarehouseAdvicePageSize, setWarehouseAdvicePagination, setStockProduct,
  setStockProductFilter, setStockProductSearch, setWarehouseAdviceModalShow, setWarehouseAdvice, setWarehouseAdviceReseller
} = warehouseSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getStockId = (state) => state.warehouse.stock_id
export const getStock = (state) => state.warehouse.stock
export const getStocks = (state) => state.warehouse.stocks
export const getStocksAll = (state) => state.warehouse.stocks_all
export const getHashStocksAll = (state) => state.warehouse.hash_stocks_all
export const getStocksHighlights = (state) => state.warehouse.stockshighlights
export const getStockModalShow = (state) => state.warehouse.stock_modalshow
export const getStockPagination = (state) => state.warehouse.stock_pagination
export const getStockPagesize = (state) => state.warehouse.stock_pagesize
export const getStockTypes = (state) => state.warehouse.stocktypes
export const getHashStockTypes = (state) => state.warehouse.hash_stocktypes
export const getStockRefresh = (state) => state.warehouse.stock_refresh

export const getRackId = (state) => state.warehouse.rack_id
export const getRack = (state) => state.warehouse.rack
export const getRacks = (state) => state.warehouse.racks
export const getRacksAll = (state) => state.warehouse.racks_all
export const getHashRacksAll = (state) => state.warehouse.hash_racks_all
export const getRacksHighlights = (state) => state.warehouse.rackshighlights
export const getRackModalShow = (state) => state.warehouse.rack_modalshow
export const getRackPagination = (state) => state.warehouse.rack_pagination
export const getRackPagesize = (state) => state.warehouse.rack_pagesize
export const getRackTypes = (state) => state.warehouse.racktypes
export const getHashRackTypes = (state) => state.warehouse.hash_racktypes

export const getWarehouseId = (state) => state.warehouse.warehouse_id
export const getWarehouse = (state) => state.warehouse.warehouse
export const getWarehouses = (state) => state.warehouse.warehouses
export const getWarehousesAll = (state) => state.warehouse.warehouses_all
export const getHashWarehousesAll = (state) => state.warehouse.hash_warehouses_all
export const getWarehousesHighlights = (state) => state.warehouse.warehouseshighlights
export const getWarehouseModalShow = (state) => state.warehouse.warehouse_modalshow
export const getWarehousePagination = (state) => state.warehouse.warehouse_pagination
export const getWarehousePagesize = (state) => state.warehouse.warehouse_pagesize
export const getWarehouseTypes = (state) => state.warehouse.warehousetypes
export const getHashWarehouseTypes = (state) => state.warehouse.hash_warehousetypes

export const getWarehouseAdviceList = (state) => state.warehouse.warehouseAdviceList
export const getWarehouseAdvicePagination = (state) => state.warehouse.warehouseAdvicePagination
export const getWarehouseAdvicePageSize = (state) => state.warehouse.warehouseAdvicePagesize

export const getStockProduct = (state) => state.warehouse.warehouseStockProduct
export const getStockProductFilter = (state) => state.warehouse.warehouseStockProductFilter
export const getStockProductSearch = (state) => state.warehouse.warehouseStockProductSearch
export const getWarehouseAdviceModalShow = (state) => state.warehouse.warehouseAdviceModalshow
export const getWarehouseAdvice = (state) => state.warehouse.warehouseAdvice
export const getWarehouseAdviceReseller = (state) => state.warehouse.warehouseAdviceReseller

export default warehouseSlice.reducer;