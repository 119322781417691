// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getKeycloakUserModalShow, setKeycloakUser, setKeycloakUserModalShow } from "../../redux/dataSlices/keycloakSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { confirmAlert } from "react-confirm-alert";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import delKeycloakUser from "../../actablueAPI/keycloak/delKeycloakUser";
import GetUserByIdStagingAndLive from "../../actablueAPI/serials/GetUserByIDStagingAndLive";
import PutKeycloakUser from "../../actablueAPI/keycloak/PutKeycloakUser";
import useHandleError from "../../customhooks/useHandleError";
// import PostKeycloakUserLogout from "../../actablueAPI/keycloak/PostKeycloakUserLogout";

export default function KeycloakUserButtons() {
  const token = useSelector(getToken)
  const modalShow = useSelector(getKeycloakUserModalShow)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const HandleError = useHandleError();

  function onViewClick(keycloakUser) {
    dispatch(setKeycloakUser(keycloakUser));
    dispatch(setKeycloakUserModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setKeycloakUserModalShow(false));
  };

  const onclickDisableEnable = (keycloakUser, refresh) => {
    // dispatch(setKeycloakUser(keycloakUser));
    let title = 'Confirm to disable'
    let message = `Are you certain you wish to disable ${keycloakUser.firstName} ${keycloakUser.lastName}?\nThis action cannot be undone, and thus removed permanently.\nPlease confirm your decision before proceeding.`
    if (!keycloakUser.enabled) {
      title = 'Confirm to enable'
      message = `Are you certain you wish to enable ${keycloakUser.firstName} ${keycloakUser.lastName}?\nThis action cannot be undone, and thus removed permanently.\nPlease confirm your decision before proceeding.`
    }
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            PutKeycloakUser(token, keycloakUser.id, {enabled:!keycloakUser.enabled})
            .catch(error => {
              HandleError({ error: error })
            })
            .finally(() => {
              refresh()
              dispatch(setLoading(false))
            })
          }
        },
        {
          label: 'No',
        }
      ]
    });
};

  // const onclickAdd = () => {
  //   navigate("/keycloak/add");
  // };

  const onclickDelete = (keycloakUser, refresh) => {
    // check where this user is connected to.
    // check token
    let admins = ''
    GetUserByIdStagingAndLive({token: token, id: keycloakUser.id})
    .then(responseUserById => {
      if (responseUserById.administrations) {
        for (const admin of responseUserById.administrations) {
          admins = admins.concat(`${admin.name} `)
        }
      }
      confirmAlert({
        title: 'Confirm to remove',
        message: `Are you certain you wish to remove ${keycloakUser.firstName} ${keycloakUser.lastName}?\nThis action cannot be undone, and thus removed permanently.\nThis user is connected to ${admins}\nPlease confirm your decision before proceeding.`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              delKeycloakUser(token, keycloakUser.id)
              .then(response => {
                dispatch(setLoading(false))
                refresh()
              })
              .catch(error => {
                HandleError({ error: error })
                dispatch(setLoading(false))
              })
            }
          },
          {
            label: 'No',
          }
        ]
      });
    })
    .catch(error => {
      HandleError({ error: error })
    })
};

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  // if (scopes.keycloak >= 1) {
  //   buttons.add = {
  //     tag: "Add",
  //     value: 'add',
  //     onClick: onclickAdd,
  //     className: 'add-button'
  //   }
  // }
  if (scopes.keycloak >= 2) {
    buttons.disable = {
      tag: "Disable",
      value: 'disable',
      onClick: onclickDisableEnable
    }
    buttons.enable = {
      tag: "Enable",
      value: 'enable',
      onClick: onclickDisableEnable
    }
  }
  if (scopes.keycloak >= 3) {
    buttons.del = {
      tag: "Delete",
      value: 'del',
      onClick: onclickDelete
    }
  }

  return buttons
}

