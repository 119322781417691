export const relationTypeChoices = [
    { id: 'CUSTOMER', name: 'CUSTOMER' },
    { id: 'B2B', name: 'B2B' },
    { id: 'SUPPLIER', name: 'SUPPLIER' },
  ]

export const relationFilterChoices = [
  { id: 'CUSTOMER', name: 'CUSTOMER' },
  { id: 'SUPPLIER', name: 'SUPPLIER' },
  // { id: 'B2C', name: 'B2C' },
  // { id: 'B2B', name: 'B2B' },
]