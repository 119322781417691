import { useNavigate } from "react-router-dom";
import { getRole } from '../../redux/dataSlices/tokenSlice';
import { useSelector, useDispatch } from "react-redux";
import { setAdministration, getAdministrationModalShow, setAdministrationModalShow } from "../../redux/dataSlices/administrationSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

export default function AdminButtons() {
  const modalShow = useSelector(getAdministrationModalShow)
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(administration) {
    dispatch(setAdministration(administration));
    dispatch(setAdministrationModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setAdministrationModalShow(false));
  };

  const onclickEdit = (administration) => {
    dispatch(setAdministration(administration));
    navigate("/administration/edit");
  };

  const onclickAdd = () => {
    navigate("/administration/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.administrations >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  if (role === 'super' || role === 'admin') {
    buttons.add = {
      tag: "Add",
      value:'add',
      onClick:onclickAdd,
      className:'add-button'
    } 
  }

  return buttons
}

