import { Table } from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import SingleSelectCreateField from './singleselectcreateField';

export default function EmailsEditTable(props) {
  const { t: translate } = useTranslation();
  let rows = []
  let selected = props.emails?.selected ?? []

  if (props.data.hasOwnProperty('emails')) {
    props.data.emails.forEach(element => {
      rows.push(
        <tr key={element.id}>
          <td><Form.Check onChange={props?.emails?.check?.onChange} id={element.id} elementid={element.id}/></td>
          <td></td>
          <td>
            <SingleSelectCreateField name='type' 
              options={props.emails.type.options} 
              onChange={props.emails.type.onChange} 
              clearable={true} 
              searcheable={true} 
              selected={{ 'value': element.type, 'label': element.type }} 
              required={true} 
              id='type' 
              elementid={element.id} prefix='phone_numbers'
            />
          </td>
          <td>
            <Form.Group controlId="formEmail">
              <Form.Control prefix={element.id}
                elementid={element.id}
                type='email'
                name='email'
                placeholder={translate('Email')}
                value={element.email}
                onChange={props?.emails?.onChange}
              />
            </Form.Group>
          </td>
          <td>
          </td>
        </tr>
      )
    });
  }

  return (
    <>
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>
            </th>
            <th></th>
            <th>
              {translate('Type')}
            </th>
            <th>
              {translate('Email')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>

        <Button variant="primary" className='mt-3' type={props.emails.add.type} onClick={props.emails.add.onClick} name={props.emails.add.name}>
          {translate(props.emails.add.title)}
        </Button>
        <Button variant="primary" className='mt-3' type={props.emails.remove.type} onClick={props.emails.remove.onClick} name={props.emails.remove.name} disabled={selected.length === 0 ? true : false}>
          {translate(props.emails.remove.title)}
        </Button>
      </Table>
    </>
  );
}