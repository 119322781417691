import { createSlice } from "@reduxjs/toolkit";

export const analyseSlice = createSlice({
  name: "analyse",
  initialState: {
  },
  reducers: {
    setTaskId: (state, action) => {
      state.task_id = action.payload;
    },
    setTaskData: (state, action) => {
      state.task_data = action.payload
    },
    setProgressModalShow: (state, action) => {
      state.progress_modal_show = action.payload
    },
    setJournalCategoryEventData: (state, action) => {
      state.journalCategoryEventData = action.payload
    },
    setTaskResults: (state, action) => {
      state.taskResults = action.payload
    },
    setActionSpinner: (state, action) => {
      state.actionSpinner = action.payload
    }
  }
});

export const {
  setTaskId,
  setTaskData,
  setProgressModalShow,
  setJournalCategoryEventData,
  setActionSpinner,
  setTaskResults

} = analyseSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getTaskId = (state) => state.analyse.task_id
export const getTaskData = (state) => state.analyse.task_data
export const getProgressModalShow = (state) => state.analyse.progress_modal_show
export const getJournalCategoryEventData = (state) => state.analyse.journalCategoryEventData
export const getActionSpinner = (state) => state.analyse.actionSpinner
export const getTaskResults = (state) => state.analyse.taskResults

export default analyseSlice.reducer;