const AdminFormFields = () => {

  const adminFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: `COC`, id: 'coc' },
    { type: 'select', name: `Country`, id: 'country_code', formdatafield: 'country_code' },
    { type: 'email', name: `Email`, id: 'email' },
    { type: 'text', name: `Company Name`, id: 'company_name' },
    { type: 'text', name: `Website`, id: 'website_url' },
    { type: 'text', name: `Logo URL`, id: 'logo_url' }
  ]

  return adminFormFields
}

export default AdminFormFields