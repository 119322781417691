import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import NotificationApplicationModal from './notificationApplictionModal';
import NotificationButtons from '../portal/notifications/notificationbuttons';

export default function ApplicationTable(applications) {
  const buttons = NotificationButtons.call()
  const modalview = buttons.hasOwnProperty('applicationModalview') ? Object.assign({}, buttons.applicationModalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false

  const { t: translate } = useTranslation();

  let rows = []
  for (let application of Object.values(applications)) {
    rows.push(
      <tr className='product-row' key={application.id}>
        <td>{application.name || "N/A"}</td>
        <td>{JSON.stringify(application.enabled) || "N/A"}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(application) }} id={application.id}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(application, 'application') }} id={application.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick('application') }} >{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Name')}</th>
            <th>{translate('Enabled')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <NotificationApplicationModal modal={modalview.modal} />
      </Table>
    </>
  );
}