import { useSelector, useDispatch } from "react-redux";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { getEJournalModalShow, getEJournalPagesize, setEJournal, setEJournalModalShow, setEJournalPagesize, setEJournalPagination } from "../../redux/dataSlices/eJournalSlice";
import { EftJournalReceiptPDF } from "../../pdfcreators/eftJournalReceiptPDF";

export default function EftJournalButtons() {
  const modalShow = useSelector(getEJournalModalShow)
  const pageSize = useSelector(getEJournalPagesize)
  const dispatch = useDispatch();

  function onViewClick(eftJournalReceipt) {
    dispatch(setEJournal(eftJournalReceipt));
    dispatch(setEJournalModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setEJournalModalShow(false));
  };

  const onPaginationClick = (event) => {
    dispatch(setEJournalPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setEJournalPagesize(event.value))
  }

  const onClickPdf = async (eftJournalReceipt) => {
    EftJournalReceiptPDF(eftJournalReceipt)
  }

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },
    pdf: {
      tag: 'PDF',
      value: 'PDF',
      onClick: onClickPdf
    }

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.

  return buttons
}

