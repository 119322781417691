import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ImportSaveForm from './importsaveForm';
import './css/modal.css'

export default function ImportSaveModal(props) {
  const { t: translate } = useTranslation();

  return (
    <Modal
      show={props.modal.show}
      size="xl"
      dialogClassName="modal-md modal-fullscreen-md-down"
      contentClassName='w-100 h-100'
      className='product-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered scrollable>
      <Modal.Header className="product-modal-head" >
        <Modal.Title className='text-center' id="contained-modal-title-vcenter">
          Save import mapping scheme
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='cst-modal-body'>
        <ImportSaveForm {...props.formData} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.modal.onHide}>{translate('Save')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
