import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RelationButtons from '../portal/relations/relationbuttons';
import PaginationComponent from './pagination';
import PaginationAndSizeComponent from './paginationAndSize';
import RelationModal from './relationModal';


export default function RelationTable(props) {
  const relations = props.relations
  const hash_admins = props.hash_admins
  const hash_users = props.hash_users
  const buttons = RelationButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false

  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  const pages = relations?.total_pages || 0
  const page = (relations?.number + 1) || 0

  let rows = []
  if (relations && relations.content && relations.content.length > 0) {
    for (const object of relations.content) {
      rows.push (
        <tr className='product-row' key={object.id}>
          <td>{object.name}</td>
          <td>{hash_users[object.user_id]?.name || object.user_id}</td>
          <td>{hash_admins[object.administration_id]?.name || object.administration_id}</td>
          <td>{object.type}</td>
          <td>
            <p>
              {translate('Created')}: {new Date(object.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(object.modification).toLocaleString()}
            </p>
          </td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(object) }} id={object.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(object) }} id={object.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Name')}</th>
            <th>{translate('User')}</th>
            <th>{translate('Administration')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Date')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <RelationModal modal={modalview.modal} />
      </Table>
    </>
  );
}
