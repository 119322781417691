import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';
import SortCategoryButtons from '../portal/kitchenmanager/sortcategoryButtons';
import PaginationAndSizeComponent from './paginationAndSize';
import SortCategoryModal from './sortCategoryModal';

export default function SortCategoryTable(props) {
  const sortCategories = props?.sortCategories?.items
  const pages = props?.sortCategories?.pages
  const page = props?.sortCategories?.page

  const buttons = SortCategoryButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (sortCategories) {
    for (const sortCategory of Object.values(sortCategories)) {
      rows.push(
        <tr className='product-row' key={sortCategory.id}>
          <td>{sortCategory?.name ?? 'Unknown'}</td>
          <td>{sortCategory?.sort_index}</td>
          <td>
            {new Date(sortCategory?.creation).toLocaleString()}
          </td>
          <td>
            {new Date(sortCategory?.modification).toLocaleString()}
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(sortCategory) }} id={sortCategory?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(sortCategory) }} id={sortCategory?.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Sort index')}</th>
            <th>{translate('Created on')}</th>
            <th>{translate('Modified at')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <SortCategoryModal modal={modalview.modal} />
    </>
  );
}