import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import GetDevices from "../../actablueAPI/narrowcasting/GetDevices";
import NarrowcastingDevicesTable from "../../components/narrowcastingDevicesTable";
import BasicCard from "../../components/card";
import { getDevicePageSize, getDevicePagination, getDevices, setDevices } from "../../redux/dataSlices/narrowcastingSlice";
import useHandleError from "../../customhooks/useHandleError";

const NarrowcastingDevices = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const devices = useSelector(getDevices);
  const dispatch = useDispatch();
  const pagination = useSelector(getDevicePagination);
  const pagesize = useSelector(getDevicePageSize);
  const HandleError = useHandleError();

  const devicesCard = {
    size: 12,
    title: "Devices",
    data: <NarrowcastingDevicesTable devices={devices} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Narrowcasting"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      GetDevices({ token: token, admin_id: admin_id, location_id: location_id, page: pagination, size: pagesize })
        .then(responseDevices => {
          dispatch(setDevices(responseDevices.data))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, pagesize, pagination]);

  return (
    <>
      <BasicCard {...devicesCard} />
    </>
  );
};
export default NarrowcastingDevices;
