import axios from "axios";

const PostSnelstartCompany = ({token, admin_id, identifier, data}) => {
  return new Promise((resolve, reject) => {
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_ENV}/snelstart/company/${identifier}`,
        headers: {
          'Authorization': `Bearer ${token['access_token']}`,
          'Content-Type': 'application/json',
          'administration': admin_id
        },
        data: data
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      if (error.response?.status === 400) {
        resolve(error.response.data)
      }
      reject(error)
    });
  });
};

export default PostSnelstartCompany