import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import NarrowcastingForm from "../../components/narrowcastingDeviceForm";
import { NarrowcastingFormFields } from "./narrowcastingDeviceFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostDevice from "../../actablueAPI/narrowcasting/PostDevice";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import { getSchedulesAll, getHashSchedulesAll, setSchedulesAll, } from "../../redux/dataSlices/narrowcastingSlice";
import GetAllSchedules from "../../actablueAPI/narrowcasting/GetAllSchedules";
import axios from "axios";
import { screenRotationChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import useHandleError from "../../customhooks/useHandleError";

const NarrowcastingDeviceAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const schedules_all = useSelector(getSchedulesAll)
  const hash_schedules_all = useSelector(getHashSchedulesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Device add"
  const [device, setDevice] = useState({ admin_id: admin_id, location_id: location_id })
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newDevice = HandleOnChange({ event: event, object: device })
    setDevice(newDevice)
  }

  const onChangeSchedules = (event) => {
    let newDevice = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: device, selectfield: 'schedules', hash: hash_schedules_all })
    setDevice(newDevice)
  }

  const onScreenRotationChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'screen_rotation' })
    setDevice(newDevice)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    let newDevice = JSON.parse(JSON.stringify(device))

    PostDevice({ token: token, admin_id: admin_id, device: newDevice })
      .then(() => {
        navigate("/narrowcastingdevices");
      })
      .catch(error => {
        HandleError({ error: error })
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NarrowcastingFormFields(),
    field: {
      onChange: onChange
    },
    data: device,
    schedules: {
      label: 'schedules',
      options: schedules_all,
      onChange: onChangeSchedules,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    screen_rotation: {
      label: 'screen_rotation',
      options: screenRotationChoices,
      onChange: onScreenRotationChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    axios.all([
      GetAllSchedules({ token: token, admin_id: admin_id, location_id: location_id })
    ])
      .then(axios.spread((scheduleResponse) => {
        dispatch(setSchedulesAll(scheduleResponse))
      }))
      .catch(error => {
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingDeviceAdd;

