import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import NarrowcastingForm from "../../components/narrowcastingViewForm";
import { NarrowcastingViewFormFields } from "./narrowcastingViewFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostView from "../../actablueAPI/narrowcasting/PostView";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { contentTypeChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import useHandleError from "../../customhooks/useHandleError";

const NarrowcastingViewAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const navTitle = "View add"
  const [view, setView] = useState({ admin_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newView = HandleOnChange({ event: event, object: view })
    setView(newView)
  }

  const onContentTypeChange = (event) => {
    let newView = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: view, selectfield: 'content_type' })
    setView(newView)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostView({ token: token, admin_id: admin_id, view: view })
      .then(() => {
        navigate("/narrowcastingviews");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NarrowcastingViewFormFields(),
    field: {
      onChange: onChange
    },
    data: view,
    content_type: {
      label: 'content_type',
      options: contentTypeChoices,
      onChange: onContentTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingViewAdd;

