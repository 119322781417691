export default function HandleOnChangeMultiStringArray(props) {
  const event = props.event
  const object = props.object || {}
  const field = props.field
  const change = props.change
  const checkedList = props.checkedList
  const index = event.target?.attributes?.index?.nodeValue

  let newObject = JSON.parse(JSON.stringify(object))

  switch (change) {
    case 'change':
      newObject[field][index] = event.target.value
      break;
    case 'add':
      if (!newObject[field]) {newObject[field] = []}
      newObject[field].push('new')
      break;
    case 'remove':
      if (checkedList) {
        for (const element of checkedList) {
          newObject[field].pop(element)
        }
      }
      break;
    default:
      break;
  }

  return (newObject)
}