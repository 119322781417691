import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import PrinterButtons from "../portal/settings/printerbuttons";
import PrinterModal from "./printerModal";
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

export default function PrinterTable(printers) {
  const buttons = PrinterButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();
  let printerRows = []

  if (printers) {
    for (const printer of Object.values(printers)) {
      const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: printer.id }) : false
      const printerDelete = buttons.hasOwnProperty('delete') ? Object.assign({}, buttons.delete, { id: printer.id }) : false
      const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: printer.id }) : false
      printerRows.push(
        <tr className='product-row' key={printer.id}>
          <td>{printer.type}</td>
          <td>{printer.name}</td>
          <td>{printer.ip}</td>
          <td>{printer.port}</td>
          <td>{printer.dpi}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(printer) }} id={printer.id}>{translate(modalview.tag)}</Button>
            {edit && <TableButton {...edit} />}
            {printerDelete && <TableButton {...printerDelete} />}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Type')}</th>
            <th>{translate('Name')}</th>
            <th>{translate('IP')}</th>
            <th>{translate('Port')}</th>
            <th>{translate('DPI')}</th>
            <th width='25%'></th>
          </tr>
        </thead>
        <tbody>
          {printerRows}
        </tbody>
        <PrinterModal modal={modalview.modal} />
      </Table>
    </>
  );
}