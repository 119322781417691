export default function AddObject(props) {
  const event = props?.event
  const object = props?.object
  const id = props?.id

  let newObject = JSON.parse(JSON.stringify(object))

  if (!newObject.hasOwnProperty(event.target.name)) {
    newObject[event.target.name] = []
  }

  if (id) {
    newObject[event.target.name].push({id: id.toString(), new: true})
  }
  
  return (newObject)
}