import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { SortCategoryFormFields } from "./sortcategoryFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import PostCategory from "../../actablueAPI/kitchenmanager/PostCategory";
import SortCategoryForm from "../../components/sortCategoryForm";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import GetCategory from "../../actablueAPI/kitchenmanager/GetCategory";
import { getSortCategoryId } from "../../redux/dataSlices/kitchenmanagerSlice";
import useHandleError from "../../customhooks/useHandleError";

const SortCategoryEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const category_id = useSelector(getSortCategoryId);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState({})
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newCategory = HandleOnChange({ event: event, object: category })
    setCategory(newCategory)
  }

  const onLocationIdChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'location_id' })
    setCategory(newCategory)
  }

  const onAdminIdChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'admin_id' })
    delete newCategory.location_id
    setCategory(newCategory)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    PostCategory({ token: token, data: category })
    .then(() => {
      navigate("/kitchenmanager/sortcategories");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: SortCategoryFormFields(),
    field: {
      onChange: onChange
    },
    data: category,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === category.admin_id) { return true } }),
      hash: hash_locations_all,
      selected: null,
      onChange: onLocationIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
    admin_id: {
      label: 'admin_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: null,
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true),
      placeholder: !locations_all_admins ? 'Loading...' : 'Select...',
      disabled: !locations_all_admins,
      loading: !locations_all_admins
    },
  };

  const editCard = {
    size: 12,
    title: "Sort-category edit",
    data: <SortCategoryForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Sort-category edit"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetCategory({ token: token, id: category_id, admin_id: admin_id, location_id: location_id })
    .then(response => {
      setCategory(response.data)
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError({ error: error })
      navigate("/kitchenmanager/sortcategories");
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};



export default SortCategoryEdit;

