import { useSelector } from 'react-redux';
import { Modal, Nav, Tab, Button } from 'react-bootstrap';
import { getGuestCheck } from '../redux/dataSlices/guestcheckSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { useTranslation } from 'react-i18next';
import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';
import OrderlineTable from '../components/orderlineTable';
import VatlineTable from './vatlineTable';

export default function GuestCheckModal(props) {
  const object = useSelector(getGuestCheck);
  const role = useSelector(getRole)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Guestcheck: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              {object.order && <Nav.Item><Nav.Link eventKey="order">{translate('Order')}</Nav.Link></Nav.Item>}
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>Number: {object.guestcheck_number}</p>
                      <p>State: {object.state}</p>
                    </div>
                    <div className="col">
                      <p>Administration: {hash_admins_all[object.administration_id]?.name}</p>
                      <p>Location: {hash_locations_all[object.location_id]?.name}</p>
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="order">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <h5>STATUS: </h5>
                      <p>{object.order?.status}</p>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Order Total</h4>
                  <div className="row">
                    <div className="col">
                      <h6>{Number(object.order?.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>Incl. BTW</p>
                    </div>
                    <div className="col">
                      <h6>{Number(object.order?.payment_amount_exclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>Excl. BTW</p>
                    </div>
                    <div className="col">
                      <h6>{Number(object.order?.payment_amount_inclusive_vat - object.order?.payment_amount_exclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </h6>
                      <p>BTW</p>
                    </div>
                  </div>
                </div>
                <br></br>
                <h4>Order Details</h4>
                <OrderlineTable order={object.order} />
                {object.order?.order_lines && <h5>Vat:</h5>}
                {object.order?.order_lines && <VatlineTable {...object.order} />}
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.order_id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}