import axios from "axios";

const PostProductLinkSchap = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/schapkaartjes/link`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: data
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default PostProductLinkSchap