import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import moment from 'moment';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';


export default function PlanningForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('reservation_timestamp') && typeof (props.data.reservation_timestamp) === 'string') {
    let newTimestamp = props.data.reservation_timestamp.slice(0, 16)
    props.data.reservation_timestamp = moment(newTimestamp).format('YYYY-MM-DDTHH:mm')
  }

  if (props.data.hasOwnProperty('status')) {
    props.status.selected = { 'value': props.data.status, 'label': props.data.status }
  }

  // if (props.data.hasOwnProperty('device_id')) {
  //   if (!props.data.device_id) props.device_id.selected = null
  //   if (props.data.device_id) props.device_id.selected = { 'value': props.hash_devices_all[props.data.device_id]?.id, 'label': props.hash_devices_all[props.data.device_id]?.name }
  // }

  if (props.data.hasOwnProperty('planning_objects')) {
    props.planning_objects.selected = { 'value': props.data.planning_objects.id, 'label': props.data.planning_objects.name ? props.data.planning_objects.name : props.data.planning_objects.title }
  }

  // if (props.data.hasOwnProperty('planning_objects')) {
  //   if (!props.planning_objects.selected) { props.planning_objects.selected = [] }
  //   props.data.planning_objects?.forEach(object => {
  //     props.planning_objects.selected.push({ 'value': object?.planning_object_id || object?.id, 'label': object.name })
  //   });
  // }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        embeddedfield.invalid = props.invalidFields.find(invalidField => invalidField.field === embeddedfield?.id) ? true : false;
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      field.invalid = props.invalidFields.find(invalidField => invalidField.field === field?.id) ? true : false;
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form onSubmit={props.submit.onClick}>
        <Tab.Container defaultActiveKey="main">
          {!props.is_assign
            &&
            <Nav variant="tabs">
              <>
                <Nav.Item>
                  <Nav.Link eventKey="main">
                    {translate('Main')}
                  </Nav.Link>
                </Nav.Item>
                {formTabs}
              </>
            </Nav>
          }
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
      {!props.is_assign
        &&
        <>
          <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
          <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
        </>
      }
    </>
  );
}