import axios from "axios";

const PostMatchingTaskProgress = ({ data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENV}/taskprogress`,
      data: data
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  });
};

export default PostMatchingTaskProgress