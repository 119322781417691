
import { Modal, Col, Row, Button } from 'react-bootstrap';

import './css/modal.css'
import CreateFieldTemplate from '../helpers/createFieldTemplate';
import { useSelector } from 'react-redux';
import { getPlanningObjectsAll } from '../redux/dataSlices/planningSlice';


export default function PlanningMoveToDeviceModal(props) {
  const planningObjectsAll = useSelector(getPlanningObjectsAll);
  if (!props.modal) return
  const toggleModal = props?.modal?.toggleModal
  const move = props?.modal?.move

  if (planningObjectsAll) {
    planningObjectsAll?.forEach(planningObject => {
      if (props.modal.data === planningObject.id) {
        props.modal.planning_object_id.selected = { 'value': planningObject.id, 'label': planningObject.name }
      }
      props.modal.planning_object_id.options.push({ 'id': planningObject.id, 'name': planningObject.name })
    });
  }

  let basicFields = []
  props?.modal?.fields?.forEach((field) => {
    basicFields.push(CreateFieldTemplate(field, props.modal))
  });

  return (
    <Modal
      show={props?.modal?.show}
      size="xl"
      contentClassName='w-100 h-100'
      className='product-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className="product-modal-head" >
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.modal?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='cst-modal-body'>
        <Row>
          <Col md={2}>
          </Col>
          <Col md={8}>
            <Row key={`fields-row`}>
              {basicFields}
            </Row>
          </Col>
          <Col md={2}>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { toggleModal(false) }}>Close</Button>
        <Button variant="primary" onClick={move}>
          {props?.modal?.tag}
        </Button>
      </Modal.Footer>
    </Modal>
  );

}