import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';
import DrawersButtons from '../portal/cashcount/drawerbuttons';
import DrawerModal from './drawerModal';
import PaginationAndSizeComponent from './paginationAndSize';

export default function DrawersTable(props) {
  const drawers = props?.drawers?.items
  const pages = props?.drawers?.pages
  const page = props?.drawers?.page

  const buttons = DrawersButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if(drawers) {
    for (const drawer of Object.values(drawers)) {
      rows.push(
        <tr className='product-row' key={drawer.id}>
          <td>{drawer?.name ?? 'Unknown Drawer'}</td>
          <td>{drawer?.count_as}</td>
          <td>{Number(drawer?.default_amount).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          <td>
              {new Date(drawer?.creation).toLocaleString()}
          </td>
          <td>
              {new Date(drawer?.modification).toLocaleString()}
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(drawer) }} id={drawer?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(drawer) }} id={drawer?.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <Button className={add.className}  size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Count as')}</th>
            <th>{translate('Default amount')}</th>
            <th>{translate('Created on')}</th>
            <th>{translate('Modified at')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <DrawerModal modal={modalview.modal} />
    </>
  );
}