import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const RelationFormFields = () => {
  const role = useSelector(getRole)

  const person = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true, prefix: 'person' },
    { type: 'text', name: `Full name`, id: 'full_name', prefix: 'person' },
    { type: 'text', name: `Formal`, id: 'formal', prefix: 'person' },
    { type: 'text', name: `Initials`, id: 'initials', prefix: 'person' },
    { type: 'text', name: `First name`, id: 'first_name', prefix: 'person' },
    { type: 'text', name: `Middle name`, id: 'middle_name', prefix: 'person' },
    { type: 'text', name: `Surname`, id: 'surname', prefix: 'person' },
    { type: 'text', name: `Gender`, id: 'gender', prefix: 'person' },
  ]

  const relationFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'keycloak_id', id: 'keycloak_id', readonly: true },
    { type: 'hidden', name: 'user_id', id: 'user_id', readonly: true },
    { type: 'hidden', name: 'ccv_id', id: 'ccv_id', readonly: true },

    { type: 'text', name: `Name`, id: 'name' },
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'text', name: `Website`, id: 'website_url' },
    { type: 'text', name: `COC`, id: 'coc' },
    { type: 'text', name: `IBAN`, id: 'iban' },
    { type: 'text', name: `BIC`, id: 'bic' },
    { type: 'text', name: `VAT number`, id: 'vat_number' },
    // { type: 'text', name: `ION`, id: 'ion' },

    { type: 'object', name: `Person`, id: 'person', fields: person },
  ]

  if (role === 'super' || role === 'admin') {
    relationFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id' },
    )
    // relationFormFields.splice(3, 1, // overwrite index 3
    //   { type: 'select', name: 'User', id: 'user_id', formdatafield: 'user_id' },
    // )
  }

  return relationFormFields

}
