import * as XLSX from 'xlsx/xlsx.mjs';
import { setTaskData } from "../redux/dataSlices/analyseSlice";
import { setProgressModalShow } from "../redux/dataSlices/analyseSlice";

export const AnalyseExport = ({ exportData, fileName, dispatch }) => {
  dispatch(setProgressModalShow(true))
  const { exportLines, dummy } = _createExportLines(exportData, dispatch)

  // first object
  let firstObject = exportLines[0]
  let newFirstObject = {}
  for (const key of Object.keys(dummy)) {
    newFirstObject[key] = firstObject[key]
  }

  exportLines[0] = Object.assign({}, newFirstObject)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(exportLines), 'sheet1')
  XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });

  dispatch(setProgressModalShow(false))

};

function _createExportLines(exportData, dispatch) {
  const exportLength = exportData.length
  let current = 1
  let dummy = {}
  let exportLines = []

  for (const product of exportData) {
    let task_data = {
      current: current,
      total: exportLength,
      progress: current === 0 ? 0 : current / exportLength
    }
    dispatch(setTaskData(task_data))

    let newLine = {}
    newLine.id = product.id
    newLine.administration_id = product.administration_id
    newLine.location_id = product.location_id
    newLine.sku = product.sku_code
    newLine.plu = product.plu_code
    newLine.name = product.name
    newLine.active = product.active
    newLine.type = product.type
    // newLine.input_required = product.input_required
    // newLine.is_weighted = product.is_weighted

    // add all productpage
    let productpageCounter = 1
    if (product.categories) {
      for (const category of product.categories) {
        if (category.type === 'JOURNAL') {
          _addJournal(newLine, category)
        }
        if (category.type === 'PRODUCTPAGE') {
          _addProductpage(newLine, category, productpageCounter)
          productpageCounter = productpageCounter + 1
        }
      }
    }

    // create longest dummy
    if (Object.keys(newLine).length > Object.keys(dummy).length) {
      dummy = Object.assign({}, newLine)
    }
    exportLines.push(newLine)

    current = current + 1
  }

  return ({ exportLines: exportLines, dummy: dummy })
}

function _addJournal(baseLine, category) {
  baseLine[`Journal id`] = category.id
  baseLine[`Journal name`] = category.title
}

function _addProductpage(baseLine, category, productpageCounter) {
  baseLine[`Productpage id ${productpageCounter}`] = category.id
  baseLine[`Productpage name ${productpageCounter}`] = category.title
}