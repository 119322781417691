import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

import PaginationAndSizeComponent from './paginationAndSize';
import { useSelector } from 'react-redux';
import { getRole } from '../redux/dataSlices/tokenSlice';
import ResellerButtons from '../portal/kitchenmanager/resellerButtons';
import KmResellerModal from './kmResellerModal';

export default function KmResellerTable(props) {
  const resellers = props?.resellers?.items || props?.resellers || []
  const pages = props?.resellers?.pages
  const page = props?.resellers?.page
  const role = useSelector(getRole);

  const buttons = ResellerButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const del = buttons.hasOwnProperty('delete') ? Object.assign({}, buttons.delete) : false
  const { t: translate } = useTranslation();
  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (resellers) {
    for (const reseller of Object.values(resellers)) {
      rows.push(
        <tr className='product-row' key={reseller.id}>
          <td>{reseller?.name || reseller?.id}</td>
          <td>{reseller?.sunmi_app_id || 'None'}</td>
          <td>{reseller?.sunmi_app_key || 'None'}</td>
          <td>
            <p>
              {translate('Created')}: {new Date(reseller?.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(reseller?.modification).toLocaleString()}
            </p>
          </td>
          <td>
            {(modalview && (role === 'super' || role === 'admin')) && <Button size="sm" type='button' onClick={() => { modalview.onClick(reseller) }} id={reseller?.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(reseller) }} id={reseller?.id}>{translate(edit.tag)}</Button>}
            {(del && (role === 'super' || role === 'admin')) && <Button size="sm" type='button' onClick={() => { del.onClick(reseller) }} id={reseller.id}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {(role === 'super' || role === 'admin') && <PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {(add && (role === 'super' || role === 'admin')) && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      {(add && (role === 'reseller' && resellers.length < 1)) && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Sunmi App ID')}</th>
            <th>{translate('Sunmi App Key')}</th>
            <th>
              {translate('Date')}
            </th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <KmResellerModal modal={modalview.modal} />
    </>
  );
}