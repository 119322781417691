export const MatchingProductCatgoryFormFields = () => {
  const matchingProductCatgoriesFormFields = [
    { type: 'select', name: `Product Category`, id: 'productcategory', formdatafield: 'productcategory' },
    { type: 'select', name: `Journal Category`, id: 'journalcategory', formdatafield: 'journalcategory' },
  ]

  return matchingProductCatgoriesFormFields
}


export const MatchingProductCatgoriesFormFields = () => {

  const matchingProductCatgoriesFormFields = [
    { type: 'select', name: `Journal Category`, id: 'journalcategory', formdatafield: 'journalcategory' },
  ]

  return matchingProductCatgoriesFormFields
}


export const MatchingAllProductsFormFields = () => {

  const matchingAllProductsFormFields = [
    { type: 'select', name: `Journal Category`, id: 'journalcategory', formdatafield: 'journalcategory' },
  ]

  return matchingAllProductsFormFields
}
