import { Line } from 'react-chartjs-2';
import moment from "moment";
import { graphColor } from '../defaultcolorvalues/graphColors.mjs';

export const statusReportMultipleDaysGraph = (report, startDate, endDate) => {
  let graphCardData = <p>No data</p>;

  const start = moment.unix(startDate);
  const end = moment.unix(endDate);

  // Initialize data arrays with zeros
  let newCash = Array.from({ length: end.diff(start, 'days') + 1 }, () => 0);
  let newExchange = Array.from({ length: end.diff(start, 'days') + 1 }, () => 0);
  let newNettoCash = Array.from({ length: end.diff(start, 'days') + 1 }, () => 0);
  let newEft = Array.from({ length: end.diff(start, 'days') + 1 }, () => 0);
  let newWebshop = Array.from({ length: end.diff(start, 'days') + 1 }, () => 0);
  let newOnAccount = Array.from({ length: end.diff(start, 'days') + 1 }, () => 0);
  let newTotal = Array.from({ length: end.diff(start, 'days') + 1 }, () => 0);
  let graphLabels = [];

  if (report?.days) {
    // Extract keys from the report days
    const keys = Object.keys(report.days);

    // Sort days in order
    keys.sort((a, b) => {
      return new Date(a) - new Date(b);
    });

    const sortedDays = {};
    keys.forEach((key) => {
      sortedDays[key] = report.days[key];
    });

    // Add missing dates to graphLabels
    const currentDate = moment(start);
    while (currentDate.isSameOrBefore(end)) {
      const formattedDate = currentDate.format("YYYY-MM-DD");
      graphLabels.push(formattedDate);
      currentDate.add(1, "day");
    }

    // Add reporting data to the indexes with actual data
    for (let day in sortedDays) {
      const formattedDay = moment(day).format("YYYY-MM-DD");
      const index = graphLabels.indexOf(formattedDay);
      if (index !== -1) {
        const totals = sortedDays[day].totals;
        newCash[index] = totals.cash;
        newExchange[index] = totals.exchange;
        newEft[index] = totals.eft;
        newWebshop[index] = totals.webshop;
        newOnAccount[index] = totals.on_account;
        newTotal[index] = totals.total;
        newNettoCash[index] = totals.cash - totals.exchange;
      }
    }
  }

  const graphDatasets = [
    {
      label: 'Netto Cash',
      data: newNettoCash,
      borderColor: 'rgb(22, 129, 24)',
      backgroundColor: 'rgba(22, 129, 24, 0.5)',
    },
    {
      label: 'Eft',
      data: newEft,
      borderColor: 'rgb(2, 149, 225)',
      backgroundColor: 'rgba(2, 149, 225, 0.5)',
    },
    {
      label: 'Webshop',
      data: newWebshop,
      borderColor: 'rgb(255, 255, 0)',
      backgroundColor: 'rgba(255, 255, 225, 0.5)',
    },
    {
      label: 'On Account',
      data: newOnAccount,
      borderColor: 'rgb(255, 0, 0)',
      backgroundColor: 'rgba(255, 0, 0, 0.5)',
    },
    {
      label: "Total",
      data: newTotal,
      borderColor: `rgba(${graphColor.r}, ${graphColor.g}, ${graphColor.b}, 1)`,
      backgroundColor: `rgba(${graphColor.r}, ${graphColor.g}, ${graphColor.b}, 0.5)`,
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const data = {
    labels: graphLabels,
    datasets: graphDatasets,
  };

  graphCardData = (
    <div className="report-chart">
      <Line options={options} data={data} />
    </div>
  );

  return graphCardData;
};
