import axios from "axios";

const GetAllViews = ({token, admin_id, location_id, size=50, page=1, payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/narrowcasting/view/all`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json',
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id,
        'page' : page,
        'size' : size
      }
    })
    .then(response => {
      let updatedPayload = [...payload];
      if (response.data.items) {
        updatedPayload = [...payload, ...response.data.items];
      }

      if (response.data.pages !== response.data.page && response.data.pages !== 0) {
        const nextPage = response.data.page+1
        GetAllViews({
          token: token,
          admin_id: admin_id,
          location_id: location_id,
          page: nextPage,
          payload: updatedPayload,
          resolver: resolver || resolve
        });
      } else {
        if (resolver) resolver(updatedPayload);
        resolve(updatedPayload);
      }
    })
    .catch(error => {
      reject(error)
    });
    });
  };

export default GetAllViews