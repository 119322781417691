import React from "react";
import { useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import logoRestaurantPort from "../static/logoRestaurantPort.svg";
import Button from "react-bootstrap/Button";
import './signup.css'


export default function Signup () {
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const onUsernameChange = (event) => {
    setUser(event.target.value)
  }; 
  const onPasswordChange = (event) => {
    setPassword(event.target.value)
  }; 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = () => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_BASE_ENV.concat("/login"),
      data: {'username':user, 'password':password}
    })
    .then(response => {
      if (response.data.status_code === 401) {
        navigate("/");  
      }
      navigate("/dashboards");
    })
  };

  const propsData = {
    actions: {
      disabled: false,
      active: true,
      size: "lg",
      children: "Sign up",
      onClick: onSubmit,
    }
  };

      return (
        <div className="row vh-100 m-0">
          <div className="col-sm-6 col-lg-4 m-auto align-middle">
            <div>
              <img className="mx-auto d-block mb-5" src={logoRestaurantPort} alt=''/>
              <div className="text-center mb-4">
                    <h4 className="form-title">Create an account</h4>
                    <p className="subtext">Get started in Humbee!</p>
              </div>
            </div>
            <form onSubmit={onSubmit}>
                <input className="username mb-4 form-control" type="text" placeholder="Enter your name" />
                <input className="username mb-4 form-control" type="email" placeholder="Enter your email" onChange={onUsernameChange} />
                <div className="input-group mb-4">
                    <input className="password form-control" type={showPassword ? "text" : "password"} placeholder="Password" onChange={onPasswordChange} />
                    <button className="showhide-button" type="button" onClick={togglePasswordVisibility}>
                        <i className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}></i>
                    </button>
                </div>
                <div>
                    <Button className="actions-instance rounded-pill text-white py-1 mt-1" {...propsData.actions} />
                </div>
            </form>
            <div className="form-footer">
              <p className="subtext">Already have an account? </p>
              <a href="/" className="link-text">Log in</a>
            </div>
          </div>
        </div>
    );
    
};
