import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getRelations, setRelations, getRelationPagination, getRelationPagesize, getRelationFilter, setRelationFilter, getRelationSearch, setRelationSearch } from "../../redux/dataSlices/relationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getHashUsersAll, setUsersAll } from "../../redux/dataSlices/userSlice";
import { getHashAdministrationsAll, setAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import BasicCard from "../../components/card"
import RelationTable from "../../components/relationTable";
import { useTranslation } from "react-i18next";
import GetRelationsListAll from "../../actablueAPI/relations/GetRelationsListAll";
import GetUsersList from "../../actablueAPI/serials/GetUsersList";
import GetSerialsList from "../../actablueAPI/serials/GetSerialsList";
import SearchAndFilter from "../../components/searchAndFilter";
import { relationFilterChoices } from "../../selectfieldchoices/relationselect.mjs";
import PostRelationSearch from "../../actablueAPI/relations/PostRelationsSearch";
import GetRelationsByType from "../../actablueAPI/relations/GetRelationsByType";
import useHandleError from "../../customhooks/useHandleError";

const Relations = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const relations = useSelector(getRelations)
  const pagination = useSelector(getRelationPagination);
  const pageSize = useSelector(getRelationPagesize)
  const hash_admins = useSelector(getHashAdministrationsAll)
  const hash_users = useSelector(getHashUsersAll)
  const filter = useSelector(getRelationFilter)
  const search = useSelector(getRelationSearch)
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false)
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();


  function toggleRefresh() {
    setRefresh(!refresh)
  }

  const onSearchChange = (event) => {
    dispatch(setRelationSearch(event.target.value))
  }

  const onSearchKeyDown = (key) => {
    if (key.keyCode === 13) {
      onSearchClick()
    }
  }

  const onSearchClick = () => {
    if (search !== '') {
      let searchDataObject = {
        'search': search,
        // 'type': filter,
        'fields': ['name', 'coc', 'iban', 'bic', 'place', 'street', 'email', 'vatNumber', 'zipCode'],
        'limit': 25
      }
      if (filter) {
        searchDataObject.type = filter
      }
      PostRelationSearch({token:token, admin_id:admin_id, data:searchDataObject})
      .then(searchResponse => {
        let newProducts = { 'content': [] }
        newProducts.content = [...searchResponse.data]
        dispatch(setRelations(newProducts))
      })
      .catch(error => {HandleError({ error: error })})
      .finally (() => {dispatch(setLoading(false))})
    }
    if (search === '') {
      GetRelationsWithFilter()
    }
  }

  const onFilterChange = (event) => {
    if (event) {
      dispatch(setRelationFilter(event.value))
    } else {
      dispatch(setRelationFilter(''))
    }
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
      onClick: onSearchClick,
      onKeyDown: onSearchKeyDown,
    },
    filter: {
      label: 'Filter by:',
      selected: filter ? { 'id': filter, 'name': filter } : {},
      options: relationFilterChoices,
      onChange: onFilterChange,
      clearable: Boolean(true)
    }
  }

  const relationsCard = {
    size: 12,
    title: admin_id ? translate("Relations from admin", {admin: hash_admins[admin_id]?.name}) : "Relations from you",
    data: <RelationTable relations={relations} hash_admins={hash_admins} hash_users={hash_users}/>,
  };

  const GetRelationsWithFilter = () => {
    dispatch(setLoading(true))
    GetRelationsByType({token:token, admin_id:admin_id, page:pagination, size:pageSize, type:filter})
    .then(response => {dispatch(setRelations(response.data))})
    .catch(error => {HandleError({ error: error })})
    .finally(() => {dispatch(setLoading(false))})
  }

  useEffect(() => {
    dispatch(setNavBarTitle("Relations"))
    getDataFromApi()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    onSearchClick()
    // eslint-disable-next-line
  }, [, admin_id, filter, pagination, pageSize]);

  function getDataFromApi() {
    axios.all([
      GetUsersList({token:token}),
      GetSerialsList({token:token})
    ])
    .then(axios.spread((userResponse, administrationResponse) => {
      dispatch(setUsersAll(userResponse.data))
      dispatch(setAdministrationsAll(administrationResponse.data))
      dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError({ error: error })
      dispatch(setLoading(false))
    }) 
  }
  return (
    <>
      <SearchAndFilter {...searchAndFilter} />
      <BasicCard {...relationsCard} />
    </>
  )
};
export default Relations;
