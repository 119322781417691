import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import NarrowcastingScheduleForm from "../../components/narrowcastingScheduleForm";
import { NarrowcastingScheduleFormFields } from "./narrowcastingScheduleFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSchedule, setSchedule, getPlaylistsAll, getHashPlaylistsAll, setPlaylistsAll } from "../../redux/dataSlices/narrowcastingSlice";
import PostSchedule from "../../actablueAPI/narrowcasting/PostSchedule";
import GetAllPlaylists from "../../actablueAPI/narrowcasting/GetAllPlaylists";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import { dayChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import useHandleError from "../../customhooks/useHandleError";

const NarrowcastingScheduleEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const schedule = useSelector(getSchedule)
  const playlists_all = useSelector(getPlaylistsAll)
  const hash_playlists_all = useSelector(getHashPlaylistsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Schedule edit"
  const HandleError = useHandleError();


  const onChange = (event) => {
    let newSchedule = HandleOnChange({ event: event, object: schedule })
    dispatch(setSchedule(newSchedule))
  }

  const onHandleOnChangeMultiSelectFieldWithObjects = (event) => {
    let newSchedule = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: schedule, selectfield: 'playlists', hash: hash_playlists_all })
    dispatch(setSchedule(newSchedule))
  }

  const onDayChange = (event) => {
    let newSchedule = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: schedule, selectfield: 'day' })
    dispatch(setSchedule(newSchedule))
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostSchedule({ token: token, admin_id: admin_id, schedule: schedule })
      .then(() => {
        navigate("/narrowcastingschedules");
      })
      .catch(error => {
        HandleError({ error: error })
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NarrowcastingScheduleFormFields(),
    field: {
      onChange: onChange
    },
    data: schedule,
    playlists: {
      label: 'playlists',
      options: playlists_all,
      onChange: onHandleOnChangeMultiSelectFieldWithObjects,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    day: {
      label: 'day',
      options: dayChoices,
      onChange: onDayChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingScheduleForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    GetAllPlaylists({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        console.log("response", response)
        dispatch(setPlaylistsAll(response))
      })
      .catch(error => {
        HandleError({ error: error })
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingScheduleEdit;

