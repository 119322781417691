import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { getTransactions, getTransactionsHighlights, setTransactions, setTransactionsHighLights, getTransactionPagination, getTransactionPagesize, getTransactionsPageSettings, setTransactionsPageSettings } from "../../redux/dataSlices/transactionSlice";
import { setStartDate, setEndDate, getStartDate, getEndDate } from "../../redux/dataSlices/reportingDateSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import axios from "axios";
// import { useTranslation } from 'react-i18next';
import BasicCard from "../../components/card"
import Datepicker from "../../components/datepicker";
import moment from "moment"
import TransactionTable from "../../components/transactionTable";
import HighlightsCard from "../../components/HighlightsCard";
import HighlightTable from "../../components/highlightTable";
import TransactionHighLightCalculation from "./highlightcalculation"
import WeatherWidget from "../../components/WeatherWidget";
import { getSnelstartCompany, setSnelstartCompany } from "../../redux/dataSlices/snelstartSlice";
import { setDevicesAll } from "../../redux/dataSlices/deviceSlice";
import TableButton from "../../components/tableButton";
import GetSnelstartCompany from "../../actablueAPI/snelstart/GetSnelstartCompany";
import GetDevicesVectron from "../../actablueAPI/devices/GetDevicesVectron";
import GetDevicesSmartpos from "../../actablueAPI/devices/GetDevicesSmartpos";
import { filterOriginChoices, filterProcessedChoices } from "../../selectfieldchoices/transactionselect.mjs";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import { getHashEmployeesAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import GetAllTransactionsSearchV2Minimal from "../../actablueAPI/invoicepayments/GetAllTransactionsSearchV2Minimal";
import useHandleError from "../../customhooks/useHandleError";
import PostTransactionSearch from "../../actablueAPI/invoicepayments/PostTransactionSearch";

const Transactions = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const transactions = useSelector(getTransactions);
  const transactionshighlights = useSelector(getTransactionsHighlights);
  const snelstartCompany = useSelector(getSnelstartCompany)
  const hashEmployees = useSelector(getHashEmployeesAll)
  const role = useSelector(getRole)
  const pagination = useSelector(getTransactionPagination)
  const pageSize = useSelector(getTransactionPagesize)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const scopes = useSelector(getScopes)
  const settings = useSelector(getTransactionsPageSettings)
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  // const [snelstart, setSnelstart] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [filtered, setFiltered] = useState()
  const [search, setSearch] = useState()
  const [filterOrigin, setFilterOrigin] = useState()
  const [filterStatus] = useState()
  const [processed, setProcessedStatus] = useState()
  const [progressEnabled, setProgressEnabled] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(0);
  // const { t: translate } = useTranslation();

  const snelstart = Snelstart()

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    setSearch(value)
    SearchAndFilter({ origin: filterOrigin, status: filterStatus, search: value, processed: processed, transactions: transactions })
  }

  const onFilterOriginChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilterOrigin(value)
    SearchAndFilter({ origin: value, status: filterStatus, search: search, processed: processed, transactions: transactions })
  }

  const onFilterProcessedChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setProcessedStatus(value)
    SearchAndFilter({ origin: filterOrigin, status: filterStatus, search: search, processed: value, transactions: transactions })
  }

  function SearchAndFilter(props) {
    if (props.transactions) {
      if (!props.origin && !props.status && !props.search && !props.processed) { return setFiltered() }

      let filtered = transactions

      if (props.origin) { filtered = filtered.filter(transaction => transaction.type === props.origin.toUpperCase()) }

      if (props.status) { filtered = filtered.filter(transaction => transaction.status === props.status.toUpperCase()) }

      if (props.search) { filtered = filtered.filter(transaction => JSON.stringify(transaction).toLowerCase().includes(props.search.toLowerCase())) }

      if (props.processed || props.processed === 'False') {
        let isProcessed = false
        if (props.processed === 'True') { isProcessed = true }
        filtered = filtered.filter(transaction => transaction.is_processed_by_accounting === isProcessed)
      }

      return setFiltered(filtered)
    }
    return setFiltered()
  }

  function Snelstart() {
    if (scopes.snelstart >= 0 && snelstartCompany && snelstartCompany.administration_id === admin_id && snelstartCompany.location.id === location_id) {
      return true
    }
    return false
  }

  const onRefreshClick = () => {
    setRefresh(!refresh)
  }

  const onMinimalClick = (event) => {
    let newSettings = JSON.parse(JSON.stringify(settings))
    newSettings.minimal = !settings.minimal
    dispatch(setTransactionsPageSettings(newSettings))
  }


  const refreshButton = {
    tag: "Refresh",
    value: 'refresh',
    onClick: onRefreshClick,
    className: 'add-button',
    disabled: progressEnabled
  }

  const onDatePickerChange = (start, end) => {
    dispatch(setStartDate(start.unix()))
    dispatch(setEndDate(end.unix()))
  }

  const datePicker = {
    startDate: moment.unix(startDate),
    endDate: moment.unix(endDate),
    locale: {
      format: "DD/MM/YYYY",
    },
    alwaysShowCalendars: true,
    showDropdowns: true,
    opens: 'right',
    linkedCalendars: false,
    autoApply: true,
    singleDatePicker: false,
    className: 'reporting-date-picker',
    disabled: progressEnabled,
    onChange: onDatePickerChange
  }

  const highlightCard = {
    size: 6,
    title: 'Highlights',
    data: <HighlightTable {...transactionshighlights} />,
  };

  const transactionCard = {
    size: 12,
    title: 'Transactions',
    subtitle: <><Datepicker {...datePicker} /><TableButton {...refreshButton} /></>,
    data: <TransactionTable transactions={filtered || transactions} snelstart={snelstart} employees={hashEmployees} settings={settings} />,
    loadingData: {
      total: total,
      current: current,
      enabled: progressEnabled
    }
  };

  const weatherWidgetCard = {
    size: 6
  };

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    },
    filters: [
      {
        label: 'Origin',
        options: filterOriginChoices,
        onChange: onFilterOriginChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Proccessed',
        options: filterProcessedChoices,
        onChange: onFilterProcessedChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      // {
      //   label: 'Status',
      //   options: filterStatusChoices,
      //   onChange: onFilterStatusChange,
      //   clearable: Boolean(true),
      //   searchable: Boolean(true)
      // }
    ],
    settings:{
      minimal :{
        label: 'minimal',
        onClick: onMinimalClick,
        value: settings.minimal
      }
    }
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Transactions'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setTransactions())
    if (location_id) {
      dispatch(setLoading(true))
      setProgressEnabled(true);
      getTransactionsFromApi()
    }
    if (!location_id) {
      // dispatch(setTransactions())
      setFiltered()
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pageSize, startDate, refresh, settings.minimal]);

  async function getTransactionsFromApi() {
    if (settings.minimal) {
      let combinedTransactionAnalyseResponse = []
      let transactionAnalyseCancelled = false
      let transactionAnalysProgressCounter = 0
      let differenceBetweenStartAndEndDate = Math.ceil(Math.abs(endDate-startDate)/(60*60*24))
      setTotal(differenceBetweenStartAndEndDate)
      let start = new Date(startDate*1000)
      let end = new Date(endDate*1000)
      setProgressEnabled(true)
  
      while (!transactionAnalyseCancelled && (start < end)) {
        setCurrent(transactionAnalysProgressCounter);
        // Calculate the end date for this range
        let endDateRange = new Date(start.valueOf())
        endDateRange.setUTCDate(endDateRange.getUTCDate() + 1)
        if (endDateRange > end) {
          endDateRange = end
        }
  
        // GetAllTransactionsSearch({ token: token, admin_id: admin_id, location_id: location_id, startDate: startDate, signal: signal.current })
        await GetAllTransactionsSearchV2Minimal({ token: token, admin_id: admin_id, location_id: location_id, startDate: start, endDate: endDateRange })
          // eslint-disable-next-line no-loop-func
          .then(responseTransactionMinimal => {
            let combined = JSON.parse(JSON.stringify(combinedTransactionAnalyseResponse))
            combinedTransactionAnalyseResponse = [...combined, ...responseTransactionMinimal]
          })
          // eslint-disable-next-line no-loop-func
          .catch(error => {
            if (error.code !== "ERR_CANCELED") {
              dispatch(setLoading(false))
              HandleError({ error: error })
            }
            transactionAnalyseCancelled = true
            
          })
        // Move the start date to the beginning of the next range
        start = new Date(endDateRange.valueOf())
        transactionAnalysProgressCounter++
      }
      if (!transactionAnalyseCancelled) {
        SearchAndFilter({ origin: filterOrigin, status: filterStatus, search: search, processed: processed, transactions: combinedTransactionAnalyseResponse })
        dispatch(setTransactions(combinedTransactionAnalyseResponse))
        dispatch(setTransactionsHighLights(TransactionHighLightCalculation(combinedTransactionAnalyseResponse)))
        setProgressEnabled(false);
        dispatch(setLoading(false))
      }
    }

    if (!settings.minimal) {
      // check how many days and calculate how many hours 
      let hours = (moment.unix(endDate).diff(moment.unix(startDate), 'days') + 1) * 24
      // get transactions by hour
      let tempTransactions = []
      for (var hour = 0; hour < hours; hour++) {
        setTotal(hours);
        setCurrent(hour);

        const searchObject = {
          date_range: {
            from: moment.unix(startDate).add(hour, 'hour').format(`YYYY-MM-DDTHH:mm:ss.SSSZ`),
            to: moment.unix(startDate).add(hour + 1, 'hour').format(`YYYY-MM-DDTHH:mm:ss.SSSZ`),
            field: "creation"
          },
          limit: 999
        }
        await PostTransactionSearch({ token: token, admin_id: admin_id, location_id: location_id, searchObject: searchObject })
          // eslint-disable-next-line no-loop-func
          .then(response => {
            tempTransactions = [...tempTransactions, ...response.data]
          })
          .catch(error => {
            HandleError({ error: error })
          })
      }
      setProgressEnabled(false);
      SearchAndFilter({ origin: filterOrigin, status: filterStatus, search: search, processed: processed, transactions: tempTransactions })
      dispatch(setTransactions(tempTransactions))
      dispatch(setTransactionsHighLights(TransactionHighLightCalculation(tempTransactions)))
    }
    
    axios.all([
      GetSnelstartCompany({ token: token, admin_id: admin_id, location_id: location_id }),
      GetDevicesVectron({ token: token, admin_id: admin_id }),
      GetDevicesSmartpos({ token: token, admin_id: admin_id }),
      GetEmployees({ token: token, admin_id: admin_id })
    ])
      .then(axios.spread((responseSnelstart, responseDevicesVectron, responseDevicesSmartpos, responseEmployees) => {
        dispatch(setSnelstartCompany(responseSnelstart.data))
        dispatch(setDevicesAll([...responseDevicesVectron.data, ...responseDevicesSmartpos.data]))
        dispatch(setEmployeesAll(responseEmployees.data))
        dispatch(setLoading(false))
      }))
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
  }

  return (
    <>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
      <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
      <WeatherWidget {...weatherWidgetCard} />
      <HighlightsCard {...highlightCard} />
      <SearchAndMultiFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...transactionCard} />
    </>
  )
};
export default Transactions;
