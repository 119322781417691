export default function ChangeHashObjectsKeyValueMulti(hash_objects, keys) {
    let newObject = JSON.parse(JSON.stringify(hash_objects))

    for (const [key, value] of Object.entries(keys)) {
      for (const object of Object.values(newObject)) {
        object[key] = value
      }
    }

    return JSON.parse(JSON.stringify(newObject))
}