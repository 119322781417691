import { createSlice } from "@reduxjs/toolkit";

export const qrorderingSlice = createSlice({
  name: "qrordering",
  initialState: {
    data: { 'table_number': "1", 'table_number_range_start': 1, 'table_number_range_end': 1 }
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  }
});

export const {
  setData
} = qrorderingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getData = (state) => state.qrordering.data

export default qrorderingSlice.reducer;