export default function HandleOnChangeCheckbox(props) {
  const event = props.event
  const object = props.object || {}

  const checked = event.target?.checked
  const id = event.target?.attributes?.id?.nodeValue

  // let newObject = Object.assign([], object)
  let newObject = JSON.parse(JSON.stringify(object))
  
  if (checked) {newObject.push(id)}
  if (!checked) {newObject.pop(id)}

  return (newObject)
}


