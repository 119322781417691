export default function HandleOnChangeTemplateElement(props) {
  const event = props.event
  const object = props.object
  const index = props.index

  let newObject = JSON.parse(JSON.stringify(object))
  
  switch (event.target.type) {
    case 'checkbox':
      newObject.elements[index][event.target.id] = event.target.checked ? event.target.checked : false
      break;
    case 'number':
      newObject.elements[index][event.target.id] = (event.target.value === null || event.target.value === "") ? null : Number(event.target.value)
      break;
    default:
      newObject.elements[index][event.target.id] = event.target.value
      break;
  }

  return (newObject)
}


