import { createSlice } from "@reduxjs/toolkit";

export const EJournalSlice = createSlice({
  name: "eJournal",
  initialState: {
    eJournal_pagesize: 10,
    eJournal_pagination: 0,
  },
  reducers: {
    setEJournalId: (state, action) => {
      state.eJournal_id = action.payload;
    },
    setEJournal: (state, action) => {
      state.eJournal = action.payload;
    },
    setEJournals: (state, action) => {
      state.eJournals = action.payload
    },
    setEJournalsHighLights: (state, action) => {
      state.eJournalshighlights = action.payload
    },
    setEJournalModalShow: (state, action) => {
      state.eJournalmodalshow = action.payload
    },
    setEJournalPagination: (state, action) => {
      state.eJournal_pagination = (action.payload - 1)
    },
    setEJournalPagesize: (state, action) => {
      state.eJournal_pagesize = action.payload
    },
  }
});

export const {
  setEJournalId, setEJournal, setEJournals, editEJournals, setEJournalsHighLights, setEJournalModalShow, setEJournalPagination, setEJournalPagesize,
} = EJournalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getEJournalId = (state) => state.eJournal.eJournal_id
export const getEJournal = (state) => state.eJournal.eJournal
export const getEJournals = (state) => state.eJournal.eJournals
export const getEJournalsHighlights = (state) => state.eJournal.eJournalshighlights
export const getEJournalModalShow = (state) => state.eJournal.eJournalmodalshow
export const getEJournalPagination = (state) => state.eJournal.eJournal_pagination
export const getEJournalPagesize = (state) => state.eJournal.eJournal_pagesize

export default EJournalSlice.reducer;