import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getApplication, getHashChannelsEmailsAll, getChannelsEmailsAll, setApplication } from "../../redux/dataSlices/notificationSlice";
import { NotificationApplicationFormFields } from "./notificationsFormFields";
import NotificationApplicationForm from "../../components/notificationApplicationForm";
import { eventChoices } from "../../selectfieldchoices/notificationselect.mjs";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import PostNotificationApplication from "../../actablueAPI/notifications/PostNotificationsApplication";
import { useTranslation } from "react-i18next";

const NotificationApplicationEdit = () => {
  const token = useSelector(getToken);
  const application = useSelector(getApplication)
  const channels_all = useSelector(getChannelsEmailsAll)
  const hash_channels_all = useSelector(getHashChannelsEmailsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const onChange = (event) => {
    let newApplication = HandleOnChange({ event: event, object: application })
    dispatch(setApplication(newApplication))
  }

  const onHandleOnChangeMultiSelectFieldWithObjects = (event) => {
    let newApplication = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: application, selectfield: 'channels', hash: hash_channels_all })
    dispatch(setApplication(newApplication))
  }

  const onHandleOnChangeMultiSelectFieldWithValues = (event) => {
    let newApplication = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: application, selectfield: 'events' })
    dispatch(setApplication(newApplication))
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostNotificationApplication({ token: token, data: application })
      .then(() => {
        navigate("/notifications");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NotificationApplicationFormFields(),
    field: {
      onChange: onChange
    },
    data: application,
    channels: {
      label: 'channels',
      options: channels_all,
      onChange: onHandleOnChangeMultiSelectFieldWithObjects,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    events: {
      label: 'events',
      options: eventChoices,
      onChange: onHandleOnChangeMultiSelectFieldWithValues,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const card = {
    size: 12,
    title: translate('Application Edit', {name: application?.name || "N/A"}),
    data: <NotificationApplicationForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationApplicationEdit;

