import axios from "axios";


const PostImage = ({ token, admin_id, location_id, file }) => {
  const FormData = require('form-data')
  let data = new FormData();
  data.append('file', file)

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/cdn/image`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'multipart/form-data',
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        // ...data.getHeaders()
      },
      data: data
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  });
};

export default PostImage