export const productESLModelChoices = [
    { 'id': 'EE153R', 'name': 'EE153R' },
    { 'id': 'EE213R', 'name': 'EE213R' },
    { 'id': 'EE293R', 'name': 'EE293R' },
    { 'id': 'EE420R', 'name': 'EE420R' },
    { 'id': 'EE750R', 'name': 'EE750R' },
]
export const codeTypeChoices = [
    { 'name': 'ean', 'id': 'ean' },
    { 'name': 'sku', 'id': 'sku' }
]
