import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

const OpticonConnectionFormFields = () => {
  const role = useSelector(getRole)

  let opticonConnectionFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },

    // single option select fields  
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id' },

    // single option fields
    { type: 'text', name: `Server GUID`, id: 'guid' },
    { type: 'checkbox', name: `Use Template`, id: 'use_template' },
    { type: 'checkbox', name: `Use Image`, id: 'use_image' },
  ]

  if (role === 'super' || role === 'admin') {
    opticonConnectionFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id' },
    )
  }

  return opticonConnectionFormFields
}

export default OpticonConnectionFormFields