export const addSubscriptionFormFields = [
  { type: 'select', name: 'registration_id', id: 'registration_id', formdatafield: 'registration_id' },
]

export const createNewSubscriptionFormFields = [
  // single option fields
  { type: 'text', name: 'formal', id: 'formal' },
  { type: 'text', name: 'initials', id: 'initials' },
  { type: 'text', name: 'first_name', id: 'first_name' },
  { type: 'text', name: 'middle_name', id: 'middle_name' },
  { type: 'text', name: 'surname', id: 'surname' },
  { type: 'text', name: 'gender', id: 'gender' },
  { type: 'text', name: 'street', id: 'street' },
  { type: 'number', name: 'street_number', id: 'street_number' },
  { type: 'text', name: 'street_additional_number', id: 'street_additional_number' },
  { type: 'text', name: 'zip_code', id: 'zip_code' },
  { type: 'text', name: 'place', id: 'place' },
  { type: 'text', name: 'proverence', id: 'proverence' },
  { type: 'text', name: 'country', id: 'country' },
  { type: 'email', name: 'email', id: 'email' },
  { type: 'text', name: 'phone_number', id: 'phone_number' },
  { type: 'text', name: 'website_url', id: 'website_url' },
  { type: 'text', name: 'coc', id: 'coc' },
  { type: 'text', name: 'iban', id: 'iban' },
  { type: 'text', name: 'bic', id: 'bic' },
  { type: 'text', name: 'vat_number', id: 'vat_number' },
  // {type:'text', name:'ion', id:'ion'},
  { type: 'select', name: 'country_code', id: 'country_code', formdatafield: 'country_code' },

]

export const copySubscrioptionToFormFields = [
  { type: 'select', name: 'To location', id: 'location_id', formdatafield: 'location_id' },

]