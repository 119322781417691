import axios from "axios";

const GetTemplates = ({ token, admin_id, location_id, base_labels = true, page = 0, size = 10, sorted = true }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/schapkaartjes/template/all"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id,
        'base_labels': base_labels,
        'page': page,
        'size': size
      },
    })
      .then(response => {
        if (!sorted) resolve(response);
        const sortedItems = Object.values(response.data.items)
          .sort((a, b) => b.base_label - a.base_label);

        const newObject = {
          ...response.data,
          items: sortedItems
        };
        resolve(newObject)
      })
      .catch(error => {
        console.error("error:", error)
        reject(error)
      });
  });
};

export default GetTemplates