import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getApplicationModalShow, getChannelsEmailModalShow, getReservationTemplateModalShow, getSubscriptionModalShow, getTemplateModalShow, setApplication, setApplicationModalShow, setChannelsEmail, setChannelsEmailModalShow, setReservationTemplate, setReservationTemplateModalShow, setSubscription, setSubscriptionModalShow, setTemplate, setTemplateModalShow } from "../../redux/dataSlices/notificationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import GetNotificationTemplate from "../../actablueAPI/notifications/GetNotificationTemplate";

export default function NotificationButtons() {
  const application_modal_show = useSelector(getApplicationModalShow)
  const template_modal_show = useSelector(getTemplateModalShow)
  const channels_email_modal_show = useSelector(getChannelsEmailModalShow)
  const subscription_modal_show = useSelector(getSubscriptionModalShow)
  const reservation_template_modal_show = useSelector(getReservationTemplateModalShow)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const scopes = useSelector(getScopes)
  const token = useSelector(getToken)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onApplicationViewClick(application) {
    dispatch(setApplication(application));
    dispatch(setApplicationModalShow(true));
  };

  const onApplicationViewHide = () => {
    dispatch(setApplicationModalShow(false));
  };

  function onSubscriptionViewClick(subscription) {
    console.log("onSubscriptionViewClick - subscription:", subscription);
    dispatch(setSubscription(subscription));
    dispatch(setSubscriptionModalShow(true));
  };

  const onASubscriptionViewHide = () => {
    dispatch(setSubscriptionModalShow(false));
  };

  function onTemplateViewClick(template) {
    let newTemplate = JSON.parse(JSON.stringify(template))
    // get template preview data
    GetNotificationTemplate({ token: token, admin_id: admin_id, location_id: location_id, id: template.id })
      .then((response) => {
        newTemplate.template = response.data
        dispatch(setTemplate(newTemplate));
        dispatch(setTemplateModalShow(true));
      })
      .catch(() => {
        dispatch(setTemplate(template));
        dispatch(setTemplateModalShow(true));
      })
  };

  const onTemplateViewHide = () => {
    dispatch(setTemplateModalShow(false));
  };

  function onReservationTemplateViewClick(reservationtemplate) {
    dispatch(setReservationTemplate(reservationtemplate));
    dispatch(setReservationTemplateModalShow(true));
  };

  const onReservationTemplateViewHide = () => {
    dispatch(setReservationTemplateModalShow(false));
  };

  function onChannelsEmailViewClick(channelsemail) {
    dispatch(setChannelsEmail(channelsemail));
    dispatch(setChannelsEmailModalShow(true));
  };

  const onChannelsEmailViewHide = () => {
    dispatch(setChannelsEmailModalShow(false));
  };

  const onclickEdit = (object, type) => {
    switch (type) {
      case 'application':
        dispatch(setApplication(object));
        navigate("/notification/application/edit");
        break;
      case 'subscription':
        dispatch(setSubscription(object));
        navigate("/notification/subscription/edit");
        break;
      case 'template':
        dispatch(setTemplate(object));
        navigate("/notification/template/edit");
        break;
      case 'channel':
        dispatch(setChannelsEmail(object));
        navigate("/notification/channel/edit");
        break;
      case 'reservationtemplate':
        dispatch(setReservationTemplate(object));
        navigate("/notification/reservationtemplate/edit");
        break;
      default:
        break;
    }

  };

  const onclickAdd = (type) => {
    switch (type) {
      case 'application':
        navigate("/notification/application/add");
        break;
      case 'subscription':
        navigate("/notification/subscription/add");
        break;
      case 'template':
        navigate("/notification/template/add");
        break;
      case 'channel':
        navigate("/notification/channel/add");
        break;
      case 'reservationtemplate':
        navigate("/notification/reservationtemplate/add");
        break;
      default:
        break;
    }
  };

  let buttons = {
    applicationModalview: {
      tag: "View",
      onClick: onApplicationViewClick,
      modal: {
        show: application_modal_show,
        onHide: onApplicationViewHide
      }
    },
    templateModalview: {
      tag: "View",
      onClick: onTemplateViewClick,
      modal: {
        show: template_modal_show,
        onHide: onTemplateViewHide
      }
    },
    reservationTemplateModalview: {
      tag: "View",
      onClick: onReservationTemplateViewClick,
      modal: {
        show: reservation_template_modal_show,
        onHide: onReservationTemplateViewHide
      }
    },
    channelsEmailModalview: {
      tag: "View",
      onClick: onChannelsEmailViewClick,
      modal: {
        show: channels_email_modal_show,
        onHide: onChannelsEmailViewHide
      }
    },
    subscriptionModalview: {
      tag: "View",
      onClick: onSubscriptionViewClick,
      modal: {
        show: subscription_modal_show,
        onHide: onASubscriptionViewHide
      }
    },
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.notifications >= 1) {
    buttons.add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.notifications >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

