import axios from "axios";

const PostTransactionSearchV2Minimal = ({ token, admin_id, location_id, page, searchObject }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/invoicepayments/v2/transaction/search/minimal"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'location': location_id,
        'page': page
      },
      data: searchObject,
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  });
};

export default PostTransactionSearchV2Minimal