import { createSlice } from "@reduxjs/toolkit";

export const deviceSlice = createSlice({
  name: "device",
  initialState: {
    device_pagesize: 10,
    device_pagination: 0,
    hash_devices_all: {},
    hash_devices_uid_all: {}
  },
  reducers: {
    setDeviceId: (state, action) => {
      state.device_id = action.payload;
    },
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = action.payload
    },
    setDevicesAll: (state, action) => {
      state.devices_all = action.payload
      action?.payload?.forEach(device => {
        state.hash_devices_all[device.id] = device
        state.hash_devices_uid_all[device.uid] = device
      });
    },
    setDeviceModalShow: (state, action) => {
      state.device_modalshow = action.payload
    },
    setDevicePagination: (state, action) => {
      state.device_pagination = (action.payload - 1)
    },
    setDevicePagesize: (state, action) => {
      state.device_pagesize = action.payload
    },
  }
});

export const {
  setDeviceId, setDevice, setDevices, setDeviceModalShow, setDevicePagination, setDevicePagesize, setDevicesAll,
} = deviceSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getDeviceId = (state) => state.device.device_id
export const getDevice = (state) => state.device.device
export const getDevices = (state) => state.device.devices
export const getDevicesAll = (state) => state.device.devices_all
export const getHashDevicesAll = (state) => state.device.hash_devices_all
export const getHashDevicesUidAll = (state) => state.device.hash_devices_uid_all
export const getDevicesHighlights = (state) => state.device.deviceshighlights
export const getDeviceModalShow = (state) => state.device.device_modalshow
export const getDevicePagination = (state) => state.device.device_pagination
export const getDevicePagesize = (state) => state.device.device_pagesize

export default deviceSlice.reducer;