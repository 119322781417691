import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import { useTranslation } from 'react-i18next';
import EmployeeRoleButtons from '../portal/employees/employeeRoleButtons';
import EmployeeRoleModal from './employeeRoleModal';
import './css/productTable.css';

export default function EmployeeRoleTable(employees_roles) {
  const buttons = EmployeeRoleButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let rows = []
  for (const employee_role of Object.values(employees_roles)) {
    rows.push(
      <tr className='product-row' key={employee_role.id}>
        <td>{employee_role.name}</td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(employee_role) }} id={employee_role.id}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(employee_role) }} id={employee_role.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <EmployeeRoleModal modal={modalview.modal} />
    </>
  );
}