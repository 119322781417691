import { allScopes, resellerScopes } from "../scopes/scopes.mjs"
import RegistrationIsValid from "./RegistrationIsValid"
import CreateAdminRegistrationHashBasedOnAdminId from "./createAdminRegistrationHashBasedOnLocationId"
import CreateLocationRegistrationHashBasedOnAdminId from "./createLocationRegistrationHashBasedOnAdminId"

export default function CreateSidebar(props) {
  const role = props.role
  const admin_id = props.admin_id
  const location_id = props.location_id
  const registrations = props.registrations
  const userroles = props.userroles
  const token = props.token
  const dispatch = props.dispatch

  let sidebar = {}

  switch (role) {
    case 'super':
      _createSidebar({registrationScopes:allScopes, sidebar:sidebar})
      break;
    case 'admin':
      _createSidebar({registrationScopes:allScopes, sidebar:sidebar})
      break;
    case 'reseller':
      _createSidebar({registrationScopes:resellerScopes, sidebar:sidebar})
      break;
    case 'merchant':
    if (registrations) {
        let registrationSettingsToUse = undefined
        if (!location_id) {
          const hashRegistrationsBasedOnAdminId = CreateAdminRegistrationHashBasedOnAdminId(registrations)
          if (hashRegistrationsBasedOnAdminId && hashRegistrationsBasedOnAdminId.hasOwnProperty(admin_id)) {
            registrationSettingsToUse = hashRegistrationsBasedOnAdminId[admin_id]
          }
        } 

        if (location_id) {
          const hashRegistrationsBasedOnLocationId = CreateLocationRegistrationHashBasedOnAdminId(registrations)
          if (hashRegistrationsBasedOnLocationId && hashRegistrationsBasedOnLocationId.hasOwnProperty(location_id)) {
            registrationSettingsToUse = hashRegistrationsBasedOnLocationId[location_id]
          }
        } 

        if (registrationSettingsToUse) {
          registrationSettingsToUse.registrations.forEach(registration => {
            if (registration.hasOwnProperty('subscription') && registration.subscription.hasOwnProperty('scopes') && RegistrationIsValid({token:token, setting:registration, dispatch:dispatch})) {
              // send scopes from registrations
              _createSidebar({registrationScopes:registration.subscription.scopes, sidebar:sidebar})
            }
          });
          break
        }
      } 
      sidebar['1settings']= []
      sidebar['1settings']['subscribe'] = {'tag': 'subscribe', 'link': 'subscribe'}       
      break; 
    case 'user':
      if (userroles) {
        _createUserSidebar({sidebar:sidebar, userroles:userroles, admin_id:admin_id, location_id:location_id})
      }
      break;
    default:
      break;
  }
  return sidebar
}


function _createSidebar(props) {
  const registrationScopes = props.registrationScopes
  let sidebar = props.sidebar
  for (const scope of registrationScopes.values()) {
    const splitted = scope.split(':')
    _addToSidebar({sidebar:sidebar, splitted:splitted})
  }
  
  return
}

function _createUserSidebar(props) {
  const admin_id = props.admin_id
  const location_id = props.location_id
  const userroles = props.userroles

  let sidebar = props.sidebar
  // let _notNormalLink = false
  
  for (const role of userroles) {
    // console.log('role', role)
    const splitted = role.split(':')
    const type = splitted.at(0)
    const roleAdminId = splitted.at(1)
    const roleLocationId = splitted.at(2)

    if (type === 'portal' && roleAdminId === admin_id) {
      if (location_id && roleLocationId === location_id) {
        _addToSidebar({sidebar:sidebar, splitted:splitted})
      }

      // if (!location_id) {
      //   _addToSidebar({sidebar:sidebar, splitted:splitted})
      // }
    }
  }
  return
}

function _addToSidebar(props) {
  let sidebar = props.sidebar
  const splitted = props.splitted
  const type = splitted.at(0)
  let sidebarMain
  let sidebarSub
  let tag
  let link

  if (type === 'portal') {
    if (splitted.length === 4) {
      sidebarMain = splitted.at(1)
      sidebarSub = splitted.at(2)
      tag = splitted.at(2)
      link = splitted.at(2)
    }
    
    if (splitted.length === 6) {
      sidebarMain = splitted.at(3)
      sidebarSub = splitted.at(4)
      tag = splitted.at(4)
      link = splitted.at(4)
    }

    if (sidebarMain) {
      if (!sidebar.hasOwnProperty(sidebarMain)) {
        sidebar[sidebarMain] = []
      }
      
      switch (sidebarSub) {
        case 'schapkaartjes':
          sidebar[sidebarMain]['shelflabels'] = {'tag': 'shelflabels', 'link': 'shelflabels'}
          break
        default:
          sidebar[sidebarMain][sidebarSub] = {'tag': tag, 'link': link}
      }
    }
  }
}