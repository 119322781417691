export const scopes =
  [
    "portal:1settings",
    "portal:1settings:dashboards",
    "portal:1settings:subscribe",
    "portal:1settings:administrations",
    "portal:1settings:users",
    "portal:1settings:userroles",
    "portal:1settings:locations",
    "portal:1settings:reservationlocations",
    "portal:1settings:devices",
    "portal:1settings:settings",
    "portal:1settings:ccvshops",
    "portal:1settings:snelstart",
    "portal:1settings:notifications",
    "portal:1settings:onlinepayments",
    "portal:1settings:qrordering",
    "portal:2relations",
    "portal:2relations:boarding",
    "portal:2relations:registrations",
    "portal:2relations:relations",
    "portal:2relations:contacts",
    "portal:2relations:employees",
    "portal:2relations:employeeroles",
    "portal:3products",
    "portal:3products:subscriptions",
    "portal:3products:products",
    "portal:3products:producteans",
    "portal:3products:productsmodified",
    "portal:3products:stock",
    "portal:3products:warehouse",
    "portal:3products:productsuppliers",
    "portal:3products:productcategories",
    "portal:3products:journalcategories",
    "portal:3products:matching",
    "portal:3products:vats",
    "portal:3products:importing",
    "portal:3products:attributegroups",
    "portal:3products:schapkaartjes",
    "portal:3products:opticon",
    "portal:4transactions",
    "portal:4transactions:guestchecks",
    "portal:4transactions:orders",
    "portal:4transactions:invoices",
    "portal:4transactions:transactions",
    "portal:4transactions:kitchenorders",
    "portal:4transactions:loyalty",
    "portal:5reservations:reservations",
    "portal:5reservations:reservationobjects",
    "portal:5reservations:reservationoptions",
    "portal:6overview",
    "portal:6overview:reports",
    "portal:6overview:reporting",
    "portal:6overview:eftjournal",
    "portal:6overview:cashcount",
    "portal:7import",
    "portal:7import:importprofiles",
    "portal:7import:importing",
    "portal:7import:export",
    "portal:8analyse",
    "portal:8analyse:analyses",
    "portal:9narrowcasting",
    "portal:9narrowcasting:narrowcastingdevices",
    "portal:9narrowcasting:narrowcastingschedules",
    "portal:9narrowcasting:narrowcastingplaylists",
    "portal:9narrowcasting:narrowcastingviews",
    "portal:10kitchenmanager",
    "portal:10kitchenmanager:settings",
    "portal:10kitchenmanager:sortcategories",
    "portal:10kitchenmanager:kmdevices",
    "portal:10kitchenmanager:printers",
    "portal:10kitchenmanager:screens",
    "portal:10kitchenmanager:resellers",
    "portal:11workorder",
    "portal:11workorder:planning",
    "portal:12warehouse:stock",
    "portal:12warehouse:warehouse",
    "portal:12warehouse:purchaseadvice",
    "portal:99super",
    "portal:99super:sitemap",
    "portal:99super:analyses",
    "portal:99super:keycloak",
    "portal:99super:searchid",
    "portal:99super:translations",
    "1settings:accounting",
    "1settings:ccvshopwebhooks",
    "6overview:transactionAnalyse",
    "NumberOfDevices",
    "4transaction:orderdpd"
  ]

export const apiScopes = [
  "api:products:vectron",
  "api:products:vat",
  "api:products:supplier",
  "api:products:settings",
  "api:products:productgroup",
  "api:products:product",
  "api:products:category",
  "api:products:attribute",
  "api:products:v2",
  "api:products:user",
  "api:products:smartpos",
  "api:products:voicemeal",
  "api:products:logout",
  "api:products:app",
  "api:products:ean"
]

export const apiScopesMap = {
  "portal:3products:products": [
    "api:products:product",
    "api:products:category",
    "api:products:vat",
    "api:products:settings",
    "api:products:attribute",
    "api:products:supplier"
  ]
}

export const allScopes =
  [
    "portal:1settings:create",
    "portal:1settings:dashboards:delete",
    "portal:1settings:subscribe:delete",
    "portal:1settings:administrations:delete",
    "portal:1settings:users:delete",
    "portal:1settings:userroles:delete",
    "portal:1settings:locations:delete",
    "portal:1settings:reservationlocations:delete",
    "portal:1settings:devices:delete",
    "portal:1settings:settings:delete",
    "portal:1settings:ccvshops:delete",
    "portal:1settings:snelstart:delete",
    "portal:1settings:notifications:delete",
    "portal:1settings:onlinepayments:delete",
    "portal:1settings:qrordering:delete",
    "portal:2relations:create",
    "portal:2relations:boarding:delete",
    "portal:2relations:registrations:delete",
    "portal:2relations:relations:delete",
    "portal:2relations:contacts:delete",
    "portal:2relations:employees:delete",
    "portal:2relations:employeeroles:delete",
    "portal:3products:delete",
    "portal:3products:subscriptions:delete",
    "portal:3products:products:delete",
    "portal:3products:producteans:delete",
    "portal:3products:productsmodified:delete",
    "portal:3products:productsuppliers:delete",
    "portal:3products:productcategories:delete",
    "portal:3products:journalcategories:delete",
    "portal:3products:matching:delete",
    "portal:3products:vats:delete",
    "portal:3products:attributegroups:delete",
    "portal:3products:schapkaartjes:delete",
    "portal:3products:opticon:delete",
    "portal:4transactions:delete",
    "portal:4transactions:guestchecks:delete",
    "portal:4transactions:orders:delete",
    "portal:4transactions:invoices:delete",
    "portal:4transactions:transactions:delete",
    "portal:4transactions:kitchenorders:delete",
    "portal:4transactions:loyalty:delete",
    "portal:5reservations:reservations:delete",
    "portal:5reservations:reservationobjects:delete",
    "portal:5reservations:reservationoptions:delete",
    "portal:6overview:delete",
    "portal:6overview:reports:delete",
    "portal:6overview:reporting:delete",
    "portal:6overview:eftjournal:delete",
    "portal:6overview:cashcount:delete",
    "portal:7import:delete",
    "portal:7import:importprofiles:delete",
    "portal:7import:importing:delete",
    "portal:7import:export:delete",
    "portal:8analyse:delete",
    "portal:8analyse:analyses:delete",
    "portal:9narrowcasting:delete",
    "portal:9narrowcasting:narrowcastingdevices:delete",
    "portal:9narrowcasting:narrowcastingschedules:delete",
    "portal:9narrowcasting:narrowcastingplaylists:delete",
    "portal:9narrowcasting:narrowcastingviews:delete",
    "portal:10kitchenmanager:delete",
    "portal:10kitchenmanager:sortcategories:delete",
    "portal:10kitchenmanager:kmdevices:delete",
    "portal:10kitchenmanager:printers:delete",
    "portal:10kitchenmanager:screens:delete",
    "portal:10kitchenmanager:resellers:delete",
    "portal:11workorder:planning:delete",
    "portal:12warehouse:stock:delete",
    "portal:12warehouse:warehouse:delete",
    "portal:12warehouse:purchaseadvice:delete",
    "portal:99super:delete",
    "portal:99super:sitemap:delete",
    "portal:99super:analyses:delete",
    "portal:99super:keycloak:delete",
    "portal:99super:searchid:delete",
    "portal:99super:translations:delete",
    "1settings:accounting:delete",
    "1settings:ccvshopwebhooks:delete",
    "6overview:transactionAnalyse:delete",
    "NumberOfDevices:unlimited",
    "4transaction:orderdpd:delete"
  ]

export const resellerScopes =
  [
    "portal:1settings:create",
    "portal:1settings:dashboards:delete",
    "portal:1settings:subscribe:delete",
    "portal:1settings:administrations:delete",
    "portal:1settings:users:delete",
    "portal:1settings:userroles:delete",
    "portal:1settings:locations:delete",
    "portal:1settings:reservationlocations:delete",
    "portal:1settings:devices:delete",
    "portal:1settings:settings:delete",
    "portal:1settings:ccvshops:delete",
    "portal:1settings:snelstart:delete",
    "portal:1settings:notifications:delete",
    "portal:1settings:onlinepayments:delete",
    // "portal:1settings:qrordering",
    "portal:2relations:create",
    // "portal:2relations:boarding:delete",
    // "portal:2relations:registrations:delete",
    "portal:2relations:relations:delete",
    // "portal:2relations:contacts:delete",
    "portal:2relations:employees:delete",
    "portal:2relations:employeeroles:delete",
    "portal:3products:delete",
    // "portal:3products:subscriptions:delete",
    "portal:3products:products:delete",
    "portal:3products:producteans:delete",
    "portal:3products:productsmodified:delete",
    "portal:3products:stock:delete",
    "portal:3products:warehouse:delete",
    "portal:3products:productsuppliers:delete",
    "portal:3products:productcategories:delete",
    "portal:3products:journalcategories:delete",
    "portal:3products:matching:delete",
    "portal:3products:vats:delete",
    "portal:3products:attributegroups:delete",
    "portal:3products:schapkaartjes:delete",
    "portal:3products:opticon:delete",
    "portal:4transactions:delete",
    "portal:4transactions:guestchecks:delete",
    "portal:4transactions:orders:delete",
    "portal:4transactions:invoices:delete",
    "portal:4transactions:transactions:delete",
    // "portal:4transactions:kitchenorders:delete",
    // "portal:4transactions:loyalty:delete",
    "portal:5reservations:reservations:delete",
    "portal:5reservations:reservationobjects:delete",
    "portal:5reservations:reservationoptions:delete",
    "portal:6overview:delete",
    // "portal:6overview:reports:delete",
    "portal:6overview:reporting:delete",
    // "portal:6overview:eftjournal:delete",
    "portal:6overview:cashcount:delete",
    "portal:7import:delete",
    "portal:7import:importprofiles:delete",
    "portal:7import:importing:delete",
    // "portal:7import:export:delete",
    "portal:8analyse:delete",
    "portal:8analyse:analyses:delete",
    "portal:9narrowcasting:delete",
    // "portal:9narrowcasting:narrowcastingdevices:delete",
    // "portal:9narrowcasting:narrowcastingschedules:delete",
    // "portal:9narrowcasting:narrowcastingviews:delete",
    // "portal:9narrowcasting:narrowcastingplaylists:delete",
    "portal:10kitchenmanager:delete",
    "portal:10kitchenmanager:sortcategories:delete",
    "portal:10kitchenmanager:kmdevices:delete",
    "portal:10kitchenmanager:printers:delete",
    "portal:10kitchenmanager:screens:delete",
    "portal:10kitchenmanager:resellers:delete",
    // "portal:11workorder:planning:delete",
    // "portal:10kitchenmanager:screens:delete",
    // "portal:99super:delete",
    // "portal:99super:sitemap:delete",
    // "portal:99super:analyses:delete",
    // "portal:99super:keycloak:delete",
    // "portal:99super:searchid:delete",
    // "portal:99super:translations:delete",
    "1settings:accounting:delete",
    "1settings:ccvshopwebhooks:delete",
    "6overview:transactionAnalyse:delete",
    // "NumberOfDevices:unlimited",
    // "4transaction:orderdpd:delete"
  ]

