import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button, Col } from 'react-bootstrap';

import { getTemplate } from '../redux/dataSlices/templateSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import './css/productModal.css'
import JsonHighlighter from './serviceJsonHighlight';
import TemplateEditCanvas from './templateEditCanvas';
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';


export default function TemplateModal(props) {
  const object = useSelector(getTemplate);
  const role = useSelector(getRole)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  let template = {}
  template.data = object;
  template.draggable = Boolean(false);

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Template: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="info">
                  {translate('Info')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="preview">
                  {translate('Preview')}
                </Nav.Link>
              </Nav.Item>
              {service &&
                <Nav.Item>
                  <Nav.Link eventKey="service">
                    {translate('Service')}
                  </Nav.Link>
                </Nav.Item>
              }
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>ESL Model: {object.esl_model}</p>
                      <p>Type: {object.type}</p>
                    </div>
                    <div className="col">
                      <p>Administration: {hash_admins_all[object.admin_id]?.name}</p>
                      <p>Location: {hash_locations_all[object.location_id]?.name}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="preview">
                <div className="container text-center">
                  <div className="row">
                    <Col sm='12' className='konvajs-canvas-parent'>
                      <TemplateEditCanvas {...template} />
                    </Col>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                    <div className="col">

                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object.admin_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}