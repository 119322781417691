import { Table } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';


export default function OperatorsSalesReport(props) {
  const operatorsSalesReport = props.operatorsSalesReport
  
  if (!operatorsSalesReport?.operators || Object.keys(operatorsSalesReport?.operators).length === 0) return <p>No data</p>

  let accordions = []

  accordions.push(userAccordion(operatorsSalesReport))

  return accordions
}

function userAccordion(operatorsSalesReport) {
  let userRows = []
  for (const [operatorId, operatorData ] of Object.entries(operatorsSalesReport.operators).sort(([a, adata], [b, bdata]) => bdata.days?.totals?.total - adata.days?.totals?.total)) {
    // find this levels childs
    let dayRows = []
    dayRows.push(dayAccordion(operatorData.days))

    userRows.push(
      <Accordion flush>
        <Accordion.Item eventKey={operatorId}>
          <Accordion.Header className='journal-category-header'>
            <Table width='100%'>
              <thead>
                <tr>
                  <th width='60%'>{'Operator'}</th>
                  <th width='10%' className="text-end">{'Percentage'}</th>
                  <th width='10%' className="text-end">{'Quantity'}</th>
                  <th width='20%' className="text-end">{'Amount'}</th>
                </tr>
              </thead>
              <tbody>
                <tr key={operatorId}>
                  <td>{operatorId}</td>
                  <td align='right'>{operatorData.days?.totals?.percentage}%</td>
                  <td align='right'>{operatorData.days?.totals?.amount}</td>
                  <td align='right'>{operatorData.days?.totals?.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                </tr>
              </tbody>
            </Table>
          </Accordion.Header>
          <Accordion.Body className='journal-category-body'>{dayRows}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }
  return userRows
}

function dayAccordion(days) {
  let dayRows = []

  for (const [date, dateData ] of Object.entries(days)) {
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/i.test(date)) {
      let journalRows = []
      journalRows.push(journalAccordion(dateData))

      dayRows.push(
        <Accordion flush>
          <Accordion.Item eventKey={date}>
            <Accordion.Header className='journal-category-header'>
              <Table width='100%'>
                <thead>
                  <tr>
                    <th width='60%'>{'Date'}</th>
                    <th width='10%' className="text-end">{'Percentage'}</th>
                    <th width='10%' className="text-end">{'Quantity'}</th>
                    <th width='20%' className="text-end">{'Amount'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={date}>
                    <td>{date}</td>
                    <td align='right'>{dateData.totals?.percentage}%</td>
                    <td align='right'>{dateData.totals?.amount}</td>
                    <td align='right'>{dateData.totals?.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Header>
            <Accordion.Body>{journalRows}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    }
  }
  return dayRows
}

function journalAccordion(journals) {
  let journalRows = []

  for (const [journalId, journalData ] of Object.entries(journals)) {
    if (journalId !== 'totals') {
      let productRows = []
      productRows.push(getProductTable(journalData.products))

      journalRows.push(
        <Accordion flush>
          <Accordion.Item eventKey={journalId}>
            <Accordion.Header className='journal-category-header'>
              <Table width='100%'>
                <thead>
                  <tr>
                    <th width='60%'>{'Journal'}</th>
                    <th width='10%' className="text-end">{'Percentage'}</th>
                    <th width='10%' className="text-end">{'Quantity'}</th>
                    <th width='20%' className="text-end">{'Amount'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={journalId}>
                    <td>{journalData.name}</td>
                    <td align='right'>{journalData.totals?.percentage}%</td>
                    <td align='right'>{journalData.totals?.amount}</td>
                    <td align='right'>{journalData.totals?.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Header>
            <Accordion.Body>{productRows}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    }
  }
  return journalRows
}

function getProductTable(products) {
  let productTable = []
  let productRows = []
  for (let [productId, product] of Object.entries(products)) {
    productRows.push(
      <tr key={productId}>
        <td>{product.name}</td>
        <td align="right">{product.percentage}%</td>
        <td align="right">{product.amount}</td>
        <td align="right">{product.total.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
      </tr>
    )
  }

  productTable.push(
    <Table width='100%'>
      <thead>
        <tr>
          <th width='60%'>
            {'Product'}
          </th>
          <th width='10%' className="text-end">
            {'Percentage'}
          </th>
          <th width='10%' className="text-end">
            {'Quantity'}
          </th>
          <th width='20%' className="text-end">
            {'Amount'}
          </th>
        </tr>
      </thead>
      <tbody>
        {productRows}
      </tbody>
    </Table>
  )
  return productTable
}