import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { getSnelstartCompanyModalShow, setSnelstartCompanyModalShow, setSnelstartCompany, getSnelstartCompany } from "../../redux/dataSlices/snelstartSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SyncSnelstartProducts from "../../actablueAPI/snelstart/SyncSnelstartProducts";
import SyncSnelstartRelations from "../../actablueAPI/snelstart/SyncSnelstartRelations";
import SyncSnelstartOrders from "../../actablueAPI/snelstart/SyncSnelstartOrders";
import ImportSnelstartProducts from "../../actablueAPI/snelstart/ImportSnelstartProducts";
import ImportSnelstartCategories from "../../actablueAPI/snelstart/ImportSnelstartCategories";
import ImportSnelstartRelations from "../../actablueAPI/snelstart/ImportSnelstartRelations";
import ImportSnelstartOrders from "../../actablueAPI/snelstart/ImportSnelstartOrders";
import PostSnelstartKeysUnconnected from "../../actablueAPI/snelstart/PostSnelstartKeysUnconnected";
import SyncSnelstartInvoices from "../../actablueAPI/snelstart/SyncSnelstartInvoices";
import useHandleError from "../../customhooks/useHandleError";

export default function SnelstartButtons() {
  const token = useSelector(getToken)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const modalShow = useSelector(getSnelstartCompanyModalShow)
  const scopes = useSelector(getScopes)
  const snelstartCompany = useSelector(getSnelstartCompany)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();

  function onViewClick(company) {
    dispatch(setSnelstartCompany(company));
    dispatch(setSnelstartCompanyModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setSnelstartCompanyModalShow(false));
  };

  // const onclickEdit = (event) => {
  //   dispatch(setDeviceId(event.target.id));
  //   navigate("/snelstart/edit");
  // };

  const onclickImportProducts = () => {
    confirmAlert({
      title: 'Confirm to import',
      message: 'Are you certain you wish to import snelstart products?\nThis does not create productpages.\nThis will start the import process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            ImportSnelstartProducts({token:token, admin_id:admin_id, productpages:false})
            .then((response) => {})
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickImportProductsAndPages = () => {
    confirmAlert({
      title: 'Confirm to import',
      message: 'Are you certain you wish to import snelstart products?\nThis does also create productpages.\nThis will start the import process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            ImportSnelstartProducts({token:token, admin_id:admin_id, productpages:true})
            .then((response) => {})
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickImportCategories = () => {
    confirmAlert({
      title: 'Confirm to import',
      message: 'Are you certain you wish to import snelstart categories?\nThis does not create productpages.\nThis will start the import process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            ImportSnelstartCategories({token:token, admin_id:admin_id, productpages:false})
            .then((response) => {})
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickImportCategoriesAndPages = () => {
    confirmAlert({
      title: 'Confirm to import',
      message: 'Are you certain you wish to import snelstart categories?\nThis does also create productpages.\nThis will start the import process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            ImportSnelstartCategories({token:token, admin_id:admin_id, productpages:true})
            .then((response) => {})
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickImportRelations = () => {
    confirmAlert({
      title: 'Confirm to import',
      message: 'Are you certain you wish to import snelstart relations?\nThis will start the import process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            ImportSnelstartRelations({token:token, admin_id:admin_id})
            .then((response) => {})
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickImportOrders = () => {
    confirmAlert({
      title: 'Confirm to import',
      message: 'Are you certain you wish to import snelstart orders?\nThis will start the import process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            ImportSnelstartOrders({token:token, admin_id:admin_id})
            .then((response) => {})
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickSyncOrders = () => {
    confirmAlert({
      title: 'Confirm to sync',
      message: 'Are you certain you wish to sync snelstart orders?\nThis will start the sync process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            SyncSnelstartOrders({token:token, admin_id:admin_id, location_id:location_id})
            .then((response) => {
            })
            .catch(error => {
              HandleError({ error: error })
            })
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickSyncRelations = () => {
    confirmAlert({
      title: 'Confirm to sync',
      message: 'Are you certain you wish to sync snelstart relations?\nThis will start the sync process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            SyncSnelstartRelations({token:token, admin_id:admin_id, location_id:location_id})
            .then((response) => {
            })
            .catch(error => {
              HandleError({ error: error })
            })
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickSyncProducts = () => {
    confirmAlert({
      title: 'Confirm to sync',
      message: 'Are you certain you wish to sync snelstart products?\nThis will start the sync process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            SyncSnelstartProducts({token:token, admin_id:admin_id, location_id:location_id})
            .then((response) => {
            })
            .catch(error => {
              HandleError({ error: error })
            })
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  const onclickSyncInvoices = () => {
    confirmAlert({
      title: 'Confirm to sync',
      message: 'Are you certain you wish to sync snelstart invoices?\nThis will start the sync process on the server.\nThis takes some time. Please be patient.\n\nConfirm your decision.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            SyncSnelstartInvoices({token:token, admin_id:admin_id, location_id:location_id})
            .then((response) => {
            })
            .catch(error => {
              HandleError({ error: error })
            })
          }
        },
        {
          label: 'No',
        }
      ]
    });
  }

  function onclickEdit(company) {
    dispatch(setSnelstartCompany(company));
    navigate("/snelstart/edit");
  };

  const onclickAdd = () => {
    // create unconnected link
    let unconnnected = { administration_id: admin_id, location_id: location_id }
    let returnURL = 'https://mijn.actablue.com/snelstart'
    PostSnelstartKeysUnconnected({token:token, data:unconnnected})
    .then(response => {
      // go to snelstart couplings page

      let snelstart_api_key_id = response.data.id
      let app_snelstart_tegel = process.env.REACT_APP_SNELSTART_TEGEL
      window.location.href = `https://web.snelstart.nl/couplings/activate/${app_snelstart_tegel}?referenceKey=${snelstart_api_key_id}&successUrl=${encodeURIComponent(returnURL)}`
    })
  };

  const onclickSnelstartSettings = () => {
    let url =  `${process.env.REACT_APP_SNELSTART_WIZARD}?administration=${admin_id}&location=${location_id}`
    console.log('url', url)
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const onclickSnelstartConnect = () => {
     let url = `${process.env.REACT_APP_SNELSTART_COUPLING_URL}%3Fadministration%${admin_id}%26location%${location_id}`
     window.open(url, '_blank', 'noopener,noreferrer')
  }


  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.snelstart >= 0) {
      buttons.sync_orders = {
        tag: 'sync orders',
        value: 'orders',
        onClick: onclickSyncOrders,
        }
      buttons.sync_relations = {
        tag: 'sync relations',
        value: 'relations',
        onClick: onclickSyncRelations,
      }
      buttons.sync_products = {
        tag: 'sync products',
        value: 'products',
        onClick: onclickSyncProducts,
      }
      buttons.sync_invoices = {
        tag: 'sync invoices',
        value: 'invoices',
        onClick: onclickSyncInvoices,
      }
    }
  
  if (scopes.snelstart >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.snelstart >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }
  if (scopes.snelstart >= 3) {
    // buttons.import_products = {
    //   tag: 'import products',
    //   value: 'products',
    //   onClick: onclickImportProducts,
    // }
    buttons.import_products_and_productpages = {
      tag: 'import products + productpages',
      value: 'products+pages',
      onClick: onclickImportProductsAndPages,
    }
    // buttons.import_categories = {
    //   tag: 'import categories',
    //   value: 'categories',
    //   onClick: onclickImportCategories,
    // }
    // buttons.import_categories_and_productpages = {
    //   tag: 'import categories + pages',
    //   value: 'categories+pages',
    //   onClick: onclickImportCategoriesAndPages,
    // }
    buttons.import_relations = {
      tag: 'import relations',
      value: 'relations',
      onClick: onclickImportRelations,
    }
    buttons.import_orders = {
      tag: 'import orders',
      value: 'orders',
      onClick: onclickImportOrders,
    }

    if (snelstartCompany && (role === 'super' || role === 'admin' || role === 'reseller')) {
      buttons.snelstartsettings = {
        tag: 'snelstart_settings',
        value: 'snelstart_settings',
        onClick: onclickSnelstartSettings,
      }
    }

    if (!snelstartCompany && (role === 'super' || role === 'admin' || role === 'reseller')) {
      buttons.snelstartconnect = {
        tag: 'snelstart_connect',
        value: 'snelstart_connect',
        onClick: onclickSnelstartConnect,
      }
    }
  }

  return buttons
}

