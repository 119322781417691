import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getReservationPagination, getReservations, setReservations, setReservationsHighLights, getReservationsHighlights, getReservationObjects, setReservationObjects, getReservationPagesize, getStartDate, setStartDate, getEndDate, setEndDate, setReservationLocation } from "../../redux/dataSlices/reservationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import axios from "axios";
import Button from "react-bootstrap/Button";
import ReservationButtons from "./reservationbuttons";
import WeatherWidget from "../../components/WeatherWidget";
import BasicCard from "../../components/card"
import HighlightsCard from "../../components/HighlightsCard";
import ReservationTable from "../../components/reservationTable";
import HighlightTable from "../../components/highlightTable";
import ReservationWithoutObjectTable from "../../components/reservationWithoutObjectTable";
import ReservationHighLightCalculation from "./highlightcalculation"
import Datepicker from "../../components/datepicker";
import ReservationTimeline from "../../components/reservationTimeLine";
import moment from "moment"
import { Col } from "react-bootstrap";
import TableButton from "../../components/tableButton";
import { useTranslation } from 'react-i18next';
import GetReservationsListByLocationId from "../../actablueAPI/reservations/GetReservationsListByLocationId";
import GetReservationObjects from "../../actablueAPI/reservations/GetReservationObjects";
import GetReservationLocationById from "../../actablueAPI/reservations/GetReservationLocationByID";
import useHandleError from "../../customhooks/useHandleError";

const Reservations = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const reservations = useSelector(getReservations);
  const reservationshighlights = useSelector(getReservationsHighlights);
  const reservationobjects = useSelector(getReservationObjects);
  const pagination = useSelector(getReservationPagination);
  const pageSize = useSelector(getReservationPagesize)
  const startDate = moment(useSelector(getStartDate), 'YYYY-MM-DD:HH:mm:ssZZ')
  const endDate = moment(useSelector(getEndDate), 'YYYY-MM-DD:HH:mm:ssZZ')
  const dispatch = useDispatch();
  const buttons = ReservationButtons.call();
  const [currentPage, setCurrentPage] = useState('timeline');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();

  const [reservationsWithObject, setReservationsWithObject] = useState([])
  const [reservationsWithoutObject, setReservationsWithoutObject] = useState([])

  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false

  const onDatePickerChange = (start, end) => {
    dispatch(setStartDate(start.format('YYYY-MM-DD:HH:mm:ssZZ')))
    dispatch(setEndDate(end.format('YYYY-MM-DD:HH:mm:ssZZ')))
    getReservationsWithTime(start, end)
  }

  const handlePageChange = (page) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(page);
      setIsTransitioning(false);
    }, 250); // Change this value to adjust the duration of the transition, the one in the CSS too (1000 here is 1s in CSS)
  };

  const datePicker = {
    startDate: startDate,
    endDate: endDate,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    locale: {
      format: "DD/MM/YYYY",
    },
    alwaysShowCalendars: true,
    showDropdowns: true,
    opens: 'right',
    linkedCalendars: false,
    autoApply: true,
    singleDatePicker: false,
    onChange: onDatePickerChange
  }

  const highlightCard = {
    size: 6,
    title: 'Highlights',
    data: <HighlightTable {...reservationshighlights} />,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const weatherWidgetCard = {
    size: 6,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const reservationDashboardCard = {
    size: 12,
    title: 'Reservation dashboard',
    subtitle: <Datepicker {...datePicker} />,
    data: <ReservationTable reservations={reservations} />,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const reservationWithoutObjectCard = {
    size: 12,
    title: 'Reservations',
    // subtitle: <Datepicker {...datePicker} />,
    data: <ReservationWithoutObjectTable reservations={reservationsWithoutObject} />,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const reservationTimelineCard = {
    size: 12,
    title: 'Reservation timeline',
    data: <ReservationTimeline objects={reservationobjects} reservations={reservationsWithObject} start={startDate} end={endDate} />,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  function getReservationsWithTime(start, end) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/reservations/reservation/list/location"),
      headers: {
        'Authorization': 'Bearer '.concat(token['access_token']),
        'Content-Type': 'application/json'
      },
      params: {
        'location_id': location_id,
        'start': start.format('YYYY-MM-DD').concat('T00:00:00Z'),
        'end': end.format('YYYY-MM-DD').concat('T23:59:59Z')
      },
    })
      .then(response => {
        dispatch(setReservations(response.data))
        dispatch(setReservationsHighLights(ReservationHighLightCalculation(response.data)))
      })
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Reservations'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      let start = startDate.format('YYYY-MM-DD').concat('T00:00:00Z')
      let end = endDate.format('YYYY-MM-DD').concat('T23:59:59Z')
      
      axios.all([
        GetReservationLocationById({token:token,location_id:location_id}),
        GetReservationsListByLocationId({token:token, admin_id:admin_id, location_id:location_id, start:start, end:end}),
        GetReservationObjects({token:token, admin_id:admin_id, location_id:location_id})
      ])
      .then(axios.spread((reservationLocationResponse, resevationListResponse, reservationObjectsResponse) => {
        dispatch(setReservationLocation(reservationLocationResponse.data))
        dispatch(setReservations(resevationListResponse.data))
        dispatch(setReservationsHighLights(ReservationHighLightCalculation(resevationListResponse.data)))
        dispatch(setReservationObjects(reservationObjectsResponse.data))
        dispatch(setLoading(false))
      }))
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }
    
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pageSize]);

  useEffect(() => {
    let newReservationsWithObjects = []
    let newReservationsWithoutObjects = []

    if (reservations) {
      Object.values(reservations).forEach(reservation => {
        if (reservation.hasOwnProperty('reservation_objects')) {
          newReservationsWithObjects.push(reservation)
        }
        if (!reservation.hasOwnProperty('reservation_objects')) {
          newReservationsWithoutObjects.push(reservation)
        }
      });
      setReservationsWithObject(newReservationsWithObjects)
      setReservationsWithoutObject(newReservationsWithoutObjects)
    }
    // eslint-disable-next-line
  }, [reservations]);

  return (
    <>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
      <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
      <Col md={12}>
        <div className='row g-3'>
          <div className='col'>
            <div className="p-3 my-3">
              <Button className={`${currentPage === 'timeline' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('timeline')}>
                {translate('Timeline')}
              </Button>
              <Button className={`${currentPage === 'dashboard' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('dashboard')}>
                {translate('Dashboard')}
              </Button>
              {add && <TableButton {...add} />}
            </div>
          </div>
        </div>
      </Col>

      {currentPage === 'timeline' ?
        <>
          <BasicCard {...reservationTimelineCard} />
          <BasicCard {...reservationWithoutObjectCard} />
        </>
        :
        <>
          <WeatherWidget {...weatherWidgetCard} />
          <HighlightsCard {...highlightCard} />
          <BasicCard {...reservationDashboardCard} />
        </>
      }
    </>
  )
};
export default Reservations;