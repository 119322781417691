import axios from "axios";

const GetStatusReportRange = ({ token, admin_id, location_id, startDate, endDate, location, signal }) => {
  return new Promise((resolve, reject) => {
    let timezone = location?.timezone ? location?.timezone : "Europe/Amsterdam"

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/reporting/v2/dailystatus"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        'start_date': startDate,
        'end_date': endDate,
        'tz': timezone,
      },
      signal: signal
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      });
  })

};

export default GetStatusReportRange
