import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EftJournalButtons from '../portal/eftjournal/eftJournalButtons';
import PaginationAndSizeComponent from './paginationAndSize';
import EftJournalModal from './eftJournalModal';

export default function EftJournalTable(props) {
  const eJournals = props.eJournals
  const buttons = EftJournalButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const pdf = buttons.hasOwnProperty('pdf') ? Object.assign({}, buttons.pdf) : false

  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  const pages = eJournals?.total_pages || 0
  const page = (eJournals?.number + 1) || 0

  let rows = []
  if (eJournals && eJournals.content && eJournals.content.length > 0) {
    for (const object of eJournals.content) {
      let origin = 'CCV'
      if (object.lines?.length === 3) {
        origin = 'MSP'
      }

      rows.push (
        <tr className='product-row' key={object.id}>
          <td>
            {new Date(object.creation).toLocaleString()}
          </td>
          <td>
            {origin}
          </td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(object) }}>{translate(modalview.tag)}</Button>
            <Button size="sm" type='button' onClick={() => { pdf.onClick(object) }}>{translate(pdf.tag)}</Button>
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Date')}</th>
            <th>{translate('Origin')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <EftJournalModal modal={modalview.modal} />
      </Table>
    </>
  );
}
