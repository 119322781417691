import axios from "axios";

const GetProductsType = ({token, admin_id, location_id, page, size, filter}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/product/type`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'location': location_id,
        'page': page,
        'size': size,
        'type': filter
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  });
};

export default GetProductsType