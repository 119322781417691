import axios from "axios";

const PostOrderSearch = ({token, admin_id, location_id, searchObject}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/invoicepayments/order/search"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'location': location_id,
      },
      data: searchObject
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log("error:", error)
    });
  });
};

export default PostOrderSearch