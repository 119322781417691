import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getProductCategoriesAll, getHashProductCategoriesAll, getCategory, setCategory } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import ProductCategoryForm from "../../components/productcategoryForm";
import { CategoryFormFields } from "./productcategoryFormFields"
import HandleOnChange from "../../helpers/handleOnChange";
import PutCategory from "../../actablueAPI/products/PutCategory";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import GetProductCategoryProductCategoryId from "../../actablueAPI/kitchenmanager/GetProductCategoryProductCategoryId";
import GetAllPrinters from "../../actablueAPI/kitchenmanager/GetAllPrinters";
import { getHashPrintersAll, getHashScreensAll, getHashSortCategoriesAll, getPrintersAll, getScreensAll, getSortCategoriesAll, setPrintersAll, setScreensAll, setSortCategoriesAll } from "../../redux/dataSlices/kitchenmanagerSlice";
import GetAllCategories from "../../actablueAPI/kitchenmanager/GetAllCategories";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostProductCategory from "../../actablueAPI/kitchenmanager/PostProductCategory";
import DeleteProductCategory from "../../actablueAPI/kitchenmanager/DeleteProductCategory";
import { validateFormObject } from "../../helpers/validateFormObject";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import GetAllScreens from "../../actablueAPI/kitchenmanager/GetAllScreens";
import useHandleError from "../../customhooks/useHandleError";

const CategoryEdit = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const category = useSelector(getCategory)
  const productpageCategories = useSelector(getProductCategoriesAll)
  const hash_productpage_categories = useSelector(getHashProductCategoriesAll)
  const kmPrinters = useSelector(getPrintersAll);
  const hash_printers = useSelector(getHashPrintersAll);
  const sortCategories = useSelector(getSortCategoriesAll);
  const hash_sort_categories = useSelector(getHashSortCategoriesAll);
  const screens = useSelector(getScreensAll);
  const hash_screens = useSelector(getHashScreensAll);
  const [invalidFields, setInvalidFields] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const navTitle = "Product category edit"

  const onChange = (event) => {
    let newCategory = HandleOnChange({ event: event, object: category })
    dispatch(setCategory(newCategory))
  }

  const onParentChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: category, selectfield: 'parent', hash: hash_productpage_categories })
    dispatch(setCategory(newCategory))
  };

  const onPrinterChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'printer', prefix: 'kitchenmanager' })
    dispatch(setCategory(newCategory))
  }

  const onCategoryChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'category', prefix: 'kitchenmanager' })
    dispatch(setCategory(newCategory))
  }

  const onScreensChange = (event) => {
    let newCategory = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: category, selectfield: 'screens', prefix: 'kitchenmanager' })
    if (newCategory.kitchenmanager.screens.length === 0) {
      delete newCategory.kitchenmanager.screens
    }
    dispatch(setCategory(newCategory))
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = async () => {
    const [invalid] = validateFormObject({ object: category, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    let newCategory = JSON.parse(JSON.stringify(category))
    if (newCategory.hasOwnProperty('kitchenmanager') && Object.keys(newCategory?.kitchenmanager).length > 0) {
      if (scopes.sortcategories) {
        if ((newCategory?.kitchenmanager?.printer && newCategory?.kitchenmanager?.category) ||
          (newCategory?.kitchenmanager?.screens && newCategory?.kitchenmanager?.category) ||
          (newCategory?.kitchenmanager?.printer && newCategory?.kitchenmanager?.category && newCategory?.kitchenmanager?.screens)) {
          newCategory.kitchenmanager.product_category_id = newCategory?.id
          newCategory.kitchenmanager.admin_id = admin_id
          newCategory.kitchenmanager.location_id = location_id
          newCategory.kitchenmanager.name = newCategory?.title
          if (!newCategory?.kitchenmanager?.screens) newCategory.kitchenmanager.screens = [];
          await PostProductCategory({ token: token, data: newCategory?.kitchenmanager })
            .catch(error => {
              console.error(error)
            });
        } else {
          await DeleteProductCategory({ token: token, id: newCategory?.kitchenmanager?.id, admin_id: admin_id, location_id: location_id })
            .catch(error => {
              console.error(error)
            });
        }
        delete newCategory.kitchenmanager
      }
    }
    PutCategory({ token: token, admin_id: admin_id, category: newCategory })
      .then(() => {
        navigate("/productcategories");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    invalidFields: invalidFields,
    fields: CategoryFormFields(),
    field: {
      onChange: onChange
    },
    data: category,
    hash_printers: hash_printers,
    hash_sort_categories: hash_sort_categories,
    hash_screens: hash_screens,
    parent: {
      label: 'parent',
      placeholder: 'parent',
      hash: hash_productpage_categories,
      options: productpageCategories,
      selected: {},
      onChange: onParentChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    printer: {
      label: 'printer',
      options: kmPrinters,
      selected: [],
      onChange: onPrinterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    category: {
      label: 'category',
      options: sortCategories,
      selected: [],
      onChange: onCategoryChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    screens: {
      label: 'screens',
      options: screens,
      selected: [],
      onChange: onScreensChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <ProductCategoryForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    dispatch(setLoading(true))
    if (scopes?.sortcategories) {

      GetProductCategoryProductCategoryId({ token: token, id: category?.id, admin_id: admin_id, location_id: location_id })
        .then(response => {
          let newCategory = JSON.parse(JSON.stringify(category))
          newCategory.kitchenmanager = response.data;
          if (response.data?.printer) newCategory.kitchenmanager.printer = response.data?.printer?.id;
          if (response.data?.category) newCategory.kitchenmanager.category = response.data?.category?.id;
          dispatch(setCategory(newCategory))
        })
        .catch(error => {
          console.error(error)
        });

      GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setPrintersAll(response))
        })
        .catch(error => { HandleError({ error: error }) });

      GetAllCategories({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setSortCategoriesAll(response))
        })
        .catch(error => { HandleError({ error: error }) });

      GetAllScreens({ token: token, admin_id: admin_id, location_id: location_id })
        .then(response => {
          dispatch(setScreensAll(response))
        })
        .catch(error => { HandleError({ error: error }) })
        .finally(() => {
          dispatch(setLoading(false))
        })

    } else {
      dispatch(setLoading(false))
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default CategoryEdit;