export default function AddParentToChildren(categories) {
  let hash = []
  for (const category of categories) {
    hash[category.id] = category
  }

  function AddChildren(newCategory, category) {
    if (category.hasOwnProperty('children')) {
      let newChildren = []
      let parent = JSON.parse(JSON.stringify(category))
      delete parent.children
      for (const child of category.children) {
        if (hash[child.id]) {
          let newChild = JSON.parse(JSON.stringify(hash[child.id]))
          newChild.parent = parent
          AddChildren(newChild, JSON.parse(JSON.stringify(hash[child.id])))
          newChildren.push(newChild)
        }
      }
      newCategory.children = newChildren
    }
  }

  let newCategories = []
  for (const category of categories) {
    let newCategory = JSON.parse(JSON.stringify(category))
    delete newCategory.children
      AddChildren(newCategory, category)
      // if (category.hasOwnProperty('children')) {
      // let newChildren = []
      // let parent = JSON.parse(JSON.stringify(category))
      // delete parent.children
      // for (const child of category.children) {
      //   let newChild = JSON.parse(JSON.stringify(hash[child.id]))
      //   newChild.parent = parent
      //   newChildren.push(newChild)
      // }
      // newCategory.children = newChildren
    // }
    newCategories.push(newCategory)
  }
  return (newCategories)
}