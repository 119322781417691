import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    product_pagesize: 10,
    product_pagination: 0,
    hash_addon_products_all: {},
    hash_subscription_products_all: {},
    selected_products: {},
    product_esl: {},
    productmodaltab: 'info',
    productFilter: 'article',
    productSearch: ''
  },
  reducers: {
    setProductId: (state, action) => {
      state.product_id = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setProducts: (state, action) => {
      if (action?.payload?.hasOwnProperty('content') && action?.payload?.hasOwnProperty('first')) {state.products = action.payload}
      if (action?.payload?.hasOwnProperty('content') && !action?.payload?.hasOwnProperty('first')) {state.products = action.payload}
      if (!action?.payload?.hasOwnProperty('content') && action?.payload?.hasOwnProperty('first')) {state.products = {'content': []}} // This one should happen when you don't get any products back from the API
      if (!action?.payload?.hasOwnProperty('content') && !action?.payload?.hasOwnProperty('first') ) {state.products = {'content':action.payload}}
    },
    setAddOnProductsAll: (state, action) => {
      state.addonproductsall = action.payload
      action?.payload?.forEach(product => {
        state.hash_addon_products_all[product.id] = product
      })
    },
    setSubscriptionProductsAll: (state, action) => {
      state.subscription_products_all = action.payload
      action?.payload?.forEach(product => {
        state.hash_subscription_products_all[product.id] = product
      })
    },
    setServiceProductsAll: (state, action) => {
      state.serviceproductsall = action.payload
      let hashMap = {}
      action?.payload?.forEach(product => {
        hashMap[product.id] = product
      });
      state.hashserviceproductsall = hashMap
    },
    setDepositProductsAll: (state, action) => {
      state.depositproductsall = action.payload
      let hashMap = {}
      action?.payload?.forEach(product => {
        hashMap[product.id] = product
      });
      state.hashdepositproductsall = hashMap
    },
    editProducts: (state, action) => {
      for (let product in state.products.content) {
        if (state.products.content[product].id === action.payload.id) {
          state.products.content[product] = action.payload
        }
      }
    },
    setProductsHighLights: (state, action) => {
      state.productshighlights = action.payload
    },
    setProductModalShow: (state, action) => {
      state.productmodalshow = action.payload
    },
    setProductModalTab: (state, action) => {
      state.productmodaltab = action.payload
    },
    setProductPagination: (state, action) => {
      state.product_pagination = (action.payload - 1)
    },
    setProductPagesize: (state, action) => {
      state.product_pagesize = action.payload
    },
    setSelectedProducts: (state, action) => {
      state.selected_products = action.payload
    },
    setProductEsl: (state, action) => {
      state.product_esl = action.payload
    },
    setProductSearch: (state, action) => {
      state.productSearch = action.payload
    },
    setProductFilter: (state, action) => {
      state.productFilter = action.payload
    },
  }
});

export const {
  setProductId, setProduct, setProducts, editProducts, setProductsHighLights, setProductModalShow, setProductModalTab, setProductPagination, setProductPagesize,
  setAddOnProductsAll, setDepositProductsAll, setServiceProductsAll, setSubscriptionProductsAll, setSelectedProducts, setProductEsl,
  setProductFilter,
  setProductSearch
} = productSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getProductId = (state) => state.product.product_id
export const getProduct = (state) => state.product.product
export const getProducts = (state) => state.product.products
export const getAddOnProductsAll = (state) => state.product.addonproductsall
export const getHashAddOnProductsAll = (state) => state.product.hash_addon_products_all
export const getSubscriptionProductsAll = (state) => state.product.subscription_products_all
export const getHashSubscriptionProductsAll = (state) => state.product.hash_subscription_products_all
export const getServiceProductsAll = (state) => state.product.serviceproductsall
export const getHashServiceProductsAll = (state) => state.product.hashserviceproductsall
export const getDepositProductsAll = (state) => state.product.depositproductsall
export const getHashDepositProductsAll = (state) => state.product.hashdepositproductsall
export const getProductsHighlights = (state) => state.product.productshighlights
export const getProductModalShow = (state) => state.product.productmodalshow
export const getProductModalTab = (state) => state.product.productmodaltab
export const getProductPagination = (state) => state.product.product_pagination
export const getProductPagesize = (state) => state.product.product_pagesize
export const getSelectedProducts = (state) => state.product.selected_products
export const getProductEsl = (state) => state.product.product_esl
export const getProductSearch = (state) => state.product.productSearch
export const getProductFilter = (state) => state.product.productFilter

export default productSlice.reducer;