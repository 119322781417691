import { Button, Table, Col, Container, Row } from 'react-bootstrap'
import './css/productTable.css';
import { useTranslation } from 'react-i18next';
import SearchAndFilter from "../components/searchAndFilter";


export default function AttributeProductToAddTable(props) {
  const searchAndFilter = props.searchAndFilter
  const products = props.products
  const addButton = props.addButton
  const { t: translate } = useTranslation();

  let productRows = [
    <Container>
      <Row className='justify-content-md-center'>
        <Col>No products found.</Col>
      </Row>
    </Container>
  ]

  if (products && products.length > 0) {
    productRows = []
    // sort products modification date 
    // let sorted = Object.values(products.content).sort((a, b) => new Date(b?.modification) - new Date(a?.modification))
    // eslint-disable-next-line
    products.map((product) => {
      productRows.push(
        <tr className={'product-row'} key={product.id}>
          <td align='left'>{product.sku_code}</td>
          <td align='left'>{product.name}</td>
          <td align="right">{Number(product.price_incl_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          <td align='left'>{product.type}</td>
          <td className='table-icons'>{product?.active ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</td>
          <td><Button size="sm" type='button' onClick={() => { addButton.onClick(product) }}>{translate(addButton.tag)}</Button></td>
        </tr>
      )
    })
  }

  return (
    <>
      <SearchAndFilter {...searchAndFilter} />
      <Table width='100%'>
        <thead>
          <tr>
            <th>{translate('SKU code')}</th>
            <th>{translate('Name')}</th>
            <th className="text-end">{translate('Price')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Active')}</th>
            <th>{translate('Actions')}</th>
            </tr>
        </thead>
        <tbody>
          {productRows}
        </tbody>
      </Table>
    </>
  );
}