import React from "react";
import { Page, Document, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../pdfstylesheet/stylesTransactionPDF"
import { calculateVatLines, generateQRCode } from "./createTransactionPDF";

const Invoice = ({ invoice, hash_locations, relation }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} key="header">

          <View style={styles.locationNaw} key='location-naw'>
            <Text>{hash_locations[invoice?.location_id]?.name}</Text>
            <Text>{hash_locations[invoice?.location_id]?.address?.street} {hash_locations[invoice?.location_id]?.address?.street_number}{hash_locations[invoice?.location_id]?.address?.street_number_additional}</Text>
            <Text>{hash_locations[invoice?.location_id]?.address?.zip_code}, {hash_locations[invoice?.location_id]?.address?.place}</Text>
            {hash_locations[invoice?.location_id]?.phonenumbers && hash_locations[invoice?.location_id]?.phonenumbers?.find(phonenumber => phonenumber.type === "facturatie")?.number &&
              <Text>T: {hash_locations[invoice?.location_id]?.phonenumbers?.find(phonenumber => phonenumber.type === "facturatie")?.number}</Text>
            }
            {hash_locations[invoice?.location_id]?.emails && hash_locations[invoice?.location_id]?.emails?.find(email => email.type === "facturatie")?.email &&
              <Text>E: {hash_locations[invoice?.location_id]?.emails?.find(email => email.type === "facturatie")?.email}</Text>
            }
            {hash_locations[invoice?.location_id]?.webshop_url &&
              <Text>W: {hash_locations[invoice?.location_id]?.webshop_url}</Text>
            }
          </View>

          <View style={styles.relationNaw} key='relation-naw'>
            {invoice?.relation_id &&
              relation &&
              <>
                <Text>{relation?.name}</Text>
                {relation?.addresses && relation?.addresses.length > 0 &&
                  <>
                    <Text>{relation?.addresses[0]?.street} {relation?.addresses[0]?.street_number}{relation?.addresses[0]?.street_number_additional}</Text>
                    <Text>{relation?.addresses[0]?.zip_code}, {relation?.addresses[0]?.place}</Text>
                  </>
                }
                {relation?.phone_numbers && relation?.phone_numbers?.find(phonenumber => phonenumber.type === "facturatie")?.number &&
                  <Text>T: {relation?.phone_numbers?.find(phonenumber => phonenumber.type === "facturatie")?.number}</Text>
                }
                {relation?.emails && relation?.emails?.find(email => email.type === "facturatie")?.email &&
                  <Text>E: {relation?.emails?.find(email => email.type === "facturatie")?.email}</Text>
                }
              </>
            }
          </View>

        </View>

        <Text style={styles.invoiceTitle}>Invoice</Text>
        <Text style={styles.invoiceInfo}>Invoice #{invoice?.number}</Text>
        <Text style={styles.invoiceInfo}>Status: {invoice?.status}</Text>
        <Text style={styles.invoiceInfo}>Date: {new Date(invoice?.creation).toLocaleString()}</Text>

        <View style={styles.table} key='invoice-table'>
          <View style={styles.tableRow} key='invoice-table-row'>
            <Text style={styles.tableHeaderAmount}>Amount</Text>
            <Text style={styles.tableHeader}>Item</Text>
            <Text style={styles.tableHeaderPrice}>Price</Text>
          </View>

          {invoice?.invoice_lines?.map((line, index) => (
            <View style={styles.tableRow} key={`table-row-${index}`}>
              <Text style={styles.tableCellAmount}>{line?.quantity}</Text>
              <Text style={styles.tableCell}>{line?.product_name}</Text>
              <Text style={styles.tableCellPrice}>{Number(line.amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
            </View>
          ))}
        </View>
        <View style={styles.tableRow} key='table-row-total'>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCellPrice}>TOTAL:</Text>
          <Text style={styles.totalsPrice}>{Number(invoice?.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
        </View>

        <Text style={styles.invoiceTitle}>VAT Total</Text>

        <View style={styles.table} key='invoice-table'>
          <View style={styles.tableRow} key='invoice-table-row'>
            <Text style={styles.tableHeaderCentered}>%</Text>
            <Text style={styles.tableHeaderPrice}>Incl. VAT</Text>
            <Text style={styles.tableHeaderPrice}>Excl. VAT</Text>
            <Text style={styles.tableHeaderPrice}>VAT</Text>
          </View>

          {Object.values(calculateVatLines(invoice?.invoice_lines))?.map((line) => (
            <View style={styles.tableRow} key='table-row-vats'>
              <Text style={styles.tableCellCentered}>{line['%']}</Text>
              <Text style={styles.tableCellPrice}>{Number(line.inclVat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
              <Text style={styles.tableCellPrice}>{Number(line.exclVat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
              <Text style={styles.tableCellPrice}>{Number(line.vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
            </View>
          ))}
        </View>

        <View style={styles.qrCodeContainer} key='table-row-total'>
          <Image src={generateQRCode(invoice.id)} />
        </View>
      </Page>
    </Document>
  );
}

export default Invoice;