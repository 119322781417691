import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { setProgressModalShow, setTaskData } from "../redux/dataSlices/analyseSlice";
import QRCode from 'qrcode';

const qrCodeOptions = {
  margin: 0,
  height: 1028,
  width: 1028
}

export const generateQRCode = async (data) => {
  try {
    const qrCodeData = await QRCode.toDataURL(data, qrCodeOptions);
    return qrCodeData;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

async function downloadQrCodes(start, end, admin_id, location_id, token, dispatch) {

  const zip = new JSZip();
  const numberArray = Array.from({ length: end - start }, (_, index) => index + start);

  try {

    const images = {};
    let current = 0
    let index = 0;
    let task_data = {
      current: current,
      total: end,
      progress: current === 0 ? 0 : current / end
    }

    dispatch(setTaskData(task_data))
    dispatch(setProgressModalShow(true))

    async function generateAndDownload() {
      if (index <= end) {

        images[index] = { data: await generateQRCode(`${process.env.REACT_APP_QRORDERING_BASE_URL}?adminId=${admin_id}&locationId=${location_id}&userId=${token?.payload?.sub}&gc=${index}`) };

        let fileName = `QRCode-${index}.png`;

        if (start !== end) zip.file(fileName, atob(images[index].data.split(',')[1]), { binary: true });

        task_data = {
          current: index,
          total: Object.keys(numberArray).length,
          progress: current === 0 ? 0 : current / Object.keys(numberArray).length
        }
        index++
        current++
        dispatch(setTaskData(task_data))

        setTimeout(generateAndDownload, 1);
      } else {
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'QR-Codes.zip');
        dispatch(setProgressModalShow(false))
      }

    }

    generateAndDownload();
  } catch (error) {
    console.error(error);
    dispatch(setProgressModalShow(false))
  }
}

export default downloadQrCodes