import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import NotificationButtons from '../portal/notifications/notificationbuttons';
import NotificationReservationTemplateModal from './notificationReservationTemplateModal';

export default function ReservationTemplateTable(reservationTemplates) {
  const buttons = NotificationButtons.call()
  const modalview = buttons.hasOwnProperty('reservationTemplateModalview') ? Object.assign({}, buttons.reservationTemplateModalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let rows = []
  for (let template of Object.values(reservationTemplates)) {
    rows.push(
      <tr className='product-row' key={template.client_id}>
        <td>{template.name || "N/A"}</td>
        <td>{template.reservation_status || "N/A"}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(template) }} id={template.id}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(template, 'reservationtemplate') }} id={template.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick('reservationtemplate') }} >{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Name')}</th>
            <th>{translate('Status')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <NotificationReservationTemplateModal modal={modalview.modal} />
      </Table>
    </>
  );
}