export const UserFormEditFields = () => {

  const userFormEditFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'select', name: `Roles`, id: 'roles', formdatafield: 'roles' },
    { type: 'multi+create', name: `Administrations`, id: 'administrations', formdatafield: 'administrations' },
  ]

  return userFormEditFields
}

export const UserFormAddFields = () => {

  const userFormAddFields = [
    // single option fields
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'email', name: `Email for login`, id: 'email' },
    { type: 'text', name: `Password`, id: 'password' },
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'select', name: `Roles`, id: 'roles', formdatafield: 'roles' },
    { type: 'multi+create', name: `Administrations`, id: 'administrations', formdatafield: 'administrations' },
  ]

  return userFormAddFields
}

export const KeyCloakUserFormAddFields = () => {

  const keycloakUserFormAddFields = [
    // single option fields
    { type: 'text', name: `Keycloak user id`, id: 'id' },
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'select', name: `Roles`, id: 'roles', formdatafield: 'roles' },
    { type: 'multi+create', name: `Administrations`, id: 'administrations', formdatafield: 'administrations' },
  ]

  return keycloakUserFormAddFields
}