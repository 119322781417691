import axios from "axios";
import { setTaskData } from "../../redux/dataSlices/exportSlice";

const GetAllProducts = ({ type, token, admin_id, location_id, dispatch, page = 0, payload = [], resolver = null, retries = 0 }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/product/list`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'location': location_id,
        'type': type,
        'page': page,
        'size': 10
      },
    })
      .then(response => {
        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }

        if (response.data.last !== true) {
          const nextPage = response.data.number + 1
          dispatch(setTaskData({ 'current': (response.data.number || 0) + 1, 'total': response.data.total_pages }))
          GetAllProducts({
            type: type,
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            dispatch: dispatch,
            page: nextPage,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => {
        // add retries
        if (retries < 10) {
          console.log('retry: ', retries)
          let newRetries = retries + 1
          GetAllProducts({
            type: type,
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            dispatch: dispatch,
            page: page,
            payload: payload,
            retries: newRetries,
            resolver: resolver || resolve
          });
        }
        if (retries >= 10) {
          reject(error)
        }
      });
  });
};

export default GetAllProducts