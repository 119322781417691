import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const PrinterFormFields = () => {
  const role = useSelector(getRole);

  const printerFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'select', name: 'Reseller', id: 'reseller_id', formdatafield: 'reseller_id', required: true },
    { type: 'text', name: 'Name', id: 'name', required: true },
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', required: true },
    { type: 'text', name: 'Serial Number', id: 'serial_number', required: true },
    { type: 'number', name: 'Dots Per Line', id: 'dots_per_line', required: false },
    { type: 'text', name: 'Device ID', id: 'device_id', required: false },
    { type: 'hidden', name: 'backup_printer', id: 'backup_printer', readonly: true },
    { type: 'number', name: 'Receipt print amount', id: 'receipt_print_amount' },
    { type: 'checkbox', name: 'Active', id: 'active' },
  ]

  if (role === 'super' || role === 'admin') {
    printerFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'admin_id', formdatafield: 'admin_id', required: true },
    )
    printerFormFields.splice(2, 1, // overwrite index 2 
      { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id', required: true },
    )
  }

  return printerFormFields
}