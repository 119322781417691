import { useSelector, useDispatch } from "react-redux";
import { getOrder } from "../../redux/dataSlices/orderSlice";
import BasicCard from "../../components/card";
import CreateTransactionForm from "../../components/createTransactionForm";
import HandleOnChange from "../../helpers/handleOnChange";
import { useEffect, useState } from "react";
import { createTransactionFormFields } from "./orderFormFields.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { paymentChoices } from "../../selectfieldchoices/paymentselect.mjs";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { useNavigate } from "react-router-dom";
import CreateInvoice from "../../helpers/createInvoice";
import CreateTransaction from "../../helpers/createTransaction";
import PostInvoice from "../../actablueAPI/invoicepayments/PostInvoice";
import PostTransaction from "../../actablueAPI/invoicepayments/PostTransaction";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import GetTransactionOrderById from "../../actablueAPI/invoicepayments/GetTransactionOrderByID";
import { setMessage } from "../../redux/dataSlices/messageSlice";
import CheckExcistingInvoice from "../../helpers/checkExcistingInvoice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import useHandleError from "../../customhooks/useHandleError";

const CreateTransactionFromOrder = () => {
  const token = useSelector(getToken)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const order = useSelector(getOrder)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({})
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newForm = HandleOnChange({ event: event, object: form })
    setForm(newForm)
  }

  const onTypeChange = (event) => {
    let newForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: form, selectfield: 'payment_type' })
    setForm(newForm)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    CheckExcistingInvoice({token:token, admin_id:admin_id, location_id:location_id, order:order})
    .then(invoice => {
      if (!invoice) {
        invoice = CreateInvoice(order)
      }
      PostInvoice({token: token, admin_id: order.administration_id, invoice: invoice})
      .then(responseInvoice => {
        const transaction = CreateTransaction(order, responseInvoice.data, form.payment_type)
        PostTransaction({token: token, admin_id: order.administration_id, transaction: transaction})
        .then(responseTransaction => {
          navigate("/orders")  
        })
        .catch(error => {
          HandleError({ error: error })
        })
      })
      .catch(error => {
        HandleError({ error: error })
      })
    })
    .catch(error => {
      HandleError({ error: error })
    })
}

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: createTransactionFormFields,
    field: {
      onChange: onChange
    },
    data: form,
    payment_type: {
      label: 'payment_type',
      options: paymentChoices,
      selected: [],
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  }

  const card = {
    size: 12,
    title: 'Create Transaction',
    data: <CreateTransactionForm {...formData} />,
  }
  
  useEffect(() => {
    dispatch(setNavBarTitle('Create Transaction from unpaid order'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // check if there is already a transaction
    GetTransactionOrderById({token: token, admin_id: order.administration_id, id: order.id })
    .then(responseTransactionOrder => {
      if (responseTransactionOrder.length > 0) {
        dispatch(setMessage('Order has a transaction, action not possible'))
        navigate("/orders")  
      }
    })
    .catch(error => {
      HandleError({ error: error })
    })
    // eslint-disable-next-line
  }, []);
  

  return (
    <>
      <BasicCard {...card} />
    </>
  )
}

export default CreateTransactionFromOrder;
