import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';

import MultiSelectField from './multiselectField';

export default function ReservationAssignForm(props) {

  if (props.data.hasOwnProperty('reservation_objects')) {
    // eslint-disable-next-line
    props.data.reservation_objects.map((reservation_object) => {
      props.reservation_objects.selected.push({ 'value': reservation_object.reservation_object_id, 'label': reservation_object.name ? reservation_object.name : reservation_object.title })
    })
  }

  if (props.hasOwnProperty('reservation_objects_data')) {
    // eslint-disable-next-line
    props?.reservation_objects_data?.map((reservation_object) => {
      props.reservation_objects.options.push({ 'id': reservation_object.id, 'name': reservation_object.name ? `(${reservation_object.capacity}) ${reservation_object.name}` : reservation_object.title })
    })
  }

  let basicFields = []
  props.fields.forEach(field => {
    basicFields.push(
      field.type !== 'hidden' ?
        <Form.Group as={Row} className="mb-3" controlId={field.id}>
          <Col sm="10">
            <MultiSelectField {...props[field.formdatafield]} prefix={field.prefix} />
          </Col>
        </Form.Group>
        :
        ''
    )
  });

  return (
    <>
      <Form>
        <div className='tab-container'>
          {basicFields}
        </div>
      </Form>
    </>
  );
}