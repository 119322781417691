export default function CreateAdminRegistrationHashBasedOnAdminId(registrations) {
  let hash = {}
  registrations.forEach(registration => {
    registration.settings.forEach(setting => {
      if (!hash[registration.administration_id]) {
        hash[registration.administration_id] = {}
        hash[registration.administration_id]['registrations'] = []
      }
      let newSetting = JSON.parse(JSON.stringify(setting))
      newSetting['registration_id'] = registration.id
      hash[registration.administration_id]['registrations'].push(newSetting)
      let type = setting.subscription.type.type
      hash[registration.administration_id][type] = true
    });
  });
  return hash
}