import OrderButtons from "../portal/orders/orderbuttons";
import OrderModal from "./orderModal";
import { useTranslation } from 'react-i18next';
import { Button, Table, Dropdown, Form, Navbar } from 'react-bootstrap';
import PlanningButtons from "../portal/planning/planningbuttons";
import UnpaidOrderPlanModal from "./unpaidOrderPlanModal";
import TableButton from "./tableButton";

export default function UnpaidOrderTable(props) {
  const orders = props.orders || []
  const orderButtons = OrderButtons.call()
  const planningButtons = PlanningButtons.call()
  const modalview = orderButtons?.hasOwnProperty('modalview') ? Object.assign({}, orderButtons.modalview) : false
  const planorder = planningButtons?.hasOwnProperty('planOrder') ? Object.assign({}, planningButtons.planOrder) : false
  const exportButton = orderButtons?.hasOwnProperty('export') ? Object.assign({}, orderButtons.export) : false
  const selected = orderButtons?.select?.selected
  const refreshButton = planningButtons.hasOwnProperty('refresh') ? Object.assign({}, planningButtons.refresh) : false
  const { t: translate } = useTranslation();

  let rows = []

  orders.forEach(order => {

    order?.relation_id && rows.push(
      <tr key={order?.id} className={selected?.hasOwnProperty(order.id) ? 'product-row selected' : 'product-row'}>
        <td>
          <Form>
            <Form.Check onChange={() => { orderButtons.select.onClick(order) }} checked={selected?.hasOwnProperty(order?.id)} />
          </Form>
        </td>
        <td>{order.number}</td>
        <td>{order.date ? new Date(order.date).toLocaleString() : new Date(order.modification).toLocaleString()}</td>
        <td align="right">{Number(order.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td className="text-center">{order.origin}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(order) }} id={order.id}>{translate(modalview.tag)}</Button>
          <Button size="sm" type='button' onClick={() => { planorder.onClick(order) }} id={order.id}>{translate(planorder.tag)}</Button>
        </td>
      </tr>
    )
  });

  return (
    <>
      {refreshButton && <TableButton {...refreshButton} />}
      {Object.keys(selected).length > 0 &&
        <Navbar expand="xxl" className="bg-body-tertiary">
          {Object.keys(selected).length} {translate('order(s) selected.')}
          <Dropdown className='actions-button'>
            <Dropdown.Toggle size='sm' type='button'>
              {translate('Actions')}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {exportButton && <Dropdown.Item onClick={() => { exportButton.onClick() }}>{exportButton.tag}</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      }
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {orders && orders?.length > 0 &&
                <Form>
                  <Form.Check onChange={() => { orderButtons.selectAll.onClick(orders) }} checked={orders?.every((order) => selected?.hasOwnProperty(order?.id))} />
                </Form>
              }
            </th>
            <th>{translate('Order number')}</th>
            <th>{translate('Date')}</th>
            <th className="text-end">{translate('Amount')}</th>
            <th className="text-center">{translate('Origin')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <OrderModal modal={modalview.modal} />
        <UnpaidOrderPlanModal modal={planorder.modal} />
      </Table>
    </>
  );
}