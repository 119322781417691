import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

const SmartPOSFormFields = () => {
  const role = useSelector(getRole)

  const settings = [
    { type: 'text', name: `Language`, id: 'language', prefix: 'settings' },

    { type: 'text', name: `Company name`, id: 'company_name', prefix: 'settings' },
    { type: 'text', name: `Address line 1`, id: 'line_one', prefix: 'settings' },
    { type: 'text', name: `Address line 2`, id: 'line_two', prefix: 'settings' },
    { type: 'text', name: `Website`, id: 'website', prefix: 'settings' },
    { type: 'text', name: `Email`, id: 'email', prefix: 'settings' },
    { type: 'text', name: `Telephone`, id: 'tel', prefix: 'settings' },
    { type: 'text', name: `Extra info`, id: 'extra_info', prefix: 'settings' },
    { type: 'text', name: `Receipt logo url`, id: 'receipt_logo_url', prefix: 'settings' },
    { type: 'file', name: `New Logo File (only PNG supported, 593x111 pxl, white background)`, id: 'new_receipt_logo', accept: 'image/png', prefix: 'new_receipt_logo' },
    { type: 'text', name: `Display logo url`, id: 'display_logo_url', prefix: 'settings' },
    { type: 'file', name: `New Display File (only PNG supported, 593x111 pxl, transparant or white background)`, id: 'new_display_logo', accept: 'image/png', prefix: 'new_display_logo' },
    { type: 'checkbox', name: 'Large customer display', id: 'is_large_customer_display', prefix: 'settings' },

    // { type: 'text', name: `receipt_footer`, id: 'receipt_footer', prefix: 'settings' },

    { type: 'select', name: 'Selected scanner', id: 'selected_scanner', formdatafield: 'selected_scanner', prefix: 'settings' },
    { type: 'select', name: 'Selected printer', id: 'selected_printer', formdatafield: 'selected_printer', prefix: 'settings' },
    { type: 'text', name: `Printer IP`, id: 'printer_ip', prefix: 'settings' },

    { type: 'select', name: 'EFT provider', id: 'eft_payment_provider', formdatafield: 'eft_payment_provider', prefix: 'settings' },
    { type: 'checkbox', name: 'MSP mini', id: 'use_mini', formdatafield: 'use_mini', prefix: 'settings' },
    { type: 'text', name: `CCV terminal IP`, id: 'ccv_terminal_ip', prefix: 'settings' },
    { type: 'text', name: `MSP api key`, id: 'msp_api', prefix: 'settings' },
    { type: 'text', name: `MSP mini api key`, id: 'msp_mini_api', prefix: 'settings' },
    { type: 'text', name: `MSP terminal ID`, id: 'msp_terminal_id', prefix: 'settings' },
    { type: 'checkbox', name: 'MSP ping for every pin transaction', id: 'ping_before_pay', formdatafield: 'ping_before_pay', prefix: 'settings' },
    { type: 'text', name: `MSP terminal IP`, id: 'msp_terminal_ip', prefix: 'settings' },

    { type: 'number', name: `Device low memory threshold`, id: 'device_low_memory_threshold', prefix: 'settings' },
    { type: 'number', name: `Local stored receipts threshold`, id: 'device_receipt_history', prefix: 'settings' },
    { type: 'number', name: `Sync threshold`, id: 'batch_sync_int', prefix: 'settings' },
    
    { type: 'checkbox', name: 'Use weighted or priced barcodes', id: 'scan_weight_and_price', prefix: 'settings' },
    { type: 'checkbox', name: 'Priced barcodes (on), Weighted barcodes (off)', id: 'is_price_barcodes', prefix: 'settings' },
    { type: 'number', name: `Lenght skucodes used (4 or 5)`, id: 'barcode_sub_string_amount', prefix: 'settings', min: '4', max: '5' },

    // { type: 'checkbox', name: 'Auto logout employee', id: 'auto_logout_employee', prefix: 'settings' },
    // { type: 'checkbox', name: 'Auto logout treshold', id: 'auto_logout_millis', prefix: 'settings' },

    // { type: 'checkbox', name: 'Force mail login', id: 'mail', prefix: 'settings' },
    // { type: 'checkbox', name: 'Force employee login', id: 'force_employee_login', prefix: 'settings' },
    { type: 'checkbox', name: 'Always open cash drawer', id: 'always_open_cash_drawer', prefix: 'settings' },
    { type: 'checkbox', name: 'Always print receipt', id: 'always_print', prefix: 'settings' },
    { type: 'checkbox', name: 'Show non active', id: 'show_non_active', prefix: 'settings' },
    // { type: 'checkbox', name: 'Search bar enabled', id: 'search_bar_enabled', prefix: 'settings' },
    // { type: 'checkbox', name: 'Check msp intent', id: 'check_msp_intent', prefix: 'settings' },
    // { type: 'checkbox', name: 'Repost transaction', id: 'repost_transaction', prefix: 'settings' },
    // { type: 'checkbox', name: 'Online mode', id: 'online_mode', prefix: 'settings' },
    { type: 'checkbox', name: 'Hide connection hints', id: 'hide_connection_hints', prefix: 'settings' },
    { type: 'checkbox', name: 'Ask always for relation', id: 'request_relation_dialog', prefix: 'settings' },
    { type: 'checkbox', name: 'Ask always for male/female/country', id: 'request_customer_details_dialog', prefix: 'settings' },
    { type: 'checkbox', name: 'Round cash payment ', id: 'round_receipt', prefix: 'settings' },
    // { type: 'checkbox', name: 'Pin device', id: 'pin_device', prefix: 'settings' },

    { type: 'checkbox', name: 'Require settings pin', id: 'require_settings_pin', prefix: 'settings' },
    { type: 'text', name: `Settings PIN`, id: 'super_user_pin', prefix: 'settings' },

    // { type: 'checkbox', name: 'Vectron api switch', id: 'vectron_api_switch', prefix: 'settings' },
    // { type: 'text', name: `API key`, id: 'api_key', prefix: 'settings' },

    { type: 'checkbox', name: 'Ask always for guestcheck', id: 'table_guest_switch', prefix: 'settings' },
    { type: 'checkbox', name: 'Disable toasts', id: 'disable_toasts', prefix: 'settings' },
    { type: 'checkbox', name: 'Force CASH first split', id: 'force_cash_first_split', prefix: 'settings' },
    // { type: 'text', name: `min_table`, id: 'min_table', prefix: 'settings' },
    // { type: 'text', name: `max_table`, id: 'max_table', prefix: 'settings' },

    // { type: 'text', name: `selected_theme`, id: 'selected_theme', prefix: 'settings' },
    // { type: 'text', name: `theme`, id: 'theme', prefix: 'settings' },
  ]

  const info = [
  ]

  let smartposDeviceFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'uid', id: 'uid', readonly: true },

    // single option select fields  
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'hidden', name: 'Administration', id: 'administration_id', readonly: true },
    { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id' },

    // single option fields
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: 'Actablue device id', id: 'serial_number' },
    { type: 'email', name: 'Email', id: 'email' },
    { type: 'checkbox', name: 'Active', id: 'active' },

    // single option object fields
    { type: 'object', name: 'Settings', id: 'settings', fields: settings },
    { type: 'object', name: 'Info', id: 'info', fields: info },


    // multiple options select field
    // terminals
  ]

  if (role === 'super' || role === 'admin') {
    smartposDeviceFormFields.splice(3, 1, // overwrite index 3 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id' },
    )
  }

  return smartposDeviceFormFields
}

export default SmartPOSFormFields