import React from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useFlag } from '@unleash/proxy-client-react';
import { Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from '../redux/dataSlices/tokenSlice';

import "./portal.css"
import ErrorToast from "../components/errorToast";
import Sidebar from './sidebar/sidebar';
import MainNavbar from './navbar/navbar';
import MessageToast from '../components/messageToast';
import ProgressCard from '../components/progressCard';
import IsTokenExpiredV2 from '../helpers/tokenExpiredCheckV2';
import SubscriptionExpiredAlert from '../components/SubscriptionExpiredAlert';

export default function Portal() {
  const token = useSelector(getToken);
  const packageJson = require("../../package.json")
  const currentVersion = packageJson.version
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const websiteUrl = window.location.href;

  const testFeatureFlag = useFlag('test')

  useEffect(() => {
    const localVersion = localStorage.getItem('portal_version');
    if (localVersion && JSON.parse(localVersion) !== currentVersion) {
      navigate('/dashboards'); // This is to prevent error messages on pages like edit pages, annoying for the user but also prevents errors
      window.location.reload(true); // Refresh the page
    }
    localStorage.setItem('portal_version', JSON.stringify(currentVersion));
    // eslint-disable-next-line
  }, [currentVersion])

  useEffect(() => {
    const intervalId = setInterval(async () => {  // Assign interval to a variable to clear it.
      await IsTokenExpiredV2({ token: token, dispatch: dispatch });
    }, 10000)

    return () => clearInterval(intervalId); // This is important to clear the interval when the component unmounts
  }, [dispatch, token])

  if (!token) {
    return (
      <div>
        <Navigate to='/' />
      </div>
    )
  }

  if (testFeatureFlag) {
    console.log('featureflag test', testFeatureFlag)
  }

  if (!testFeatureFlag) {
    console.log('featureflag test', testFeatureFlag)
  }
  
  return (
    <>
      <Container fluid>
        <div className="toast-container">
          <ErrorToast />
          <MessageToast />
          <SubscriptionExpiredAlert />
        </div>
        <Row>
          <Sidebar />
          <Col className="main-body">
            <MainNavbar />
            <Row xs={1} md={4} className="content g-4">
              <Outlet />
            </Row>
          </Col>
          <ProgressCard />
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col className='main-footer'>
            <ul className='align-start'>
              <li>
                {websiteUrl?.includes('humbee') ?
                  <p>Humbee &copy; {(new Date().getFullYear())}</p>
                  :
                  <p>ActaBlue &copy; {(new Date().getFullYear())}</p>
                }
              </li>
            </ul>
            <ul className='align-end'>
              <li>
                <p>v.{currentVersion}</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
}
