import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getEmployeeRolesAll, setEmployeeRolesAll } from "../../redux/dataSlices/employeeSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import EmployeeRoleTable from "../../components/employeeRoleTable";
import GetEmployeeRoles from "../../actablueAPI/employee/GetEmployeeRoles";
import useHandleError from "../../customhooks/useHandleError";

const EmployeeRoles = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const employees_roles = useSelector(getEmployeeRolesAll);
  const dispatch = useDispatch();
  const HandleError = useHandleError();

  const card = {
    size: 12,
    title: "Employee roles",
    data: <EmployeeRoleTable {...employees_roles} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Employee roles"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      GetEmployeeRoles({ token: token, admin_id: admin_id })
        .then(response => {
          dispatch(setEmployeeRolesAll(response.data))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [admin_id]);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default EmployeeRoles;
