export default function CalculateReportingTotals(data) {
  let totals = {
    'timestamp': Date.now(),
    'cash': 0.00,
    'exchange': 0.00,
    'eft': 0.00,
    // 'off_account': 0.00,
    'on_account': 0.00,
    'total': 0.00
  }
  if (data.days) {
    for (const day in data.days) {
      totals.cash += data.days[day].totals.cash
      totals.eft += data.days[day].totals.eft
      totals.exchange += data.days[day].totals.exchange
      // totals.off_account += data.days[day].totals.off_account
      totals.on_account += data.days[day].totals.on_account
      // totals.total += data.days[day].totals.total
    }
  }

  if (!data.days) {
    for (const report in data) {
      totals.timestamp = data[report].timestamp
      totals.cash =+ data[report].cash
      totals.eft =+ data[report].eft
      totals.exchange =+ data[report].exchange
      // totals.off_account =+ data[report].off_account
      totals.on_account =+ data[report].on_account
      // totals.total =+ data[report].total
    }
  }

  totals.total = (totals.cash + totals.eft + totals.on_account - totals.exchange)
  totals.total.toFixed(2)

  return totals
}