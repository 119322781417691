import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getProductPagesize, getProductPagination } from "../../redux/dataSlices/productSlice";
import { getProductSuppliers, setProductSuppliers } from "../../redux/dataSlices/productsupplierSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import ProductSupplierTable from "../../components/productsupplierTable";
import GetProductSupplierList from "../../actablueAPI/products/GetProductSupplierList";

const Products = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const productsuppliers = useSelector(getProductSuppliers);
  const pagination = useSelector(getProductPagination)
  const pageSize = useSelector(getProductPagesize)
  const dispatch = useDispatch();
  const navTitle = "Product suppliers"

  const productsuppliersCard = {
    size: 12,
    title: navTitle,
    data: <ProductSupplierTable {...productsuppliers} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      GetProductSupplierList({token:token, admin_id:admin_id, location_id:location_id, page:pagination, size:pageSize})
      .then(response => {
        dispatch(setProductSuppliers(response.data))
        dispatch(setLoading(false))
      })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pageSize]);

  return (
    <>
      <BasicCard {...productsuppliersCard} />
    </>
  );
};
export default Products;