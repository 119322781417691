export default function AnalyseTransactionDescription (transactions) {
  let totalPaidNetto = 0
  let descriptReport = []

  for (let transaction of transactions) {
    const paidNetto = Number(transaction.amount_paid) - Number(transaction.amount_exchange)
    totalPaidNetto += paidNetto
    
    try {
      const descript = JSON.parse(transaction.descript)
      if (descript.Country) {
        if (descriptReport[descript.Country]) {
          descriptReport[descript.Country].revenue += paidNetto
          if (descript.Gender) {
            descriptReport[descript.Country][descript.Gender].revenue += paidNetto
          }
        }
        if (!descriptReport[descript.Country]) {
          descriptReport[descript.Country] = {
            country: descript.Country,
            revenue: paidNetto,
            percentage: 0
          }
          if (descript.Gender) {
            descriptReport[descript.Country][descript.Gender] = {revenue: paidNetto}
          }
        }
      }
      if (descript.Gender) {
        if (descriptReport[descript.Gender]) {
          descriptReport[descript.Gender].revenue += paidNetto
        }
        if (!descriptReport[descript.Gender]) {
          descriptReport[descript.Gender] = {
            gender: descript.Gender,
            revenue: paidNetto,
            percentage: 0
          }
        }
      }
    } catch (error) {
      // console.log('error', error)
      if (descriptReport['No country selected']) {
        descriptReport['No country selected'].revenue += paidNetto
      }
      if (!descriptReport['No country selected']) {
        descriptReport['No country selected'] = {
          revenue: paidNetto,
          percentage: 0
        }
      }
    }
  };

  // add percentage to descriptReport
  for (let [country, countryData] of Object.entries(descriptReport)) {
    if (countryData.revenue) {
      countryData.percentage = ((countryData.revenue * 100) / totalPaidNetto)//.toFixed(2)
    }
    for (let [gender, genderData] of Object.entries(countryData)) {
      if (genderData.revenue) {
        genderData.percentage = ((genderData.revenue * 100) / totalPaidNetto)//.toFixed(2)
      }
    }
  }

  return descriptReport
}