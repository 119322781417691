import { createSlice } from "@reduxjs/toolkit";

export const loyaltySlice = createSlice({
  name: "loyalty",
  initialState: {
    program_pagesize: 10,
    program_pagination: 0,
    hash_programs_all: {},
    card_pagesize: 10,
    card_pagination: 0,
    hash_cards_all: {},
  },
  reducers: {
    setProgramId: (state, action) => {
      state.program_id = action.payload;
    },
    setProgram: (state, action) => {
      state.program = action.payload;
    },
    setPrograms: (state, action) => {
      state.programs = action.payload
    },
    setProgramsAll: (state, action) => {
      state.programs_all = action.payload
      action?.payload?.items?.forEach(program => {
        state.hash_programs_all[program.id] = program
      });
    },
    setProgramModalShow: (state, action) => {
      state.program_modalshow = action.payload
    },
    setProgramPagination: (state, action) => {
      state.program_pagination = (action.payload - 1)
    },
    setProgramPageSize: (state, action) => {
      state.program_pagesize = action.payload
    },
    setCardId: (state, action) => {
      state.card_id = action.payload;
    },
    setCard: (state, action) => {
      state.card = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload
    },
    setCardsAll: (state, action) => {
      state.cards_all = action.payload
      action?.payload?.items?.forEach(card => {
        state.hash_cards_all[card.id] = card
      });
    },
    setCardModalShow: (state, action) => {
      state.card_modalshow = action.payload
    },
    setCardPagination: (state, action) => {
      state.card_pagination = (action.payload - 1)
    },
    setCardPageSize: (state, action) => {
      state.card_pagesize = action.payload
    }
  }
});

export const {
  setProgramId, setProgram, setProgramModalShow, setProgramPageSize, setProgramPagination, setPrograms, setProgramsAll,
  setCardId, setCard, setCardModalShow, setCardPageSize, setCardPagination, setCards, setCardsAll
} = loyaltySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getProgramId = (state) => state.loyalty.program_id;
export const getProgram = (state) => state.loyalty.program;
export const getPrograms = (state) => state.loyalty.programs;
export const getProgramsAll = (state) => state.loyalty.programs_all;
export const getHashProgramsAll = (state) => state.loyalty.hash_programs_all;
export const getProgramModalShow = (state) => state.loyalty.program_modalshow;
export const getProgramPagination = (state) => state.loyalty.program_pagination;
export const getProgramPageSize = (state) => state.loyalty.program_pagesize;

export const getCardId = (state) => state.loyalty.card_id;
export const getCard = (state) => state.loyalty.card;
export const getCards = (state) => state.loyalty.cards;
export const getCardsAll = (state) => state.loyalty.cards_all;
export const getHashCardsAll = (state) => state.loyalty.hash_cards_all;
export const getCardModalShow = (state) => state.loyalty.card_modalshow;
export const getCardPagination = (state) => state.loyalty.card_pagination;
export const getCardPageSize = (state) => state.loyalty.card_pagesize;

export default loyaltySlice.reducer;