import { useSnackbar } from "notistack";

export default function useHandleError() {
    const { enqueueSnackbar } = useSnackbar();

    const HandleError = ({ error, variant, persist, preventDuplicate, transitionDuration, anchorOrigin, style }) => {
        console.error('HandleError:', error);

        enqueueSnackbar(error?.message || error || 'An unexpected error occurred', {
            variant: variant || 'error',
            persist: persist || false,
            preventDuplicate: preventDuplicate || true,
            transitionDuration: transitionDuration || { enter: 225, exit: 195 },
            anchorOrigin: anchorOrigin || { horizontal: 'right', vertical: 'bottom' },
            style: style
        });
    };

    return HandleError;
}