export const orderLines =
  [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'ccv_id', id: 'ccv_id' },
    { type: 'hidden', name: 'operator_id', id: 'operator_id', readonly: true },
    { type: 'hidden', name: 'product_id', id: 'product_id' },
    { type: 'hidden', name: 'attributes', id: 'attributes' },
    { type: 'hidden', name: 'user_id', id: 'user_id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },

    { type: 'text', name: 'type', id: 'type' },
    { type: 'text', name: 'status', id: 'status' },
    { type: 'text', name: 'origin', id: 'origin' },

    { type: 'text', name: 'quantity', id: 'quantity' },
    { type: 'text', name: 'product_name', id: 'product_name' },
    { type: 'text', name: 'descript', id: 'descript' },

    { type: 'text', name: 'price_exclusive_vat', id: 'price_exclusive_vat' },
    { type: 'text', name: 'price_inclusive_vat', id: 'price_inclusive_vat' },

    { type: 'text', name: 'amount_exclusive_vat', id: 'amount_exclusive_vat' },
    { type: 'text', name: 'amount_inclusive_vat', id: 'amount_inclusive_vat' },

    { type: 'text', name: 'vat_percentage', id: 'vat_percentage' },

    { type: 'datetime-local', name: 'creation', id: 'creation', readonly: true },
    { type: 'datetime-local', name: 'modification', id: 'modification', readonly: true },
  ]

export const orderFormFields =
  [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'user_id', id: 'user_id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'hidden', name: 'customer_id', id: 'customer_id', readonly: true },
    { type: 'hidden', name: 'relation_id', id: 'relation_id', readonly: true },
    { type: 'hidden', name: 'contact_id', id: 'contact_id', readonly: true },
    { type: 'hidden', name: 'ccv_order_id', id: 'ccv_order_id', readonly: true },
    { type: 'hidden', name: 'ccv_order_number', id: 'ccv_order_number', readonly: true },
    { type: 'hidden', name: 'lightspeed_id', id: 'lightspeed_id', readonly: true },
    { type: 'hidden', name: 'lightspeed_number', id: 'lightspeed_number', readonly: true },
    { type: 'hidden', name: 'tastyigniter_id', id: 'tastyigniter_id', readonly: true },
    { type: 'hidden', name: 'operator_id', id: 'operator_id', readonly: true },
    { type: 'text', name: 'name', id: 'name' },
    { type: 'text', name: 'descript', id: 'descript' },
    { type: 'text', name: 'origin', id: 'origin' },
    { type: 'text', name: 'type', id: 'type' },
    { type: 'text', name: 'status', id: 'status' },
    { type: 'text', name: 'payment_amount_exclusive_vat', id: 'payment_amount_exclusive_vat' },
    { type: 'text', name: 'payment_amount_inclusive_vat', id: 'payment_amount_inclusive_vat' },
    { type: 'text', name: 'is_paid', id: 'is_paid' },
    { type: 'form', name: 'order_lines', id: 'order_lines', formFields: orderLines },
    { type: 'text', name: 'delivery', id: 'delivery' },
    { type: 'datetime-local', name: 'order_date', id: 'order_date' },
    { type: 'datetime-local', name: 'creation', id: 'creation', readonly: true },
    { type: 'datetime-local', name: 'modification', id: 'modification', readonly: true },
  ]

export const createTransactionFormFields = 
[
  { type: 'select', name: 'Payment type', id: 'payment_type', formdatafield: 'payment_type', required: true },

]