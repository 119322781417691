export const metaDataHighlights = (country, gender) => {
  let newReportHighlights = []

  newReportHighlights.push({'*** Country top 3 ***':{}})

  let count = 0
  if (country?.metadata?.Country) {
    for (const [countryName, countryQuantity] of Object.entries(country.metadata.Country).sort((a, b) => b[1] - a[1])) {
      if (count < 3) {
        let newLine = {}
      // newLine[countryName] = {amount:Number(countryData.revenue).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:countryData.percentage.toFixed(2)+'%'}
      newLine[countryName] = {quantity: countryQuantity}
      newReportHighlights.push(newLine)
      count = count + 1
      }
    }
  }
  
  newReportHighlights.push({'*** Gender ***': {}})

  if (gender?.metadata?.Gender) {
    for (const [genderName, genderQuantity] of Object.entries(gender.metadata.Gender).sort((a, b) => b[1] - a[1])) {
      let newLine = {}
      // newLine[genderName] = {amount:Number(countryData.revenue).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:countryData.percentage.toFixed(2)+'%'}
      newLine[genderName] = {quantity: genderQuantity}
      newReportHighlights.push(newLine)
    }
  
  }
  
  return newReportHighlights
};