import axios from "axios";

const GetProductScanLive = ({token, admin_id, location_id, ean}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/product/scan/live`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'ean': ean,
        'location': location_id  
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)});
  });
};

export default GetProductScanLive