import axios from "axios";

const GetSubscriptionAll = ({token}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/registrations/subscription/all`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    });
  });
};

export default GetSubscriptionAll