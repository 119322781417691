export default function ReservationHighLightCalculation (reservations) {
    let guests = 0
    let returning = 0
    let emails = []
    for (let reservation in reservations) {
      guests = guests + reservations[reservation].number_of_people
      if (emails.includes(reservations[reservation].reservation_email)) {
        returning = returning + 1
      } else {emails.push(reservations[reservation].reservation_email)}
    }

    return [{Guests:guests}, {Returning:returning}, {Reservations:reservations.length}]
}