import axios from "axios";

const GetUserRoleDefinitions = ({ token, admin_id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/serials/roledefinitions"),
      headers: {
        'Authorization': 'Bearer '.concat(token['access_token']),
        'Content-Type': 'application/json',
        'administration': admin_id
      }
    })
      .then(response => {resolve(response)})
      .catch(error => {reject(error)})
  });
};

export default GetUserRoleDefinitions