import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getAccountingModalShow, setAccountingModalShow, setAccounting } from "../../redux/dataSlices/accountingSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";


export default function AccountingButtons() {
  const modalShow = useSelector(getAccountingModalShow)
  // const pageSize = useSelector(getDevicePagesize)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(company) {
    dispatch(setAccounting(company));
    dispatch(setAccountingModalShow(true));
  };


  function onclickEdit(company) {
    dispatch(setAccounting(company));
    navigate("/accounting/edit");
  };

  const onViewHide = () => {
    dispatch(setAccountingModalShow(false));
  };

  const onclickAdd = () => {
    navigate("/accounting/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.accounting >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.accounting >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

