import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import AccountingForm from "../../components/accountingForm";
import { accountingFormFields } from "./accoutingFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import PostPaymentLedgers from "../../actablueAPI/accounting/PostPaymentLedgers";
// import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";

const AccountingAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  // const locations_all = useSelector(getLocationsAll)
  // const hash_locations_all = useSelector(getHashLocationsAll)
  // const accounting = useSelector(getAccounting)
  const navigate = useNavigate();
  const [accounting, setAccounting] = useState({ administration_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newCompany = HandleOnChange({ event: event, object: accounting })
    setAccounting(newCompany)
  }

  // const onLocationIdChange = (event) => {
  //   let newCompany = HandleOnChangeSingleSelectFieldWithValue({event:event, object:accounting, selectfield:'location_id'})
  //   setAccounting(newCompany)
  // }
  
  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostPaymentLedgers({ token: token, data: accounting })
      .then(() => {
        navigate("/snelstart");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: accountingFormFields,
    field: {
      onChange: onChange
    },
    data: accounting,
  };

  const card = {
    size: 12,
    title: "Accounting add ",
    data: <AccountingForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default AccountingAdd;

