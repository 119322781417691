import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';

export const reservationSlice = createSlice({
  name: "reservation",
  initialState: {
    reservation_pagesize: 10,
    reservationobject_pagesize: 10,
    reservationoption_pagesize: 10,
    reservationlocation_pagesize:10,
    reservation_pagination: 0,
    reservationobject_pagination: 10,
    reservationoption_pagination: 10,
    reservationlocation_pagination:10,
    hash_reservations_all: {},
    hash_reservationobject_all: {},
    hash_reservationlocations_all:{},
    // start_date: moment().startOf('day'),
    start_date: moment().startOf('day').format('YYYY-MM-DD:HH:mm:ssZZ'),
    // end_date: moment().endOf('day')
    end_date: moment().endOf('day').format('YYYY-MM-DD:HH:mm:ssZZ')
  },
  reducers: {
    setReservationId: (state, action) => {
      state.reservation_id = action.payload;
    },
    setReservation: (state, action) => {
      state.reservation = action.payload;
    },
    setReservations: (state, action) => {
      state.reservations = action.payload
    },
    setReservationsAll: (state, action) => {
      state.reservations_all = action.payload
      action?.payload?.forEach(reservation => {
        state.hash_reservations_all[reservation.id] = reservation
      });
    },
    editReservations: (state, action) => {
      for (let reservation in state.reservations) {
        if (state.reservations[reservation].id === action.payload.id) {
          state.reservations[reservation] = action.payload
        }
      }
    },
    setReservationsHighLights: (state, action) => {
      state.reservationshighlights = action.payload
    },
    setReservationModalShow: (state, action) => {
      state.reservationmodalshow = action.payload
    },
    setReservationTimelineModalShow: (state, action) => {
      state.reservationtimelinemodalshow = action.payload
    },
    setReservationWithoutObjectModalShow: (state, action) => {
      state.reservationwithoutobjectmodalshow = action.payload
    },
    setReservationPagination: (state, action) => {
      state.reservation_pagination = (action.payload - 1)
    },
    setReservationPagesize: (state, action) => {
      state.reservation_pagesize = action.payload
    },

    setReservationObjectId: (state, action) => {
      state.reservationobject_id = action.payload;
    },
    setReservationObject: (state, action) => {
      state.reservationobject = action.payload;
    },
    setReservationObjects: (state, action) => {
      state.reservationobjects = action.payload
      action?.payload?.forEach(reservationobject => {
        state.hash_reservationobject_all[reservationobject.id] = reservationobject
      })
    },
    editReservationObjects: (state, action) => {
      for (let reservationobject in state.reservationobjects) {
        if (state.reservationobjects[reservationobject].id === action.payload.id) {
          state.reservationobjects[reservationobject] = action.payload
        }
      }
    },
    setReservationObjectModalShow: (state, action) => {
      state.reservationobjectmodalshow = action.payload
    },
    setReservationObjectPagination: (state, action) => {
      state.reservationobject_pagination = (action.payload - 1)
    },
    setReservationObjectPagesize: (state, action) => {
      state.reservationobject_pagesize = action.payload
    },

    setReservationOptionId: (state, action) => {
      state.reservationoption_id = action.payload;
    },
    setReservationOption: (state, action) => {
      state.reservationoption = action.payload;
    },
    setReservationOptions: (state, action) => {
      state.reservationoptions = action.payload
      let hashMap = {}
      action?.payload?.forEach(reservation_option => {
        hashMap[reservation_option.id] = reservation_option
      });
      state.hashreservationoptionsall = hashMap
    },
    editReservationOptions: (state, action) => {
      for (let reservationoption in state.reservationoptions) {
        if (state.reservationoptions[reservationoption].id === action.payload.id) {
          state.reservationoptions[reservationoption] = action.payload
        }
      }
    },
    setReservationOptionModalShow: (state, action) => {
      state.reservationoptionmodalshow = action.payload
    },
    setReservationOptionPagination: (state, action) => {
      state.reservationoption_pagination = (action.payload - 1)
    },
    setReservationOptionPagesize: (state, action) => {
      state.reservationoption_pagesize = action.payload
    },

    setReservationLocationId: (state, action) => {
      state.reservationlocation_id = action.payload;
    },
    setReservationLocation: (state, action) => {
      state.reservationlocation = action.payload;
    },
    setReservationLocations: (state, action) => {
      state.reservationlocations = action.payload
    },
    setReservationLocationsAllAdmins: (state, action) => {
      state.reservationlocations_all_admins = action.payload
      action?.payload?.forEach(reservationlocation => {
        state.hash_reservationlocations_all[reservationlocation.id] = reservationlocation
      });
    },
    setReservationLocationsAll: (state, action) => {
      state.reservationlocations_all = action.payload
      action?.payload?.forEach(reservationlocation => {
        state.hash_reservationlocations_all[reservationlocation.id] = reservationlocation
      });
    },
    setReservationLocationModalShow: (state, action) => {
      state.reservationlocation_modalshow = action.payload
    },
    setReservationLocationPagination: (state, action) => {
      state.reservationlocation_pagination = (action.payload - 1)
    },
    setReservationLocationPagesize: (state, action) => {
      state.reservationlocation_pagesize = action.payload
    },

    setStartDate: (state, action) => {
      state.start_date = action.payload
    },

    setEndDate: (state, action) => {
      state.end_date = action.payload
    },
  }
});

export const {
  setReservationId, setReservation, setReservations, setReservationsAll, editReservations, setReservationsHighLights, setReservationModalShow, setReservationWithoutObjectModalShow, setReservationTimelineModalShow, setReservationPagination, setReservationPagesize,
  setReservationObjectId, setReservationObject, setReservationObjects, editReservationObjects, setReservationObjectModalShow, setReservationObjectPagination, setReservationObjectPagesize,
  setReservationOptionId, setReservationOption, setReservationOptions, editReservationOptions, setReservationOptionModalShow, setReservationOptionPagination, setReservationOptionPagesize,
  setReservationLocationId, setReservationLocation, setReservationLocations, setReservationLocationModalShow, setReservationLocationPagination, setReservationLocationPagesize, setReservationLocationsAll,
  setStartDate, setEndDate

} = reservationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getReservationId = (state) => state.reservation.reservation_id
export const getReservation = (state) => state.reservation.reservation
export const getReservations = (state) => state.reservation.reservations
export const getReservationsAll = (state) => state.reservation.reservations_all
export const getHashReservationsAll = (state) => state.reservation.hash_reservations_all
export const getReservationsHighlights = (state) => state.reservation.reservationshighlights
export const getReservationTimelineModalShow = (state) => state.reservation.reservationtimelinemodalshow
export const getReservationWithoutObjectModalShow = (state) => state.reservation.reservationwithoutobjectmodalshow
export const getReservationModalShow = (state) => state.reservation.reservationmodalshow
export const getReservationPagination = (state) => state.reservation.reservation_pagination
export const getReservationPagesize = (state) => state.reservation.reservation_pagesize

export const getReservationObjectId = (state) => state.reservation.reservationobject_id
export const getReservationObject = (state) => state.reservation.reservationobject
export const getReservationObjects = (state) => state.reservation.reservationobjects
export const getHashReservationObjectsAll = (state) => state.reservation.hash_reservationobject_all

export const getReservationObjectModalShow = (state) => state.reservation.reservationobjectmodalshow
export const getReservationObjectPagination = (state) => state.reservation.reservationobject_pagination
export const getReservationObjectPagesize = (state) => state.reservation.reservationobject_pagesize

export const getReservationOptionId = (state) => state.reservation.reservationoption_id
export const getReservationOption = (state) => state.reservation.reservationoption
export const getReservationOptions = (state) => state.reservation.reservationoptions
export const getHashReservationOptionsAll = (state) => state.reservation.hashreservationoptionsall
export const getReservationOptionModalShow = (state) => state.reservation.reservationoptionmodalshow
export const getReservationOptionPagination = (state) => state.reservation.reservationoption_pagination
export const getReservationOptionPagesize = (state) => state.reservation.reservationoption_pagesize

export const getReservationLocationLoading = (state) => state.reservation.reservationlocation_loading
export const getReservationLocationId = (state) => state.reservation.reservationlocation_id
export const getReservationLocation = (state) => state.reservation.reservationlocation
export const getReservationLocations = (state) => state.reservation.reservationlocations
export const getReservationLocationsAll = (state) => state.reservation.reservationlocations_all
export const getHashReservationLocationsAll = (state) => state.reservation.hash_reservationlocations_all
export const getReservationLocationsHighlights = (state) => state.reservation.reservationlocationshighlights
export const getReservationLocationModalShow = (state) => state.reservation.reservationlocation_modalshow 
export const getReservationLocationPagination = (state) => state.reservation.reservationlocation_pagination
export const getReservationLocationPagesize = (state) => state.reservation.reservationlocation_pagesize

export const getStartDate = (state) => state.reservation.start_date
export const getEndDate = (state) => state.reservation.end_date


export default reservationSlice.reducer;