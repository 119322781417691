export const ContactFormFields = () => {

  const person = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true, prefix: 'person' },
    { type: 'text', name: `Full name`, id: 'full_name', prefix: 'person' },
    { type: 'text', name: `Formal`, id: 'formal', prefix: 'person' },
    { type: 'text', name: `Initials`, id: 'initials', prefix: 'person' },
    { type: 'text', name: `First name`, id: 'first_name', prefix: 'person' },
    { type: 'text', name: `Middle name`, id: 'middle_name', prefix: 'person' },
    { type: 'text', name: `Surname`, id: 'surname', prefix: 'person' },
    { type: 'text', name: `Gender`, id: 'gender', prefix: 'person' },
  ]

  const contactFormFields = [

    { type: 'object', name: `Person`, id: 'person', fields: person },
  ]

  return contactFormFields

}
