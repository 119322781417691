import { confirmAlert } from 'react-confirm-alert';

export default function ConfirmAlert({title, message, onClick, translate}) {

  return confirmAlert({
    title: translate(title),
    message: translate(message),
    buttons: [
      {
        label: translate('Yes'),
        onClick: onClick
      },
      {
        label: translate('No'),
      }
    ]
  });
}

