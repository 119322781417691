import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton'
import SubscriptionButtons from '../portal/subscriptions/subscriptionbuttons'
import SubscriptionModal from './subscriptionModal'
import { useTranslation } from 'react-i18next';

export default function SubscriptionsInclScopesTable(props) {
  const subscriptions = props.subscriptions
  const refresh = props.refresh
  const buttons = SubscriptionButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const copy = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.copy) : false
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const { t: translate } = useTranslation();

  let rows = []

  if (subscriptions) {
    for (const subscription of Object.values(subscriptions)) {
      rows.push(
        <tr className='product-row' key={subscription.id}>
          <td>{subscription.name}</td>
          <td>{subscription.duration}</td>
          <td>{subscription.interval}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(subscription) }}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(subscription) }}>{translate(edit.tag)}</Button>}
            {copy && <Button size="sm" type='button' onClick={() => { copy.onClick(subscription) }}>{translate(copy.tag)}</Button>}
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(subscription, refresh) }}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    };
  }
  
  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Duration (months)')}
            </th>
            <th>
              {translate('Interval (months)')}
            </th>
            {/* <th>Scopes</th> */}
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <SubscriptionModal modal={modalview.modal} />
      </Table>
    </>
  );
}