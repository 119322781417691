import axios from "axios";

const GetUserByIdStagingAndLive = ({token, id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `https://api.staging.octoblue.nl/serials/user/${id}`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json'
      },
    })
    .then(responseStaging => {
      axios({
        method: 'get',
        url: `https://api.hunter-crm.com/serials/user/${id}`,
        headers: {'Authorization': `Bearer ${token['access_token']}`,
                  'Content-Type':'application/json'
        },
      })
      .then(responseLive => {
        resolve([...responseStaging.data, ...responseLive.data])
      })
      .catch(error => {
        console.log("error live:", error)
        resolve(responseStaging.data)
      });
    })
    .catch(error => {
      axios({
        method: 'get',
        url: `https://api.hunter-crm.com/serials/user/${id}`,
        headers: {'Authorization': `Bearer ${token['access_token']}`,
                  'Content-Type':'application/json'
        },
      })
      .then(responseLive => {
        resolve(responseLive.data)
      })
      .catch(error => {
        console.log("error live:", error)
        resolve(error)
      });
      console.log("error staging:", error)
      resolve(error)
    });
  });
};

export default GetUserByIdStagingAndLive