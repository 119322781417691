export const operatorsSalesReportHighlights = (report) => {
  let totalRevenue = 0
  let totalQuantity = 0
  let highlights = []

  // calculate totalRevenue and totalQuantity
  for (const [operatorId, operatorData] of Object.entries(report.operators)) {
    totalRevenue += operatorData.days.totals?.total
    totalQuantity += operatorData.days.totals?.amount
  }

  // add percentage
  for (let [operatorId, operatorData] of Object.entries(report.operators)) {
    operatorData.days.totals.percentage = ((Number(operatorData.days.totals?.total)*100)/Number(totalRevenue)).toFixed(2)
  }

  for (const [operatorId, operatorData] of Object.entries(report.operators).sort(([a, adata], [b, bdata]) => bdata.total - adata.total)) {
    let newLine = {}
    newLine[operatorId] = {amount:Number(operatorData.days.totals.total).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:operatorData.days.totals.percentage?.concat('%')}
    highlights.push(newLine)
  }
  
  return highlights
};