import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { ProgramFormFields } from "./loyaltyFormFields";
import PostPrograms from "../../actablueAPI/loyalty/PostPrograms";
import ProgramForm from "../../components/programForm";
import HandleOnChange from "../../helpers/handleOnChange";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import useHandleError from "../../customhooks/useHandleError";

const ProgramAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [program, setProgram] = useState({ administration_id: admin_id, location_id: location_id })
  const HandleError = useHandleError();

  const onChange = (event) => {
    if (event?.target?.id === 'minimal_points_required') event.target.value = Math.round(event.target.value)
    let newProgram = HandleOnChange({ event: event, object: program })
    setProgram(newProgram)
  }

  const onLocationIdChange = (event) => {
    let newProgram = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: program, selectfield: 'location_id' })
    setProgram(newProgram)
  }

  const onAdminIdChange = (event) => {
    let newProgram = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: program, selectfield: 'administration_id' })
    newProgram.location_id = null
    setProgram(newProgram)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    PostPrograms({ token: token, admin_id: program?.admin_id, program })
    .then(response => {
      navigate("/loyalty");
    })
    .catch(error => {
      HandleError({ error: error })
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: ProgramFormFields(),
    field: {
      onChange: onChange
    },
    data: program,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item?.administration_id === program?.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Program add",
    data: <ProgramForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Program add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default ProgramAdd;

