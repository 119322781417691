
export const UserRoleFormFields = () => {

  const userRoleFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'hidden', name: 'administration_id', id: 'administration_id' },
    { type: 'text', name: `Name`, id: 'name' },
  ]

  return userRoleFormFields
}