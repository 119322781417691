export const usersSalesReportMulitpleDaysHighlights = (report, hash_users_all) => {
  let totalRevenue = 0
  let totalQuantity = 0
  let highlights = []

  // calculate totalRevenue and totalQuantity
  for (const [userId, userData] of Object.entries(report.users)) {
    totalRevenue += userData.days.totals?.total
    totalQuantity += userData.days.totals?.amount
  }

  // add percentage
  for (let [userId, userData] of Object.entries(report.users)) {
    userData.days.totals.percentage = ((Number(userData.days.totals?.total)*100)/Number(totalRevenue)).toFixed(2)
  }
  
  for (const [userId, userData] of Object.entries(report.users).sort(([a, adata], [b, bdata]) => bdata.total - adata.total)) {
    let newLine = {}
    newLine[hash_users_all[userId] ? hash_users_all[userId].name : 'Unknown'] = {amount:Number(userData.days.totals.total).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:userData.days.totals.percentage?.concat('%')}
    highlights.push(newLine)
  }

  return highlights
};