import * as XLSX from 'xlsx/xlsx.mjs';
import { setTaskData } from "../redux/dataSlices/exportSlice";

export const ProductExport = ({data, dispatch }) => {
  const { exportLines, dummy } = _createExportLines(data, dispatch)

  // first object
  let firstObject = exportLines[0]
  let newFirstObject = {}
  for (const key of Object.keys(dummy)) {
    newFirstObject[key] = firstObject[key]
  }

  exportLines[0] = Object.assign({}, newFirstObject)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(exportLines), 'sheet1')
  XLSX.writeFile(workbook, `actablueproducts.xlsx`, { compression: true });

};

function _createExportLines(data, dispatch) {
  const exportLength = data.length
  let current = 1
  let dummy = {}
  let exportLines = []

  for (const product of data) {
    let task_data = {
      current: current,
      total: exportLength,
      progress: current === 0 ? 0 : current / exportLength
    }
    dispatch(setTaskData(task_data))

    let newLine = {}
    newLine.id = product.id
    newLine.name = product.name
    newLine.administration_id = product.administration_id
    newLine.location_id = product.location_id
    newLine.active = product.active
    newLine.is_weighted = product.is_weighted
    newLine.input_required = product.input_required
    newLine.type = product.type
    newLine.sku_code = product.sku_code
    newLine.plu_code = product.plu_code
    newLine.thumbnail = product.thumbnail
    newLine.price_incl_vat = product.price_incl_vat
    newLine.price_excl_vat = product.price_excl_vat
    newLine.emballage = product.emballage
    newLine.discount = product.discount

    if (product.vat) {
      newLine.vat_id = product.vat.id
      newLine.vat_name = product.vat.name
      newLine.vat_percentage = product.vat.value
    }

    if (product.categories) {
      let productpagecounter = 1
      for (const category of product.categories) {
        if (category.type === 'JOURNAL') {
          newLine.journal_id = category.id
          newLine.journal_title = category.title
        }
        if (category.type === 'PRODUCTPAGE') {
          newLine[`productpage_${productpagecounter}_id`] = category.id
          newLine[`productpage_${productpagecounter}_title`] = category.title
          productpagecounter = productpagecounter + 1
        }
      }
    }

    if (product.eans) {
      let eancounter = 1
      for (const ean of product.eans) {
        newLine[`ean_${eancounter}_id`] = ean.id
        newLine[`ean_${eancounter}_ean`] = ean.ean_code
      }
    }


    // create longest dummy
    if (Object.keys(newLine).length > Object.keys(dummy).length) {
      dummy = Object.assign({}, newLine)
    }
    exportLines.push(newLine)

    current = current + 1
  }

  return ({ exportLines: exportLines, dummy: dummy })
}
