import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getCalendarModalShow, getHashPlanningsFromPlanningObjects, getPlanningModalShow, getPlanningObjectModalShow, getPlanningObjectPageSize, getPlanningPageSize, getRefresh, setCalendarModalView, setHashPlanningsFromPlanningObjects, setPlanning, setPlanningAssignDevicesLoading, setPlanningId, setPlanningModalShow, setPlanningObject, setPlanningObjectId, setPlanningObjectModalShow, setPlanningObjectPageSize, setPlanningObjectPagination, setPlanningPageSize, setPlanningPagination, setRefresh } from "../../redux/dataSlices/planningSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { PatchPlanningStatus } from "../../actablueAPI/planning/Planning";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getOrderPlanModalShow, setOrder, setOrderPlanModalShow } from "../../redux/dataSlices/orderSlice";
import GetRelationById from "../../actablueAPI/relations/GetRelationByID";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setRelation } from "../../redux/dataSlices/relationSlice";
import GetDevicesListAll from "../../actablueAPI/devices/GetDevicesListAll";
import { getDevicesAll, setDevicesAll } from "../../redux/dataSlices/deviceSlice";
import { useState } from "react";
import useHandleError from "../../customhooks/useHandleError";
import DeepCopy from "../../helpers/DeepCopy";

export default function PlanningButtons() {
  const modalShow = useSelector(getPlanningModalShow);
  const calendarModalShow = useSelector(getCalendarModalShow);
  const objectModalShow = useSelector(getPlanningObjectModalShow);
  const scopes = useSelector(getScopes)
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const refresh = useSelector(getRefresh);
  const plannings = useSelector(getHashPlanningsFromPlanningObjects);
  const planningPageSize = useSelector(getPlanningPageSize);
  const planningObjectPageSize = useSelector(getPlanningObjectPageSize);
  const orderPlanModalShow = useSelector(getOrderPlanModalShow);
  const admin_id = useSelector(getSelectedAdmin_id);
  const devices = useSelector(getDevicesAll);
  const [modalLoading, setModalLoading] = useState(true);

  function onViewClick(planning) {
    dispatch(setPlanning(planning));
    dispatch(setPlanningModalShow(true));
  };

  function onViewClickObject(planning_object) {
    dispatch(setPlanningObject(planning_object));
    dispatch(setPlanningObjectModalShow(true));
  };

  function onViewClickCalendar(planning) {
    dispatch(setPlanning(planning));
    dispatch(setCalendarModalView(true));
  };

  const onViewHide = () => {
    dispatch(setPlanningModalShow(false));
    dispatch(setPlanningObjectModalShow(false));
    dispatch(setCalendarModalView(false));
  };

  const onclickEdit = (event) => {
    dispatch(setPlanningId(event.target.id));
    navigate("/planning/edit");
  };

  const onclickEditObject = (event) => {
    dispatch(setPlanningObjectId(event.target.id));
    navigate("/planning/object/edit");
  };

  const onPlanningPaginationClick = (event) => {
    dispatch(setPlanningPagination(event.target.value))
  };

  const onPlanningObjectPaginationClick = (event) => {
    dispatch(setPlanningObjectPagination(event.target.value))
  };

  const onPlanningPageSizeChange = (event) => {
    dispatch(setPlanningPageSize(event.value))
  };

  const onPlanningObjectPageSizeChange = (event) => {
    dispatch(setPlanningObjectPageSize(event.value))
  };

  const onclickAdd = () => {
    navigate("/planning/add");
  };

  const onclickAddObject = () => {
    navigate("/planning/object/add");
  };

  const onStatusChange = (event, id, status) => {
    let newPlannings = DeepCopy(plannings);
    PatchPlanningStatus({ token: token, id: id, status: status })
      .then(response => {
        for (const planning_object of response?.data?.planning_objects) {
          if (!newPlannings[planning_object?.planning_object_id]) newPlannings[planning_object?.planning_object_id] = {}
          newPlannings[planning_object?.planning_object_id][id] = response?.data
        }
        dispatch(setHashPlanningsFromPlanningObjects(newPlannings));
        dispatch(setPlanningModalShow(false));
      })
      .catch(error => {
        HandleError({ error: error });
      })
  };

  async function onViewClickPlan(order) {
    dispatch(setOrderPlanModalShow(true));
    dispatch(setPlanningAssignDevicesLoading(true));
    if (!devices) setModalLoading(true);

    if (order?.relation_id) {
      setModalLoading(true);
      await GetRelationById({ token: token, id: order?.relation_id, admin_id: admin_id })
        .then(response => {
          dispatch(setRelation(response.data));
        })
        .catch(error => {
          HandleError({ error: error });
        });
    } else {
      HandleError({ error: "Order does not have a relation, cannot auto-fill info", variant: 'warning', anchorOrigin: { horizontal: 'center', vertical: 'top' } })
    }

    await GetDevicesListAll({ token: token })
      .then(response => {
        dispatch(setDevicesAll(response.data))
      })
      .catch(error => {
        HandleError({ error: error })
      })
      .finally(() => {
        dispatch(setPlanningAssignDevicesLoading(false));
      })

    dispatch(setOrder(order));
    setModalLoading(false);
  };

  async function onRefreshClick() {
    dispatch(setRefresh(!refresh));
  }

  let buttons = {
    calendarmodalview: {
      tag: 'View',
      onClick: onViewClickCalendar,
      modal: {
        show: calendarModalShow,
        onHide: onViewHide
      }
    },
    modalview: {
      tag: 'View',
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide,
        onStatusChange: onStatusChange
      }
    },
    objectmodalview: {
      tag: 'View',
      onClick: onViewClickObject,
      modal: {
        show: objectModalShow,
        onHide: onViewHide
      }
    },
    paginationPlanning: {
      maxbuttons: 5, //uneven number
      onClick: onPlanningPaginationClick
    },
    paginationObjectPlanning: {
      maxbuttons: 5, //uneven number
      onClick: onPlanningObjectPaginationClick
    },
    pagesizePlanning: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pagesize,
      selected: { 'id': planningPageSize, 'name': planningPageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPlanningPageSizeChange
    },
    pagesizePlanningObject: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: planningObjectPageSize,
      selected: { 'id': planningObjectPageSize, 'name': planningObjectPageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPlanningObjectPageSizeChange
    },
    planOrder: {
      tag: 'Plan',
      onClick: onViewClickPlan,
      modal: {
        show: orderPlanModalShow,
        onHide: onViewHide,
        loading: modalLoading
      }
    },
    refresh: {
      tag: 'Refresh',
      value: 'refresh',
      onClick: onRefreshClick,
      style: {
        fontWeight: '600',
        fontSize: '1rem',
        height: '2.438rem',
        float: 'right'
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.planning >= 1) {
    buttons.add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAdd,
      style: {
        fontWeight: '600',
        fontSize: '1rem',
        height: '2.438rem',
        float: 'right'
      }
    }
    buttons.planning_object_add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAddObject,
      style: {
        fontWeight: '600',
        fontSize: '1rem',
        height: '2.438rem',
        float: 'right'
      }
    }
  }
  if (scopes.planning >= 2) {
    buttons.edit = {
      tag: 'Edit',
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.planning_object_edit = {
      tag: 'Edit',
      value: 'edit',
      onClick: onclickEditObject
    }
  }

  return buttons
}

