import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getReservationObject } from '../redux/dataSlices/reservationSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';


export default function ReservationObjectModal(props) {
  const object = useSelector(getReservationObject);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Reservation object: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="options">Options</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>Description: {object.description}</p>
                      <p>Capacity: {object.capacity}</p>
                      <p>Type: {object.type}</p>
                      <p>Autom. max capacity: {JSON.stringify(object.automatically_maximize_capacity)}</p>
                      <p>Counts for capacity: {JSON.stringify(object.counts_for_capacity)}</p>
                    </div>
                    <div className="col">
                      <p>Administration: {hash_admins_all[object.administration_id]?.name}</p>
                      <p>Location: {hash_locations_all[object.location_id]?.name}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="options">
                <br></br>
                <div className="container text-center">
                  {object.hasOwnProperty('reservation_options') ? object.reservation_options.map((reservation_option) => (
                    <>
                      <div className="col">
                        <p>{translate('Name')}: {reservation_option.name}</p>
                        <p>Start: {reservation_option.starting_time}</p>
                        <p>End: {reservation_option.ending_time}</p>
                        <p>Cancel before days: {reservation_option.days_before_final_cancel}</p>
                      </div>
                      <div className="col">
                        {reservation_option.hasOwnProperty('reservation_objects') ? reservation_option.reservation_objects.map((reservation_object) => (
                          <>
                            <p>{translate('Name')}: {reservation_object.name}</p>
                            <p>{translate('Type')}: {reservation_object.type}</p>
                            <p>{translate('Capacity')}: {reservation_object.capacity}</p>
                            <p>Autom. max capacity: {JSON.stringify(reservation_object.automatically_maximize_capacity)}</p>
                            <p>Counts for capacity: {JSON.stringify(reservation_object.counts_for_capacity)}</p>
                          </>
                        )) : <></>}

                      </div>
                    </>
                  )) : <></>}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}