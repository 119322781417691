import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getOnlinePayments, getRefresh, setOnlinePayments } from "../../redux/dataSlices/onlinepaymentSlice";
import OnlinePaymentTable from "../../components/onlinePaymentTable";
import GetOnlinePaymtentsProfilesAll from "../../actablueAPI/onlinepayments/GetOnlinePaymentsProfilesAll";
import useHandleError from "../../customhooks/useHandleError";

const OnlinePayments = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const online_payments = useSelector(getOnlinePayments)
  const refresh = useSelector(getRefresh)
  const dispatch = useDispatch();
  const HandleError = useHandleError();

  const card = {
    size: 12,
    title: "Online payments",
    data: <OnlinePaymentTable {...online_payments} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Online payments"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      GetOnlinePaymtentsProfilesAll({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setOnlinePayments(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, refresh]);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default OnlinePayments;
