export default function CalculatePayments(invoice, payment_type) {
  let payments = []
  let amount_exchange = 0
  let amount_open = 0
  let amount_paid = 0
    
  amount_paid = invoice.payment_amount_inclusive_vat

  switch (payment_type) {
    case 'cash':
      payments.push({
        payment_id: '1',
        administration_id: invoice.administration_id,
        name: 'CASH',
        amount: amount_paid,
        type: 'CASH'
      })
      break;
    case 'eft':
      payments.push({
        payment_id: '2',
        administration_id: invoice.administration_id,
        name: 'EFT',
        amount: amount_paid,
        type: 'EFT'
      })
      break;
    case 'on_account':
      payments.push({
        payment_id: '5',
        administration_id: invoice.administration_id,
        name: 'ON_ACCOUNT',
        amount: amount_paid,
        type: 'ON_ACCOUNT'
      })
      break;
    case 'webshop':
      payments.push({
        payment_id: '6',
        administration_id: invoice.administration_id,
        name: 'WEBSHOP',
        amount: amount_paid,
        type: 'WEBSHOP'
      })
      break;
    default:
        break;
  }
  return ({payments, amount_exchange, amount_open, amount_paid})
  }