export default function HandleOnChangeSingleSelectFieldWithObject(props) {
  const event = props.event
  const object = props.object || {}
  const selectfield = props.selectfield || event.id
  const hash = props.hash

  let newObject = JSON.parse(JSON.stringify(object))
  //   let newObject = Object.assign({}, object)

  // clear newobject selectfield
  delete newObject[selectfield]

  // put new object in 
  if (event) {
    newObject[selectfield] = hash[event.value] 
  }

  return (newObject)
}