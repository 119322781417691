import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import ReservationLocationButtons from '../portal/reservationlocations/reservationlocationbuttons'
import ReservationLocationModal from './reservationlocationModal'
import { useTranslation } from 'react-i18next';


export default function ReservationLocationTable(props) {
  const { t: translate } = useTranslation();
  const hash = props.hash_locations
  const locations = props.locations

  const buttons = ReservationLocationButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false

  let rows = []
  for (let index in locations) {
    const location = locations[index]
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: location.id }) : false
    rows.push(
      <tr className='product-row' key={location.id}>
        <td>
          <img className="product-thumbnail" src={hash[location.id]?.img_urls} alt='' width='128'></img>
        </td>
        <td>{hash[location.id]?.name}</td>
        <td>
          {hash[location.id]?.hasOwnProperty('address') ? `${hash[location.id]?.address.street} ${hash[location.id]?.address.street_number}` : ''} <br></br>
          {hash[location.id]?.hasOwnProperty('address') ? `${hash[location.id]?.address.proverence}, ${hash[location.id]?.address.zip_code}` : ''} <br></br>
          {hash[location.id]?.hasOwnProperty('address') ? `${hash[location.id]?.address.country}` : ''}
        </td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(location) }} id={location.id}>{translate(modalview.tag)}</Button>
          {edit && <TableButton {...edit} />}
        </td>
      </tr>
    )
  }

  if (locations) {
    // const pages = locations.total_pages
    // const page = (locations.number + 1)
    return (
      <>
        {add && <TableButton {...add} />}
        {/* {<PaginationComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />} */}
        <Table hover width='100%'>
          <thead>
            <tr>
              <th></th>
              <th>{translate('Name')}</th>
              <th>
                {translate('Place')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
          <ReservationLocationModal modal={modalview.modal} />
        </Table>
      </>
    );
  } else {
    return (
      <>
        {add && <TableButton {...add} />}
        <Table width='100%'>
          <thead>
            <tr>
              <th></th>
              <th>{translate('Name')}</th>
              <th>
                {translate('Place')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
        </Table>
      </>
    );
  }
}