import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSubscriptionRefresh, setSubscription, setSubscriptionRefresh } from "../../redux/dataSlices/subscriptionSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { copySubscrioptionToFormFields } from "./addSubscriptionFormFields.mjs";
import { getHashLocationsAll, getLocationsAll } from "../../redux/dataSlices/locationSlice";
import { useState } from "react";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import PostRegistration from "../../actablueAPI/registrations/PostRegistration";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getRegistration, setRegistration, getRegistrationModalShow, setRegistrationModalShow, getRegistrationCopyModalShow, setRegistrationCopyModalShow, setRegistrationEditModalShow, getRegistrationEditModalShow } from "../../redux/dataSlices/registrationSlice";
import HandleOnChangeSubscriptionSettings from "../../helpers/handleOnChangeSubscriptionSettings";
import PutRegistration from "../../actablueAPI/registrations/PutRegistration";
import PostRegistrationByIdSubscriptionSettingsById from "../../actablueAPI/registrations/PostRegistrationByIdSubscriptionSettingsById";
import DeleteRegistrationByIdSubscriptionSettingsById from "../../actablueAPI/registrations/DeleteRegistrationByIdSubscriptionSettingsById";
import useHandleError from "../../customhooks/useHandleError";

export default function SubscribeButtons() {
  const token = useSelector(getToken)
  const role = useSelector(getRole)
  const admin_id = useSelector(getSelectedAdmin_id)
  const modalShow = useSelector(getRegistrationModalShow)
  const copyModalShow = useSelector(getRegistrationCopyModalShow)
  const editModalShow = useSelector(getRegistrationEditModalShow)
  const scopes = useSelector(getScopes)
  const locations_all = useSelector(getLocationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const registration = useSelector(getRegistration)
  const refresh = useSelector(getSubscriptionRefresh)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const [newLocationId, setNewLocationId] = useState()

  function onViewClick(registration) {
    dispatch(setRegistration(registration));
    dispatch(setRegistrationModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setRegistrationModalShow(false));
  };

  function onSubscribeClick(subscription) {
    dispatch(setSubscription(subscription));
    navigate("/subscribe/subscribe");
  };

  const onclickAdd = () => {
    dispatch(setSubscription());
    navigate("/subscribe/subscribe");
  };

  function onclickCopy(registration) {
    dispatch(setRegistration(registration));
    dispatch(setRegistrationCopyModalShow(true));
    // navigate("/subscribe/copy");
  };

  function onCopyToLocationChange(event) {
    setNewLocationId(event.value)
  }

  const onclickEdit = (registration) => {
    dispatch(setRegistration(registration));
    dispatch(setRegistrationEditModalShow(true));
  };

  const onEditViewChange = (event) => {
    let newRegistration = HandleOnChangeSubscriptionSettings({ event: event, object: registration })
    dispatch(setRegistration(newRegistration))
  };

  const onEditViewDelete = (props) => {
    DeleteRegistrationByIdSubscriptionSettingsById({token: token, registration_id:props.registration_id, subscription_id:props.subscription_id})
    .then(response => {
      dispatch(setRegistration(response.data))
    })
    .catch(error => {HandleError({ error: error })})
  };

  const onEditViewHide = () => {
    dispatch(setSubscriptionRefresh(!refresh))
    dispatch(setRegistrationEditModalShow(false));
  };

  const onEditViewSave = () => {
    for (let setting of Object.values(registration.settings)) {
      let tempSetting = JSON.parse(JSON.stringify(setting))
      delete tempSetting.subscription
      PostRegistrationByIdSubscriptionSettingsById({token: token, registration_id:registration.id, settings_id:setting.id, data:tempSetting})
      .then(response => {
        dispatch(setSubscriptionRefresh(!refresh))
      })
      .catch(error => {HandleError({ error: error })})
    }
    dispatch(setRegistrationEditModalShow(false));
  };

  const onCopyViewSave = () => {
    if (newLocationId) {
      let newRegistration = JSON.parse(JSON.stringify(registration))
      newRegistration.name = hash_locations_all[newLocationId]?.name
      newRegistration.location_id = newLocationId
      delete newRegistration.subscriptions
      // newRegistration.subscriptions = []
      delete newRegistration.settings
      // newRegistration.subscription_settings = []
      // first create the registration

      // save changes with new location
      PostRegistration({token:token, data:newRegistration})


            // add subscriptions one by one
      // // for (const subscription of registration.subscriptions) {
      //   let subscription = registration.subscriptions[0]
      //   // create start and end
      //   const start = new Date()
      //   let stop = new Date()
      //   stop.setMonth(stop.getMonth() + subscription.duration)
  
      //   // create object
      //   let newSubscription = {}
      //   newSubscription.administration_id = admin_id
      //   newSubscription.location_id = newLocationId
      //   newSubscription.name = subscription.name
      //   newSubscription.start = start.toISOString()
      //   newSubscription.stop = stop.toISOString()
      //   newSubscription.interval = subscription.interval
      //   newSubscription.grace_period = subscription.grace_period
      //   newSubscription.cancel_period = subscription.cancel_period
      //   newSubscription.duration = subscription.duration
      //   newSubscription.prolongation = subscription.prolongation
      //   // newSubscription.id = subscription.id
      //   newSubscription.subscription_id = subscription.id
      //   newRegistration.subscription_settings.push(newSubscription)
      //   newRegistration.subscriptions.push(subscription.id)
      // }
    }

    dispatch(setRegistrationCopyModalShow(false));
  };

  const onCopyViewHide = () => {
    dispatch(setRegistrationCopyModalShow(false));
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    subscribe: {
      tag: 'Subscribe',
      onClick: onSubscribeClick,
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.subscribe >= 1) {
    buttons.add = {
      tag: "Add",
      value:'add',
      onClick:onclickAdd,
      className:'add-button'
    }
    buttons.copy = {
      tag: "Copy",
      value: 'copy',
      onClick: onclickCopy,
      className: 'add-button',
      modal: {
        show: copyModalShow,
        onHide: onCopyViewHide,
        onSave: onCopyViewSave,
        formData: {
          fields: copySubscrioptionToFormFields,
          location_id: {
            label: 'location_id',
            options: locations_all,
            hash: hash_locations_all,
            onChange: onCopyToLocationChange,
            clearable: Boolean(false),
            searchable: Boolean(true)
          },
        }
      }
    }
  }
  if (role === 'super' || role === 'admin') {
    buttons.edit = {
      tag:'Edit',
      value:'edit',
      onClick:onclickEdit,
      modal: {
        show: editModalShow,
        onCancel: onEditViewHide,
        onSave: onEditViewSave,
        onChange: onEditViewChange,
        onDelete: onEditViewDelete,
        formData: {
          fields: copySubscrioptionToFormFields,
          location_id: {
            label: 'location_id',
            options: locations_all,
            hash: hash_locations_all,
            onChange: onEditViewChange,
            clearable: Boolean(false),
            searchable: Boolean(true)
          },
        }
      }
    }
  }

  return buttons
}
