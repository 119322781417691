import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

const locale = navigator.language.split('-')[0];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: locale,
    fallbackLng: 'en',
    debug: process.env.REACT_APP_TRANSLATE_DEBUG === 'true',
    resources: {
      en: {
        translation: require(`./translations/en.json`)
      },
      nl: {
        translation: require(`./translations/nl.json`)
      }
    },
    saveMissing: process.env.REACT_APP_TRANSLATE_SAVE_MISSING === 'true', // send not translated keys to endpoint
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      // Your backend settings
      addPath: `${process.env.REACT_APP_BASE_ENV}/translations/missing`, // path to post missing translations
      crossDomain: true,
      withCredentials: false,
      overrideMimeType: true,
      queryStringParams: { locale: locale },
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default'
      }
    }
  });

export default i18n;