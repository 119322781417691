import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

export default function LoadingSpinner(props) {
    const { t: translate } = useTranslation();
    return (
        <div className='product-spinner'>
            <Spinner animation="border" role="status" className="me-2">
                <span className="visually-hidden">{translate('Loading...')}</span>
            </Spinner>
            <span>{translate('Loading...')} {(props && props?.enabled) && `${props?.current} out of ${props?.total}`}</span>
        </div>
    )
}
