import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

const WarehouseFormFields = () => {
  const role = useSelector(getRole)

  let warehouseFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },

    // single option select fields  
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id' },

    // single option fields
    { type: 'text', name: `Name`, id: 'name' },
  ]

  if (role === 'super' || role === 'admin') {
    warehouseFormFields.splice(1, 1, // overwrite index 1 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id' },
    )
  }

  return warehouseFormFields
}

export default WarehouseFormFields