export default function HandleOnChangeJournalCategory(props) {
  const event = props.event
  const object = props.object || {}
  const hash = props.hash
  const deleteList = props.deleteList

  let newObject = JSON.parse(JSON.stringify(object))
  // let newObject = Object.assign({}, object)
  let newDeleteCategories = Object.assign([], deleteList)
  newObject.categories = []
  if (object.hasOwnProperty('categories')) {
    // eslint-disable-next-line
    object.categories.map((category) => {
      if (category.type !== 'JOURNAL') {
        newObject.categories.push(category)
      }
      if (category.type === 'JOURNAL') {
        newDeleteCategories.push(category)
      }
    })
  }

  if (event) {
    if (event.value !== 'false') {
      // newObject.categories.push({'id':event.value, 'title':event.label , 'type':'JOURNAL'})
      newObject.categories.push(hash[event.value])
    }
    // check vat!! change to same vat as journalcategory
    if (hash[event.value].hasOwnProperty('vat')) {
      newObject.vat = hash[event.value].vat
    }
    if (!hash[event.value].hasOwnProperty('vat')) {
      delete newObject['vat']
    }
  }

  if (!event) {
    newObject.categories = []
  }

  return [newObject, newDeleteCategories]
}