import { useSelector, useDispatch } from "react-redux";
import { getStockModalShow, getStockPagesize, getStockRefresh, setStock, setStockModalShow, setStockPageSize, setStockPagination, setStockRefresh, setWarehouseAdvice } from "../../redux/dataSlices/warehouseSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { StockExport } from "../../helpers/stockExport";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getRole } from "../../redux/dataSlices/tokenSlice";
import GetWarehouseStockReset from "../../actablueAPI/warehouse/GetWarehouseStockReset";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';
import useHandleError from "../../customhooks/useHandleError";
import { useNavigate } from "react-router-dom";

export default function StockButtons() {
  const modalShow = useSelector(getStockModalShow)
  const pageSize = useSelector(getStockPagesize)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const token = useSelector(getToken)
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)
  const refresh = useSelector(getStockRefresh)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();
  const { t: translate } = useTranslation();

  const toggleRefresh = () => {
    dispatch(setStockRefresh(!refresh))
  }

  function onViewClick(stockMutation) {
    dispatch(setStock(stockMutation));
    dispatch(setStockModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setStockModalShow(false));
  };

  const onPaginationClick = (event) => {
    dispatch(setStockPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setStockPageSize(event.value))
  };

  const onExportClick = () => {
    StockExport({ token: token, admin_id: admin_id, location_id: location_id, dispatch: dispatch })
  }

  function onclickEdit(product) {
    dispatch(setWarehouseAdvice(product));
    navigate("/purchaseadvice/edit");
  };

  const onResetToZeroClick = () => {
    confirmAlert({
      title: translate('confirm_alert_title_reset_to_zero'),
      message: translate(`confirm_alert_message_reset_to_zero`),
      buttons: [
        {
          label: translate('Yes'),
          onClick: () => {
            GetWarehouseStockReset({ token: token, admin_id: admin_id, location_id: location_id })
            .then(() => {
              HandleError({ error: translate("reset_to_zero_started"), variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } })
            })
            .catch(error => {HandleError({ error: error })})
          }
        },
        {
          label: translate('No'),
        }
      ]
    })
  }

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.stock >= 0) {
    buttons.refresh = {
      tag: "Refresh",
      value: 'refresh',
      onClick: toggleRefresh,
      className: 'add-button'
    }
  }
  if (scopes.stock >= 2) {
    buttons.resettozero = {
      tag: "reset_to_zero",
      value: 'resettozero',
      onClick: onResetToZeroClick,
      className: 'add-button'
    }
  }
  if (scopes.stock >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }
  if (role === 'super' || role === 'admin' || role === 'reseller') {
    buttons.export = {
      tag: "Export",
      onClick: onExportClick,
      className: 'add-button'
    }
  }

  return buttons
}

