import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
// import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { getInvoices, getInvoicesHighlights, setInvoices, setInvoicesHighLights, getInvoicePagesize, getInvoicePagination } from "../../redux/dataSlices/invoiceSlice";
import { setStartDate, setEndDate, getStartDate, getEndDate } from "../../redux/dataSlices/reportingDateSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import moment from "moment"
import Datepicker from "../../components/datepicker";
import BasicCard from "../../components/card"
import InvoiceTable from "../../components/invoiceTable";
import HighlightsCard from "../../components/HighlightsCard";
import HighlightTable from "../../components/highlightTable";
import WeatherWidget from "../../components/WeatherWidget";
import InvoiceHighLightCalculation from "./highlightcalculation"
import TableButton from "../../components/tableButton";
import PostInvoiceSearch from "../../actablueAPI/invoicepayments/PostInvoiceSearch";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import { filterOriginChoices, filterStatusChoices } from "../../selectfieldchoices/invoiceselect.mjs";
import { getHashEmployeesAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import useHandleError from "../../customhooks/useHandleError";

const Invoices = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const invoices = useSelector(getInvoices);
  const invoiceshighlights = useSelector(getInvoicesHighlights);
  const pagination = useSelector(getInvoicePagination)
  const pageSize = useSelector(getInvoicePagesize)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const hashEmployees = useSelector(getHashEmployeesAll)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false)
  const [filtered, setFiltered] = useState()
  const [search, setSearch] = useState()
  const [filterOrigin, setFilterOrigin] = useState()
  const [filterStatus, setFilterStatus] = useState()
  const [progressEnabled, setProgressEnabled] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(0);
  // const { t: translate } = useTranslation();
  const HandleError = useHandleError();

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    setSearch(value)
    SearchAndFilter(filterOrigin, filterStatus, value, invoices)
  }

  const onFilterOriginChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilterOrigin(value)
    SearchAndFilter(value, filterStatus, search, invoices)
  }

  const onFilterStatusChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilterStatus(value)
    SearchAndFilter(filterOrigin, value, search, invoices)
  }

  const onRefreshClick = () => {
    setRefresh(!refresh)
  }

  function SearchAndFilter(origin, status, search, invoices) {
    if (!origin && !status && !search && !invoices) { return setFiltered() }

    if (invoices) {
      let filtered = JSON.parse(JSON.stringify(invoices))

      if (origin && filtered) { filtered = filtered.filter(invoice => invoice.origin === origin.toUpperCase()) }
      if (status && filtered) { filtered = filtered.filter(invoice => invoice.status === status.toUpperCase()) }
      if (search && filtered) { filtered = filtered.filter(invoice => JSON.stringify(invoice).toLowerCase().includes(search.toLowerCase())) }

      return setFiltered(filtered)
    }
  }

  const refreshButton = {
    tag: "Refresh",
    value: 'refresh',
    onClick: onRefreshClick,
    className: 'add-button',
    disabled: progressEnabled
  }

  const onDatePickerChange = (start, end) => {
    dispatch(setStartDate(start.unix()))
    dispatch(setEndDate(end.unix()))
  }

  const datePicker = {
    startDate: moment.unix(startDate),
    endDate: moment.unix(endDate),
    locale: {
      format: "DD/MM/YYYY",
    },
    alwaysShowCalendars: true,
    showDropdowns: true,
    opens: 'right',
    linkedCalendars: false,
    autoApply: true,
    singleDatePicker: false,
    className: 'reporting-date-picker',
    disabled: progressEnabled,
    onChange: onDatePickerChange
  }


  const highlightCard = {
    size: 6,
    title: "Highlights",
    data: <HighlightTable {...invoiceshighlights} />,
  };

  const invoiceCard = {
    size: 12,
    title: 'Invoices',
    subtitle: <><Datepicker {...datePicker} /><TableButton {...refreshButton} /></>,
    data: <InvoiceTable invoices={filtered || invoices} employees={hashEmployees} />,
    loadingData: {
      total: total,
      current: current,
      enabled: progressEnabled
    }
  };

  const weatherWidgetCard = {
    size: 6
  };

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    },
    filters: [
      {
        label: 'Origin',
        options: filterOriginChoices,
        onChange: onFilterOriginChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Status',
        options: filterStatusChoices,
        onChange: onFilterStatusChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Invoices'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      setProgressEnabled(true);
      getInvoicesFromApi()
    }
    if (!location_id) {
      dispatch(setInvoices())
      setFiltered()
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pageSize, startDate, refresh]);

  async function getInvoicesFromApi() {
    // check how many days and calculate how many hours 
    let hours = (moment.unix(endDate).diff(moment.unix(startDate), 'days') + 1) * 24
    // get transactions by hour
    let tempInvoices = []
    for (var hour = 0; hour < hours; hour++) {
      setTotal(hours);
      setCurrent(hour);

      const searchObject = {
        date_range: {
          from: moment.unix(startDate).add(hour, 'hour').format(`YYYY-MM-DDTHH:mm:ss.SSSZ`),
          to: moment.unix(startDate).add(hour + 1, 'hour').format(`YYYY-MM-DDTHH:mm:ss.SSSZ`),
          field: "creation"
        },
        limit: 999
      }
      await PostInvoiceSearch({ token: token, admin_id: admin_id, location_id: location_id, searchObject: searchObject })
        // eslint-disable-next-line no-loop-func
        .then(response => {
          tempInvoices = [...tempInvoices, ...response.data]
        })
        .catch(error => {
          HandleError({ error: error })
          dispatch(setLoading(false))
          setProgressEnabled(false);
        })
    }
    SearchAndFilter(filterOrigin, filterStatus, search, tempInvoices)
    dispatch(setInvoices(tempInvoices))
    dispatch(setInvoicesHighLights(InvoiceHighLightCalculation(tempInvoices)))
    // dispatch(setLoading(false))
    // dispatch(setProgressModalShow(false))

    axios.all([
      GetEmployees({ token: token, admin_id: admin_id })
    ])
      .then(axios.spread((responseEmployees) => {
        dispatch(setEmployeesAll(responseEmployees.data))
        dispatch(setLoading(false))
        setProgressEnabled(false);
      }))
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
        setProgressEnabled(false);
      })
  }

  return (
    <>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
      <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
      <SearchAndFilter />
      <WeatherWidget {...weatherWidgetCard} />
      <HighlightsCard {...highlightCard} />
      <SearchAndMultiFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...invoiceCard} />
    </>
  )
};
export default Invoices;
