import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function JsonHighlighter(json) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div>
      <CopyToClipboard text={JSON.stringify(json, null, 2)}>
        <button onClick={handleCopy}>{copied ? 'Copied!' : 'Copy JSON'}</button>
      </CopyToClipboard>
      <SyntaxHighlighter language="json">
        {JSON.stringify(json, null, 2)}
      </SyntaxHighlighter>
    </div>
  );
};
