import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: {
  },
  reducers: {
    setLocationLoading: (state, action) => {
      state.location_loading = action.payload
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setReportingLoading: (state, action) => {
      state.reporting_loading = action.payload;
    },

    setLoadingModal: (state, action) => {
      state.loading_modal = action.payload
    }
  }
});

export const {
  setLocationLoading, setLoading, setReportingLoading, setLoadingModal
} = loadingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getLocationLoading = (state) => state.loading.location_loading
export const getReportingLoading = (state) => state.loading.reporting_loading
export const getLoading = (state) => state.loading.loading
export const getLoadingModal = (state) => state.loading.loading_modal

export default loadingSlice.reducer