import axios from "axios";

const GetCategoriesWithType = ({ type, token, admin_id, location_id, page = 0, size = 10}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/category/type/list`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        'type': type,
        'page': page,
        'size': size
      },
    })
      .then(response => {resolve(response)
      })
      .catch(error => {reject(error)});
  });
};

export default GetCategoriesWithType