import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

function HighlightTableReporting(props) {
  const { t: translate } = useTranslation();
  let datalines = []

  if (Object.keys(props).length === 0) {
    return (
      <p>{translate("No data")}</p>
    );
  }

  for (let item in props) {
    for (const [key, value] of Object.entries(props[item])) {
      datalines.push(
        <tr key={key}>
          <td align='left'>{key}</td>
          <td align='right'>{value?.quantity}</td>
          <td align='right'>{value?.percentage}</td>
          <td align='right'>{value?.amount}</td>
        </tr>
      )
    }

  }

  if (props) {
    return (
      <Table responsive='sm' hover width='100%'>
        <tbody>
          {datalines}
        </tbody>
      </Table>
    );
  } else {
    return (
      <p>{translate("No data")}</p>
    );
  }
}

export default HighlightTableReporting;