export const QrOrderingFormFields = () => {

  const qrOrderingFormFields = [
    { type: 'text', name: 'Table number', id: 'table_number', md: 4 }
  ]

  return qrOrderingFormFields
}

export const QrOrderingRangeFormFields = () => {

  const qrOrderingRangeFormFields = [
    { type: 'number', name: 'Table number start', id: 'table_number_range_start', md: 6 },
    { type: 'number', name: 'Table number end', id: 'table_number_range_end', md: 6 }
  ]

  return qrOrderingRangeFormFields
}