export const printerTypeChoices = [
    { 'id': 'Epson_TMT88VII', 'name': 'Epson TM-T88VII' },
    { 'id': 'Sunmi_NT311', 'name': 'Sunmi NT311' },
    { 'id': 'Sunmi_NT311S', 'name': 'Sunmi NT311S' },
]

export const screenTypeChoices = [
    { 'id': 'Chef', 'name': 'Chef' },
    { 'id': 'Division', 'name': 'Division' },
    { 'id': 'Outgoing', 'name': 'Outgoing' },
]

export const orderSequenceChoices = [
    { 'id': 'Insert', 'name': 'Insert' },
    { 'id': 'Append', 'name': 'Append' },
    { 'id': 'After_OnHold', 'name': 'After On Hold' },
    { 'id': 'After_Paused', 'name': 'After Paused' },
    { 'id': 'After_InProgress', 'name': 'After In Progress' },
    { 'id': 'Before_Onhold', 'name': 'Before On Hold' },
    { 'id': 'Before_Paused', 'name': 'Before Paused' },
    { 'id': 'Before_InProgress', 'name': 'Before In Progress' }
]