import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { SearchSelectChoices } from "../../selectfieldchoices/searchselect";
import { SearchFormFields } from "./searchFormFields";
import GetSerialById from "../../actablueAPI/serials/GetSerialByID";
import SearchIdForm from "../../components/searchIdForm";
import SearchIdDataTable from "../../components/searchIdDataTable";
import GetLocationById from "../../actablueAPI/locations/GetLocationByID";
import GetDeviceById from "../../actablueAPI/devices/GetDeviceByID";
import GetDeviceByUId from "../../actablueAPI/devices/GetDeviceByUID";
import GetUserById from "../../actablueAPI/serials/GetUserByID";
import GetRelationById from "../../actablueAPI/relations/GetRelationByID";
import GetProductById from "../../actablueAPI/products/GetProductByID";
import GetCategoryById from "../../actablueAPI/products/GetCategoryByID";
import GetVatById from "../../actablueAPI/products/GetVatByID";
import GetOrderById from "../../actablueAPI/invoicepayments/GetOrderByID";
import GetInvoiceById from "../../actablueAPI/invoicepayments/GetInvoiceByID";
import GetTransactionById from "../../actablueAPI/invoicepayments/GetTransactionByID";
import useHandleError from "../../customhooks/useHandleError";

const SearchId = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const dispatch = useDispatch();
  const [searchForm, setSearchForm] = useState({})
  const [searchResult, setSearchResult] = useState()
  const HandleError = useHandleError();

  const { t: translate } = useTranslation();

  const onChange = (event) => {
    let newSearchForm = HandleOnChange({ event: event, object: searchForm })
    setSearchForm(newSearchForm)
  }

  const onSearchtypeChange = (event) => {
    let newSearchForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: searchForm, selectfield: 'searchtype' })
    setSearchForm(newSearchForm)
  };

  const onSearch = (event) => {
    setSearchResult()
    switch (searchForm.searchtype) {
      case 'administration_id':
        GetSerialById({token:token, id:searchForm.id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'location_id':
        GetLocationById({token:token, id:searchForm.id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'device_id':
        GetDeviceById({token:token, id:searchForm.id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'device_uid':
        GetDeviceByUId({token:token, id:searchForm.id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'user_id':
        GetUserById({token:token, id:searchForm.id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'relation_id':
        GetRelationById({token:token, id:searchForm.id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'product_id':
        GetProductById({token:token, id:searchForm.id, admin_id: admin_id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'category_id':
        GetCategoryById({token:token, id:searchForm.id, admin_id: admin_id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'vat_id':
        GetVatById({token:token, id:searchForm.id, admin_id: admin_id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'order_id':
        GetOrderById({token:token, id:searchForm.id, admin_id: admin_id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'invoice_id':
        GetInvoiceById({token:token, id:searchForm.id, admin_id: admin_id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      case 'transaction_id':
        GetTransactionById({token:token, id:searchForm.id, admin_id: admin_id})
        .then(response => {
          setSearchResult(response.data)
        })
        .catch(error => {
          HandleError({ error: error })
        })
        break;
      default:
        break;
    }
  };

  const searchFormData = {
    submit: {
      title: 'Search',
      type: 'submit',
      onClick: onSearch
    },
    fields: SearchFormFields(),
    field: {
      onChange: onChange
    },
    data: searchForm,
    searchtype: {
      name: 'searchtype',
      options: SearchSelectChoices(),
      // selected: {},
      onChange: onSearchtypeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  }

  const searchSelectcard = {
    size: 12,
    title: 'Search on ID',
    data: <SearchIdForm {...searchFormData} />,
  };

  const dataCard = {
    size: 12,
    title: 'Search results',
    data: <SearchIdDataTable {...searchResult} />,
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Search'))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...searchSelectcard} />
      <BasicCard {...dataCard} />
    </>
  )
};
export default SearchId;