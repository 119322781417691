import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getHashChannelsEmailsAll, getChannelsEmailsAll, getSubscription, setSubscription } from "../../redux/dataSlices/notificationSlice";
import { NotificationSubscriptionFormFields } from "./notificationsFormFields";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import { eventChoices } from "../../selectfieldchoices/notificationselect.mjs";
import NotificationSubscriptionForm from "../../components/notificationSubscriptionForm";
import PostNotificationSubscription from "../../actablueAPI/notifications/PostNotificationSubscription";
import { useTranslation } from "react-i18next";

const NotificationSubscriptionEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const subscription = useSelector(getSubscription)
  const channels_all = useSelector(getChannelsEmailsAll)
  const hash_channels_all = useSelector(getHashChannelsEmailsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const onChange = (event) => {
    let newSubscription = HandleOnChange({ event: event, object: subscription })
    dispatch(setSubscription(newSubscription))
  }

  const onChangeChannel = (event) => {
    let newSubscription = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: subscription, hash: hash_channels_all })
    dispatch(setSubscription(newSubscription))
  }

  const onChangeEvents = (event) => {
    let newSubscription = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: subscription, selectfield: 'events' })
    dispatch(setSubscription(newSubscription))
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostNotificationSubscription({ token: token, admin_id: admin_id, data: subscription })
      .then(() => {
        navigate("/notifications");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: NotificationSubscriptionFormFields(),
    field: {
      onChange: onChange
    },
    data: subscription,
    channel: {
      label: 'channel',
      options: channels_all,
      onChange: onChangeChannel,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    events: {
      label: 'events',
      options: eventChoices,
      onChange: onChangeEvents,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const card = {
    size: 12,
    title: translate('Subscription Edit', {name: subscription?.name || "N/A"}),
    data: <NotificationSubscriptionForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationSubscriptionEdit;

