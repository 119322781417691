import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getGuestCheck, setGuestCheck } from "../../redux/dataSlices/guestcheckSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import GuestCheckForm from "../../components/guestcheckForm";
import GuestCheckFormFields from "./guestcheckFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import { stateChoices } from "../../selectfieldchoices/guestcheckselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostGuestCheck from "../../actablueAPI/guestchecks/PostGuestCheck.jsx";

const GuestCheckEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const guestcheck = useSelector(getGuestCheck)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (event) => {
    let newGuestCheck = HandleOnChange({ event: event, object: guestcheck })
    dispatch(setGuestCheck(newGuestCheck))
  }

  const onTypeChange = (event) => {
    let newGuestCheck = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: guestcheck, selectfield: 'state' })
    dispatch(setGuestCheck(newGuestCheck))
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostGuestCheck({token:token, admin_id:admin_id, data:guestcheck})
      .then(response => {
        navigate("/guestchecks");
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: GuestCheckFormFields(),
    field: {
      onChange: onChange
    },
    data: guestcheck,
    state: {
      label: 'state',
      options: stateChoices,
      selected: { value: 'OPEN', label: 'OPEN' },
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: "GuestCheck edit ".concat(guestcheck.name),
    data: <GuestCheckForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("GuestCheck Edit"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default GuestCheckEdit;

