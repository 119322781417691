import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import ImportProfileForm from "../../components/importProfileForm";
import { importProfileFormFields } from "./ImportProfileFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import { getImportProfile, setImportProfile } from "../../redux/dataSlices/importSlice";
import PutImportProfile from "../../actablueAPI/import/PutImportProfile";
import useHandleError from "../../customhooks/useHandleError";

const ImportProfileEdit = () => {
  const token = useSelector(getToken);
  const import_profile = useSelector(getImportProfile)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newImportProfile = HandleOnChange({ event: event, object: import_profile })
    dispatch(setImportProfile(newImportProfile))
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = (event) => {
    event.preventDefault();
    PutImportProfile({ token: token, data: import_profile })
      .then(response => {
        navigate("/importprofiles");
      })
      .catch(error => {
        HandleError({ error: error })
        navigate("/importprofiles");
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: importProfileFormFields,
    field: {
      onChange: onChange
    },
    data: import_profile,
  };

  const card = {
    size: 12,
    title: "Import profile edit",
    data: <ImportProfileForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Import profile edit"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default ImportProfileEdit;

