import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';
import PaginationAndSizeComponent from './paginationAndSize';
import ProgramButtons from '../portal/loyalty/programbuttons';
import ProgramModal from './programModal';
import { useSelector } from 'react-redux';
import { getSelectedAdmin_id } from '../redux/dataSlices/selectAdminSlice';
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';

export default function ProgramsTable(props) {
  const selectedAdmin = useSelector(getSelectedAdmin_id)
  const selectedLocation = useSelector(getSelectedLocation_id)
  const hash_locations_all = props.hash_locations_all
  const hash_admins_all = props.hash_admins_all
  const programs = props?.programs?.content
  const pages = props?.programs?.total_pages
  const page = (props?.programs?.number + 1)

  const buttons = ProgramButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize
  let rows = []

  if (programs) {
    for (const program of Object.values(programs)) {
      rows.push(
        <tr className='product-row' key={program.id}>
          <td>{hash_admins_all[program?.administration_id]?.name ?? 'Unknown Administration'}</td>
          <td>{hash_locations_all[program?.location_id]?.name ?? 'Unknown Location'}</td>
          <td>{program?.name ?? 'Unknown Program'}</td>
          <td>{program?.description}</td>
          <td>
            {program?.start && new Date(program?.start).toLocaleString()}
          </td>
          <td>
            {program?.stop && new Date(program?.stop).toLocaleString()}
          </td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(program) }} id={program?.id}>{translate(modalview.tag)}</Button>}
            {edit && selectedAdmin && selectedLocation && <Button size="sm" type='button' onClick={() => { edit.onClick(program) }} id={program?.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      <PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />
      {add && selectedAdmin && selectedLocation && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Administration')}</th>
            <th>{translate('Location')}</th>
            <th>{translate('Name')}</th>
            <th>{translate('Description')}</th>
            <th>{translate('Start')}</th>
            <th>{translate('Stop')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <ProgramModal modal={modalview.modal} />
    </>
  );
}