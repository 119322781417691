import axios from "axios";

const PostImageBase64 = ({ token, template_id, code_type, inverted_print, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/schapkaartjes/image/base64"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'template_id': template_id,
        'code_type': code_type,
        'inverted': inverted_print
      },
      data: data
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default PostImageBase64