import { roles } from "../../scopes/roles.mjs"

export const EmployeeRoleFormFields = () => {

  let roleFields = []

  const employeeRoleFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name' },
  ]

  for (const role of Object.values(roles)) {
    roleFields.push({ type: 'checkbox', name: role, id: role, placeholder: role, formdatafield: 'roles', prefix: 'roles' })
  }
  return { employeeRoleFormFields: employeeRoleFormFields, roleFields: roleFields }
}