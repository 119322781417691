import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOpticonEslPagesize, setOpticonEsl, setOpticonEslModalShow, setOpticonEslPagination, getOpticonEslModalShow } from "../../redux/dataSlices/opticonSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setMessage } from "../../redux/dataSlices/messageSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setProductLink } from "../../redux/dataSlices/templateSlice";
import GetProductById from "../../actablueAPI/products/GetProductByID";
import PostShelflabelsProductLinkRefresh from "../../actablueAPI/shelflabels/PostShelflabelsProductLinkRefresh";

export default function OpticonEslButtons() {
  const modalShow = useSelector(getOpticonEslModalShow)
  const pageSize = useSelector(getOpticonEslPagesize);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const scopes = useSelector(getScopes)
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(opticonEsl, shelflabelEsl) {
    dispatch(setOpticonEsl(opticonEsl));
    dispatch(setProductLink(shelflabelEsl));
    dispatch(setOpticonEslModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setOpticonEslModalShow(false));
  };

  const onclickEdit = (opticonEsl) => {
    dispatch(setOpticonEsl(opticonEsl));
    navigate("/opticon/esl/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setOpticonEslPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setOpticonEslPagination(event.value))
  };

  const onclickAdd = () => {
    navigate("/opticon/esl/add");
  };

  const onRefreshClick = async (opticonEsl, shelflabelEsl) => {
    GetProductById({token:token, admin_id:admin_id, id:opticonEsl.product_id})
    .then(response => {
      let product = response.data
      let ean_code = '0'
      if (product.eans) { ean_code = product?.eans[0]?.ean_code }
      let productData = {
        'product_id': product?.id,
        'admin_id': product.administration_id,
        'location_id': product.location_id,
        'name': product?.name ?? "",
        'contents': product?.description ?? "",
        'price': Number(product?.price_incl_vat ?? 0 - product?.discount ?? 0).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        'sku_code': product?.sku_code ?? "",
        'ean_code': ean_code ?? "",
        'url': '',
        'discount': `${Number(product?.discount ?? 0).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
        'emballage': `${Number(product?.emballage ?? 0).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
        'price_incl_vat': `${Number(product?.price_incl_vat ?? 0).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00',
        'price_excl_vat': `${Number(product?.price_excl_vat ?? 0).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? '00.00'
      }
      PostShelflabelsProductLinkRefresh({token:token, admin_id:admin_id, location_id:location_id, product_id:opticonEsl.product_id, data:productData})
      .then(() => {
        dispatch(setMessage(`Refresh event sent.`))
      })
      .catch(() => {
        dispatch(setMessage(`Unable to refresh ESL ${opticonEsl.esl_barcode}`))
      });
    })
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.opticon >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.opticon >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }
  if (scopes.opticon >= 1) {
    buttons.refresh = {
      tag: "Refresh",
      value: 'refresh',
      onClick: onRefreshClick,
    }
  }

  return buttons
}

