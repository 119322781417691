import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLocation, setLocationId, setLocationPagination, getLocationModalShow, setLocationModalShow, setLocationPagesize, getLocationPagesize } from "../../redux/dataSlices/locationSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { setReservationLocation } from "../../redux/dataSlices/reservationSlice";
import { setPlanningLocation } from "../../redux/dataSlices/planningSlice";

export default function LocationButtons() {
  const modalShow = useSelector(getLocationModalShow)
  const pageSize = useSelector(getLocationPagesize)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(location, reservation_location, planning_location) {
    dispatch(setLocation(location));
    dispatch(setReservationLocation(reservation_location));
    dispatch(setPlanningLocation(planning_location))
    dispatch(setLocationModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setLocationModalShow(false));
  };

  const onclickEdit = (event) => {
    dispatch(setLocationId(event.target.id));
    navigate("/location/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setLocationPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setLocationPagesize(event.value))
  };

  const onclickAdd = () => {
    navigate("/location/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.locations >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.locations >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

