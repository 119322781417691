import { Button, Table } from 'react-bootstrap'
import RegistrationsModal from './registrationsModal';
import RegistrationsButtons from '../portal/registrations/registrationsbuttons';
import { useTranslation } from 'react-i18next';

export default function RegistrationsTable(props) {
  const registrations = props.registrations || []
  const hash_users = props.hash_users || []
  const hash_resellers = props.hash_resellers || []
  const refresh = props.refresh
  const buttons = RegistrationsButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const del = buttons.hasOwnProperty('del') ? Object.assign({}, buttons.del) : false
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const { t: translate } = useTranslation();

  let rows = []

  if (registrations) {
    for (const registration of Object.values(registrations)) {
      let registrationRow = []
      for (const setting of Object.values(registration.settings)) {
        registrationRow.push(
          <tr key={setting.id}>
            <td>{setting.subscription.name}</td>
            <td>{setting.start ? new Date(setting.start).toLocaleString() : undefined}</td>
            <td>{setting.stop ? new Date(setting.stop).toLocaleString() : undefined}</td>
            <td>{setting.duration}</td>
          </tr>
        )
      }
      rows.push(
        <tr className='product-row' key={registration.id}>
          <td>{hash_resellers[registration.reseller_id] ? hash_resellers[registration.reseller_id].firstName + ' ' + hash_resellers[registration.reseller_id]?.lastName : registration.reseller_id}</td>
          <td>{registration.name}</td>
          <td>{hash_users[registration.user_id]?.name || registration.user_id}</td>
          <td>
            <Table width='100%'>
              <thead>
                <tr>
                  <th width='20%'>{translate('Registration')}</th>
                  <th width='20%'>{translate('Start')}</th>
                  <th width='20%'>{translate('Stop')}</th>
                  <th width='20%'>{translate('Length')}</th>
                </tr>
              </thead>
              <tbody>
                {registrationRow}
              </tbody>
            </Table>
          </td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(registration) }} id={registration.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={()=>{edit.onClick(registration)}} id={registration.id}>{translate(edit.tag)}</Button>}
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(registration, refresh) }} id={registration.id}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    };
  }

  return (
    <>
      {add && <Button className={add.className}  size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Reseller')}</th>
            <th>{translate('Name')}</th>
            <th>{translate('User')}</th>
            <th>{translate('Registration')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <RegistrationsModal modal={modalview.modal} />
      </Table>
    </>
  );
}