import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import { getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getPrinterPageSize, getPrinterPagination, getPrinters, getScreenPageSize, getScreenPagination, getScreens, setPrinters, setPrintersAll, setResellersAll, setScreens, setScreensAll, setSortCategoriesAll } from "../../redux/dataSlices/kitchenmanagerSlice";
import KmPrinterTable from "../../components/kmPrinterTable";
import GetPrinters from "../../actablueAPI/kitchenmanager/GetPrinters";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import GetScreens from "../../actablueAPI/kitchenmanager/GetScreens";
import KmScreensTable from "../../components/kmScreensTable";
import GetAllCategories from "../../actablueAPI/kitchenmanager/GetAllCategories";
import GetAllScreens from "../../actablueAPI/kitchenmanager/GetAllScreens";
import GetAllPrinters from "../../actablueAPI/kitchenmanager/GetAllPrinters";
import useHandleError from "../../customhooks/useHandleError";
import GetAllResellers from "../../actablueAPI/kitchenmanager/GetAllResellers";

const Devices = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const printers = useSelector(getPrinters);
  const printer_pagination = useSelector(getPrinterPagination);
  const printer_pagesize = useSelector(getPrinterPageSize);
  const screens = useSelector(getScreens);
  const screens_pagination = useSelector(getScreenPagination);
  const screen_pagesize = useSelector(getScreenPageSize);
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const navTitle = "Kitchen Manager"

  const printersCard = {
    size: 12,
    title: "Printers",
    data: location_id ? <KmPrinterTable printers={printers} hash_admins_all={hash_admins_all} hash_locations_all={hash_locations_all} /> : 'Please select a location or administration',
  };

  const screensCard = {
    size: 12,
    title: "Screens",
    data: location_id ? <KmScreensTable screens={screens} hash_admins_all={hash_admins_all} hash_locations_all={hash_locations_all} /> : 'Please select a location or administration',
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))

      if (scopes.printers) {
        GetPrinters({ token: token, admin_id: admin_id, location_id: location_id, page: printer_pagination, size: printer_pagesize })
          .then(response => {
            dispatch(setPrinters(response.data))
          })
          .catch(error => {
            HandleError({ error: error })
          })
          .finally(() => {
            if (!scopes.kmdevices) dispatch(setLoading(false))
          })
      }

      if (scopes.kmdevices) {
        GetScreens({ token: token, admin_id: admin_id, location_id: location_id, page: screens_pagination, size: screen_pagesize })
          .then(response => {
            dispatch(setScreens(response.data))
          })
          .catch(error => {
            HandleError({ error: error })
          })

        GetAllCategories({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setSortCategoriesAll(response))
          })
          .catch(error => {
            HandleError({ error: error })
          })

        GetAllScreens({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setScreensAll(response))
          })
          .catch(error => {
            HandleError({ error: error })
          })

        GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
          .then(response => {
            dispatch(setPrintersAll(response))
          })
          .catch(error => {
            HandleError({ error: error })
          })

        GetAllResellers({ token: token })
          .then(response => {
            dispatch(setResellersAll(response))
          })
          .catch(error => {
            HandleError({ error: error })
          })
          .finally(() => {
            dispatch(setLoading(false))
          })
      }
    }

    // eslint-disable-next-line
  }, [, admin_id, location_id, printer_pagesize, printer_pagination, screen_pagesize, screens_pagination]);

  return (
    <>
      {scopes.printers &&
        <BasicCard {...printersCard} />
      }
      {scopes.screens &&
        <BasicCard {...screensCard} />
      }
    </>
  )
};
export default Devices;

