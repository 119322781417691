import axios from "axios";

const PostOrderSyncUnpaid = ({ token, admin_id, location_id, date }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/invoicepayments/order/sync/unpaid?location=${location_id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      data: {
        "date": date
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default PostOrderSyncUnpaid