import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setRelationsAll, getSupplierRelationsAll } from "../../redux/dataSlices/relationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import ProductSupplierForm from "../../components/productsupplierForm";
import { productSupplierFormFields } from "./productSupplierFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostProductSupplier from "../../actablueAPI/products/PostProductSupplier";
import GetAllRelationsListAll from "../../actablueAPI/relations/GetAllRelationsListAll";

const ProductSupplierAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const suppliers = useSelector(getSupplierRelationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productsupplier, setProductSupplier] = useState({ administration_id: admin_id, location_id: location_id })

  const onRelationIdChange = (event) => {
    let newProductSupplier = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: productsupplier, selectfield: 'relation_id' })
    setProductSupplier(newProductSupplier)
  }

  const onChange = (event) => {
    let newProductSupplier = HandleOnChange({ event: event, object: productsupplier })
    setProductSupplier(newProductSupplier)
  };

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostProductSupplier({token:token, admin_id:admin_id, data:productsupplier})
    .then(response => {
      navigate("/productsuppliers");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: productSupplierFormFields,
    field: {
      onChange: onChange
    },
    data: productsupplier,
    relation_id: {
      name: 'realtion_id',
      options: suppliers,
      selected: [],
      onChange: onRelationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Product supplier add ",
    data: <ProductSupplierForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Product supplier Add"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetAllRelationsListAll({ admin_id: admin_id, token: token })
    .then(data => {
      dispatch(setRelationsAll(data))
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )
};
export default ProductSupplierAdd;
