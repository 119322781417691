import { decodeToken} from "react-jwt"


export default function GetPayloadFromToken(token) {
    if (token) {
        const payload = decodeToken(token.access_token)
        let role = 'user'
        if (payload.realm_access?.roles?.includes('merchant')) {role = 'merchant'}
        if (payload.realm_access?.roles?.includes('reseller')) {role = 'reseller'}
        if (payload.realm_access?.roles?.includes('admin')) {role = 'admin'}
        if (payload.realm_access?.roles?.includes('super')) {role = 'super'}

        return [payload, role]
    }
    
}