import React, { Fragment } from "react";
import { Page, Document, Text, View } from "@react-pdf/renderer";
import { styles } from "../pdfstylesheet/stylesSalesReportPDF"
import moment from "moment";

export function CreateColumns(exportData) {
  const first = exportData[0]
  const lengthPercentage = 10
  const lengthQty = 10
  const lengthAmount = 10
  const lenghtProduct = 30
  const lengthProductSKU = 15
  let journalsAndSubs = 0

  for (const key of Object.keys(first)) {
    if (key.includes('journal') || key.includes('Journal')) {
      journalsAndSubs++
    }
  }
  const restLenght = 100 - lenghtProduct - lengthPercentage - lengthQty - lengthAmount - lengthProductSKU
  const journalLenght = (restLenght / journalsAndSubs).toFixed(0)

  return ({ journal: journalLenght, product: lenghtProduct, productSKU: lengthProductSKU, productPercentage: lengthPercentage, productQuantity: lengthQty, productTotal: lengthAmount })
}
const ItemsTable = ({ exportData }) => {
  const lenghtObject = CreateColumns(exportData)
  return (
    <View style={styles.tableContainer}>
      <TableHeader exportData={exportData} lenghtObject={lenghtObject} />
      <TableRow exportData={exportData} lenghtObject={lenghtObject} />
      {/* <TableBlankSpace rowsCount={ tableRowsCount - exportData.length} /> */}
      <TableFooter items={exportData} lenghtObject={lenghtObject} />
    </View>
  )
};

const TableHeader = ({ exportData, lenghtObject }) => {
  let headers = []
  for (const key of Object.keys(exportData[0])) {
    switch (true) {
      case key.includes('journal'):
      case key.includes('Journal'):
        headers.push(<Text style={Object.assign({ width: lenghtObject.journal.concat('%') }, styles.headerjournal)} >{key}</Text>)
        break;
      case key === 'product':
        headers.push(<Text style={Object.assign({ width: lenghtObject[key].toString().concat('%') }, styles.headerjournal)} >{key}</Text>)
        break;
      case key.includes('product'):
        headers.push(<Text style={Object.assign({ width: lenghtObject[key].toString().concat('%') }, styles.headerjournal)} >{key.replace('product', '')}</Text>)
        break;
      case key === 'packaging':
      case key.includes('marge'):
      case key.includes('ean'):
        break;
      default:
          headers.push(<Text style={Object.assign({ width: lenghtObject[key].toString().concat('%') }, styles['header'.concat(key)])}>{key}</Text>)
        break;
    }
  }
  return (<View style={styles.headerContainer}> {headers} </View>)
};

const TableRow = ({ exportData, lenghtObject }) => {
  const first = exportData[0]
  const rows = []

  for (const item of exportData) {
    const row = []
    for (const key of Object.keys(first)) {
      switch (true) {
        case key.includes('journal'):
        case key.includes('Journal'):
          row.push(<Text style={Object.assign({ width: lenghtObject.journal.concat('%') }, styles.rowjournal)} >{item[key] ? item[key].slice(0, lenghtObject.journal) : ''}</Text>)
          break;
        case key === 'productTotal':
          row.push(<Text style={Object.assign({ width: lenghtObject[key].toString().concat('%') }, styles['row'.concat(key)])}>{item[key] ? item[key].toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>)
          break;
        case key === 'product':
          row.push(<Text style={Object.assign({ width: lenghtObject[key].toString().concat('%') }, styles['row'.concat(key)])}>{item[key] ? item[key].slice(0, lenghtObject[key]) : ''}</Text>)
          break;
        case key === 'packaging':
        case key.includes('marge'):
        case key.includes('ean'):
          break;
        default:
          row.push(<Text style={Object.assign({ width: lenghtObject[key].toString().concat('%') }, styles['row'.concat(key)])}>{item[key] ? item[key] : ''}</Text>)
          break;
      }
    }

    rows.push(
      <View style={styles.row} key={item.journal.concat(item.product)}>
        {row}
      </View>
    )
  }

  return (<Fragment>{rows}</Fragment>)
};


const TableFooter = ({ items }) => {
  const total = items.map(item => item.productTotal)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  return (
    <View style={styles.footerRow}>
      <Text style={styles.footerDescription}>TOTAL</Text>
      <Text style={styles.footerTotal}>{total.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
    </View>
  )
};

const SalesReport = ({ exportData, admin, location }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <Text style={styles.header} fixed>Actablue Report Generator</Text>
      <Text style={styles.title}>Report from administration {admin?.name}, location {location?.name}</Text>
      <Text style={styles.title}>Report printed {moment().format('YYYY-MM-DD HH:mm:ss')}</Text>
      <ItemsTable exportData={exportData} />
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
    </Page>
  </Document>
);

export default SalesReport;