import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

export const ResellerFormFields = () => {
  const role = useSelector(getRole);

  const resellerFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'name', id: 'name', readonly: true },
    { type: 'text', name: 'Sunmi App ID', id: 'sunmi_app_id', required: true },
    { type: 'text', name: 'Sunmi App Key', id: 'sunmi_app_key', required: true },
  ]

  if (role === 'super' || role === 'admin') {
    resellerFormFields.splice(0, 1,
      { type: 'text', name: 'User ID', id: 'id', required: true },
    )
    resellerFormFields.splice(1, 1,
      { type: 'text', name: 'Name', id: 'name', required: true },
    )
  }

  return resellerFormFields
}