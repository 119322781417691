import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getDevice, setDevice } from "../../redux/dataSlices/deviceSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import DeviceForm from "../../components/deviceForm";
import SmartPOSFormFields from "./smartposDeviceFormFields";
import VectronFormFields from "./vectronDeviceFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { deviceTypeChoices, eft_payment_provider, selected_printer, selected_scanner } from "../../selectfieldchoices/deviceselect.mjs";
import PostImage from "../../actablueAPI/cdn/PostImage";
import PutDevicesDevice from "../../actablueAPI/devices/PutDevicesDevice";
import useHandleError from "../../customhooks/useHandleError";

const DeviceEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const device = useSelector(getDevice)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();

  const onChange = (event, id) => {
    let newDevice = HandleOnChange({ event: event, object: device })
    dispatch(setDevice(newDevice))
  }

  const onTypeChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'type' })
    dispatch(setDevice(newDevice))
  }

  const onLocationIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'location_id' })
    dispatch(setDevice(newDevice))
  }

  const onAdminIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device, selectfield: 'administration_id' })
    delete newDevice.location_id
    dispatch(setDevice(newDevice))
  }

  const onHandleOnChangeSingleSelectFieldWithValue = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: device })
    dispatch(setDevice(newDevice))
  }

  const onReceiptLogoChange = (event) => {

    // check extension of file
    let extension = event.target?.files[0]?.name?.split('.')[1]
    if (extension.toLowerCase() === 'png') {
      PostImage({ token: token, admin_id: device?.administration_id, location_id: device?.location_id, file: event.target?.files[0] })
        .then(response => {
          let newDevice = JSON.parse(JSON.stringify(device))
          if (!newDevice.settings) { newDevice.settings = {} }
          newDevice.settings.receipt_logo_url = response.data.image_url
          dispatch(setDevice(newDevice))
        })
    }

    if (extension.toLowerCase() !== 'png') {
      HandleError({ error: "Uploaded file must be .PNG", variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } })
    }

  }

  const onDisplayLogoChange = (event) => {
    // check extension of file
    let extension = event.target?.files[0]?.name?.split('.')[1]
    if (extension.toLowerCase() === 'png') {
      PostImage({ token: token, admin_id: device?.administration_id, location_id: device?.location_id, file: event.target?.files[0] })
        .then(response => {
          let newDevice = JSON.parse(JSON.stringify(device))
          if (!newDevice.settings) { newDevice.settings = {} }
          newDevice.settings.display_logo_url = response.data.image_url
          dispatch(setDevice(newDevice))
        })
    }

    if (extension.toLowerCase() !== 'png') {
      HandleError({ error: "Uploaded file must be .PNG", variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } })
    }
  }

  const onCancel = () => { navigate(-1) }

  const onSubmit = () => {
    let newDevice = JSON.parse(JSON.stringify(device))
    if (newDevice.settings && !newDevice.settings?.device_low_memory_threshold) {
      newDevice.settings.device_low_memory_threshold = ''
    }
    PutDevicesDevice({ token: token, admin_id: device?.administration_id, type: device?.type.toLowerCase(), data: newDevice })
      .then(() => {
        navigate("/devices");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: device?.type === 'VECTRON' ? VectronFormFields() : SmartPOSFormFields(),
    field: {
      onChange: onChange
    },
    data: device,
    type: {
      label: 'type',
      options: deviceTypeChoices,
      selected: { value: 'SMARTPOS', label: 'SMARTPOS' },
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item.administration_id === device?.administration_id) { return true } }),
      hash: hash_locations_all,
      // selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      // selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    selected_scanner: {
      label: 'selected_scanner',
      options: selected_scanner,
      // selected: {},
      onChange: onHandleOnChangeSingleSelectFieldWithValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    selected_printer: {
      label: 'selected_printer',
      options: selected_printer,
      // selected: {},
      onChange: onHandleOnChangeSingleSelectFieldWithValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    eft_payment_provider: {
      label: 'eft_payment_provider',
      options: eft_payment_provider,
      // selected: {},
      onChange: onHandleOnChangeSingleSelectFieldWithValue,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    new_receipt_logo: {
      label: 'new_receipt_logo',
      onChange: onReceiptLogoChange,
    },
    new_display_logo: {
      label: 'new_display_logo',
      onChange: onDisplayLogoChange,
    }
  };

  const editCard = {
    size: 12,
    title: "Device edit ".concat(device?.name),
    data: <DeviceForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Device Edit"))

    if (!formData?.data) navigate(-1)

    // eslint-disable-next-line
  }, []);

  if (formData?.data) {
    return (
      <>
        <BasicCard {...editCard} />
      </>
    )
  }
};
export default DeviceEdit;

