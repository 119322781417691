import Form from 'react-bootstrap/Form';
import { Tab, Nav, Col, Row, Button, Spinner } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import TemplateEditCanvas from './templateEditCanvas';
import { useSelector } from 'react-redux';
import { getProduct } from '../redux/dataSlices/productSlice';
import { useTranslation } from 'react-i18next';


export default function ProductPrintForm(props) {
  const product = useSelector(getProduct);
  const { t: translate } = useTranslation();

  if (product?.hasOwnProperty('eans')) {
    props.eans.options = []
    product.eans.forEach(ean => {
      props.eans.options.push({ 'id': ean.ean_code, 'name': ean.ean_code })
    });
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  if (props.data) {
    return (
      <>
        <Row>
          <Col sm='6'>
            <Form>
              <Tab.Container defaultActiveKey="main">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="main">
                      {translate('Main')}
                    </Nav.Link>
                  </Nav.Item>
                  {formTabs}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="main">
                    <div className='tab-container'>
                      {basicFields}
                    </div>
                  </Tab.Pane>
                  {formTabContent}
                </Tab.Content>
              </Tab.Container>
            </Form>
            {props?.printer?.type === 'Bixolon' &&
              <Button variant="success" type={props.print.type} onClick={props.print.onClick}>
                {props.print.title}
              </Button>
            }
            <Button variant="primary" type={props.download.type} onClick={props.download.onClick} disabled={props.download.isDownloading}>
              {!props.download.isDownloading ?
                props.download.title
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Spinner animation="border" role="status" className="me-2">
                    <span className="visually-hidden">{translate('Loading...')}</span>
                  </Spinner>
                  <span>{translate('Downloading...')}</span>
                </div>
              }
            </Button>
          </Col>
          <Col sm='1'>
            <div className="vl"></div>
          </Col>
          <Col sm='4' className='konvajs-canvas-parent'>
            <TemplateEditCanvas {...props} />
          </Col>
          <p className='form-required-text-hint'>{translate("* Downloaded or printed result may vary from preview")}</p>
        </Row>
      </>
    );
  }

}