import { createSlice } from "@reduxjs/toolkit";

export const ccvshopSlice = createSlice({
  name: "ccvshop",
  initialState: {
    hash_ccvshops_all: {},
    hash_ccvshops:{},
  },
  reducers: {
    setCcvshopId: (state, action) => {
      state.ccvshop_id = action.payload;
    },
    setCcvshop: (state, action) => {
      state.ccvshop = action.payload;
    },
    setCcvshops: (state, action) => {
      state.ccvshops = action.payload
      action?.payload?.forEach(ccvshop => {
        state.hash_ccvshops[ccvshop.id] = ccvshop
      });
    },
    setCcvshopsAll: (state, action) => {
      state.ccvshops_all = action.payload
      action?.payload?.forEach(ccvshop => {
        state.hash_ccvshops_all[ccvshop.id] = ccvshop
      });
    },
    setCcvshopModalShow: (state, action) => {
      state.ccvshop_modalshow = action.payload
    },
    setCcvshopPagination: (state, action) => {
      state.ccvshop_pagination = (action.payload - 1)
    },
    setCcvshopPagesize: (state, action) => {
      state.ccvshop_pagesize = action.payload
    },

    setCcvshopWebhookId: (state, action) => {
      state.ccvshop_webhook_id = action.payload;
    },
    setCcvshopWebhook: (state, action) => {
      state.ccvshop_webhook = action.payload;
    },
    setCcvshopWebhooks: (state, action) => {
      state.ccvshop_webhooks = action.payload
    },
    setCcvshopWebhooksAll: (state, action) => {
      state.ccvshop_webhooks_all = action.payload
      action?.payload?.forEach(ccvshop_webhook => {
        state.hash_ccvshop_webhooks_all[ccvshop_webhook.id] = ccvshop_webhook
      });
    },
    setCcvshopWebhookModalShow: (state, action) => {
      state.ccvshop_webhook_modalshow = action.payload
    },
    setCcvshopWebhookPagination: (state, action) => {
      state.ccvshop_webhook_pagination = (action.payload - 1)
    },
    setCcvshopWebhookPagesize: (state, action) => {
      state.ccvshop_webhook_pagesize = action.payload
    },
  }
});

export const {
  setCcvshopId, setCcvshop, setCcvshops, setCcvshopModalShow, setCcvshopPagination, setCcvshopPagesize, setCcvshopsAll,
  setCcvshopWebhookId, setCcvshopWebhook, setCcvshopWebhooks, setCcvshopWebhookModalShow, setCcvshopWebhookPagination, setCcvshopWebhookPagesize, setCcvshopWebhooksAll,
} = ccvshopSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getCcvshopId = (state) => state.ccvshop.ccvshop_id
export const getCcvshop = (state) => state.ccvshop.ccvshop
export const getCcvshops = (state) => state.ccvshop.ccvshops
export const getHashCcvshops = (state) => state.ccvshop.hash_ccvshops
export const getCcvshopsAll = (state) => state.ccvshop.ccvshops_all
export const getHashCcvshopsAll = (state) => state.ccvshop.hash_ccvshops_all
export const getCcvshopsHighlights = (state) => state.ccvshop.ccvshopshighlights
export const getCcvshopModalShow = (state) => state.ccvshop.ccvshop_modalshow
export const getCcvshopPagination = (state) => state.ccvshop.ccvshop_pagination
export const getCcvshopPagesize = (state) => state.ccvshop.ccvshop_pagesize

export const getCcvshopWebhookId = (state) => state.ccvshop.ccvshop_webhook_id
export const getCcvshopWebhook = (state) => state.ccvshop.ccvshop_webhook
export const getCcvshopWebhooks = (state) => state.ccvshop.ccvshop_webhooks
export const getCcvshopWebhooksAll = (state) => state.ccvshop.ccvshop_webhooks_all
export const getHashCcvshopWebhooksAll = (state) => state.ccvshop.hash_ccvshop_webhooks_all
export const getCcvshopWebhooksHighlights = (state) => state.ccvshop.ccvshop_webhookshighlights
export const getCcvshopWebhookModalShow = (state) => state.ccvshop.ccvshop_webhook_modalshow
export const getCcvshopWebhookPagination = (state) => state.ccvshop.ccvshop_webhook_pagination
export const getCcvshopWebhookPagesize = (state) => state.ccvshop.ccvshop_webhook_pagesize

export default ccvshopSlice.reducer;