import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getRegistration } from '../redux/dataSlices/registrationSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { useTranslation } from 'react-i18next';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';

export default function RegistrationsModal(props) {
  const object = useSelector(getRegistration);
  const role = useSelector(getRole)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            {translate('Registration')}: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="settings">{translate('Subscriptions')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>{translate('Administration')}: {hash_admins_all[object.administration_id]?.name || object.administration_id}</p>
                      <p>{translate('Location')}: {hash_locations_all[object.location_id]?.name || object.location_id}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="settings">
                <div className="container text-center">
                  {object.hasOwnProperty('settings') &&
                    <>
                      {object.settings.map((setting) => {
                        return (
                          <div className="row">
                            <div className="col">
                              <p>{translate('Registration')}: {setting?.subscription?.name}</p>
                              <p>{translate('Start')}: {new Date(setting?.start).toLocaleString() ?? undefined}</p>
                              <p>{translate('Stop')}: {new Date(setting?.stop).toLocaleString() ?? undefined}</p>
                              <p>{translate('Duration (Months)')}: {setting?.duration}</p>
                              <p>{translate('Interval (Months)')}: {setting?.interval}</p>
                              <p>{translate('Grace period (Months)')}: {setting?.grace_period}</p>
                              <p>{translate('Cancel period (Months)')}: {setting?.cancel_period}</p>
                              <p>{translate('Prolongation (Months)')}: {setting?.prolongation}</p>
                            </div>
                            <div className="col">
                              <p>{translate('Administration')}: {hash_admins_all[object.administration_id]?.name || object.administration_id}</p>
                              <p>{translate('Location')}: {hash_locations_all[object.location_id]?.name || object.location_id}</p>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  }
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}