import { Stage, Layer, Text, Image, Transformer, Rect, Circle, Shape } from 'react-konva';
import './css/templateEditCanvas.css';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedEan } from '../redux/dataSlices/templateSlice';
import { getProduct } from '../redux/dataSlices/productSlice';
import { getSettingsSchapkaartjes } from '../redux/dataSlices/settingsSlice';

import JsBarcode from 'jsbarcode';
import { QRCodeCanvas } from 'qrcode.react';

import { supportFonts } from '../selectfieldchoices/shelflabelselect.mjs';
import { GetInvertedPrintType } from '../helpers/getInvertedPrintType';
import { getHashPrintersAll } from '../redux/dataSlices/printerSlice';

export default function TemplateEditCanvas(props) {
  const codeRefs = useRef({});
  const stageRef = useRef();
  const transformerRef = useRef();
  const mainLayerRef = useRef();
  const template = props?.data;
  const setTemplate = props?.template_data?.setTemplate;
  const product = useSelector(getProduct);
  const schapkaartjesSettings = useSelector(getSettingsSchapkaartjes);
  const selectedEan = useSelector(getSelectedEan);
  const templateImages = props?.images?.templateImages ?? template.images
  const setTemplateImages = props?.images?.setTemplateImages
  const settings = useSelector(getSettingsSchapkaartjes);
  const currencySymbol = settings?.accepted_currency ?? '\u20AC';
  const hash_printers = useSelector(getHashPrintersAll);
  const invertedPrintType = GetInvertedPrintType(settings, hash_printers);
  const [textAlign, setTextAlign] = useState('left')
  const [productInfo, setProductInfo] = useState({
    name: 'Product Naam',
    description: 'Description',
    price: `${currencySymbol} 00.00`,
    code: '1234567890',
    discount: `${currencySymbol} 00.00`,
    emballage: `${currencySymbol} 00.00`,
    price_excl_vat: `${currencySymbol} 00.00`,
    price_incl_vat: `${currencySymbol} 00.00`,
    price_incl_discount: `${currencySymbol} 00.00`,
    brand: `Brand`,
    weight: 'Weight',
    height: `Height`,
    length: `Length`,
    width: `Width`
  })
  const MIN_WIDTH = 20;

  function setupProductInfo(product) {
    if (!props.isPrint) return
    const formattedPriceIncl = Number(product?.price_incl_vat).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const formattedPriceExcl = Number(product?.price_excl_vat).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const formattedPriceInclDiscount = Number((product?.price_incl_vat - product?.discount)).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const formattedEmballage = Number(product?.emballage).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const formattedDiscount = Number(product?.discount).toLocaleString('en-EN', { currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 });

    let newProductInfo = Object.assign({}, productInfo)
    newProductInfo.name = product.name ?? '';
    newProductInfo.description = product.description ?? '';
    newProductInfo.price = `${currencySymbol} ${formattedPriceIncl}` ?? `${currencySymbol} 00.00`;
    newProductInfo.price_incl_vat = `${currencySymbol} ${formattedPriceIncl}` ?? `${currencySymbol} 00.00`;
    newProductInfo.price_excl_vat = `${currencySymbol} ${formattedPriceExcl}` ?? `${currencySymbol} 00.00`;
    newProductInfo.price_incl_discount = `${currencySymbol} ${formattedPriceInclDiscount}` ?? `${currencySymbol} 00.00`;
    newProductInfo.emballage = `${currencySymbol} ${formattedEmballage}` ?? `${currencySymbol} 00.00`;
    newProductInfo.discount = `${currencySymbol} ${formattedDiscount}` ?? `${currencySymbol} 00.00`;
    newProductInfo.brand = product?.product_specifications?.brand ?? '';
    newProductInfo.weight = product?.product_specifications?.weight ?? '';
    newProductInfo.height = product?.product_specifications?.height ?? '';
    newProductInfo.length = product?.product_specifications?.length ?? '';
    newProductInfo.width = product?.product_specifications?.width ?? '';
    switch (schapkaartjesSettings?.code_to_print) {
      case 'sku':
        newProductInfo.code = product?.sku_code ?? '0';
        break;
      case 'ean':
        newProductInfo.code = selectedEan?.value ?? '0';
        break;
      case 'url':
        newProductInfo.code = product?.thumbnail ?? '0';
        break;
      default:
        newProductInfo.code = '1234567890';
    }
    setProductInfo(newProductInfo)
  }

  async function generateCode(codeRefs, stageRef, id, type, element) {
    const codeRef = codeRefs.current[id];
    let imageData = '';
    if (codeRef && stageRef.current) {
      switch (type) {
        case 'qr':
          imageData = codeRef.firstChild.toDataURL();
          break;
        case 'bar':
          // In some cases the barcode generates too slowly, that's why we do an await
          if (element) imageData = await loadBarcode(codeRef, template?.elements[id.split("_")[1]]?.width, template?.elements[id.split("_")[1]]?.height, template?.elements[id.split("_")[1]]?.text);
          if (!element) imageData = await loadBarcode(codeRef, template.code_width_px, template.code_height_px, productInfo.code);
          break;
        default:
          break;
      }
      const imageElement = new window.Image();
      imageElement.src = imageData;
      imageElement.onload = () => {
        if (stageRef.current) {
          const elementId = id.split("_")[1] ? `#element_${id.split("_")[1]}` : '#code'
          const codeImage = stageRef.current.findOne(elementId);
          codeImage.image(imageElement);
          stageRef.current.batchDraw();
        }
      };
    }
  }

  async function loadBarcode(codeRef, width, height, code) {
    return new Promise((resolve) => {
      try {
        JsBarcode(codeRef, code || "0", {
          format: 'CODE128',
          displayValue: true,
          width: width / 100,
          height: height,
          background: invertedPrintType === 'brother' ? '#000000' : '#FFFFFF',
          lineColor: invertedPrintType === 'brother' ? '#FFFFFF' : '#000000'
        });
        const imageData = codeRef.src;
        resolve(imageData);
      } catch (error) {
        const imageData = codeRef.src;
        resolve(imageData);
      }
    });
  }

  function alignText(stageRef, align) {
    if (!stageRef.current) return;
    setTextAlign(align)
  }

  function centerText(stageRef, text_centered, template) {
    if (text_centered) {
      const titleText = stageRef.current.findOne('#title');
      const contentsText = stageRef.current.findOne('#contents');
      const priceText = stageRef.current.findOne('#price');
      titleText.width(template?.width_px)
      titleText.x(0)
      contentsText.width(template?.width_px)
      contentsText.x(0)
      priceText.width(template?.width_px)
      priceText.x(0)

      setTextAlign('center')

      if (setTemplate) setTemplate({
        ...template,
        text_centered: true,
        text_align: 'center',
        title_x: 0,
        contents_x: 0,
        price_x: 0
      });
    }
  }

  function handleImageLoad(stageRef) {
    if (stageRef.current && template?.include_images && templateImages) {
      for (const [index, image] of Object.entries(templateImages)) {
        const imageComponent = stageRef.current.findOne(`#image-${index}`);
        const imageElement = new window.Image();
        imageElement.onload = () => {
          imageComponent.image(imageElement);
          stageRef.current.batchDraw();
        };
        imageElement.src = image?.data;
      }
    }
  }

  function generateElements(element, index) {
    switch (element?.type) {
      case 'rectangle':
        return (
          <Rect
            id={`element_${index}`}
            x={parseInt(element?.x)}
            y={parseInt(element?.y)}
            width={parseInt(element?.width)}
            height={parseInt(element?.height)}
            opacity={0 + (1 - 0) * ((element?.opacity - 0) / (255 - 0))}
            fill={element?.fill}
            stroke={element?.outline_color}
            strokeWidth={element?.outline_size}
            draggable={props.draggable}
            onDragEnd={(event) => handleDrag(event)}
            onClick={(event) => handleClick(event)}
          />
        )
      case 'text':
        return (
          <Text
            id={`element_${index}`}
            x={parseInt(element?.x)}
            y={parseInt(element?.y)}
            fontSize={parseInt(element?.text_fontsize)}
            width={parseInt(element?.width)}
            fontFamily={supportFonts.find(fontElement => fontElement.id === element?.text_font)?.name}
            fontStyle={
              (element?.text_bold ? 'bold ' : '') +
              (element?.text_italic ? 'italic ' : '')
            }
            textDecoration={
              (element?.text_underlined ? 'underline ' : '') +
              (element?.text_strikethrough ? 'line-through ' : '')
            }
            text={
              (element?.text_type === 'custom' ? element.text : '') +
              (element?.text_type === 'discount' ? productInfo.discount : '') +
              (element?.text_type === 'emballage' ? productInfo.emballage : '') +
              (element?.text_type === 'price_excl_vat' ? productInfo.price_excl_vat : '') +
              (element?.text_type === 'price_incl_vat' ? productInfo.price_incl_vat : '') +
              (element?.text_type === 'price_incl_discount' ? productInfo.price_incl_discount : '') +
              (element?.text_type === 'brand' ? productInfo.brand : '') +
              (element?.text_type === 'weight' ? productInfo.weight : '') +
              (element?.text_type === 'height' ? productInfo.height : '') +
              (element?.text_type === 'length' ? productInfo.length : '') +
              (element?.text_type === 'width' ? productInfo.width : '')
            }
            fill={invertedPrintType === 'normal' ? 'white' : element.fill ?? 'black'}
            opacity={0 + (1 - 0) * ((element?.opacity - 0) / (255 - 0))}
            stroke={element?.outline_color}
            strokeWidth={element?.outline_size}
            align={textAlign}
            draggable={props.draggable}
            onDragEnd={(event) => handleDrag(event)}
            onClick={(event) => handleClick(event)}
          />
        )
      case 'circle':
        return (
          <Circle
            id={`element_${index}`}
            x={parseInt(element?.x)}
            y={parseInt(element?.y)}
            width={parseInt(element?.width)}
            height={parseInt(element?.height)}
            opacity={0 + (1 - 0) * ((element?.opacity - 0) / (255 - 0))}
            fill={element?.fill}
            stroke={element?.outline_color}
            strokeWidth={element?.outline_size}
            draggable={props.draggable}
            onDragEnd={(event) => handleDrag(event)}
            onClick={(event) => handleClick(event)}
          />
        )
      case 'triangle':
        return (
          <Shape
            className="Triangle"
            id={`element_${index}`}
            x={parseInt(element?.x)}
            y={parseInt(element?.y)}
            width={parseInt(element?.width)}
            height={parseInt(element?.height)}
            opacity={0 + (1 - 0) * ((element?.opacity - 0) / (255 - 0))}
            fill={element?.fill}
            stroke={element?.outline_color}
            strokeWidth={element?.outline_size}
            draggable={props.draggable}
            onDragEnd={(event) => handleDrag(event)}
            onClick={(event) => handleClick(event)}
            sceneFunc={(ctx, shape) => {
              // Define triangle points relative to the element's width and height
              const halfWidth = element.width / 2;
              const height = element.height;

              // Calculate the scaling factor based on the element's width
              const scale = element.width / 100;

              ctx.beginPath();
              ctx.moveTo(halfWidth, 0); // Top center
              ctx.lineTo(halfWidth + (scale * 50), height); // Bottom right
              ctx.lineTo(halfWidth - (scale * 50), height); // Bottom left
              ctx.closePath();

              ctx.strokeShape(shape);
              ctx.fillShape(shape);
            }}
            hitFunc={(context, shape) => {
              // Define hit area as a rectangle (for simplicity)
              context.beginPath();
              context.rect(0, 0, shape.width(), shape.height());
              context.closePath();
              context.fillStrokeShape(shape);
            }}
          />
        )
      case 'cross':
        return (
          <Shape
            className="Cross"
            id={`element_${index}`}
            x={parseInt(element?.x)}
            y={parseInt(element?.y)}
            width={parseInt(element?.width)}
            height={parseInt(element?.height)}
            opacity={0 + (1 - 0) * ((element?.opacity - 0) / (255 - 0))}
            fill={element?.fill}
            stroke={element?.outline_color}
            strokeWidth={element?.outline_size}
            draggable={props.draggable}
            onDragEnd={(event) => handleDrag(event)}
            onClick={(event) => handleClick(event)}
            sceneFunc={(ctx, shape) => {
              ctx.save();
              ctx.translate(shape.width() / 2, shape.height() / 2);
              ctx.rotate((45 * Math.PI) / 180);
              ctx.beginPath();
              ctx.moveTo(-shape.width() / 2, 0);
              ctx.lineTo(shape.width() / 2, 0);
              ctx.moveTo(0, -shape.height() / 2);
              ctx.lineTo(0, shape.height() / 2);
              ctx.closePath();
              ctx.strokeShape(shape);
              ctx.fillShape(shape);
              ctx.restore();
            }}
            hitFunc={(ctx, shape) => {
              ctx.beginPath();
              ctx.rect(0, 0, shape.width(), shape.height());
              ctx.closePath();
              ctx.fillStrokeShape(shape);
            }}
          />
        )
      case 'qrcode':
        let qrElements = [];
        // qrElements.push(
        //   <Rect
        //     id={`element_${index}_bg`}
        //     x={parseInt(element?.x)}
        //     y={parseInt(element?.y)}
        //     width={parseInt(element?.width)}
        //     height={parseInt(element?.height)}
        //     fill={invertedPrintType === 'brother' ? 'black' : 'white'}
        //     stroke={invertedPrintType === 'brother' ? 'black' : 'white'}
        //     strokeWidth={invertedPrintType === 'brother' ? 5 : 15}
        //   />
        // );
        qrElements.push(
          <Image
            id={`element_${index}`}
            x={parseInt(element?.x)}
            y={parseInt(element?.y)}
            width={parseInt(element?.width)}
            height={parseInt(element?.height)}
            fill='white'
            image={null}
            draggable={props.draggable}
            onDragEnd={(event) => handleDrag(event)}
            onClick={(event) => handleClick(event)}
          />
        )
        return qrElements
      case 'barcode':
        let barElements = [];
        // barElements.push(
        //   <Rect
        //     id={`element_${index}_bg`}
        //     x={parseInt(element?.x)}
        //     y={parseInt(element?.y)}
        //     width={parseInt(element?.width)}
        //     height={parseInt(element?.height)}
        //     fill={invertedPrintType === 'brother' ? 'black' : 'white'}
        //     stroke={invertedPrintType === 'brother' ? 'black' : 'white'}
        //     strokeWidth={invertedPrintType === 'brother' ? 5 : 15}
        //   />
        // );
        barElements.push(
          <Image
            id={`element_${index}`}
            x={parseInt(element?.x)}
            y={parseInt(element?.y)}
            width={parseInt(element?.width)}
            height={parseInt(element?.height)}
            fill='white'
            image={null}
            draggable={props.draggable}
            onDragEnd={(event) => handleDrag(event)}
            onClick={(event) => handleClick(event)}
          />
        )
        return barElements
      default:
        break;
    }
  }

  function generateElementRefs(element, index) {
    switch (element.type) {
      case 'qrcode':
        return (
          <div className='code' ref={ref => codeRefs.current[`qrCode_${index}`] = ref}>
            <QRCodeCanvas
              value={element.text}
              size={512}
              bgColor={invertedPrintType === 'brother' ? "#000000" : '#FFFFFF'}
              fgColor={invertedPrintType === 'brother' ? "#FFFFFF" : '#000000'}
              level={"L"}
              includeMargin={false}
            />
          </div>
        )
      case 'barcode':
        return (
          <>
            <div className='code'>
              <img ref={ref => codeRefs.current[`barcode_${index}`] = ref} alt='barcode'></img>
            </div>
          </>
        )
      default:
        break;
    }
  }

  function fitStageIntoParentContainer() {
    if (!stageRef.current) return;

    const stage = stageRef.current;
    let container = document.querySelector('.konvajs-canvas-parent');

    if (!container) return;

    // now we need to fit stage into parent container
    let containerWidth = container.offsetWidth;

    if (containerWidth === 0) return;

    // but we also make the full scene visible
    // so we need to scale all objects on canvas
    let scale = containerWidth / template?.width_px;

    stage.width(template?.width_px * scale);
    stage.height(template?.height_px * scale);
    stage.scale({ x: scale, y: scale });
  }

  useEffect(() => {
    generateCode(codeRefs, stageRef, 'mainCode', template?.type, false);
    Object.keys(codeRefs?.current || {}).forEach((key) => {
      const type = key.split("_")[0] === 'qrCode' ? 'qr' : 'bar';
      generateCode(codeRefs, stageRef, key, type, true);
    });
    alignText(stageRef, template?.text_align);
    // eslint-disable-next-line
  }, [productInfo, template?.type, template?.text_align, template?.code_width_px, template?.code_height_px, template?.elements]);

  useEffect(() => {
    centerText(stageRef, template?.text_centered, template);
    // eslint-disable-next-line
  }, [template?.text_centered]);

  useEffect(() => {
    setupProductInfo(product);
    // eslint-disable-next-line
  }, [template, product, schapkaartjesSettings, selectedEan]);

  useEffect(() => {
    fitStageIntoParentContainer()
    // eslint-disable-next-line
  }, [template.width_px, template.height_px, stageRef])

  useEffect(() => {
    handleImageLoad(stageRef)
    // eslint-disable-next-line
  }, [templateImages, template?.include_images])

  const handleDrag = (event) => {
    if (!props.draggable) return;

    const newTemplate = JSON.parse(JSON.stringify(template))

    const updateTemplate = (node, id) => {
      const isElement = id.includes("element");
      const elementIndex = isElement ? id.split('_')[1] : undefined;

      if (node.className) { // Custom shapes have their className set to undefined for some reason, for those we get it from attrs.
        switch (node.className) {
          case 'Text':
            if (template?.text_centered) node.x(0);
            if (isElement) {
              newTemplate.elements[elementIndex].x = parseInt(node.x());
              newTemplate.elements[elementIndex].y = parseInt(node.y());
            } else {
              newTemplate[`${id}_x`] = parseInt(node.x());
              newTemplate[`${id}_y`] = parseInt(node.y());
            }
            break;
          case 'Rect':
          case 'Circle':
            if (isElement) {
              newTemplate.elements[elementIndex].x = parseInt(node.x());
              newTemplate.elements[elementIndex].y = parseInt(node.y());
              newTemplate.elements[elementIndex].width = parseInt(node.width());
              newTemplate.elements[elementIndex].height = parseInt(node.height());
            } else {
              newTemplate[`${id}_x`] = parseInt(node.x());
              newTemplate[`${id}_y`] = parseInt(node.y());
            }
            break;
          case 'Image':
            if (isElement) {
              newTemplate.elements[elementIndex].x = parseInt(node.x());
              newTemplate.elements[elementIndex].y = parseInt(node.y());
              newTemplate.elements[elementIndex].width = parseInt(node.width());
              newTemplate.elements[elementIndex].height = parseInt(node.height());
            } else {
              newTemplate[`${id}_x`] = parseInt(node.x());
              newTemplate[`${id}_y`] = parseInt(node.y());
              newTemplate.code_width_px = parseInt(node.width());
              newTemplate.code_height_px = parseInt(node.height());
            }
            break;
          default:
            break;
        }
      } else {
        switch (node.attrs.className) {
          case 'Cross':
          case 'Triangle':
            if (isElement) { // This isn't really needed but we'll do it anyways just incase
              newTemplate.elements[elementIndex].x = parseInt(node.x());
              newTemplate.elements[elementIndex].y = parseInt(node.y());
              newTemplate.elements[elementIndex].width = parseInt(node.width());
              newTemplate.elements[elementIndex].height = parseInt(node.height());
            } else {
              newTemplate[`${id}_x`] = parseInt(node.x());
              newTemplate[`${id}_y`] = parseInt(node.y());
            }
            break;
          default:
            break;
        }
      }
    };

    if (transformerRef.current) {
      const nodes = transformerRef.current.nodes();
      if (nodes.length !== 0) {
        nodes.forEach((node) => {
          const id = node.attrs.id;
          updateTemplate(node, id);
        });
      } else {
        const id = event.target.attrs.id;
        updateTemplate(event.target, id);
      }
    }

    setTemplate({ ...template, ...newTemplate });
  };

  const handleDragImage = (event) => {
    if (!props.draggable) return;

    const newTemplateImages = JSON.parse(JSON.stringify(templateImages))

    const updateTemplateImage = (node, id) => {
      const imageIndex = node.attrs.id.split('-')[1];
      newTemplateImages[imageIndex]['image_x'] = parseInt(node.x());
      newTemplateImages[imageIndex]['image_y'] = parseInt(node.y());
      newTemplateImages[imageIndex]['width_px'] = parseInt(node.width());
      newTemplateImages[imageIndex]['height_px'] = parseInt(node.height());
    };

    if (transformerRef.current) {
      const nodes = transformerRef.current.nodes();
      if (nodes.length !== 0) {
        nodes.forEach((node) => {
          const id = node.attrs.id;
          updateTemplateImage(node, id);
        });
      } else {
        const id = event.target.attrs.id;
        updateTemplateImage(event.target, id);
      }
    }

    setTemplateImages(newTemplateImages);
  };

  const handleClick = (event) => {
    if (props.draggable) {
      const element = event.target;
      const transformer = transformerRef.current;
      const metaPressed = event.evt.shiftKey || event.evt.ctrlKey || event.evt.metaKey;
      const isSelected = transformer.nodes().indexOf(element) >= 0;

      if (!transformer || !stageRef.current) {
        return;
      }

      if (isSelected) {
        transformer.nodes([]);
        return;
      }

      if (!metaPressed) {
        transformer.nodes([element]);
      } else {
        let transformerNodes = [...transformer.nodes(), element];
        transformer.nodes(transformerNodes);
      }

      if (element.className) { // Custom shapes have their className set to undefined for some reason, for those we get it from attrs.
        switch (element.className) {
          case 'Text':
            transformer.enabledAnchors(['middle-left', 'middle-right']);
            transformer.visible(true);
            break;
          case 'Circle':
            transformer.enabledAnchors([
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right'
            ]);
            transformer.visible(true);
            break;
          case 'Rect':
          case 'Image':
            transformer.enabledAnchors([
              'top-left',
              'top-center',
              'top-right',
              'middle-right',
              'middle-left',
              'bottom-left',
              'bottom-center',
              'bottom-right'
            ]);
            transformer.visible(true);
            break;
          default:
            break;
        }
      } else {
        switch (event.target.attrs.className) {
          case 'Cross':
          case 'Triangle':
            transformer.enabledAnchors([
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right'
            ]);
            transformer.visible(true);
            break;
          default:
            break;
        }
      }
      if (metaPressed) {
        transformer.enabledAnchors([
          'middle-right',
          'middle-left',
        ]);
      }

      stageRef.current.batchDraw();
    }
  };

  const handleTransform = (event) => {
    if (!props.draggable) return;

    const transformer = transformerRef.current;
    if (!transformer) return;

    const node = event.target;
    const isElement = node.attrs.id.includes("element");
    const isImage = node.attrs.id.includes("image");

    const newTemplate = JSON.parse(JSON.stringify(template))
    const newTemplateImages = JSON.parse(JSON.stringify(templateImages))

    if (node.className) { // Custom shapes have their className set to undefined for some reason, for those we get it from attrs.
      switch (node.className) {
        case 'Text':
          node.setAttrs({
            width: Math.max(node.width() * node.scaleX(), MIN_WIDTH),
            scaleX: 1,
            scaleY: 1,
          });
          if (isElement) {
            const elementIndex = node.attrs.id.split('_')[1];
            newTemplate.elements[elementIndex].width = Math.round(node.width());
            newTemplate.elements[elementIndex].x = parseInt(node.x());
            newTemplate.elements[elementIndex].y = parseInt(node.y());
          } else {
            newTemplate[`${node.attrs.id}_width`] = Math.round(node.width());
            newTemplate[`${node.attrs.id}_x`] = parseInt(node.x());
            newTemplate[`${node.attrs.id}_y`] = parseInt(node.y());
          }
          break;
        case 'Rect':
        case 'Circle':
        case 'Image':
          if (isImage) {
            const imageIndex = node.attrs.id.split('-')[1];
            newTemplateImages[imageIndex].image_x = parseInt(node.x());
            newTemplateImages[imageIndex].image_y = parseInt(node.y());
            newTemplateImages[imageIndex].width_px = Math.round(node.width() * node.scaleX());
            newTemplateImages[imageIndex].height_px = Math.round(node.height() * node.scaleY());
          } else {
            if (isElement) {
              const elementIndex = node.attrs.id.split('_')[1];
              newTemplate.elements[elementIndex].width = Math.round(node.width() * node.scaleX());
              newTemplate.elements[elementIndex].height = Math.round(node.height() * node.scaleY());
              newTemplate.elements[elementIndex].x = parseInt(node.x());
              newTemplate.elements[elementIndex].y = parseInt(node.y());
            } else {
              newTemplate[`${node.attrs.id}_width_px`] = Math.round(node.width() * node.scaleX());
              newTemplate[`${node.attrs.id}_height_px`] = Math.round(node.height() * node.scaleY());
              newTemplate[`${node.attrs.id}_x`] = parseInt(node.x());
              newTemplate[`${node.attrs.id}_y`] = parseInt(node.y());
            }
          }
          break;
        default:
          break;
      }
    } else {
      switch (event.target.attrs.className) {
        case 'Triangle':
        case 'Cross':
          if (isElement) { // Not really needed but we do it just incase
            const elementIndex = node.attrs.id.split('_')[1];
            newTemplate.elements[elementIndex].width = Math.round(node.width() * node.scaleX());
            newTemplate.elements[elementIndex].height = Math.round(node.height() * node.scaleY());
            newTemplate.elements[elementIndex].x = parseInt(node.x());
            newTemplate.elements[elementIndex].y = parseInt(node.y());
          } else {
            newTemplate[`${node.attrs.id}_width_px`] = Math.round(node.width() * node.scaleX());
            newTemplate[`${node.attrs.id}_height_px`] = Math.round(node.height() * node.scaleY());
            newTemplate[`${node.attrs.id}_x`] = parseInt(node.x());
            newTemplate[`${node.attrs.id}_y`] = parseInt(node.y());
          }
          break;
        default:
          break;
      }
    }

    setTemplate(newTemplate);
    setTemplateImages(newTemplateImages)
  };

  window.addEventListener('resize', fitStageIntoParentContainer);

  if (template && Object.keys(template).length > 0) {
    return (
      <>
        <Stage width={template?.width_px} height={template?.height_px} className='template-stage' ref={stageRef} id='templateCanvas'>
          <Layer ref={mainLayerRef}>
            <Rect
              x={0}
              y={0}
              width={parseInt(template?.width_px) - 2}
              height={parseInt(template?.height_px) - 2}
              fill={invertedPrintType === 'normal' ? 'black' : 'white'}
            />
            {template?.include_border_primary &&
              <Rect
                id="border_primary"
                x={parseInt(template?.border_primary_x)}
                y={parseInt(template?.border_primary_y)}
                width={parseInt(template?.border_primary_width_px)}
                height={parseInt(template?.border_primary_height_px)}
                stroke={invertedPrintType === 'normal' ? 'white' : 'black'}
                strokeWidth={2}
                draggable={props.draggable}
                onDragEnd={(event) => handleDrag(event)}
                onClick={(event) => handleClick(event)}
              />
            }
            {template?.include_border_secondary &&
              <Rect
                id="border_secondary"
                x={parseInt(template?.border_secondary_x)}
                y={parseInt(template?.border_secondary_y)}
                width={parseInt(template?.border_secondary_width_px)}
                height={parseInt(template?.border_secondary_height_px)}
                stroke={invertedPrintType === 'normal' ? 'white' : 'black'}
                strokeWidth={2}
                draggable={props.draggable}
                onDragEnd={(event) => handleDrag(event)}
                onClick={(event) => handleClick(event)}
              />
            }
            <Text
              id="title"
              x={parseInt(template?.title_x)}
              y={parseInt(template?.title_y)}
              fontSize={parseInt(template?.title_fontsize_px)}
              width={parseInt(template?.title_width)}
              fontFamily={supportFonts.find(element => element.id === template?.title_text_font)?.name}
              fontStyle={
                (template?.title_text_bold ? 'bold ' : '') +
                (template?.title_text_italic ? 'italic ' : '')
              }
              textDecoration={
                (template?.title_text_underlined ? 'underline ' : '') +
                (template?.title_text_strikethrough ? 'line-through ' : '')
              }
              text={productInfo.name}
              fill={invertedPrintType === 'normal' ? 'white' : template?.title_text_color ?? 'black'}
              align={textAlign}
              draggable={props.draggable}
              onDragEnd={(event) => handleDrag(event)}
              onClick={(event) => handleClick(event)}
            />
            <Text
              id="contents"
              x={parseInt(template?.contents_x)}
              y={parseInt(template?.contents_y)}
              fontSize={parseInt(template?.contents_fontsize_px)}
              width={parseInt(template?.contents_width)}
              fontFamily={supportFonts.find(element => element.id === template?.contents_text_font)?.name}
              fontStyle={
                (template?.contents_text_bold ? 'bold ' : '') +
                (template?.contents_text_italic ? 'italic ' : '')
              }
              textDecoration={
                (template?.contents_text_underlined ? 'underline ' : '') +
                (template?.contents_text_strikethrough ? 'line-through ' : '')
              }
              text={productInfo.description}
              fill={invertedPrintType === 'normal' ? 'white' : template?.contents_text_color ?? 'black'}
              align={textAlign}
              draggable={props.draggable}
              onDragEnd={(event) => handleDrag(event)}
              onClick={(event) => handleClick(event)}
            />
            <Text
              id="price"
              x={parseInt(template?.price_x)}
              y={parseInt(template?.price_y)}
              fontSize={parseInt(template?.price_fontsize_px)}
              width={parseInt(template?.price_width)}
              fontFamily={supportFonts.find(element => element.id === template?.price_text_font)?.name}
              fontStyle={
                (template?.price_text_bold ? 'bold ' : '') +
                (template?.price_text_italic ? 'italic ' : '')
              }
              textDecoration={
                (template?.price_text_underlined ? 'underline ' : '') +
                (template?.price_text_strikethrough ? 'line-through ' : '')
              }
              text={productInfo.price}
              fill={invertedPrintType === 'normal' ? 'white' : template?.price_text_color ?? 'black'}
              align={textAlign}
              draggable={props.draggable}
              onDragEnd={(event) => handleDrag(event)}
              onClick={(event) => handleClick(event)}
            />
            {invertedPrintType === 'normal' &&
              <Rect
                x={parseInt(template?.code_x)}
                y={parseInt(template?.code_y)}
                width={parseInt(template?.code_width_px)}
                height={parseInt(template?.code_height_px)}
                fill='white'
                stroke="white"
                strokeWidth={5}
              />
            }
            {invertedPrintType === 'brother' &&
              <Rect
                x={parseInt(template?.code_x)}
                y={parseInt(template?.code_y)}
                width={parseInt(template?.code_width_px)}
                height={parseInt(template?.code_height_px)}
                fill='black'
                stroke="black"
                strokeWidth={15}
              />
            }
            <Image
              id="code"
              x={parseInt(template?.code_x)}
              y={parseInt(template?.code_y)}
              width={parseInt(template?.code_width_px)}
              height={parseInt(template?.code_height_px)}
              fill='white'
              image={null}
              draggable={props.draggable}
              onDragEnd={(event) => handleDrag(event)}
              onClick={(event) => handleClick(event)}
            />
            {template?.include_images &&
              Object.entries(templateImages).map(([index, image]) => (
                <Image
                  id={`image-${index}`}
                  key={index}
                  x={image?.image_x}
                  y={image?.image_y}
                  width={image?.width_px}
                  height={image?.height_px}
                  draggable={props.draggable}
                  image={null}
                  onDragEnd={(event) => handleDragImage(event)}
                  onClick={(event) => handleClick(event)}
                />
              ))
            }
            {template?.elements.map((element, index) => (
              generateElements(element, index)
            ))}
            {props.draggable &&
              <Transformer
                id="transformer"
                ref={transformerRef}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < MIN_WIDTH) {
                    return oldBox;
                  }
                  return newBox;
                }}
                rotateEnabled={false}
                onTransform={(event) => handleTransform(event)}
                enabled={props.draggable}
                padding={5}
              // centeredScaling={true}
              />
            }
          </Layer>
        </Stage>
        {template?.elements.map((element, index) => (
          generateElementRefs(element, index)
        ))}
        {template?.type === 'qr' ? (
          <div className='code' ref={ref => codeRefs.current['mainCode'] = ref}>
            <QRCodeCanvas
              value={productInfo.code}
              size={512}
              bgColor={invertedPrintType === 'brother' ? "#000000" : '#FFFFFF'}
              fgColor={invertedPrintType === 'brother' ? "#FFFFFF" : '#000000'}
              level={"L"}
              includeMargin={false}
            />
          </div>
        ) : (
          <>
            <div className='code'>
              <img ref={ref => codeRefs.current['mainCode'] = ref} alt='barcode'></img>
            </div>
          </>
        )}
      </>
    );
  }
}
