import { Button, Table } from 'react-bootstrap';
import ContactButtons from '../portal/contacts/contactbuttons';
import PaginationAndSizeComponent from './paginationAndSize';
import TableButton from './tableButton';
import ContactModal from './contactModal';
import { useTranslation } from 'react-i18next';


export default function ContactTable(contacts) {
  const buttons = ContactButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  if (contacts && contacts.content && contacts.content.length > 0) {
    const pages = contacts.total_pages
    const page = (contacts.number + 1)
    for (const object of contacts.content) {
      rows.push(
        <tr key={object.id}>
          <td>{object.hasOwnProperty('person') ? object.person.full_name : ''}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(object) }}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(object) }}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {add && <TableButton {...add} />}
      <Table width='100%'>
        <thead>
          <tr>
            <th width='70%'>{translate('Name')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <ContactModal {...modalview}/>
    </>
  );

}
