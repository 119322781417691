import React from "react";
import { useEffect, useState } from "react";

import "daterangepicker"
import $ from "jquery"

const Datepicker = (props) => {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  
  useEffect(() => {
    $('input[name="daterange"]').daterangepicker({
      startDate: startDate.format('DD/MM/YYYY'),
      endDate: endDate.format('DD/MM/YYYY'),
      ranges: props.ranges,
      locale: props.locale,
      "alwaysShowCalendars": props.alwaysShowCalendars,
      "showDropdowns": props.showDropdowns,
      "opens": props.opens,
      "linkedCalendars": props.linkedCalendars,
      "autoApply": props.autoApply,
      "singleDatePicker": props.singleDatePicker
    }, function(start, end, label) {
      props.onChange(start, end)
      setStartDate(start)
      setEndDate(end)
    });
    // eslint-disable-next-line
  }, [props.singleDatePicker]);

    return (
        <input className={props.className} disabled={props.disabled} type="text" name="daterange"/> 
    )
};
export default Datepicker;