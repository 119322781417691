import React, { Fragment } from "react";
import { Page, Document, Text, View } from "@react-pdf/renderer";
import { styles } from "../pdfstylesheet/stylesStatusReportPDF"
import moment from "moment";

const ReportHeader = ({ report, admin, location }) => {
  let lines = []
  const type = report.period?.type
  const startDate = report.period?.startDate

  const month = moment.unix(startDate).month() + 1 // returns index !!
  const week = moment.unix(startDate).week()
  const readableStartDate = moment.unix(report.period.startDate).format("YYYY-MM-DD")
  const readableEndDate = moment.unix(report.period.endDate).format("YYYY-MM-DD")

  lines.push(<Text style={styles.title}>Administration: {admin?.name}</Text>)
  lines.push(<Text style={styles.title}>Location: {location?.name}</Text>)

  switch (type) {
    case 'statusday':
      lines.push(<Text style={styles.title}>Period: {readableStartDate}</Text>)
      break
    case 'statusweek':
      lines.push(<Text style={styles.title}>Period: week {week}</Text>)
      break
    case 'statusmonth':
      lines.push(<Text style={styles.title}>Period: month {month}</Text>)
      break
    default:
      lines.push(<Text style={styles.title}>Period: {readableStartDate} - {readableEndDate}</Text>)
      break;
  }

  lines.push(<Text style={styles.title}>Report printed at {moment().format("YYYY-MM-DD HH:mm")}</Text>)
  return lines
}

const TableFromKeyValue = ({ items, headername }) => {
  return (
    <View style={styles.tableContainer}>
      <Header headername={headername} />
      <RowsFromKeyValue items={items} />
      <FooterFromKeyValue items={items} />
    </View>
  )
};

const TableFromHashObject = ({ items, headername }) => {
  return (
    <View style={styles.tableContainer}>
      <Header headername={headername} />
      <RowsFromHashObject items={items} />
      <FooterFromHashObject items={items} />
    </View>
  )
};

const Header = ({ headername }) => {
  let headers = []
  headers.push(<Text style={styles['headername']}>{headername}</Text>)
  headers.push(<Text style={styles['headeramount']}>Amount</Text>)
  return (<View style={styles.headerContainer}> {headers} </View>)
};

const RowsFromKeyValue = ({ items }) => {
  const rows = []
  for (const [name, amount] of Object.entries(items)) {
    if (name !== 'total') {
      rows.push(
        <View style={styles.row} key={name}>
          <Text style={styles['rowname']}>{name}</Text>
          <Text style={styles['rowamount']}>{amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
        </View>
      )
    }
  }
  return (<Fragment>{rows}</Fragment>)
};

const RowsFromHashObject = ({ items }) => {
  const rows = []
  for (const [id, hashObject] of Object.entries(items)) {
    if (id !== 'total') {
      rows.push(
        <View style={styles.row} key={id}>
          <Text style={styles['rowname']}>{hashObject.name}</Text>
          <Text style={styles['rowamount']}>{hashObject.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
        </View>
      )
    }
  }
  return (<Fragment>{rows}</Fragment>)
};

const FooterFromKeyValue = ({ items }) => {
  let totalName = 'Total'
  let totalAmount = undefined
  let calculatedAmount = 0
  for (const [name, amount] of Object.entries(items)) {
    if (name !== 'total') {
      calculatedAmount = calculatedAmount + amount
    }
    if (name === 'total') {
      totalAmount = amount
      break
    }
  }

  if (!totalAmount) {
    totalAmount = calculatedAmount
  }

  return (
    <View style={styles.footerRow}>
      <Text style={styles.footerDescription}>{totalName}</Text>
      <Text style={styles.footerTotal}>{totalAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
    </View>
  )
};

const FooterFromHashObject = ({ items }) => {
  let totalName = 'Total'
  let calculatedAmount = 0
  for (const [, hashObject] of Object.entries(items)) {
    calculatedAmount += hashObject.total ?? 0
  }

  return (
    <View style={styles.footerRow}>
      <Text style={styles.footerDescription}>{totalName}</Text>
      <Text style={styles.footerTotal}>{calculatedAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
    </View>
  )
};

const StatusReport = ({ report, admin, location }) => {
  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <Text style={styles.header} fixed>Actablue Report Generator</Text>
        <ReportHeader report={report} admin={admin} location={location} />
        <TableFromKeyValue items={report.calculatedTotals.payments} headername={'Payment'} />
        <TableFromKeyValue items={report.calculatedTotals.taxs} headername={'Tax'} />
        <TableFromHashObject items={report.calculatedTotals.journals} headername={'Journal'} />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
      </Page>
    </Document>
  )
};

export default StatusReport;