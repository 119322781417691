import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

// pages
import Login from '../login/login';
import Signup from '../signup/signup';
import Portal from '../portal/portal';
import Dashboard from '../portal/dashboard/dashboard';
import Subscribe from '../portal/subscribe/subscribe';
import SubscribeAddSubscription from '../portal/subscribe/subscribeaddsubscription';
import Administrations from '../portal/administrations/administrations';
import AdminEdit from '../portal/administrations/adminedit';
import AdminAdd from '../portal/administrations/adminadd';
import Users from '../portal/users/users';
import UserEdit from '../portal/users/useredit';
import UserAdd from '../portal/users/useradd';
import KecyloakUserAdd from '../portal/users/useraddkeycloakuser';
import Employees from '../portal/employees/employees';
import EmployeeAdd from '../portal/employees/employeeAdd';
import EmployeeEdit from '../portal/employees/employeeEdit';
import EmployeeRoles from '../portal/employees/employeeRoles';
import EmployeeRoleAdd from '../portal/employees/employeeRoleAdd';
import EmployeeRoleEdit from '../portal/employees/employeeRoleEdit';
import PrinterAdd from '../portal/settings/printeradd';
import PrinterEdit from '../portal/settings/printeredit';
import Locations from '../portal/locations/locations';
import LocationEdit from '../portal/locations/locationedit';
import LocationAdd from '../portal/locations/locationadd';
import ReservationLocations from '../portal/reservationlocations/reservationlocations';
import ReservationLocationEdit from '../portal/reservationlocations/reservationlocationedit';
import ReservationLocationAdd from '../portal/reservationlocations/reservationlocationadd';
import Devices from '../portal/devices/devices';
import DeviceEdit from '../portal/devices/deviceedit';
import DeviceAdd from '../portal/devices/deviceadd';
import Settings from '../portal/settings/settings';
import Ccvshops from '../portal/ccvshops/ccvshops';
import CcvshopEdit from '../portal/ccvshops/ccvshopedit';
import CcvshopAdd from '../portal/ccvshops/ccvshopadd';
import CcvshopWebhookAdd from '../portal/ccvshops/ccvshopwebhookadd';
import Snelstart from '../portal/snelstart/snelstart';
import SnelstartEdit from '../portal/snelstart/snelstartedit';
import SnelstartAdd from '../portal/snelstart/snelstartadd';
import AccountingEdit from '../portal/accounting/accountingedit';
import AccountingAdd from '../portal/accounting/accountingadd';
import Boarding from '../portal/boarding/boarding';
import Registrations from '../portal/registrations/registrations';
import RegistrationAdd from '../portal/registrations/registrationAdd';
import RegistrationEdit from '../portal/registrations/registrationEdit';
import Relations from '../portal/relations/relations';
import RelationEdit from '../portal/relations/relationedit';
import RelationAdd from '../portal/relations/relationadd';
import Contacts from '../portal/contacts/contacts';
import ContactEdit from '../portal/contacts/contactedit';
import ContactAdd from '../portal/contacts/contactadd';
import Subscriptions from '../portal/subscriptions/subscriptions';
import SubscriptionEdit from '../portal/subscriptions/subscriptionedit';
import SubscriptionAdd from '../portal/subscriptions/subscriptionadd';
import Products from '../portal/products/products'
import Product from '../portal/products/product';
import ProductEdit from '../portal/products/productedit'
import ProductAdd from '../portal/products/productadd'
import ProductPrint from '../portal/products/productprint'
import ProductSuppliers from '../portal/products/productsuppliers'
import ProductSupplierAdd from '../portal/products/productsupplieradd'
import Productsmodified from '../portal/productsmodified/productsmodified'
import ProductEans from '../portal/products/producteans';
import Stock from '../portal/stock/stock'
import StockEdit from '../portal/stock/stockedit'
import StockAdd from '../portal/stock/stockadd'
import Warehouse from '../portal/stock/warehouse'
import WarehouseAdd from '../portal/stock/warehouseadd'
import WarehouseEdit from '../portal/stock/warehouseedit'
import PurchaseAdviceList from '../portal/stock/purchaseAdviceList'
import StockProductEdit from '../portal/stock/productedit';
import RackAdd from '../portal/stock/rackadd'
import RackEdit from '../portal/stock/rackedit'
import Productcategories from '../portal/productcategories/productcategories'
import ProductCategoryEdit from '../portal/productcategories/productcategoryedit'
import ProductCategoryAdd from '../portal/productcategories/productcategoryadd'
import Journalcategories from '../portal/journalcategories/journalcategories'
import JournalCategoryEdit from '../portal/journalcategories/journalcategoryedit'
import JournalCategoryAdd from '../portal/journalcategories/journalcategoryadd'
import Matching from '../portal/matching/matching'
import Vats from '../portal/vats/vats'
import VatEdit from '../portal/vats/vatedit'
import VatAdd from '../portal/vats/vatadd'
import Importing from '../portal/importing/importing'
import ImportProfiles from '../portal/import_profiles/importProfiles';
import ImportProfileEdit from '../portal/import_profiles/importProfileedit';
import Export from '../portal/export/export';
import Attributegroups from '../portal/attributes/attributegroups'
import AttributeGroupEdit from '../portal/attributes/attributegroupedit';
import AttributeGroupAdd from '../portal/attributes/attributegroupadd';
import Shelflabels from '../portal/shelflabels/shelflabels'
import TemplateAdd from '../portal/shelflabels/templateadd'
import Opticon from '../portal/opticon/opticon'
import OpticonConnectionEdit from '../portal/opticon/opticonConnectionEdit';
import OpticonConnectionAdd from '../portal/opticon/opticonConnectionAdd';
import OpticonEslEdit from '../portal/opticon/opticonEslEdit';
import OpticonEslAdd from '../portal/opticon/opticonEslAdd';
import Guestchecks from '../portal/guestchecks/guestchecks'
import GuestcheckEdit from '../portal/guestchecks/guestcheckedit'
import Orders from '../portal/orders/orders'
import CreateTransactionFromOrder from '../portal/orders/createTransactionFromOrder';
import Invoices from '../portal/invoices/invoices'
import Transactions from '../portal/transactions/transactions'
import Kitchenorders from '../portal/kitchenorders/kitchenorder'
import Reservations from '../portal/reservations/reservations'
import ReservationEdit from '../portal/reservations/reservationedit';
import ReservationAdd from '../portal/reservations/reservationadd';
import ReservationObjects from '../portal/reservations/reservationobjects';
import ReservationObjectEdit from '../portal/reservations/reservationobjectedit';
import ReservationObjectAdd from '../portal/reservations/reservationobjectadd';
import ReservationOptions from '../portal/reservations/reservationoptions';
import ReservationOptionEdit from '../portal/reservations/reservationoptionedit';
import ReservationOptionAdd from '../portal/reservations/reservationoptionadd';
import Reports from '../portal/reports/reports'
import Reporting from '../portal/reporting/reporting'
import Eftjournal from '../portal/eftjournal/eftjournal'
import Cashcount from '../portal/cashcount/cashcount'
import Sitemap from '../portal/sitemap/sitemap';
import Analyses from '../portal/analyses/analyses';
import TemplateEdit from '../portal/shelflabels/templateedit';
import Notifications from '../portal/notifications/notifications';
import NotificationApplicationEdit from '../portal/notifications/notificationApplicationEdit';
import NotificationApplicationAdd from '../portal/notifications/notificationApplicationAdd';
import NotificationChannelsEdit from '../portal/notifications/notificationChannelsEdit';
import NotificationChannelsAdd from '../portal/notifications/notificationChannelsAdd';
import NotificationTemplateEdit from '../portal/notifications/notificationTemplateEdit';
import NotificationTemplateAdd from '../portal/notifications/notificationTemplateAdd';
import NotificationReservationTemplateEdit from '../portal/notifications/notificationReservationTemplateEdit';
import NotificationReservationTemplateAdd from '../portal/notifications/notificationReservationTemplateAdd';
import NotificationSubscriptionEdit from '../portal/notifications/notificationSubscriptionEdit';
import NotificationSubscriptionAdd from '../portal/notifications/notificationSubscriptionAdd';
import OnlinePayments from '../portal/onlinepayments/onlinePayments';
import OnlinePaymentEdit from '../portal/onlinepayments/onlinePaymentEdit';
import OnlinePaymentAdd from '../portal/onlinepayments/onlinePaymentAdd';
import DrawerAdd from '../portal/cashcount/draweradd'
import DrawerEdit from '../portal/cashcount/draweredit';
import CountAdd from '../portal/cashcount/countadd';
import CountEdit from '../portal/cashcount/countedit';
import KeycloakUsers from '../portal/keycloak/keycloakusers';
import SearchId from '../portal/search_id/searchId';
import Translations from '../portal/translations/translations';
import Qrordering from '../portal/qrordering/qrordering';
import Loyalty from '../portal/loyalty/loyalty';
import ProgramAdd from '../portal/loyalty/programadd';
import ProgramEdit from '../portal/loyalty/programedit';
import CardAdd from '../portal/loyalty/cardadd';
import CardEdit from '../portal/loyalty/cardedit';
import SortCategoryAdd from '../portal/kitchenmanager/sortcategoryadd';
import SortCategoryEdit from '../portal/kitchenmanager/sortcategoryedit';
import { default as KmPrinterAdd } from '../portal/kitchenmanager/printeradd';
import { default as KmPrinterEdit } from '../portal/kitchenmanager/printeredit';
import NarrowcastingDevices from '../portal/narrowcasting/narrowcastingDevices';
import NarrowcastingSchedules from '../portal/narrowcasting/narrowcastingSchedules';
import NarrowcastingPlaylists from '../portal/narrowcasting/narrowcastingPlaylists';
import NarrowcastingViews from '../portal/narrowcasting/narrowcastingViews';
import NarrowcastingViewEdit from '../portal/narrowcasting/narrowcastingViewEdit';
import NarrowcastingViewAdd from '../portal/narrowcasting/narrowcastingViewAdd';
import NarrowcastingDeviceEdit from '../portal/narrowcasting/narrowcastingDeviceEdit';
import NarrowcastingDeviceAdd from '../portal/narrowcasting/narrowcastingDeviceAdd';
import NarrowcastingScheduleEdit from '../portal/narrowcasting/narrowcastingScheduleEdit';
import NarrowcastingScheduleAdd from '../portal/narrowcasting/narrowcastingScheduleAdd';
import NarrowcastingPlaylistEdit from '../portal/narrowcasting/narrowcastingPlaylistEdit';
import NarrowcastingPlaylistAdd from '../portal/narrowcasting/narrowcastingPlaylistAdd';
import UserRoles from '../portal/users/userRoles';
import UserRoleAdd from '../portal/users/userRoleAdd';
import UserRoleEdit from '../portal/users/userRoleEdit';
import SortCategories from '../portal/kitchenmanager/sortcategories';
import { default as KmDevices } from '../portal/kitchenmanager/devices';
import Planning from '../portal/planning/planning';
import PlanningAdd from '../portal/planning/planningadd';
import PlanningEdit from '../portal/planning/planningedit';
import PlanningObjectAdd from '../portal/planning/planningobjectadd';
import PlanningObjectEdit from '../portal/planning/planningobjectedit';
import ScreenAdd from '../portal/kitchenmanager/screenadd';
import ScreenEdit from '../portal/kitchenmanager/screenedit';
import Resellers from '../portal/kitchenmanager/resellers';
import ResellerAdd from '../portal/kitchenmanager/reselleradd';
import ResellerEdit from '../portal/kitchenmanager/reselleredit';

export default function router() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Login />,
        },
        {
            path: "register",
            element: <Signup />,
        },
        {
            element: <Portal />,
            children: [
                { path: "dashboards", element: <Dashboard /> },
                { path: "subscribe", element: <Subscribe /> },
                { path: "subscribe/subscribe", element: <SubscribeAddSubscription /> },
                { path: "administrations", element: <Administrations /> },
                { path: "administration/edit", element: <AdminEdit /> },
                { path: "administration/add", element: <AdminAdd /> },
                { path: "users", element: <Users /> },
                { path: "users/add", element: <UserAdd /> },
                { path: "users/edit", element: <UserEdit /> },
                { path: "users/keycloakuseradd", element: <KecyloakUserAdd /> },
                { path: "userroles", element: <UserRoles /> },
                { path: "userrole/add", element: <UserRoleAdd /> },
                { path: "userrole/edit", element: <UserRoleEdit /> },
                { path: "employees", element: <Employees /> },
                { path: "employee/add", element: <EmployeeAdd /> },
                { path: "employee/edit", element: <EmployeeEdit /> },
                { path: "employeeroles", element: <EmployeeRoles /> },
                { path: "employeerole/add", element: <EmployeeRoleAdd /> },
                { path: "employeerole/edit", element: <EmployeeRoleEdit /> },
                { path: "locations", element: <Locations /> },
                { path: "location/edit", element: <LocationEdit /> },
                { path: "location/add", element: <LocationAdd /> },
                { path: "reservationlocations", element: <ReservationLocations /> },
                { path: "reservationlocation/edit", element: <ReservationLocationEdit /> },
                { path: "reservationlocation/add", element: <ReservationLocationAdd /> },
                { path: "devices", element: <Devices /> },
                { path: "device/edit", element: <DeviceEdit /> },
                { path: "device/add", element: <DeviceAdd /> },
                { path: "settings", element: <Settings /> },
                { path: "printer/add", element: <PrinterAdd /> },
                { path: "printer/edit", element: <PrinterEdit /> },
                { path: "ccvshops", element: <Ccvshops /> },
                { path: "ccvshop/edit", element: <CcvshopEdit /> },
                { path: "ccvshop/add", element: <CcvshopAdd /> },
                { path: "ccvshopwebhook/add", element: <CcvshopWebhookAdd /> },
                { path: "snelstart", element: <Snelstart /> },
                { path: "snelstart/edit", element: <SnelstartEdit /> },
                { path: "snelstart/add", element: <SnelstartAdd /> },
                { path: "accounting/edit", element: <AccountingEdit /> },
                { path: "accounting/add", element: <AccountingAdd /> },
                { path: "ccvshops", element: <Ccvshops /> },
                { path: "boarding", element: <Boarding /> },
                { path: "registrations", element: <Registrations /> },
                { path: "registration/edit", element: <RegistrationEdit /> },
                { path: "registration/add", element: <RegistrationAdd /> },
                { path: "relations", element: <Relations /> },
                { path: "relation/edit", element: <RelationEdit /> },
                { path: "relation/add", element: <RelationAdd /> },
                { path: "contacts", element: <Contacts /> },
                { path: "contact/edit", element: <ContactEdit /> },
                { path: "contact/add", element: <ContactAdd /> },
                { path: "subscriptions", element: <Subscriptions /> },
                { path: "subscription/edit", element: <SubscriptionEdit /> },
                { path: "subscription/add", element: <SubscriptionAdd /> },
                { path: "products", element: <Products /> },
                { path: "product/:productId", element: <Product /> },
                { path: "product/edit", element: <ProductEdit /> },
                { path: "product/add", element: <ProductAdd /> },
                { path: "product/print", element: <ProductPrint /> },
                { path: "productsuppliers", element: <ProductSuppliers /> },
                { path: "productsupplier/add", element: <ProductSupplierAdd /> },
                { path: "productsmodified", element: <Productsmodified /> },
                { path: "producteans", element: <ProductEans /> },
                { path: "stock", element: <Stock /> },
                { path: "stock/edit", element: <StockEdit /> },
                { path: "stock/add", element: <StockAdd /> },
                { path: "warehouse", element: <Warehouse /> },
                { path: "warehouse/add", element: <WarehouseAdd /> },
                { path: "warehouse/edit", element: <WarehouseEdit /> },
                { path: "purchaseadvice", element: <PurchaseAdviceList /> },
                { path: "purchaseadvice/edit", element: <StockProductEdit /> },
                { path: "rack/add", element: <RackAdd /> },
                { path: "rack/edit", element: <RackEdit /> },
                { path: "productcategories", element: <Productcategories /> },
                { path: 'productcategory/edit', element: <ProductCategoryEdit /> },
                { path: 'productcategory/add', element: <ProductCategoryAdd /> },
                { path: "journalcategories", element: <Journalcategories /> },
                { path: "journalcategory/edit", element: <JournalCategoryEdit /> },
                { path: "journalcategory/add", element: <JournalCategoryAdd /> },
                { path: "matching", element: <Matching /> },
                { path: "vats", element: <Vats /> },
                { path: "vat/edit", element: <VatEdit /> },
                { path: "vat/add", element: <VatAdd /> },
                { path: "importing", element: <Importing /> },
                { path: "importprofiles", element: <ImportProfiles /> },
                { path: "importprofiles/edit", element: <ImportProfileEdit /> },
                { path: "export", element: <Export /> },
                { path: "attributegroups", element: <Attributegroups /> },
                { path: "attributegroup/edit", element: <AttributeGroupEdit /> },
                { path: "attributegroup/add", element: <AttributeGroupAdd /> },
                // {path: "attribute/edit", element: <AttributeEdit />},
                { path: "shelflabels", element: <Shelflabels /> },
                { path: "template/add", element: <TemplateAdd /> },
                { path: "template/edit", element: <TemplateEdit /> },
                { path: "opticon", element: <Opticon /> },
                { path: "opticon/connection/edit", element: <OpticonConnectionEdit /> },
                { path: "opticon/connection/add", element: <OpticonConnectionAdd /> },
                { path: "opticon/esl/edit", element: <OpticonEslEdit /> },
                { path: "opticon/esl/add", element: <OpticonEslAdd /> },
                { path: "guestchecks", element: <Guestchecks /> },
                { path: "guestcheck/edit", element: <GuestcheckEdit /> },
                { path: "orders", element: <Orders /> },
                { path: "order/transaction", element: <CreateTransactionFromOrder /> },
                { path: "invoices", element: <Invoices /> },
                { path: "transactions", element: <Transactions /> },
                { path: "kitchenorders", element: <Kitchenorders /> },
                { path: "reservations", element: <Reservations /> },
                { path: "reservation/edit", element: <ReservationEdit /> },
                { path: "reservation/add", element: <ReservationAdd /> },
                { path: "reservationobjects", element: <ReservationObjects /> },
                { path: "reservationobject/edit", element: <ReservationObjectEdit /> },
                { path: "reservationobject/add", element: <ReservationObjectAdd /> },
                { path: "reservationoptions", element: <ReservationOptions /> },
                { path: "reservationoption/edit", element: <ReservationOptionEdit /> },
                { path: "reservationoption/add", element: <ReservationOptionAdd /> },
                { path: "reports", element: <Reports /> },
                { path: "reporting", element: <Reporting /> },
                { path: "eftjournal", element: <Eftjournal /> },
                { path: "cashcount", element: <Cashcount /> },
                { path: "sitemap", element: <Sitemap /> },
                { path: "analyses", element: <Analyses /> },
                { path: "notifications", element: <Notifications /> },
                { path: "notification/application/add", element: <NotificationApplicationAdd /> },
                { path: "notification/application/edit", element: <NotificationApplicationEdit /> },
                { path: "notification/channel/edit", element: <NotificationChannelsEdit /> },
                { path: "notification/channel/add", element: <NotificationChannelsAdd /> },
                { path: "notification/template/edit", element: <NotificationTemplateEdit /> },
                { path: "notification/template/add", element: <NotificationTemplateAdd /> },
                { path: "notification/reservationtemplate/edit", element: <NotificationReservationTemplateEdit /> },
                { path: "notification/reservationtemplate/add", element: <NotificationReservationTemplateAdd /> },
                { path: "notification/subscription/edit", element: <NotificationSubscriptionEdit /> },
                { path: "notification/subscription/add", element: <NotificationSubscriptionAdd /> },
                { path: "onlinepayments", element: <OnlinePayments /> },
                { path: "onlinepayment/edit", element: <OnlinePaymentEdit /> },
                { path: "onlinepayment/add", element: <OnlinePaymentAdd /> },
                { path: "drawer/add", element: <DrawerAdd /> },
                { path: "drawer/edit", element: <DrawerEdit /> },
                { path: "count/add", element: <CountAdd /> },
                { path: "count/edit", element: <CountEdit /> },
                { path: "keycloak", element: <KeycloakUsers /> },
                { path: "searchid", element: <SearchId /> },
                { path: "translations", element: <Translations /> },
                { path: "qrordering", element: <Qrordering /> },
                { path: "loyalty", element: <Loyalty /> },
                { path: "program/add", element: <ProgramAdd /> },
                { path: "program/edit", element: <ProgramEdit /> },
                { path: "card/add", element: <CardAdd /> },
                { path: "card/edit", element: <CardEdit /> },
                { path: "narrowcastingdevices", element: <NarrowcastingDevices /> },
                { path: "narrowcastingschedules", element: <NarrowcastingSchedules /> },
                { path: "narrowcastingplaylists", element: <NarrowcastingPlaylists /> },
                { path: "narrowcastingviews", element: <NarrowcastingViews /> },
                { path: "narrowcasting/view/edit", element: <NarrowcastingViewEdit /> },
                { path: "narrowcasting/view/add", element: <NarrowcastingViewAdd /> },
                { path: "narrowcasting/device/edit", element: <NarrowcastingDeviceEdit /> },
                { path: "narrowcasting/device/add", element: <NarrowcastingDeviceAdd /> },
                { path: "kitchenmanager/sortcategories", element: <SortCategories /> },
                { path: "kitchenmanager/devices", element: <KmDevices /> },
                { path: "kitchenmanager/resellers", element: <Resellers /> },
                { path: "kitchenmanager/sortcategory/add", element: <SortCategoryAdd /> },
                { path: "kitchenmanager/sortcategory/edit", element: <SortCategoryEdit /> },
                { path: "kitchenmanager/printer/add", element: <KmPrinterAdd /> },
                { path: "kitchenmanager/printer/edit", element: <KmPrinterEdit /> },
                { path: "kitchenmanager/screen/add", element: <ScreenAdd /> },
                { path: "kitchenmanager/screen/edit", element: <ScreenEdit /> },
                { path: "kitchenmanager/reseller/add", element: <ResellerAdd /> },
                { path: "kitchenmanager/reseller/edit", element: <ResellerEdit /> },
                { path: "narrowcasting/schedule/edit", element: <NarrowcastingScheduleEdit /> },
                { path: "narrowcasting/schedule/add", element: <NarrowcastingScheduleAdd /> },
                { path: "narrowcasting/playlist/edit", element: <NarrowcastingPlaylistEdit /> },
                { path: "narrowcasting/playlist/add", element: <NarrowcastingPlaylistAdd /> },
                { path: "planning", element: <Planning /> },
                { path: "planning/add", element: <PlanningAdd /> },
                { path: "planning/edit", element: <PlanningEdit /> },
                { path: "planning/object/add", element: <PlanningObjectAdd /> },
                { path: "planning/object/edit", element: <PlanningObjectEdit /> }
            ]
        },
    ], { basename: process.env.REACT_APP_PREFIX_URL });

    return router
}
