import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Alert, Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getInvalidRegistrations } from '../redux/dataSlices/registrationSlice';

export default function SubscriptionExpiredAlert() {
  const invalidRegistrations = useSelector(getInvalidRegistrations)
  const { t: translate } = useTranslation();
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (invalidRegistrations) {
      setShowAlert(true)
    }
  }, [invalidRegistrations]);

  const onViewHide = () => {
    setShowAlert(false)
  };


  let expiredRows = []

  if (invalidRegistrations) {
    for (const invalidRegistration of Object.values(invalidRegistrations)) {
      expiredRows.push(
        <tr className='product-row' key={invalidRegistration.id}>
          <td>{invalidRegistration.subscription.name}</td>
          <td>{invalidRegistration.start ? new Date(invalidRegistration.start).toLocaleString() : undefined}</td>
          <td>{invalidRegistration.stop ? new Date(invalidRegistration.stop).toLocaleString() : undefined}</td>
          <td>{translate('expired')}</td>
        </tr>
      )
    }
  }

  if (showAlert && expiredRows.length > 0) {
    return (
      <>
        <Toast
          onClose={onViewHide}
          key="subscription-expired-toast"
          className='subscription-expired-toast'
          animation={false}
        >
          <Toast.Header>
            <strong className="me-auto"><i className="bi bi-exclamation-square-fill" /> {translate('WARNING')}!!<br></br>{translate('Some licences are expired')}</strong>
          </Toast.Header>
          <Toast.Body>
            <Table hover width='100%'>
              <thead>
                <tr>
                  <th>{translate('Name')}</th>
                  <th>{translate('Start')}</th>
                  <th>{translate('Stop')}</th>
                  <th>{translate('Reason')}</th>
                </tr>
              </thead>
              <tbody>
                {expiredRows}
              </tbody>
            </Table>
          </Toast.Body>
        </Toast>
      </>
    );
  }


  return null;
}