export const filterOriginChoices = [
  { 'id': 'smartpos', 'name': 'ActaSmart' },
  { 'id': 'ccvshop', 'name': 'Ccvshop' },
  { 'id': 'vectron', 'name': 'Vectron' },
  { 'id': 'internal', 'name': 'Internal' },
]

export const filterStatusChoices = [
  { 'id': 'open', 'name': 'Open' },
  { 'id': 'accepted', 'name': 'Accepted' },
  { 'id': 'intransport', 'name': 'In transport' },
  { 'id': 'complete', 'name': 'Complete' },
  { 'id': 'onhold', 'name': 'On hold' },
  { 'id': 'cancelled', 'name': 'Cancelled' },
  { 'id': 'returned', 'name': 'Returned' },
]