export const transactionAnalyseHighlights = (report) => {
  let newReportHighlights = []

  for (const countryData of Object.values(report).sort((a, b) => b.percentage - a.percentage)) {
    if (countryData.hasOwnProperty('country')) {
      let newLine = {}
      newLine[countryData.country] = {amount:Number(countryData.revenue).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:countryData.percentage.toFixed(2)+'%'}
      newReportHighlights.push(newLine)
    }
  }

  let newLine = {}
      newLine['Gender'] = {}
      newReportHighlights.push(newLine)

  for (const countryData of Object.values(report).sort((a, b) => b.percentage - a.percentage)) {
    if (countryData.hasOwnProperty('gender')) {
      let newLine = {}
      newLine[countryData.gender] = {amount:Number(countryData.revenue).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:countryData.percentage.toFixed(2)+'%'}
      newReportHighlights.push(newLine)
    }
  }

  return newReportHighlights
};