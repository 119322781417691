export default function GetLocationRawScopes(props, addLocationPrefix = false, location_id = null) {
  let scopes = []
  const scopeOptions = ['read', 'create', 'update', 'delete']

  let tempScopes = {}

  props.forEach(registration => {
    if (registration.subscriptions) {
      Object.values(registration.subscriptions).forEach(subscription => {
        subscription.scopes.forEach(scope => {
          let scopeName = scope;
          const splitted = scope.split(':')
          const name = splitted.at(-2)
          const value = splitted.at(-1)
          let index = scopeOptions.findIndex((element) => element === value)

          if (addLocationPrefix && location_id) scopeName = `${location_id}+${scope}`;

          if (scopes[name] && index > scopes[name].index) {
            tempScopes[name] = { scope: scopeName, index: index }
          }
          if (!scopes[name]) {
            tempScopes[name] = { scope: scopeName, index: index }
          }
        })
      })
    }

  });

  Object.values(tempScopes).forEach(scope => {
    scopes.push(scope.scope)
  })

  return scopes
}