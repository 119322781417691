import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import AccountingButtons from '../portal/accounting/accountingbuttons';
import AccountingModal from './accountingModal';
import { useTranslation } from 'react-i18next';


export default function AccountingTable(paymentledgers) {
  let accounting_excists = false
  const { t: translate } = useTranslation();

  const buttons = AccountingButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: paymentledgers.id }) : false

  let rows = []

  for (const index in paymentledgers) {
    const paymentledger = paymentledgers[index]
    accounting_excists = paymentledger.hasOwnProperty('id')
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: paymentledger.id }) : false
    const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: paymentledger.id }) : false

    rows.push(
      <tr className='product-row' key={paymentledger.id}>
        <td>{paymentledger.cash_ledger || "N/A"}</td>
        <td>{paymentledger.eft_ledger || "N/A"}</td>
        <td>{paymentledger.webshop_ledger || "N/A"}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(paymentledger) }} id={paymentledger.id}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(paymentledger) }} id={paymentledger.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  };

  return (
    <>
      {add && !accounting_excists && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Cash ledger')}</th>
            <th>{translate('EFT ledger')}</th>
            <th>{translate('Webshop ledger')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <AccountingModal modal={modalview.modal} />
      </Table>
    </>
  );
}