import axios from "axios";

const DeleteRegistrationByIdSubscriptionSettingsById = ({token, registration_id, subscription_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_ENV}/registrations/registration/${registration_id}/subscriptions/${subscription_id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default DeleteRegistrationByIdSubscriptionSettingsById