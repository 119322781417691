import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';
import { printerPaperTypeChoices } from '../selectfieldchoices/printerselect.mjs';


function PrinterForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('type')) {
    props.type.selected.push({ 'value': props.data.type, 'label': props.data.type })
    if (props.data.type.includes('Bixolon')) {
      props.fields.find(field => field.id === 'paper_type').type = 'select';
      props.fields.find(field => field.id === 'paper_type').required = true;
      props.fields.find(field => field.id === 'gap_length').type = 'number';
      props.fields.find(field => field.id === 'gap_length').required = true;
      props.fields.find(field => field.id === 'gap_offset').type = 'number';
      props.fields.find(field => field.id === 'gap_offset').required = true;
    }
    if (!props.data.type.includes('Bixolon')) {
      props.fields.find(field => field.id === 'paper_type').type = 'hidden';
      props.fields.find(field => field.id === 'paper_type').required = false;
      props.fields.find(field => field.id === 'gap_length').type = 'hidden';
      props.fields.find(field => field.id === 'gap_length').required = false;
      props.fields.find(field => field.id === 'gap_offset').type = 'hidden';
      props.fields.find(field => field.id === 'gap_offset').required = false;
      props.data.paper_type = null
      props.data.gap_length = null
      props.data.gap_offset = null
    }
  }

  if (props.data.hasOwnProperty('paper_type')) {
    if (props.data.paper_type) props.paper_type.selected.push({ 'value': props.data.paper_type, 'label': printerPaperTypeChoices.find(type => type.id === props?.data?.paper_type)?.name })
    if (!props.data.paper_type) props.paper_type.selected = null
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default PrinterForm;