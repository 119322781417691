import { confirmAlert } from "react-confirm-alert";
import { setProgressModalShow, setTaskData } from "../../redux/dataSlices/analyseSlice";
import { setSelectedProducts } from "../../redux/dataSlices/productSlice";
import DelProductIdFromCategory from "../../actablueAPI/products/DelProductIdFromCategory";
import ChangeHashObjectsKeyValueMulti from "../changeHashObjectsKeyValueMulti";
import PutProduct from "../../actablueAPI/products/PutProduct";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setError } from "../../redux/dataSlices/errorSlice";

export function ModifyMultipleProducts({token, admin_id, selectedProducts, multiEditData, dispatch, setModalShow, setMultiEditData, toggleRefresh }) {
  confirmAlert({
    title: 'Confirm to change multiple products',
    message: `Are you sure you wish to change multiple products?\n\nPlease make sure all intended products are selected.`,
    buttons: [
      {
        label: 'Yes',
        onClick: async () => {
          try {
            let newProducts = ChangeHashObjectsKeyValueMulti(selectedProducts, multiEditData)
            let current = 1
            let end = Object.keys(selectedProducts).length
            let task_data = {
              current: current,
              total: end,
              progress: current === 0 ? 0 : current / end
            }

            dispatch(setTaskData(task_data))
            dispatch(setProgressModalShow(true))

            async function multiEditProducts() {
              if (current <= end) {
                const current_id = Object.keys(selectedProducts)[(current - 1)]
                const newProduct = newProducts[current_id];
                const oldProduct = selectedProducts[current_id];

                if (multiEditData.categories && multiEditData.categories.length >= 1) {
                  if (newProduct && oldProduct) {
                    for (const category of Object.values(oldProduct.categories)) {
                      if (category.type === 'JOURNAL') {
                        await DelProductIdFromCategory({token:token, admin_id:admin_id, category_id:category.id, product_id:current_id})
                      }
                      if (category.type === 'PRODUCTPAGE') {
                        newProduct.categories.push(category);
                      }
                    }
                  }
                }
                dispatch(setLoading(true))
                await PutProduct({ token: token, admin_id: admin_id, data: newProduct });
                task_data = {
                  current: current,
                  total: end,
                  progress: current === 0 ? 0 : current / end
                }

                current++
                dispatch(setTaskData(task_data))
                setTimeout(multiEditProducts, 1);
              } else {
                toggleRefresh()
                setModalShow(false);
                dispatch(setProgressModalShow(false));
                dispatch(setSelectedProducts({}));
                setMultiEditData({});
              }
            }

            multiEditProducts();
          } catch (error) {
            console.log('error', error)
            if (!error.code === "ERR_BAD_REQUEST") {
              dispatch(setError(JSON.stringify(`An unknown error occurred.`)));
              toggleRefresh()
              setModalShow(false);
              dispatch(setProgressModalShow(false));
              dispatch(setSelectedProducts({}));
              setMultiEditData({});
              return false;
            }
          }
        }
      },
      {
        label: 'No',
      }
    ]
  });
}

