import { useSelector } from 'react-redux';

import { Modal, Nav, Tab, Button } from 'react-bootstrap';

import { getCcvshopWebhook } from '../redux/dataSlices/ccvshopSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import './css/modal.css'
import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';


export default function CcvshopWebhookModal(props) {
  const object = useSelector(getCcvshopWebhook);
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            {translate('Webhook ID')}: {object.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Type')}: {object.type}</p>
                      <p>{translate('Event')}: {object.event}</p>
                      <p>{translate('CCV Webhook ID')}: {object.ccv_webhook_id}</p>
                      <p>{translate('CCV Webhook Key')}: {object.ccv_webhook_key}</p>
                      <p>{translate('CCV Webhook Callback')}: {object.ccv_webhook_callback}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}