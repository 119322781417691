import { createSlice } from "@reduxjs/toolkit";

export const narrowcastingSlice = createSlice({
  name: "narrowcasting",
  initialState: {
    view_pagesize: 10,
    view_pagination: 1,
    playlist_pagesize: 10,
    playlist_pagination: 1,
    schedule_pagesize: 10,
    schedule_pagination: 1,
    device_pagesize: 10,
    device_pagination: 1,
    hash_devices_all: {},
    hash_schedules_all: {},
    hash_views_all: {},
    hash_campagnes_all: {},
    hash_playlists_all: {}
  },
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload
    },
    setDevices: (state, action) => {
      state.devices = action.payload
    },
    setDevicesAll: (state, action) => {
      state.devices_all = action.payload
      action?.payload?.forEach(device => {
        state.hash_devices_all[device.id] = device
      });
    },
    setView: (state, action) => {
      state.view = action.payload
    },
    setViews: (state, action) => {
      state.views = action.payload
    },
    setViewsAll: (state, action) => {
      state.views_all = action.payload
      action?.payload?.forEach(view => {
        state.hash_views_all[view.id] = view
      });
    },
    setSchedule: (state, action) => {
      state.schedule = action.payload
    },
    setSchedules: (state, action) => {
      state.schedules = action.payload
    },
    setSchedulesAll: (state, action) => {
      state.schedules_all = action.payload
      action?.payload?.forEach(schedule => {
        state.hash_schedules_all[schedule.id] = schedule
      });
    },
    setPlaylist: (state, action) => {
      state.playlist = action.payload
    },
    setPlaylists: (state, action) => {
      state.playlists = action.payload
    },
    setPlaylistsAll: (state, action) => {
      state.playlists_all = action.payload
      action?.payload?.forEach(playlist => {
        state.hash_playlists_all[playlist.id] = playlist
      });
    },
    setNarrowcastingDevicesModalShow: (state, action) => {
      state.narrowcasting_devices_modalshow = action.payload
    },
    setNarrowcastingSchedulesModalShow: (state, action) => {
      state.narrowcasting_schedules_modalshow = action.payload
    },
    setNarrowcastingViewsModalShow: (state, action) => {
      state.narrowcasting_views_modalshow = action.payload
    },
    setNarrowcastingPlaylistsModalShow: (state, action) => {
      state.narrowcasting_playlists_modalshow = action.payload
    },
    setViewPagination: (state, action) => {
      state.view_pagination = action.payload
    },
    setViewPageSize: (state, action) => {
      state.view_pagesize = action.payload
    },
    setPlaylistPagination: (state, action) => {
      state.playlist_pagination = action.payload
    },
    setPlaylistPageSize: (state, action) => {
      state.playlist_pagesize = action.payload
    },
    setSchedulePagination: (state, action) => {
      state.schedule_pagination = action.payload
    },
    setSchedulePageSize: (state, action) => {
      state.schedule_pagesize = action.payload
    },
    setDevicePagination: (state, action) => {
      state.device_pagination = action.payload
    },
    setDevicePageSize: (state, action) => {
      state.device_pagesize = action.payload
    }
  }
});

export const {
  setDevice, setDevices, setDevicesAll,
  setView, setViews, setViewsAll,
  setSchedule, setSchedules, setSchedulesAll,
  setPlaylist, setPlaylists, setPlaylistsAll,
  setNarrowcastingDevicesModalShow,
  setNarrowcastingSchedulesModalShow,
  setNarrowcastingViewsModalShow,
  setNarrowcastingPlaylistsModalShow,
  setViewPageSize, setViewPagination,
  setPlaylistPageSize, setPlaylistPagination,
  setSchedulePageSize, setSchedulePagination,
  setDevicePageSize, setDevicePagination
} = narrowcastingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getDevice = (state) => state.narrowcasting.device
export const getDevices = (state) => state.narrowcasting.devices
export const getDevicesAll = (state) => state.narrowcasting.devices_all
export const getHashDevicesAll = (state) => state.narrowcasting.hash_devices_all

export const getView = (state) => state.narrowcasting.view
export const getViews = (state) => state.narrowcasting.views
export const getViewsAll = (state) => state.narrowcasting.views_all
export const getHashViewsAll = (state) => state.narrowcasting.hash_views_all

export const getSchedule = (state) => state.narrowcasting.schedule
export const getSchedules = (state) => state.narrowcasting.schedules
export const getSchedulesAll = (state) => state.narrowcasting.schedules_all
export const getHashSchedulesAll = (state) => state.narrowcasting.hash_schedules_all

export const getPlaylist = (state) => state.narrowcasting.playlist
export const getPlaylists = (state) => state.narrowcasting.playlists
export const getPlaylistsAll = (state) => state.narrowcasting.playlists_all
export const getHashPlaylistsAll = (state) => state.narrowcasting.hash_playlists_all

export const getNarrowcastingDevicesModalShow = (state) => state.narrowcasting.narrowcasting_devices_modalshow
export const getNarrowcastingSchedulesModalShow = (state) => state.narrowcasting.narrowcasting_schedules_modalshow
export const getNarrowcastingViewsModalShow = (state) => state.narrowcasting.narrowcasting_views_modalshow
export const getNarrowcastingPlaylistsModalShow = (state) => state.narrowcasting.narrowcasting_playlists_modalshow

export const getViewPagination = (state) => state.narrowcasting.view_pagination
export const getViewPageSize = (state) => state.narrowcasting.view_pagesize
export const getPlaylistPagination = (state) => state.narrowcasting.playlist_pagination
export const getPlaylistPageSize = (state) => state.narrowcasting.playlist_pagesize
export const getSchedulePagination = (state) => state.narrowcasting.schedule_pagination
export const getSchedulePageSize = (state) => state.narrowcasting.schedule_pagesize
export const getDevicePagination = (state) => state.narrowcasting.device_pagination
export const getDevicePageSize = (state) => state.narrowcasting.device_pagesize

export default narrowcastingSlice.reducer;