export const defaultTemplate =
{
  "base_label": false,
  "name": "New Template",
  "type": "qr",
  "esl_model": "other",
  "height_px": 200,
  "width_px": 400,
  "text_centered": false,
  "text_align": "Left",
  "title_x": 5,
  "title_y": 5,
  "title_width": 395,
  "title_fontsize_px": 35,
  "title_text_color": "#000000",
  "title_text_font": "ArialBlack",
  "title_text_bold": false,
  "title_text_italic": false,
  "title_text_underlined": false,
  "title_text_strikethrough": false,
  "contents_x": 5,
  "contents_y": 35,
  "contents_width": 395,
  "contents_fontsize_px": 25,
  "contents_text_color": "#000000",
  "contents_text_font": "ArialBlack",
  "contents_text_bold": false,
  "contents_text_italic": false,
  "contents_text_underlined": false,
  "contents_text_strikethrough": false,
  "price_x": 5,
  "price_y": 95,
  "price_width": 395,
  "price_fontsize_px": 40,
  "price_text_color": "#000000",
  "price_text_font": "ArialBlack",
  "price_text_bold": false,
  "price_text_italic": false,
  "price_text_underlined": false,
  "price_text_strikethrough": false,
  "code_x": 205,
  "code_y": 40,
  "code_width_px": 128,
  "code_height_px": 128,
  "include_border_primary": false,
  "border_primary_x": 0,
  "border_primary_y": 0,
  "border_primary_width_px": 128,
  "border_primary_height_px": 128,
  "include_border_secondary": false,
  "border_secondary_x": 0,
  "border_secondary_y": 0,
  "border_secondary_width_px": 128,
  "border_secondary_height_px": 128,
  "include_images": false,
  "images": [],
  "elements": []
}

export const templateElementDefault =
{
  "name": "New Element",
  "type": "rectangle",
  "x": 0,
  "y": 0,
  "width": 128,
  "height": 128,
  "opacity": 255,
  "fill": "#FFFFFF",
  "outline_color": "#000000",
  "outline_size": 1,
  "text": "Element Text",
  "text_fontsize": 16,
  "text_type": "custom",
  "text_font": "ArialBlack",
  "text_bold": false,
  "text_italic": false,
  "text_underlined": false,
  "text_strikethrough": false
}