export default function HandleOnChangeServiceHours(props) {
  const event = props.event
  const object = props.object || {}

  const prefix = event.target.attributes.prefix ? event.target.attributes.prefix.nodeValue : false

  let newObject = JSON.parse(JSON.stringify(object))
  // let newObject = Object.assign({}, object)

  if (!newObject.hasOwnProperty('opening_times')) {
    newObject.opening_times = []
  }

  let foundDay = false
  for (const day of Object.values(newObject.opening_times)) {
    if (day.day === Number(prefix)) {
      day[event.target.id] = event.target.value
      foundDay = true
    }
  }
  if (!foundDay) {
    let newDay = {}
    newDay.location_id = object.id
    newDay.day = Number(prefix)
    newDay[event.target.id] = event.target.value
    newObject.opening_times.push(newDay)
  }

  return (newObject)
}


