import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

export default function PaymentTable(props) {
  const { t: translate } = useTranslation();

  if (props.payments) {
    return (
      <Table hover width='100%'>
        <thead>
          <tr>
            <th width='20%'></th>
            <th width='20%'></th>
            <th width='20%'></th>
            <th width='20%'>{translate('Name')}</th>
            <th width='20%'>Amount</th>
          </tr>
        </thead>
        <tbody>
          {props.payments.map((payment) => {
            return (
              <tr key={payment.id}>
                <td></td>
                <td></td>
                <td></td>
                <td>{payment.name}</td>
                <td>{Number(payment.amount).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    );
  }
  return (
    <Table width='100%'>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>{translate('Name')}</th>
          <th>Amount</th>
        </tr>
      </thead>
    </Table>
  );
}