import axios from "axios";

const GetAllTemplates = ({ token, admin_id, location_id, base_labels = true, page = 0, size = 25, sorted = true, payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/schapkaartjes/template/all`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id,
        'base_labels': base_labels,
        'page': page,
        'size': size
      },
    })
      .then(response => {

        let total = response.data.total


        let updatedPayload = [...payload];
        if (response.data.items) {
          updatedPayload = [...payload, ...response.data.items];
        }
        let count = updatedPayload.length

        if (count < total) {
          const nextPage = response.data.page + 1
          GetAllTemplates({
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            base_labels: base_labels,
            page: nextPage,
            size: size,
            sorted: true,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          if (!sorted) resolve(updatedPayload);
          const sortedItems = Object.values(updatedPayload)
            .sort((a, b) => b.base_label - a.base_label);

          resolve(sortedItems)
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default GetAllTemplates