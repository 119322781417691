import { Table } from 'react-bootstrap';


export default function ProductsReportMonth(productsReportMonth) {
  let productRowsDays = []

  if (!productsReportMonth.days || Object.keys(productsReportMonth.days).length === 0) return <p>No data</p>

  if (productsReportMonth.days) {
    for (let [date, day] of Object.entries(productsReportMonth.days)) {
      let productRowsDay = []

      if (date === 'totals') {
        let productRow = []
        for (const [productId, product] of Object.entries(day).sort(([a, adata], [b, bdata]) => bdata.amount - adata.amount)) {
          productRow.push(
            <tr key={productId}>
              <td>{product.name}</td>
              <td>{product.amount}</td>
              <td>{product.total.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
            </tr>
          )
        }
        productRowsDay.push(
          <tr key='totals'>
            <td><strong>Totals</strong></td>
            <td>
              <Table hover width='100%'>
                <thead>
                  <tr>
                    <th width='40%'>Product</th>
                    <th width='30%'>Amount</th>
                    <th width='30%'>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {productRow}
                </tbody>
              </Table>
            </td>
          </tr>
        )
        productRowsDays.push(productRowsDay)
      }
    }
  }

  return (
    <Table width='100%'>
      <thead>
        <tr key='header'>
          <th width='10%'>Date</th>
          <th width='90%'>Products</th>
        </tr>
      </thead>
      <tbody>
        {productRowsDays}
      </tbody>
    </Table>
  )
}