import axios from "axios";

const GetRelationsContactListAll = ({ token, admin_id, page, size }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/relations/contact/list/all`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'page': page,
        'size': size
      },
    })
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(error);
      console.error("error:", error)
    });
  });
};

export default GetRelationsContactListAll