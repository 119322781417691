import axios from "axios";

export default function GetKeycloakResellersAll({token, first, max, payload = [], resolver = null}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_KEYCLOAK_ADMIN_URL}/${process.env.REACT_APP_KEYCLOAK_REALM}/roles/reseller/users`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
      data: {
        first: first,
        max: max
      }    
    })
    .then(response => {
      // console.log('keycloak response', response)
      let updatedPayload = [...payload]
      if (response.data) {
        updatedPayload = [...payload, ...response.data]
      }
      const count = response.data.length
      if (count === max) {
        const next = first + max
        GetKeycloakResellersAll({
          token:token,
          first: next,
          max: max,
          payload: updatedPayload,
          resolver: resolver || resolve
        })
      } else {
        if (resolver) resolver(updatedPayload)
          resolve(updatedPayload)
      }
    })
    .catch(error => {reject(error)})
  });
};