import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const reportingDateSlice = createSlice({
  name: "reportingDate",
  initialState: {
    start_date: moment().startOf('day').unix(),
    end_date: moment().endOf('day').unix()
  },
  reducers: {
    setStartDate: (state, action) => {
      state.start_date = action.payload
    },
    setEndDate: (state, action) => {
      state.end_date = action.payload
    }
  }
});

export const {
  setStartDate, setEndDate
} = reportingDateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getStartDate = (state) => state.reportingDate.start_date
export const getEndDate = (state) => state.reportingDate.end_date

export default reportingDateSlice.reducer;