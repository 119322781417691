import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import { useTranslation } from 'react-i18next';
import UserRoleButtons from '../portal/users/userRoleButtons';
import './css/productTable.css';
import UserRoleModal from './userRoleModal';

export default function UserRoleTable(props) {
  const data = props.userRoles
  const buttons = UserRoleButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let rows = []
  if (data) {
    for (const user_role of Object.values(data)) {
      rows.push(
        <tr className='product-row' key={user_role.id}>
          <td>{user_role.name}</td>
          <td>
            {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(user_role) }} id={user_role.id}>{translate(modalview.tag)}</Button>}
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(user_role) }}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }

  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <UserRoleModal modal={modalview.modal} />
    </>
  );
}