import axios from "axios";

const GetGuestChecksStatus = ({token, admin_id, location_id, state}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/guestchecks/guestcheck"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        'state': state
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {
      if (error.response.status === 404) {resolve({})}
      else {reject(error)}
    });
  });
};

export default GetGuestChecksStatus