import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import UserButtons from '../portal/users/userbuttons';
import UserModal from './userModal';
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

export default function UserTable(props) {
  const users = props.users
  const buttons = UserButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const addkeycloakuser = buttons.hasOwnProperty('addkeycloakuser') ? Object.assign({}, buttons.addkeycloakuser) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();

  let rows = []
  if (users) {
    for (const user of Object.values(users)) {
      let role = 'No matching role'
      if (user.roles && props.roles) {
        let foundRoles = props.roles.filter(role => JSON.stringify(role.roles || {}).toLowerCase() === JSON.stringify(user.roles).toLowerCase())
        if (foundRoles.length > 0) {
          let newRole = ''
          for (const role of foundRoles) {
            newRole = newRole.concat(`${role.name} `)
          }
          role = newRole
        }
      }

      rows.push(
        <tr className='product-row' key={user.id}>
          <td>{user.name}</td>
          <td>{user.hasOwnProperty('type') ? user.type : ''}</td>
          <td>{role}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(user) }} id={user.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(user) }} id={user.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }

  }

  return (
    <>
      {add && <TableButton {...add} />}
      {addkeycloakuser && <TableButton {...addkeycloakuser} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Roles')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <UserModal modal={modalview.modal} />
      </Table>
    </>
  );
}