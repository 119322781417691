import { useDispatch, useSelector } from "react-redux";

import { Form, Modal } from "react-bootstrap";
import { getEmployeePasswordModalShow, setEmployeePasswordModalShow } from "../redux/dataSlices/cashcountSlice";
import { getEmployee } from "../redux/dataSlices/employeeSlice";

function EmployeePasswordModal(props) {
  const employeePasswordModalShow = useSelector(getEmployeePasswordModalShow);
  const employee = useSelector(getEmployee)
  const dispatch = useDispatch()



  return (
    <Modal
      size="md"
      show={employeePasswordModalShow}
      onHide={() => dispatch(setEmployeePasswordModalShow(false))}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-md modal-fullscreen-md-down"
      contentClassName='w-100 h-100'
      className='product-modal'
      centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title id="modal-sizes-title-sm">
          <h5>Please enter pincode for employee {employee?.name}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="employeePincode">
          <Form.Label>Pincode</Form.Label>
          <Form.Control type="password" 
                        name={"employeePincode"} 
                        maxLength={4} 
                        onChange={props?.modal?.onPincodeChange} 
                        disabled={props?.modal?.verifying} 
                        isInvalid={Boolean(props?.modal?.invalid)} 
                        autoComplete="new-password"
                        autoFocus />
          <Form.Control.Feedback type="invalid">
            Please enter a valid pincode
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
}

export default EmployeePasswordModal;