import { useSelector } from "react-redux"
import { getRole } from "../../redux/dataSlices/tokenSlice"

const VectronFormFields = () => {
  const role = useSelector(getRole)

  const settings = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'text', name: 'Timezone', id: 'timezone' },

    { type: 'number', name: 'Operator number', id: 'operator_no' },
    { type: 'text', name: 'Operator pin', id: 'operator_pin' },

    { type: 'text', name: 'Online order type', id: 'online_order_type' },
    { type: 'checkbox', name: 'Auto numbering', id: 'auto_numbering' },

    { type: 'number', name: 'Paid in', id: 'paid_in' },
    { type: 'number', name: 'Paid out', id: 'paid_out' },

    { type: 'number', name: 'Payment number', id: 'payment_no' },

    { type: 'number', name: 'Product does not exist', id: 'product_does_not_exist' },

    { type: 'number', name: 'Deposit number', id: 'deposit_no' },
    { type: 'number', name: 'Deposit in advance', id: 'deposit_in_advance' },
    { type: 'number', name: 'Deposit in advance correction', id: 'deposit_in_advance_correction' },

    { type: 'number', name: 'Discount number', id: 'discount_no' },

    { type: 'checkbox', name: 'Debug', id: 'debug' },

  ]


  let smartposDeviceFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'uid', id: 'uid', readonly: true },

    // single option select fields  
    { type: 'select', name: `Type`, id: 'type', formdatafield: 'type' },
    { type: 'hidden', name: 'Administration', id: 'administration_id', readonly: true },
    { type: 'select', name: 'Location', id: 'location_id', formdatafield: 'location_id' },

    // single option fields
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: 'Serial number', id: 'serial_number' },
    { type: 'email', name: 'Email', id: 'email' },
    { type: 'text', name: 'Software version', id: 'version_sw' },
    { type: 'text', name: 'Script version', id: 'version_script' },
    { type: 'text', name: 'Register number', id: 'register_number' },
    { type: 'checkbox', name: 'Active', id: 'active' },

    // single option object fields
    { type: 'object', name: 'Settings', id: 'settings', fields: settings },


    // multiple options select field
    // terminals
  ]

  if (role === 'super' || role === 'admin') {
    smartposDeviceFormFields.splice(3, 1, // overwrite index 3 
      { type: 'select', name: 'Administration', id: 'administration_id', formdatafield: 'administration_id' },
    )
  }

  return smartposDeviceFormFields
}

export default VectronFormFields