import axios from "axios";

const PostShelflabelsProductLinkRefresh = ({ token, admin_id, location_id, product_id, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/schapkaartjes/link/refresh`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        admin_id: admin_id,
        location_id: location_id,
        product_id: product_id
      },
      data: data
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default PostShelflabelsProductLinkRefresh