import { Button, Table } from 'react-bootstrap';
import VatButtons from '../portal/vats/vatbuttons';
import PaginationAndSizeComponent from './paginationAndSize';
import TableButton from './tableButton';
import VatModal from './vatModal';
import { useTranslation } from 'react-i18next';

export default function VatTable(vats) {
  const buttons = VatButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  let pages = 0
  let page = 0

  if (vats && vats.content && vats.content.length > 0) {
    pages = vats.total_pages
    page = (vats.number + 1)

    vats.content.map((vat) => {
      rows.push(
        <tr key={vat.id}>
          <td>{vat.name}</td>
          <td>{vat.value}</td>
          <td>{JSON.stringify(vat.active)}</td>
          <td>{vat.ledger}</td>
          <td>{vat.external_id}</td>
          <td>
            <p>
              {translate('Created')}: {new Date(vat.creation).toLocaleString()}
            </p>
            <p>
              {translate('Modified')}: {new Date(vat.modification).toLocaleString()}
            </p>
          </td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(vat) }}>{translate(modalview.tag)}</Button>
            {edit  && <Button size="sm" type='button' onClick={() => { edit.onClick(vat) }}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    })
    
  }
  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      {add && <TableButton {...add} />}
      <Table width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Name')}</th>
            <th>%</th>
            <th>{translate('Active')}</th>
            <th>{translate('Ledger')}</th>
            <th>{translate('External id')}</th>
            <th>{translate('Date')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <VatModal modal={modalview.modal} />
    </>
  );
}
