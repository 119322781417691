import { useSelector } from 'react-redux';
import { Modal, Nav, Tab, Button } from 'react-bootstrap';
import ProductButtons from '../portal/products/productbuttons';

import { getProduct, getProductModalTab } from '../redux/dataSlices/productSlice';
import { getRole } from '../redux/dataSlices/tokenSlice';

import Barcode from 'react-barcode';
import './css/modal.css'
import './css/productModal.css'
import JsonHighlighter from './serviceJsonHighlight';

import DOMPurify from 'dompurify'
import { config } from '../defaultformvalues/dompurifyConfig.mjs';
import { isHTML } from '../helpers/htmlChecker';
import { getHashAdministrationsAll } from '../redux/dataSlices/administrationSlice';
import { getHashLocationsAll } from '../redux/dataSlices/locationSlice';
import { useTranslation } from 'react-i18next';
import { getHashRelationsAll } from '../redux/dataSlices/relationSlice';

export default function ProductModal(props) {
  const object = useSelector(getProduct);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const hash_relations_all = useSelector(getHashRelationsAll);
  const role = useSelector(getRole)
  const defaultActiveKey = useSelector(getProductModalTab)
  const { t: translate } = useTranslation();
  const navSelect = ProductButtons().modalTabSelect

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }


  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header className="product-modal-head" >
          <Modal.Title className='text-center' id="contained-modal-title-vcenter">
            Product: {object.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='cst-modal-body'>
          <Tab.Container defaultActiveKey={defaultActiveKey}>
            <Nav variant="tabs" onSelect={navSelect.onSelect}>
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="ingredients">{translate('Ingredients')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="nutrients">{translate('Nutrients')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="allergenics">{translate('Allergenics')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="eans">{translate('EANS')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="categories">{translate('Categories')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="specifications">{translate('Specifications')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="suppliers">{translate('Supplierproducts')}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="attributecombinations">{translate('Attributecombinations')}</Nav.Link></Nav.Item>
              <Nav.Item ><Nav.Link eventKey="warehouse">{translate('Warehouse')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col-3">
                      <img className="thumbnail" src={object.thumbnail} alt='' width='256'></img>
                    </div>
                    <div className='col-3'></div>
                    <div className="col">
                      <p>Administration: {hash_admins_all[object.administration_id]?.name}</p>
                      <p>Location: {hash_locations_all[object.location_id]?.name}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.name}</p>
                      <p>Description: {!isHTML(DOMPurify.sanitize(object.description, config)) ? object.description : ''} </p>

                      {isHTML(DOMPurify.sanitize(object.description, config)) ?
                        <div className='product-description' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(object.description, config) }}></div>
                        :
                        ''
                      }

                      <p>Type: {object.type}</p>
                      <p>Active: {JSON.stringify(object.active)}</p>
                      <p>SKU Code: {object.sku_code}</p>
                      <p>PLU Code: {object.plu_code}</p>
                      <p>Ask SN: {object.ask_sn}</p>
                      <p>Input required: {object.input_required ? 'True' : 'False' }</p>
                      <p>Marge: {object.marge}</p>
                    </div>
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                      <p>Price Incl. Vat: {Number(object.price_incl_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                      <p>Price Excl. Vat: {Number(object.price_excl_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                      <p>Discount: {object.discount ? Number(object.discount).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 'None'}</p>
                      <p>Emballage: {object.emballage ? Number(object.emballage).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 'None'}</p>
                      <p>Ledger: {object.ledger}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="ingredients">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Ingredients</h4>
                  <div className="row">
                    <div className="col">
                      {object.ingredients ?
                        <div>
                          <ul>
                            {object.ingredients.map((ingredient) => (
                              <li>
                                <p key={ingredient}>{ingredient}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                        :
                        <h5>No Ingredients</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="nutrients">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Nutrients</h4>
                  <div className="row">
                    <div className="col">
                      {object.nutrients ?
                        <div>
                          <ul>
                            {object.nutrients.map((nutrient) => (
                              <li>
                                <p key={nutrient.id}>{nutrient.name} {nutrient.value} {nutrient.value_per_serving} {nutrient.guide_line_daily_amount} {nutrient.measurement_precision} {nutrient.measurement_unit}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                        :
                        <h5>No Nutrients Found</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="allergenics">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Allergenics</h4>
                  <div className="row">
                    <div className="col">
                      {object.allergenics ?
                        <div>
                          <ul>
                            {object.allergenics.map((allergenic) => (
                              <li>
                                <p key={allergenic}>{allergenic}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                        :
                        <h5>No Allergenics Found</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="eans">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Product EANS</h4>
                  <div className="row">
                    <div className="col">
                      {object.eans ?
                        <div className='ean-container'>
                          <ul>
                            {object.eans.map((ean) => (
                              <li>
                                <p key={ean.id}>ID: {ean.id}</p>
                                <p>EAN Code: {ean.ean_code}</p>
                                <Barcode value={ean.ean_code}></Barcode>
                              </li>
                            ))}
                          </ul>
                        </div>
                        :
                        <h5>No EANS Found</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="categories">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <h4 className='text-start'>Journal categories</h4>
                    {object.categories ?
                      <div className='category-container'>
                        <div className="row">
                          {object.categories.map((category) => (
                            category.type === 'JOURNAL' ?
                              <div className="col">
                                <h5>{category.title}</h5>
                                <p key={category.id}>ID: {category.id}</p>
                                <p>Is visible: {JSON.stringify(category.is_visible)}</p>
                                <p>Created: {new Date(category.creation).toLocaleString()}</p>
                                <p>Modified: {new Date(category.modification).toLocaleString()}</p>
                              </div>
                              : category.type !== 'JOURNAL' ?
                                ''
                                :
                                ''
                          ))}
                        </div>
                      </div>
                      :
                      <div className='col'>
                        <h5>No categories found</h5>
                      </div>
                    }
                  </div>
                  <br></br>
                  <div className="row">
                    <h4 className='text-start'>Product Categories</h4>
                    {object.categories ?
                      <div className='category-container'>
                        <div className="row">
                          {object.categories.map((category) => (
                            category.type === 'PRODUCTPAGE' ?
                              <div className="col">
                                <h5>{category.title}</h5>
                                <p key={category.id}>ID: {category.id}</p>
                                <p>Is visible: {JSON.stringify(category.is_visible)}</p>
                                <p>Created: {new Date(category.creation).toLocaleString()}</p>
                                <p>Modified: {new Date(category.modification).toLocaleString()}</p>
                              </div>
                              : category.type !== 'PRODUCTPAGE' ?
                                ''
                                :
                                ''
                          ))}
                        </div>
                      </div>
                      :
                      <div className='col'>
                        <h5>No categories found</h5>
                      </div>
                    }
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="specifications"> 
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Specifications</h4>
                  {object.hasOwnProperty('product_specifications') ?
                    <div className="col">
                      <p>CCVid: {object.product_specifications?.ccvid}</p>
                      <p>Brand: {object.product_specifications?.brand}</p>
                      <p>Model: {object.sproduct_specifications?.model}</p>
                      <p>Packaging: {object.product_specifications?.packaging}</p>
                      <p>Contents: {object.product_specifications?.contents}</p>
                      <p>Weight: {object.product_specifications?.weigth}</p>
                      <p>Height: {object.product_specifications?.heigth}</p>
                      <p>Length: {object.product_specifications?.length}</p>
                      <p>Width: {object.product_specifications?.width}</p>
                      <p>Batch number: {object.product_specifications?.batch_number}</p>
                      <p>Expires on: {object.product_specifications?.expires_on}</p>
                    </div>
                    : <></>}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="suppliers">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Supplier products</h4>
                  {object.hasOwnProperty('suppliers') ?
                    object.suppliers.map((supplier) => (
                      <div className="col">
                        <p>{translate('Name')}: {hash_relations_all[supplier.relation_id]?.name ?? "None"}</p>
                        <p>{translate('Supplier product code')}: {supplier.supplier_artikel_code}</p>
                        <p>{translate('Supplier product price')}: {Number(supplier.supplier_price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                        <p>{translate('Order unit')}: {supplier.packaging ?? "1"}</p>
                        <p>{translate('Created')}: {new Date(supplier.modification).toLocaleString()}</p>
                        <p>{translate('Modified')}: {new Date(supplier.modification).toLocaleString()}</p>
                      </div>
                    ))
                    : <></>}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="attributecombinations">
                <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Attributecombinations EANS</h4>
                  <div className="row">
                    <div className="col">
                      {object.attribute_combinations ?
                        <div className='ean-container'>
                          <ul>
                            {object.attribute_combinations.map((attribute_combination) => (
                              attribute_combination.product?.eans ?
                                attribute_combination.product.eans.map((ean) => (
                                  <li>
                                    <p key={attribute_combination.id}>ID: {attribute_combination.id}</p>
                                    <p>{translate('Name')}: {attribute_combination.product.name}</p>
                                    <p>EAN Code: {ean.ean_code}</p>
                                    <Barcode value={ean.ean_code}></Barcode>
                                  </li>))
                                : <></>
                            ))}
                          </ul>
                        </div>
                        :
                        <h5>No EANS Found</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="warehouse">
              <br></br>
                <div className="container text-center">
                  <h4 className='text-start'>Warehouse</h4>
                  <div className="row">
                    <div className="col">
                      {object.warehouse ?
                          <ul>
                            {object.warehouse.map((warehouse) => (
                              warehouse.product?.product_locations ?
                              warehouse.product.product_locations.map((product_location) => (
                                <li>
                                  <p>{translate('Warehouse')}: {product_location.warehouse_name}</p>
                                  <p>{translate('Rack')}: {product_location.rack_name}</p>
                                  <p>{translate('Quantity')}: {product_location.quantity}</p>
                                </li>))
                              : <></>

                            ))}
                          </ul>
                        : <h5>No info found</h5>
                      }
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <br></br>
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                    <div className="col">

                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Administration: {object.administration_id}</p>
                    </div>
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Created: {new Date(object.creation).toLocaleString()}</p>
                    </div>
                    <div className="col">
                      <p>Modified: {new Date(object.modification).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}