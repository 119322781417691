import { React } from "react";
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { ReactSVG } from "react-svg";
import { FlagProvider } from '@unleash/proxy-client-react';
import './i18n';

// store
import store from './redux/store';
import { Provider } from 'react-redux';

// router
import { RouterProvider } from 'react-router-dom';
import router from './router/router';

// icons
import humbeelogo from './static/icons/humbeelogo.ico'
import humbeelogo192 from './static/icons/humbeelogo192.png'
import actabluelogo from './static/icons/actabluelogo.ico'
import actabluelogo192 from './static/icons/actabluelogo192.png'
import Close from "./static/icons/close.svg";

Sentry.init({
  // dsn: "https://fe3b359bd96b49baa2758f98343a3cc8@sentry.staging.octoblue.nl/4",
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", process.env.REACT_APP_SENTRY_PROPTARGET],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const flagProviderConfig = {
  url: `${process.env.REACT_APP_UNLEASH_URL}/proxy`, //, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: process.env.REACT_APP_UNLEASH_CLIENTKEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: process.env.REACT_APP_UNLEASH_INTERVAL, // How often (in seconds) the client should poll the proxy for updates
  appName: process.env.REACT_APP_UNLEASH_APPLICATION_NAME, // The name of your application. It's only used for identifying your application
};

const websiteUrl = window.location.href;
const favicon = document.getElementById("favicon");
const appleTouchIcon = document.getElementById("apple-touch-icon");

switch (true) {
  case websiteUrl.includes('humbee'):
    if (favicon) favicon.href = humbeelogo;
    if (appleTouchIcon) appleTouchIcon.href = humbeelogo192;
    document.title = 'Humbee';
    import('./css/humbee.css').then(() => {
      renderApp();
    });
    break;
  case websiteUrl.includes('actablue'):
  case websiteUrl.includes('octoblue'):
    if (favicon) favicon.href = actabluelogo;
    if (appleTouchIcon) appleTouchIcon.href = actabluelogo192;
    document.title = 'Mijn Actablue';
    import('./css/actablue.css').then(() => {
      renderApp();
    });
    break;
  default:
    if (favicon) favicon.href = actabluelogo;
    if (appleTouchIcon) appleTouchIcon.href = actabluelogo192;
    document.title = 'Mijn Actablue';
    import('./css/actablue.css').then(() => {
      renderApp();
    });
}

function renderApp() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    // <React.StrictMode>
    <FlagProvider config={flagProviderConfig}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        action={(snackbarId) => (
          <>
            <div className="d-flex" style={{ height: '2.7rem', color: 'inherit' }}>
              <div className="vr"></div>
            </div>
            <button className='notistack-dismiss' onClick={() => closeSnackbar(snackbarId)}>
              <ReactSVG src={Close} />
            </button>
          </>
        )}>
        <Provider store={store}>
          <RouterProvider router={router()}></RouterProvider>
        </Provider>
      </SnackbarProvider>
    </FlagProvider>
    // </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
