import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';

import './css/productEdit.css'



function MatchingForm(props) {
  const { t: translate } = useTranslation();
  if (props.data.hasOwnProperty('productcategory')) {
    props.productcategory.selected = { 'value': props.data.productcategory.id, 'label': props.data.productcategory.title }
  }

  if (props.data.hasOwnProperty('journalcategory')) {
    props.journalcategory.selected = { 'value': props.data.journalcategory.id, 'label': props.data.journalcategory.title }
  }

  let matchCategoryForm = []
  let matchCategoriesForm = []
  let matchProductForm = []

  props.matchingProductCatgoryFormFields.forEach(field => {
    matchCategoryForm.push(CreateField(field, props))
  });
  matchCategoryForm.push(<Button variant="primary" onClick={props.matchingProductCatgorySubmit.onClick}>{translate(props.matchingProductCatgorySubmit.title)}</Button>)

  props.matchingProductCatgoriesFormFields.forEach(field => {
    matchCategoriesForm.push(CreateField(field, props))
  });
  matchCategoriesForm.push(<Button variant="primary" onClick={props.matchingProductCatgoriesSubmit.onClick}>{translate(props.matchingProductCatgoriesSubmit.title)}</Button>)

  props.matchingAllProductsFormFields.forEach(field => {
    matchProductForm.push(CreateField(field, props))
  });
  matchProductForm.push(<Button variant="primary" onClick={props.matchingAllProductsSubmit.onClick}>{translate(props.matchingAllProductsSubmit.title)}</Button>)


  return (
    <Form>
      <Tab.Container defaultActiveKey="matchcategory">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="matchcategory">
              {translate('Match category')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="matchcategories">
              {translate('Match categories')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="matchallproducts">
              {translate('Match all products')}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="matchcategory">
            <div className='tab-container'>
              {matchCategoryForm}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="matchcategories">
            <div className='tab-container'>
              {matchCategoriesForm}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="matchallproducts">
            <div className='tab-container'>
              {matchProductForm}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Form>
  );
}

export default MatchingForm;