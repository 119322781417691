import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getVats, setVats, getVatPagination, getVatPagesize, setVatsAll } from "../../redux/dataSlices/vatSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import VatTable from "../../components/vatTable";
import GetVatList from "../../actablueAPI/products/GetVatList";
import GetAllVats from "../../helpers/getAllVats";
import useHandleError from "../../customhooks/useHandleError";

const Vats = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const vats = useSelector(getVats)
  const pagination = useSelector(getVatPagination);
  const pagesize = useSelector(getVatPagesize)
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const navTitle = "Vats"

  const vatsCard = {
    size: 12,
    title: navTitle,
    data: <VatTable {...vats} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
      .then((response) => { dispatch(setVatsAll(response)) })
      .catch(error => {HandleError({ error: error })})
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      GetVatList({token:token, admin_id:admin_id, location_id:location_id, page: pagination, size:pagesize})
      .then(response => {
        dispatch(setVats(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError({ error: error })
        dispatch(setLoading(false))
      })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pagesize]);

  return (
    <>
      <BasicCard {...vatsCard} />
    </>
  )
};

export default Vats;
