import { createSlice } from "@reduxjs/toolkit";

export const onlinePaymentSlice = createSlice({
  name: "onlinepayment",
  initialState: {
    refresh: false
  },
  reducers: {
    setOnlinePaymentId: (state, action) => {
      state.online_payment_id = action.payload;
    },
    setOnlinePayment: (state, action) => {
      state.online_payment = action.payload;
    },
    setOnlinePayments: (state, action) => {
      state.online_payments = action.payload
    },
    setOnlinePaymentsAll: (state, action) => {
      state.online_payments_all = action.payload
      action?.payload?.forEach(onlinePayment => {
        state.hash_online_payments_all[onlinePayment.id] = onlinePayment
      });
    },
    setOnlinePaymentModalShow: (state, action) => {
      state.online_payment_modalshow = action.payload
    },
    setOnlinePaymentPagination: (state, action) => {
      state.online_payment_pagination = (action.payload - 1)
    },
    setOnlinePaymentPageSize: (state, action) => {
      state.online_payment_pagesize = action.payload
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload
    }
  }
});

export const {
  setOnlinePaymentd, setOnlinePayment, setOnlinePayments, setOnlinePaymentModalShow, setOnlinePaymentPagination, setOnlinePaymentPageSize, setOnlinePaymentsAll, setRefresh
} = onlinePaymentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getOnlinePaymentId = (state) => state.onlinepayment.online_payment_id
export const getOnlinePayment = (state) => state.onlinepayment.online_payment
export const getOnlinePayments = (state) => state.onlinepayment.online_payments
export const getOnlinePaymentsAll = (state) => state.onlinepayment.online_payments_all
export const getHashOnlinePaymentsAll = (state) => state.onlinepayment.hash_online_payments_all
export const getOnlinePaymentModalShow = (state) => state.onlinepayment.online_payment_modalshow
export const getOnlinePaymentPagination = (state) => state.onlinepayment.online_payment_pagination
export const getOnlinePaymentPageSize = (state) => state.onlinepayment.online_payment_pagesize
export const getRefresh = (state) => state.onlinepayment.refresh

export default onlinePaymentSlice.reducer;