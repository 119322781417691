import { Table } from 'react-bootstrap'

export default function ImportDataTable(data) {
  if (data.data && data.data.length > 0) {
    return (
      <>
        <Table hover width='100%'>
          <thead>
            <tr>
              {Object.keys(data.data[0]).map((key) => (<th key={key}>{key}</th>))}
            </tr>
          </thead>
          <tbody>
            {data.data.slice(0, 10).map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}