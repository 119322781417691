import { Table } from 'react-bootstrap';


export default function EftJournalReceipt(eftJournalReceipt) {
  let eftJournalReceiptLines = []

  if (eftJournalReceipt) {
    if (eftJournalReceipt.lines?.length === 3) {
      mspReceipt()
    }
    if (eftJournalReceipt.lines?.length > 3) {
      ccvReceipt()
    }
  }
 
  function ccvReceipt() {
    for (const line of eftJournalReceipt.lines) {
      eftJournalReceiptLines.push(<tr>{line[1]}</tr>)
    }
  }

  function mspReceipt() {
    for (const lineArray of eftJournalReceipt.lines) {
      for (const line of lineArray) {
        eftJournalReceiptLines.push(<tr>{line}</tr>)
      }
    }
  }

  return (
    <Table width='100%'>
      <tbody>
        {eftJournalReceiptLines}
      </tbody>
    </Table>
  )
}