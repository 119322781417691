import axios from "axios";

const GetAllAttributegroups = ({ token, admin_id, location_id, page = 0, payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/products/attribute/list"),
      headers: {
        'Authorization': 'Bearer '.concat(token['access_token']),
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'page': page,
        'size': 25,
        'location': location_id
      },
    })
      .then(response => {
        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }

        if (response.data.last !== true) {
          const nextPage = response.data.number + 1
          GetAllAttributegroups({
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            page: nextPage,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => {
        reject(error)
      });
  });
};

export default GetAllAttributegroups