import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import SnelstartForm from "../../components/snelstartForm";
import { snelstartKeysAddFormFields } from "./snelstartFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import GetSnelstartTemplates from "../../actablueAPI/snelstart/GetSnelstartTemplates";
import { getSnelstartTemplates, setSnelstartTemplates } from "../../redux/dataSlices/snelstartSlice";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { syncVerkoopOrderProcesStatusChoices } from "../../selectfieldchoices/snelstartSelect.mjs";
import PostSnelstartCompany from "../../actablueAPI/snelstart/PostSnelstartCompany";

const SnelstartAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const snelstartTemplates = useSelector(getSnelstartTemplates)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snelstart_keys, setSnelstartKeys] = useState({})

  const onChange = (event) => {
    let newCompany = HandleOnChange({ event: event, object: snelstart_keys })
    setSnelstartKeys(newCompany)
  }

  const onOrderSjabloonChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_keys, selectfield: 'default_verkoop_order_sjabloon' })
    setSnelstartKeys(newCompany)
  };

  const onDirectPaymentSjabloonChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_keys, selectfield: 'direct_payment_verkoop_order_sjabloon' })
    setSnelstartKeys(newCompany)
  };

  const onOnAcountOrderSjabloonChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_keys, selectfield: 'invoice_verkoop_order_sjabloon' })
    setSnelstartKeys(newCompany)
  };


  const onSyncVerkoopOrderProcesStatusChange = (event) => {
    let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_keys, selectfield: 'sync_verkoop_order_proces_status' })
    setSnelstartKeys(newCompany)
  }

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PostSnelstartCompany({ token: token, admin_id: admin_id, identifier: snelstart_keys.snelstart_administratie_identifier, data: snelstart_keys })
      .then(response => {
        navigate("/snelstart");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: snelstartKeysAddFormFields,
    field: {
      onChange: onChange
    },
    data: snelstart_keys,
    default_verkoop_order_sjabloon: {
      label: 'default_verkoop_order_sjabloon',
      options: snelstartTemplates,
      selected: [],
      onChange: onOrderSjabloonChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    direct_payment_verkoop_order_sjabloon: {
      label: 'direct_payment_verkoop_order_sjabloon',
      options: snelstartTemplates,
      selected: [],
      onChange: onDirectPaymentSjabloonChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    invoice_verkoop_order_sjabloon: {
      label: 'invoice_verkoop_order_sjabloon',
      options: snelstartTemplates,
      selected: [],
      onChange: onOnAcountOrderSjabloonChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    sync_verkoop_order_proces_status: {
      label: 'sync_verkoop_order_proces_status',
      options: syncVerkoopOrderProcesStatusChoices,
      onChange: onSyncVerkoopOrderProcesStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: "Snelstart add ",
    data: <SnelstartForm {...formData} />,
  };

  useEffect(() => {
    GetSnelstartTemplates({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setSnelstartTemplates(response.data))
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default SnelstartAdd;

