export const salesReportHighlights = (report, allCategories) => {
  let hashCategories = {}
  let reportCumulated = {}
  let newReportHighlights = [{'Main categories':''}]
  let totalRevenue = 0
  let totalQuantity = 0

  // create hash from allCategories
  for (const category of allCategories) {
    hashCategories[category.id] = Object.assign({}, category)
  }

  // add parent to categories
  for (const category of allCategories) {
    if (category.children) {
      for (const child of category.children) {
        if (hashCategories[child.id]) {
          hashCategories[child.id]['parent'] = category.id
        }
      }
    }
  }

  if (report.hasOwnProperty('days')) {
    for (const [day, dayData ] of Object.entries(report.days)) {
      if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/i.test(day)) {
        totalRevenue += dayData.totals?.total
        totalQuantity += dayData.totals?.amount
        for (const [journalCategoryId, journalData] of Object.entries(dayData)) {
          if (journalCategoryId !== 'totals' && journalCategoryId !== 'revenue' ) {
            if (reportCumulated[journalCategoryId]) {
              // totals
              reportCumulated[journalCategoryId].totals.total += journalData.totals.total
              reportCumulated[journalCategoryId].totals.amount += journalData.totals.amount
              // products
              for (const [productId, productData] of Object.entries(journalData.products)) {
                if (reportCumulated[journalCategoryId].products[productId]) {
                  reportCumulated[journalCategoryId].products[productId].amount += productData.amount
                  reportCumulated[journalCategoryId].products[productId].total += productData.total
                }
                if (!reportCumulated[journalCategoryId].products[productId]) {
                  reportCumulated[journalCategoryId].products[productId] = productData
                }
              }
            }
            if (!reportCumulated[journalCategoryId]) {
              reportCumulated[journalCategoryId] = journalData
              reportCumulated[journalCategoryId].bottomlevel = true
            }
          }
        }
      } 
    }

    // add percentage to journals, add precentage to products, add parents revenue
    for (const [journalCategoryId, journalData] of Object.entries(Object.assign({}, reportCumulated))) {
      searchParentV2(hashCategories, reportCumulated, journalCategoryId, journalData, totalRevenue)
      addPercentageToBottomLevels(journalData, totalRevenue)
      addPercentageToProducts(journalData, totalRevenue)
    }
  }
  

  for (const [, journalData] of Object.entries(reportCumulated).sort(([a, adata], [b, bdata]) => bdata.totals.total - adata.totals.total)) {
    if (journalData.toplevel) {
      let newLine = {}
      newLine[journalData.name] = {amount:Number(journalData.totals.total).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:journalData.totals.percentage?.concat('%')}
      newReportHighlights.push(newLine)
    }
  }

  newReportHighlights.push({'Total':{amount:Number(totalRevenue).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }), percentage:'100.00%'}})

  // add to report
  report.totalRevenue = {totalRevenue:totalRevenue, totalQuantity:totalQuantity}
  report.cumulated = reportCumulated
  report.hashCategories = hashCategories

  if (!report?.cumulated || Object.keys(report?.cumulated).length === 0) return false

  return newReportHighlights
};

function addRevenueToParentV2(reportCumulated, parentId, journalData, name, totalRevenue) {
  if (reportCumulated.hasOwnProperty(parentId)) {
    reportCumulated[parentId].totals.total += journalData.totals.total
    reportCumulated[parentId].totals.amount += journalData.totals.amount
    reportCumulated[parentId].totals.percentage = ((Number(reportCumulated[parentId].totals.total)*100)/Number(totalRevenue)).toFixed(2)
  }
  if (!reportCumulated.hasOwnProperty(parentId)) {
    reportCumulated[parentId] = {}
    reportCumulated[parentId].name = name ? name : journalData.name
    reportCumulated[parentId].totals = {}
    reportCumulated[parentId].totals.total = journalData.totals.total
    reportCumulated[parentId].totals.amount = journalData.totals.amount
    reportCumulated[parentId].totals.percentage = ((Number(reportCumulated[parentId].totals.total)*100)/Number(totalRevenue)).toFixed(2)
  }
}

function searchParentV2(hashCategories, reportCumulated, categoryId, journalData, totalRevenue) {
  if (!hashCategories[categoryId]?.hasOwnProperty('parent')) {
    reportCumulated[categoryId].toplevel = true
  }
  if (hashCategories[categoryId]?.hasOwnProperty('parent')) {
    const parentId = hashCategories[categoryId].parent
    const parentName = hashCategories[parentId]?.title
    addRevenueToParentV2(reportCumulated, parentId, journalData, parentName, totalRevenue)
    searchParentV2(hashCategories, reportCumulated, parentId, journalData, totalRevenue)
  }
}

function addPercentageToProducts(journalData, totalRevenue) {
  for (const [, productData] of Object.entries(journalData.products)) {
    productData.percentage = ((Number(productData.total)*100)/Number(totalRevenue)).toFixed(2)
  } 
}

function addPercentageToBottomLevels(journalData, totalRevenue) {
  if (journalData.bottomlevel) {
    journalData.totals.percentage = ((Number(journalData.totals.total)*100)/Number(totalRevenue)).toFixed(2)
  }
}