import * as React from "react";
import Button from "react-bootstrap/Button";
import SelectField from "../components/selectField";
import Datepicker from "../components/datepicker";
import "./css/date-selectfield-button.css"

import { Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const DatePickerSelectFieldRefreshButton = (props) => {
  const datePicker = props.datePicker
  const refreshButton = props.refreshButton
  const reportSelect = props.reportSelect
  const { t: translate } = useTranslation();

  if (datePicker && refreshButton && reportSelect) {
    return (
      <Col md={4} className="reporting-picker-list">
        <ul className="horizontal-list">
          <li>
            <Datepicker {...datePicker} />
          </li>
          <li>
            <SelectField {...reportSelect} />
          </li>
          <li>
            <Button onClick={refreshButton.onClick}>{translate(refreshButton.tag)}</Button>
          </li>
        </ul>
      </Col>
    );
  }
};
export default DatePickerSelectFieldRefreshButton;
