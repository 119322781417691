import { Button, Table } from 'react-bootstrap'
import SnelstartButtons from '../portal/snelstart/snelstartbuttons';
import SnelstartModal from './snelstartModal';
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

export default function SnelstartTable(company) {
  // no company = empty object... check for snelstart_administratie_identifier
  const { t: translate } = useTranslation();
  const company_excists = company.hasOwnProperty('snelstart_administratie_identifier')

  const buttons = SnelstartButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: company.id }) : false

  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: company.id }) : false
  const import_product = buttons.hasOwnProperty('import_product') ? Object.assign({}, buttons.import_product, { id: company.id }) : false
  const import_products_and_productpages = buttons.hasOwnProperty('import_products_and_productpages') ? Object.assign({}, buttons.import_products_and_productpages, { id: company.id }) : false
  const import_categories = buttons.hasOwnProperty('import_categories') ? Object.assign({}, buttons.import_categories, { id: company.id }) : false
  const import_categories_and_productpages = buttons.hasOwnProperty('import_categories_and_productpages') ? Object.assign({}, buttons.import_categories_and_productpages, { id: company.id }) : false
  const import_relations = buttons.hasOwnProperty('import_relations') ? Object.assign({}, buttons.import_relations, { id: company.id }) : false
  const import_orders = buttons.hasOwnProperty('import_orders') ? Object.assign({}, buttons.import_orders, { id: company.id }) : false
  const sync_orders = buttons.hasOwnProperty('sync_orders') ? Object.assign({}, buttons.sync_orders, { id: company.id }) : false
  const sync_products = buttons.hasOwnProperty('sync_products') ? Object.assign({}, buttons.sync_products, { id: company.id }) : false
  const sync_relations = buttons.hasOwnProperty('sync_relations') ? Object.assign({}, buttons.sync_relations, { id: company.id }) : false
  const sync_invoices = buttons.hasOwnProperty('sync_invoices') ? Object.assign({}, buttons.sync_invoices, { id: company.id }) : false

  const snelstartsettings = buttons.hasOwnProperty('snelstartsettings') ? Object.assign({}, buttons.snelstartsettings, { id: company.id }) : false
  const snelstartconnect = buttons.hasOwnProperty('snelstartconnect') ? Object.assign({}, buttons.snelstartconnect, { id: company.id }) : false

  let rows = []
  if (company_excists) {
    rows.push(
      <tr className='product-row' key={company.snelstart_administratie_identifier}>
        <td>{company.company_name}</td>
        <td>{company.email}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(company) }} id={company.id}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(company) }} id={company.id}>{translate(edit.tag)}</Button>}
          {snelstartsettings && <Button size="sm" type='button' onClick={() => { snelstartsettings.onClick(company) }} id={company.id}>{translate(snelstartsettings.tag)}</Button>}
          {snelstartconnect && <Button size="sm" type='button' onClick={() => { snelstartconnect.onClick(company) }} id={company.id}>{translate(snelstartconnect.tag)}</Button>}
        </td>
        <td>
          {import_product && <Button size="sm" type='button' onClick={() => { buttons.import_product.onClick() }}>{translate(import_product.tag)}</Button>}
          {import_products_and_productpages && <Button size="sm" type='button' onClick={() => { buttons.import_products_and_productpages.onClick() }}>{translate(import_products_and_productpages.tag)}</Button>}
          {import_categories_and_productpages && <Button size="sm" type='button' onClick={() => { buttons.import_categories_and_productpages.onClick() }}>{translate(import_categories_and_productpages.tag)}</Button>}
          {import_categories && <Button size="sm" type='button' onClick={() => { buttons.import_categories.onClick() }}>{translate(import_categories.tag)}</Button>}
          {import_relations && <Button size="sm" type='button' onClick={() => { buttons.import_relations.onClick() }}>{translate(import_relations.tag)}</Button>}
          {import_orders && <Button size="sm" type='button' onClick={() => { buttons.import_orders.onClick() }}>{translate(import_orders.tag)}</Button>}
        </td>
        <td>
          {sync_orders && <Button size="sm" type='button' onClick={() => { buttons.sync_orders.onClick() }}>{translate(sync_orders.tag)}</Button>}
          {sync_products && <Button size="sm" type='button' onClick={() => { buttons.sync_products.onClick() }}>{translate(sync_products.tag)}</Button>}
          {sync_relations && <Button size="sm" type='button' onClick={() => { buttons.sync_relations.onClick() }}>{translate(sync_relations.tag)}</Button>}
          {sync_invoices && <Button size="sm" type='button' onClick={() => { buttons.sync_invoices.onClick() }}>{translate(sync_invoices.tag)}</Button>}
        </td>
      </tr>
    )
  }


  return (
    <>
      {/* {add && <TableButton {...add} />} */}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Name')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Actions')}</th>
            <th>{translate('Imports')}</th>
            <th>{translate('Sync')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <SnelstartModal modal={modalview.modal} />
      </Table>
    </>
  );
}