export default function OrderHighLightCalculation (orders) {
  let total_orders = 0
  let ordercount = 0 
  let average = 0
  let waiting = 0
  for (let order in orders) {
    total_orders = total_orders + Number(orders[order].payment_amount_inclusive_vat)
    ordercount = ordercount + 1
    if (orders[order].status === 'OPEN') {
      waiting = waiting + 1
    }
  };
  if (ordercount > 0) {
    average = total_orders/ordercount
  }
  average = Number(average).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  return [{Total:ordercount}, {Average:average}, {Waiting:waiting}]
}