import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import BasicCard from "../../components/card"
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import TranslationTable from "../../components/translationTable";

const Translations = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const translationCard = {
    size: 12,
    title: 'Translations',
    data: <TranslationTable />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle('Translations'))
    // eslint-disable-next-line
  }, []);

    return (
    <>
      <BasicCard {...translationCard} />
    </>
  )
};
export default Translations;
