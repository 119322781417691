export const snelstartCompanyFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'text', name: 'name', id: 'name' },
  { type: 'email', name: 'email', id: 'email' },
  { type: 'text', name: 'coc', id: 'coc' },
  { type: 'text', name: 'country_code', id: 'country_code' },
  { type: 'text', name: 'company_name', id: 'company_name' },
  { type: 'number', name: 'default_cash_register', id: 'default_cash_register' },
  { type: 'number', name: 'cash_gap_ledger', id: 'cash_gap_ledger' },
  { type: 'number', name: 'default_emballage_ledger', id: 'default_emballage_ledger' },
  { type: 'checkbox', name: 'write_product', id: 'write_product' },
  { type: 'checkbox', name: 'write_order', id: 'write_order' },
  { type: 'checkbox', name: 'write_as_order', id: 'write_as_order' },
  { type: 'checkbox', name: 'write_relation', id: 'write_relation' },
  { type: 'checkbox', name: 'skip_non_active_artikels', id: 'skip_non_active_artikels' },
  { type: 'checkbox', name: 'prices_inclusive_tax', id: 'prices_inclusive_tax' },
  { type: 'checkbox', name: 'verkoop_order_inclusive_tax', id: 'verkoop_order_inclusive_tax' },
  { type: 'select', name: 'default_verkoop_order_sjabloon', id: 'default_verkoop_order_sjabloon', formdatafield: 'default_verkoop_order_sjabloon' },
  { type: 'select', name: 'direct_payment_verkoop_order_sjabloon', id: 'direct_payment_verkoop_order_sjabloon', formdatafield: 'direct_payment_verkoop_order_sjabloon' },
  { type: 'select', name: 'invoice_verkoop_order_sjabloon', id: 'invoice_verkoop_order_sjabloon', formdatafield: 'invoice_verkoop_order_sjabloon' },
  { type: 'checkbox', name: 'rounded_prices', id: 'rounded_prices' },
  { type: 'number', name: 'price_decimals', id: 'price_decimals' },
  { type: 'select', name: 'sync_verkoop_order_proces_status', id: 'sync_verkoop_order_proces_status', formdatafield: 'sync_verkoop_order_proces_status' },
  { type: 'checkbox', name: 'sync_product', id: 'sync_product' },
  { type: 'checkbox', name: 'sync_relation', id: 'sync_relation' },
  { type: 'checkbox', name: 'sync_verkoop_order', id: 'sync_verkoop_order' },
  { type: 'checkbox', name: 'sync_open_verkoop_factuur', id: 'sync_open_verkoop_factuur' },
  // { type: 'checkbox', name: 'access_to_verkoop_orders', id: 'access_to_verkoop_orders' },
]

export const snelstartKeysAddFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
  { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
]

export const snelstartKeysEditFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
  { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
  { type: 'text', name: 'default_no_relation', id: 'default_no_relation' },
  { type: 'text', name: 'subscriptionKey', id: 'subscriptionKey' },
  { type: 'text', name: 'clientKey', id: 'clientKey' },
  { type: 'text', name: 'username', id: 'username' },
  { type: 'text', name: 'password', id: 'password' },
  { type: 'text', name: 'authentication_method', id: 'authentication_method', readonly: true }
]

// parameters snelstart 26-6-2024
// "snelstart_administratie_identifier": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "administration_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "name": "string",
// "email": "string",
// "coc": "string",
// "country_code": "string",
// "company_name": "string",
// "cash_gap_ledger": 0,
// "default_cash_register": 0,
// "default_emballage_ledger": 0,
// "write_product": true,
// "write_order": true,
// "write_as_order": true,
// "write_relation": true,
// "skip_non_active_artikels": true,
// "prices_inclusive_tax": true,
// "verkoop_order_inclusive_tax": true,
// "sync_open_verkoop_factuur": true,
// "sync_verkoop_order": true,
// "sync_product": true,
// "sync_relation": true,
// "sync_verkoop_order_proces_status": "Order",
// "access_to_verkoop_orders": true,
// "rounded_prices": true,
// "default_verkoop_order_sjabloon": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "direct_payment_verkoop_order_sjabloon": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "invoice_verkoop_order_sjabloon": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// "price_decimals": 0,