export default function CreateCategoryTree(categories) {
  let hashCategories = {}
  let topLevel = []

  if (categories?.length > 0) {
    // create hash from allCategories
    for (const category of categories) {
      hashCategories[category.id] = Object.assign({}, category)
    }

    for (let category of Object.values(hashCategories)) {
      if (!category.parent || !hashCategories[category.parent.id]) {
        topLevel.push(category)
      }
    }
  }

  return topLevel
}