import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getRole } from "../../redux/dataSlices/tokenSlice";
import { getLocationsAll, getHashLocationsAll, setLocationsAllAdmins, setLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, setAdministrationsAll, getHashAdministrationsAll, setAdministrationRegistrations, getAdministrationRegistrations } from "../../redux/dataSlices/administrationSlice";
import { getSelectedAdmin_id, setSelectedAdminId, setSelectedAdmin, setSelectedAdminScopes } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id, setSelectedLocationId, setSelectedLocation, setSelectedLocationScopes } from "../../redux/dataSlices/selectLocationSlice";
import { setLocationLoading, getLocationLoading } from "../../redux/dataSlices/loadingSlice";
import { setScopes } from "../../redux/dataSlices/scopesSlice";
import { setSidebar } from "../../redux/dataSlices/sidebarSlice";
import { getNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import settingsSvg from '../../static/settings.svg';
import { ReactSVG } from "react-svg";
import axios from "axios";
import "./navbar.css";
import SelectField from "../../components/selectField";
import Settings from "../settings/settings";
import OffcanvasSidebar from "../../components/offcanvas";
import CreateSidebar from "../../helpers/createSidebar";
import CreateScopes from "../../helpers/createScopes";
import { Navbar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import GetSerialsList from "../../actablueAPI/serials/GetSerialsList";
import GetLocationsList from "../../actablueAPI/locations/GetLocationsList";
import GetRegistration from "../../actablueAPI/registrations/GetRegistration";
import { clearReduxAdministration } from "../../redux/dataSlices/administrationSlice";
import { clearReduxLocation } from "../../redux/dataSlices/locationSlice";
import GetUserById from "../../actablueAPI/serials/GetUserByID";
import { getLoggedInUser, setLoggedInUser } from "../../redux/dataSlices/loggedInUserSlice";
import { getInvalidRegistrations } from "../../redux/dataSlices/registrationSlice";
import useHandleError from "../../customhooks/useHandleError";

const MainNavbar = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const locations_all = useSelector(getLocationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const role = useSelector(getRole);
  const navbartitle = useSelector(getNavBarTitle);
  const locationloading = useSelector(getLocationLoading);
  const administrationRegistrations = useSelector(getAdministrationRegistrations)
  const loggedInUser = useSelector(getLoggedInUser)
  const invalidRegistrations = useSelector(getInvalidRegistrations)
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const HandleError = useHandleError();
  const [settingsShow, setSettingsShow] = useState(false);
  const handleSettingsClose = () => setSettingsShow(false);
  const handleSettingsShow = () => setSettingsShow(true);

  const onLocationChange = (event) => {
    dispatch(clearReduxLocation())
    if (event) {
      dispatch(setSelectedLocationId(event.value));
      dispatch(setSelectedLocation(hash_locations_all[event.value]));
      setLocationScopeAndSidebar({token:token, admin_id:admin_id, location_id:event.value, role:role, userroles:loggedInUser?.roles})
    } 
    if (!event) {
      dispatch(setSelectedLocationId(false))
      dispatch(setSelectedLocation(false))
      dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, registrations:administrationRegistrations, role: role, admin_id:admin_id, userroles:loggedInUser?.roles })))
      dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, registrations:administrationRegistrations, role: role, admin_id:admin_id, userroles:loggedInUser?.roles })))
    }
  };

  const onAdminChange = (event) => {
    dispatch(clearReduxAdministration())
    dispatch(setLocationLoading(true))
    if (event) {
      dispatch(setSelectedAdminId(event.value));
      dispatch(setSelectedAdmin(hash_admins_all[event.value]));
      dispatch(setSelectedLocationId(false))
      dispatch(setSelectedLocation(false))
      setAdminScopeAndSidebar({token:token, admin_id:event.value, role:role, userroles:loggedInUser?.roles})
    }
    if (!event) {
      dispatch(setSelectedAdminId(false));
      dispatch(setSelectedAdmin(false));
      dispatch(setSelectedLocationId(false))
      dispatch(setSelectedLocation(false))
      dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, role: role })))
      dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, role: role })))
      dispatch(setLocationLoading(false))
    }
  };

  useEffect(() => {
    dispatch(setLocationLoading(true))

    if (role !== 'user') {
      axios.all([
        // get all admins
        GetSerialsList({token:token}),
        // get all locations
        GetLocationsList({token:token}),
      ])
      .then(axios.spread((administrationsResponse, locationsAllResponse) => {
        dispatch(setAdministrationsAll(administrationsResponse.data))
        dispatch(setLocationsAllAdmins(locationsAllResponse.data))

        if (!admin_id && !location_id) {
          dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, role: role })))
          dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, role: role })))
        }
        
        if (admin_id && !location_id) {
          setAdminScopeAndSidebar({token:token, admin_id:admin_id, role:role})
        }

        if (admin_id && location_id) {
          setAdminScopeLocationScopeAndSidebar({token:token, admin_id:admin_id, location_id:location_id, role:role})
        }

      }))
      .catch(error => {
        dispatch(setLocationLoading(false))
        HandleError({ error: error })
      })
    }

    if (role === 'user') {
      axios.all([
        // get user for admins
        GetUserById({token: token, id:token.payload.sub}),
        // get locations for this admin
        GetLocationsList({token:token, admin_id:admin_id})
      ])
      .then(axios.spread((userResponse, locationsResponse) => {
        dispatch(setLocationsAllAdmins(locationsResponse.data))
        dispatch(setLocationsAll(locationsResponse.data))
        dispatch(setLoggedInUser(userResponse.data))
        dispatch(setAdministrationsAll(userResponse.data.administrations))

        if (!admin_id && !location_id) {
          dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, role: role })))
          dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, role: role })))
        }
    
        if (admin_id) {
          dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, role: role, userroles:userResponse.data.roles, admin_id:admin_id, location_id:location_id })))
          dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, role: role, userroles:userResponse.data.roles, admin_id:admin_id, location_id:location_id })))
        }

        dispatch(setLocationLoading(false))
      }))
      .catch(error => {
        dispatch(setLocationLoading(false))
        HandleError({ error: error })
      })
    }
    // eslint-disable-next-line
  }, []);

  const location = {
    name: 'location',
    className: 'navbar-select',
    options: admin_id ? locations_all: undefined,
    selected: location_id ? hash_locations_all[location_id] : null,
    onChange: onLocationChange,
    clearable: Boolean(true),
    searchable: Boolean(true),
    placeholder: 'Select location...',
    loading: locationloading
  };

  const administration = {
    name: 'administration',
    className: 'navbar-select',
    options: admins_all,
    selected: hash_admins_all[admin_id],
    onChange: onAdminChange,
    clearable: Boolean(true),
    searchable: Boolean(true),
    placeholder: 'Select administration...',
  };

  const settingsProps = {
    offcanvas: Boolean(true)
  }

  const settingsOffcanvas = {
    title: 'Settings',
    data: <Settings {...settingsProps} />,
    show: settingsShow,
    placement: 'end',
    onHide: handleSettingsClose,
    className: 'navbar-offcanvas'
  }

  const SettingsIcon = () => {
    return (
      <div>
        <ReactSVG className='settings-svg' src={settingsSvg} />
      </div>
    );
  };

  function setAdminScopeAndSidebar({token, admin_id, role, userroles}) {
    axios.all([
      GetRegistration({token:token, admin_id:admin_id}),
      GetLocationsList({token:token, admin_id:admin_id})
    ])
    .then(axios.spread((registrationResponse, locationsResponse) => {
      dispatch(setSelectedAdminScopes(CreateScopes({token:token, dispatch:dispatch, registrations:registrationResponse.data, role:'merchant', admin_id:admin_id})))
      dispatch(setAdministrationRegistrations(registrationResponse.data))
      dispatch(setLocationsAll(locationsResponse.data))
      dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, registrations:registrationResponse.data, role: role, admin_id:admin_id, userroles:userroles })))
      dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, registrations:registrationResponse.data, role: role, admin_id:admin_id, userroles:userroles })))
    }))
    .catch(error => {
      HandleError({ error: error })
    })
    .finally(() => {
      dispatch(setLocationLoading(false))
    })
  }
  
  function setLocationScopeAndSidebar({token, admin_id, location_id, role, userroles}) {
    GetRegistration({token:token, admin_id:admin_id, location_id:location_id})
    .then(registrationResponse => {
      dispatch(setSelectedLocationScopes(CreateScopes({ token:token, dispatch:dispatch, registrations:registrationResponse.data, role:'merchant', admin_id:admin_id, location_id:location_id})))
      dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, registrations:registrationResponse.data, role:role, admin_id:admin_id, location_id:location_id, userroles:userroles })))
      dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, registrations:registrationResponse.data, role:role, admin_id:admin_id, location_id:location_id, userroles:userroles })))
    })
    .catch(error => {
      HandleError({ error: error })
    })
    .finally(() => {
      dispatch(setLocationLoading(false))
    })
  }

  function setAdminScopeLocationScopeAndSidebar({token, admin_id, location_id, role, userroles}) {
    axios.all([
      GetRegistration({token:token, admin_id:admin_id}),
      GetLocationsList({token:token, admin_id:admin_id}),
      GetRegistration({token:token, admin_id:admin_id, location_id:location_id})
    ])
    .then(axios.spread((adminRegistrationResponse, locationsResponse, locationRegistrationResponse) => {
      dispatch(setSelectedAdminScopes(CreateScopes({token:token, dispatch:dispatch, registrations:adminRegistrationResponse.data, role:'merchant', admin_id:admin_id})))
      dispatch(setAdministrationRegistrations(adminRegistrationResponse.data))
      dispatch(setLocationsAll(locationsResponse.data))
      dispatch(setSelectedLocationScopes(CreateScopes({token:token, dispatch:dispatch, registrations:locationRegistrationResponse.data, role:'merchant', admin_id:admin_id, location_id:location_id})))
      dispatch(setSidebar(CreateSidebar({ token:token, dispatch:dispatch, registrations:locationRegistrationResponse.data, role:role, admin_id:admin_id, location_id:location_id, userroles:userroles })))
      dispatch(setScopes(CreateScopes({ token:token, dispatch:dispatch, registrations:locationRegistrationResponse.data, role:role, admin_id:admin_id, location_id:location_id, userroles:userroles })))
    }))
    .catch(error => {
      HandleError({ error: error })
    })
    .finally(() => {
      dispatch(setLocationLoading(false))
    })
  }

  return (
    <>
      <Navbar>
        <h5 className="navbar-title-text">{translate(navbartitle)}</h5>
        <div className="navbar-selects">
          <ul>
            <li>
              <label htmlFor="navbar-selectadmin">
                {translate('Select Administration')}
              </label>
              <SelectField {...administration} />
            </li>
            <li>
              <label htmlFor="navbar-selectlocation">
                {translate('Select Location')}
              </label>
              <SelectField {...location} />
            </li>
          </ul>
          <button className="settings" onClick={handleSettingsShow}>
            <SettingsIcon />
          </button>
        </div>
      </Navbar>

      <OffcanvasSidebar {...settingsOffcanvas} />
    </>
  );
};

export default MainNavbar;

