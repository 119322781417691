import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';


export default function OpeningtimesTable(opening_times) {
  const { t: translate } = useTranslation();
  const days = {
    1: translate('Monday'),
    2: translate('Tuesday'),
    3: translate('Wednesday'),
    4: translate('Thursday'),
    5: translate('Friday'),
    6: translate('Saturday'),
    7: translate('Sunday')
  }

  // convert object to array for sorting
  let opening_times_array = Object.values(opening_times)
  let sorted = opening_times_array.sort((a, b) => a.day - b.day)

  let rows = []
  sorted.forEach(day => {
    rows.push(
      <tr key={day.day}>
        <td>{days[day.day]}</td>
        <td>{day.time_open}</td>
        <td>{day.time_closed}</td>
      </tr>
    )
  });

  if (opening_times) {
    return (
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>Day</th>
            <th>Open</th>
            <th>{translate('Close')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  } else {
    return (
      <Table width='100%'>
        <thead>
          <tr>
            <th>Day</th>
            <th>Open</th>
            <th>{translate('Close')}</th>
          </tr>
        </thead>
      </Table>
    );
  }
}