import axios from "axios";

const GetWarehouseProductSearch = ({ token, adminId, locationId, name = null, skuCode = null, ean = null, page = 0, size = null, signal = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/warehouse/products/search`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': adminId,
        'location': locationId,
        'name': name,
        'skuCode': skuCode,
        'ean': ean
      },
      params: {
        page: page,
        pagesize: size
      },
      signal: signal
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export default GetWarehouseProductSearch