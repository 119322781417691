import axios from "axios";

const GetReservationLocationList = ({token, admin_id}) => {

  return new Promise ((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/reservations/location/list`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
      params: {
        APP: 'portal',
        administration: admin_id
      },
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  })

};

export default GetReservationLocationList
