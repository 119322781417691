import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setVatsAll, getVatsAll, getHashVatsAll } from "../../redux/dataSlices/vatSlice";
import { getJournalCategoriesAll, getHashJournalCategoriesAll, getCategory, setCategory } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import CategoryForm from "../../components/categoryForm"
import { CategoryFormFields } from "./journalcategoryFormFields"
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleOnChange from "../../helpers/handleOnChange";
import GetAllVats from "../../helpers/getAllVats";
import PutCategory from "../../actablueAPI/products/PutCategory";
import useHandleError from "../../customhooks/useHandleError";

const CategoryEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const category = useSelector(getCategory)
  const vats = useSelector(getVatsAll)
  const hash_vats = useSelector(getHashVatsAll)
  const journalCategories = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HandleError = useHandleError();

  const onChange = (event) => {
    let newCategory = HandleOnChange({ event: event, object: category })
    dispatch(setCategory(newCategory))
  }

  const onVatChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: category, selectfield: 'vat', hash: hash_vats })
    dispatch(setCategory(newCategory))
  };

  const onParentChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: category, selectfield: 'parent', hash: hash_journal_categories })
    dispatch(setCategory(newCategory))
  };

  const onCancel = () => {navigate(-1)}

  const onSubmit = () => {
    PutCategory({token:token, admin_id:admin_id, category:category})
    .then(response => {
      navigate("/journalcategories");
    })
    .catch((error) => {
      HandleError({ error: error })
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    cancel: {
      title: 'Cancel',
      type: 'button',
      onClick: onCancel
    },
    fields: CategoryFormFields(),
    field: {
      onChange: onChange
    },
    data: category,
    vats: {
      label: 'vat',
      options: vats,
      onChange: onVatChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    parent: {
      label: 'parent',
      placeholder: 'parent',
      hash: hash_journal_categories,
      options: journalCategories,
      selected: {},
      onChange: onParentChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: "Category edit ".concat(category.title),
    data: <CategoryForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Category Edit"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
    .then(response => {
      dispatch(setVatsAll(response))
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError({ error: error })
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default CategoryEdit;