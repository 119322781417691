import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { setProgressModalShow } from "../../redux/dataSlices/reportingSlice";
import * as XLSX from 'xlsx/xlsx.mjs';
import { CreateSalesReportMultipleDaysExport } from "../../helpers/salesReportMultipleDaysExport";
import { SalesReportPDF } from "../../pdfcreators/salesReportPDF"
import { StatusReportPDF } from "../../pdfcreators/statusReportPDF";
import { getSelectedAdmin } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation } from "../../redux/dataSlices/selectLocationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";

export default function ReportingButtons() {
  const token = useSelector(getToken)
  const scopes = useSelector(getScopes)
  const admin = useSelector(getSelectedAdmin)
  const location = useSelector(getSelectedLocation)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onclickExport = async (props) => {
    const report = props.report
    const type = props.type
    const workbook = XLSX.utils.book_new()
    const categoryId = props.categoryId

    switch (type) {
      case 'statusReportSingleDay':
        break
      case 'statusReportMultipleDays':
        break
      case 'salesReportMultipleDays':
        dispatch(setProgressModalShow(true))
        let data = await CreateSalesReportMultipleDaysExport({report:report, token:token, admin:admin, categoryId:categoryId, dispatch:dispatch, navigate:navigate})
        XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(data), 'sheet1')
        XLSX.writeFile(workbook, "export.xlsx", { compression: true });
        dispatch(setProgressModalShow(false))
        break;
      default:
        break;
    }
  }

  const onclickPDF = async (props) => {
    const report = props.report
    const type = props.type
    const categoryId = props.categoryId

    switch (type) {
      case 'statusReportSingleDay':
        StatusReportPDF(report, admin, location)
        break
      case 'statusReportMultipleDays':
        StatusReportPDF(report, admin, location)
        break
      case 'salesReportMultipleDays':
        dispatch(setProgressModalShow(true))
        let data = await CreateSalesReportMultipleDaysExport({report:report, token:token, admin:admin, categoryId:categoryId, dispatch:dispatch, navigate:navigate})
        SalesReportPDF(report, data, admin, location)
        dispatch(setProgressModalShow(false))
        break;
      default:
        break;
    }
  }

  let buttons = {
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.reporting >= 0) {
    buttons.export = {
      tag: "Export",
      value: 'export',
      onClick: onclickExport,
      className: 'export-button'
    }
    buttons.pdf = {
      tag: "PDF",
      value: 'pdf',
      onClick: onclickPDF,
      className: 'pdf-button'
    }
  }

  return buttons
}

