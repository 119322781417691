import axios from "axios";

const GetShelflabelsSettings = ({token, admin_id, location_id}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/schapkaartjes/settings"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'admin_id': admin_id,
        'location_id': location_id
      }
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.error("error:", error)
      reject(error)
    });
  });
};

export default GetShelflabelsSettings