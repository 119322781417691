import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';
import { useSelector } from 'react-redux';
import { getEmployeeRolesAll } from '../redux/dataSlices/employeeSlice';
import { roles } from '../scopes/roles.mjs';


function EmployeeForm(props) {
  const { t: translate } = useTranslation();
  const employee_roles_all = useSelector(getEmployeeRolesAll)

  if (props?.data?.hasOwnProperty('roles')) {
    let convertedRoles;
    if (props.data.roles) {
      let newRoles = []
      for (const [role, value] of Object.entries(props.data.roles)) {
        if (roles.includes(role)) {
          newRoles.push(role.concat(':').concat(value))
        }
      }
      convertedRoles = newRoles
    }

    const foundRoles = employee_roles_all.filter(role => JSON.stringify(role.roles?.toSorted() || {}).toLowerCase() === JSON.stringify(convertedRoles?.toSorted()).toLowerCase())
    props.role.selected = { 'value': foundRoles?.[0]?.id, 'label': foundRoles?.[0]?.name }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.employeeFormFields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  // roles
  formTabs.push(
    <Nav.Item key='roles'>
      <Nav.Link eventKey='roles'>
        {translate('Roles')}
      </Nav.Link>
    </Nav.Item>
  );
  let scopeFields = []
  props.fields.roleFields.forEach(field => {
    scopeFields.push(CreateField(field, props, field.prefix))
  });
  formTabContent.push(
    <Tab.Pane eventKey='roles' key='role'>
      <div className='tab-container'>
        {scopeFields}
      </div>
    </Tab.Pane>
  );


  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
    </>
  );
}

export default EmployeeForm;