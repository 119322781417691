export default function HandleOnChangeArrayWithObjects(props) {
  const event = props.event
  const object = props.object || {}
  const selectfield = props.selectfield || props.event.id
  const action = props.action

  let newObject = JSON.parse(JSON.stringify(object))
  
  if (action === 'add') {
    newObject[selectfield].push(event)
    return newObject
  }

  // clear newobject selectfield
  newObject[selectfield] = []

  // check for id's that stay or must be deleted
  if (object.hasOwnProperty(selectfield)) {
    // eslint-disable-next-line
    object[selectfield]?.map((selected) => {
      if (selected.id !== event.id) {
        newObject[selectfield].push(selected)
      }
    })
  }
  
  return (newObject)
}