import axios from "axios";

const GetProductSupplierList = ({ token, admin_id, location_id, page, size }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/supplier/list`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        page: page,
        size: size,
        location: location_id
      }
    })
      .then(response => {resolve(response)})
      .catch(error => {reject(error)})
  });
};

export default GetProductSupplierList