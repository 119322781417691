import { useEffect, useRef, useState } from "react"
import ProgressModal from "../../components/progressModal";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setStocks, getStocks, getStockPagination, getStockPagesize, getStockProductFilter, getStockProductSearch, setStockProductFilter, setStockProductSearch, setStockPagination, setStockPageSize, getStockRefresh } from "../../redux/dataSlices/warehouseSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getProgressModalShow, setProgressModalShow } from "../../redux/dataSlices/analyseSlice";
import BasicCard from "../../components/card"
import StockTable from "../../components/stockTable";
import useHandleError from "../../customhooks/useHandleError";
import GetWarehouseProductSearch from "../../actablueAPI/warehouse/GetWarehouseProductSearch";
import { searchChoices } from "../../selectfieldchoices/warehouseselect.mjs";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import GetWarehouseProductPaginated from "../../actablueAPI/warehouse/GetWarehouseProductPaginated";

const Stock = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const stocks = useSelector(getStocks)
  const stock_pagination = useSelector(getStockPagination)
  const stock_pagesize = useSelector(getStockPagesize)
  const modal_show = useSelector(getProgressModalShow)
  const search = useSelector(getStockProductSearch)
  const filter = useSelector(getStockProductFilter) || "name"
  const refresh = useSelector(getStockRefresh);
  const [controller, setController] = useState();
  const dispatch = useDispatch();
  const HandleError = useHandleError();
  const signal = useRef();
  const navTitle = "Stock";

  const stopAxios = async () => {
    if (controller) {
      controller.abort();
    }
  };

  const card = {
    size: 12,
    title: navTitle,
    data: <StockTable {...stocks} />,
  };

  const onViewHide = () => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Creating and downloading export.....',
      onHide: onViewHide
    }
  }

  const onSearchChange = (event) => {
    dispatch(setStockProductSearch(event.target.value))
    dispatch(setStockPagination(0))
  }

  const onSearchKeyDown = (key) => {
    if (key.keyCode === 13) {
      dispatch(setStockPagination(0))
      getStock();
    }
  }

  const onSearchClick = () => {
    dispatch(setStockPagination(0))
    getStock();
  }

  const onFilterChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    dispatch(setStockProductFilter(value))
  }

  const getStock = () => {
    stopAxios();
    dispatch(setLoading(true))
    let newController = new AbortController();
    setController(newController);
    signal.current = newController?.signal;
    let searchObject = { token: token, adminId: admin_id, locationId: location_id, page: stock_pagination, size: stock_pagesize, signal: signal?.current }
    if (search) {
      searchObject[filter] = search
      GetWarehouseProductSearch(searchObject)
        .then((searchResponse) => {
          dispatch(setStocks(searchResponse.data))
          dispatch(setLoading(false))
        })
        .catch(error => { if (error?.code !== "ERR_CANCELED") HandleError({ error: error }) })
    }
    if (!search || search === '') {
      GetWarehouseProductPaginated({ token: token, admin_id: admin_id, location_id: location_id, page: stock_pagination, size: stock_pagesize })
        .then((stockResponse) => { dispatch(setStocks(stockResponse.data)) })
        .catch(error => { HandleError({ error: error }) })
        .finally(() => { dispatch(setLoading(false)) })
    }
  }

  const searchAndFilter = {
    style: {
      marginTop: 32
    },
    search: {
      value: search,
      onChange: onSearchChange,
      onClick: onSearchClick,
      onKeyDown: onSearchKeyDown,
      showButton: true
    },
    filters: [
      {
        label: 'filter_label_search_as',
        options: searchChoices,
        onChange: onFilterChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    dispatch(setStockProductSearch(null))
    dispatch(setStockPagination(1))
    dispatch(setStockPageSize(10))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getStock();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (admin_id) {
      dispatch(setLoading(true))
      getStock()
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id, stock_pagination, stock_pagesize, refresh]);

  return (
    <>
      <ProgressModal {...progressModal} />
      {location_id && <SearchAndMultiFilterSingleSelect {...searchAndFilter} />}
      <BasicCard {...card} />
    </>
  );
};
export default Stock;
