import { allScopes, resellerScopes } from "../scopes/scopes.mjs"
import CreateAdminRegistrationHashBasedOnAdminId from "./createAdminRegistrationHashBasedOnLocationId"
import CreateLocationRegistrationHashBasedOnAdminId from "./createLocationRegistrationHashBasedOnAdminId"
import RegistrationIsValid from "./RegistrationIsValid"

export default function CreateScopes(props) {
  const role = props.role
  const admin_id = props.admin_id
  const location_id = props.location_id
  const registrations = props.registrations
  const userroles = props.userroles
  const token = props.token
  const dispatch = props.dispatch

  let scopes = {'NumberOfDevices': 0}

  // CheckSubscriptsEnds(registrations)

  switch (role) {
    case 'super':
      _createScopes({registrationScopes:allScopes, scopes:scopes})
      break;
    case 'admin':
      _createScopes({registrationScopes:allScopes, scopes:scopes})
      break;
    case 'reseller':
      _createScopes({registrationScopes:resellerScopes, scopes:scopes})
      break;
    case 'merchant':
      if (registrations) {
        let registrationToUse = undefined
        if (!location_id) {
          const hashRegistrationsBasedOnAdminId = CreateAdminRegistrationHashBasedOnAdminId(registrations)
          if (hashRegistrationsBasedOnAdminId && hashRegistrationsBasedOnAdminId.hasOwnProperty(admin_id)) {
            registrationToUse = hashRegistrationsBasedOnAdminId[admin_id]
          }
        }
        
        if (location_id) {
          const hashRegistrationsBasedOnLocationId = CreateLocationRegistrationHashBasedOnAdminId(registrations)
          if (hashRegistrationsBasedOnLocationId && hashRegistrationsBasedOnLocationId.hasOwnProperty(location_id)) {
            registrationToUse = hashRegistrationsBasedOnLocationId[location_id]
          }
        }
  
        if (registrationToUse) {
          for (const registration of registrationToUse.registrations) {
            if (registration.hasOwnProperty('subscription') && registration.subscription.hasOwnProperty('scopes') && RegistrationIsValid({token:token, setting:registration, dispatch:dispatch})) {
              _createScopes({registrationScopes:registration.subscription.scopes, scopes:scopes})
            }
          }
        }

        if (!registrationToUse) {
          scopes['subscribe'] = 1  
        }
      }

      if (!registrations) {
        scopes['subscribe'] = 1
      }
      break;
    case 'user':
      if (userroles) {
        _createUserScopes({scopes:scopes, userroles:userroles, admin_id:admin_id, location_id:location_id})
      }
      break;
    default:
      break;
  }
  return scopes
}

function _createScopes(props) {
  const scopeOptions = ['read', 'create', 'update', 'delete']
  const registrationScopes = props.registrationScopes
  let scopes = props.scopes

  for (const scope of registrationScopes.values()) {
    const splitted = scope.split(':')
    const name = splitted.at(-2)
    const value = splitted.at(-1)
    let index = scopeOptions.findIndex((element) => element === value)
    _addToScope({scopes:scopes, name:name, value:value, index:index})
  }
  return 
}

function _createUserScopes(props) {
  const admin_id = props.admin_id
  const location_id = props.location_id
  const userroles = props.userroles
  const scopeOptions = ['read', 'create', 'update', 'delete']
  let scopes = props.scopes

  for (const role of userroles) {
    const splitted = role.split(':')
    const type = splitted.at(0)
    const roleAdminId = splitted.at(1)
    const roleLocationId = splitted.at(2)
    const name = splitted.at(-2)
    const value = splitted.at(-1)
    let index = scopeOptions.findIndex((element) => element === value)

    if (type === 'portal' && roleAdminId === admin_id) {
      if (location_id && roleLocationId === location_id) {
        _addToScope({scopes:scopes, name:name, value:value, index:index})
      }
      // if (!location_id) {
      //   _addToScope({scopes:scopes, name:name, value:value, index:index})
      // }
    }
  }
  return
}

function _addToScope(props) {
  const name = props.name
  const value = props.value
  const index = props.index
  let scopes = props.scopes

  if (name === 'NumberOfDevices') {
    if (scopes['NumberOfDevices']) {
      scopes['NumberOfDevices'] += Number(value)
    }
    if (!scopes['NumberOfDevices']) {
      scopes['NumberOfDevices'] = Number(value)
    }
  }
    
  if (name !== 'NumberOfDevices') {
    if (scopes[name] && index > scopes[name]) {
      scopes[name] = index
    }
    if (!scopes[name]) {
      scopes[name] = index
    }
  }
}