import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import WarehouseButtons from '../portal/stock/warehousebuttons';
import WarehouseModal from './warehouseModal';
import { useTranslation } from 'react-i18next';

export default function WarehouseTable(warehouses) {
  const buttons = WarehouseButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const sync = buttons.hasOwnProperty('sync') ? Object.assign({}, buttons.sync) : false
  const resetToZero = buttons.hasOwnProperty('resettozero') ? Object.assign({}, buttons.resettozero) : false
  const { t: translate } = useTranslation();

  let warehouseRows = []
  for (let warehouse of Object.values(warehouses)) {
    warehouseRows.push(
      <tr className='product-row' key={warehouse.id}>
        <td>{warehouse.name}</td>
        <td>
          <p>
            {translate('Created')}: {new Date(warehouse.creation).toLocaleString()}
          </p>
          <p>
            {translate('Modified')}: {new Date(warehouse.modification).toLocaleString()}
          </p>
        </td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(warehouse) }}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(warehouse) }}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {sync && <TableButton {...sync} />}{resetToZero && <TableButton {...resetToZero} />}{add && <TableButton {...add} />}
      <Table width='100%'>
        <thead key='header'>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th>
              {translate('Date')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {warehouseRows}
        </tbody>
        <WarehouseModal modal={modalview.modal} />
      </Table>
    </>
  );
}