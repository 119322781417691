export const statusReportMultipleDaysHighlights = (report, startDate) => {
  let newTotalCash = 0
  let newTotalEft = 0
  let newTotalOnAccount = 0
  let newTotalExchange = 0
  let newTotalTotal = 0
  let newTotalNettoCash = 0
  let newTotalWebshop = 0
  let newOGs = []
  let journalsTotal = 0
  let newTaxs = []
  let taxTotal = 0

  if (report?.days) {
    for (let day in report.days) {
      const totals = report.days[day].totals
      const journals = report.days[day].journals
      const taxs = report.days[day].taxs

      // for highlights
      newTotalCash = newTotalCash + totals.cash
      newTotalEft = newTotalEft + totals.eft
      newTotalOnAccount += totals.on_account
      newTotalExchange += totals.exchange
      newTotalTotal += totals.total
      newTotalWebshop += totals.webshop

      // ogs for highlights
      if (journals) {
        for (const [journalId, journalObject] of Object.entries(journals)) {
          if (journalId !== 'total' && !newOGs.hasOwnProperty(journalId)) {
            newOGs[journalId] = { name: journalObject.name, total: 0 }
          }
          if (journalId !== 'total' && newOGs.hasOwnProperty(journalId)) {
            newOGs[journalId]['total'] += journalObject.total
            journalsTotal += journalObject.total
          }
        }
      }

      // taxs for highlights
      if (taxs) {
        for (const [name, value] of Object.entries(taxs)) {
          if (name !== 'total' && !newTaxs.hasOwnProperty(name)) {
            newTaxs[name] = 0
          }
          if (name !== 'total' && newTaxs.hasOwnProperty(name)) {
            newTaxs[name] += value
            taxTotal += value
          }
        }
      }
    }
    newOGs.total = journalsTotal
    newTaxs.total = taxTotal
  }

  newTotalNettoCash = newTotalCash - newTotalExchange

  let newReportHighlights = [
    { Total: { amount: Number(newTotalTotal).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) } },
    { Cash: { amount: Number(newTotalNettoCash).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) } },
    { EFT: { amount: Number(newTotalEft).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) } },
    { Webshop: { amount: Number(newTotalWebshop).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) } },
    { OnAccount: { amount: Number(newTotalOnAccount).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 }) } },
    { Transactions: { amount: Number(report.transactions) } },
  ]

  report.calculatedTotals = {
    transactions: report.transactions,
    payments: { cash: newTotalCash, eft: newTotalEft, onAccount: newTotalOnAccount, exchange: newTotalExchange, webshop: newTotalWebshop, total: newTotalTotal },
    journals: newOGs,
    taxs: newTaxs
  }

  return newReportHighlights
};