import { useSelector } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

const ReservationLocationFormFields = () => {
  const scopes = useSelector(getScopes);

  if (!scopes.reservations) return {}

  let reservations = [
    { type: 'select', name: `Application`, id: 'application', formdatafield: 'application', required: false },
    { type: 'number', name: `Capacity`, id: 'capacity', required: false },
    { type: 'number', name: `Duration`, id: 'duration', required: false },
    { type: 'number', name: `Walk-in-time`, id: 'walk_in_time', required: false },
    { type: 'number', name: `Walk-out-time`, id: 'walk_out_time', required: false },
    { type: 'text', name: 'Timezone', id: 'timezone', required: false },
    { type: 'checkbox', name: 'Eco', id: 'eco', required: false },
    { type: 'checkbox', name: `Can reserve events`, id: 'can_reserve_events', required: false },
    { type: 'checkbox', name: `Automatically accept reservations`, id: 'automatically_accept_reservations', required: false },
    { type: 'checkbox', name: `Automatically assign tables`, id: 'automatically_assign_tables', required: false },
    { type: 'checkbox', name: `Precise automatic reservations`, id: 'precise_automatic_reservations', required: false },
    { type: 'checkbox', name: `Active`, id: 'is_active', required: false },
    { type: 'multi+create', name: 'Image URLs', id: 'img_urls', formdatafield: 'reservation_img_urls', required: false },
    { type: 'multi+create', name: `Areas`, id: 'areas', formdatafield: 'areas', required: false },
  ]

  return reservations
}

export default ReservationLocationFormFields