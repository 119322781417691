
import { Modal, Col, Row, Button, ListGroup } from 'react-bootstrap';

import './css/modal.css'
import CreateFieldTemplate from '../helpers/createFieldTemplate';
import { useSelector } from 'react-redux';
import { getSelectedProductCategories } from '../redux/dataSlices/categorySlice';
import { visibleChoices } from '../selectfieldchoices/productselect.mjs';


export default function CategoryMultiEditModal(props) {
  const toggleModal = props?.toggleModal
  const onSaveClick = props?.onSaveClick
  const selectedCategories = useSelector(getSelectedProductCategories)
  const categoryKeys = Object.keys(selectedCategories);

  if (props.data?.hasOwnProperty('is_visible')) {
    if (props.data.is_visible !== null) props.is_visible.selected = { 'value': props.data.is_visible, 'label': visibleChoices.find(element => element.id === props.data.is_visible)?.name }
    if (props.data.is_visible === null) props.is_visible.selected = []
  }

  let basicFields = []
  props.fields.forEach((field) => {
    basicFields.push(CreateFieldTemplate(field, props))
  });

  return (
    <Modal
      show={props?.show}
      size="xl"
      contentClassName='w-100 h-100'
      className='product-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className="product-modal-head" >
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='cst-modal-body'>
        <Row>
          <Col md={3}>
            <h6>Selected categories</h6>
            <ListGroup>
              {categoryKeys.map(key => (
                <ListGroup.Item key={key}>
                  {selectedCategories[key]?.title}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={'auto'}>
            <div className="vl"></div>
          </Col>
          <Col md={6}>
            <Row key={`fields-row`}>
              {basicFields}
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleModal}>Close</Button>
        <Button variant="primary" onClick={onSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

}