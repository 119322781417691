import Table from 'react-bootstrap/Table';

export default function TransactionTotalsTable(props) {
  if (props.transaction) {
    return (
      <Table hover width='100%'>
        <thead>
          <tr>
            <th width='20%'></th>
            <th width='60%'></th>
            <th width='20%'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>Amount open:</td>
            <td>{Number(props.transaction.amount_open).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
          <tr>
            <td></td>
            <td>Amount paid:</td>
            <td>{Number(props.transaction.amount_paid).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
          <tr>
            <td></td>
            <td>Exchange:</td>
            <td>{Number(props.transaction.amount_exchange).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
}