import { useSelector } from 'react-redux';
import { getTemplateElementModalShow } from '../redux/dataSlices/templateSlice';
import TemplateElementForm from './templateElementForm';
import OffcanvasSidebar from './offcanvas';

export default function TemplateElementOffcanvas(props) {
  const modalShow = useSelector(getTemplateElementModalShow)

  if (props?.data && Object.keys(props?.data).length > 0) {
    const element = props?.data?.elements[props?.elements?.selectedIndex] ?? null

    const templateElementOffcanvas = {
      title: `Element: ${element?.name ?? 'Unknown Element'}`,
      data: <TemplateElementForm {...props} />,
      show: modalShow,
      placement: 'start',
      onHide: props?.elements?.onModalHide,
      className: 'template-element-offcanvas'
    }

    return (
      <OffcanvasSidebar {...templateElementOffcanvas} />
    );
  }
}