import React from "react";
import { Page, Document, Text, View, Image } from "@react-pdf/renderer";
import { styles } from "../pdfstylesheet/stylesTransactionPDF"

import QRCode from 'qrcode';


export const calculateVatLines = (lines) => {
  let calculatedVatLines = []
  if (lines) {
    for (const line of Object.values(lines)) {
      const vatPercentage = String(line.vat_percentage)
      const inclVat = Number(line.amount_inclusive_vat)
      const exclVat = line.amount_exclusive_vat ? Number(line.amount_exclusive_vat) : line.vat_percentage ? Number(line.amount_inclusive_vat / (100 + line.vat_percentage) * 100) : Number(line.amount_inclusive_vat)
      if (!calculatedVatLines[String(vatPercentage)]) {
        calculatedVatLines[String(vatPercentage)] = { '%': vatPercentage, inclVat: 0, exclVat: 0, vat: 0 }
      }
      const addedInclVat = calculatedVatLines[String(vatPercentage)].inclVat + inclVat
      const addedExclVat = calculatedVatLines[String(vatPercentage)].exclVat + exclVat
      const addedVAT = addedInclVat - addedExclVat
      calculatedVatLines[String(vatPercentage)] = { '%': vatPercentage, inclVat: addedInclVat, exclVat: addedExclVat, vat: addedVAT }
      if (line.attributes) {
        for (const attribute of Object.values(line.attributes)) {
          const vatPercentage = String(attribute.vat_percentage)
          const inclVat = Number(attribute.amount_inclusive_vat)
          const exclVat = attribute.amount_exclusive_vat ? Number(attribute.amount_exclusive_vat) : attribute.vat_percentage ? Number(attribute.amount_inclusive_vat / (100 + attribute.vat_percentage) * 100) : Number(attribute.amount_inclusive_vat)
          if (!calculatedVatLines[String(vatPercentage)]) {
            calculatedVatLines[String(vatPercentage)] = { '%': vatPercentage, inclVat: 0, exclVat: 0, vat: 0 }
          }
          const addedInclVat = calculatedVatLines[String(vatPercentage)].inclVat + inclVat
          const addedExclVat = calculatedVatLines[String(vatPercentage)].exclVat + exclVat
          const addedVAT = addedInclVat - addedExclVat
          calculatedVatLines[String(vatPercentage)] = { '%': vatPercentage, inclVat: addedInclVat, exclVat: addedExclVat, vat: addedVAT }
        }
      }
    }
  }

  return calculatedVatLines
}

export const eftReceiptLines = (receipt) => {
  let lines = []
  if (receipt.lines) {
    for (const [index, line] of receipt.lines) {
      try {
        let jsonObject = JSON.parse(line.replace('event: session.orderdata: ', ''))
        lines.push('MSP payment result:')
        for (const [key, value] of Object.entries(jsonObject)) {
          lines.push(`${key}: ${value}`)
        }
      }
      catch (error) {
        if (index === 0) {lines.push('CCV payment result:')}
        lines.push(line)
      }
    }
  }

  return lines
}

export const generateQRCode = async data => {
  try {
    return await QRCode.toDataURL(data)
  } catch (err) {
    console.error(err)
  }
}

const Transaction = ({ transaction, hash_locations, relation }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} key="header">

          <View style={styles.locationNaw} key='location-naw'>
            <Text>{hash_locations[transaction?.location_id]?.name}</Text>
            <Text>{hash_locations[transaction?.location_id]?.address?.street} {hash_locations[transaction?.location_id]?.address?.street_number}{hash_locations[transaction?.location_id]?.address?.street_number_additional}</Text>
            <Text>{hash_locations[transaction?.location_id]?.address?.zip_code}, {hash_locations[transaction?.location_id]?.address?.place}</Text>
            {hash_locations[transaction?.location_id]?.phonenumbers && hash_locations[transaction?.location_id]?.phonenumbers?.find(phonenumber => phonenumber.type === "facturatie")?.number &&
              <Text>T: {hash_locations[transaction?.location_id]?.phonenumbers?.find(phonenumber => phonenumber.type === "facturatie")?.number}</Text>
            }
            {hash_locations[transaction?.location_id]?.emails && hash_locations[transaction?.location_id]?.emails?.find(email => email.type === "facturatie")?.email &&
              <Text>E: {hash_locations[transaction?.location_id]?.emails?.find(email => email.type === "facturatie")?.email}</Text>
            }
            {hash_locations[transaction?.location_id]?.webshop_url &&
              <Text>W: {hash_locations[transaction?.location_id]?.webshop_url}</Text>
            }
          </View>

          <View style={styles.relationNaw} key='relation-naw'>
            {relation &&
              <>
                <Text>{relation?.name}</Text>
                {relation?.addresses && relation?.addresses.length > 0 &&
                  <>
                    <Text>{relation?.addresses[0]?.street} {relation?.addresses[0]?.street_number}{relation?.addresses[0]?.street_number_additional}</Text>
                    <Text>{relation?.addresses[0]?.zip_code}, {relation?.addresses[0]?.place}</Text>
                  </>
                }
                {relation?.phone_numbers && relation?.phone_numbers?.find(phonenumber => phonenumber.type === "facturatie")?.number &&
                  <Text>T: {relation?.phone_numbers?.find(phonenumber => phonenumber.type === "facturatie")?.number}</Text>
                }
                {relation?.emails && relation?.emails?.find(email => email.type === "facturatie")?.email &&
                  <Text>E: {relation?.emails?.find(email => email.type === "facturatie")?.email}</Text>
                }
              </>
            }
          </View>

        </View>

        <Text style={styles.invoiceTitle}>Transaction</Text>
        <Text style={styles.invoiceInfo}>Invoice #{transaction?.invoice?.number}</Text>
        <Text style={styles.invoiceInfo}>Date: {new Date(transaction?.invoice?.creation).toLocaleString()}</Text>

        <View style={styles.table} key='invoice-table'>
          <View style={styles.tableRow} key='invoice-table-row'>
            <Text style={styles.tableHeaderAmount}>Amount</Text>
            <Text style={styles.tableHeader}>Item</Text>
            <Text style={styles.tableHeaderPrice}>Price</Text>
          </View>

          {transaction.invoice?.invoice_lines?.map((invoiceLine, index) => (
            <View style={styles.tableRow} key={`table-row-${index}`}>
              <Text style={styles.tableCellAmount}>{invoiceLine?.quantity}</Text>
              <Text style={styles.tableCell}>{invoiceLine?.product_name}</Text>
              <Text style={styles.tableCellPrice}>{Number(invoiceLine.amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
            </View>
          ))}
        </View>
        <View style={styles.tableRow} key='table-row-total'>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCellPrice}>TOTAL:</Text>
          <Text style={styles.totalsPrice}>{Number(transaction.invoice?.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
        </View>

        <Text style={styles.invoiceTitle}>VAT Total</Text>

        <View style={styles.table} key='invoice-table'>
          <View style={styles.tableRow} key='invoice-table-row'>
            <Text style={styles.tableHeaderCentered}>%</Text>
            <Text style={styles.tableHeaderPrice}>Incl. VAT</Text>
            <Text style={styles.tableHeaderPrice}>Excl. VAT</Text>
            <Text style={styles.tableHeaderPrice}>VAT</Text>
          </View>

          {Object.values(calculateVatLines(transaction.invoice?.invoice_lines))?.map((line) => (
            <View style={styles.tableRow} key='table-row-vats'>
              <Text style={styles.tableCellCentered}>{line['%']}</Text>
              <Text style={styles.tableCellPrice}>{Number(line.inclVat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
              <Text style={styles.tableCellPrice}>{Number(line.exclVat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
              <Text style={styles.tableCellPrice}>{Number(line.vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
            </View>
          ))}
        </View>

        <Text style={styles.invoiceTitle}>Payments</Text>

        <View style={styles.table} key='invoice-table'>
          <View style={styles.tableRow} key='invoice-table-row'>
            <Text style={styles.tableHeaderPrice}>Type</Text>
            <Text style={styles.tableHeaderPrice}>Amount Paid</Text>
          </View>

          {transaction?.payments?.map((payment, index) => (
            <View style={styles.tableRow} key={`table-row-${index}`}>
              <Text style={styles.tableCellPrice}>{payment?.name}</Text>
              <Text style={styles.tableCellPrice}>{Number(payment?.amount).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
            </View>
          ))}
        </View>
        <View style={styles.tableRow} key='table-row-total'>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCellPrice}>AMOUNT PAID:</Text>
          <Text style={styles.totalsPrice}>{Number(transaction?.amount_paid).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
        </View>
        <View style={styles.tableRow} key='table-row-total'>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCellPrice}>TOTAL:</Text>
          <Text style={styles.totalsPrice}>{Number(transaction.invoice?.payment_amount_inclusive_vat).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
        </View>
        <View style={styles.tableRow} key='table-row-total'>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCell}></Text>
          <Text style={styles.tableCellPrice}>EXCHANGE:</Text>
          <Text style={styles.totalsPrice}>{Number(transaction?.amount_exchange).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Text>
        </View>
        
        <Text style={styles.invoiceTitle}>EFT ticket(s)</Text>
        {transaction.invoice?.receipts?.map((receipt, index) => (
          eftReceiptLines(receipt).map((receiptline) => (
            <Text style={styles.eftReceiptLine}>{receiptline}</Text>
          ))
        ))}

        <View style={styles.qrCodeContainer} key='table-row-total'>
          <Image src={generateQRCode(transaction.id)} />
        </View>
      </Page>
    </Document>
  );
}

export default Transaction;