import axios from "axios";

const GetInvoicePaymentsEJournal = ({token, admin_id, location_id, page = 0, size = 25}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/invoicepayments/ejournal`,
      headers: {'Authorization': `Bearer ${token['access_token']}`,
                'Content-Type':'application/json',
                'administrationId': admin_id
      },
      params: {
        'locationId': location_id,
        page: page,
        size: size
      }
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default GetInvoicePaymentsEJournal