import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getMessage, setMessage } from '../redux/dataSlices/messageSlice';
import { getNavBarTitle } from '../redux/dataSlices/navbartitleSlice';

import { Toast } from 'react-bootstrap';
import './css/toast.css';
import { useTranslation } from 'react-i18next';

export default function MessageToast() {
  const title = useSelector(getNavBarTitle);
  const message = useSelector(getMessage);
  const dispatch = useDispatch();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (message) {
      setIsTransitioning(true);
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const onViewHide = () => {
    setIsTransitioning(false);
    dispatch(setMessage(false))
  };

  if (message) {
    let newTitle = title
    if (typeof title === 'string') {
      newTitle = translate(title)
    }
    if (typeof title === 'object') {
      newTitle = `${translate(title.text)} (${translate(title.option)})`
    }
  
    let newMessage = message
    if (typeof message === 'string') {
      newMessage = translate(message)
    }
    if (typeof message === 'object') {
      newMessage = `${translate(message.text)} (${translate(message.option)})`
    }

    return (
        <Toast
          // bg='success'
          onClose={onViewHide}
          key="message-modal"
          delay={5000}
          autohide
          className={'transition-div' + (isTransitioning ? ' transition-in' : ' transition-out')}
          animation={true}
        >
          <Toast.Header>
            <strong className="me-auto"><i className="bi bi-check-square-fill"></i> {newTitle}</strong>
          </Toast.Header>
          <Toast.Body>
            {newMessage}
          </Toast.Body>
        </Toast>
    );
  }

  return null;
}