import axios from "axios";
import { setTaskData } from "../../redux/dataSlices/analyseSlice";

const GetWarehouseProductsPurchaseAdviceAll = ({ token, admin_id, location_id, supplier_id, relation_id, page = 0, size = 25, payload = [], resolver = null, dispatch }) => {

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/warehouse/products/purchaseadvicelist`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id,
        'location': location_id,
        'supplier': supplier_id,
        'relation': relation_id
      },
      params: {
        page: page,
        pagesize: size
      },
    })
      .then(response => {
        let task_data = {
          title: `Getting purchase advice by pages of ${size}`,
          current: page + 1,
          total: response?.data?.total_pages ?? 0,
          progress: (page + 1) === 0 ? 0 : (page + 1) / response?.data?.total_pages
        };

        dispatch(setTaskData(task_data))

        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }

        if (response.data.last !== true) {
          const nextPage = response.data.number + 1
          GetWarehouseProductsPurchaseAdviceAll({
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            supplier_id: supplier_id,
            relation_id: relation_id,
            page: nextPage,
            size: size,
            payload: updatedPayload,
            resolver: resolver || resolve,
            dispatch: dispatch
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => {
        reject(error)
      });
  });
};

export default GetWarehouseProductsPurchaseAdviceAll