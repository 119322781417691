import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getCountModalShow, getCountPageSize, setCount, setCountId, setCountModalShow, setCountPageSize, setCountPagination } from "../../redux/dataSlices/cashcountSlice";

import { pagesize } from "../../selectfieldchoices/pagesize.mjs";



export default function CountButtons() {
  const modalShow = useSelector(getCountModalShow)
  const pageSize = useSelector(getCountPageSize)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(count) {
    dispatch(setCount(count));
    dispatch(setCountModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setCountModalShow(false));
  };

  const onClickEdit = (event) => {
    dispatch(setCountId(event.id));
    navigate("/count/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setCountPagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setCountPageSize(event.value))
  }

  const onClickAdd = () => {
    navigate("/count/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.cashcount >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onClickAdd,
      className: 'add-button'
    }
  }
  if (scopes.cashcount >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onClickEdit
    }
  }

  return buttons
}

