import axios from "axios";

const PostRegistrationByIdSubscriptionSettingsById = ({token, registration_id, settings_id, data}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/registrations/registration/${registration_id}/subscriptions/settings/${settings_id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      data: data
    })
    .then(response => {resolve(response)})
    .catch(error => {reject(error)})
  });
};

export default PostRegistrationByIdSubscriptionSettingsById