/* eslint-disable array-callback-return */
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav, Table, Col, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import CreateFieldTemplate from '../helpers/createFieldTemplate';

export default function CountForm(props) {
  const { t: translate } = useTranslation();
  let previous_day_amount = 0;
  let reporting_total = 0

  if (props?.selectedDrawer?.previous_count) previous_day_amount = props?.selectedDrawer?.previous_count?.next_day_amount
  if (props?.reportingData?.total) reporting_total = (props?.reportingData?.cash - props?.reportingData?.exchange)

  if (props.selectedDrawer) {
    if (props.selectedDrawer.count_as !== 'Employee') props.fields.find(field => field.name === 'Employee').type = 'hidden'
    if (props.selectedDrawer.count_as === 'Employee') props.fields.find(field => field.name === 'Employee').type = 'select'
  }

  if (props.hasOwnProperty('drawer_id') && props.drawers && !props.drawers.items) {
    props?.drawers?.forEach(drawer => {
      if (props.data.drawer_id === drawer.id) {
        props.drawer_id.selected = { 'value': drawer.id, 'label': drawer.name }
      }
      props.drawer_id.options.push({ 'id': drawer.id, 'name': drawer.name })
    });
  }

  if (props.hasOwnProperty('employee_id') && props.employees) {
    props?.employees?.forEach(employee => {
      if (props.data.employee_id === employee.id) {
        props.employee_id.selected = { 'value': employee.id, 'label': employee.name }
      }
      props.employee_id.options.push({ 'id': employee.id, 'name': employee.name })
    });
  }

  let fields = [];
  let basicFields = [];
  let basicFields2 = [];
  let formTabs = [];
  let formTabContent = [];
  if (props.allowedCurrency) {

    props?.allowedCurrency?.allowed_currency?.forEach(currency => {

      fields?.push(
        <tr key={currency}>
          <td>
            <Form.Label>{Number(currency).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Form.Label>
          </td>
          <td>
            <Form.Control type={'number'}
              id={`${currency}-number`}
              role={'total'}
              name={currency}
              value={props?.data?.counted?.currency[currency]?.count ?? '0'}
              onChange={props?.field?.onChange}
              required={true}
            />
          </td>
          <td>
            <Form.Control type={'number'}
              id={`${currency}-total`}
              role={'value'}
              name={currency}
              value={props?.data?.counted?.currency[currency]?.total ?? '0'}
              onChange={props?.field?.onChange}
              required={true}
            />
          </td>
        </tr>
      )
    });
  }

  props?.fields?.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateFieldTemplate(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    }
    else {
      basicFields.push(CreateFieldTemplate(field, props))
    }
  });

  props?.fields2?.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateFieldTemplate(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    }
    else {
      basicFields2.push(CreateFieldTemplate(field, props))
    }
  });
  return (
    <>
      <Form onSubmit={props.submit.onClick}>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                <Row>
                  <Col md={8}>
                    <Row key={`fields-row`} className='h-100 align-items-center'>
                      {basicFields}
                    </Row>
                  </Col>
                  <Col md={'auto'}>
                    <div className="vl"></div>
                  </Col>
                  <Col md={3}>
                    <h5>{translate('Reporting Data')}</h5>
                    {props.selectedDrawer ?
                      !props.reportingLoading ?
                        props.selectedDrawer.previous_count ?
                          <>
                            {!props.reportingData && translate('No reporting data available.')}
                            <Table width='100%'>
                              <tbody>
                                {props.reportingData && Object.entries(props.reportingData).map(([key, value]) => {
                                  if (key !== 'timestamp') {
                                    return (
                                      <tr key={key}>
                                        <td width={'40%'}><b>{key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")}:</b></td>
                                        <td>{Number(value).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                      </tr>
                                    )
                                  }
                                })}
                                <tr key={'previous_amount'}>
                                  <td width={'40%'}><b>{translate('Previous drawer amount')}:</b></td>
                                  <td>{Number(props.selectedDrawer.previous_count.next_day_amount).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </>
                          :
                          translate('First count for this drawer. No reporting data available.')
                        :
                        translate('Loading...')
                      :
                      translate('Please select a drawer')
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Table hover width='100%'>
                      <thead>
                        <tr>
                          <th width="20%"></th>
                          <th width="40%">
                            {translate('Total')}
                          </th>
                          <th width="40%">
                            {translate('Value')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields}
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={'auto'}>
                    <div className="vl"></div>
                  </Col>
                  <Col md={3} className='d-flex align-items-end'>
                    <Row key={`fields-row`}>
                      {basicFields2}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <h4 className='count-form-total'>
          {translate('COUNTED')}: {Number(props?.data?.counted?.total ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className='count-form-total'>
          {translate('EXPECTED')}: {Number((reporting_total + previous_day_amount) ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className={`count-form-total-${Number(props?.data?.discrepancy) !== 0 ? "incorrect" : "correct"}`}>
          {translate('DISCREPANCY')}: {Number(props?.data?.discrepancy ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className={`count-form-total-${Number(props?.data?.eft_discrepancy) !== 0 ? "incorrect" : "correct"}`}>
          {translate('EFT DISCREPANCY')}: {Number(props?.data?.eft_discrepancy ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <h4 className={`count-form-total-${Number(props?.data?.on_account_discrepancy) !== 0 ? "incorrect" : "correct"}`}>
          {translate('ON ACCOUNT DISCREPANCY')}: {Number(props?.data?.on_account_discrepancy ?? 0).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h4>
        <br />
        <br />
        <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
        <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
    </>
  );
}
