import { Pie } from 'react-chartjs-2';

export const metaDataGraph = (country, gender) => {
  let graphCardData = <p>No data</p>

  if (country?.metadata?.Country) {

    let graphLabels = []
    let totalData = []
    let percentageData = []
    let backGroundColor = []
    let borderColor = []

    let count = 0
    let restGraphLabels = 'Rest'
    let restTotalData = 0
    for (const [countryName, countryQuantity] of Object.entries(country.metadata.Country).sort((a, b) => b[1] - a[1])) {

      if (count < 10) {
        let color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
        graphLabels.push(countryName)
        totalData.push(countryQuantity)
        // percentageData.push(countryData.percentage.toFixed(2))
        backGroundColor.push(color)
        borderColor.push(color)
        count = count + 1 
      }
      if (count >= 10) {
        restTotalData = restTotalData + countryQuantity
        count = count + 1 
      }      
    }

    // push rest to graph
    let color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    graphLabels.push(restGraphLabels)
    totalData.push(restTotalData)
    // percentageData.push(countryData.percentage.toFixed(2))
    backGroundColor.push(color)
    borderColor.push(color)
    
    const graphDatasetAmount = [
      {
        label: 'Value',
        data: totalData,
        borderColor: borderColor,
        backgroundColor: backGroundColor,
      },
    ]

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
        }
      },
    };

    const dataAmount = {
      labels: graphLabels,
      datasets: graphDatasetAmount
    }

    graphCardData =  <div className='pie-container'><Pie options={options} data={dataAmount} /></div> //<Pie options={options} data={dataQuantity} />
  }

  return graphCardData
};