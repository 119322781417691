import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import CcvshopWebhookButtons from '../portal/ccvshops/ccvshopWebhookbuttons';
import CcvshopWebhookModal from './ccvshopWebhookModal';
import { useTranslation } from 'react-i18next';


export default function CcvshopWebhookTable(webhooks) {
  const buttons = CcvshopWebhookButtons.call()
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  let modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: webhooks.id }) : false
  const { t: translate } = useTranslation();

  let rows = []

  for (const index in webhooks) {
    const webhook = webhooks[index]
    if (webhook) {
      const del = buttons.hasOwnProperty('delete') ? Object.assign({}, buttons.delete, { id: webhook.id }) : false
      const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: webhook.id }) : false
      rows.push(
        <tr className='product-row' key={webhook.id}>
          <td>{webhook.type}</td>
          <td>{webhook.event}</td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(webhook) }} id={webhook.id}>{translate(modalview.tag)}</Button>
            {del && <Button size="sm" type='button' onClick={() => { del.onClick(webhook) }} id={webhook.id}>{translate(del.tag)}</Button>}
          </td>
        </tr>
      )
    }

  };

  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Type')}
            </th>
            <th>
              {translate('Event')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <CcvshopWebhookModal modal={modalview.modal} />
      </Table>
    </>
  );
}