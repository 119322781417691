export const statusReportHighlightsSingleDay = (report) => {
  let newTotalCash = 0
  let newTotalEft = 0
  let newTotalOnAccount = 0
  let newTotalExchange = 0
  let newTotalTotal = 0
  let newTotalNettoCash = 0
  let newTotalWebshop = 0

  if (report.totals) {
    newTotalCash = report.totals.cash ? report.totals.cash : 0
    newTotalEft = report.totals.eft ? report.totals.eft : 0
    newTotalOnAccount = report.totals.on_account ? report.totals.on_account : 0
    newTotalExchange = report.totals.exchange ? report.totals.exchange : 0
    newTotalWebshop = report.totals.webshop ? report.totals.webshop : 0
    newTotalTotal = report.totals.total ? report.totals.total : 0
  }

  newTotalNettoCash = newTotalCash - newTotalExchange

  let newReportHighlights = [
    {Total:{amount:Number(newTotalTotal).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {Cash:{amount:Number(newTotalNettoCash).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {EFT:{amount:Number(newTotalEft).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {Webshop:{amount:Number(newTotalWebshop).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {OnAccount:{amount:Number(newTotalOnAccount).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {Transactions:{amount:Number(report.transactions)}},
  ]

  return newReportHighlights
};