import axios from "axios";

const GetRelationsByType = ({ token, admin_id, type, page = 0, size = 25 }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/relations/relation/`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'type': type,
        'page': page,
        'size': size
      },
    })
      .then(response => {resolve(response)})
      .catch(error => {reject(error)})
  });
};

export default GetRelationsByType