export const registrationFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'text', name: 'name', id: 'name' },
  { type: 'select', name: 'reseller_id', id: 'reseller_id', formdatafield: 'reseller_id' },
  { type: 'select', name: 'user_id', id: 'user_id', formdatafield: 'user_id' },
  { type: 'select', name: 'administration_id', id: 'administration_id', formdatafield: 'administration_id',  readonly: true },
  { type: 'select', name: 'location_id', id: 'location_id', formdatafield: 'location_id',  readonly: true },

  // single option fields
  { type: 'text', name: 'formal', id: 'formal' },
  { type: 'text', name: 'initials', id: 'initials' },
  { type: 'text', name: 'first_name', id: 'first_name' },
  { type: 'text', name: 'middle_name', id: 'middle_name' },
  { type: 'text', name: 'surname', id: 'surname' },
  { type: 'text', name: 'gender', id: 'gender' },
  { type: 'text', name: 'street', id: 'street' },
  { type: 'number', name: 'street_number', id: 'street_number' },
  { type: 'text', name: 'street_additional_number', id: 'street_additional_number' },
  { type: 'text', name: 'zip_code', id: 'zip_code' },
  { type: 'text', name: 'place', id: 'place' },
  { type: 'text', name: 'proverence', id: 'proverence' },
  { type: 'text', name: 'country', id: 'country' },
  { type: 'email', name: 'email', id: 'email' },
  { type: 'text', name: 'phone_number', id: 'phone_number' },
  { type: 'text', name: 'website_url', id: 'website_url' },
  { type: 'text', name: 'coc', id: 'coc' },
  { type: 'text', name: 'iban', id: 'iban' },
  { type: 'text', name: 'bic', id: 'bic' },
  { type: 'text', name: 'vat_number', id: 'vat_number' },
  // {type:'text', name:'ion', id:'ion'},
  { type: 'select', name: 'country_code', id: 'country_code', formdatafield: 'country_code' },

  // { type: 'multiselect', name: `subscriptions`, id: 'subscriptions', formdatafield: 'subscriptions' },
]

export const registrationEditFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'text', name: 'name', id: 'name' },
  { type: 'select', name: 'reseller_id', id: 'reseller_id', formdatafield: 'reseller_id' },
  { type: 'select', name: 'user_id', id: 'user_id', formdatafield: 'user_id' },
  { type: 'select', name: 'relation_id', id: 'relation_id', formdatafield: 'relation_id' },
  { type: 'select', name: 'administration_id', id: 'administration_id', formdatafield: 'administration_id' },
  { type: 'select', name: 'location_id', id: 'location_id', formdatafield: 'location_id' },
  { type: 'multiselect', name: `subscriptions`, id: 'subscriptions', formdatafield: 'subscriptions' },
]


export const registrationSettingsFormFields = [
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'datetime-local', name: 'start', id: 'start', step: '15', required: true },
  { type: 'datetime-local', name: 'stop', id: 'stop', step: '15', required: true },
  { type: 'number', name: `interval`, id: 'interval', required: false, step:'1'  },
  { type: 'number', name: `grace_period`, id: 'grace_period', required: false, step:'1'  },
  { type: 'number', name: `cancel_period`, id: 'cancel_period', required: false, step:'1'  },
  { type: 'number', name: `duration`, id: 'duration', required: false, step:'1'  },
  { type: 'number', name: `prolongation`, id: 'prolongation', required: false, step:'1'  },

]


export const addSubscriptionFormFields = [
  { type: 'select', name: 'registration_id', id: 'registration_id', formdatafield: 'registration_id' },
]

export const createNewSubscriptionFormFields = [
  
]