export default function HandleOnChangeSubscriptionType(props) {
  const event = props.event
  const object = props.object || {}
  const selectfield = props.selectfield || event.id
  const hash = props.hash

  let newObject = JSON.parse(JSON.stringify(object))

  // clear newobject selectfield
  delete newObject[selectfield]

  // put new object in 
  if (event && hash[event.value]) {
    newObject[selectfield] = hash[event.value] 
  }
  if (event && !hash[event.value]) {
    newObject[selectfield] = {'type': event.value}
  }

  return (newObject)
}